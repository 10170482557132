import React from 'react'
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import { useIntl } from 'react-intl'
import CrowconButton from 'components/miscComponents/CrowconButton'
import { PDFIcon } from 'assets/icons'
import { ExportColumnType, ExportWidgetType } from './DataExportInterfaces'
import { FormatData } from './ExportDataUtils'

interface ExportPDFProps<T> {
  data: T[]
  reportColumns: ExportColumnType[]
  filterSummary: string
  widgetForReport?: ExportWidgetType[]
  reportTitle: string
}
const TOP_START = 30

function ExportPDF<T>(props: ExportPDFProps<T>) {
  const { data, reportColumns, widgetForReport, reportTitle, filterSummary } = props
  const widgetCount = widgetForReport?.length || 0
  const formattedData = FormatData(data || [], reportColumns)
  const intl = useIntl()
  const localDate = Intl.DateTimeFormat(intl.locale).format(new Date())
  // eslint-disable-next-line new-cap
  const doc = new jsPDF('p', 'pt', 'a4')

  const initilizeText = (fontsize: number, colour: string, fontFamily: string) => {
    doc.setFontSize(fontsize)
    doc.setTextColor(colour)
    doc.setFont(fontFamily)
  }

  const printWidgetDataToPDF = () => {
    widgetForReport?.forEach((w, index) => {
      doc.text(w.WidgetTitle, 40, 40 + index * 70)
      autoTable(doc, {
        startY: 50 + index * 70,
        head: [w.ColumnHeaders],
        body: [w.ColumnDatas],
        margin: { top: 5, bottom: 5, right: 25 },
        styles: {
          overflow: 'ellipsize',
          fontSize: 8,
          fillColor: '#EAF1F6',
          textColor: '#0F1B2E',
          lineWidth: 0.5,
          lineColor: '#0F1B2E',
          halign: 'center',
        },
        bodyStyles: { fillColor: 'white', halign: 'center' },
        alternateRowStyles: { fillColor: 'white', halign: 'center' },
      })
    })
  }

  const printMainDataToPDF = (printColumns: string[], printRes: string[][], cellWidth: any) => {
    autoTable(doc, {
      // startY: 160 + widgetCount * 40,
      startY: 70,
      head: [printColumns],
      body: printRes,
      margin: { top: TOP_START, bottom: TOP_START },
      styles: {
        overflow: 'ellipsize',
        fontSize: 8,
        fillColor: 'white',
        textColor: '#0F1B2E',
        lineWidth: 0.5,
        lineColor: '#EAF1F6',
      },
      columnStyles: cellWidth,
      bodyStyles: { fillColor: 'white' },
      alternateRowStyles: { fillColor: 'white' },
    })
  }

  const downloadPDFReport = () => {
    const printColumns: string[] = []
    const cellWidth: any = {}
    const printRes: string[][] = []
    reportColumns.forEach((col, index) => {
      cellWidth[index] = { cellWidth: col.cellWidth }
      printColumns.push(col.label)
    })
    formattedData.forEach((dataRow) => {
      const reportBodyRow: string[] = []
      reportColumns.forEach((col) => {
        const bodyCell = dataRow[col.key]
        reportBodyRow.push(bodyCell)
      })
      printRes.push(reportBodyRow)
    })

    initilizeText(14, '#0F1B2E', 'Helvetica')
    const width = doc.internal.pageSize.getWidth()
    doc.text(reportTitle || '', width / 2, TOP_START, { align: 'center' })
    // doc.setFontSize(12)
    // printWidgetDataToPDF()
    doc.setFontSize(10)
    if (filterSummary) {
      const stringFilterSummary = doc.splitTextToSize(
        `Showing ${data?.length} results matching ${filterSummary}`,
        500,
      )
      doc.text(stringFilterSummary, 40, TOP_START + 20)
      // doc.text(stringFilterSummary, 40, TOP_START + 20 + widgetCount * 70)
    }
    printMainDataToPDF(printColumns, printRes, cellWidth)
    doc.save(`${reportTitle}_${localDate}`)
  }

  return (
    <CrowconButton
      additionalClassName='text-base text-c-dark-blue-1  font-bold  bg-c-dark-blue-1 hover:text-white h-52  w-full rounded-4xl'
      buttonChildren={
        <span className='flex flex-row pl-16 gap-3'>
          Download PDF <PDFIcon />
        </span>
      }
      buttonAction={() => downloadPDFReport()}
    />
  )
}

export default ExportPDF
