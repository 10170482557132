import React from 'react'
import {
  groupDeviceDataByDeviceType,
  groupDeviceDataByGasType,
  groupDeviceDataByComplianceType,
} from 'pages/devices/data/DeviceDataUtils'

import { ExportWidgetType } from 'exportReports/DataExportInterfaces'
import {
  iCombinedDeviceData,
  iDeviceTypeCount,
  iGasTypeCount,
  iComplianceCount,
} from 'pages/devices/data/iDevicesList'
import { iDeviceListDataWithStats } from '../data/DevicePageData'

const GetDevicesByTypeForReport = (deviceData: iDeviceListDataWithStats[]) => {
  const devicesCountByTypeForReport: ExportWidgetType = {
    WidgetTitle: '',
    ColumnHeaders: [],
    ColumnDatas: [],
  }
  const devicesByType: iDeviceTypeCount[] = groupDeviceDataByDeviceType(deviceData)
  devicesByType.forEach((val) => {
    devicesCountByTypeForReport.ColumnHeaders.push(val.deviceType)
    devicesCountByTypeForReport.ColumnDatas.push(val.count)
  })
  devicesCountByTypeForReport.WidgetTitle = 'Type'

  return devicesCountByTypeForReport
}

const GetDevicesByGasForReport = (deviceData: iDeviceListDataWithStats[]) => {
  const devicesCountByGasForReport: ExportWidgetType = {
    WidgetTitle: '',
    ColumnHeaders: [],
    ColumnDatas: [],
  }
  const devicesByGas: iGasTypeCount[] = groupDeviceDataByGasType(deviceData)
  devicesByGas.forEach((val) => {
    devicesCountByGasForReport.ColumnHeaders.push(val.gasType)
    devicesCountByGasForReport.ColumnDatas.push(val.count)
  })
  devicesCountByGasForReport.WidgetTitle = 'Gases'

  return devicesCountByGasForReport
}

const GetDevicesByComplianceForReport = (deviceData: iDeviceListDataWithStats[]) => {
  const devicesCountByComplianceForReport: ExportWidgetType = {
    WidgetTitle: '',
    ColumnHeaders: [],
    ColumnDatas: [],
  }
  const devicesByCompliance: iComplianceCount[] = groupDeviceDataByComplianceType(deviceData)
  devicesByCompliance.forEach((val) => {
    devicesCountByComplianceForReport.ColumnHeaders.push(val.complianceParam)
    devicesCountByComplianceForReport.ColumnDatas.push(val.count)
  })
  devicesCountByComplianceForReport.WidgetTitle = 'Compliance'
  return devicesCountByComplianceForReport
}

function FormatDeviceWidget(deviceData: iDeviceListDataWithStats[]) {
  const DevicesWidgetForReport: ExportWidgetType[] = []
  const devicesByTypeForReport: ExportWidgetType = GetDevicesByTypeForReport(deviceData)
  DevicesWidgetForReport.push(devicesByTypeForReport)
  const devicesByGasForRetport: ExportWidgetType = GetDevicesByGasForReport(deviceData)
  DevicesWidgetForReport.push(devicesByGasForRetport)
  const devicesByComplianceForReport: ExportWidgetType = GetDevicesByComplianceForReport(deviceData)
  DevicesWidgetForReport.push(devicesByComplianceForReport)

  return DevicesWidgetForReport
}
export default FormatDeviceWidget
