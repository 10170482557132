import React from 'react'
import { SvgProps } from 'types/SVGProps'

export default function SuccessIcon({ className }: SvgProps) {
  return (
    <svg className={className} viewBox='0 0 24 24' strokeWidth='1.5' aria-hidden='true'>
      <path
        strokeLinecap='round'
        strokeLinejoin='round'
        d='M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z'
      />
    </svg>
  )
}
