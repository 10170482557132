import { Transition, Dialog } from '@headlessui/react'
import { CloseIcon } from 'assets/icons'
import { CreatableSelectControl, SelectControl } from 'components/formComponents'
import TextInputControl from 'components/formComponents/TextInputControl'
import React, { Fragment } from 'react'
import { Controller, DeepPartial, FieldErrors, useForm } from 'react-hook-form'
import { useLocation } from 'react-router-dom'
import clsx from 'clsx'
import { IselectOptionProps } from 'forms/FormModelInterface'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useMsal } from '@azure/msal-react'
import { CustomerStatusConstants, CustomerStatusEnums } from 'pages/customer/view/CustomerPageUtils'
import { useGenericEventHandler } from 'data/GenericEventHandler'
import { AxiosError } from 'axios'
import { billingPlanQueryKey } from 'services/apis/caching/business-plans'
import { BillingPlanPageColumnIds } from '../utils/BillingPlansUtils'
import { addBillingPlan, iBillingPlanApiPayload, updateBillingPlan } from '../data/BillingPlanData'

export type BillingPlanFormType = {
  id?: string
  name: string
  description: string
  numberOfDevices: number
  numberOfOperators: number
  numberOfUsers: number
  numberOfApiKeys: number
  billingPlanType?: IselectOptionProps
  status: IselectOptionProps
}

export default function BillingPlanCrudForm({
  type,
  data,
  close,
}: {
  type?: string
  data?: BillingPlanFormType
  close: () => void
}) {
  const location = useLocation()
  const isOpened = Boolean(type)
  // useQueryClient is a hook provided by react-query that returns the query client instance.
  // The query client is used to interact with and control the state of queries.
  const queryClient = useQueryClient()

  // useMsal is a hook provided by the MSAL (Microsoft Authentication Library) React library that returns the MSAL context.
  // The MSAL context contains various properties and methods related to authentication.
  const msalContext = useMsal()
  const { genericEventHandler } = useGenericEventHandler()
  const {
    handleSubmit,
    control,
    formState: { errors, isValid, isSubmitting },
  } = useForm<BillingPlanFormType>({
    defaultValues: data as DeepPartial<BillingPlanFormType>,
    values: data,
    criteriaMode: 'all',
    shouldUnregister: true,
    mode: 'onTouched',
  })

  const updateBillingPlanMutation = useMutation(updateBillingPlan, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: billingPlanQueryKey(),
      })
      close()
    },
    onError: (error: AxiosError) => {
      genericEventHandler({
        error,
        severity: 'error',
        onlyTrack: false,
        message:
          (
            (error as unknown as AxiosError).response?.data as {
              detail: string
            }
          )?.detail ||
          error?.message ||
          'Error while updating billing plan',
        extraData: {
          component: 'BillingPlanCrudCrudFrom.tsx',
          action: 'billing plan updating failed',
        },
      })
    },
  })
  const addBillingPlanMutation = useMutation(addBillingPlan, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: billingPlanQueryKey(),
      })
      close()
    },
    onError: (error: AxiosError) => {
      genericEventHandler({
        error,
        severity: 'error',
        onlyTrack: false,
        message:
          (
            (error as unknown as AxiosError).response?.data as {
              detail: string
            }
          )?.detail ||
          error?.message ||
          'Error while creating billing plan',
        extraData: {
          component: 'BillingPlanCrudCrudFrom.tsx',
          action: 'billing plan creation failed',
        },
      })
    },
  })

  const onSubmit = (values: BillingPlanFormType) => {
    const payload: iBillingPlanApiPayload = {
      id: data?.id || '',
      name: values.name as string,
      description: values?.description as string,
      numberOfApiKeys: 5,
      numberOfDevices: Number(values.numberOfDevices),
      numberOfOperators: Number(values.numberOfOperators),
      numberOfUsers: Number(values.numberOfUsers),
      BillingPlanType: values?.billingPlanType?.value as string,
      status: values.status.value === 'Active' ? 'Active' : 'Inactive',
    }

    if (type === 'edit') {
      updateBillingPlanMutation.mutate({
        msalContext,
        redirectPageURL: location.pathname,
        payload,
      })
    } else {
      delete payload.id
      delete payload.status

      addBillingPlanMutation.mutate({
        msalContext,
        redirectPageURL: location.pathname,
        payload,
      })
    }
  }
  const onError = (error: FieldErrors<BillingPlanFormType>) => {
    console.log(error)
  }

  const onReset = () => {
    close()
  }
  return (
    <Transition
      show={isOpened}
      enter='transition duration-100 ease-out'
      enterFrom='transform scale-95 opacity-0'
      enterTo='transform scale-100 opacity-100'
      leave='transition duration-75 ease-out'
      leaveFrom='transform scale-100 opacity-100'
      leaveTo='transform scale-95 opacity-0'
      as={Fragment}
    >
      <Dialog open={isOpened} onClose={close} className='relative z-l-2 rounded-2xl'>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-black/30' />
        </Transition.Child>
        <form
          className='fixed inset-0 w-screen overflow-y-auto'
          onSubmit={handleSubmit(onSubmit, onError)}
          onReset={onReset}
          noValidate
        >
          <div className='flex min-h-full items-center justify-center p-4'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Dialog.Panel className='mx-auto w-530 rounded-2xl bg-white z-l-3'>
                <div className='font-poppins px-6 pt-18 pb-10 w-full '>
                  <div className='flex flex-col '>
                    <div
                      id='add-device-form-header'
                      className='flex flex-row justify-center items-center mb-6'
                    >
                      <div className='w-full font-bold text-base leading-5'>
                        {type === 'edit' ? 'Edit' : 'Add'} Billing Plan
                      </div>
                      <CloseIcon
                        toggleClick={() => close()}
                        className='bg-c-dark-blue-1 rounded-full w-10 h-10 p-2.5 hover:bg-c-dark-blue-2  active:bg-c-dark-blue-3'
                      />
                    </div>
                    <div className='grid grid-cols-2 gap-2'>
                      <div className=''>
                        <label
                          htmlFor={BillingPlanPageColumnIds.name}
                          className='pl-[14px] text-xs font-bold leading-5 text-c-dark-blue-1 '
                        >
                          Name
                        </label>
                        <Controller
                          name='name'
                          control={control}
                          rules={{
                            required: 'Name is required',
                          }}
                          render={({ field }) => (
                            <TextInputControl
                              className='w-full h-[42px]'
                              id={BillingPlanPageColumnIds.name}
                              elementProps={{
                                value: field.value,
                                defaultValue: field.value,
                                placeholder: 'Enter Name',
                                onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                                  field.onChange(event.target.value)
                                },
                                onBlur: () => {
                                  field.onBlur()
                                },
                              }}
                            />
                          )}
                        />
                        <p className='text-xs text-red-500'>{errors.name?.message}</p>
                      </div>
                      <div className=''>
                        <label
                          htmlFor={BillingPlanPageColumnIds.billingPlanType}
                          className='pl-[13px] text-xs font-bold leading-5 text-c-dark-blue-1 '
                        >
                          Type
                        </label>
                        <Controller
                          name='billingPlanType'
                          rules={{
                            required: 'Subscription Plan is required',
                          }}
                          control={control}
                          render={({ field }) => (
                            <CreatableSelectControl
                              className='w-full h-[41px]'
                              selectControlProps={{
                                options: [
                                  {
                                    label: 'Demo Plan',
                                    value: 'DemoPlan',
                                  },
                                  {
                                    label: 'Live Plan',
                                    value: 'LivePlan',
                                  },
                                ],
                                value: field.value,
                                defaultValue: field.value,
                                isMulti: false,
                                isSearchable: true,
                                isDropDownSelectable: false,
                                openMenuOnClick: true,
                                placeholder: 'Select Plan Type',
                                isClearable: true,
                                // maxMenuHeight: 130,
                                onChange: field.onChange,
                              }}
                            />
                          )}
                        />
                        <p className='text-xs text-red-500'>{errors.billingPlanType?.message}</p>
                      </div>
                      <div className='col-span-full'>
                        <label
                          htmlFor={BillingPlanPageColumnIds.description}
                          className='pl-[14px] text-xs font-bold leading-5 text-c-dark-blue-1 '
                        >
                          Description
                        </label>
                        <Controller
                          name='description'
                          control={control}
                          render={({ field }) => (
                            <textarea
                              {...field}
                              placeholder='Enter description'
                              rows={4}
                              className={clsx(
                                ' focus:bg-c-light-blue-2 focus:border-c-blue ',
                                'active:bg-c-blue active:text-c-white active:border-c-blue ',
                                'read-only:bg-c-light-blue-1 ',
                                'hover:border-c-dark-blue-2 ',
                                'text-xs font-medium leading-5 border-c-light-blue-2 placeholder-c-dark-blue-2',
                                'w-full h-auto bg-c-white font-poppins rounded-3xl border-3 pl-4 py-3 pr-3 text-c-dark-blue-2 hideScrollBar ',
                              )}
                            />
                          )}
                        />
                        <p className='text-xs text-red-500'>{errors.description?.message}</p>
                      </div>
                      <div className='col-span-1'>
                        <label
                          htmlFor={BillingPlanPageColumnIds.numberOfDevices}
                          className='pl-[14px] text-xs font-bold leading-5 text-c-dark-blue-1 '
                        >
                          Number of Devices
                        </label>
                        <Controller
                          name='numberOfDevices'
                          control={control}
                          rules={{
                            required: 'Number of Devices is required',
                            min: {
                              value: 1,
                              message: 'Number of API keys should be greater than 1',
                            },
                            max: {
                              value: 5000,
                              message: 'Number of API keys should be less than 5000',
                            },
                          }}
                          render={({ field }) => (
                            <TextInputControl
                              className='w-full h-[42px]'
                              id={BillingPlanPageColumnIds.numberOfDevices}
                              elementProps={{
                                type: 'number',
                                value: field.value,
                                min: 1,
                                max: 5000,
                                defaultValue: field.value,
                                placeholder: 'Enter Max of Devices',
                                onChange: field.onChange,
                                onBlur: () => {
                                  field.onBlur()
                                },
                              }}
                            />
                          )}
                        />
                        <p className='text-xs text-red-500'>{errors.numberOfDevices?.message}</p>
                      </div>

                      <div className='col-span-1'>
                        <label
                          htmlFor={BillingPlanPageColumnIds.numberOfOperators}
                          className='pl-[14px] text-xs font-bold leading-5 text-c-dark-blue-1 '
                        >
                          Number of Operators
                        </label>
                        <Controller
                          name='numberOfOperators'
                          control={control}
                          rules={{
                            required: 'Number of Operators is required',
                            min: {
                              value: 1,
                              message: 'Number of API keys should be greater than 1',
                            },
                            max: {
                              value: 5000,
                              message: 'Number of API keys should be less than 5000',
                            },
                          }}
                          render={({ field }) => (
                            <TextInputControl
                              className='w-full h-[42px]'
                              id={BillingPlanPageColumnIds.numberOfOperators}
                              elementProps={{
                                type: 'number',
                                value: field.value,
                                min: 1,
                                max: 5000,
                                defaultValue: field.value,
                                placeholder: 'Enter Max of Operators',
                                onChange: field.onChange,
                                onBlur: () => {
                                  field.onBlur()
                                },
                              }}
                            />
                          )}
                        />
                        <p className='text-xs text-red-500'>{errors.numberOfOperators?.message}</p>
                      </div>

                      <div className={type === 'edit' ? 'col-span-1' : 'col-span-full'}>
                        <label
                          htmlFor={BillingPlanPageColumnIds.numberOfUsers}
                          className='pl-[14px] text-xs font-bold leading-5 text-c-dark-blue-1 '
                        >
                          Number of Users
                        </label>
                        <Controller
                          name='numberOfUsers'
                          control={control}
                          rules={{
                            min: {
                              value: 1,
                              message: 'Number of API keys should be greater than 1',
                            },
                            max: {
                              value: 100,
                              message: 'Number of API keys should be less than 100',
                            },
                            required: 'Number of Users is required',
                          }}
                          render={({ field }) => (
                            <TextInputControl
                              className='w-full h-[42px]'
                              id={BillingPlanPageColumnIds.numberOfUsers}
                              elementProps={{
                                type: 'number',
                                value: field.value,
                                min: 1,
                                max: 100,
                                defaultValue: field.value,
                                placeholder: 'Enter Max of Users',
                                onChange: field.onChange,
                                onBlur: () => {
                                  field.onBlur()
                                },
                              }}
                            />
                          )}
                        />
                        <p className='text-xs text-red-500'>{errors.numberOfUsers?.message}</p>
                      </div>
                      {/* <div className='col-span-1'>
                        <label
                          htmlFor={BillingPlanPageColumnIds.numberOfApiKeys}
                          className='pl-[14px] text-xs font-bold leading-5 text-c-dark-blue-1 '
                        >
                          Number of API Keys
                        </label>
                        <Controller
                          name='numberOfApiKeys'
                          control={control}
                          rules={{
                            min: {
                              value: 1,
                              message: 'Number of API keys should be greater than 1',
                            },
                            max: {
                              value: 5,
                              message: 'Number of API keys should be less than 5',
                            },
                            required: 'Number of API keys is required',
                          }}
                          render={({ field }) => (
                            <TextInputControl
                              className='w-full h-[42px]'
                              id={BillingPlanPageColumnIds.numberOfApiKeys}
                              elementProps={{
                                type: 'number',
                                value: field.value,
                                max: 5,
                                min: 1,
                                defaultValue: field.value,
                                placeholder: 'Enter Max of API Keys',
                                onChange: field.onChange,
                                onBlur: () => {
                                  field.onBlur()
                                },
                              }}
                            />
                          )}
                        />
                        <p className='text-xs text-red-500'>{errors.numberOfApiKeys?.message}</p>
                      </div> */}
                      {type === 'edit' && (
                        <div className=''>
                          <label
                            htmlFor='status'
                            className='pl-[13px] text-xs font-bold leading-5 text-c-dark-blue-1 '
                          >
                            Status
                          </label>
                          <Controller
                            name='status'
                            control={control}
                            render={({ field }) => (
                              <SelectControl
                                id='editDeviceFormDeviceStatus'
                                className='h-11 rounded-3xl'
                                selectControlProps={{
                                  options: [
                                    {
                                      label: CustomerStatusConstants[CustomerStatusEnums.Active],
                                      value: CustomerStatusEnums.Active,
                                    },
                                    {
                                      label: CustomerStatusConstants[CustomerStatusEnums.Inactive],
                                      value: CustomerStatusEnums.Inactive,
                                    },
                                  ],
                                  defaultValue: field.value,
                                  isMulti: false,
                                  isSearchable: true,
                                  isDropDownSelectable: true,
                                  openMenuOnClick: true,
                                  placeholder: 'Select an option',
                                  isClearable: false,
                                  onChange: field.onChange,
                                }}
                              />
                            )}
                          />

                          <p className='text-xs text-red-500'>{errors.status?.message}</p>
                        </div>
                      )}

                      <div className='col-span-2'>
                        <button
                          type='submit'
                          disabled={!isValid || isSubmitting}
                          className='font-extrabold  active:bg-c-dark-blue-3  hover:bg-c-dark-blue-2 disabled:bg-c-light-blue-3 disabled:text-c-dark-blue-1 text-base mt-2 bg-c-dark-blue-1 text-white h-12 w-full rounded-3xl'
                        >
                          {type === 'edit' ? 'Update' : 'Create'} Billing Plan
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </form>
      </Dialog>
    </Transition>
  )
}
BillingPlanCrudForm.defaultProps = {
  data: undefined,
  type: 'add',
}
