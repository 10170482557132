import React from 'react'
import DeviceDetailsReportSelector from 'pages/deviceDetails/rootPage/view/DeviceDetailsReportSelector'
import { useParams } from 'react-router-dom'
import { PubNubProvider } from 'pubnub-react'
import Pubnub from 'pubnub'
import withFeatureFlag from 'hoc/withFeatureFlag'
import { FeatureFlags } from 'utils/FeatureFlags'
import Graph from './components/Graph'

const pubnub = new Pubnub({
  publishKey: process.env.REACT_APP_PUBLISH_KEY as string,
  subscribeKey: process.env.REACT_APP_SUBSCRIBE_KEY as string,
  uuid: Pubnub.generateUUID(),
  secretKey: process.env.REACT_APP_SECRET_KEY as string,
})

function LiveReadings() {
  const params = useParams()
  const { deviceSerialNumber } = params as { deviceSerialNumber: string }
  document.title = `Live Readings - ${deviceSerialNumber}`

  return (
    <PubNubProvider client={pubnub}>
      <div className='bg-c-light-blue-2 h-full relative'>
        <DeviceDetailsReportSelector />
        <div className='px-5 py-3'>
          <Graph />
        </div>
      </div>
    </PubNubProvider>
  )
}
export default withFeatureFlag(LiveReadings, FeatureFlags.LiveReadings)
