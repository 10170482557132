import { IPublicClientApplication, InteractionStatus } from '@azure/msal-browser'
import { QueryClient } from '@tanstack/react-query'
import { allCustomerOption, getOrFetchCustomerList } from 'data/CustomerListHook'
import { getOrFetchHelpAndSupportData } from 'data/HelpAndSupportDetailsHook'
import { iDataLoaderParams } from 'data/Interfaces'
import { getOrFetchUserInfo } from 'data/UserInfoHook'
import { LoaderFunction, createSearchParams } from 'react-router-dom'
import { getAccessTokenFromPCA } from 'services/MSALService'
import { IsUserGlobalScope } from 'utils/UserDataUtils'

interface HelpPageDataLoaderParams {
  queryClient: QueryClient
  msalInstance: IPublicClientApplication
}

export const HelpPageDataLoader =
  (props: iDataLoaderParams): LoaderFunction =>
  async ({ request, params }) => {
    const { queryClient, msalInstance, msalAccounts, msalInProgress } = props
    if (!msalInstance) throw new Error('No MSAL Context')
    if (!queryClient) throw new Error('No query client')

    try {
      if (msalInProgress !== InteractionStatus.None) {
        return {}
      }
      if (msalAccounts.length === 0) {
        return {}
      }
      const token = await getAccessTokenFromPCA({ msalInstance })
      const userInfo = await getOrFetchUserInfo({ queryClient, token })
      const isUserGlobal = IsUserGlobalScope(userInfo)
      const searchParams = createSearchParams(new URLSearchParams(request.url.split('?')[1]))
      const customerList = !isUserGlobal ? [] : await getOrFetchCustomerList({ queryClient, token })
      const uniqueCustomerList = customerList?.filter((item) => item !== allCustomerOption)
      const customerId = isUserGlobal
        ? searchParams.get('c') || uniqueCustomerList[0].id
        : userInfo?.user?.customerId

      const helpAndSupportData = await getOrFetchHelpAndSupportData({
        queryClient,
        token,
        customerId,
      })

      return {
        helpPageData: helpAndSupportData,
        uniqueCustomerList,
      }
    } catch (error) {
      console.error(error)
      throw error
    }
  }
