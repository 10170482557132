import React from 'react'
import clsx from 'clsx'
import { SvgProps } from 'types/SVGProps'

function Spinner(props: SvgProps) {
  const { stroke, fill, className, toggleClick } = props
  return (
    <div
      className={clsx(
        'animate-spin rounded-full border-4 border-solid border-c-white border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]',
        className,
      )}
    />
  )
}

export default Spinner
