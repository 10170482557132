import { allBusinessUnitOption } from 'data/BusinessUnitsListHookWrapper'
import { convertUrlForDemoMode } from 'utils/CommonUtils'
import { endpoints } from '../endpoints'

export const listFaultsApiUrl = (FromDate: string, ToDate: string, businessUnitId: string) => {
  let url = `${endpoints.logs.faults}?FromDate=${FromDate}&ToDate=${ToDate}`
  if (businessUnitId !== null && businessUnitId !== (allBusinessUnitOption.id as string)) {
    url += `&BusinessUnitId=${businessUnitId}`
  }
  return convertUrlForDemoMode(url)
}
