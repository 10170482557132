import React from 'react'
import { ExportColumnType } from 'exportReports/DataExportInterfaces'
import { FormatUTXToDateTime } from 'exportReports/ExportDataUtils'


const GasLogsReportColumns: ExportColumnType[] = [
    {
      key: 'timeStamp',
      label: 'Date and time',
      format: (val: any) => FormatUTXToDateTime(val as number),
      cellWidth: 100,
    },
    {
      key: 'channelNumber',
      label: 'Channel number',
      format: (val: string) => val,
      cellWidth: 120,
    },
    {
      key: 'gasName',
      label: 'Gas name',
      format: (val: string) => val,
      cellWidth: 150,
    },
    {
      key: 'gasValue',
      label: 'Gas value(UoM)',
      format: (val: string) => val,
      cellWidth: 80,
    }
  ]
  
  export { GasLogsReportColumns }
