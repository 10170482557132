import _ from 'lodash'
import { add, format, startOfMonth, endOfMonth, isEqual, isAfter, isBefore } from 'date-fns'
import { PortableTypes, DeviceCalDueTypes } from 'utils/CommonEnums'

import { DateRange, IselectOptionProps } from 'forms/FormModelInterface'
import { selectAllOption } from 'forms/FormUtils'
import {
  iDeviceCalDueByDaysCount,
  iDeviceCalDueByMonthCount,
} from 'pages/devices/data/iDevicesList'
import { iDevice } from 'data/DeviceListHook'
import { DeviceCalDueTypeTexts } from 'utils/Constants'
import { GetProductNameText } from 'utils/CommonUtils'
import { dayDateToDate, returnStaticDate } from 'components/modules/Calender/CalenderUtils'
import { CalibrationPageColumnIds } from '../view/CalibrationsPageUtils'

export const PrepareDeviceTypeOptionsForSelect = (deviceData: iDevice[]): IselectOptionProps[] => {
  // temporary remove gasman devices for test
  // const deviceDataWithoutGasman = removeGasmanDevices(deviceData);

  // Get all enums in a string array
  const portableTypes: IselectOptionProps[] = []

  Object.keys(PortableTypes).forEach((portableType) => {
    const isDisabled = !deviceData.some((device) => device?.deviceType?.toString() === portableType)
    const deviceTypeText = GetProductNameText(portableType as PortableTypes)
    portableTypes.push({
      value: deviceTypeText,
      label: deviceTypeText,
      // isDisabled,
    })
  })

  portableTypes.unshift(selectAllOption)

  return portableTypes
}

export const groupDeviceDataByCalDueDays = (deviceData: iDevice[]): iDeviceCalDueByDaysCount[] => {
  const devicesGroupedByCalDuePeriod = _.groupBy(deviceData, 'calDueStatus')

  const getCount = (type: DeviceCalDueTypes) => devicesGroupedByCalDuePeriod[type]?.length || 0

  const deviceduein7d = getCount(DeviceCalDueTypes.DueIn7Days)
  const deviceduein30d = deviceduein7d + getCount(DeviceCalDueTypes.DueIn30Days)
  const deviceduein60d = deviceduein30d + getCount(DeviceCalDueTypes.DueIn60Days)
  const deviceduein90d = deviceduein60d + getCount(DeviceCalDueTypes.DueIn90Days)

  return [
    {
      calDueParam: DeviceCalDueTypes.Overdue,
      count: getCount(DeviceCalDueTypes.Overdue),
      columnId: CalibrationPageColumnIds.calDue,
    },
    {
      calDueParam: DeviceCalDueTypes.DueIn7Days,
      count: deviceduein7d,
      columnId: CalibrationPageColumnIds.calDue,
    },
    {
      calDueParam: DeviceCalDueTypes.DueIn30Days,
      count: deviceduein30d,
      columnId: CalibrationPageColumnIds.calDue,
    },
    {
      calDueParam: DeviceCalDueTypes.DueIn60Days,
      count: deviceduein60d,
      columnId: CalibrationPageColumnIds.calDue,
    },
    {
      calDueParam: DeviceCalDueTypes.DueIn90Days,
      count: deviceduein90d,
      columnId: CalibrationPageColumnIds.calDue,
    },
    {
      calDueParam: DeviceCalDueTypes.DueOver90Days,
      count: getCount(DeviceCalDueTypes.DueOver90Days),
      columnId: CalibrationPageColumnIds.calDue,
    },
    {
      calDueParam: DeviceCalDueTypes.NotAvailable,
      count: getCount(DeviceCalDueTypes.NotAvailable),
      columnId: CalibrationPageColumnIds.calDue,
    },
  ]
}

export const constructNextXMonths = (NumberOfMonths = 6): DateRange[] => {
  const arr = Array.from({ length: NumberOfMonths }, (_, i) => i)
  const NextXMonthsDateRange: DateRange[] = []
  arr.forEach((month) => {
    NextXMonthsDateRange.push({
      startDate: format(startOfMonth(add(new Date(), { months: month })), 'yyyy-MM-dd'),
      endDate: format(endOfMonth(add(new Date(), { months: month })), 'yyyy-MM-dd'),
    })
  })

  NextXMonthsDateRange.unshift({
    startDate: format(startOfMonth(new Date('1970-01-01')), 'yyyy-MM-dd'),
    endDate: format(new Date(), 'yyyy-MM-dd'),
    isCustom: true,
    customValue: DeviceCalDueTypes.Overdue,
  })
  // const NextXMonths = arr.map((month) => getMonth(add(new Date(), { months: month })))
  // NextXMonths.forEach((month) => {
  //   NextXMonthsDateRange.push({
  //     startDate: startOfMonth(new Date(month)),
  //     endDate: endOfMonth(new Date(month)),
  //   })
  // })

  return NextXMonthsDateRange
}

export const getDeviceCountDueInNextXMonths = (
  deviceData: iDevice[],
  NumberOfMonths = 6,
): iDeviceCalDueByMonthCount[] => {
  const next6MonthsStartEndDates = constructNextXMonths(NumberOfMonths)

  const deviceCalDueByMonthCount: iDeviceCalDueByMonthCount[] = []

  const getCountForMonth = (startDate: string, endDate: string) =>
    deviceData.filter((device) => {
      const deviceCalDueDate = device.calibrationDueDate
        ? dayDateToDate(device.calibrationDueDate.split('T')[0])
        : null

      return deviceCalDueDate
        ? (isBefore(deviceCalDueDate, dayDateToDate(endDate)) ||
            isEqual(deviceCalDueDate, dayDateToDate(endDate))) &&
            (isAfter(deviceCalDueDate, dayDateToDate(startDate)) ||
              isEqual(deviceCalDueDate, dayDateToDate(startDate)))
        : false
    }).length

  deviceCalDueByMonthCount.push({
    calDueMonth:
      DeviceCalDueTypeTexts.find((calDueType) => calDueType.value === DeviceCalDueTypes.Overdue)
        ?.value || 'Overdue',
    count: deviceData.filter(
      (device) => device.calibrationDueDate && device.calDueStatus === DeviceCalDueTypes.Overdue,
    ).length,
  })

  for (let i = 0; i < NumberOfMonths; i += 1) {
    deviceCalDueByMonthCount.push({
      calDueMonth: format(add(new Date(), { months: i }), 'MMM'),
      count: getCountForMonth(
        next6MonthsStartEndDates[i + 1].startDate as string,
        next6MonthsStartEndDates[i + 1].endDate as string,
      ),
    })
  }

  return deviceCalDueByMonthCount
}

// deviceCalDueByMonthCount.push({
//   calDueMonth: format(add(new Date(), { months: 1 }), 'MMM'),
//   count: deviceData.filter((device) => {
//     const deviceCalDueDate = device.calibrationDueDate
//       ? new Date(device.calibrationDueDate)
//       : null
//     return deviceCalDueDate
//       ? differenceInCalendarMonths(deviceCalDueDate, new Date()) <= 2 &&
//           differenceInCalendarMonths(deviceCalDueDate, new Date()) > 1
//       : false
//   }).length,
// })

// deviceCalDueByMonthCount.push({
//   calDueMonth: format(add(new Date(), { months: 2 }), 'MMM'),
//   count: deviceData.filter((device) => {
//     const deviceCalDueDate = device.calibrationDueDate
//       ? new Date(device.calibrationDueDate)
//       : null
//     return deviceCalDueDate
//       ? differenceInCalendarMonths(deviceCalDueDate, new Date()) <= 3 &&
//           differenceInCalendarMonths(deviceCalDueDate, new Date()) > 2
//       : false
//   }).length,
// })

// deviceCalDueByMonthCount.push({
//   calDueMonth: format(add(new Date(), { months: 3 }), 'MMM'),
//   count: deviceData.filter((device) => {
//     const deviceCalDueDate = device.calibrationDueDate
//       ? new Date(device.calibrationDueDate)
//       : null
//     return deviceCalDueDate
//       ? differenceInCalendarMonths(deviceCalDueDate, new Date()) <= 4 &&
//           differenceInCalendarMonths(deviceCalDueDate, new Date()) > 3
//       : false
//   }).length,
// })

// deviceCalDueByMonthCount.push({
//   calDueMonth: format(add(new Date(), { months: 4 }), 'MMM'),
//   count: deviceData.filter((device) => {
//     const deviceCalDueDate = device.calibrationDueDate
//       ? new Date(device.calibrationDueDate)
//       : null
//     return deviceCalDueDate
//       ? differenceInCalendarMonths(deviceCalDueDate, new Date()) <= 5 &&
//           differenceInCalendarMonths(deviceCalDueDate, new Date()) > 4
//       : false
//   }).length,
// })

// deviceCalDueByMonthCount.push({
//   calDueMonth: format(add(new Date(), { months: 5 }), 'MMM'),
//   count: deviceData.filter((device) => {
//     const deviceCalDueDate = device.calibrationDueDate
//       ? new Date(device.calibrationDueDate)
//       : null
//     return deviceCalDueDate
//       ? differenceInCalendarMonths(deviceCalDueDate, new Date()) <= 6 &&
//           differenceInCalendarMonths(deviceCalDueDate, new Date()) > 5
//       : false
//   }).length,
// })
