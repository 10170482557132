import React from 'react'
import { useNavigate } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import CrowconButton from 'components/miscComponents/CrowconButton'
import { NavBackIcon } from 'assets/icons'

export function NavigateBackButton(): JSX.Element {
  const navigate = useNavigate()

  return (
    <CrowconButton
      additionalClassName='flex flex-row justify-around items-center rounded-4xl p-[0.6rem] md:p-[1rem] '
      buttonAction={() => navigate('/web/devices')}
      buttonChildren={
        <>
          <span className='md:mr-2'>
            <NavBackIcon />
          </span>
          <span className='font-bold text-base leading-5 mr-4 lg:mr-6 hidden md:block'>
            <FormattedMessage id='commonText.Button.goBack' />
          </span>
        </>
      }
    />
  )
}

// <CrowconButton
//       additionalClassName='flex flex-row justify-around items-center rounded-full md:rounded-4xl w-10 md:w-44 h-10 md:h-52 '
//       buttonAction={buttonAction}
//       buttonChildren={
//         <>
//           <span className='hidden md:inline ml-5 font-bold text-base leading-5 '>{buttonText}</span>
//           <span className='md:mr-4 md:ml-3'>
//             <AddIcon />
//           </span>
//         </>
//       }
//     />

// <button
//   type='button'
//   className='bg-c-blue text-c-white text-3xs font-bold py-1 px-2 rounded'
//   onClick={() => navigate('/devices')}
// >
//   <FormattedMessage id='commonText.Button.goBack' />
// </button>
