import { useMsal } from '@azure/msal-react'
import { useMutation } from '@tanstack/react-query'
import { StepsTypes, DemoOperators, AzureFunctionResponse, OperatorProps } from '../../interfaces'
import { StageStatus } from '../../utils/constants'
import { getDemoOperators } from '../apis'

export function useOperatorMutation(
  state: StepsTypes[],
  setState: React.Dispatch<React.SetStateAction<StepsTypes[]>>,
) {
  const { instance: msalInstance } = useMsal()

  return useMutation<DemoOperators[], AzureFunctionResponse<unknown>, OperatorProps>(
    async (props) => {
      setState((prevState) => {
        const newState = [...prevState]
        newState[2].status = StageStatus.WORKING
        return newState
      })
      const operators = await Promise.all(
        Array.from({ length: 5 }).map((_, i) =>
          getDemoOperators({
            customerId: props.customerId,
            businessUnitId: props.businessUnitIdList[i],
            msalInstance,
          }),
        ),
      )
      return operators
    },
    {
      retry: 3,
      retryDelay: 1000,
      onSuccess: () => {
        setState((prevState) => {
          const newState = [...prevState]
          newState[2].endTitle = 'Your operator details are ready.'
          newState[2].status = StageStatus.END
          return newState
        })
      },
      onError: (error: AzureFunctionResponse<unknown>) => {
        console.log(error)
        setState((prevState) => {
          const newState = [...prevState]
          newState[2].error = error.message
          return newState
        })
      },
    },
  )
}
