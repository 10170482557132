import { selectAllOption } from 'forms/FormUtils'
import { convertUrlForDemoMode } from 'utils/CommonUtils'
import { endpoints } from '../endpoints'

const healthDateUrl = (businessUnitId?: string, customerId?: string) => {
  if (
    businessUnitId &&
    businessUnitId.toLowerCase() !== selectAllOption.value?.toString().toLowerCase()
  ) {
    return `${endpoints.fleet.summary}?ExpandBusinessUnit=true&BusinessUnitId=${businessUnitId}`
  }
  return endpoints.fleet.summary
}
const inSightDataUrl = (businessUnitId?: string, customerId?: string) => {
  if (
    businessUnitId &&
    businessUnitId.toLowerCase() !== selectAllOption.value?.toString().toLowerCase()
  ) {
    return `${endpoints.fleet.insights}?ExpandBusinessUnit=true&BusinessUnitId=${businessUnitId}`
  }
  return endpoints.fleet.insights
}

export const fleetHealthDataURL = (businessUnitId?: string, customerId?: string) =>
  convertUrlForDemoMode(healthDateUrl(businessUnitId, customerId))

export const fleetInsightsDataURL = (businessUnitId?: string, customerId?: string) =>
  convertUrlForDemoMode(inSightDataUrl(businessUnitId, customerId))
