import { AzureFunctionResponse } from 'components/modules/demo/interfaces'

export async function azureFunctionCaller<T, P>(
  url: string,
  token: string,
  payload?: P,
  method: 'GET' | 'POST' = 'GET',
): Promise<AzureFunctionResponse<T>> {
  try {
    const headers = new Headers()
    headers.append('Authorization', `Bearer ${token}`)
    let config: RequestInit = {
      headers,
    }
    if (method === 'POST') {
      config = {
        ...config,
        method: 'POST',
        body: JSON.stringify(payload),
      }
    }
    const response = await fetch(url, config)
    const data = await response.json()
    if (!response.ok) {
      throw new Error(response.statusText, { cause: { data, status: response.status } })
    }
    return data
  } catch (error) {
    const apiError = error as Error
    throw new Error(error as string, { cause: apiError.cause })
  }
}
