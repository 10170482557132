import { useQuery } from '@tanstack/react-query'
import { useMsal } from '@azure/msal-react'
import { InteractionStatus } from '@azure/msal-browser'
import { sub } from 'date-fns'
import { LoaderFunction, createSearchParams } from 'react-router-dom'
import ConnectAPIClient from 'services/ConnectAPIClient'
import { getAccessToken, getAccessTokenFromPCA } from 'services/MSALService'
import { getOrFetchDeviceDetails } from 'data/DeviceDetailsHook'
import {
  iDeviceLogsAPIParams,
  iGetDeviceLogsParams,
  iGetOrFetchDeviceLogDataProps,
} from 'pages/deviceDetails/commonUtils/interfaces'
import { iDataLoaderParams } from 'data/Interfaces'
import { AxiosError } from 'axios'
import { deviceEventLogsQueryKeyIdDates } from 'services/apis/caching/device'
import { eventLogsUrl } from 'services/apis/urls/device'
import { iDeviceEventLog } from './interface'

interface iDeviceEventLogResponse {
  deviceId: string
  eventLogs: iDeviceEventLog[]
}

async function fetchDeviceEventLogs(
  deviceEventLogParams: iDeviceLogsAPIParams,
): Promise<iDeviceEventLog[]> {
  try {
    const { deviceId, startDate = '', endDate = '', token } = deviceEventLogParams
    const urlSearchParams = new URLSearchParams()
    if (startDate) {
      urlSearchParams.set('FromDate', startDate)
    }
    if (endDate) {
      urlSearchParams.set('ToDate', endDate)
    }
    const url = eventLogsUrl(deviceId, urlSearchParams.toString())
    const resp = await ConnectAPIClient.get<iDeviceEventLogResponse>(url, token)
    const response = resp.data as iDeviceEventLogResponse
    const { eventLogs } = response
    return eventLogs
  } catch (error) {
    console.error(error)
    throw error
  }
}

async function getOrFetchEventLogsData(props: iGetOrFetchDeviceLogDataProps) {
  const { queryClient, token, startDate = '', endDate = '', deviceId } = props
  const eventLogsDataCached = queryClient.getQueryData<iDeviceEventLog[]>(
    deviceEventLogsQueryKeyIdDates({ startDate, endDate, deviceId }),
  )
  if (eventLogsDataCached) return eventLogsDataCached
  const eventLogsDataFetched = await queryClient.fetchQuery(
    deviceEventLogsQueryKeyIdDates({ deviceId, startDate, endDate }),
    async () =>
      fetchDeviceEventLogs({
        startDate,
        endDate,
        token,
        deviceId,
      }),
  )
  return eventLogsDataFetched
}

export async function getEventLogsData(eventLogsDataParams: iGetDeviceLogsParams) {
  const { startDate, endDate, msalContext, redirectPageURL, deviceId } = eventLogsDataParams
  const token = await getAccessToken({ msalContext, redirectPageURL })
  const eventLogsData = await fetchDeviceEventLogs({ startDate, endDate, token, deviceId })
  return eventLogsData
}

export const DeviceEventLogsLoader =
  (props: iDataLoaderParams): LoaderFunction =>
  async ({ request, params }) => {
    const { queryClient, msalInstance, msalAccounts, msalInProgress } = props
    if (!msalInstance) throw new Error('No MSAL Context')
    if (!queryClient) throw new Error('No query client')

    try {
      if (msalInProgress !== InteractionStatus.None) {
        return {}
      }
      if (msalAccounts.length === 0) {
        return {}
      }
      if (!params?.deviceSerialNumber) throw new Error('No device serial number')
      const token = await getAccessTokenFromPCA({ msalInstance })
      const searchParams = createSearchParams(new URLSearchParams(request.url.split('?')[1]))
      const endDate = searchParams.get('to') ?? new Date().toISOString().split('T')[0]
      const startDate =
        searchParams.get('from') ??
        sub(new Date(endDate), { months: 6 }).toISOString().split('T')[0]

      const deviceDetails = await getOrFetchDeviceDetails({
        queryClient,
        token,
        deviceSerialNumber: params?.deviceSerialNumber,
      })
      const deviceId = deviceDetails?.id ?? ''
      const logsData = getOrFetchEventLogsData({
        queryClient,
        token,
        startDate,
        endDate,
        deviceId,
      })
      return logsData
    } catch (error) {
      console.error(error)
      throw error
      // return []
    }
  }

export function useDeviceEventLogsData(
  deviceId: string,
  startDate: string,
  endDate: string,
  redirectPageURL: string,
  enabled?: boolean,
) {
  const msalContext = useMsal()

  return useQuery(
    deviceEventLogsQueryKeyIdDates({ deviceId, startDate, endDate }),
    () =>
      getEventLogsData({
        msalContext,
        redirectPageURL,
        deviceId,
        startDate,
        endDate,
      }),
    {
      enabled,
      onError: (error: AxiosError) => {
        console.log(error)
      },
    },
  )
}

// export const deviceEventLogsQuery = (deviceEventLogParams: iDeviceEventLogAPIParams) => ({
//   queryKey: deviceEventLogsQueryKeyIdDates(deviceEventLogParams),
//   queryFn: async () => {
//     try {
//       const deviceEventLogs = await getDeviceEventLogs(deviceEventLogParams)
//       return deviceEventLogs
//     } catch (error) {
//       console.error(error)
//       throw error
//     }
//   },

//   // retry: 10
// })
