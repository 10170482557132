import React from 'react'
import { SvgProps } from 'types/SVGProps'

export default function ColumnIcon({ fill, width, height }: SvgProps) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width ?? '22'}
      height={height ?? '24'}
      viewBox='0 0 22 24'
      fill='none'
    >
      <path
        d='M8.99999 24L13 24V13.5V0H8.99999V24ZM0 24L3.99999 24V0H0V24ZM18 0V13.3391V24L22 24V12.1122V0H18Z'
        fill={fill ?? 'black'}
      />
    </svg>
  )
}
