/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Children, ReactElement } from 'react'

/**
 * Each is a generic React component that maps over an array of items and renders each item using a provided render function.
 *
 * @component
 * @example
 * const items = ['Apple', 'Banana', 'Cherry'];
 * <Each mapper={items} render={(item, index) => <div key={index}>{item}</div>} />
 *
 * @param {object} props - The properties that define the Each component.
 * @param {T[]} props.mapper - An array of items to be rendered.
 * @param {(item: T, index: number) => JSX.Element} props.render - A function that takes an item and its index and returns a JSX element.
 *
 * @returns {JSX.Element[]} An array of JSX elements, each representing a rendered item from the mapper array.
 */

type EachProps<T> = {
  mapper: T[]
  render: (item: T, index: number, array: T[]) => ReactElement
}

export default function Each<T>({ mapper, render }: EachProps<T>) {
  return (
    <>
      {Children.toArray(
        mapper.map((item: T, index: number, array: T[]) => render(item, index, array)),
      )}
    </>
  )
}
