import React, { useContext, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { UserAccountContext } from 'contexts/UserAccountContext'
import { iAuthenticatedUserInfo } from 'contexts/interfaces'
import { useLocaleList } from 'data/UserLocaleHook'
import { IselectOptionProps } from 'forms/FormModelInterface'
import { UserRoles } from 'utils/Constants'
import { withPageTracking } from 'utils/AppInsightConfig'
import { useGenericEventHandler } from 'data/GenericEventHandler'
import { withErrorBoundary } from 'react-error-boundary'
import ErrorPage from 'pages/common/ErrorPage'
import EditUserSettingsForm from './edit/EditUserSettingsForm'
import { UserSettingsFormType } from './edit/UserSettingsFormInterface'

const GetUserSettingsFormInitialValue = (
  userInfo: iAuthenticatedUserInfo,
  locales: IselectOptionProps[],
) => {
  const initialValue: UserSettingsFormType = {
    Id: userInfo?.user.id,
    FirstName: userInfo?.user.firstName,
    LastName: userInfo?.user.lastName,
    UserEmail: userInfo?.user.emailId,
    BusinessUnit: userInfo?.authority[0]?.businessUnitName,
    RoleName:
      UserRoles.find((r) => r.value === userInfo?.authority[0]?.roleName)?.label ||
      userInfo?.authority[0]?.roleName,
    Language: locales?.find((l) => l.label === userInfo?.user.locale) || {
      label: userInfo?.user.locale,
      value: '',
    },
    TimeZone: userInfo?.user.timeZone,
  }
  return initialValue
}

function UserSettings() {
  const location = useLocation()
  const { state: userInfo } = useContext(UserAccountContext)
  const { genericEventHandler } = useGenericEventHandler()

  const {
    data: localeData,
    // isLoading: localeDataLoading,
    isError: isLocaleDataError,
    error: localeDataError,
    // isSuccess: isLocaleFetchSuccess,
  } = useLocaleList(location.pathname, true)

  useEffect(() => {
    if (isLocaleDataError) {
      genericEventHandler({
        error: localeDataError,
        severity: 'error',
        onlyTrack: true,
        message: localeDataError?.message || 'Error fetching locale data',
        extraData: {
          component: 'UserSettings',
          action: 'fetching locale data',
        },
      })
    }
  }, [isLocaleDataError, localeDataError])

  useEffect(() => {
    document.title = 'Profile - Account'
  }, [])

  const initialValue = GetUserSettingsFormInitialValue(userInfo, localeData || [])

  return (
    <div className='w-full h-full bg-c-light-blue-1 p-5'>
      {/* <div className='h-16 md:h-[82px] lg:h-[92px] w-full flex flex-row bg-c-light-blue-4 sticky top-0 right-0 z-20'>
        <div className='w-full p-[8px]  md:p-5 '>
          <PageTitle title='User Settings' />
        </div>
        <div className='py-4 px-2 md:px-5 lg:p-4'>
          <button
            className='text-2xs md:text-base md:leading-5 text-c-white  font-bold  m-1 p-1 rounded-4xl w-20 h-8 md:w-32 md:h-52 cursor-pointer  bg-c-red-1 outline-0 hover:bg-c-red-2 active:bg-c-red-dark focus:border-4  border-c-red-dark'
            type='button'
            onClick={() => signOutUser()}
          >
            Sign Out
          </button>
        </div>
      </div> */}
      <div className='bg-c-white rounded-3xl shadow'>
        <EditUserSettingsForm initialValue={initialValue} locales={localeData || []} />
      </div>
    </div>
  )
}
export default withErrorBoundary(withPageTracking(UserSettings, 'div', 'h-full'), {
  FallbackComponent: ErrorPage,
})
