export const Stages = {
  SensorConfiguration: 'Sensor Configuration',
  ZeroCalibration: 'Zero Calibration',
  ApplyGas: 'Apply Gas',
  SpanCalibration: 'Span Calibration',
  Complete: 'Complete',
}

export const Status = {
  None: '',
  Working: 'Working',
  End: 'End',
}
export const CalibrationStages = [
  {
    startTitle: Stages.SensorConfiguration,
    status: Status.None,
  },
  {
    startTitle: Stages.ZeroCalibration,
    status: Status.None,
  },
  {
    startTitle: Stages.ApplyGas,
    status: Status.None,
  },
  {
    startTitle: Stages.SpanCalibration,
    status: Status.None,
  },
  {
    startTitle: Stages.Complete,
    status: Status.None,
  },
]
