import { iCustomerList, useCustomerList } from 'data/CustomerListHook'
import { iBusinessUnitDetails } from 'data/Interfaces'
import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useMemo,
  useState,
} from 'react'
import { useLocation } from 'react-router-dom'
import { IsUserGlobalScope } from 'utils/UserDataUtils'
import { useBusinessUnitsListWrapper } from 'data/BusinessUnitsListHookWrapper'
import {
  QueryObserverLoadingErrorResult,
  QueryObserverLoadingResult,
  QueryObserverRefetchErrorResult,
  QueryObserverSuccessResult,
} from '@tanstack/react-query'
import { UserAccountContext } from './UserAccountContext'

type BusinessContextType = {
  customerDataFromQuery?:
    | QueryObserverRefetchErrorResult<iCustomerList[], unknown>
    | QueryObserverSuccessResult<iCustomerList[], unknown>
    | QueryObserverLoadingErrorResult<iCustomerList[], unknown>
    | QueryObserverLoadingResult<iCustomerList[], unknown>
  businessUnitDataFromQuery?:
    | QueryObserverRefetchErrorResult<iBusinessUnitDetails[], unknown>
    | QueryObserverSuccessResult<iBusinessUnitDetails[], unknown>
    | QueryObserverLoadingErrorResult<iBusinessUnitDetails[], unknown>
    | QueryObserverLoadingResult<iBusinessUnitDetails[], unknown>
  selectedBusinessUnit?: iBusinessUnitDetails
  setSelectedBusinessUnit?: Dispatch<SetStateAction<iBusinessUnitDetails | undefined>> // Update the type here
  selectedCustomer?: iCustomerList
  setSelectedCustomer?: Dispatch<SetStateAction<iCustomerList | undefined>>
}
export const BusinessContext = createContext({} as BusinessContextType)

export function BusinessContextProvider({ children }: { children: React.ReactNode }) {
  const location = useLocation()
  const redirectPageURL = `${location.pathname}${location.search}`
  const userAccountInfo = useContext(UserAccountContext)
  const { state: userInfo } = userAccountInfo
  const isGlobalUser = IsUserGlobalScope(userInfo)
  const [selectedCustomer, setSelectedCustomer] = useState<iCustomerList>()
  const [selectedBusinessUnit, setSelectedBusinessUnit] = useState<iBusinessUnitDetails>()
  const customerDataFromQuery = useCustomerList(
    redirectPageURL,
    isGlobalUser,
    Boolean(userInfo?.user?.id) && isGlobalUser,
  )

  const businessUnitDataFromQuery = useBusinessUnitsListWrapper(
    selectedCustomer?.id ?? '',
    redirectPageURL,
    isGlobalUser,
    userInfo?.user?.id !== '',
  )

  const contextValue = useMemo(
    () => ({
      customerDataFromQuery,
      businessUnitDataFromQuery,
      selectedBusinessUnit,
      setSelectedBusinessUnit,
      selectedCustomer,
      setSelectedCustomer,
    }),
    [
      customerDataFromQuery,
      businessUnitDataFromQuery,
      selectedBusinessUnit,
      setSelectedBusinessUnit,
      selectedCustomer,
      setSelectedCustomer,
    ],
  )

  return <BusinessContext.Provider value={contextValue}>{children}</BusinessContext.Provider>
}
