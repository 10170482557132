import clsx from 'clsx'
import Tooltip from 'components/atom/Tooltip'
import React from 'react'

interface UserIdProps {
  userFirstName: string | undefined
  userLastName: string | undefined
  sideNavExpanded: boolean
  isActive: boolean
}

function UserIdentification(userData: UserIdProps): JSX.Element {
  const { userFirstName, userLastName, sideNavExpanded, isActive } = userData
  const initials = `${userFirstName ? userFirstName[0] : ''}${userLastName ? userLastName[0] : ''}`

  return (
    <div className='ml-2 gap-2 flex flex-row items-center justify-start scale-75 lg:scale-100 w-full'>
      <div className=''>
        <svg height='56' width='56'>
          {isActive && <circle cx='28' cy='26' r='24' stroke='#FFFFFF' strokeWidth='4' />}
          <circle cx='28' cy='26' r='21' stroke='#D25062' strokeWidth='1' fill='#D25062' />
          <text
            x='28'
            y='26'
            textAnchor='middle'
            dominantBaseline='middle'
            fill='#FFFFFF'
            className='text-sm font-bold text-c-white text-ellipsis uppercase'
          >
            {initials}
          </text>
        </svg>
      </div>
      {/* <div className="h-11 text-center align-middle  bg-c-pink-2 text-base font-bold text-c-white px-2 py-2 ">{initials}</div> */}

      <div
        className={clsx(
          'w-full flex items-center justify-start duration-500 max-lg:hidden relative ',
          sideNavExpanded ? 'scale-100' : 'scale-0 hidden',
        )}
      >
        <Tooltip
          toolTipClass='tooltip left-0 w-fit -top-8'
          showOnlyWhenTextIsTruncate
          id={`user-name ${userFirstName}  ${userLastName}`}
          tooltipText={`${userFirstName} ${userLastName}`}
          className=' text-ellipsis mr-1 text-4xs font-bold text-c-white max-w-[120px]'
        >
          {userFirstName} {userLastName}
        </Tooltip>
      </div>
    </div>
  )
}

export default UserIdentification
