import { useMsal } from '@azure/msal-react'
import { QueryClient, useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { helpAndSupportDataQueryKey } from 'services/apis/caching/customer'
import { helpAndSupportDataURL } from 'services/apis/urls/customer'
import ConnectAPIClient from 'services/ConnectAPIClient'
import { getAccessToken, getAccessTokenProps } from 'services/MSALService'

interface iHelpAndSupportData {
  customerId: string
  customerName: string
  supportRepoLink: string
  password: string
  customerSupportEmail: string
  techSupportEmail: string
}

interface iFetchHelpAndSupportDataParams {
  token: string
  customerId?: string
}

export interface iAPIHelpAndSupportParams {
  customerId?: string
}

interface iGetOrFetchHelpAndSupportProps {
  queryClient: QueryClient
  token: string
  customerId?: string
}

interface iHelpAndSupportAPIParams extends iAPIHelpAndSupportParams, getAccessTokenProps {}

export async function FetchHelpAndSupportDetails(
  params: iFetchHelpAndSupportDataParams,
): Promise<iHelpAndSupportData> {
  try {
    const { token, customerId } = params
    const url = helpAndSupportDataURL(customerId)
    const resp = await ConnectAPIClient.get<iHelpAndSupportData>(url, token)
    const response = resp.data
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}

export async function getHelpAndSupportData(helpAndSupportAPIParams: iHelpAndSupportAPIParams) {
  const { msalContext, redirectPageURL, customerId } = helpAndSupportAPIParams
  const token = await getAccessToken({ msalContext, redirectPageURL })
  const helpAndSupportData = await FetchHelpAndSupportDetails({ token, customerId })
  return helpAndSupportData
}

export function useHelpAndSupport(customerId?: string, enabled?: boolean) {
  const msalContext = useMsal()

  return useQuery(
    helpAndSupportDataQueryKey(customerId),
    () =>
      getHelpAndSupportData({
        msalContext,
        redirectPageURL: '',
        customerId: customerId || '',
      }),
    {
      enabled,
      select: (resultData) => {
        if (!resultData) {
          throw new Error('Support contacts fetch error..')
        }

        return resultData
      },
      onError: (error: AxiosError) => {
        console.log(error)
      },
    },
  )
}

export async function getOrFetchHelpAndSupportData(props: iGetOrFetchHelpAndSupportProps) {
  const { queryClient, token, customerId } = props

  const helpAndSupportDataCached = queryClient.getQueryData<iHelpAndSupportData>(
    helpAndSupportDataQueryKey(customerId),
  )

  if (helpAndSupportDataCached) {
    return helpAndSupportDataCached
  }

  const helpAndSupportDataFetched = await queryClient.fetchQuery(
    helpAndSupportDataQueryKey(customerId),
    async () =>
      FetchHelpAndSupportDetails({
        token,
        customerId,
      }),
  )

  return helpAndSupportDataFetched
}
