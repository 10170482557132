import React from 'react'
import { SvgProps } from 'types/SVGProps'

function CloseIcon(props: SvgProps): JSX.Element {
  const { stroke, fill, className, toggleClick, height, width } = props

  return (
    <svg
      className={className}
      stroke={stroke ?? '#FFFFFF'}
      strokeWidth={0}
      width={width ?? '20'}
      height={height ?? '20'}
      viewBox='0 0 20 20'
      fill='none'
      onClick={toggleClick}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M16.4352 16.4354C16.8241 16.0465 16.8241 15.4101 16.4352 15.0212L5.12144 3.70751C4.73253 3.3186 4.09614 3.3186 3.70723 3.70751C3.31832 4.09641 3.31832 4.73281 3.70723 5.12172L15.0209 16.4354C15.4098 16.8243 16.0462 16.8243 16.4352 16.4354Z'
        fill={fill ?? '#FFFFFF'}
      />
      <path
        d='M16.435 3.70712C16.0461 3.31821 15.4097 3.31821 15.0208 3.70712L3.70708 15.0208C3.31817 15.4097 3.31817 16.0461 3.70708 16.435C4.09599 16.824 4.73238 16.824 5.12129 16.435L16.435 5.12133C16.8239 4.73243 16.8239 4.09603 16.435 3.70712Z'
        fill={fill ?? '#FFFFFF'}
      />
    </svg>
  )
}

export default CloseIcon
