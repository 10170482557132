import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import { apiBaseURL } from 'utils/AuthConfig'

class ConnectAPIClient {
  private axiosInstance: AxiosInstance

  constructor(baseURL: string, headers?: AxiosRequestConfig['headers']) {
    this.axiosInstance = axios.create({
      baseURL,
      headers,
    })
  }

  public async get<T>(
    url: string,
    token: string,
    version?: string,
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse<T>> {
    this.axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`
    // if (version) {
    //   this.axiosInstance.defaults.headers.common['X-API-Version'] = version
    // } else {
    //   this.axiosInstance.defaults.headers.common['X-API-Version'] = ''
    // }
    return this.axiosInstance.get<T>(url, {
      ...config,
      headers: { ...config?.headers, 'X-API-Version': version },
    })
  }

  public async getNoAuth<T>(
    url: string,
    version?: string,
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse<T>> {
    // if (version) {
    //   this.axiosInstance.defaults.headers.common['X-API-Version'] = version
    // } else {
    //   this.axiosInstance.defaults.headers.common['X-API-Version'] = ''
    // }
    return this.axiosInstance.get<T>(url, {
      ...config,
      headers: { ...config?.headers, 'X-API-Version': version },
    })
  }

  public async delete<T>(
    url: string,
    token: string,
    version?: string,
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse<T>> {
    this.axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`
    if (version) this.axiosInstance.defaults.headers.common['X-API-Version'] = version
    return this.axiosInstance.delete<T>(url, {
      ...config,
      headers: { ...config?.headers, 'X-API-Version': version },
    })
  }

  public async post<T>(
    url: string,
    token: string,
    version?: string,
    data?: T,
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse<T>> {
    this.axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`
    if (version) this.axiosInstance.defaults.headers.common['X-API-Version'] = version
    return this.axiosInstance.post<T>(url, data, {
      ...config,
      headers: { ...config?.headers, 'X-API-Version': version },
    })
  }

  public async put<T>(
    url: string,
    token: string,
    version?: string,
    data?: T,
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse<T>> {
    this.axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`
    if (version) this.axiosInstance.defaults.headers.common['X-API-Version'] = version
    return this.axiosInstance.put<T>(url, data, {
      ...config,
      headers: { ...config?.headers, 'X-API-Version': version },
    })
  }
}

const instance = new ConnectAPIClient(apiBaseURL as string)

export default instance
