import React from 'react'
import { IFilterValues, DateRange } from 'forms/FormModelInterface'
import { FormatDateRange } from 'exportReports/ExportDataUtils'
import { DevicePageColumnIds } from '../view/DevicesPageUtils'

const DeviceReportFilterLookup = [
  { columId: DevicePageColumnIds.serialNumber, label: 'Serial #', format: (val: string) => val },
  { columId: DevicePageColumnIds.assetNumber, label: 'Asset #', format: (val: string) => val },
  {
    columId: DevicePageColumnIds.businessUnit,
    label: 'Business unit',
    format: (val: string) => val,
  },
  { columId: DevicePageColumnIds.deviceStatus, label: 'Status', format: (val: string) => val },
  { columId: DevicePageColumnIds.deviceType, label: 'Device', format: (val: string) => val },
  { columId: DevicePageColumnIds.deviceUser, label: 'Assigned', format: (val: string) => val },
  {
    columId: DevicePageColumnIds.lastSwitchOn,
    label: 'Last on',
    format: (val: DateRange) => FormatDateRange(val),
  },
  {
    columId: DevicePageColumnIds.lastSync,
    label: 'Synced',
    format: (val: DateRange) => FormatDateRange(val),
  },
]

const mapColumnLabel = (filterVal: IFilterValues) => {
  const labelToReturn = DeviceReportFilterLookup.find(
    (l) => l.columId === filterVal.columnId,
  )?.label
  return labelToReturn
}

const mapColumnValue = (filterVal: any) => {
  const valueToReturn = DeviceReportFilterLookup.find(
    (l) => l.columId === filterVal.columnId,
  )?.format(filterVal?.value || '')
  return valueToReturn
}

function DeviceFilterFormat(filterValues: IFilterValues[], customer: string, mainBu: string) {
  let summary = `Customer: ${customer}, Global business unit: ${mainBu}\n`
  filterValues?.forEach((f) => {
    if (f.value)
      summary = `${`${summary} ${mapColumnLabel(f)}`}: ${f.value ? mapColumnValue(f) : ''},`
  })

  return summary.replace(/,\s*$/, '')
}

export default DeviceFilterFormat
