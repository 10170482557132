import { AccountInfo } from '@azure/msal-browser'
import { useMsal } from '@azure/msal-react'
import Timer from 'components/atom/Timer'
import CrowconButton from 'components/miscComponents/CrowconButton'
import React, { useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import MultipleAccounts from './MultipleAccounts'
import { RedirectProps } from './SignUpPage'

/**
 * This is a functional component in React that handles redirection for users who are logging in.
 */
export default function LoginRedirect({ userInviteData }: RedirectProps) {
  // Using the Microsoft Authentication Library (MSAL) instance for authentic action operations
  const { instance } = useMsal()
  // Using the navigate hook from react-router to programmatically navigate through the application
  const navigate = useNavigate()
  // State variable for storing the account found in cache, if any
  const [accountFoundInCache, setAccountFoundInCache] = React.useState<AccountInfo>()
  // State variable for managing the navigation for multiple login
  const [multipleAccount, setMultipleAccount] = React.useState<boolean>(false)

  // Function to manage redirection based on whether an account was found in cache
  const manageRedirect = useCallback(() => {
    if (accountFoundInCache) {
      instance.setActiveAccount(accountFoundInCache)
      navigate('/')
    } else {
      setMultipleAccount(true)
    }
  }, [accountFoundInCache])

  // Effect hook to check if userInviteData is present and if an account exists in cache
  useEffect(() => {
    if (userInviteData) {
      const cachedAccounts: AccountInfo[] = instance.getAllAccounts()
      if (cachedAccounts.length > 0) {
        const haveLinkAccount = cachedAccounts.find(
          (account) => account.idTokenClaims?.email === userInviteData.emailId,
        )
        setAccountFoundInCache(haveLinkAccount)
      }
    }
  }, [userInviteData])

  // If user has multiple account, redirect to multiple account page
  if (multipleAccount) {
    return <MultipleAccounts userInviteData={userInviteData} />
  }
  // The component returns a JSX that displays a welcome message to the user and a countdown to redirection
  return (
    <div className='mx-auto text-center text-c-dark-blue-1 w-screen md:w-max p-2 md:p-0'>
      <div className='text-base font-bold font-poppins text-center md:text-left tracking-tight text-c-dark-blue-1 leading-[20px]'>
        Welcome {userInviteData?.firstName} {userInviteData?.lastName}
      </div>
      <p className='mx-auto mt-4 max-w-xl text-center md:text-left text-sm leading-5 text-c-dark-blue-1 '>
        Welcome back to Crowcon Connect.
      </p>

      <p className='mx-auto mt-2.5 max-w-xl text-center md:text-left text-sm leading-6 text-c-dark-blue-1'>
        Your account for Crowcon Connect is already setup using your email address{' '}
        <br className='md:hidden' />
        <span className='text-c-dark-blue-4 font-semibold'>{userInviteData?.emailId}</span>.
      </p>

      <p className='mx-auto mt-6 max-w-xl text-base leading-6 text-c-dark-blue-1 font-bold text-center'>
        Redirecting to {accountFoundInCache ? 'Crowcon Connect App ' : 'login page '} in{' '}
        <Timer countdown={5} callback={manageRedirect} /> seconds
      </p>

      <p className='mx-auto mt-1 max-w-xl text-xs leading-4 text-c-light-gray-1 text-center'>
        Or you can change the account by clicking the button below
      </p>

      <div className='mt-6 items-center  justify-center gap-x-10'>
        <CrowconButton
          additionalClassName='text-base mb-4 bg-c-dark-blue-1 w-full h-12 rounded-3xl'
          buttonChildren={<>Login</>}
          buttonAction={() => setMultipleAccount(true)}
        />
      </div>
      <p className='mx-auto mt-1 text-center md:text-left max-w-xl text-xs leading-4 text-c-light-gray-1'>
        If you are facing ant issue, please contact your administrator or{' '}
        <a
          href='mailto:techsupport@crowcon.com'
          className=' leading-6 text-c-dark-blue-4 hover:text-indigo-500'
        >
          Crowcon support
        </a>
      </p>
    </div>
  )
}
