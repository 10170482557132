import React from 'react'
import { AlertIcon } from 'assets/icons'

function TableNoDataFound(): JSX.Element {
  return (
    <div className='flex flex-col justify-center items-center'>
      <div>
        <AlertIcon />
      </div>
      <div className='text-center font-bold tracking-tight text-black text-3xl'>
        No Data Found
      </div>
    </div>
  )
}

export default TableNoDataFound
