import React, { useEffect } from 'react'

import { DashboardDataSmall } from 'components/viewComponents'
import { IFilterValues } from 'forms/FormModelInterface'
import clsx from 'clsx'
import { AlarmIcon } from 'assets/icons'
import Show from 'components/atom/Show'
import Each from 'components/atom/Each'
import { iWidgetProps } from './interfaces'

function WidgetPlaceholder({ widgetLabelCount }: { widgetLabelCount: number }) {
  const fixedArray = Array.from(Array(widgetLabelCount || 4).keys())
  const [showLoader, setShowLoader] = React.useState(true)
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLoader(false)
    }, 5000)

    return () => {
      clearTimeout(timer)
    }
  }, [])

  if (showLoader) {
    return (
      <div className='grid grid-cols-1 gap-1'>
        <Each
          mapper={fixedArray}
          render={(item) => (
            <div className='animate-pulse' key={item}>
              <DashboardDataSmall LabelOriginalData={' '} />
            </div>
          )}
        />
      </div>
    )
  }
  return (
    <div className='flex flex-col justify-center items-center p-2'>
      <AlarmIcon fill='black' className='w-10 h-10' />
      <div className='text-center my-2 font-semibold'>No Data Found</div>
    </div>
  )
}

function GenericWidget({ widgetProps }: { widgetProps: iWidgetProps }): JSX.Element {
  const {
    widgetData,
    title,
    widgetLabelCount,
    onFilter,
    gridContainerClassName = '',
    titleContainerClassName = '',
    titleTextClassName = '',
    cardContainerClassName = '',
  } = widgetProps

  // const onFilterData = useCallback((labelValue: string) => {
  //   // const filterRequestValue: IFilterValues[] = []
  //   // filterRequestValue.push({
  //   //   columnId: columnId ?? '',
  //   //   value: labelValue ?? '',
  //   // })
  //   if (onFilter) {
  //     onFilter(labelValue)
  //   }
  // }, [columnId, onFilter])

  return (
    <div
      className={
        cardContainerClassName ||
        'flex flex-col lg:flex-wrap bg-c-white rounded-2xl py-18 px-2 md:px-18 h-full w-full '
      }
    >
      <div
        className={
          titleContainerClassName || 'flex flex-row justify-between items-center pb-[10px] h-auto '
        }
      >
        <div className={titleTextClassName || 'text-sm  sm:text-base font-bold sm:leading-5'}>
          {title}
        </div>
        {/* <div className='text-xs font-bold leading-5'>Expand</div> */}
      </div>

      {/* <div className='flex flex-col flex-wrap gap-y-[5px] gap-x-3'> */}
      <Show>
        <Show.When isTrue={Boolean(widgetData && widgetData.length > 0)}>
          <div
            className={clsx(
              {
                'grid-cols-1 lg:grid-cols-2 gap-x-3 gap-y-1 h-auto': !gridContainerClassName,
              },
              `grid ${gridContainerClassName || ''} `,
            )}
          >
            <Each
              mapper={widgetData || []}
              render={(labelData) => (
                <div className={labelData.gridItemClassName} key={labelData.displayText}>
                  <DashboardDataSmall
                    Label={labelData.displayText}
                    LabelOriginalData={labelData.originalData}
                    Data={labelData.value}
                    className={labelData.className}
                    filteredClassName={labelData.filteredClassName}
                    // onClick={(labelValue : string) => onFilterData(labelValue)}
                    // onClick={
                    //   (labelClickData) => {
                    //     onFilterData(labelClickData)
                    //   }
                    // }

                    onClick={(filterRequestValues: IFilterValues[]) =>
                      onFilter && onFilter(filterRequestValues)
                    }
                    isFiltered={labelData.isFiltered}
                    columnId={labelData.columnId}
                  />
                </div>
              )}
            />
          </div>
        </Show.When>
        <Show.Otherwise>
          <WidgetPlaceholder widgetLabelCount={widgetLabelCount} />
        </Show.Otherwise>
      </Show>
    </div>
  )
}

export default GenericWidget
