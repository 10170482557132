import React, { useContext, useEffect, useState } from 'react'
import CrowconButton from 'components/miscComponents/CrowconButton'
import { useForm, Controller, SubmitHandler, FieldErrors } from 'react-hook-form'
import { AlertIcon, CloseIcon, Spinner, TickIcon } from 'assets/icons'
import TextInputControl from 'components/formComponents/TextInputControl'
import SelectControl2 from 'components/formComponents/SelectControl2'
import { allCustomerOption, iCustomerList, useCustomerList } from 'data/CustomerListHook'
import { UserAccountContext } from 'contexts/UserAccountContext'
import { iBusinessUnitDetails } from 'components/commonComponents/BusinessUnitDataUtils'
import { IsUserGlobalScope } from 'utils/UserDataUtils'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useLocation, useSearchParams } from 'react-router-dom'
import { getAccessTokenProps } from 'services/MSALService'
import { useMsal } from '@azure/msal-react'
import clsx from 'clsx'
import { iAddOperatorDTO, addOperator } from 'pages/operators/data/AddOperatorData'
import _ from 'lodash'
import {
  allBusinessUnitOption,
  useBusinessUnitsListWrapper2,
} from 'data/BusinessUnitsListHookWrapper'
import { useGenericEventHandler } from 'data/GenericEventHandler'
import { operatorMutationKey } from 'services/apis/caching/operators'
import { CreateOperatorFormType } from '../CreateOperatorFormType'

export interface CreateOperatorFormProps {
  maxStep: number
  formStep: number
  nextFormStep: () => void
  confirmCancel: () => void
}

const defaultFormValues: CreateOperatorFormType = {
  FirstName: '',
  LastName: '',
  OperatorID: '',
  BusinessUnit: null,
  Customer: null,
}
function CreateOperatorForm(props: CreateOperatorFormProps) {
  const { maxStep, formStep, confirmCancel, nextFormStep } = props
  const onError = () => {
    console.log('Error..')
  }

  const msalContext = useMsal()
  const location = useLocation()
  const queryClient = useQueryClient()
  const [searchParams, setSearchParams] = useSearchParams()
  const redirectPageURL = `${location.pathname}${location.search}`

  const userAccount = useContext(UserAccountContext)
  const { state: userInfo } = userAccount
  const isGlobalUser = IsUserGlobalScope(userInfo)

  const addOperatorMutation = useMutation({
    mutationKey: operatorMutationKey(),
    mutationFn: (operatorInfo: CreateOperatorFormType) => {
      const operatorToAdd: iAddOperatorDTO = {
        firstName: operatorInfo.FirstName,
        lastName: operatorInfo.LastName,
        customerId: isGlobalUser
          ? operatorInfo.Customer?.id || ''
          : userInfo?.user?.customerId || '',
        businessUnitId: operatorInfo.BusinessUnit?.id || '',
        uniqueId: operatorInfo.OperatorID,
        trainingDetails: [],
      }

      const accessTokenProps: getAccessTokenProps = {
        msalContext,
        redirectPageURL,
      }

      const mutationResult = addOperator({ operatorToAdd, accessTokenProps })
      return mutationResult
    },
    onSuccess: async (operator: any) => {
      console.log('Operator added successfully')
      queryClient.invalidateQueries({
        queryKey: ['operators', 'list'],
      })
      setTimeout(() => {
        confirmCancel()
      }, 500)
    },
    onError: (error: any) => {
      console.log('Error adding operator')
    },
  })

  const {
    isLoading: isAddOperatorMutationLoading,
    isError: isAddOperatorMutationError,
    error: addOperatorMutationError,
    isSuccess: isAddperatorMutationSuccess,
  } = addOperatorMutation

  const onSubmit: SubmitHandler<CreateOperatorFormType> = (
    operatorInfo: CreateOperatorFormType,
  ) => {
    addOperatorMutation.mutate(operatorInfo)
  }
  const {
    register,
    handleSubmit,
    watch,
    reset,
    control,
    setValue,
    getValues,
    formState: {
      errors,
      isDirty,
      isSubmitting,
      isValid,
      isValidating,
      touchedFields,
      isSubmitSuccessful,
      isSubmitted,
      dirtyFields,
    },
  } = useForm<CreateOperatorFormType>({
    defaultValues: defaultFormValues,
    // values: formValues,
    criteriaMode: 'all',
    shouldFocusError: true,
    mode: 'onTouched',
  })
  const formWatch = watch()
  const { genericEventHandler } = useGenericEventHandler()
  const [customerListData, setCustomerListData] = useState<iCustomerList[]>([])
  const [businessUnitsListData, setBusinessUnitsListData] = useState<iBusinessUnitDetails[]>([])
  const [selectedCustomer, setSelectedCustomer] = React.useState<string>()
  const [selectedMainBU, setSelectedMainBU] = React.useState<string>()

  const selectedCustomerParam: string = isGlobalUser
    ? searchParams.get('c')
      ? searchParams.get('c')?.toLowerCase() === 'all'
        ? ''
        : searchParams.get('c') ?? ''
      : ''
    : ''
  const selectedMainBUParam: string = searchParams.get('mainbu')
    ? searchParams.get('mainbu')?.toLowerCase() === 'all'
      ? ''
      : searchParams.get('mainbu') ?? ''
    : ''
  const {
    data: customerList,
    isLoading: isCustomerListLoading,
    isError: isCustomerListError,
    error: customerListError,
  } = useCustomerList(redirectPageURL, isGlobalUser, userInfo?.user?.id !== '' && isGlobalUser)

  useEffect(() => {
    if (customerList) {
      const customerListModified = [...customerList]
      _.remove(customerListModified, (c) => c.id === allCustomerOption.id)
      setCustomerListData(customerListModified)

      if (selectedCustomerParam === '') {
        setValue('Customer', customerListModified[0])
      } else {
        const selCustomer = customerListModified.find((c) => c.id === selectedCustomerParam)
        if (selCustomer) {
          setValue('Customer', selCustomer)
        }
      }
    }
  }, [customerList])

  const {
    data: businessUnitsList,
    isLoading: isBusinessUnitsListLoading,
    isError: isBusinessUnitsListError,
    error: businessUnitsListError,
  } = useBusinessUnitsListWrapper2(
    isGlobalUser ? formWatch.Customer?.id || '' : '',
    redirectPageURL,
    isGlobalUser,
    userInfo?.user?.id !== '' && Boolean(formWatch.Customer?.id) && !isCustomerListLoading,
  )

  useEffect(() => {
    if (isCustomerListError) {
      genericEventHandler({
        onlyTrack: true,
        severity: 'error',
        error: customerListError,
        message: customerListError?.message || 'Error while fetching customer list',
        extraData: {
          component: 'Help',
          action: 'fetching customer list',
        },
      })
    }
    if (isBusinessUnitsListError) {
      genericEventHandler({
        onlyTrack: true,
        severity: 'error',
        error: businessUnitsListError,
        message: businessUnitsListError?.message || 'Error while fetching business unit list',
        extraData: {
          component: 'Help',
          action: 'fetching business unit list',
        },
      })
    }
  }, [isCustomerListError, customerListError, isBusinessUnitsListError, businessUnitsListError])

  useEffect(() => {
    if (businessUnitsList) {
      const businessUnitsListModified = [...businessUnitsList]
      _.remove(businessUnitsListModified, (c) => c.id === allBusinessUnitOption.id)
      setBusinessUnitsListData(businessUnitsListModified)
      if (selectedMainBUParam === '') {
        setValue('BusinessUnit', businessUnitsListModified[0])
      } else {
        const selBU = businessUnitsListModified.find((c) => c.id === selectedMainBUParam)
        if (selBU) {
          setValue('BusinessUnit', selBU)
        }
      }
    }
  }, [businessUnitsList, selectedCustomer])

  return (
    <div className='py-4 px-6'>
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <div className='flex flex-col '>
          <div
            id='add-operator-form-header'
            className='flex flex-row justify-center items-center mb-6'
          >
            <div className='w-full font-bold text-base leading-5'>Add Operator</div>
            <div
              role='button'
              tabIndex={0}
              onClick={() => null}
              onKeyDown={() => null}
              className='flex justify-center items-center bg-c-dark-blue-1 rounded-full p-2'
            >
              <CloseIcon toggleClick={() => confirmCancel()} className='w-6 h-6 ' />
            </div>
          </div>

          <div id='add-operator-form'>
            <form noValidate onSubmit={handleSubmit(onSubmit, onError)} className='w-full'>
              <div id='-operator-form-elements-buttons' className='flex flex-col'>
                {formStep === 0 && (
                  <div id='add-operator-form-elements'>
                    <div
                      id='add-operator-form-elements'
                      className='flex flex-col gap-y-2 items-start'
                    >
                      <div id='add-operator-sr-name' className='flex flex-row w-full gap-2'>
                        <div className='flex flex-col gap-0.5 items-start w-full'>
                          <label
                            htmlFor='FirstName'
                            className='pl-[14px] text-xs font-bold leading-5 text-c-dark-blue-1 '
                          >
                            First Name
                          </label>
                          <Controller
                            name='FirstName'
                            control={control}
                            rules={{
                              required: 'First name is required',
                            }}
                            render={({ field }) => (
                              <TextInputControl
                                className='w-full h-[42px]'
                                id='firstName'
                                elementProps={{
                                  value: field.value,
                                  defaultValue: field.value,
                                  placeholder: '',
                                  onChange: (selectedOption: string) => {
                                    field.onChange(selectedOption)
                                  },
                                  onBlur: () => {
                                    field.onBlur()
                                  },
                                }}
                              />
                            )}
                          />
                          <p className='pl-2 text-3xs text-red-500'>{errors.FirstName?.message}</p>
                        </div>

                        <div className='flex flex-col gap-0.5 items-start w-full'>
                          <label
                            htmlFor='LastName'
                            className='pl-[14px] text-xs font-bold leading-5 text-c-dark-blue-1 '
                          >
                            Last Name
                          </label>
                          <Controller
                            name='LastName'
                            control={control}
                            rules={{
                              required: 'Last name is required',
                            }}
                            render={({ field }) => (
                              <TextInputControl
                                className='w-full h-[42px]'
                                id='lastName'
                                elementProps={{
                                  value: field.value,
                                  defaultValue: field.value,
                                  placeholder: '',
                                  onChange: (selectedOption: string) => {
                                    field.onChange(selectedOption)
                                  },
                                  onBlur: () => {
                                    field.onBlur()
                                  },
                                }}
                              />
                            )}
                          />
                          <p className='pl-2 text-3xs text-red-500'>{errors.LastName?.message}</p>
                        </div>
                      </div>
                      <div className='flex flex-col gap-0.5 items-start self-stretch'>
                        <label
                          htmlFor='operatorID'
                          className='pl-[14px] text-xs font-bold leading-5 text-c-dark-blue-1 '
                        >
                          Operator ID
                        </label>
                        <Controller
                          name='OperatorID'
                          control={control}
                          render={({ field }) => (
                            <TextInputControl
                              className='w-full h-[42px]'
                              id='operatorID'
                              elementProps={{
                                value: field.value,
                                defaultValue: field.value,
                                placeholder: '-',
                                onChange: (selectedOption: string) => {
                                  field.onChange(selectedOption)
                                },
                              }}
                            />
                          )}
                        />
                        <p className='pl-2 text-3xs text-red-500'>{errors.OperatorID?.message}</p>
                      </div>

                      {isGlobalUser && (
                        <div className='flex flex-col gap-0.5 items-start self-stretch'>
                          <label
                            htmlFor='customer'
                            className='pl-[14px] text-xs font-bold leading-5 text-c-dark-blue-1 '
                          >
                            Customer
                          </label>

                          <Controller
                            name='Customer'
                            control={control}
                            rules={{ required: true }}
                            render={({ field }) => (
                              <SelectControl2
                                className='w-full h-[42px]'
                                selectControlProps={{
                                  isLoading: isCustomerListLoading,
                                  options: customerListData,
                                  getOptionLabel: (option: iCustomerList) => option.name,
                                  getOptionvalue: (option: iCustomerList) => option.id,
                                  value: field.value,
                                  defaultValue: field.value,
                                  isMulti: false,
                                  isSearchable: true,
                                  isDropDownSelectable: true,
                                  openMenuOnClick: true,
                                  placeholder: 'Select customer',
                                  isClearable: false,
                                  onChange: (selectedOption: iCustomerList) => {
                                    field.onChange(selectedOption)
                                    setSelectedCustomer(selectedOption.id)
                                  },
                                }}
                              />
                            )}
                          />
                          <p className='pl-2 text-3xs text-red-500'>{errors.Customer?.message}</p>
                        </div>
                      )}

                      <div className='flex flex-col gap-0.5 items-start self-stretch'>
                        <label
                          htmlFor='businessunit'
                          className='pl-[14px] text-xs font-bold leading-5 text-c-dark-blue-1'
                        >
                          Business Unit
                        </label>
                        <Controller
                          name='BusinessUnit'
                          control={control}
                          rules={{ required: true }}
                          render={({ field }) => (
                            <SelectControl2
                              className='w-full h-[42px]'
                              selectControlProps={{
                                options: businessUnitsListData,
                                isLoading: isBusinessUnitsListLoading,
                                getOptionLabel: (option: iBusinessUnitDetails) => option.name,
                                getOptionvalue: (option: iBusinessUnitDetails) => option.id,
                                value: field.value,
                                defaultValue: field.value,
                                isMulti: false,
                                isSearchable: true,
                                isDropDownSelectable: true,
                                openMenuOnClick: true,
                                placeholder: 'Select business unit',
                                isClearable: false,
                                onChange: (selectedOption: iBusinessUnitDetails) => {
                                  field.onChange(selectedOption)
                                },
                              }}
                            />
                          )}
                        />
                        <p className='pl-2 text-3xs text-red-500'>{errors.BusinessUnit?.message}</p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </form>
          </div>
        </div>
        <div className='mt-4 py-4 flex flex-row gap-4'>
          <CrowconButton
            useDefaultTextColour={false}
            additionalClassName='text-base text-c-dark-blue-1  font-bold mt-1 bg-c-light-blue-2 hover:text-white h-12  w-28 rounded-3xl'
            buttonChildren={<span>Cancel</span>}
            buttonAction={() => confirmCancel()}
          />
          <button
            type='submit'
            className={clsx(
              'w-56 h-[48px] mt-1 px-3 rounded-3xl  bg-c-dark-blue-1  outline-0  focus:bg-c-dark-blue-4   hover:bg-c-dark-blue-2  active:bg-c-dark-blue-3  disabled:bg-c-light-blue-2 disabled:text-c-dark-blue-1',
              !isDirty || !isValid
                ? 'text-c-dark-blue-1 bg-c-light-blue-2 hover:text-c-white'
                : 'text-c-white bg-c-dark-blue-1 ',
            )}
            disabled={!isDirty || !isValid || isSubmitting}
          >
            <div className='px-3 md:px-5 flex flex-row  gap-1 justify-center items-center'>
              {isAddOperatorMutationLoading && <Spinner className='w-5 h-5' />}
              {isAddperatorMutationSuccess && <TickIcon className='w-5 h-5' />}
              {isAddOperatorMutationError && <AlertIcon fill='#ff0000' className='w-5 h-5 ' />}
              <div
                className={clsx(
                  'flex-grow text-base font-bold leading-5 ',
                  !isDirty || !isValid ? 'text-c-dark-blue-1' : 'text-c-white ',
                )}
              >
                Add
              </div>
            </div>
          </button>
        </div>
        {isAddOperatorMutationError && (
          <div className='text-xs text-red-500 font-normal pt-2 pl-2'>
            {addOperatorMutationError?.message ?? ''}
          </div>
        )}
      </form>
    </div>
  )
}

export default CreateOperatorForm
