import React from 'react'
import { SvgProps } from 'types/SVGProps'

function DropDownIcon(props: SvgProps): JSX.Element {
  const { className, height, width } = props

  return (
    <div>
      <svg
        className={className}
        width={width ?? 20}
        height={height ?? 20}
        viewBox='0 0 20 20'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g id='Icons Small'>
          <path
            id='Vector 107'
            d='M13 9L10 12L7 9'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </g>
      </svg>
    </div>
  )
}

export default DropDownIcon
