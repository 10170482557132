import React, { useState, ReactNode } from 'react'
/**
 * Tooltip is a React component that displays a tooltip text when hovered over or focused.
 *
 * @component
 * @example
 * <Tooltip tooltipText="This is a tooltip">
 *   <button>Hover over me</button>
 * </Tooltip>
 *
 * @param {object} props - The properties that define the Tooltip component.
 * @param {ReactNode} props.tooltipText - The text or ReactNode to be displayed in the tooltip.
 * @param {ReactNode} props.children - The children over which the tooltip should be displayed.
 * @param {boolean} [props.shouldShow=true] - Whether the tooltip should be displayed.
 * @param {string} [props.className=''] - The CSS class to be applied to the tooltip container.
 * @param {string} [props.toolTipClass=''] - The CSS class to be applied to the tooltip itself.
 * @param {boolean} [props.showOnlyWhenTextIsTruncate=false] - Whether the tooltip should be displayed only when the text is truncated.
 * @param {React.HTMLProps<HTMLDivElement>} rest - Any other props to be passed to the tooltip container.
 *
 * @returns {ReactElement} A div element that displays the tooltip when hovered over or focused.
 */
interface TooltipProps extends React.HTMLProps<HTMLDivElement> {
  tooltipText: ReactNode
  children: ReactNode
  shouldShow?: boolean
  className?: string
  toolTipClass?: string
  showOnlyWhenTextIsTruncate?: boolean
}
function Tooltip({
  tooltipText,
  children,
  shouldShow = true,
  className = '',
  toolTipClass = '',
  showOnlyWhenTextIsTruncate = false,
  ...rest
}: TooltipProps) {
  const [showTooltip, setShowTooltip] = useState(false)
  // Function to check if the text in an element is truncated
  const isEllipsisActive = (e: HTMLElement | null) => {
    // If the element exists
    if (e) {
      // Return true if the element's content is wider than its width (i.e., the text is truncated)
      return e.offsetWidth < e.scrollWidth
    }
    // If the element does not exist, return false
    return false
  }

  // Function to handle the mouse over event
  const showToolTipHandler = () => {
    // If the tooltip should only be shown when the text is truncated
    if (showOnlyWhenTextIsTruncate) {
      // If an id is provided
      if (rest?.id) {
        // Get the element with the provided id
        const ellipsisText = document.getElementById(rest?.id)
        // Show the tooltip if the text in the element is truncated
        setShowTooltip(isEllipsisActive(ellipsisText))
      }
    } else {
      // If the tooltip should always be shown, show the tooltip
      setShowTooltip(true)
    }
  }

  // Function to handle the mouse out event
  const hideTooltipHandler = () => {
    // Hide the tooltip
    setShowTooltip(false)
  }

  return (
    <div
      onMouseOver={showToolTipHandler}
      onMouseOut={hideTooltipHandler}
      onFocus={showToolTipHandler}
      onBlur={hideTooltipHandler}
      className={className}
      {...rest}
    >
      {children}
      {shouldShow && tooltipText && showTooltip && (
        <div className={toolTipClass ?? ''}>{tooltipText}</div>
      )}
    </div>
  )
}
Tooltip.defaultProps = {
  toolTipClass: '',
  shouldShow: true,
  className: '',
  showOnlyWhenTextIsTruncate: false,
}
export default Tooltip
