import { DateRange, IFilterQueryParamValues, IselectOptionProps } from 'forms/FormModelInterface'

export const OperatorsPageColumnIds = {
  fullName: 'fullName',
  devicesInUse: 'devicesInUse',
  businessUnit: 'businessUnit',
  email: 'email',
  operatorId: 'uniqueId',
  status: 'status',
}

export const OperatorsPageParamLabels = {
  name: 'name',
  businessUnit: 'cbu',
  devicesInUse: 'diu',
  operatorId: 'oid',
  status: 'status',
}

export const OperatorsPageFilterParamValues: IFilterQueryParamValues[] = [
  { paramName: OperatorsPageParamLabels.name, paramValue: null },
  { paramName: OperatorsPageParamLabels.businessUnit, paramValue: null },
  { paramName: OperatorsPageParamLabels.devicesInUse, paramValue: null },
  { paramName: OperatorsPageParamLabels.operatorId, paramValue: null },
  { paramName: OperatorsPageParamLabels.status, paramValue: null },
]

export type OperatorsPageFilterFormType = {
  fullname: IselectOptionProps
  businessUnit: IselectOptionProps
  operatorId: IselectOptionProps
  devicesInUse: IselectOptionProps
  status: IselectOptionProps
}
