import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js'
// eslint-disable-next-line import/no-extraneous-dependencies
import { createBrowserHistory } from 'history'
import { InstrumentationKey } from './AuthConfig'

const browserHistory = createBrowserHistory()
export const reactPlugin = new ReactPlugin()
// *** Add the Click Analytics plug-in. ***
/* var clickPluginInstance = new ClickAnalyticsPlugin();
   var clickPluginConfig = {
     autoCapture: true
}; */

export enum SeverityLevel {
  Verbose = 0,
  Information = 1,
  Warning = 2,
  Error = 3,
  Critical = 4,
}
export const appInsights = new ApplicationInsights({
  config: {
    connectionString: InstrumentationKey,
    enableAutoRouteTracking: true,
    // *** If you're adding the Click Analytics plug-in, delete the next line. ***
    extensions: [reactPlugin],
    // *** Add the Click Analytics plug-in. ***
    // extensions: [reactPlugin, clickPluginInstance],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory },
      // *** Add the Click Analytics plug-in. ***
      // [clickPluginInstance.identifier]: clickPluginConfig
    },
  },
})

export const withPageTracking = (
  Component: React.ComponentType<unknown>,
  componentName?: string,
  className?: string,
) => withAITracking(reactPlugin, Component, componentName, className)
