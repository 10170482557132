import React from 'react'

const daysOfWeek = [
  { short: 'M', full: 'on' },
  { short: 'T', full: 'ue' },
  { short: 'W', full: 'ed' },
  { short: 'T', full: 'hu' },
  { short: 'F', full: 'ri' },
  { short: 'S', full: 'at' },
  { short: 'S', full: 'un' },
]

export default function CalenderWeek() {
  return (
    <div className='grid grid-cols-7 gap-px border-y border-gray-300 bg-gray-200 text-center text-xs font-semibold leading-6 text-gray-700 flex-none'>
      {daysOfWeek.map((day) => (
        <div key={day.full} className='bg-white py-2'>
          {day.short}
          <span className='sr-only sm:not-sr-only'>{day.full}</span>
        </div>
      ))}
    </div>
  )
}
