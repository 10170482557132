import React from 'react'
import { SvgProps } from 'types/SVGProps'

function DeclineIcon(props: SvgProps): JSX.Element {
  const { stroke, fill, className, toggleClick } = props

  return (
      <svg
        className={className}
        width='20'
        height='20'
        viewBox='0 0 20 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M10 0C4.5 0 0 4.5 0 10C0 15.5 4.5 20 10 20C15.5 20 20 15.5 20 10C20 4.5 15.5 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18Z'
          fill={fill ?? '#CEDCEB'}
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M8.62868 10.0429L5.58579 7L7 5.58579L10.0429 8.62868L13.0858 5.58579L14.5 7L11.4571 10.0429L14.5 13.0858L13.0858 14.5L10.0429 11.4571L7 14.5L5.58579 13.0858L8.62868 10.0429Z'
          fill={fill ?? '#CEDCEB'}
        />
      </svg>
  )
}

export default DeclineIcon
