import React, { useEffect, useState, memo } from 'react'
import { useForm, SubmitHandler, Controller, FieldErrors } from 'react-hook-form'
import { DateRange, IFilterValues, IselectOptionProps } from 'forms/FormModelInterface'
// eslint-disable-next-line import/no-extraneous-dependencies
import { DevTool } from '@hookform/devtools'
import { sub } from 'date-fns'

import {
  DateRangeSelectorTypes,
  OperatorDeviceCountTypes,
  SelectOptionTypes,
} from 'utils/CommonEnums'
import { ChevronRightIcon, CloseIcon } from 'assets/icons'
import { DatePickerControl, SelectControl, CreatableSelectControl } from 'components/formComponents'
import { OperatorDeviceCount } from 'utils/Constants'

import FilterFormTitle from 'components/formComponents/FilterFormTitle'
import { iOperatorInfo } from 'data/OperatorListHook'
import { dateRangeOptions, selectAllOption, selectBlankOption } from 'forms/FormUtils'
import { withErrorBoundary } from 'react-error-boundary'
import ErrorPage from 'pages/common/ErrorPage'
import { OperatorsPageColumnIds, OperatorsPageFilterFormType } from './OperatorsPageUtils'

import { PrepareDeviceTypeOptionsForSelect } from '../data/OperatorsDataUtils'

interface OperatorsPageFilterFormProps {
  selectedFilterValues?: OperatorsPageFilterFormType
  optionsData: iOperatorInfo[]
  onFilterSubmit: (filterValues: IFilterValues[]) => void
  onFilterReset: () => void
  onFilterFormClose: () => void
}

// fullname: IselectOptionProps
// businessUnit: IselectOptionProps
// operatorId: IselectOptionProps
// devicesInUse: IselectOptionProps

const defaultFilterValues = () => {
  const defValues: OperatorsPageFilterFormType = {
    fullname: selectBlankOption,
    businessUnit: selectAllOption,
    operatorId: selectAllOption,
    status: selectAllOption,
    devicesInUse: OperatorDeviceCount.find(
      (device) => device.value === OperatorDeviceCountTypes.All,
    ) || {
      value: OperatorDeviceCountTypes.All,
      label: OperatorDeviceCountTypes.All,
    },
  }
  return defValues
}

function OperatorsPageFilterForm(props: OperatorsPageFilterFormProps): JSX.Element {
  const { selectedFilterValues, optionsData, onFilterSubmit, onFilterReset, onFilterFormClose } =
    props

  const defaultFormValues = defaultFilterValues()

  const {
    register,
    handleSubmit,
    watch,
    reset,
    control,
    setValue,
    getValues,
    formState: {
      errors,
      isDirty,
      isSubmitting,
      isValid,
      isValidating,
      touchedFields,
      isSubmitSuccessful,
      isSubmitted,
      dirtyFields,
    },
  } = useForm<OperatorsPageFilterFormType>({
    defaultValues: defaultFormValues,
    values: selectedFilterValues,
  })

  const [fullNameOptions, setFullNameOptions] = useState<IselectOptionProps[]>([])
  const [businessUnitOptions, setBusinessUnitOptions] = useState<IselectOptionProps[]>([])
  const [operatorIdOptions, setOperatorIdOptions] = useState<IselectOptionProps[]>([])

  useEffect(() => {
    if (optionsData) {
      const fullNamesInData: IselectOptionProps[] = optionsData?.map((device) => ({
        value: `${device.firstName} ${device.lastName}`,
        label: `${device.firstName} ${device.lastName}`,
      }))
      // remove duplicate serial numbers

      const uniqueFullNames = new Set(fullNamesInData.map((item) => item.value))
      const fullNamesInDataUnique = Array.from(uniqueFullNames).map((item) => ({
        value: item,
        label: item?.toString(),
      }))

      const businessUnitsInData: IselectOptionProps[] | undefined = optionsData?.map((device) => ({
        value: device.businessUnit?.id,
        label: device.businessUnit?.name,
      }))
      // remove duplicates in business units

      const businessUnitsInDataUnique: IselectOptionProps[] = businessUnitsInData.filter(
        (v, i, a) => a.findIndex((t) => t.value === v.value) === i,
      )

      businessUnitsInDataUnique.unshift(
        selectAllOption as {
          value: string
          label: string
        },
      )

      const operatorIDsInData: IselectOptionProps[] = optionsData?.map((operator) => ({
        value: operator.uniqueId,
        label: operator.uniqueId,
      }))
      // remove duplicates

      const uniqueOperatorIDs = new Set(operatorIDsInData.map((item) => item.value))
      const uniqueOperatorIDsUnique = Array.from(uniqueOperatorIDs).map((item) => ({
        value: item,
        label: item?.toString(),
      }))

      setFullNameOptions(fullNamesInDataUnique as IselectOptionProps[])
      setBusinessUnitOptions(businessUnitsInDataUnique as IselectOptionProps[])
      setOperatorIdOptions(uniqueOperatorIDsUnique as IselectOptionProps[])
    }
  }, [optionsData])

  const onSubmit: SubmitHandler<OperatorsPageFilterFormType> = (data) => {
    const filterValues: IFilterValues[] = []

    filterValues.push({
      columnId: OperatorsPageColumnIds.fullName,
      value: data.fullname ? data.fullname.value : '',
    })

    filterValues.push({
      columnId: OperatorsPageColumnIds.devicesInUse,
      value:
        data.devicesInUse.value === OperatorDeviceCountTypes.All ? '' : data.devicesInUse.value,
      // data.devicesInUse ? data.devicesInUse.value : '',
    })

    filterValues.push({
      columnId: OperatorsPageColumnIds.operatorId,
      value:
        data.operatorId.value === SelectOptionTypes.All ? '' : (data.operatorId.label as string),
    })
    filterValues.push({
      columnId: OperatorsPageColumnIds.status,
      value: data.status.value === SelectOptionTypes.All ? '' : (data.status.label as string),
    })

    filterValues.push({
      columnId: OperatorsPageColumnIds.businessUnit,
      value: data.businessUnit
        ? data.businessUnit.value === SelectOptionTypes.All
          ? ''
          : (data.businessUnit.label as string)
        : '',
    })

    onFilterSubmit(filterValues)
  }

  const onError = (formErrors: FieldErrors<OperatorsPageFilterFormType>) => {
    console.log(formErrors)
  }

  const onReset = () => {
    reset(defaultFilterValues)
    onFilterReset()
  }

  return (
    <div className='filter-form-container'>
      <div className='filter-form-header'>
        <FilterFormTitle />

        <div
          title='Close Filter Form'
          role='button'
          tabIndex={0}
          onClick={() => onFilterFormClose()}
          onKeyDown={() => onFilterFormClose()}
          className='filter-form-close-button'
        >
          <CloseIcon toggleClick={() => onFilterFormClose()} className='w-5 h-5 ' />
        </div>
      </div>
      <form
        onSubmit={handleSubmit(onSubmit, onError)}
        onReset={onFilterReset}
        noValidate
        className=''
      >
        <div className='filter-form-fields-container'>
          <div className='flex flex-col gap-0.5 items-start self-stretch'>
            <label
              htmlFor='faultsrFilter'
              className='pl-[13px] text-xs font-bold leading-5 text-c-dark-blue-1 '
            >
              Name
            </label>
            <Controller
              name='fullname'
              control={control}
              render={({ field }) => (
                <CreatableSelectControl
                  className='w-full h-[41px]'
                  selectControlProps={{
                    options: fullNameOptions,
                    value: field.value,
                    defaultValue: field.value,
                    isMulti: false,
                    isSearchable: true,
                    isDropDownSelectable: false,
                    openMenuOnClick: true,
                    placeholder: '-',
                    isClearable: true,
                    // maxMenuHeight: 130,
                    onChange: (selectedOption: IselectOptionProps) => {
                      field.onChange(selectedOption)
                    },
                  }}
                />
              )}
            />
            <p className='text-xs text-red-500'>{errors.fullname?.message}</p>
          </div>

          <div className='flex flex-col gap-0.5 items-start self-stretch w-full'>
            <label
              htmlFor='deviceTypeFilter'
              className='pl-[13px] text-xs font-bold leading-5 text-c-dark-blue-1 '
            >
              Device(s) in use
            </label>

            <Controller
              name='devicesInUse'
              control={control}
              // rules={{ required: true }}
              render={({ field }) => (
                <CreatableSelectControl
                  className='w-full h-[42px]'
                  selectControlProps={{
                    options: OperatorDeviceCount,
                    value: field.value,
                    defaultValue: field.value,
                    isMulti: false,
                    isSearchable: true,
                    isDropDownSelectable: true,
                    openMenuOnClick: true,
                    placeholder: '-',
                    isClearable: false,
                    onChange: (selectedOption: IselectOptionProps) => {
                      field.onChange(selectedOption)
                    },
                  }}
                />
              )}
            />
          </div>

          <div className='flex flex-col gap-0.5 items-start self-stretch'>
            <label
              htmlFor='businessUnitFilter'
              className='pl-[13px] text-xs font-bold leading-5 text-c-dark-blue-1 '
            >
              Business Unit
            </label>
            <Controller
              name='businessUnit'
              control={control}
              render={({ field }) => (
                <SelectControl
                  className='w-full h-[42px]'
                  selectControlProps={{
                    options: businessUnitOptions,
                    value: field.value,
                    defaultValue: field.value,
                    isMulti: false,
                    isSearchable: true,
                    isDropDownSelectable: true,
                    openMenuOnClick: true,
                    placeholder: 'Select business unit',
                    isClearable: false,
                    onChange: (selectedOption: IselectOptionProps) => {
                      field.onChange(selectedOption)
                    },
                  }}
                />
              )}
            />
            <p className='text-xs text-red-500'>{errors.businessUnit?.message}</p>
          </div>

          <div className='flex flex-col gap-0.5 items-start self-stretch'>
            <label
              htmlFor='businessUnitFilter'
              className='pl-[13px] text-xs font-bold leading-5 text-c-dark-blue-1 '
            >
              ID
            </label>
            <Controller
              name='operatorId'
              control={control}
              render={({ field }) => (
                <SelectControl
                  className='w-full h-[42px]'
                  selectControlProps={{
                    options: operatorIdOptions.filter((ele) => ele.value),
                    value: field.value,
                    defaultValue: field.value,
                    isMulti: false,
                    isSearchable: true,
                    isDropDownSelectable: true,
                    openMenuOnClick: true,
                    placeholder: 'Select Status',
                    isClearable: false,
                    onChange: (selectedOption: IselectOptionProps) => {
                      field.onChange(selectedOption)
                    },
                  }}
                />
              )}
            />
            <p className='text-xs text-red-500'>{errors.operatorId?.message}</p>
          </div>
        </div>
        {/* Buttons */}
        <div className='filter-form-footer'>
          <input
            className='filter-form-reset-button'
            type='reset'
            disabled={isSubmitting}
            onClick={() => {
              onReset()
            }}
          />
          <input
            className='filter-form-apply-button'
            type='submit'
            value='Apply'
            // disabled={!isDirty || !isValid || isSubmitting}
          />
        </div>
      </form>

      <DevTool control={control} />
    </div> // opening div
  )
}

export default withErrorBoundary(memo(OperatorsPageFilterForm), {
  FallbackComponent: ErrorPage,
})
