import jsPDF from 'jspdf'
import React from 'react'
import crowconLogo from '../../../../../assets/images/main-logo.png'
import { CalibrationDataType } from '..'

export default function CalibrationReportPdf({
  calibrationData,
}: {
  calibrationData: CalibrationDataType
}) {
  async function downloadPDF() {
    const reportId = document.getElementById('report')?.cloneNode(true) as HTMLElement
    reportId?.setAttribute('style', `width: 1240px`)
    const parentNode = reportId.childNodes[0].cloneNode(true) as HTMLElement
    const { childNodes } = parentNode
    const lengthOfChild = childNodes.length
    Array.from(childNodes).map((element: ChildNode, i) => {
      parentNode.removeChild(element)
      if (i === lengthOfChild - 1) {
        const cloneNode = element.cloneNode(true) as HTMLElement
        parentNode.appendChild(cloneNode)
        cloneNode.setAttribute('style', 'margin-top: 650px')
        return cloneNode
      }
      parentNode.appendChild(element)
      return element
    })

    reportId.removeChild(reportId.childNodes[0])
    reportId.appendChild(parentNode)
    if (reportId) {
      // eslint-disable-next-line new-cap
      const pdf = new jsPDF('p', 'pt', 'a4', true)
      pdf.html(reportId, {
        callback() {
          pdf.save(`Calibration Report ${new Date().toLocaleDateString()}.pdf`)
        },
        x: 5,
        y: 0,
        html2canvas: {
          scale: 0.47,
        },
      })
    }
  }
  return (
    <>
      <div className='fixed bottom-5 right-5'>
        <button
          title='Download PDF'
          type='button'
          className='rounded-full relative flex bg-c-dark-blue-1 px-3.5 py-3.5 text-sm font-semibold  shadow-sm hover:bg-c-blue  '
          onClick={downloadPDF}
        >
          <span className='animate-ping absolute inline-flex h-full w-full rounded-full bg-c-dark-blue-1 opacity-75 bottom-0 right-1' />
          <svg
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
            strokeWidth='1.5'
            className='w-6 h-6 stroke-c-white'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              d='M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3'
            />
          </svg>
        </button>
      </div>

      <div className='overflow-hidden rounded-lg bg-white shadow text-sm ' id='report'>
        <div className='px-4 py-5 sm:p-6 text-black flex flex-col gap-3 justify-between items-stretch'>
          <div className=''>
            <div className='grid grid-cols-5 gap-2 border-b-4 pb-4 border-slate-700'>
              <div className=''>
                <p className='font-bold'>Scout</p>
                <p>© Crowcon, {new Date().getFullYear()}</p>
              </div>
              <div className='col-start-3'>
                <p className='text-center font-bold'>Calibration Report</p>
                <p className='text-center'> W999990/001</p>
              </div>
              <div className='col-start-5'>
                <img
                  className='h-full w-full object-contain'
                  src={crowconLogo}
                  alt='crowcon logo'
                />
              </div>
            </div>
            <div className='grid grid-cols-2  py-4 '>
              <div className='text-left'>
                <p className='font-bold'>Calibration Report</p>
                <p className='font-bold'>W999990/001</p>
                <p className='font-bold'>V1 i1.00 B05-</p>
                <p>Calibration Date</p>
                <p>{calibrationData.CalibrationDate}</p>
              </div>
              <div className='text-right'>
                <p className='font-bold'>Crowcon Detection Instruments</p>
                <p>172 Brook Drive</p>
                <p>Milton Park</p>
                <p>Abingdon</p>
                <p>OX14 4SD</p>
                <p>01235557700</p>
                <p>sales@crowcon.com</p>
              </div>
            </div>
            <table className='min-w-full border-y-4 pb-4 border-slate-700'>
              <thead />
              <tbody className='bg-white'>
                <tr className='even:bg-gray-50'>
                  <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3'>
                    Target Gas
                  </td>
                  <td className='whitespace-nowrap px-3 py-4 text-sm font-bold text-gray-500'>
                    Oxygen
                  </td>
                  <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                    Sensor Range
                  </td>
                  <td className='whitespace-nowrap px-3 py-4 text-sm font-bold text-gray-500'>
                    20.9 %VOL
                  </td>
                </tr>
                <tr className='even:bg-gray-50'>
                  <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3'>
                    Calibration Level
                  </td>
                  <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>20.89 %VOL</td>
                  <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                    Post Cal Reading
                  </td>
                  <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>20.88 %VOL</td>
                </tr>
              </tbody>
            </table>
            <div className='text-right text-xl font-bold pt-4'>Result: Success</div>
            <div className=''>
              <p className='font-bold'>Statement of Conformity</p>
              <p>
                I declare that the products listed above have been inspected and tested and are
                supplied in accordance with the ISO 9001:2015 standard and with the requirements of
                this order. All measuring Instruments used in above testing are traceable back to
                national standards.
              </p>
            </div>
            <div className='grid grid-cols-2'>
              <div className=''> </div>
              <div className='col-start-2 text-right border-b-2  border-slate-700 pb-[5rem]'>
                <p>Calibrated, Inspected & Tested By:</p>
                <p className='font-bold '>{calibrationData.OperatorName}</p>
              </div>
            </div>
          </div>
          <div className=''>
            <div className='pt-[18rem] border-b-4  border-slate-700' />
            <div className=''>
              <div className='grid grid-cols-2'>
                <div className=''>
                  <p>UK: 172 Brook Drive, Milton Park Abingdon, OX14 45D</p>
                  <p className='pl-7'>Tel: +44 (0)1235 557700 E-mail: sales@crowcon.com</p>
                </div>
                <div className='text-right'>www.crowcon.com</div>
              </div>
            </div>
            <div className=''>
              <p>US: 5690 Eighteen Mile Road, Sterling Heights, MI 48314, USA</p>
              <p className='pl-7'>Tel: 859-957-1039 Email: salesusa@crowcon.com</p>
            </div>
            <div className=''>
              <p>NL: VIambloem 129, 3068JG, Rotterdam</p>
              <p className='pl-7'>Tel: +31 10 421 1232 E-mail; eu@crowcon.com</p>
            </div>
            <div className=''>
              <p>SG: Block 194 Pandan Loop, 06-20 Pantech Industrial Complex, Singapore, 128383</p>
              <p className='pl-7'>Tel: +65 6745 2936 E-mail: sales@crowcon.com.sg</p>
            </div>
            <div className=''>
              <p>
                CN: 316, Area 1, Tower b, Chuangxin Building, 12 Hongda North Road, Beijing Economic
                & Technological Development Area, Bejing, PRC 100176
              </p>
              <p className='pl-7'>Tel: +86 10 6787 0335 E-mail: saleschina@crowcon.com</p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
