import React from 'react'
import { iBusinessUnit } from 'data/DeviceListHook'
import { ExportColumnType } from 'exportReports/DataExportInterfaces'
import {
  FormatToShortDate,
  FormatBusinessUnit,
  FormatUTXToDateTime,
} from 'exportReports/ExportDataUtils'

const ServiceFaultReportColumns: ExportColumnType[] = [
  {
    key: 'deviceSerialNumber',
    label: 'Serial #',
    format: (val: string) => val,
    cellWidth: 60,
  },
  {
    key: 'customerAssetNumber',
    label: 'Asset #',
    format: (val: string) => val,
    cellWidth: 60,
  },
  {
    key: 'deviceUserName',
    label: 'Assign To',
    format: (val: string) => val,
    cellWidth: 60,
  },
  {
    key: 'businessUnit',
    label: 'Business Unit',
    format: (val: iBusinessUnit) => FormatBusinessUnit(val),
    cellWidth: 80,
  },
  {
    key: 'deviceType',
    label: 'Device',
    format: (val: string) => val,
    cellWidth: 55,
  },
  {
    key: 'eventType',
    label: 'Fault type',
    format: (val: string) => val,
    cellWidth: 70,
  },
  {
    key: 'eventDescription',
    label: 'Description',
    format: (val: string) => val,
    cellWidth: 140,
  },
  {
    key: 'timeStamp',
    label: 'Date and time',
    format: (val?: number) => FormatUTXToDateTime(val || 0),
    cellWidth: 95,
  },
]

export { ServiceFaultReportColumns }
