import { useMsal } from '@azure/msal-react'
import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { parseAPIError, iCrowconProblemDetails } from 'services/APIErrorParser'
import { deviceTypesListQueryKey } from 'services/apis/caching/device'
import { crudDeviceURL, deviceTypesListURL } from 'services/apis/urls/device'
import ConnectAPIClient from 'services/ConnectAPIClient'
import { getAccessToken, getAccessTokenProps } from 'services/MSALService'

export interface iDeviceType {
  id: string
  name: string
  description: string
}

export interface iDeviceAssignmentDTO {
  deviceUserId: string
  assignedOn: string // 2022-01-18T06:04:48.572Z
}

export interface iAddDeviceDTO {
  deviceTypeId: string
  customerId: string
  businessUnitId: string
  serialNumber: string
  customerAssetNumber: string
  serviceDate?: string // 2022-08-18T06:04:48.572Z,
  calibrationDueDate?: string // 2022-06-18T06:04:48.572Z,
  Note: string
  deviceAssignment?: iDeviceAssignmentDTO
}

export interface iAddDeviceProps {
  deviceToAdd: iAddDeviceDTO
  accessTokenProps: getAccessTokenProps
}

export function useGetDeviceTypes(redirectPageURL: string, enabled?: boolean) {
  const msalContext = useMsal()
  const apiURL = deviceTypesListURL()
  const queryKey = deviceTypesListQueryKey()

  return useQuery<iDeviceType[], AxiosError>(
    queryKey,
    async () => {
      const token = await getAccessToken({ msalContext, redirectPageURL })
      const url = apiURL
      const resp = await ConnectAPIClient.get<iDeviceType[]>(url, token)
      const response = resp.data
      return response
    },
    {
      enabled,
      onSuccess: () => {},
      onError: (error) => {
        console.log(error)
      },
    },
  )
}

export async function addDevice(addDeviceProps: iAddDeviceProps) {
  const { deviceToAdd, accessTokenProps } = addDeviceProps
  try {
    const apiURL = crudDeviceURL()
    const token = await getAccessToken(accessTokenProps)
    const resp = await ConnectAPIClient.post<iAddDeviceDTO>(apiURL, token, '0.2', deviceToAdd)
    const response = resp.data
    return response
  } catch (error) {
    const axiosError = error as AxiosError
    const errorDescription = parseAPIError(axiosError?.response?.data as iCrowconProblemDetails)
    throw new Error(errorDescription)
  }
}
