import React from 'react'
import { SvgProps } from 'types/SVGProps'

function AddIcon(props: SvgProps): JSX.Element {
  const { stroke, fill, className, toggleClick } = props

  return (
    <div className=''>
      <svg
        className={className}
        width='20'
        height='20'
        viewBox='0 0 20 20'
        fill='none'
        onClick={toggleClick}
        xmlns='http://www.w3.org/2000/svg'
      >
        <path d='M10 5V15' stroke={stroke ?? '#FFFFFF'} strokeWidth='1.5' strokeLinecap='round' />
        <path d='M15 10L5 10' stroke={stroke ?? '#FFFFFF'} strokeWidth='1.5' strokeLinecap='round' />
      </svg>
    </div>
  )
}

export default AddIcon
