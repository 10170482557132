import { Menu, Transition } from '@headlessui/react'
import withRoleChecking from 'hoc/withRoleChecking'
import React, { ButtonHTMLAttributes } from 'react'
import { UserRoleTypes } from 'utils/CommonEnums'
/**
 * Renders a menu component for organization CRUD operations.
 *
 * @param {Object} props - The component props.
 * @param {React.ReactNode} props.children - The content to be rendered inside the menu button.
 * @param {ButtonHTMLAttributes<HTMLButtonElement>[]} props.menuItemConfig - The configuration for each menu item.
 * @returns {JSX.Element} The rendered menu component.
 */
function OrganizationCrudMenu({
  children,
  menuItemConfig,
}: {
  children: React.ReactNode
  menuItemConfig: ButtonHTMLAttributes<HTMLButtonElement>[]
}): JSX.Element {
  return (
    <Menu>
      <Menu.Button className=' rounded-md text-sm/6 font-semibold text-white  shadow-white/10 focus:outline-none data-[hover]:bg-gray-700 data-[open]:bg-gray-700 data-[focus]:outline-1 data-[focus]:outline-white'>
        {children}
      </Menu.Button>
      <Transition
        enter='transition ease-out duration-75'
        enterFrom='opacity-0 scale-95'
        enterTo='opacity-100 scale-100'
        leave='transition ease-in duration-100'
        leaveFrom='opacity-100 scale-100'
        leaveTo='opacity-0 scale-95'
      >
        <Menu.Items className='p-1  gap-3 z-50 flex -top-4 left-5 absolute origin-top-right rounded-xl border border-white/5 bg-c-blue  text-sm/6 focus:outline-none'>
          {menuItemConfig.map((itemConfig) => (
            <Menu.Item key={itemConfig.title} as='button' {...itemConfig} />
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  )
}

export default withRoleChecking(OrganizationCrudMenu, [
  UserRoleTypes.Admin,
  UserRoleTypes.CustomerAdmin.split(' ').join(''),
])
