import { useMsal } from '@azure/msal-react'
import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import ConnectAPIClient from 'services/ConnectAPIClient'
import { getAccessTokenProps, getAccessToken } from 'services/MSALService'
import { BusinessUnitTreeQueryKey, organizationTreeQueryKey } from 'services/apis/caching/users'
import { businessUnitsTree, businessUnitsTreeCrud } from 'services/apis/urls/business'
import { organizationTree, organizationTreeCrud } from 'services/apis/urls/customer'
import {
  iOrganizationParams,
  iBusinessUnitTreeData,
  iBusinessUnitTreePayloadParams,
  iOrganizationTreeData,
  iOrganizationTreePayloadParams,
} from '../utils/OrganizationInterface'

/**
 * this is for organization Business Unit Tree list
 */

/**
 * Fetches the organization Business Unit Tree list.
 * @param params - The parameters for fetching the organization Business Unit Tree list.
 * @returns A promise that resolves to the organization Business Unit Tree data.
 * @throws If an error occurs during the fetch operation.
 */
export async function FetchBusinessUnitTreeList(
  params: iOrganizationParams,
): Promise<iBusinessUnitTreeData> {
  try {
    const { token, customerId } = params
    const url = businessUnitsTree(customerId)
    const resp = await ConnectAPIClient.get<iBusinessUnitTreeData>(url, token)
    const response = resp.data
    return { ...response, customerId }
  } catch (error) {
    console.error(error)
    throw error
  }
}

/**
 * Fetches the organization Business Unit Tree list data.
 * @param localeAPIParams - The parameters for fetching the organization Business Unit Tree list data.
 * @param customerId - The customer ID.
 * @returns A promise that resolves to the organization Business Unit Tree list data.
 */
export async function getBusinessUnitTreeListListData(
  localeAPIParams: getAccessTokenProps,
  customerId: string,
) {
  const { msalContext, redirectPageURL } = localeAPIParams
  const token = await getAccessToken({ msalContext, redirectPageURL })
  const localeListData = await FetchBusinessUnitTreeList({ token, customerId })
  return localeListData
}

/** Fetches and returns the organization Business Unit Tree list.
   @param redirectPageURL - The redirect page URL.
   @param customerId - The customer ID.
   @param enabled - Whether the query should be enabled.
   @returns The organization Business Unit Tree list query.
  */
export function useBusinessUnitTreeList(
  redirectPageURL: string,
  customerId: string,
  enabled?: boolean,
) {
  const msalContext = useMsal()
  return useQuery({
    queryKey: BusinessUnitTreeQueryKey(customerId),
    queryFn: () =>
      getBusinessUnitTreeListListData(
        {
          msalContext,
          redirectPageURL,
        },
        customerId,
      ),

    enabled: enabled && !!customerId,
    onError: (error: AxiosError) => {
      console.log(error)
    },
  })
}

/**
 * Adds a node to the organization Business Unit Tree list.
 * @param params - The parameters for adding a node to the organization Business Unit Tree list.
 * @returns A promise that resolves to the organization Business Unit Tree data.
 * @throws If an error occurs during the process.
 */
export async function addNodeToBusinessUnitTreeList(
  params: iBusinessUnitTreePayloadParams,
): Promise<iBusinessUnitTreeData> {
  try {
    const { msalContext, payload } = params
    const token = await getAccessToken({ msalContext, redirectPageURL: '' })
    const url = businessUnitsTreeCrud()
    const resp = await ConnectAPIClient.post<iBusinessUnitTreeData>(
      url,
      token,
      '',
      payload as unknown as iBusinessUnitTreeData,
    )
    const response = resp.data
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}

/**
 * Updates a node in the organization Business Unit Tree list.
 * @param params - The parameters for updating the node.
 * @returns A promise that resolves to the updated organization Business Unit Tree data.
 * @throws If an error occurs during the update process.
 */
export async function updateNodeInBusinessUnitTreeList(
  params: iBusinessUnitTreePayloadParams,
): Promise<iBusinessUnitTreeData> {
  try {
    const { msalContext, buId, payload } = params
    const token = await getAccessToken({ msalContext, redirectPageURL: '' })
    const url = businessUnitsTree(buId)
    const resp = await ConnectAPIClient.put<iBusinessUnitTreeData>(
      url,
      token,
      '',
      payload as unknown as iBusinessUnitTreeData,
    )
    const response = resp.data
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}

/**
 * this is for organization Tree list
 *
 */

/**
 * Fetches the organization Tree list based on the provided parameters.
 * @param params - The parameters required for fetching the organization Tree list.
 * @returns A promise that resolves to an array of organization Tree data.
 * @throws If an error occurs during the fetch operation.
 */
export async function FetchOrganizationTreeList(
  params: iOrganizationParams,
): Promise<iOrganizationTreeData[]> {
  try {
    const { token, customerId } = params
    const url = organizationTree(customerId)
    const resp = await ConnectAPIClient.get<iOrganizationTreeData[]>(url, token)
    const response = resp.data
    return response.map((item) => ({ ...item, customerId }))
  } catch (error) {
    console.error(error)
    throw error
  }
}

/**
 * Retrieves the organization Tree list data.
 * @param localeAPIParams - The parameters for the locale API.
 * @param customerId - The ID of the customer.
 * @returns A promise that resolves to the organization Tree list data.
 */
export async function getOrganizationTreeListData(
  localeAPIParams: getAccessTokenProps,
  customerId: string,
) {
  const { msalContext, redirectPageURL } = localeAPIParams
  const token = await getAccessToken({ msalContext, redirectPageURL })
  const localeListData = await FetchOrganizationTreeList({ token, customerId })
  return localeListData
}

/**
 * Custom hook to fetch organization Tree list data.
 *
 * @param redirectPageURL - The URL to redirect to after fetching the data.
 * @param customerId - The ID of the customer.
 * @param enabled - Optional flag to enable/disable the hook.
 * @returns The result of the query.
 */
export function useOrganizationTreeList(
  redirectPageURL: string,
  customerId: string,
  enabled?: boolean,
) {
  const msalContext = useMsal()
  return useQuery({
    queryKey: organizationTreeQueryKey(customerId),
    queryFn: () =>
      getOrganizationTreeListData(
        {
          msalContext,
          redirectPageURL,
        },
        customerId,
      ),

    enabled: enabled && !!customerId,
    onError: (error: AxiosError) => {
      console.log(error)
    },
  })
}

/**
 * Adds a node to the organization Tree list.
 * @param params - The parameters for adding a node to the organization Tree list.
 * @returns A promise that resolves to the organization Tree data.
 */
export async function addNodeToOrganizationTreeList(
  params: iOrganizationTreePayloadParams,
): Promise<iOrganizationTreeData> {
  try {
    const { msalContext, customerId, payload } = params
    const token = await getAccessToken({ msalContext, redirectPageURL: '' })
    const url = organizationTree(customerId as string)
    const resp = await ConnectAPIClient.post<iOrganizationTreeData>(
      url,
      token,
      '',
      payload as unknown as iOrganizationTreeData,
    )
    const response = resp.data
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}
/**
 * Updates a node in the organization Tree list.
 * @param params - The parameters for updating the node.
 * @returns A promise that resolves to the updated organization Tree data.
 * @throws If an error occurs during the update process.
 */
export async function updateNodeInOrganizationTreeList(
  params: iOrganizationTreePayloadParams,
): Promise<iOrganizationTreeData> {
  try {
    const { msalContext, payload } = params
    const token = await getAccessToken({ msalContext, redirectPageURL: '' })
    const url = organizationTreeCrud()
    const resp = await ConnectAPIClient.put<iOrganizationTreeData>(
      url + payload.bUlevelId,
      token,
      '',
      payload as unknown as iOrganizationTreeData,
    )
    const response = resp.data
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}
