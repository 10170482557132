import React, { MouseEvent } from 'react'
import { iFleetHealthSummary } from 'pages/dashboard/data/DashboardData'
import { Doughnut, getElementAtEvent } from 'react-chartjs-2'
import { DeviceUserAssignedFilterValues } from 'utils/CommonEnums'
import { withErrorBoundary } from 'react-error-boundary'
import ErrorPage from 'pages/common/ErrorPage'
import { useNavigate } from 'react-router-dom'

interface iDashboardDeviceAssignmentChartProps {
  deviceAssignmentData: iFleetHealthSummary['fleetAssignment']
  customerId: string
  businessUnitId: string
}

const chartOptions = {
  responsive: true,
  padding: 16,
  interaction: {
    mode: 'index' as const,
    intersect: true,
  },
  stacked: true,
  plugins: {
    title: {
      display: false,
      text: 'Device Assignments',
      font: {
        size: 16,
      },
      position: 'top' as const,
      align: 'start' as const,
    },
    legend: {
      display: false,
      position: 'top' as const,
      align: 'start' as const,
      labels: {
        pointStyle: 'circle' as const,
        usePointStyle: true,
        borderRadius: 26,
      },
    },
  },
}

function DashboardDeviceAssignmentChart(
  props: iDashboardDeviceAssignmentChartProps,
): React.ReactElement {
  // Implement your component logic here
  const chartRef = React.useRef()
  const { deviceAssignmentData, customerId, businessUnitId } = props
  const urlsearchParams = new URLSearchParams()
  if (customerId) {
    urlsearchParams.set('c', customerId)
  }
  if (businessUnitId) {
    urlsearchParams.set('mainbu', businessUnitId)
  }
  const devicesAssigned = deviceAssignmentData.deviceAssignments
  const devicesUnAssigned = deviceAssignmentData.deviceUnAssignments
  const navigate = useNavigate()
  const chartData = {
    labels: ['Assigned', 'Unassigned'],
    datasets: [
      {
        labels: ['Assigned', 'Unassigned'],
        data: [devicesAssigned, devicesUnAssigned],
        backgroundColor: ['#67b7dc', '#a367dc'],
        hoverBackgroundColor: ['#43778f', '#6a438f'],
        // borderColor: ['rgb(255, 99, 132)', 'rgb(54, 162, 235)'],
        // borderWidth: 1,
        // hoverBorderWidth: 2,
        // barThickness: 10,
        // barPercentage: 1,
        // fill: false,
        // yAxisID: 'y',
        // grouped: false,
        // stack: 'bar'
        // offset: 12,
        // hoverOffset: 12,

        borderRadius: {
          outerStart: 0,
          outerEnd: 0,
          innerStart: 8,
          innerEnd: 8,
        },
      },
    ],
  }

  const onChartClick = (event: MouseEvent<HTMLCanvasElement>) => {
    const element = chartRef.current && getElementAtEvent(chartRef.current, event)
    // element index 0 is for assigned and 1 for unassigned
    // form the url accordingly

    if (element && element[0]) {
      const { index } = element[0]
      // let url = `/web/devices`
      if (index === 0) {
        urlsearchParams.set('user', DeviceUserAssignedFilterValues.AllAssignedUsers)
        // url = `/web/devices?user=${DeviceUserAssignedFilterValues.AllAssignedUsers}`
      } else if (index === 1) {
        urlsearchParams.set('user', DeviceUserAssignedFilterValues.NoAssignedUsers)
        // url = `/web/devices?user=${DeviceUserAssignedFilterValues.NoAssignedUsers}`
      }
      const url = `/web/devices?${urlsearchParams.toString()}`
      // window.open(url, '_blank')
      navigate(url)
    }
  }

  return (
    // JSX code for your component's UI
    <div className=' px-4 py-4  bg-c-white shadow-card rounded-2xl items-center h-full '>
      <div className='my-2 flex flex-col gap-y-1 self-start'>
        <div className='text-base font-bold text-c-dark-blue-2'>Device Assignments</div>
        <div className='flex gap-x-2 mt-1 items-baseline'>
          <div className='rounded-full bg-custom-1 h-2 w-2 ' />
          <div className='text-xs text-c-dark-blue-3'>Assigned</div>
          <div className='rounded-full bg-custom-2 h-2 w-2  ' />
          <div className='text-xs text-c-dark-blue-3'>Unassigned</div>
        </div>
      </div>
      <div className='mt-2 p-2 h-48 2xl:h-64 '>
        {devicesAssigned === 0 && devicesUnAssigned === 0 ? (
          <div className='flex justify-center items-center h-full w-full text-sm text-red-500'>
            No Devices Assigned
          </div>
        ) : (
          <Doughnut ref={chartRef} onClick={onChartClick} data={chartData} options={chartOptions} />
        )}
      </div>
    </div>
  )
}

export default withErrorBoundary(DashboardDeviceAssignmentChart, {
  FallbackComponent: ErrorPage,
})
