// Importing necessary modules from react-table and react
import { Table, VisibilityState } from '@tanstack/react-table'
import { createContext } from 'react'

// Define the type for the TableContext. This includes the table itself,
// the order of the columns, and the visibility state of the columns.
export type TableContextType<T> = {
  table: Table<T> // The table instance
  columnOrder: string[] // The order of the columns in the table
  columnVisibility: VisibilityState // The visibility state of the columns
}

// Create the TableContext using React's createContext function.
// This context will provide a way to share the table data, column order,
// and column visibility state across components.
export const TableContext = createContext({} as TableContextType<any>)
