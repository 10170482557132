import React from 'react'

import { SvgProps } from 'types/SVGProps'

function MailIcon(props: SvgProps): JSX.Element {
  const { stroke, fill, className, height, width, toggleClick } = props
  return (
    <div className={className}>
      <svg
        stroke={stroke ?? '#FFFFFF'}
        width={width ?? '20'}
        height={height ?? '20'}
        viewBox='0 0 20 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M15.7162 6.45639C15.7162 5.82618 15.2006 5.31055 14.5704 5.31055H5.40366C4.77344 5.31055 4.25781 5.82618 4.25781 6.45639V13.3315C4.25781 13.9617 4.77344 14.4773 5.40366 14.4773H14.5704C15.2006 14.4773 15.7162 13.9617 15.7162 13.3315V6.45639ZM14.5704 6.45639L9.98703 9.321L5.40366 6.45639H14.5704ZM14.5704 13.3315H5.40366V7.60223L9.98703 10.4668L14.5704 7.60223V13.3315Z'
          fill={fill ?? '#FFFFFF'}
        />
      </svg>
    </div>
  )
}

export default MailIcon
