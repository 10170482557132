import { IselectOptionProps } from 'forms/FormModelInterface'
import {
  PortableTypes,
  BumpStatusTypes,
  UploadClientType,
  UploadLogType,
  UserPermissionTypes,
  UserRoleTypes,
  DeviceCalDueTypes,
  OperatorDeviceCountTypes,
  AlarmTypes,
  UserStatusEnum,
} from './CommonEnums'

// const stagingSiteVersion = 'v77s (22.03.2024.a)'
// const prodSiteVersion = 'v72p (10.03.2024.c)'
// export const SiteVersion = prodSiteVersion
export const SiteVersion = process.env.REACT_APP_SITE_VERSION

export const MaxNumberOfChannels: {
  [key in PortableTypes]: number
} = {
  [PortableTypes.Gasman]: 1,
  [PortableTypes.Scout]: 1,
  [PortableTypes.T3]: 3,
  [PortableTypes.T4]: 4,
  [PortableTypes.GasPro]: 5,
  [PortableTypes.Clip]: 1,
}

export const PortableTypeProductNames: {
  [key in PortableTypes]: string
} = {
  [PortableTypes.Gasman]: 'Gasman',
  [PortableTypes.T3]: 'T3',
  [PortableTypes.T4]: 'T4',
  [PortableTypes.GasPro]: 'Gas-Pro',
  [PortableTypes.Scout]: 'Scout',
  [PortableTypes.Clip]: 'Clip',
}

export const PortableTypeNames = [
  {
    type: PortableTypes.Gasman,
    name: 'gasman',
  },
  {
    type: PortableTypes.T3,
    name: 't3',
  },
  {
    type: PortableTypes.T4,
    name: 't4',
  },
  {
    type: PortableTypes.GasPro,
    name: 'gaspro',
  },
  {
    type: PortableTypes.Scout,
    name: 'scout',
  },
]

export const ScreenWidthsInPx = {
  xs: 480,
  sm: 560,
  md: 760,
  lg: 1024,
  xl: 1200,
  xxl: 1420,
}

// 'xs': '480px',
// 'sm': '560px',
// 'md': '760px',
// 'lg': '1024px',
// 'xl': '1200px',
// '2xl': '1420px',
// '3xl': '1600px',
export enum ScreenWidths {
  xs = 480,
  sm = 560,
  md = 760,
  lg = 1024,
  xl = 1200,
  xxl = 1420,
}

export const ScreenHeightInPx = {
  Large: 780,
}

export const TsTableParams = {
  DefaultColWidth: 10,
  PageSizes: [10, 20, 50, 100],
}

export const StatusValues: IselectOptionProps[] = [
  {
    label: 'Active',
    value: 5,
    isHidden: false,
  },
  {
    label: 'Inactive',
    value: 10,
    isHidden: false,
  },
  // {
  //   label: 'Faulty',
  //   value: 15,
  //   isHidden: true,
  // },
  // {
  //   label: 'Stolen',
  //   value: 20,
  //   isHidden: true,
  // },
  // {
  //   label: 'Deleted',
  //   value: 25,
  //   isHidden: true,
  // },
]
export interface iUserRole {
  label: string
  value: UserRoleTypes
}
export const UserRoles: iUserRole[] = [
  {
    label: 'Super User',
    value: UserRoleTypes.SuperUser,
  },
  {
    label: 'Admin',
    value: UserRoleTypes.CustomerAdmin,
  },
  {
    label: 'Developer Admin',
    value: UserRoleTypes.DeveloperAdmin,
  },
  {
    label: 'User',
    value: UserRoleTypes.User,
  },
  {
    label: 'CRT Admin',
    value: UserRoleTypes.CRTAdmin,
  },
  {
    label: 'Marketing',
    value: UserRoleTypes.Marketing,
  },
  {
    label: 'Reader',
    value: UserRoleTypes.Reader,
  },
]

export const UserAccountContextActions = {
  SetUserAccount: 'SetUserAccount',
  ClearUserAccount: 'ClearUserAccount',
  UserLogin: 'UserLogin',
  SetADB2CPolicy: 'SetADB2CPolicy',
  UpdateBasicProfile: 'UpdateBasicProfile',
  DemoModeStatus: 'DemoModeStatus',
  UpdateFeature: 'UpdateFeature',
}

export interface iUserPermissions {
  name: UserPermissionTypes
  label: string
  hideForRoles: UserRoleTypes[]
}

export const DeviceLogUploadTypes = [
  {
    label: 'Event Log',
    value: UploadLogType.EventLog,
  },
  {
    label: 'Data Log',
    value: UploadLogType.DataLog,
  },
  {
    label: 'Calibration records',
    value: UploadLogType.CalRecords,
  },
]

export const DeviceLogUploadClients = [
  {
    label: 'PC App',
    value: UploadClientType.PCApp,
  },
  {
    label: 'Mobile App',
    value: UploadClientType.MobileApp,
  },
]

export const BumpTestStatusValues: IselectOptionProps[] = [
  {
    label: 'Passed',
    value: BumpStatusTypes.BumpTestPassed,
    isHidden: false,
  },
  {
    label: 'Failed',
    value: BumpStatusTypes.BumpTestFailed,
    isHidden: false,
  },
  {
    label: 'Not available',
    value: BumpStatusTypes.BumpTestNotPerformed,
    isHidden: false,
  },
]

export const DeviceCalDueTypeTexts = [
  {
    label: DeviceCalDueTypes.NotAvailable,
    value: 'Not available',
  },
  {
    label: DeviceCalDueTypes.Overdue,
    value: 'Overdue',
  },
  {
    label: DeviceCalDueTypes.DueIn7Days,
    value: 'Due in 7 days',
  },
  {
    label: DeviceCalDueTypes.DueIn30Days,
    value: 'Due in 30 days',
  },
  {
    label: DeviceCalDueTypes.DueIn60Days,
    value: 'Due in 60 days',
  },
  {
    label: DeviceCalDueTypes.DueIn90Days,
    value: 'Due in 90 days',
  },
  {
    label: DeviceCalDueTypes.DueOver90Days,
    value: 'Due over 90 days',
  },
]

export const OperatorDeviceCount = [
  {
    label: OperatorDeviceCountTypes.All,
    value: OperatorDeviceCountTypes.All,
  },
  {
    label: OperatorDeviceCountTypes.NoDevice,
    value: OperatorDeviceCountTypes.NoDevice,
  },
  {
    label: OperatorDeviceCountTypes.OneDevice,
    value: OperatorDeviceCountTypes.OneDevice,
  },
  {
    label: OperatorDeviceCountTypes.MultipleDevices,
    value: OperatorDeviceCountTypes.MultipleDevices,
  },
]

export const AlarmTypeValues = [
  {
    label: 'Alarm 1',
    value: AlarmTypes.Alarm1,
  },
  {
    label: 'Alarm 2',
    value: AlarmTypes.Alarm2,
  },
  {
    label: 'Near Miss',
    value: AlarmTypes.Nearmiss,
  },
]

export const returnDateWithoutTimeOffset = (date?: string | Date) => {
  const dateObj = (date ? new Date(date) : new Date()).toISOString().split('T')[0]
  return new Date(dateObj)
}

export const UserRolesConstants: {
  [key in string]: UserRoleTypes
} = {
  SuperUser: UserRoleTypes.SuperUser,
  Admin: UserRoleTypes.CustomerAdmin,
  CustomerAdmin: UserRoleTypes.CustomerAdmin,
  'Developer Admin': UserRoleTypes.DeveloperAdmin,
  User: UserRoleTypes.User,
  'CRT Admin': UserRoleTypes.CRTAdmin,
  Marketing: UserRoleTypes.Marketing,
  Reader: UserRoleTypes.Reader,
}

export const AlarmsConstants = {
  [AlarmTypes.Alarm1]: 'Alarm 1',
  [AlarmTypes.Alarm2]: 'Alarm 2',
  [AlarmTypes.Nearmiss]: 'Near Miss',
}

export const UserStatusInNumber: {
  [key in string]: UserStatusEnum
} = {
  Active: UserStatusEnum.Active,
  Inactive: UserStatusEnum.InActive,
  Invited: UserStatusEnum.Invited,
  Deleted: UserStatusEnum.Deleted,
}
// export const UserPermissions : iUserPermissions[] = [
// {
//     name: UserPermissionTypes.PortalAccess,
//     label: 'Portal Access',
//     hideForRoles: [
//         UserRoles.SuperUser,
//         UserRoles.CustomerAdmin,
//         UserRoles.User,
//         UserRoles.CRTAdmin,
//         UserRoles.Marketing,
//         UserRoles.Reader,
//       ],
// },

//   name: DeviceRead: {
//     name: 'device.read',
//     hideForRoles: [
//       UserRoles.SuperUser,
//       UserRoles.CustomerAdmin,
//       UserRoles.User,
//       UserRoles.CRTAdmin,
//       UserRoles.Marketing,
//       UserRoles.Reader,
//     ],
//   },

//   DeviceReadAll: {
//     name: 'device.read.all',
//     hideForRoles: [],
//   },

//   DeviceWrite: {
//     name: 'device.write',
//     hideForRoles: [],
//   },

//   DeviceWriteAll: {
//     name: 'device.write.all',
//     hideForRoles: [
//       UserRoles.SuperUser,
//       UserRoles.CustomerAdmin,
//       UserRoles.User,
//       UserRoles.CRTAdmin,
//       UserRoles.Marketing,
//     ],
//   },

//   DeviceDelete: {
//     name: 'device.delete',
//     hideForRoles: [
//       UserRoles.SuperUser,
//       UserRoles.CustomerAdmin,
//       UserRoles.User,
//       UserRoles.CRTAdmin,
//       UserRoles.Marketing,
//       UserRoles.Reader,
//     ],
//   },

//   DeviceAssignmentWrite: {
//     name: 'deviceAssignment.write',
//     hideForRoles: [],
//   },

//   DeviceLogsRead: {
//     name: 'deviceLogs.read',
//     hideForRoles: [],
//   },

//   DeviceLogsWrite: {
//     name: 'deviceLogs.write',
//     hideForRoles: [
//       UserRoles.SuperUser,
//       UserRoles.CustomerAdmin,
//       UserRoles.User,
//       UserRoles.CRTAdmin,
//       UserRoles.Marketing,
//       UserRoles.Reader,
//     ],
//   },

//   OperatorReadAll: {
//     name: 'operator.read.all',
//     hideForRoles: [],
//   },

//   OperatorWriteAll: {
//     name: 'operator.write.all',
//     hideForRoles: [],
//   },

//   OperatorDelete: {
//     name: 'operator.delete',
//     hideForRoles: [
//       UserRoles.SuperUser,
//       UserRoles.CustomerAdmin,
//       UserRoles.User,
//       UserRoles.CRTAdmin,
//       UserRoles.Marketing,
//       UserRoles.Reader,
//     ],
//   },

//   BusinessUnitRead: {
//     name: 'bu.read',
//     hideForRoles: [UserRoles.Reader],
//   },

//   BusinessUnitWrite: {
//     name: 'bu.write',
//     hideForRoles: [],
//   },

//   BusinessUnitDelete: {
//     name: 'bu.delete',
//     hideForRoles: [
//       UserRoles.SuperUser,
//       UserRoles.CustomerAdmin,
//       UserRoles.User,
//       UserRoles.CRTAdmin,
//       UserRoles.Marketing,
//       UserRoles.Reader,
//     ],
//   },

//   UserReadAll: {
//     name: 'user.read.all',
//     hideForRoles: [],
//   },

//   UserWriteAll: {
//     name: 'user.write.all',
//     hideForRoles: [],
//   },

//   UserDelete: {
//     name: 'user.delete',
//     hideForRoles: [
//       UserRoles.SuperUser,
//       UserRoles.CustomerAdmin,
//       UserRoles.User,
//       UserRoles.CRTAdmin,
//       UserRoles.Marketing,
//       UserRoles.Reader,
//     ],
//   },

//   CustomerReadAll: {
//     name: 'customer.read.all',
//     hideForRoles: [],
//   },

//   CustomerWriteSelf: {
//     name: 'customer.write.self',
//     hideForRoles: [
//       UserRoles.SuperUser,
//       UserRoles.CustomerAdmin,
//       UserRoles.User,
//       UserRoles.CRTAdmin,
//       UserRoles.Marketing,
//       UserRoles.Reader,
//     ],
//   },

//   CustomerWriteAll: {
//     name: 'customer.write.all',
//     hideForRoles: [],
//   },

//   CustomerApprove: {
//     name: 'customer.approve',
//     hideForRoles: [
//       UserRoles.SuperUser,
//       UserRoles.CustomerAdmin,
//       UserRoles.User,
//       UserRoles.CRTAdmin,
//       UserRoles.Marketing,
//       UserRoles.Reader,
//     ],
//   },

//   RoleReadAll: {
//     name: 'role.read.all',
//     hideForRoles: [],
//   },

//   RoleWrite: {
//     name: 'role.write',
//     hideForRoles: [],
//   },

//   RoleWriteSelf: {
//     name: 'role.write.self',
//     hideForRoles: [],
//   },

//   RoleDelete: {
//     name: 'role.delete',
//     hideForRoles: [
//       UserRoles.SuperUser,
//       UserRoles.CustomerAdmin,
//       UserRoles.User,
//       UserRoles.CRTAdmin,
//       UserRoles.Marketing,
//       UserRoles.Reader,
//     ],
//   },

//   PermissionsReadAll: {
//     name: 'permissions.read.all',
//     hideForRoles: [
//       UserRoles.CustomerAdmin,
//       UserRoles.User,
//       UserRoles.CRTAdmin,
//       UserRoles.Marketing,
//       UserRoles.Reader,
//     ],
//   },

//   PermissionsWrite: {
//     name: 'permissions.write',
//     hideForRoles: [],
//   },

//   PermissionsDelete: {
//     name: 'permissions.delete',
//     hideForRoles: [
//       UserRoles.SuperUser,
//       UserRoles.CustomerAdmin,
//       UserRoles.User,
//       UserRoles.CRTAdmin,
//       UserRoles.Marketing,
//       UserRoles.Reader,
//     ],
//   },

//   BillingPlanReadAll: {
//     name: 'billingPlan.read.all',
//     hideForRoles: [],
//   },

//   BillingPlanWrite: {
//     name: 'billingPlan.write',
//     hideForRoles: [],
//   },

//   BillingPlanDelete: {
//     name: 'billingPlan.delete',
//     hideForRoles: [
//       UserRoles.SuperUser,
//       UserRoles.CustomerAdmin,
//       UserRoles.User,
//       UserRoles.CRTAdmin,
//       UserRoles.Marketing,
//       UserRoles.Reader,
//     ],
//   },

//   FeatureRead: {
//     name: 'feature.read',
//     hideForRoles: [
//       UserRoles.SuperUser,
//       UserRoles.CustomerAdmin,
//       UserRoles.User,
//       UserRoles.CRTAdmin,
//       UserRoles.Marketing,
//       UserRoles.Reader,
//     ],
//   },

//   FeatureWrite: {
//     name: 'feature.write',
//     hideForRoles: [
//       UserRoles.SuperUser,
//       UserRoles.CustomerAdmin,
//       UserRoles.User,
//       UserRoles.CRTAdmin,
//       UserRoles.Marketing,
//       UserRoles.Reader,
//     ],
//   },

//   DeviceTypeWrite: {
//     name: 'devicetypes.write',
//     hideForRoles: [
//       UserRoles.SuperUser,
//       UserRoles.CustomerAdmin,
//       UserRoles.User,
//       UserRoles.CRTAdmin,
//       UserRoles.Marketing,
//       UserRoles.Reader,
//     ],
//   },

//   MasterDataReadAll: {
//     name: 'masterdata.read.all',
//     hideForRoles: [
//       UserRoles.SuperUser,
//       UserRoles.CustomerAdmin,
//       UserRoles.User,
//       UserRoles.CRTAdmin,
//       UserRoles.Marketing,
//       UserRoles.Reader,
//     ],
//   },

//   MasterDataWriteAll: {
//     name: 'masterdata.write.all',
//     hideForRoles: [
//       UserRoles.SuperUser,
//       UserRoles.CustomerAdmin,
//       UserRoles.User,
//       UserRoles.CRTAdmin,
//       UserRoles.Marketing,
//       UserRoles.Reader,
//     ],
//   },

//   StatusActiveReadWrite: {
//     name: 'status.active.readwrite',
//     hideForRoles: [],
//   },
// ]
