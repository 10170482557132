import React from 'react'
import clsx from 'clsx'

interface buttonProps {
  value: string
  type?: string
  disabled?: boolean
  className?: string
  onClick?: () => void
}

export function CrowconFormButton(props: buttonProps) {
  const { value, type = 'submit', disabled = false, className, onClick } = props
  return (
    <input
      type={type}
      value={value}
      disabled={disabled}
      onClick={onClick}
      title={value} // Add the title attribute with the value prop
      className={clsx(
        'text-base font-bold  m-1 p-1 rounded-3xl w-36 h-12',
        disabled
          ? 'bg-c-light-blue-2'
          : 'text-c-white cursor-pointer bg-c-dark-blue-1 hover:bg-c-dark-blue-2 active:bg-c-dark-blue-3',
        className,
      )}
    />
  )
}
