/* eslint-disable no-promise-executor-return */
import { Dialog } from '@headlessui/react'
import { ArrowRight, VerifiedBadge } from 'assets/icons'
import clsx from 'clsx'
import Show from 'components/atom/Show'
import CrowconButton from 'components/miscComponents/CrowconButton'
import React, { useEffect, useState } from 'react'
import { DemoModeInitiateSteps, StageStatus } from '../../utils/constants'
import { DataForUserType } from '../../interfaces'
import { useDemoMutations } from '../../data/mutations'

export default function DemoModeInitiate({
  isOpen,
  close,
}: {
  isOpen: boolean
  close: (status: boolean, dataForUser?: DataForUserType) => void
}) {
  const [state, setState] = useState(DemoModeInitiateSteps)
  const [dataForUser, setDataForUser] = useState<DataForUserType | unknown>({})
  const isDone = state.filter((item) => item.status === StageStatus.END).length === state.length

  const demoMutation = useDemoMutations(state, setState, setDataForUser)

  useEffect(() => {
    if (isOpen) {
      demoMutation()
    }
    return () => {
      setState((pre) => pre.map((steps) => ({ ...steps, status: StageStatus.NONE })))
    }
  }, [isOpen])

  function confirmUserInDemoMode() {
    close(true, dataForUser as DataForUserType)
  }

  return (
    <Dialog open={isOpen} onClose={() => {}} className='relative '>
      <Dialog.Backdrop className='fixed inset-0 bg-gray-800 z-l-2 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in' />

      <div className='font-poppins fixed inset-0 z-l-3 w-screen overflow-y-auto'>
        <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
          <Dialog.Panel className='relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-sm data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95'>
            <div className='bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4'>
              <div className=''>
                <div className='w-full relative'>
                  {!isDone && (
                    <div className='pb-5 flex item-center gap-4'>
                      <Dialog.Title
                        as='h3'
                        className='text-center  font-poppins sm:mt-0 sm:text-left  text-lg font-bold leading-6 text-gray-900'
                      >
                        Getting ready to demo
                      </Dialog.Title>
                      <div className='flex gap-2.5 items-center'>
                        <div className='motion-safe:animate-[new-ping_1s_0.2s_ease-in-out_infinite] h-1.5 w-1.5 bg-c-blue rounded-full' />
                        <div className='motion-safe:animate-[new-ping_1s_0.4s_ease-in-out_infinite] h-2 w-2 bg-c-blue rounded-full' />
                        <div className='motion-safe:animate-[new-ping_1s_0.8s_ease-in-out_infinite]  h-1.5 w-1.5 bg-c-blue rounded-full' />
                      </div>
                    </div>
                  )}
                </div>
                <Dialog.Description as='div' className='flex flex-col '>
                  {state.map((steps, i) => (
                    <div
                      key={steps.startTitle}
                      className={clsx({
                        ' before:border-double ': i !== 0 && steps.status === StageStatus.END,
                        'before:ml-4 before:block before:h-10 before:border-l-2 before:border-c-blue/50 before:border-dashed':
                          i !== 0,
                      })}
                    >
                      <div className='flex gap-4 my-2 items-center '>
                        <Show>
                          <Show.When isTrue={Boolean(steps.status === StageStatus.WORKING)}>
                            <div className='h-8 w-8 animate-spin rounded-full border-4 border-solid border-c-dark-blue-2 border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]' />
                          </Show.When>
                          <Show.When isTrue={Boolean(steps.status === StageStatus.END)}>
                            <div className='bg-c-dark-blue-2 rounded-full p-2'>
                              <VerifiedBadge className='h-5 w-5 stroke-c-white fill-transparent' />
                            </div>
                          </Show.When>
                          <Show.Otherwise>
                            <div className='bg-c-dark-blue-2 rounded-full p-2'>{steps.icon}</div>
                          </Show.Otherwise>
                        </Show>

                        {steps.error ? (
                          <p className={clsx('font-bold text-sm text-c-red-1')}>{steps?.error}</p>
                        ) : (
                          <p
                            className={clsx(
                              {
                                'text-c-dark-blue-1': steps?.status === StageStatus.END,
                                'text-c-blue': steps?.status === StageStatus.WORKING,
                                'text-c-blue/50': steps?.status === StageStatus.NONE,
                              },
                              'font-bold  font-poppins text-sm',
                            )}
                          >
                            {steps?.status === StageStatus.END ? steps.endTitle : steps.startTitle}
                          </p>
                        )}
                      </div>
                    </div>
                  ))}
                </Dialog.Description>
              </div>
            </div>
            {isDone && (
              <div className=' px-4 pt-3 pb-5 sm:flex sm:flex-row-reverse sm:px-6'>
                <CrowconButton
                  additionalClassName='flex flex-row justify-around items-center rounded-full md:rounded-4xl w-auto h-10 md:h-52 '
                  buttonAction={() => confirmUserInDemoMode()}
                  buttonChildren={
                    <p className='flex flex-row justify-between items-center px-6'>
                      <span className='text-bold text-lg  font-poppins'>Demo mode</span>
                      <ArrowRight className='fill-c-white h-8 w-8' />
                    </p>
                  }
                />
              </div>
            )}
          </Dialog.Panel>
        </div>
      </div>
    </Dialog>
  )
}
