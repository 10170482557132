/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import clsx from 'clsx'
import { SiteVersion } from 'utils/Constants'

interface footerLinkProps {
  sideNavExpanded: boolean
}
function FooterLinks(footerProps: footerLinkProps): JSX.Element {
  const { sideNavExpanded } = footerProps

  return (
    <div
      className={clsx(
        'flex flex-col duration-500 items-start gap-1 ',
        sideNavExpanded ? 'scale-100' : 'scale-0',
      )}
    >
      <div className={clsx(
        'flex flex-row justify-start w-full items-center ',
      )}>
        <div className='text-4xs font-bold text-c-white flex-grow'>
          <a href='https://www.crowcon.com/crowcon-portal-terms-of-use/' target='_blank' rel="noreferrer noopener">Terms</a>
        </div>
        <div className='text-4xs font-bold text-c-white flex-grow'>
          <a href='https://www.crowcon.com/privacy-policy/' target='_blank' rel="noreferrer noopener">Cookies</a>
        </div>
        <div className='text-4xs font-bold text-c-white flex-grow'>
          <a href='https://www.crowcon.com/privacy-policy/' target='_blank' rel="noreferrer noopener">Privacy</a>
        </div>
      </div>
      <div className='mt-1 text-3xs font-normal text-c-white'>{SiteVersion}</div>
    </div>
  )
}

export default FooterLinks
