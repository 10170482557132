import React from 'react'
import { NavLink } from 'react-router-dom'
import { clsx } from 'clsx'
import { SideNavMenuProps } from './Interfaces'

import { topNavMenuItems } from './SideNavMenuItemsList'

function TopNavMenu(topNavMenuProps: SideNavMenuProps) {
  const { OnItemSelected } = topNavMenuProps
  // console.log (`SideNAV ExpandedStatus: ${ExpandedStatus}`)

  return (
    <>
      {/* Separator */}

      <div className='grid grid-cols-1  md:grid-cols-3 justify-items-start'>
        {topNavMenuItems.map((menuItem) => (
          <div key={menuItem.Id} className={clsx(`${menuItem.className} mb-0.5 h-12 w-full `)}>
            <NavLink
              to={menuItem.Path}
              onClick={OnItemSelected}
              className={({ isActive, isPending }) =>
                clsx(
                  'flex w-full align-middle items-center rounded-3xl border-4 border-c-dark-blue-1 outline-0 focus:border-c-blue focus:border-4  h-12 mr-5 ',

                  isActive
                    ? 'bg-c-blue border-c-blue hover:bg-c-blue hover:border-c-blue'
                    : 'hover:bg-c-dark-blue-2 ',
                )
              }
            >
              <div
                className={clsx(
                  'w-full flex rounded-3xl  bg-inherit align-middle items-center duration-500 h-11 ',
                )}
              >
                <span className='pl-3 pr-5 '>{menuItem.Icon}</span>
                <span className={clsx('pr-2 text-base leading-5 font-bold text-c-white')}>
                  {menuItem.Label}
                </span>
              </div>
            </NavLink>
          </div>
        ))}
      </div>
    </>
  )
}

export default TopNavMenu
