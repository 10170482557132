import { useMsal } from '@azure/msal-react'
import { useMutation } from '@tanstack/react-query'
import { iCustomerList } from 'data/CustomerListHook'
import { StepsTypes } from '../../interfaces'
import { StageStatus } from '../../utils/constants'
import { getDemoCustomer, createDemoCustomer } from '../apis'

export function useCustomerMutation(
  state: StepsTypes[],
  setState: React.Dispatch<React.SetStateAction<StepsTypes[]>>,
) {
  const { instance: msalInstance } = useMsal()
  return useMutation<iCustomerList, Error, unknown>(
    () => {
      setState((prevState) => {
        const newState = [...prevState]
        newState[0].status = StageStatus.WORKING
        return newState
      })
      return getDemoCustomer({
        msalInstance,
      })
    },
    {
      retry: 3,
      retryDelay: 1000,
      onSuccess() {
        setState((prevState) => {
          const newState = [...prevState]
          newState[0].status = StageStatus.END
          return newState
        })
      },
      onError(error) {
        setState((prevState) => {
          const newState = [...prevState]
          newState[0].error = error.message
          return newState
        })
      },
    },
  )
}

export function useCreateCustomerMutation(
  state: StepsTypes[],
  setState: React.Dispatch<React.SetStateAction<StepsTypes[]>>,
) {
  const { instance: msalInstance } = useMsal()
  return useMutation<string, Error, unknown>(
    () => {
      setState((prevState) => {
        const newState = [...prevState]
        newState[0].startTitle = 'Setting up your customer details'
        newState[0].status = StageStatus.WORKING
        return newState
      })
      return createDemoCustomer({
        msalInstance,
      })
    },
    {
      retry: 3,
      retryDelay: 1000,
      onSuccess() {
        setState((prevState) => {
          const newState = [...prevState]
          newState[0].endTitle = 'Your customer details are ready.'
          newState[0].status = StageStatus.END
          return newState
        })
      },
      onError(error) {
        setState((prevState) => {
          const newState = [...prevState]
          newState[0].error = error.message
          return newState
        })
      },
    },
  )
}
