export const safetyListDataQueryKey = (
  startDate: string,
  endDate: string,

  businessUnitId?: string,
  // customerId?: string,
  // serverSideSearchParams?: iSafetySearchParams,
) => {
  // const searchParams = new URLSearchParams(serverSideSearchParams as any).toString()
  // const paginationParams = new URLSearchParams(serverSideSearchParams?.paginationParams as any)
  // const serverSearchParams = new URLSearchParams(
  //   serverSideSearchParams?.serverSideSearchParams as any,
  // )

  // if (serverSearchParams.get('serialNumber') === '') {
  //   serverSearchParams.delete('serialNumber')
  // }
  // if (serverSearchParams.get('AssetNumber') === '') {
  //   serverSearchParams.delete('AssetNumber')
  // }
  // if (serverSearchParams.get('assignedTo') === '') {
  //   serverSearchParams.delete('assignedTo')
  // }
  // if (serverSearchParams.get('toDate') === '') {
  //   serverSearchParams.delete('toDate')
  // }
  // if (serverSearchParams.get('fromDate') === '') {
  //   serverSearchParams.delete('fromDate')
  // }
  // if (serverSearchParams.get('gas') === '') {
  //   serverSearchParams.delete('gas')
  // }
  // if (serverSearchParams.get('Alarm') === '') {
  //   serverSearchParams.delete('Alarm')
  // }
  // if (serverSearchParams.get('Level') === '-1') {
  //   serverSearchParams.delete('Level')
  // }
  // if (serverSearchParams.get('Alarm1SetPoint') === '-1') {
  //   serverSearchParams.delete('Alarm1SetPoint')
  // }

  const queryKey = [
    'safety',
    'list',
    'businessUnit',
    businessUnitId,
    'startDate',
    startDate,
    'endDate',
    endDate,
    // 'serverSideSearchParams',
    // serverSearchParams.toString(),
    // 'paginationParams',
    // paginationParams.toString(),
  ]
  return queryKey
}

export const safetyQueries = {
  safetyListDataQueryKey,
}
