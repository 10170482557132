import React from 'react'
import { IselectOptionProps } from 'forms/FormModelInterface'
import { DropDownIcon, SearchIcon } from 'assets/icons'
import Select, { components, GroupBase, OptionProps } from 'react-select'
import CreatableSelect from 'react-select/creatable'
import Tooltip from 'components/atom/Tooltip'

interface selectControlProps {
  id?: string
  className?: string
  selectControlProps: any
  // data?: readonly IselectOptionProps[]
  // field?: any
  // placeHolder?: string
  // isMulti?: boolean
}

const maxItemsToShow = 5

function DropDownIndicator(props: any) {
  return (
    <components.DropdownIndicator {...props}>
      <DropDownIcon className='stroke-black fill-transparent' />
    </components.DropdownIndicator>
  )
}

function SearchIconIndicator(props: any) {
  return (
    <components.DropdownIndicator {...props}>
      <SearchIcon className='fill-transparent stroke-black' />
    </components.DropdownIndicator>
  )
}
function Option({ children, ...props }: OptionProps) {
  return (
    <components.Option {...props}>
      <div className='relative px-1 cursor-pointer my-1'>
        <Tooltip
          tooltipText={children}
          showOnlyWhenTextIsTruncate
          id={`form-selection-${children}`}
          toolTipClass='tooltip text-pretty  w-fit left-0 -translate-1/2 z-50 -top-10 px-2 py-1'
          className={` text-ellipsis text-c-blue font-bold text-xs px-3 py-2 z-50 hover:bg-c-white rounded-full ${
            JSON.stringify(props?.selectProps?.value) === JSON.stringify(props?.data)
              ? 'bg-c-white'
              : 'bg-transparent'
          }`}
        >
          {children}
        </Tooltip>
      </div>
    </components.Option>
  )
}

function CreatableSelectControl(controlProps: selectControlProps): JSX.Element {
  const { className, selectControlProps } = controlProps

  // const div = document.getElementById('creatableSelectDiv')

  // if (div) {
  //   div.addEventListener(
  //     'wheel',
  //     (e) => {
  //       if (e.deltaY > 0) {
  //         div.scrollBy(0, stepSize)
  //       } else {
  //         div.scrollBy(0, -stepSize)
  //       }
  //       e.preventDefault()
  //     },
  //     { passive: false },
  //   )
  // }

  return (
    <CreatableSelect
      maxMenuHeight={selectControlProps.maxMenuHeight ?? maxItemsToShow * 32}
      isSearchable={selectControlProps.isSearchable ?? true}
      components={
        selectControlProps.isDropDownSelectable === true
          ? { DropdownIndicator: DropDownIndicator, Option }
          : { DropdownIndicator: null, Option }
      }
      formatCreateLabel={(inputValue) => `Search "${inputValue}"`}
      filterOption={(option, inputValue) => {
        // if (!option || !inputValue || !option.data) {
        //   return false
        // }
        if ((option.data as IselectOptionProps).isHidden === true) {
          return false
        }
        return ((option.data as IselectOptionProps).label as string)
          ?.toLowerCase()
          .includes(inputValue?.toLowerCase())
      }}
      allowCreateWhileLoading
      createOptionPosition='first'
      className={className}
      {...selectControlProps}
      value={
        (selectControlProps.value as IselectOptionProps)?.value === ''
          ? null
          : selectControlProps.value
      }
      //  defaultValue={selectControlProps.defaultValue}
      // classNamePrefix=''
      // options={data}
      // isMulti={false}
      // placeholder={placeHolder ?? 'Select'}
      // onChange={(selectedOption) => field.onChange(selectedOption?.value as string)}

      // unstyled
      // classNames={{
      //   control: (state) => (state.isFocused ? 'self-stretch border-4 border-purple-600' : ''),
      //   option: (state) => ( state.isDisabled ? 'text-red-600 hover:bg-blue-200' : 'text-green-600 hover:bg-blue-200'),
      //   container: (state) => ('w-60 bg-red-200 rounded-xl border-2 border-c-dark-blue-2'),
      // }}

      // Following approach - using styles works to override base styles only necessary.
      // The classNames approach above does not work to override only few styles. We have to use unstlyed and then write all styles from scratch.
      // But with the styles approach we need to use plain css, we can't use tailwind classes.
      // Overall the classNames approach is better for our use case.

      styles={{
        control: (baseStyles, state) => ({
          ...baseStyles,
          // width: '100%',
          // backgroundColor: state.isDisabled ? '#EAF1F6' : '#FFFFFF', // c-light-blue-1
          borderRadius: '26px',
          fontFamily: 'Poppins',
          fontSize: '12px',
          fontWeight: '500',
          width: '100%',
          height: '100%',
          borderWidth: '3px',

          // borderColor: '#CEDCEB',

          borderColor: state.isFocused ? '#2767A4' : '#CEDCEB', //
          backgroundColor: state.isFocused ? '#EAF1F6' : '#FFFFFF', // c-light-blue-2

          // '&:focus': {
          //   outline: 'none',
          //   borderColor: 'red', // '#2767A4', // '',
          //   backgroundColor: 'chocolate', // '#EAF1F6', // c-light-blue-2
          // },
          '&:hover': {
            borderColor: '#1B4373',
            backgroundColor: '#FFFFFF',
          },
          ':active': {
            borderColor: '#2767A4',
            backgroundColor: '#2767A4', // c-light-blue-2
            color: '#FFFFFF',
          },

          // border-c-light-blue-2 #DDE8F2
          // hover:border-c-dark-blue-2 #1B4373
          // focus:border-c-blue #2767A4
          // active:border-c-blue #2767A4

          // focus:bg-c-light-blue-2
        }),

        noOptionsMessage: (baseStyles, state) => ({
          ...baseStyles,
          fontFamily: 'Poppins',
          fontSize: '11px',
          fontWeight: '500',
        }),
        valueContainer: (baseStyles, state) => ({
          ...baseStyles,

          // backgroundColor: state.isDisabled ? '#EAF1F6' : '#FFFFFF', // c-light-blue-1
          // backgroundColor: '#FFFFFF',
          // ':active': {
          //   // color: '#FFFFFF',
          //   backgroundColor: '#2767A4', // c-light-blue-2
          // },
          // ':disabled': {
          //   backgroundColor: '#EAF1F6', // c-light-blue-2
          // },
          paddingLeft: '9px',
          borderRadius: '26px',
          fontFamily: 'Poppins',
          fontSize: '12px',
          fontWeight: '500',
        }),
        singleValue: (baseStyles, state) => ({
          ...baseStyles,
          fontFamily: 'Poppins',
          fontSize: '12px',
          fontWeight: '500',
          // paddingLeft: '14px',
          // border: '2px solid red'
          // color: '#1B4373',
          // ':active': {
          //   color: '#FFFFFF',
          //   backgroundColor: '#2767A4', // c-light-blue-2
          //   // backgroundColor: 'red', // c-light-blue-2
          // },
          // ':disabled': {
          //   backgroundColor: '#EAF1F6', // c-light-blue-2
          // },
        }),
        indicatorSeparator: (baseStyles, state) => ({
          ...baseStyles,
          display: 'none',
        }),
        dropdownIndicator: (baseStyles, state) => ({
          ...baseStyles,
          display: (state.getValue() as IselectOptionProps[])?.length === 0 ? 'block' : 'none',
        }),
        clearIndicator: (baseStyles, state) => ({
          ...baseStyles,
          color: '#D91544',
          display:
            (state.getValue() as IselectOptionProps[])[0]?.value?.toString().length === 0
              ? 'none'
              : 'block',
        }),
        menu: (base) => ({
          ...base,
          borderRadius: '20px',
          backgroundColor: '#DDE8F2', // c-light-blue-2

          paddingBottom: '16px',
          paddingTop: '16px',

          paddingRight: '6px',

          marginTop: '0px',
          marginBottom: '0px',

          border: '3px solid #DDE8F2',
          scrollSnapType: 'y mandatory',
          // width: '100%',
          boxShadow: '0px 0px 40px 0px rgba(32, 71, 127, 0.60)',
        }),
        menuList: (base, state) => ({
          ...base,
          paddingBottom: '0px',
          paddingTop: '0px',
          marginTop: '0px',
          marginBottom: '0px',
          scrollSnapAlign: 'start',
          // borderRadius: '16px',
          // marginRight: '12px',
          // width: '100%'
        }),
        menuPortal: (base) => ({
          ...base,
          // width: '50px',
          // borderRadius: '16px',
          // paddingBottom: '0px',
          // paddingTop: '0px',
        }),
        option: (baseStyles, state) => ({}),
        placeholder: (baseStyles, state) => ({
          ...baseStyles,
          color: '#1B4373',
        }),

        // control: (baseStyles, state) => ({
        //   ...baseStyles,
        //   borderColor: state.isFocused ? 'red' : 'green',

        // }),
      }}
    />
  )
}

export default CreatableSelectControl
