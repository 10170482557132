import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import ExportData from 'exportReports/ExportData'
import { useGenericEventHandler } from 'data/GenericEventHandler'
import withRoleChecking from 'hoc/withRoleChecking'
import { useCustomersList } from '../data/CustomerListData'
import CustomerPageTable from '../components/CustomerPageTable'
import { CustomersReportColumns } from '../export/CustomerReportFormat'
import { Customer } from '../data/iCustomerList'

function CustomerListPage() {
  const location = useLocation()
  const redirectPageURL = `${location.pathname}${location.search}`
  const [showFilterForm, setShowFilterForm] = useState(false)
  const [filteredCustomer, setFilteredCustomer] = useState<Customer[]>()
  const [showExportDialog, setShowExportDialog] = React.useState(false)
  const { genericEventHandler } = useGenericEventHandler()
  useEffect(() => {
    document.title = 'Profile - Customers'
  }, [])

  useEffect(() => {
    function hideFilterFormHandler(e: KeyboardEvent) {
      if (e.key === 'Escape' && showFilterForm) {
        setShowFilterForm(false)
      }
    }
    window.addEventListener('keyup', hideFilterFormHandler)

    return () => {
      window.removeEventListener('keyup', hideFilterFormHandler)
    }
  }, [showFilterForm])

  const { data, isLoading, isError, error } = useCustomersList(redirectPageURL, true)

  useEffect(() => {
    if (isError) {
      genericEventHandler({
        onlyTrack: true,
        severity: 'error',
        error,
        message: error.message || 'Error in Customer List Data',
        extraData: {
          component: 'CustomerListPage',
          action: 'useCustomersList',
        },
      })
    }
  }, [error, isError])

  return (
    <div className='h-full pt-3'>
      <ExportData
        handleCancel={() => setShowExportDialog(false)}
        IsOpen={showExportDialog}
        data={filteredCustomer || data?.filter((customer) => customer.name !== 'All') || []}
        reportColumns={CustomersReportColumns}
        widgetForReport={[]}
        reportTitle='Customers'
        filterSummary=''
      />
      <div
        id='customers-main'
        className='flex flex-col h-full w-full'
        aria-hidden='true'
        onClick={() => showFilterForm && setShowFilterForm(false)}
      >
        <CustomerPageTable
          tableData={data?.filter((customer) => customer.id !== 'all') || []}
          isLoading={isLoading}
          filterValues={[]}
          updateFilteredData={setFilteredCustomer}
          onShowFilter={() => {}}
          onFilterFormSubmit={() => {}}
          onFilterFormReset={() => {}}
          setShowExportDialog={(val) => setShowExportDialog(val)}
        />
      </div>
    </div>
  )
}

export default withRoleChecking(CustomerListPage, [], true)
