import React from 'react'

import {
  CustomerIcon,
  BusinessUnitIcon,
  OperatorsIcon,
  DevicesIcon,
  DeviceLogIcon,
  SettingIcon,
  // SettingIcon,
  // UserAddIcon,
  // GasIcon,
} from 'assets/icons'
import { StepsTypes } from '../../interfaces'

export const DemoModeOption = [
  {
    label: 'Yes',
    value: true,
  },
  {
    label: 'No',
    value: false,
  },
]

export const DemoModeInitiateSteps: StepsTypes[] = [
  {
    startTitle: 'Fetching customer data, please wait...',
    endTitle: 'Customer data has been fetched.',
    status: '',
    error: '',
    icon: <CustomerIcon className=' w-5 h-5 stroke-white fill-transparent' />,
  },
  {
    startTitle: 'Loading business unit details...',
    endTitle: 'Business unit details are now ready.',
    status: '',
    error: '',
    icon: <BusinessUnitIcon className='w-5 h-5 stroke-white  fill-transparent' />,
  },
  {
    startTitle: 'We`re getting operator data...',
    endTitle: 'Operator data is ready',
    status: '',
    error: '',
    icon: <OperatorsIcon fill='transparent' stroke='white' className='w-5 h-5' />,
  },
  {
    startTitle: 'Fetching device data, please wait..',
    endTitle: 'Device data has been fetched.',
    status: '',
    error: '',
    icon: <DevicesIcon fill='transparent' stroke='white' className='w-5 h-5' />,
  },
  {
    startTitle: 'Setting up device configuration',
    endTitle: 'Device configured successfully',
    status: '',
    error: '',
    icon: <SettingIcon fill='transparent' stroke='white' className='w-5 h-5' />,
  },

  // {
  //   startTitle: 'Assigning device',
  //   endTitle: 'Device assigned',
  //   status: '',
  //   icon: <UserAddIcon fill='transparent' stroke='white' className='w-6 h-6' />,
  // },
  {
    startTitle: 'Loading log details...',
    endTitle: 'Log data is ready',
    status: '',
    error: '',
    icon: <DeviceLogIcon className='w-5 h-5 stroke-white fill-transparent' />,
  },
  // {
  //   startTitle: 'Generating gas logs',
  //   endTitle: 'Gas logs generated',
  //   status: '',
  //   icon: <GasIcon className='w-5 h-5 stroke-white fill-transparent' />,
  // },
]

export const StageStatus = {
  WORKING: 'WORKING',
  END: 'END',
  NONE: '',
}
