export const businessUnitsListForUserQueryKey = () => ['businessUnits', 'user']
export const businessUnitsListByCustomerIdQueryKey = (customerId: string) => [
  'businessUnits',
  'customer',
  customerId,
]

export const businessQueries = {
  businessUnitsListForUserQueryKey,
  businessUnitsListByCustomerIdQueryKey,
}
