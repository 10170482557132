import { IselectOptionProps } from 'forms/FormModelInterface'
import { BumpStatusTypes } from 'utils/CommonEnums'
import { BumpTestStatusValues } from 'utils/Constants'
import { selectAllOption } from 'forms/FormUtils'
import { iBumpHistory } from './interface'

function PrepareBumpHistoryDescriptionOptionsForSelect(
  bumpHistoryData: iBumpHistory[],
): IselectOptionProps[] {
  if (!bumpHistoryData || bumpHistoryData.length === 0) return []

  const eventDescriptions = new Array<IselectOptionProps>()

  bumpHistoryData.forEach((bumpHistoryItem) => {
    eventDescriptions.push({
      label: bumpHistoryItem.eventDescription,
      value: bumpHistoryItem.eventDescription,
    })
  })

  // Remove duplicates
  const uniqueEventDescriptions = eventDescriptions.filter(
    (eventDescription, index, self) =>
      index === self.findIndex((e) => e.value === eventDescription.value),
  )

  return uniqueEventDescriptions
}

function PrepareBumpHistoryUserOptionsForSelect(
  bumpHistoryData: iBumpHistory[],
): IselectOptionProps[] {
  if (!bumpHistoryData || bumpHistoryData.length === 0) return []

  const eventDeviceUsers = new Array<IselectOptionProps>()

  bumpHistoryData.forEach((bumpHistoryItem) => {
    if (bumpHistoryItem.assignedTo !== null) {
      eventDeviceUsers.push({
        label: bumpHistoryItem.assignedTo as unknown as string,
        value: bumpHistoryItem.assignedTo as unknown as string,
      })
    }
  })

  // Remove duplicates
  const uniqueEventDeviceUsers = eventDeviceUsers.filter(
    (eventDeviceUser, index, self) =>
      index === self.findIndex((e) => e.value === eventDeviceUser.value),
  )

  return uniqueEventDeviceUsers
}

export const PrepareBumpStatusTypeOptionsForSelect = (
  bumpHistoryData: iBumpHistory[],
): IselectOptionProps[] => {
  // temporary remove gasman devices for test
  // const deviceDataWithoutGasman = removeGasmanDevices(deviceData);

  // Get all enums in a string array
  const bumpStatusTypes: IselectOptionProps[] = []

  BumpTestStatusValues.forEach((bumpStatusValue) => {
    const isDisabled = !bumpHistoryData.some(
      (bumpHistoryItem) => bumpHistoryItem.bumpStatus.toString() === bumpStatusValue.value,
    )
    bumpStatusTypes.push({
      value: bumpStatusValue.value,
      label: bumpStatusValue.label,
      isDisabled,
    })
  })

  bumpStatusTypes.unshift(selectAllOption)

  return bumpStatusTypes
}

export const prepareBumpHistoryFormData = (bumpHistoryData: iBumpHistory[]) => {
  if (!bumpHistoryData || bumpHistoryData.length === 0)
    return { bumpStatusTypes: [], eventDescriptions: [], eventDeviceUsers: [] }

  const eventDescriptions = PrepareBumpHistoryDescriptionOptionsForSelect(bumpHistoryData)
  const eventDeviceUsers = PrepareBumpHistoryUserOptionsForSelect(bumpHistoryData)
  const bumpStatusTypes = PrepareBumpStatusTypeOptionsForSelect(bumpHistoryData)

  eventDescriptions.unshift(selectAllOption)
  // eventDeviceUsers.unshift(selectAllOption)

  return { bumpStatusTypes, eventDescriptions, eventDeviceUsers }
}
