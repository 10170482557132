import { CloseIcon } from 'assets/icons'
import CrowconButton from 'components/miscComponents/CrowconButton'
import { DateRange, IFilterValues } from 'forms/FormModelInterface'
import { DeviceStatusConstants, DeviceStatusEnums } from 'pages/devices-2/data/DevicesDataUtils'
import React from 'react'

type FilterSummaryProps = {
  filterCount: number
  onClearFilters: () => void
  filteredData: IFilterValues[]
}

function FilterSummary(props: FilterSummaryProps) {
  const { filterCount, onClearFilters, filteredData } = props
  return (
    <div className='bg-c-light-blue-4 w-full flex flex-row items-center px-4 py-4 h-20'>
      <div className='w-full'>
        <div className='text-sm mb-1 font-bold text-c-dark-blue-1'>
          Showing {filterCount} results matching:
        </div>
        <div className='text-3xs'>
          {filteredData.map((filter) => (
            <span className='leading-4' key={filter.columnId}>
              <span className='font-normal italic text-c-dark-blue-1'>{filter.columnId}</span>
              <span className='font-normal italic text-c-dark-blue-1'>&nbsp;&lsquo;&nbsp;</span>
              <span className='font-bold text-c-blue '>
                {filter.value?.toString() === DeviceStatusEnums.Active ||
                filter.value?.toString() === DeviceStatusEnums.Inactive
                  ? DeviceStatusConstants[filter.value?.toString() as DeviceStatusEnums]
                  : filter.value?.toString()}
              </span>
              <span className='font-normal italic text-c-dark-blue-1'>&lsquo;&#44;&nbsp;</span>

              {/* {(filter.value as DateRange) ? (
                <span className='font-bold'>
                  {`${(filter.value as DateRange)?.startDate?.toString()} to ${(
                    filter.value as DateRange
                  )?.endDate?.toString()}`}
                </span>
              ) : (
                <span className='font-bold'>{filter.value?.toString()}</span>
              )} */}

              {/* <span className='font-bold'>
                {(filter.value as DateRange) &&
                'from:'  (filter.value as DateRange)?.startDate
                (filter.value as DateRange)?.endDate
                  ? ' to '
                  : ''}
              </span> */}
            </span>
          ))}
        </div>
      </div>
      <div className='w-auto '>
        <CrowconButton
          additionalClassName='flex flex-row justify-around items-center rounded-full md:rounded-4xl w-10 md:w-44 h-10 md:h-52 '
          buttonAction={() => {
            onClearFilters()
          }}
          buttonChildren={
            <>
              <span className='pl-5 font-bold text-base leading-5 max-md:hidden'>Clear filter</span>
              <span className='md:pr-4 md:pl-3'>
                <CloseIcon />
              </span>
            </>
          }
        />
      </div>
    </div>
  )
}

export default FilterSummary

// {
//   /* {showSl && <div>Serial number:  <span> {filterParam?.SerialNumber}</span> </div>}
//        {showAn && <div>Asset number: <span> {filterParam?.AssetNumber}</span></div> }
//        {showDt && <div>Device type:<span> {filterParam?.DeviceType.label}</span></div>}
//        {showBu && <div>Business unit:<span> {filterParam?.BusinessUnit.label}</span></div>}
//        {showDs && <div>Device status:<span> {filterParam?.DeviceStatus.label}</span></div>}
//        {showDu && <div>Device user:<span> {filterParam?.DeviceUser}</span></div>}
//        {showSync && <div>Synced from:<span> <FormattedDate value={filterParam?.LastSync.startDate}/></span></div>}
//        {showSync && <div>Synced to:<span> <FormattedDate value={filterParam?.LastSync.endDate}/></span></div>}
//        {showSwit && <div>Switch on from:<span>  <FormattedDate value={filterParam?.LastSwitchOn.startDate}/></span></div>}
//        {showSwit && <div>Switch on to:<span> <FormattedDate value={filterParam?.LastSwitchOn.endDate}/></span></div>} */
// }
