import React from 'react'
import { SvgProps } from 'types/SVGProps'

function NavBackIcon(props: SvgProps): JSX.Element {
  const { stroke, fill, className, toggleClick } = props

  return (
    <div className={className}>
      <svg
        width='20'
        height='20'
        viewBox='0 0 20 20'
        fill={fill ?? 'none'}
        xmlns='http://www.w3.org/2000/svg'
        onClick={toggleClick}
      >
        <path
          d='M12.9648 17L5.99903 10L12.9648 3'
          stroke={stroke ?? '#FFFFFF'}
          strokeWidth='2.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </div>
  )
}

export default NavBackIcon
