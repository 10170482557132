import { IFilterQueryParamValues } from "forms/FormModelInterface"

export const DeviceEventLogFilterParamLabels = {
    dateRangeFrom: 'from',
    dateRangeTo: 'to',
    filterDateFrom: 'fdatefrom',
    filterDateTo: 'fdateto',
    eventDescription: 'fdesc',
    deviceUser: 'user',
}
