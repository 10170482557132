import { iBillingPlan, useSubscription } from 'data/CustomerListHook'
import { useGenericEventHandler } from 'data/GenericEventHandler'
import ExportData from 'exportReports/ExportData'
import React, { useEffect, useState } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'
import withRoleChecking from 'hoc/withRoleChecking'
import BillingPlansPageTable from '../components/BillingPlansPageTable'
import { BillingPlanReportColumns } from '../export/BillingPlanReportFormat'

function BillingPlans() {
  const location = useLocation()
  const redirectPageURL = `${location.pathname}${location.search}`
  const [showFilterForm, setShowFilterForm] = useState(false)
  const [filteredBillingPlans, setFilteredBillingPlans] = useState<iBillingPlan[]>()
  const [showExportDialog, setShowExportDialog] = React.useState(false)
  const { genericEventHandler } = useGenericEventHandler()

  useEffect(() => {
    document.title = 'Profile - Customers'
  }, [])

  useEffect(() => {
    function hideFilterFormHandler(e: KeyboardEvent) {
      if (e.key === 'Escape' && showFilterForm) {
        setShowFilterForm(false)
      }
    }
    window.addEventListener('keyup', hideFilterFormHandler)

    return () => {
      window.removeEventListener('keyup', hideFilterFormHandler)
    }
  }, [showFilterForm])

  const { data, isLoading, isError, error } = useSubscription(redirectPageURL, true)

  useEffect(() => {
    if (isError) {
      genericEventHandler({
        onlyTrack: true,
        severity: 'error',
        error,
        message: error.message || 'Error while fetching Business List Data',
        extraData: {
          component: 'BillingPlans',
          action: 'useSubscription',
        },
      })
    }
  }, [error, isError])
  return (
    <div className='h-full pt-3'>
      <ExportData
        handleCancel={() => setShowExportDialog(false)}
        IsOpen={showExportDialog}
        data={filteredBillingPlans || data || []}
        reportColumns={BillingPlanReportColumns}
        widgetForReport={[]}
        reportTitle='Billing Plans'
        filterSummary=''
      />
      <div
        id='billing-plans-main'
        className='flex flex-col h-full w-full'
        aria-hidden='true'
        onClick={() => showFilterForm && setShowFilterForm(false)}
      >
        <BillingPlansPageTable
          tableData={data}
          isLoading={isLoading}
          filterValues={[]}
          updateFilteredData={setFilteredBillingPlans}
          onShowFilter={() => {}}
          //   onFilterFormSubmit={() => {}}
          onFilterFormReset={() => {}}
          setShowExportDialog={(val) => setShowExportDialog(val)}
        />
      </div>
    </div>
  )
}
export default withRoleChecking(BillingPlans, [], true)
