import React from 'react'
import { ExportColumnType } from 'exportReports/DataExportInterfaces'
import { FormatBusinessUnit, FormatToShortDate } from 'exportReports/ExportDataUtils'

const SyncHistoryReportColumns: ExportColumnType[] = [
  {
    key: 'uploadDate',
    label: 'Date',
    format: (val: any) => FormatToShortDate(val as Date),
    cellWidth: 120,
  },
  {
    key: 'uploadLogType',
    label: 'Sync data',
    format: (val: string) => val,
    cellWidth: 200,
  },
  {
    key: 'clientType',
    label: 'Synced via',
    format: (val: string) => val,
    cellWidth: 200,
  },
]

export { SyncHistoryReportColumns }
