import React from 'react'
import { NavBackIcon } from 'assets/icons'
import Show from 'components/atom/Show'
import SelectControlWithLabel from 'components/formComponents/SelectControlWithLabel'
import { iBusinessUnit } from 'data/DeviceListHook'
import { useNavigate } from 'react-router-dom'
import { withErrorBoundary } from 'react-error-boundary'
import ErrorPage from 'pages/common/ErrorPage'
import { iDeviceDetails } from '../data/interface'

interface DeviceDetailsHeaderProps {
  deviceDetails?: iDeviceDetails
}

function DeviceDetailsHeader(deviceDetailHeaderProps: DeviceDetailsHeaderProps) {
  const { deviceDetails } = deviceDetailHeaderProps
  const navigate = useNavigate()

  return (
    <div className=' sticky top-0 right-0 z-20'>
      <div
        id='deviceDetailsHeader'
        className=' pl-3 md:pl-5 pr-3 md:pr-5 py-4 gap-2  flex-grow flex flex-wrap sm:flex-nowrap flex-row items-center justify-between bg-c-light-blue-4'
      >
        <div className='flex items-center w-full md:w-auto justify-between md:justify-start '>
          <div
            aria-hidden='true'
            className='flex p-3 justify-center items-center rounded-full  bg-c-dark-blue-1 hover:cursor-pointer hover:bg-c-dark-blue-2  active:bg-c-dark-blue-3 focus:border-1  focus:border-c-dark-blue-3 focus:bg-c-dark-blue-3 '
            onClick={() => navigate('/web/devices')}
          >
            <NavBackIcon />
          </div>
          <div className='ml-4'>
            <Show>
              <Show.When isTrue={Boolean(deviceDetails?.serialNumber)}>
                <p className='font-bold text-2xl'>{deviceDetails?.serialNumber}</p>
              </Show.When>
              <Show.Otherwise>
                <div className='w-16 h-3 rounded-lg  animate-pulse' />
              </Show.Otherwise>
            </Show>
          </div>
        </div>

        <div className='min-w-full  md:min-w-[340px]'>
          <SelectControlWithLabel
            isLoading={!deviceDetails}
            className='w-full md:pl-1 h-12'
            selectControlProps={{
              isDisabled: true,
              labelName: 'Business Unit',
              options: deviceDetails?.businessUnit,
              getOptionLabel: (option: iBusinessUnit) => option.name,
              getOptionvalue: (option: iBusinessUnit) => option.id,
              value: deviceDetails?.businessUnit,
              // defaultValue: selectedCustomerParam ?? customerList?.[0],
              isMulti: false,
              isSearchable: false,
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default withErrorBoundary(DeviceDetailsHeader, {
  FallbackComponent: ErrorPage,
})
