import React from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import clsx from 'clsx'

// check if user is logged in, else redirect to login
// if logged in then render

export function SusiLayoutContainer() {
  const location = useLocation()
  console.log(`SusiLayoutContainer - location ${location.pathname}${location.search}`)

  // I don`t think we need this here
  // const [fixedSideNav, setFixedSideNav] = React.useState(true)

  // useEffect(() => {
  //   const handleResize = () => {
  //     const windowHeight = window.innerHeight
  //     if (windowHeight < ScreenHeightInPx.Large) {
  //       setFixedSideNav(false)
  //     }
  //   }

  //   handleResize()
  //   window.addEventListener('resize', handleResize)

  //   return () => {
  //     window.removeEventListener('resize', handleResize)
  //   }
  // }, [])

  return (
    <>
      <div className={clsx(' font-poppins bg-c-white h-full w-full fixed top-0 left-0 z-0')}>
        {/* <Outlet /> */}
        <div id='background_branding_container' className='h-full w-full'>
          <img
            className='w-full h-full object-cover  overflow-hidden'
            src='https://v2devstorageaccount.blob.core.windows.net/b2cuiroot-new/crowcon-connect-bg-1.jpg'
            alt='Illustration'
          />
        </div>
      </div>

      <div className='flex flex-col justify-center align-middle absolute right-1/2 bottom-1/2 transform translate-x-1/2 translate-y-1/2 w-auto z-10 '>
        <div className='h-10 flex justify-center mb-4'>
          <img
            className='h-10'
            src='https://v2devstorageaccount.blob.core.windows.net/b2cuiroot-new/CrowconConnect_WhiteLogo-03.png'
            alt='logo'
          />
        </div>

        <Outlet />
      </div>
    </>
  )
}

// const appInsights = TelemetryAppInsights()
// if (appInsights) {
//   appInsights.loadAppInsights()
// }

// if (isAuthenticated) {
//   console.log('login isAuthenticated')
// } else if (inProgress === InteractionStatus.None) {
//   console.log('login NOT isAuthenticated')
//   instance
//     .loginRedirect()
//     .then((r) => {
//       console.log('Logged in')
//     })
//     .catch((err) => {
//       console.error(err)
//     })
// }

// if (isAuthenticated) {
//   return (
//     <div className=' mx-auto bg-c-light-blue-2 flex flex-row font-poppins'>
//       <SideNavContainer />
//       <Outlet />
//     </div>
//   )
// }
// return <div>Not authenticated</div>
