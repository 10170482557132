import { ExportColumnType } from 'exportReports/DataExportInterfaces'
import { BillingPlanColumnHeader, BillingPlanPageColumnIds } from '../utils/BillingPlansUtils'

const BillingPlanReportColumns: ExportColumnType[] = [
  {
    key: BillingPlanPageColumnIds.name,
    label: BillingPlanColumnHeader[BillingPlanPageColumnIds.name],
    format: (val: string) => val,
    cellWidth: 110,
  },
  {
    key: BillingPlanPageColumnIds.billingPlanType,
    label: BillingPlanColumnHeader[BillingPlanPageColumnIds.billingPlanType],
    format: (val: string) => val || '',
    cellWidth: 65,
  },
  // {
  //   key: BillingPlanPageColumnIds.numberOfApiKeys,
  //   label: BillingPlanColumnHeader[BillingPlanPageColumnIds.numberOfApiKeys],
  //   format: (val: string) => val || '',
  //   cellWidth: 65,
  // },
  {
    key: BillingPlanPageColumnIds.numberOfDevices,
    label: BillingPlanColumnHeader[BillingPlanPageColumnIds.numberOfDevices],
    format: (val: string) => val || '',
    cellWidth: 65,
  },
  {
    key: BillingPlanPageColumnIds.numberOfOperators,
    label: BillingPlanColumnHeader[BillingPlanPageColumnIds.numberOfOperators],
    format: (val: string) => val || '',
    cellWidth: 75,
  },
  {
    key: BillingPlanPageColumnIds.numberOfUsers,
    label: BillingPlanColumnHeader[BillingPlanPageColumnIds.numberOfUsers],
    format: (val: string) => val || '',
    cellWidth: 65,
  },
  {
    key: BillingPlanPageColumnIds.status,
    label: BillingPlanColumnHeader[BillingPlanPageColumnIds.status],
    format: (val: string) => val || '',
    cellWidth: 65,
  },
]

export { BillingPlanReportColumns }
