import { AlertIcon } from 'assets/icons'
import { useGenericEventHandler } from 'data/GenericEventHandler'
import React, { useEffect } from 'react'
import { isRouteErrorResponse, useNavigate, useRouteError } from 'react-router-dom'

type ErrorPageProps = { resetErrorBoundary?: () => void; error?: unknown }

export default function ErrorPage({ resetErrorBoundary, error: eventError }: ErrorPageProps) {
  const { genericEventHandler } = useGenericEventHandler()
  const error = useRouteError()
  const navigate = useNavigate()

  function goBack() {
    navigate(-1)
  }

  useEffect(() => {
    if (eventError || error) {
      console.log(error || eventError)
      genericEventHandler({
        onlyTrack: true,
        severity: 'error',
        message:
          (error as { message: 'string' })?.message ||
          (eventError as { message: 'string' }).message ||
          'This is generic error',
        error: error || eventError,
        extraData: {
          component: 'ErrorPage',
          action: 'fetch error at generic error page',
        },
      })
    }
  }, [error, eventError])

  return (
    <div className='p-2 bg-transparent h-full w-full '>
      <div className=' h-full w-full  flex justify-center items-center flex-col bg-c-white rounded-xl p-4 drop-shadow-sm'>
        <AlertIcon className='fill-c-red-1 stroke-none' />
        <div className='text-c-red-1 text-lg my-2'>Something went wrong</div>
        <button
          className='bg-c-dark-blue-1 rounded-full text-white px-4 py-2 text-md'
          type='button'
          onClick={isRouteErrorResponse(error) ? goBack : resetErrorBoundary}
        >
          Try again
        </button>
      </div>
    </div>
  )
}
ErrorPage.defaultProps = {
  resetErrorBoundary: () => {}, // Add a default value for the resetErrorBoundary prop
  error: null, // Add a default value for the error prop
}
