import React from 'react'
import { NavLink } from 'react-router-dom'
import { clsx } from 'clsx'
import Tooltip from 'components/atom/Tooltip'
import { SideNavMenuProps } from './Interfaces'

import { SideNavMenuSections } from './SideNavMenuItemsList'

function SideNavMenu(sideNavMenuProps: SideNavMenuProps) {
  const { ExpandedStatus, OnItemSelected } = sideNavMenuProps
  // console.log (`SideNAV ExpandedStatus: ${ExpandedStatus}`)

  //  isPending ? 'bg-c-red-1' :

  return (
    <>
      {SideNavMenuSections.map((section) => (
        <div key={section.Id} className='mb-4 '>
          {section.SideNavMenuItems.map((menuItem) => (
            <Tooltip
              key={menuItem.Id}
              tooltipText={menuItem.Label}
              shouldShow={!ExpandedStatus}
              className='relative '
              toolTipClass='tooltip absolute -top-7 left-0 w-fit right-0 text-center w-auto m-auto z-50 bg-slate-700 text-white p-[5px] rounded-xl font-poppins text-[10px] '
            >
              <div className='mb-3 ml-3  h-11 '>
                <NavLink
                  to={menuItem.Path}
                  onClick={OnItemSelected}
                  className={({ isActive }) =>
                    clsx(
                      'inline-flex relative align-middle items-center rounded-3xl border-4 border-c-dark-blue-1 outline-0 h-12 mr-5 ',
                      isActive
                        ? 'bg-c-blue border-c-blue hover:bg-c-blue '
                        : 'hover:bg-c-dark-blue-2 focus:border-4 focus:border-c-blue',
                    )
                  }
                >
                  <div
                    className={clsx(
                      'inline-flex rounded-3xl  bg-inherit align-middle items-center duration-500 h-10 ',
                      ExpandedStatus ? 'w-44' : 'w-10',
                    )}
                  >
                    <span className='pl-2.5 pr-5 '>{menuItem.Icon}</span>
                    <span
                      className={clsx(
                        'pr-2 text-base leading-5 font-bold text-c-white duration-500 ',
                        ExpandedStatus ? 'scale-100' : 'scale-0',
                      )}
                    >
                      {menuItem.Label}
                    </span>
                  </div>
                </NavLink>
              </div>
            </Tooltip>
          ))}
          <div
            className={clsx(
              'border-b-2 border-c-white/10  duration-500 mx-5',
              ExpandedStatus ? 'w-44' : 'w-8',
            )}
          />
        </div>
      ))}
    </>
  )
}

export default SideNavMenu
