import { useMsal } from '@azure/msal-react'
import { useQueryClient, useMutation } from '@tanstack/react-query'
import { EditIcon, AddIcon, MenuDotIcon } from 'assets/icons'
import { AxiosError } from 'axios'
import { useGenericEventHandler } from 'data/GenericEventHandler'
import React, { useState } from 'react'
import { CustomNodeElementProps } from 'react-d3-tree'
import { SubmitHandler } from 'react-hook-form'
import Show from 'components/atom/Show'
import { BusinessUnitTreeQueryKey } from 'services/apis/caching/users'
import OrganizationCrudMenu from '../components/OrganizationCrudMenu'
import OrganizationCrudModal from '../components/OrganizationCrudModal'
import {
  iBusinessUnitTreeData,
  iBusinessUnitTreePayloadParams,
} from '../utils/OrganizationInterface'
import {
  updateNodeInBusinessUnitTreeList,
  addNodeToBusinessUnitTreeList,
} from '../data/OrganizationPageData'
/**
 * Represents a component that renders a node in the organization tree.
 * @param nodeDatum - The data for the node.
 * @param toggleNode - A function to toggle the visibility of the node.
 * @returns The rendered business unit node component.
 */

export default function BusinessUnitTreeNode({ nodeDatum, toggleNode }: CustomNodeElementProps) {
  // useGenericEventHandler is a custom hook that provides a generic event handler.
  // genericEventHandler is a function that can be used to handle events.
  const { genericEventHandler } = useGenericEventHandler()

  // useQueryClient is a hook provided by react-query that returns the query client instance.
  // The query client is used to interact with and control the state of queries.
  const queryClient = useQueryClient()

  // useMsal is a hook provided by the MSAL (Microsoft Authentication Library) React library that returns the MSAL context.
  // The MSAL context contains various properties and methods related to authentication.
  const msalContext = useMsal()

  // nodeDatum is cast to iBusinessUnitTreeData.
  // This is done because nodeDatum is of a different type, but we know that it has the same shape as iBusinessUnitTreeData.
  const data = nodeDatum as unknown as iBusinessUnitTreeData

  // Initialize a state variable 'isOpen' with useState hook. This state will hold an object with two properties:
  // 'open': a boolean indicating whether the modal is open,
  // 'type': a string indicating the type of the modal.
  const [isOpen, setIsOpen] = useState<{
    open: boolean
    type: string
  }>({ open: false, type: '' })

  // Define a closeModal function that sets 'isOpen' state to an object with 'open' set to false and 'type' set to an empty string.
  // This function is used to close the modal.
  const closeModal = () => {
    setIsOpen({
      open: false,
      type: '',
    })
  }

  // Define an openModal function that takes a string 'type' and sets 'isOpen' state to an object with 'open' set to true and 'type' set to 'type'.
  // This function is used to open the modal.
  function openModal(type: string) {
    setIsOpen({
      open: true,
      type,
    })
  }
  // useMutation is a hook provided by react-query that is used to perform mutations (data modifying operations).
  // It takes a mutation function and an options object.

  // updateNodeInBusinessUnitTreeList is a function that updates a node in the organization business unit list.
  // The options object contains onSuccess and onError callbacks.

  // The onSuccess callback is called when the mutation is successful.
  // It invalidates queries with the query key returned by BusinessUnitTreeQueryKey(data?.customerId ?? '') and closes the modal.

  // The onError callback is called when the mutation fails.
  // It calls the genericEventHandler function with an object containing various properties related to the error.

  const updateNode = useMutation(updateNodeInBusinessUnitTreeList, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: BusinessUnitTreeQueryKey(data?.customerId ?? ''),
      })
      closeModal()
    },
    onError: (error) => {
      genericEventHandler({
        onlyTrack: false,
        message:
          (
            (error as unknown as AxiosError).response?.data as {
              detail: string
            }
          )?.detail || 'Error in updating node',
        error,
        type: 'Error',
        severity: 'error',
        extraData: {
          component: 'BusinessUnitTreeCrudModal',
          action: 'Error in updating node',
        },
      })
    },
  })

  // addNodeToBusinessUnitTreeList is a function that adds a node to the organization business unit list.
  // The options object contains onSuccess and onError callbacks.

  // The onSuccess callback is called when the mutation is successful.
  // It invalidates queries with the query key returned by BusinessUnitTreeQueryKey(data?.customerId ?? '') and closes the modal.

  // The onError callback is called when the mutation fails.
  // It calls the genericEventHandler function with an object containing various properties related to the error.

  const createNode = useMutation(addNodeToBusinessUnitTreeList, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: BusinessUnitTreeQueryKey(data?.customerId ?? ''),
      })
      closeModal()
    },
    onError: (error) => {
      genericEventHandler({
        onlyTrack: false,
        message:
          (
            (error as unknown as AxiosError).response?.data as {
              detail: string
            }
          )?.detail || 'Error in creating node',
        error,
        type: 'Error',
        severity: 'error',
        extraData: {
          component: 'BusinessUnitTreeCrudModal',
          action: 'Error in creating node',
        },
      })
    },
  })
  // Define an onSubmit function that will be called when the form is submitted.
  // This function takes an object 'formValue' with a 'name' property and performs a mutation based on the type of the modal.
  // If the modal type is 'edit', it calls the 'mutate' method of 'updateNode' with an object containing the MSAL context, the id of the business unit, and a payload with the id of the business unit and the name from 'formValue'.
  // If the modal type is not 'edit', it calls the 'mutate' method of 'createNode' with an object containing the MSAL context and a payload with the id of the parent business unit and the name from 'formValue'.
  const onSubmit: SubmitHandler<{
    name: string
  }> = (formValue) => {
    if (isOpen.type === 'edit') {
      updateNode.mutate({
        msalContext,
        buId: data.id,
        payload: {
          id: data.id,
          name: formValue.name,
        },
      } as iBusinessUnitTreePayloadParams)
    } else {
      createNode.mutate({
        msalContext,
        payload: {
          parentId: data.id,
          name: formValue.name,
        },
      } as iBusinessUnitTreePayloadParams)
    }
  }

  // Define an onError function that will be called when there is an error in the form.
  // This function takes an 'errors' object of unknown type and logs it to the console.
  const onError = (errors: unknown) => {
    console.log(errors)
  }

  return (
    <g className='group/item '>
      <circle
        id={data?.name as string}
        r={10}
        className='fill-c-light-blue-1 stroke-c-blue'
        strokeWidth={2}
        onClick={toggleNode}
      />

      <foreignObject className='w-[100%] md:w-[50%] h-[20%]  relative '>
        <div className='ml-5 bg-c-blue  text-white px-4 w-max py-2 flex items-center rounded-full '>
          <button type='button' title='Click to view more' onClick={toggleNode} className=''>
            <h3 className='text-sm text-bold '>{data.name}</h3>
            <h3 className='text-3xs '>{nodeDatum?.attributes?.buLevelName}</h3>
          </button>

          <OrganizationCrudMenu
            menuItemConfig={[
              {
                title: 'Edit',
                type: 'button',
                className: 'group flex items-center rounded-lg  data-[focus]:bg-white/10',
                onClick: () => openModal('edit'),
                children: (
                  <EditIcon
                    className='w-4 h-4 stroke-white fill-transparent'
                    stroke='white'
                    fill='transparent'
                  />
                ),
              },
              {
                title: 'Add',
                type: 'button',
                className: 'group flex items-center rounded-lg  data-[focus]:bg-white/10',
                onClick: () => openModal('add'),
                children: <AddIcon className='h-5 w-5  fill-c-white' fill='white' />,
              },
            ]}
          >
            <div className=''>
              <MenuDotIcon className='w-4 h-4' />
            </div>
          </OrganizationCrudMenu>
        </div>
      </foreignObject>

      <Show>
        <Show.When isTrue={isOpen.open}>
          <OrganizationCrudModal
            isOpen={isOpen.open}
            closeModal={closeModal}
            type={isOpen.type}
            formConfig={[
              {
                name: 'name',
                label: 'Name',
                placeHolder: 'Enter Name',
                rules: { required: 'Name is required' },
                defaultValue: isOpen.type === 'edit' ? data.name : '',
                value: '',
              },
            ]}
            onSubmit={onSubmit}
            onError={onError}
          />
        </Show.When>
      </Show>
    </g>
  )
}
