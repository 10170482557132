import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { AlertIcon } from 'assets/icons'
import { withPageTracking } from 'utils/AppInsightConfig'
import { useGenericEventHandler } from 'data/GenericEventHandler'
import { iUserInviteData, useUserInviteData } from '../data/SignupData'

import SignUpRedirect from './SignUpRedirect'
import LoginRedirect from './LoginRedirect'

export type RedirectProps = {
  userInviteData: iUserInviteData
}

// This is a functional component in React that represents a sign-up page.
function SignUpPage(): JSX.Element {
  const { genericEventHandler } = useGenericEventHandler()
  // The useParams hook is used to get the inviteId from the URL parameters.
  const { inviteId } = useParams<{ inviteId: string }>()

  // If there is no inviteId, the function returns a div with a message.
  if (!inviteId) {
    return <div>Invalid invite link</div>
  }

  // The useUserInviteData hook is used to fetch the user invite data.
  // It returns an object with the data, a loading state, and an error state.
  const {
    data: userInviteData,
    isLoading: isUserInviteLoading,
    isError: isUserInviteError,
    error: userInviteError,
  } = useUserInviteData(inviteId, !!inviteId)

  useEffect(() => {
    if (userInviteError) {
      genericEventHandler({
        onlyTrack: true,
        severity: 'error',
        message: userInviteError?.message || 'Error getting business units list',
        error: userInviteError,
        extraData: {
          component: 'CreateDeviceForm',
          action: 'get business units list',
        },
      })
    }
  }, [userInviteError])

  // The function returns a JSX element that depends on the state of the user invite data.
  return (
    <div className='font-poppins md:rounded-3xl bg-c-white h-auto w-full  mt-[34px]'>
      <div className='pt-[30px] pb-[30px] w-full px-1 md:px-12  flex  '>
        {/* If the user invite data is valid and the status is 'Invited', a SignUpRedirect component is rendered. */}
        {userInviteData &&
          userInviteData?.isInvitationValid &&
          userInviteData?.status === 'Invited' && (
            <SignUpRedirect userInviteData={userInviteData} />
          )}
        {/* If the user invite data is valid and the status is 'Active', a LoginRedirect component is rendered. */}
        {userInviteData &&
          userInviteData?.isInvitationValid &&
          userInviteData?.status === 'Active' && <LoginRedirect userInviteData={userInviteData} />}
        {/* If the user invite data is not valid, a div with a message is rendered. */}
        {userInviteData && !userInviteData?.isInvitationValid && (
          <div className='text-4xl font-bold font-poppins  tracking-tight text-c-dark-blue-1'>
            Invitation expired or invalid
          </div>
        )}

        {/* If there was an error fetching the user invite data, a div with an error message is rendered. */}
        {isUserInviteError && (
          <div className='flex flex-col items-center justify-around gap-x-10 '>
            <AlertIcon fill='red' className='w-10 h-10 my-4' />
            <div className='max-w-xl text-red-500 text-sm font-medium leading-5  text-left'>
              Error verifying your account invitation
            </div>
          </div>
        )}
        {/* If the user invite data is still loading, a div with a loading message is rendered. */}
        {isUserInviteLoading && (
          <div className='flex  flex-col justify-center items-center gap-x-10'>
            <div className='max-w-xl text-lg font-medium leading-5 text-c-dark-blue-1 text-left'>
              Verifying your account invitation
            </div>
            <div className='p-8 mt-6 animate-spin rounded-full border-4 border-solid border-c-dark-blue-1 border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]' />
          </div>
        )}
      </div>
    </div>
  )
}
export default withPageTracking(SignUpPage)
