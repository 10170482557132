import { UserAccountContext } from 'contexts/UserAccountContext'
import { useUserHasRequiredRoles } from 'hooks'
import NotFound from 'pages/common/NotFound'
import React, { useContext } from 'react' // Adjust the import path to where your UserContext is defined

// The functional component version of the HOC
function withRoleChecking<T>(
  WrappedComponent: React.ComponentType<T>,
  requiredRoles?: string[],
  shouldShowNoAccess = false,
) {
  // The functional component that checks user roles and decides what to render
  function RoleCheckingComponent(props: React.PropsWithChildren<T>) {
    const { state: userInfo } = useContext(UserAccountContext)
    const userHasRequiredRoles = useUserHasRequiredRoles(requiredRoles)
    if (!userHasRequiredRoles) {
      // If the user does not have the required roles, redirect or show an error message
      return shouldShowNoAccess ? (
        <div className='bg-c-white'>
          <NotFound />
        </div>
      ) : (
        <div />
      )
    }

    // If the user has the required roles, render the wrapped component
    return <WrappedComponent {...(props as T)} userInfo={userInfo} />
  }

  return RoleCheckingComponent
}

export default withRoleChecking
