import React from 'react'
import { ExportColumnType } from 'exportReports/DataExportInterfaces'
import { FormatUTXToDateTime, FormatBumpResult } from 'exportReports/ExportDataUtils'

const BumpHistoryReportColumns: ExportColumnType[] = [
  {
    key: 'timeStamp',
    label: 'Date & time',
    format: (val: any) => FormatUTXToDateTime(val),
    cellWidth: 100,
  },
  {
    key: 'bumpStatus',
    label: 'Bump result',
    format: (val: string) => FormatBumpResult(val),
    cellWidth: 80,
  },
  {
    key: 'eventDescription',
    label: 'Description',
    format: (val: string) => val,
    cellWidth: 240,
  },
  {
    key: 'assignedTo',
    label: 'Assigned to',
    format: (val: string) => val,
    cellWidth: 80,
  },
]

export { BumpHistoryReportColumns }
