import { useMsal } from '@azure/msal-react'
import React from 'react'
import { loginRequest } from 'utils/AuthConfig'

export default function ErrorPageGeneric({
  errorMessage,
}: {
  errorMessage: string

}) {
  const { instance,  accounts } = useMsal()

  const logout = () => {
    // logout logic here
    instance.logoutRedirect(loginRequest)
  }

  return (
    <div>
      {/* <div className='font-poppins grid h-screen px-4 bg-c-light-blue-4 place-content-center'>
        <div className='text-center'>
          <h1 className='font-black text-gray-200 text-9xl px-56 py-5'>
            <AlertIcon />
          </h1>

          <p className='text-xl font-medium tracking-tight text-black sm:text-2xl'>
            An Error occurred
          </p>
          <div className='px-20'>
            <p className='mt-4 mb-4 text-black text-sm font-medium w-80'>{errorMessage}</p>
          </div>
          <a
            href='mailto:techsupport@crowcon.com'
            className=' mt-6 leading-6 underline text-c-dark-blue-4 hover:text-indigo-500'
          >
            Contact support
          </a>
        </div>
      </div> */}

      <main className='font-poppins grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8'>
        <div className='text-center'>
          <h1 className='mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl'>
            An Error occurred
          </h1>
          <p className='mt-6 text-base leading-7 text-gray-600'>{errorMessage}</p>

          <div className='mt-10 flex items-center justify-center gap-x-6'>
            {accounts.length > 0 && (
              <button
                type='button'
                onClick={() => logout()}
                className='font-medium text-yellow-700 underline hover:text-yellow-600'
              >
                Sign out
              </button>
            )}
            <a
              href='mailto:techsupport@crowcon.com'
              className='text-sm font-semibold text-gray-900'
            >
              Contact support <span aria-hidden='true'>&rarr;</span>
            </a>
          </div>
        </div>
      </main>
    </div>
  )
}
