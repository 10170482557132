import React, { useEffect, useState } from 'react'

import { iComplianceCount, iDeviceTypeCount } from 'pages/devices/data/iDevicesList'
import { iDeviceListDataWithStats } from 'pages/devices-2/data/DevicePageData'
import GenericWidget from 'components/viewComponents/GenericWidget'
import { iWidgetData } from 'components/viewComponents/interfaces'
import { groupDeviceDataByComplianceType } from 'pages/devices/data/DeviceDataUtils'
import { IFilterValues } from 'forms/FormModelInterface'
import { withErrorBoundary } from 'react-error-boundary'
import ErrorPage from 'pages/common/ErrorPage'
import { DevicePageColumnIds } from '../DevicesPageUtils'
// export interface iWidgetData {
//     originalData: string | string[] | number | boolean | DateRange | null
//     displayText: string
//     value: number
// }

// export interface iWidgetProps {
//     title: string
//     widgetData: iWidgetData[]
//     widgetLabelCount: number
//     // onFilter: (filterRequestValue: string | string[] | number | boolean | DateRange | null) => void
// }
function DeviceCountByComplianceWidget({
  deviceListData,
  onFilter,
  filteredParams,
}: {
  deviceListData: iDeviceListDataWithStats[]
  onFilter: (filterRequestValues: IFilterValues[]) => void
  filteredParams: string[]
}): JSX.Element {
  const [widgetData, setWidgetData] = useState<iWidgetData[]>()

  const onFilterData = (filterRequestValues: IFilterValues[]) => {
    const filterRequestValueToSend: IFilterValues[] = []
    filterRequestValues.forEach((item) => {
      if (item.value !== '') {
        if (item.columnId === DevicePageColumnIds.deviceUser) {
          filterRequestValueToSend.push({
            columnId: item.columnId,
            value: {
              label: item.value as string,
              value: item.value as string,
            },
          })
        } else {
          filterRequestValueToSend.push({
            columnId: item.columnId,
            value: {
              startDate: '',
              endDate: '',
              isCustom: true,
              customValue: item.value,
            },
          })
        }
      } else {
        filterRequestValueToSend.push({
          columnId: item.columnId,
          value: '',
        })
      }
    })

    if (onFilter) {
      onFilter(filterRequestValueToSend)
    }
  }

  useEffect(() => {
    const wdata: iWidgetData[] = []

    if (deviceListData) {
      const deviceComplianceParamCounts = groupDeviceDataByComplianceType(deviceListData)
      deviceComplianceParamCounts.forEach((item) => {
        wdata.push({
          originalData: item.complianceFilter,
          displayText: item.complianceParam,
          value: item.count,
          isFiltered: filteredParams.includes(item.complianceFilter),
          columnId: item.columnId,
        })
      })
      setWidgetData(wdata)
    }
  }, [deviceListData, filteredParams])

  return (
    <GenericWidget
      widgetProps={{
        title: 'Compliance',
        widgetData,
        widgetLabelCount: 4,
        onFilter: (filterRequestValues: IFilterValues[]) => onFilterData(filterRequestValues),
      }}
    />
  )
}

export default withErrorBoundary(DeviceCountByComplianceWidget, { FallbackComponent: ErrorPage })
