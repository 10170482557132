import React from 'react'
import { Navigate } from 'react-router-dom'

function RootPage(): JSX.Element {
  console.log(window.location.hash)
  return <Navigate replace to='/web' />

  // if (process.env.NODE_ENV === 'production') {
  //   return <Navigate replace to='/web' />
  // }
  // return <LayoutContainer />
}
export default RootPage
