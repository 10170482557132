import { getAccessToken, getAccessTokenProps } from 'services/MSALService'
import { AxiosError } from 'axios'
import { parseAPIErrorv01 } from 'services/APIErrorParser'
import ConnectAPIClient from 'services/ConnectAPIClient'
import { crudOperatorURL } from 'services/apis/urls/operators'

export interface iAddOperatorDTO {
  firstName: string
  lastName: string
  businessUnitId: string
  customerId: string
  uniqueId: string
  trainingDetails: []
}

export interface iAddOperatorProps {
  operatorToAdd: iAddOperatorDTO
  accessTokenProps: getAccessTokenProps
}

export async function addOperator(addOperatorProps: iAddOperatorProps) {
  const { operatorToAdd, accessTokenProps } = addOperatorProps
  try {
    const apiURL = crudOperatorURL()
    const token = await getAccessToken(accessTokenProps)
    const resp = await ConnectAPIClient.post<[iAddOperatorDTO]>(apiURL, token, undefined, [
      operatorToAdd,
    ])
    const response = resp.data
    return response
  } catch (error) {
    const axiosError = error as AxiosError
    const errorDescription = parseAPIErrorv01(axiosError)
    throw new Error(errorDescription)
  }
}
