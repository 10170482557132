import { Column } from '@tanstack/react-table'
import React, { forwardRef, useEffect, useRef, useState } from 'react'
import DatePicker from 'react-datepicker'
import clsx from 'clsx'
import 'react-datepicker/dist/react-datepicker.css'
import { SearchIcon } from 'assets/icons'
import { FormattedDate } from 'react-intl'
import { debounce } from 'components/modules/Calender/CalenderUtils'

type tsTableFilterProps<T> = {
  columnData: Column<T>
  placeHolder?: string
  className?: string
}

interface DebouncedInputProps {
  inputValue: string
  placeHolder?: string
  listId?: string
  className?: string
  onChange: (value: string | number) => void
}

export interface globalFilterProps {
  filterValue: string
  updaterFn: (value: string) => void
}

function DebouncedInput(props: DebouncedInputProps) {
  const { inputValue, onChange, placeHolder, listId, className } = props

  const [value, setValue] = React.useState(inputValue)

  const debounceInput = debounce(onChange, 500)

  return (
    <input
      type='text'
      placeholder={placeHolder}
      className={clsx(
        className,
        'rounded-4xl placeholder-c-dark-blue-1  px-3 h-52 font-normal outline-0 w-full border-3 border-c-light-blue-4 hover:border-c-dark-blue-1 focus:border-c-blue active:bg-c-blue active:placeholder-c-white',
      )}
      value={value}
      onChange={(e) => {
        const trimValue = e.target.value.trim()
        setValue(trimValue)
        debounceInput(trimValue)
      }}
      list={listId ?? undefined}
    />
  )
}

export function TextFilter<T>(column: tsTableFilterProps<T>): JSX.Element {
  const { columnData, placeHolder, className } = column
  const filterValue = columnData.getFilterValue()
  // const plcholder = `Search ${columnData.getFacetedUniqueValues().size} records`
  const plcholder = ''
  return (
    <DebouncedInput
      inputValue={(filterValue as string) || ''}
      onChange={(value: string | number) => columnData.setFilterValue(value)}
      placeHolder={plcholder}
      className={className}
    />
  )
}

export function TextFilterWithIcon<T>(column: tsTableFilterProps<T>): JSX.Element {
  const { columnData, placeHolder, className } = column
  const filterValue = columnData.getFilterValue()
  // const plcholder = `Search ${columnData.getFacetedUniqueValues().size} records`
  const plcholder = ''
  return (
    <div className='flex flex-grow relative w-auto group/search-input'>
      <DebouncedInput
        inputValue={(filterValue as string) || ''}
        onChange={(value: string | number) => columnData.setFilterValue(value)}
        placeHolder={plcholder}
        className={className}
      />
      {/* <span className='absolute inset-y-0 right-0 flex items-center pr-4'>
        <SearchIcon className='fill-transparent stroke-black' />
      </span> */}
      <i className='absolute inset-y-0 right-0 flex items-center pr-4'>
        <SearchIcon className='fill-transparent stroke-black group-active/search-input:stroke-white group-focus/search-input:stroke-white' />
      </i>
    </div>
  )
}

interface datePickerCustomInputProps {
  className: string
  value: string
  onChange: (value: string) => void
  onClick: () => void
}

const DatePickerCustomInput = forwardRef<HTMLInputElement, any>((props: any, ref) => {
  const { className, onChange, onClick, value, startDate, endDate } = props

  // button onclick
  //   input onchange -> onchange
  //   label
  //   input onchange -> onchange

  return (
    <div
      className='h-12 py-3 px-2 rounded-lg'
      onClick={onClick}
      onKeyDown={onClick}
      role='button'
      tabIndex={0}
      ref={ref}
      onChange={onChange}
    >
      <div className='flex flex-row justify-around'>
        <div className='text-sm font-normal pl-4 pr-3'>
          {startDate !== null ? <FormattedDate value={startDate} /> : null}
        </div>
        <div className='text-sm font-bold text-c-light-blue-4'>|</div>
        <div className='text-sm font-normal pl-3 pr-4'>
          {endDate !== null ? <FormattedDate value={endDate} /> : null}
        </div>
      </div>
    </div>
  )
})

DatePickerCustomInput.displayName = 'datepicker'

export function DatePickerFilter<T>(column: tsTableFilterProps<T>): JSX.Element {
  const { columnData, placeHolder, className } = column
  const filterValue = columnData.getFilterValue()
  const plcholder = `Search ${columnData.getFacetedUniqueValues().size} records`
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)

  React.useEffect(() => {
    if (filterValue == null) {
      setStartDate(null)
      setEndDate(null)
    }
  }, [filterValue])

  const onDatesChange = (dates: any) => {
    const [start, end] = dates
    setStartDate(start)
    setEndDate(end)
    const dateRange = {
      start,
      end,
    }
    columnData.setFilterValue(dateRange)
  }

  const onCalendarClose = () => {
    const dateRange = {
      startDate,
      endDate,
    }
    columnData.setFilterValue(dateRange)
  }
  const inputRef = useRef(null)

  return (
    <div className={clsx(className, 'bg-c-white flex flex-row  rounded-3xl')}>
      <DatePicker
        className={className}
        onChange={onDatesChange}
        startDate={startDate}
        endDate={endDate}
        selectsRange
        onCalendarClose={onCalendarClose}
        placeholderText={plcholder}
        enableTabLoop={false}
        customInput={
          <DatePickerCustomInput props={inputRef} startDate={startDate} endDate={endDate} />
        }
        isClearable={false}
      />
    </div>
  )
}

export function SelectFilter<T>(column: tsTableFilterProps<T>): JSX.Element {
  const { columnData, placeHolder, className } = column
  const filterValue = columnData.getFilterValue()
  const columnValues = Array.from(columnData.getFacetedUniqueValues().keys()).sort()

  const plcholder = `Search ${columnData.getFacetedUniqueValues().size} records`
  return (
    <>
      <datalist id={columnData.id}>
        {columnValues.map((value) => (
          <option value={value} key={value} />
        ))}
      </datalist>
      <DebouncedInput
        inputValue={(filterValue as string) || ''}
        onChange={(value: string | number) => columnData.setFilterValue(value)}
        placeHolder=''
        listId={columnData.id}
        className={clsx(className)}
      />
    </>
  )
}

export function GlobalFilter(props: globalFilterProps): JSX.Element {
  const { filterValue, updaterFn } = props

  return (
    // https://www.kindacode.com/snippet/tailwind-css-create-a-search-field-with-an-icon-inside/
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <label className='relative w-full group/search-input'>
      <DebouncedInput
        className={clsx(' rounded-3xl font-medium  inline-flex pl-4')}
        placeHolder='Search'
        inputValue={filterValue ?? ''}
        onChange={(value) => updaterFn(String(value))}
      />
      <span className='absolute inset-y-0 right-0 flex items-center pr-4'>
        <SearchIcon className='fill-transparent stroke-black group-active/search-input:stroke-white group-focus/search-input:stroke-white' />
      </span>
    </label>
  )
}
