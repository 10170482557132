import React, { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import CreateOperatorForm from './createOperatorsSteps.tsx/CreateOperatorForm'

interface CreateOperatorProps {
  isOpened: boolean
  handleClose: () => void
}

function CreateOperator(props: CreateOperatorProps): JSX.Element {
  const { handleClose, isOpened } = props
  const [formStep, setFormStep] = React.useState<number>(0)
  const nextFormStep = () => setFormStep((currentStep) => currentStep + 1)
  const prevFormStep = () => setFormStep((currentStep) => currentStep - 1)
  const handleCancel = () => {
    handleClose()
  }

  return (
    <Dialog open={isOpened} onClose={() => {}} className='relative z-50 rounded-2xl'>
      <div className='fixed inset-0 bg-black/30' aria-hidden='true' />
      <div className='fixed inset-0 w-screen overflow-y-auto'>
        <div className='flex min-h-full items-center justify-center p-4'>
          <Dialog.Panel className='mx-auto w-96 rounded-2xl bg-white'>
            <div className=' xl:h-auto xl:w-auto text-c-dark-blue-1 font-bold text-3xs font-poppins flex flex-col'>
              <CreateOperatorForm
                formStep={0}
                maxStep={3}
                nextFormStep={() => nextFormStep()}
                confirmCancel={() => handleCancel()}
              />
            </div>
          </Dialog.Panel>
        </div>
      </div>
    </Dialog>
  )
}

export default CreateOperator
