import React from 'react'
import clsx from 'clsx'

import { GlobalFilter } from '../../TsTableFilters'
import { tsTableFooterProps } from './TsTableFooter'

interface tableHeaderProps extends tsTableFooterProps {
  showGlobalActionButton: boolean
  globalActionButton?: JSX.Element
  showGlobalFilter: boolean
  globalFilterValue: string
  setGlobalFilter: (value: string) => void
  setShowExportDialog: (val: boolean) => void
  onShowFilter?: () => void
  ExportTableDataComponent?: JSX.Element
  FilterTableDataComponent?: JSX.Element
  ColumnVisibilityComponent?: JSX.Element
  // TableFilterComponent: JSX.Element
}

const TsTableHeader = React.memo((headerProps: tableHeaderProps) => {
  // function TsTableHeader(headerProps: tableHeaderProps) {

  // const { setShowFilter, showFilterStatus } = useContext(ReportPageContext)

  const {
    globalFilterValue,
    setGlobalFilter,
    globalActionButton,
    showGlobalActionButton,
    ColumnVisibilityComponent,
    ExportTableDataComponent,
    FilterTableDataComponent,
    // TableFilterComponent,
  } = headerProps

  // const windowSize = useRef([window.innerHeight, window.innerWidth])

  return (
    <div
      className={clsx(
        'gap-2 lg:gap-4  flex flex-row justify-between items-center px-3 md:px-5 py-3 md:py-4 ',
      )}
    >
      <div className='flex flex-grow '>
        <div className='flex items-center justify-start w-full md:w-80 z-0'>
          <GlobalFilter
            filterValue={globalFilterValue}
            updaterFn={(value) => setGlobalFilter(String(value))}
          />
        </div>
        {showGlobalActionButton && (
          <div className='ml-2 flex items-center justify-start'>{globalActionButton}</div>
        )}
      </div>

      {/* <div className=''>
        <TsTablePagination
          pageSize={pageSize}
          setPageSize={setPageSize}
          pageIndex={pageIndex}
          setPageIndex={setPageIndex}
          nextPage={nextPage}
          previousPage={previousPage}
          pageCount={pageCount}
          canNextPage={canNextPage}
          canPreviousPage={canPreviousPage}
          totalRecords={totalRecords}
        />
      </div> */}

      <div className='flex flex-row items-center gap-2 lg:gap-4'>
        {ExportTableDataComponent}
        {ColumnVisibilityComponent}
        {FilterTableDataComponent}
      </div>

      {/* <div>
        <button
          type='button'
          tabIndex={0}
          onClick={toggleFilterFormVisibility} >
            Filter
          </button>
      </div> */}

      {/* <div className={clsx(showFilterForm ? 'block' : 'hidden 2xl:block ')} >
        {TableFilterComponent}
      </div> */}
    </div>
  )
})

TsTableHeader.displayName = 'Table header'

export default TsTableHeader
