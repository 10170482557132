import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'

import { DashboardDataSmall } from 'components/viewComponents'
import { iDeviceTypeCount } from 'pages/devices/data/iDevicesList'
import { iFaultData } from 'pages/service-faults/data/FaultsData'
import { IFilterValues } from 'forms/FormModelInterface'
import { iWidgetData } from 'components/viewComponents/interfaces'
import { groupFaultsByDeviceType } from 'pages/service-faults/data/FaultsDataUtils'
import GenericWidget from 'components/viewComponents/GenericWidget'
import { withErrorBoundary } from 'react-error-boundary'
import ErrorPage from 'pages/common/ErrorPage'
import { ServiceFaultsColumnIds } from '../ServiceFaultsPageUtils'

function FaultsByDeviceTypeWidget({
  faultsData,
  onFilter,
  deviceTypeFilteredParam,
}: {
  faultsData: iFaultData[]
  onFilter: (filterRequestValues: IFilterValues[]) => void
  deviceTypeFilteredParam: string
}): JSX.Element {
  const intl = useIntl()
  const [widgetData, setWidgetData] = useState<iWidgetData[]>()

  const onFilterData = (filterRequestValues: IFilterValues[]) => {
    if (onFilter) {
      onFilter(filterRequestValues)
    }
  }

  useEffect(() => {
    const wdata: iWidgetData[] = []

    if (faultsData) {
      const deviceTypeCount: iDeviceTypeCount[] = groupFaultsByDeviceType(faultsData)
      deviceTypeCount.forEach((item) => {
        wdata.push({
          originalData: item.deviceType,
          displayText: item.deviceType,
          value: item.count,
          isFiltered: item.deviceType === deviceTypeFilteredParam,
          columnId: ServiceFaultsColumnIds.deviceType,
        })
      })
      setWidgetData(wdata)
    }
  }, [faultsData, deviceTypeFilteredParam])

  return (
    <GenericWidget
      widgetProps={{
        title: 'Type',
        columnId: ServiceFaultsColumnIds.deviceType,
        widgetData,
        widgetLabelCount: 4,
        onFilter: (filterRequestValues: IFilterValues[]) => onFilterData(filterRequestValues),
      }}
    />
  )
}

export default withErrorBoundary(FaultsByDeviceTypeWidget, {
  FallbackComponent: ErrorPage,
})
