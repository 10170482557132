import React from 'react'
import { SvgProps } from 'types/SVGProps'

function FilterIcon(props: SvgProps): JSX.Element {
  const { stroke, fill, className, width, height, toggleClick } = props

  return (
    <div className=''>
      <svg
        className={className}
        width={width ?? '22'}
        height={height ?? '22'}
        viewBox='0 0 22 21'
        fill={fill ?? 'none'}
        onClick={toggleClick}
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M20.125 1.78711H1.875L9.175 10.8989V17.1982L12.825 19.1246V10.8989L20.125 1.78711Z'
          stroke={stroke ?? '#FFFFFF'}
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </div>
  )
}

export default FilterIcon
