import { iBusinessUnitDetails } from 'data/Interfaces'
import { iDeviceConfig } from 'data/DeviceConfigHook'
import { addDays, subDays } from 'date-fns'
import {
  DataForUserType,
  DemoDevicesCreationResponse,
  DemoOperators,
  StepsTypes,
} from '../../interfaces'
import { useBusinessUnitMutation } from './business'
import { useCustomerMutation, useCreateCustomerMutation } from './customer'
import { useDeviceMutation, useConfigurationMutation } from './device'
import { useLogsMutation } from './logs'
import { useOperatorMutation } from './operator'

export const generateRandomLast30DaysDate = () => {
  const daysAgo = Math.floor(Math.random() * 30) + 1
  return subDays(new Date(), daysAgo).toISOString().split('T')[0]
}
export const generateRandomNextXDaysDate = (numberOfDays: number, addExtraDays?: number) => {
  const daysAhead = Math.floor(Math.random() * numberOfDays) + 1 + (addExtraDays || 0)
  return addDays(new Date(), daysAhead).toISOString().split('T')[0]
}

export const generateRandomLast6MonthsDate = () => {
  const daysAgo = Math.floor(Math.random() * 180) + 1
  return subDays(new Date(), daysAgo).toISOString().split('T')[0]
}

const lastSixMonthsDate = Array.from({ length: 10 }).map(() => generateRandomLast6MonthsDate())

const calibrationDates = [
  generateRandomLast30DaysDate(),
  generateRandomLast30DaysDate(),
  generateRandomNextXDaysDate(7),
  generateRandomNextXDaysDate(7),
  generateRandomNextXDaysDate(7),
  generateRandomNextXDaysDate(30),
  generateRandomNextXDaysDate(60),
  generateRandomNextXDaysDate(60),
  generateRandomNextXDaysDate(90),
  generateRandomNextXDaysDate(90, 90),
]

export const useDemoMutations = (
  state: StepsTypes[],
  setState: React.Dispatch<React.SetStateAction<StepsTypes[]>>,
  setDataForUser: React.Dispatch<React.SetStateAction<DataForUserType>>,
) => {
  const { mutate: customerMutation } = useCustomerMutation(state, setState)
  const { mutate: createCustomerMutation } = useCreateCustomerMutation(state, setState)
  const { mutate: businessUnitMutation } = useBusinessUnitMutation(state, setState)
  const { mutate: operatorMutation } = useOperatorMutation(state, setState)
  const { mutate: deviceMutation } = useDeviceMutation(state, setState)
  const { mutate: configMutation } = useConfigurationMutation(state, setState)
  const { mutate: logsMutation } = useLogsMutation(state, setState)

  function logsMutationFunction(data: iDeviceConfig[]) {
    logsMutation(
      data.map((d) => ({
        configuration: d,
        numberOfRecords: 100,
        timeDurationInMonths: 6,
      })),
      {
        onSuccess() {
          console.log('logsMutation success')
        },
      },
    )
  }
  function configurationMutationFunction(devices: DemoDevicesCreationResponse[]) {
    configMutation(devices, {
      onSuccess(data) {
        logsMutationFunction(data)
      },
    })
  }

  function deviceMutationFunction(operators: DemoOperators[]) {
    deviceMutation(
      Array.from({ length: 10 }).map((_, i) => ({
        customerId: operators[0].customerId,
        businessUnitId: operators[i % 5].businessUnitId,
        operatorId: operators?.[i]?.id,
        calibrationDate: calibrationDates[i],
        serviceDate: lastSixMonthsDate[i],
      })),
      {
        onSuccess(data) {
          configurationMutationFunction(data)
        },
      },
    )
  }

  async function operatorMutationFunction(
    customerId: string,
    businessUnitList: iBusinessUnitDetails[],
  ) {
    operatorMutation(
      { customerId, businessUnitIdList: businessUnitList.map((bu) => bu.id) },
      {
        onSuccess(data) {
          deviceMutationFunction(data)
        },
      },
    )
  }

  function businessUnitMutationFunction(customerId: string, fromCreation?: boolean) {
    businessUnitMutation(
      { customerId, fromCreation },
      {
        onSuccess(businessUnitList, variables) {
          setDataForUser((pre) => ({
            ...pre,
            businessUnitId: businessUnitList.find((bu) => !bu.parentId)?.id as string,
            businessUnitName: businessUnitList.find((bu) => !bu.parentId)?.name as string,
          }))
          if (variables?.fromCreation) {
            operatorMutationFunction(variables?.customerId, businessUnitList)
          }
        },
      },
    )
  }

  function createCustomerMutationFunction() {
    createCustomerMutation(undefined, {
      onSuccess(data) {
        setDataForUser((oldUserData) => ({
          ...oldUserData,
          customerId: data,
          userScope: 'BusinessUnitScope',
          roleName: 'CustomerAdmin',
          scope: 'CustomerScope',
        }))
        businessUnitMutationFunction(data, true)
      },
    })
  }

  function customerMutationFunction() {
    customerMutation(undefined, {
      onSuccess(data) {
        if (data) {
          setDataForUser((oldUserData) => ({
            ...oldUserData,
            customerId: data.id,
            userScope: 'BusinessUnitScope',
            roleName: 'CustomerAdmin',
            scope: 'CustomerScope',
          }))
          businessUnitMutationFunction(data.id, false)
        } else {
          createCustomerMutationFunction()
        }
      },
    })
  }

  function demoMutationFunction() {
    customerMutationFunction()
  }
  return demoMutationFunction
}
