import React from 'react'
import { groupDeviceDataByDeviceType } from 'pages/devices/data/DeviceDataUtils'
import {
  groupDeviceDataByCalDueDays,
  getDeviceCountDueInNextXMonths,
} from 'pages/calibration/data/CalibrationDataUtils'

import { ExportWidgetType } from 'exportReports/DataExportInterfaces'
import { iDeviceTypeCount } from 'pages/devices/data/iDevicesList'

import { iDevice } from 'data/DeviceListHook'

const GetDevicesByTypeForReport = (deviceData: iDevice[]) => {
  const devicesCountByTypeForReport: ExportWidgetType = {
    WidgetTitle: '',
    ColumnHeaders: [],
    ColumnDatas: [],
  }
  const devicesByType: iDeviceTypeCount[] = groupDeviceDataByDeviceType(deviceData)
  devicesByType.forEach((val) => {
    devicesCountByTypeForReport.ColumnHeaders.push(val.deviceType)
    devicesCountByTypeForReport.ColumnDatas.push(val.count)
  })
  devicesCountByTypeForReport.WidgetTitle = 'Type'

  return devicesCountByTypeForReport
}

const GetDevicesByCalDueDaysForReport = (deviceData: iDevice[]) => {
  const devicesCountByCalDueDaysForReport: ExportWidgetType = {
    WidgetTitle: '',
    ColumnHeaders: [],
    ColumnDatas: [],
  }
  const devicesByCalDueDays = groupDeviceDataByCalDueDays(deviceData)
  devicesByCalDueDays.forEach((val) => {
    devicesCountByCalDueDaysForReport.ColumnHeaders.push(val.calDueParam)
    devicesCountByCalDueDaysForReport.ColumnDatas.push(val.count)
  })
  devicesCountByCalDueDaysForReport.WidgetTitle = 'Due'

  return devicesCountByCalDueDaysForReport
}

const GetDevicesDueByMonthForReport = (deviceData: iDevice[]) => {
  const devicesDueByMonthForReport: ExportWidgetType = {
    WidgetTitle: '',
    ColumnHeaders: [],
    ColumnDatas: [],
  }
  const devicesDueByMonths = getDeviceCountDueInNextXMonths(deviceData)
  devicesDueByMonths.forEach((val) => {
    devicesDueByMonthForReport.ColumnHeaders.push(val.calDueMonth)
    devicesDueByMonthForReport.ColumnDatas.push(val.count)
  })
  devicesDueByMonthForReport.WidgetTitle = 'Due by month'
  return devicesDueByMonthForReport
}

function FormatCalibrationWidget(deviceData: iDevice[]) {
  const CalibrationWidgetForReport: ExportWidgetType[] = []
  const devicesByDueDaysForReport: ExportWidgetType = GetDevicesByCalDueDaysForReport(deviceData)
  CalibrationWidgetForReport.push(devicesByDueDaysForReport)
  const devicesByTypeForReport: ExportWidgetType = GetDevicesByTypeForReport(deviceData)
  CalibrationWidgetForReport.push(devicesByTypeForReport)
  const devicesDueByMonthForReport: ExportWidgetType = GetDevicesDueByMonthForReport(deviceData)
  CalibrationWidgetForReport.push(devicesDueByMonthForReport)

  return CalibrationWidgetForReport
}
export default FormatCalibrationWidget
