import { convertUrlForDemoMode } from 'utils/CommonUtils'
import { endpoints } from '../endpoints'

export const businessUnitsListByCustomerURL = (customerId: string) =>
  convertUrlForDemoMode(`${endpoints.businessUnit.list.byCustomer}/${customerId}`)

export const businessUnitsTree = (customerId?: string) =>
  convertUrlForDemoMode(`${endpoints.businessUnit.list.byCustomer}/${customerId}`)
export const businessUnitsTreeCrud = () =>
  convertUrlForDemoMode(`${endpoints.businessUnit.list.byCustomer}/`)

export const businessUnitsListForUserURL = () =>
  convertUrlForDemoMode(endpoints.businessUnit.list.byUser)

export const demoBusinessUnitUrl = () =>
  convertUrlForDemoMode(endpoints.businessUnit.list.byCustomer)
