import React, { useEffect, useState } from 'react'
import { iDevice } from 'data/DeviceListHook'
import GenericWidget from 'components/viewComponents/GenericWidget'
import { iWidgetData } from 'components/viewComponents/interfaces'
import {
  getDeviceCountDueInNextXMonths,
  constructNextXMonths,
} from 'pages/calibration/data/CalibrationDataUtils'
import { DateRangeSelectorTypes, DeviceCalDueTypes } from 'utils/CommonEnums'
import { DateRange, IFilterValues } from 'forms/FormModelInterface'
import { format, isEqual } from 'date-fns'
import { withErrorBoundary } from 'react-error-boundary'
import ErrorPage from 'pages/common/ErrorPage'
import { CalibrationPageColumnIds } from '../CalibrationsPageUtils'

const getStartTimeWithEndTime = (dateRange: DateRange): DateRange => ({
  startDate: new Date(`${dateRange.startDate}T00:00:00`),
  endDate: new Date(`${dateRange.endDate}T23:59:59`),
})
function CalibrationCountByMonthWidget({
  deviceListData,
  onFilter,
  calDueDatesFilteredParam,
  calDueDateSelectorFilteredParam,
}: {
  deviceListData: iDevice[]
  onFilter: (filterRequestValues: IFilterValues[]) => void
  calDueDatesFilteredParam: DateRange
  calDueDateSelectorFilteredParam: string
}): JSX.Element {
  const [widgetData, setWidgetData] = useState<iWidgetData[]>()

  const next6MonthsDates = constructNextXMonths(6)

  const onFilterData = (filterRequestValues: IFilterValues[]) => {
    const filterRequestValueToSend: IFilterValues[] = []
    filterRequestValues.forEach((item) => {
      if (item.value === '') {
        filterRequestValueToSend.push({
          columnId: item.columnId,
          value: '',
        })
      } else {
        const v = item.value as DateRange
        if (v?.customValue === next6MonthsDates[0].customValue) {
          filterRequestValueToSend.push({
            columnId: item.columnId,
            value: {
              label: DeviceCalDueTypes.Overdue,
              value: next6MonthsDates[0],
            },
          })
        } else {
          filterRequestValueToSend.push({
            columnId: item.columnId,
            value: {
              label: DateRangeSelectorTypes.Custom,
              value: item.value as DateRange,
            },
          })
        }
      }
    })
    if (onFilter) {
      onFilter(filterRequestValueToSend)
    }
  }

  useEffect(() => {
    // const wdata: iWidgetData[] = []
    // let gridPosition = 'col-start-1 col-end-2 row-start-1 row-end-2'
    if (deviceListData) {
      const deviceCalDueMonthsCount = getDeviceCountDueInNextXMonths(deviceListData)

      const wDataToDisplay: iWidgetData[] = []

      wDataToDisplay.push({
        originalData: next6MonthsDates[0], // Overdue
        // deviceCalDueMonthsCount[0].calDueMonth, // Overdue
        displayText: next6MonthsDates[0].customValue,
        // DeviceCalDueTypeTexts.find((x) => x.label === deviceCalDueMonthsCount[0].calDueMonth)
        //   ?.value || deviceCalDueMonthsCount[0].calDueMonth,
        value: deviceCalDueMonthsCount[0].count || 0,
        // className: 'bg-c-red-1 text-c-white hover:bg-c-red-dark',
        // filteredClassName: 'bg-c-red-2 text-c-white hover:bg-c-red-dark',
        gridItemClassName: 'col-span-2',
        columnId: CalibrationPageColumnIds.calDue,
        isFiltered: calDueDateSelectorFilteredParam === next6MonthsDates[0].customValue,
      })

      wDataToDisplay.push({
        originalData: next6MonthsDates[1],
        // deviceCalDueMonthsCount[1].calDueMonth, // Overdue
        displayText: format(new Date(next6MonthsDates[1].startDate), 'MMM'),
        // deviceCalDueMonthsCount[1].calDueMonth,
        value: deviceCalDueMonthsCount[1].count || 0,
        // gridItemClassName: 'lg:col-start-1 lg:col-end-2 lg:row-start-2 lg:row-end-3',
        columnId: CalibrationPageColumnIds.calDue,
        isFiltered:
          isEqual(
            new Date(calDueDatesFilteredParam?.startDate),
            getStartTimeWithEndTime(next6MonthsDates[1]).startDate as Date,
          ) &&
          isEqual(
            new Date(calDueDatesFilteredParam?.endDate),
            getStartTimeWithEndTime(next6MonthsDates[1]).endDate as Date,
          ),
      })

      wDataToDisplay.push({
        originalData: next6MonthsDates[2],
        displayText: format(new Date(next6MonthsDates[2].startDate), 'MMM'),
        value: deviceCalDueMonthsCount[2].count || 0,
        // gridItemClassName: 'lg:col-start-1 lg:col-end-2 lg:row-start-3 lg:row-end-4',
        columnId: CalibrationPageColumnIds.calDue,
        isFiltered:
          isEqual(
            new Date(calDueDatesFilteredParam?.startDate),
            getStartTimeWithEndTime(next6MonthsDates[2]).startDate as Date,
          ) &&
          isEqual(
            new Date(calDueDatesFilteredParam?.endDate),
            getStartTimeWithEndTime(next6MonthsDates[2]).endDate as Date,
          ),
      })

      wDataToDisplay.push({
        originalData: next6MonthsDates[3],
        displayText: format(new Date(next6MonthsDates[3].startDate), 'MMM'),
        value: deviceCalDueMonthsCount[3].count || 0,
        // gridItemClassName: 'lg:col-start-1 lg:col-end-2 lg:row-start-4 lg:row-end-5',
        columnId: CalibrationPageColumnIds.calDue,
        isFiltered:
          isEqual(
            new Date(calDueDatesFilteredParam?.startDate),
            getStartTimeWithEndTime(next6MonthsDates[3]).startDate as Date,
          ) &&
          isEqual(
            new Date(calDueDatesFilteredParam?.endDate),
            getStartTimeWithEndTime(next6MonthsDates[3]).endDate as Date,
          ),
      })

      wDataToDisplay.push({
        originalData: next6MonthsDates[4],
        displayText: format(new Date(next6MonthsDates[4].startDate), 'MMM'),
        value: deviceCalDueMonthsCount[4].count || 0,
        // gridItemClassName: 'lg:col-start-2 lg:col-end-3 lg:row-start-1 lg:row-end-2',
        columnId: CalibrationPageColumnIds.calDue,
        isFiltered:
          isEqual(
            new Date(calDueDatesFilteredParam?.startDate),
            getStartTimeWithEndTime(next6MonthsDates[4]).startDate as Date,
          ) &&
          isEqual(
            new Date(calDueDatesFilteredParam?.endDate),
            getStartTimeWithEndTime(next6MonthsDates[4]).endDate as Date,
          ),
      })

      wDataToDisplay.push({
        originalData: next6MonthsDates[5],
        displayText: format(new Date(next6MonthsDates[5].startDate), 'MMM'),
        value: deviceCalDueMonthsCount[5].count || 0,
        // gridItemClassName: 'lg:col-start-2 lg:col-end-3 lg:row-start-2 lg:row-end-3',
        columnId: CalibrationPageColumnIds.calDue,
        isFiltered:
          isEqual(
            new Date(calDueDatesFilteredParam?.startDate),
            getStartTimeWithEndTime(next6MonthsDates[5]).startDate as Date,
          ) &&
          isEqual(
            new Date(calDueDatesFilteredParam?.endDate),
            getStartTimeWithEndTime(next6MonthsDates[5]).endDate as Date,
          ),
      })

      wDataToDisplay.push({
        originalData: next6MonthsDates[6],
        displayText: format(new Date(next6MonthsDates[6].startDate), 'MMM'),
        value: deviceCalDueMonthsCount[6].count || 0,
        // gridItemClassName: 'lg:col-start-2 lg:col-end-3 lg:row-start-3 lg:row-end-4',
        columnId: CalibrationPageColumnIds.calDue,
        isFiltered:
          isEqual(
            new Date(calDueDatesFilteredParam?.startDate),
            getStartTimeWithEndTime(next6MonthsDates[6]).startDate as Date,
          ) &&
          isEqual(
            new Date(calDueDatesFilteredParam?.endDate),
            getStartTimeWithEndTime(next6MonthsDates[6]).endDate as Date,
          ),
      })

      // const wDataToDisplay: iWidgetData[] = [
      //   {
      //     originalData: DeviceCalDueTypes.DueIn7Days,
      //     displayText:
      //       DeviceCalDueTypeTexts.find((x) => x.label === DeviceCalDueTypes.DueIn7Days)?.value ||
      //       DeviceCalDueTypes.DueIn7Days,
      //     value:
      //       deviceCalDueDaysCount.find((x) => x.calDueParam === DeviceCalDueTypes.DueIn7Days)
      //         ?.count || 0,
      //   },
      //   {
      //     originalData: DeviceCalDueTypes.DueIn30Days,
      //     displayText:
      //       DeviceCalDueTypeTexts.find((x) => x.label === DeviceCalDueTypes.DueIn30Days)?.value ||
      //       DeviceCalDueTypes.DueIn30Days,
      //     value:
      //       deviceCalDueDaysCount.find((x) => x.calDueParam === DeviceCalDueTypes.DueIn30Days)
      //         ?.count || 0,
      //   },
      //   {
      //     originalData: DeviceCalDueTypes.DueIn60Days,
      //     displayText:
      //       DeviceCalDueTypeTexts.find((x) => x.label === DeviceCalDueTypes.DueIn60Days)?.value ||
      //       DeviceCalDueTypes.DueIn60Days,
      //     value:
      //       deviceCalDueDaysCount.find((x) => x.calDueParam === DeviceCalDueTypes.DueIn60Days)
      //         ?.count || 0,
      //   },
      //   {
      //     originalData: DeviceCalDueTypes.DueIn90Days,
      //     displayText:
      //       DeviceCalDueTypeTexts.find((x) => x.label === DeviceCalDueTypes.DueIn90Days)?.value ||
      //       DeviceCalDueTypes.DueIn90Days,
      //     value:
      //       deviceCalDueDaysCount.find((x) => x.calDueParam === DeviceCalDueTypes.DueIn90Days)
      //         ?.count || 0,
      //   },
      // ]

      setWidgetData(wDataToDisplay)
    }
  }, [deviceListData, calDueDatesFilteredParam])

  return (
    <GenericWidget
      widgetProps={{
        title: 'Due by month',
        widgetData,
        widgetLabelCount: 4,
        columnId: CalibrationPageColumnIds.calDue,
        gridContainerClassName: 'grid-cols-2 gap-1',
        onFilter: (filterRequestValues: IFilterValues[]) => onFilterData(filterRequestValues),
      }}
    />
  )
}

export default withErrorBoundary(CalibrationCountByMonthWidget, {
  FallbackComponent: ErrorPage,
})
