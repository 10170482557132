import { QueryClient, useQuery } from '@tanstack/react-query'
import { useMsal } from '@azure/msal-react'
import { InteractionStatus } from '@azure/msal-browser'
import { LoaderFunction, createSearchParams } from 'react-router-dom'
import { sub } from 'date-fns'
import ConnectAPIClient from 'services/ConnectAPIClient'
import { AlarmTypes, GasType } from 'utils/CommonEnums'
import { iBusinessUnitDetails } from 'components/commonComponents/BusinessUnitDataUtils'
import { getAccessToken, getAccessTokenFromPCA, getAccessTokenProps } from 'services/MSALService'
import { getOrFetchUserInfo } from 'data/UserInfoHook'
import { IsUserGlobalScope } from 'utils/UserDataUtils'
import { allCustomerOption, getOrFetchCustomerList } from 'data/CustomerListHook'
import { getOrFetchBusinessUnitsByCustomer } from 'data/BusinessUnitsListByCustomerHook'
import { getOrFetchBusinessUnitsForUser } from 'data/BusinessUnitsListByUserHook'
import { allBusinessUnitOption } from 'data/BusinessUnitsListHookWrapper'
import { iDevice, getOrFetchDevicesList } from 'data/DeviceListHook'
import { iDataLoaderParams } from 'data/Interfaces'
import { AxiosError } from 'axios'
import { safetyListDataQueryKey } from 'services/apis/caching/safety'
import { safetyListDataURL } from 'services/apis/urls/safety'
import { AlarmTypeValues } from 'utils/Constants'
import { iSafetyData, iSafetyAPIResponse } from './iSafetyList'

export interface iSafetyServerSideSearchParams {
  serialNumber?: string
  gas?: GasType
  Alarm?: AlarmTypes
  AssetNumber?: string
  Level?: number
  assignedTo?: string
  Alarm1SetPoint?: number
  toDate?: Date | string
  fromDate?: Date | string
}

export interface iSafetySearchParams {
  paginationParams?: {
    PageNumber?: number
    PageSize?: number
  }
  serverSideSearchParams?: iSafetyServerSideSearchParams
}

export interface iBusinessUnit {
  id: string
  name: string
}

// interface iSafetyPageDataLoaderParams {
//   queryClient: QueryClient
//   msalInstance: IPublicClientApplication
// }

export interface iAPIBusinessUnitParams {
  businessUnitId?: string
  customerId?: string
}

interface iFetchSafetyListParams {
  token: string
  businessUnitId?: string
  customerId?: string
  // searchParams?: iSafetySearchParams
  startDate: string
  endDate: string
}

interface iGetOrFetchSafetyListProps {
  queryClient: QueryClient
  token: string
  businessUnitId?: string
  customerId?: string
  // searchParams?: iSafetySearchParams
  startDate: string
  endDate: string
}

interface iAPIDateRangeBusinessUnitParams {
  startDate: string
  endDate: string
  businessUnitId: string
}

interface iSafetyListAPIParams extends iAPIDateRangeBusinessUnitParams, getAccessTokenProps {}

function getTopLevelBusinessUnitId(businessUnitsList: iBusinessUnitDetails[]) {
  const topLevelBusinessUnit = businessUnitsList.find(
    (businessUnit) => businessUnit.parentId === null,
  )
  if (!topLevelBusinessUnit) return ''
  return topLevelBusinessUnit?.id
}

function updateSafetyDataWithBusinessUnit(
  safetyData: iSafetyData[],
  deviceListData: iDevice[],
): iSafetyData[] {
  const combinedData = safetyData?.map((safetyDataItem) => {
    const deviceBusinessUnit = deviceListData?.find(
      (device) => device.id === safetyDataItem.DeviceId,
    )?.businessUnit ?? { id: 'unknown', name: 'unknown' }

    return { ...safetyDataItem, businessUnit: deviceBusinessUnit }
  })
  return combinedData
}

export async function FetchSafetyListData(
  params: iFetchSafetyListParams,
): Promise<iSafetyAPIResponse> {
  try {
    const { token, businessUnitId, startDate, endDate } = params
    const url = safetyListDataURL(startDate, endDate, businessUnitId)
    const resp = await ConnectAPIClient.get<iSafetyAPIResponse>(url, token)
    const response = resp.data
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}

export async function getSafetyListData(safetyListAPIParams: iSafetyListAPIParams) {
  const { msalContext, redirectPageURL, businessUnitId, startDate, endDate } = safetyListAPIParams
  const token = await getAccessToken({ msalContext, redirectPageURL })
  const safetyListData = await FetchSafetyListData({
    token,
    businessUnitId,
    startDate,
    endDate,
  })
  return safetyListData
}

export function useSafetyListData(
  startDate: string,
  endDate: string,
  redirectPageURL: string,
  businessUnitId: string,
  customerId?: string,
  businessUnitsList?: iBusinessUnitDetails[] | undefined,
  enabled?: boolean,
) {
  const msalContext = useMsal()

  let buId = ''
  if (
    businessUnitId === allBusinessUnitOption.id &&
    customerId !== allCustomerOption.id &&
    businessUnitsList
  ) {
    buId = getTopLevelBusinessUnitId(businessUnitsList)
  } else {
    buId = businessUnitId
  }

  return useQuery(
    safetyListDataQueryKey(startDate, endDate, buId),
    () =>
      getSafetyListData({
        msalContext,
        redirectPageURL,
        businessUnitId: buId,
        startDate,
        endDate,
      }),
    {
      enabled,
      keepPreviousData: true,
      select: (data) => ({
        ...data,
        Data: data.Data.map((item) => ({
          ...item,
          Level:
            item?.alarm === AlarmTypeValues[0].value || item?.alarm === AlarmTypeValues[1].value
              ? '> 100'
              : item?.Level?.toString(),
        })),
      }),
      onError: (error: AxiosError) => {
        console.log(error)
      },
    },
  )
}

export async function getOrFetchSafetyList(props: iGetOrFetchSafetyListProps) {
  const { queryClient, token, businessUnitId, customerId, startDate, endDate } = props

  const safetyListCached = queryClient.getQueryData<iSafetyAPIResponse>(
    safetyListDataQueryKey(startDate, endDate, businessUnitId),
  )

  if (safetyListCached) {
    return safetyListCached
  }

  const safetyListFetched = await queryClient.fetchQuery(
    safetyListDataQueryKey(startDate, endDate, businessUnitId),
    async () =>
      FetchSafetyListData({
        token,
        businessUnitId,
        customerId,
        startDate,
        endDate,
      }),
  )

  return safetyListFetched
}

export const SafetyPageDataLoader =
  (props: iDataLoaderParams): LoaderFunction =>
  async ({ request }) => {
    const { queryClient, msalInstance, msalAccounts, msalInProgress } = props
    if (!msalInstance) throw new Error('No MSAL Context')
    if (!queryClient) throw new Error('No query client')

    try {
      if (msalInProgress !== InteractionStatus.None) {
        return {}
      }
      if (msalAccounts.length === 0) {
        return {}
      }
      const token = await getAccessTokenFromPCA({ msalInstance })

      const userInfo = await getOrFetchUserInfo({ queryClient, token })

      const isUserGlobal = IsUserGlobalScope(userInfo)

      const searchParams = createSearchParams(new URLSearchParams(request.url.split('?')[1]))
      const endDate = searchParams.get('to') ?? new Date().toISOString().split('T')[0]
      const startDate =
        searchParams.get('from') ??
        sub(new Date(endDate), { months: 6 }).toISOString().split('T')[0]
      const customerId = isUserGlobal
        ? searchParams.get('c') ?? (allCustomerOption.id as string)
        : ''
      const businessUnitId = searchParams.get('mainbu') ?? allBusinessUnitOption.id

      const customerList = !isUserGlobal ? [] : await getOrFetchCustomerList({ queryClient, token })

      const businessUnitsList =
        isUserGlobal && customerId !== '' && customerId !== allCustomerOption.id
          ? await getOrFetchBusinessUnitsByCustomer({ queryClient, token, customerId })
          : await getOrFetchBusinessUnitsForUser({ queryClient, token })

      let buId = ''
      if (
        businessUnitId === allBusinessUnitOption.id &&
        customerId !== '' &&
        customerId !== allCustomerOption.id &&
        businessUnitsList
      ) {
        buId = getTopLevelBusinessUnitId(businessUnitsList)
      } else {
        buId = businessUnitId
      }

      const safetyListAPIResponse = await getOrFetchSafetyList({
        queryClient,
        token,
        businessUnitId: buId,
        customerId,
        startDate,
        endDate,
      })

      const safetyListAPIData = safetyListAPIResponse.Data
      const devicesList = await getOrFetchDevicesList({ queryClient, token })

      const safetyDataWithBusinessUnit = updateSafetyDataWithBusinessUnit(
        safetyListAPIData,
        devicesList,
      )

      return {
        safetyPageData: safetyDataWithBusinessUnit,
        businessUnitsList,
        customerList,
      }
    } catch (error) {
      console.error(error)
      throw error
    }
  }
