import { convertUrlForDemoMode } from 'utils/CommonUtils'
import { endpoints } from '../endpoints'

export const updateUserSettingsURL = () => convertUrlForDemoMode(endpoints.preference)
export const userUrl = () => convertUrlForDemoMode(endpoints.user.common)
export const updateUserRole = () => convertUrlForDemoMode(endpoints.user.userRoles)
export const userListUrl = (businessId?: string) =>
  convertUrlForDemoMode(
    `${endpoints.user.common}?${businessId ? `BusinessUnitId=${businessId}` : ''}`,
  )
export const roleListUrl = () => convertUrlForDemoMode(endpoints.role)
export const userInfoUrl = () => convertUrlForDemoMode(endpoints.userInfo)
export const localeURL = () => convertUrlForDemoMode(endpoints.locale)
export const emailValidityURL = (email: string) =>
  convertUrlForDemoMode(`${endpoints.user.validity}/${email}`)
export const userInviteStatusURL = (invitationId?: string) =>
  invitationId
    ? `${endpoints.user.inviteStatus}?invitationId=${invitationId}`
    : `${endpoints.user.inviteStatus}`
