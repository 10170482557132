import React from 'react'
import { SvgProps } from 'types/SVGProps'

function EditIcon(props: SvgProps): JSX.Element {
  const { stroke, fill, className, toggleClick } = props

  return (
    <div className=''>
      <svg
        className={className}
        width='20'
        height='20'
        viewBox='0 0 20 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        onClick={toggleClick}
      >
        <path
          d='M5 15H7.29136L14.0493 8.24203L11.758 5.95067L5 12.7086V15ZM6.22206 13.2158L11.758 7.67988L12.3201 8.24203L6.7842 13.7779H6.22206V13.2158ZM14.3915 4.17869C14.335 4.12205 14.2678 4.07711 14.1939 4.04645C14.12 4.01578 14.0408 4 13.9607 4C13.8807 4 13.8015 4.01578 13.7275 4.04645C13.6536 4.07711 13.5865 4.12205 13.53 4.17869L12.4118 5.29687L14.7031 7.58823L15.8213 6.47005C15.878 6.41352 15.9229 6.34637 15.9536 6.27246C15.9842 6.19854 16 6.1193 16 6.03927C16 5.95925 15.9842 5.88001 15.9536 5.80609C15.9229 5.73217 15.878 5.66503 15.8213 5.6085L14.3915 4.17869Z'
          fill={fill ?? '#FFFFFF'}
        />
      </svg>
    </div>
  )
}

export default EditIcon
