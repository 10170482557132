import React, { useState, useEffect, useContext, Fragment } from 'react'
import { useMsal } from '@azure/msal-react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { CloseIcon, TickIcon, Spinner, DeleteIcon } from 'assets/icons'
import TextInputControl from 'components/formComponents/TextInputControl'
import { iDevice } from 'data/DeviceListHook'

import { useGenericEventHandler } from 'data/GenericEventHandler'
import { deleteDevice, useDoesDeviceHaveAnyLogs } from '../data/DeleteDeviceAPI'

interface iConfirmDeleteDeviceProps {
  rowData: iDevice
  onCloseEditDialog: () => void
  onCloseConfirmDeleteDialog: () => void
}

export function ConfirmDeleteDevice(props: iConfirmDeleteDeviceProps): JSX.Element {
  const { rowData, onCloseEditDialog, onCloseConfirmDeleteDialog } = props
  const handleClose = () => {}
  const { genericEventHandler } = useGenericEventHandler()
  const queryClient = useQueryClient()
  const msalContext = useMsal()

  const deleteDeviceMutation = useMutation(deleteDevice, {
    onSuccess: (data) => {
      console.log('Device deleted successfully')
      queryClient.invalidateQueries({
        queryKey: ['devices'],
      })
      setTimeout(() => {
        onCloseEditDialog()
      }, 500)
    },
    onError: (error: Error) => {
      console.log('Error in deleting device', error)
    },
    // mutationKey:
  })

  const {
    isLoading,
    isSuccess,
    isError: isDeleteDeviceMutationError,
    error: deleteDeviceMutationError,
  } = deleteDeviceMutation

  const onConfirmDeleteDevice = () => {
    console.log('confirm delete device')
    const devicesToDelete: string[] = [rowData.id]
    deleteDeviceMutation.mutate({
      devicesToDelete,
      msalContext,
    })
  }

  const onConfirmDialogClose = () => {
    // console.log('confirm dialog close')
    deleteDeviceMutation.reset()
    onCloseConfirmDeleteDialog()
  }
  useEffect(() => {
    if (isDeleteDeviceMutationError) {
      genericEventHandler({
        onlyTrack: true,
        severity: 'error',
        message: deleteDeviceMutationError?.message || 'Error in deleting device',
        error: deleteDeviceMutationError,
        extraData: {
          component: 'ConfirmDeleteDevice',
          action: 'delete device',
        },
      })
    }
  }, [isDeleteDeviceMutationError, deleteDeviceMutationError])

  return (
    <div className='w-80 font-poppins h-full transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all    text-c-dark-blue-1 font-bold text-3xs border-1 border-c-light-blue-4'>
      <div className='flex flex-col justify-center items-center'>
        <div className='flex flex-row gap-2'>
          <div className='h-11 w-full font-bold text-base pt-2 leading-5  text-c-dark-blue-1'>
            Are you sure you want to delete this device?
          </div>
          <div>
            <CloseIcon
              toggleClick={() => onConfirmDialogClose()}
              className='bg-c-dark-blue-1 rounded-full w-10 h-10 p-2.5 hover:bg-c-dark-blue-2  active:bg-c-dark-blue-3'
            />
          </div>
        </div>

        <div className='flex flex-col pt-5'>
          <div className='flex flex-row w-full space-x-2 h-16'>
            <div className=''>
              <label className='px-3' htmlFor='editDeviceSerialNumber'>
                Serial Number
                <div className='w-full py-1 h-10'>
                  <TextInputControl
                    id='editDeviceSerialNumber'
                    className='h-11 w-32 border-3'
                    readOnly
                    elementProps={{
                      readOnly: 'true',
                      value: rowData.serialNumber,
                    }}
                  />
                </div>
              </label>
            </div>

            <div className='w-1/2'>
              <label className='px-3' htmlFor='editDeviceFormDeviceType'>
                Device Type
                <div className='w-full py-1 h-10'>
                  <TextInputControl
                    id='editDeviceFormDeviceType'
                    className='h-11 w-32 border-3'
                    readOnly
                    elementProps={{
                      readOnly: 'true',
                      value: rowData.deviceType,
                    }}
                  />
                </div>
              </label>
            </div>
          </div>

          <div className='w-full gap-2 pt-3 md:pt-8 flex flex-row items-center'>
            <div className=''>
              <button
                type='button'
                className='
                    
                  flex justify-center items-center bg-c-light-blue-2 p-1 rounded-3xl text-base font-bold text-c-dark-blue-1 hover:text-white cursor-pointer hover:bg-c-dark-blue-2 active:bg-c-dark-blue-3 w-24 h-12 outline-0'
                onClick={() => onConfirmDialogClose()}
              >
                Cancel
              </button>
            </div>
            <div className='flex-grow w-full'>
              <button
                type='button'
                className='w-full justify-center items-center  h-12 bg-c-red-1 p-1 rounded-3xl text-base font-bold text-c-white cursor-pointer hover:bg-c-red-2 active:bg-c-red-3  outline-0'
                onClick={() => onConfirmDeleteDevice()}
              >
                <div className='px-3 md:px-5 flex flex-row justify-center items-center py-1.5 gap-1'>
                  {isLoading && <Spinner className='w-7 h-7' />}
                  {isSuccess && <TickIcon className='w-6 h-7' />}
                  {isDeleteDeviceMutationError && <div className='w-6 h-7 text-red-500'>!</div>}
                  <div> Delete </div>
                </div>
              </button>
            </div>
          </div>
          {isDeleteDeviceMutationError && (
            <div className='text-red-500 text-xs font-normal pt-2 pl-2'>
              {deleteDeviceMutationError?.message ?? 'Error in deleting device'}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default ConfirmDeleteDevice
