import clsx from 'clsx'
import React from 'react'

export default function Chips({
  name,
  stat,
  icon,
  isAlarm,
}: {
  name: string
  stat: string | number | boolean
  icon: JSX.Element
  isAlarm?: boolean
}) {
  const isAlarmStatus = stat === 'High' || stat === 'Low'
  return (
    <div
      className={clsx(
        'flex flex-row gap-3 items-center  overflow-hidden rounded-lg  px-4 py-5 shadow sm:p-5 ',
        {
          'bg-c-red-1': isAlarm && isAlarmStatus,
          'bg-white': stat !== 'High' && stat !== 'Low',
        },
      )}
    >
      <div
        className={clsx('p-4 bg-c-light-blue-1 rounded-full', {
          'text-c-dark-blue-2': !isAlarm,
          'text-c-red-1': isAlarm,
        })}
      >
        {icon}
      </div>
      <div className=''>
        <div
          className={clsx('truncate text-sm font-medium ', {
            'text-c-dark-blue-2': !isAlarm,
            'text-c-red-1': isAlarm && !isAlarmStatus,
            'text-c-light-blue-1': isAlarm && isAlarmStatus,
          })}
        >
          {name}
        </div>
        <div
          className={clsx('mt-1 text-xl  lg:text-3xl font-semibold tracking-tight ', {
            'text-c-dark-blue-2': !isAlarm,
            'text-c-red-1': isAlarm && !isAlarmStatus,
            'text-c-light-blue-1': isAlarm && isAlarmStatus,
          })}
        >
          {stat}
        </div>
      </div>
    </div>
  )
}
Chips.defaultProps = {
  isAlarm: '',
}
