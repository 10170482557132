import ConnectAPIClient from 'services/ConnectAPIClient'
import { getAccessToken } from 'services/MSALService'
import { IPublicClientApplication } from '@azure/msal-browser'
import { IMsalContext } from '@azure/msal-react'
import { parseApiErrorOrNetworkError } from 'services/APIErrorParser'

export interface iGenericAPIParams {
  msalContext: IMsalContext
  apiURL: string
  redirectPageURL: string
  version?: string
  adb2cPolicy?: string
}

export interface iGenericAPIParamsMsalInstance {
  msalInstance: IPublicClientApplication
  apiURL: string
  redirectPageURL: string
  version?: string
  adb2cPolicy?: string
}

export async function getAPIData<T extends iGenericAPIParams, R>(props: T): Promise<R> {
  console.log('Get API data for : ', props.apiURL)
  try {
    const { msalContext, redirectPageURL, apiURL, version, adb2cPolicy } = props
    const token = await getAccessToken({ msalContext, redirectPageURL, adb2cPolicy })
    const resp = await ConnectAPIClient.get<R>(apiURL, token, version)
    const response = resp.data as R
    return response
  } catch (error) {
    console.error(error)
    throw parseApiErrorOrNetworkError(error)
  }
}

// export async function putAPIData<T extends iGenericAPIParams, R, U>(props: T, data:U): Promise<U> {
//   console.log('Put API data for : ', props.apiURL)
//   try {
//     const { msalContext, redirectPageURL, apiURL, version, adb2cPolicy} = props

//     const token = await getAccessToken({ msalContext, redirectPageURL, adb2cPolicy })
//     const resp = await ConnectAPIClient.put<U>(apiURL, token, version, data)
//     const response = resp.data
//     return response
//   } catch (error) {
//     console.error(error)
//     throw error
//   }
// }

// interface iAPIDateRangeParams {
//   startDate: string
//   endDate: string
// }

// interface iFaultsAPIParams extends iAPIDateRangeParams, getAccessTokenProps {}

// interface iFetchDataParams extends iAPIDateRangeParams {
//     token: string
// }

// export async function FetchFaultsData(params: iFetchDataParams): Promise<iFaultData[]> {
//   try {
//     const { startDate, endDate, token } = params
//     const ToDate = new Date(endDate).toISOString().split('T')[0]
//     const FromDate = new Date(startDate).toISOString().split('T')[0]

//     const resp = await ConnectAPIClient.get<iFaultData[]>(
//       `devicelogs/api/EventLogs/faults?FromDate=${FromDate}&ToDate=${ToDate}`, token
//     )

//     const response = resp.data
//     return response
//   } catch (error) {
//     console.error(error)
//     throw error
//   }
// }

// interface iFaultsDataLoaderParams {
//     queryClient: QueryClient
//     msalInstance: IPublicClientApplication
// }

// export const FaultsDataLoader =
//   (props: iFaultsDataLoaderParams): LoaderFunction =>
//   async ({ request, params }) => {
//     const { queryClient, msalInstance } = props
//     if (!msalInstance) throw new Error('No MSAL Context')
//     if (!queryClient) throw new Error('No query client')

//     try {

//       const searchParams = createSearchParams(new URLSearchParams(request.url.split('?')[1]))
//       const token = await getAccessTokenFromPCA({ msalInstance})
//       const endDate = searchParams.get('from') ?? new Date().toISOString().split('T')[0]
//       const startDate = searchParams.get('to') ??
//       sub(new Date(endDate), { years: 1 }).toISOString().split('T')[0]
//       // new Date('2022-10-8').toISOString().split('T')[0]

//       const faultsDataCached = queryClient.getQueryData<iFaultData[]>(allFaultsQueryKey({ startDate, endDate }))
//       if (faultsDataCached) return faultsDataCached
//       const faultsDataFetched = await queryClient.fetchQuery(
//         allFaultsQueryKey({ startDate, endDate }),
//         async () => FetchFaultsData({ startDate, endDate, token })
//         // allFaultsQuery({ token, startDate, endDate })
//         )
//       return faultsDataFetched
//     } catch (error) {
//       console.error(error)
//       throw error
//     }
//   }

//   export async function getFaultsData(faultAPIParams: iFaultsAPIParams) {
//     const { startDate, endDate, msalContext, redirectPageURL } = faultAPIParams
//     const token = await getAccessToken({ msalContext, redirectPageURL })
//     const faultsData = await FetchFaultsData({ startDate, endDate, token } )
//     return faultsData
//   }
