/* 

* TODO - Most ridicuiosly complex code..
* So many Select controls
* Need a major refactor to unify all the Select controls
*/

import React from 'react'
import { IselectOptionProps } from 'forms/FormModelInterface'
import { DropDownIcon, SearchIcon } from 'assets/icons'
import Select, { components, GroupBase } from 'react-select'

interface selectControlProps {
  id?: string
  className?: string
  selectControlProps: any
  // data?: readonly IselectOptionProps[]
  // field?: any
  // placeHolder?: string
  // isMulti?: boolean
}

const maxItemsToShow = 5

function DropDownIndicator(props: any) {
  return (
    <components.DropdownIndicator {...props}>
      <DropDownIcon className='stroke-black fill-transparent' />
    </components.DropdownIndicator>
  )
}

function SelectControl2(controlProps: selectControlProps): JSX.Element {
  const { className, selectControlProps } = controlProps

  return (
    <Select
      maxMenuHeight={selectControlProps.maxMenuHeight ?? maxItemsToShow * 32}
      isSearchable={selectControlProps.isSearchable ?? true}
      components={
        selectControlProps.isDropDownSelectable === true
          ? { DropdownIndicator: DropDownIndicator }
          : { DropdownIndicator: null }
      }
      className={className}
      {...selectControlProps}
      // value={selectControlProps.defaultValue}
      styles={{
        control: (baseStyles, state) => ({
          ...baseStyles,
          // width: '100%',
          // backgroundColor: state.isDisabled ? '#EAF1F6' : '#FFFFFF', // c-light-blue-1
          borderRadius: '26px',
          fontFamily: 'Poppins',
          fontSize: '12px',
          fontWeight: '500',
          width: '100%',
          height: '100%',
          borderWidth: '3px',

          // borderColor: '#CEDCEB',

          borderColor: state.isFocused ? '#2767A4' : '#CEDCEB', //
          backgroundColor: state.isFocused ? '#EAF1F6' : '#FFFFFF', // c-light-blue-2

          // '&:focus': {
          //   outline: 'none',
          //   borderColor: 'red', // '#2767A4', // '',
          //   backgroundColor: 'chocolate', // '#EAF1F6', // c-light-blue-2
          // },
          '&:hover': {
            borderColor: '#1B4373',
            backgroundColor: '#FFFFFF',
          },
          ':active': {
            borderColor: '#2767A4',
            backgroundColor: '#2767A4', // c-light-blue-2
            color: '#FFFFFF',
          },

          // border-c-light-blue-2 #DDE8F2
          // hover:border-c-dark-blue-2 #1B4373
          // focus:border-c-blue #2767A4
          // active:border-c-blue #2767A4

          // focus:bg-c-light-blue-2
        }),

        noOptionsMessage: (baseStyles, state) => ({
          ...baseStyles,
          fontFamily: 'Poppins',
          fontSize: '11px',
          fontWeight: '500',
        }),
        valueContainer: (baseStyles, state) => ({
          ...baseStyles,

          // backgroundColor: state.isDisabled ? '#EAF1F6' : '#FFFFFF', // c-light-blue-1
          // backgroundColor: '#FFFFFF',
          // ':active': {
          //   // color: '#FFFFFF',
          //   backgroundColor: '#2767A4', // c-light-blue-2
          // },
          // ':disabled': {
          //   backgroundColor: '#EAF1F6', // c-light-blue-2
          // },
          paddingLeft: '9px',
          borderRadius: '26px',
          fontFamily: 'Poppins',
          fontSize: '12px',
          fontWeight: '500',
        }),
        singleValue: (baseStyles, state) => ({
          ...baseStyles,
          fontFamily: 'Poppins',
          fontSize: '12px',
          fontWeight: '500',
          // paddingLeft: '14px',
          // border: '2px solid red'
          // color: '#1B4373',
          // ':active': {
          //   color: '#FFFFFF',
          //   backgroundColor: '#2767A4', // c-light-blue-2
          //   // backgroundColor: 'red', // c-light-blue-2
          // },
          // ':disabled': {
          //   backgroundColor: '#EAF1F6', // c-light-blue-2
          // },
        }),
        indicatorSeparator: (baseStyles, state) => ({
          ...baseStyles,
          display: 'none',
        }),
        dropdownIndicator: (baseStyles, state) => ({
          ...baseStyles,
          paddingRight: '10px',
          // display: (state.getValue() as IselectOptionProps[])?.length === 0 ? 'block' : 'none',
        }),
        clearIndicator: (baseStyles, state) => ({
          ...baseStyles,
          color: '#D91544',
          display: 'none',
        }),
        menu: (base) => ({
          ...base,
          borderRadius: '20px',
          backgroundColor: '#DDE8F2', // c-light-blue-2

          paddingBottom: '16px',
          paddingTop: '16px',

          paddingRight: '6px',

          marginTop: '0px',
          marginBottom: '0px',

          border: '3px solid #DDE8F2',
          scrollSnapType: 'y mandatory',
          // width: '100%',
          boxShadow: '0px 0px 40px 0px rgba(32, 71, 127, 0.60)',
        }),
        menuList: (base, state) => ({
          ...base,
          paddingBottom: '0px',
          paddingTop: '0px',
          marginTop: '0px',
          marginBottom: '0px',
          scrollSnapAlign: 'start',
          // borderRadius: '16px',
          // marginRight: '12px',
          // width: '100%'
        }),
        menuPortal: (base) => ({
          ...base,
          // width: '50px',
          // borderRadius: '16px',
          // paddingBottom: '0px',
          // paddingTop: '0px',
        }),
        option: (baseStyles, state) => ({
          ...baseStyles,
          fontFamily: 'Poppins',
          fontSize: '12px',
          scrollSnapAlign: 'start',
          height: '32px',
          width: 'auto',

          lineClamp: 1,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',

          fontWeight: state.isDisabled ? '400' : '700',
          backgroundColor: state.isFocused ? '#FFFFFF' : '#DDE8F2', // c-light-blue-2

          color: state.isDisabled ? 'gray' : '#1B4373',
          // borderRadius: state.isFocused ? '0 0 16px 16px' : '16px',
          borderRadius: '16px',
          // width: '100%'
          marginRight: '12px',
          // borderRadius:
          //   state.options.indexOf(state.data) === 0
          //     ? '16px 16px 0 0'
          //     : ((state.options.indexOf(state.data)+1) % maxItemsToShow) === 0
          //     ? '0 0 16px 16px'
          //     : '0px',
        }),
        placeholder: (baseStyles, state) => ({
          ...baseStyles,
          color: '#1B4373',
        }),

        // control: (baseStyles, state) => ({
        //   ...baseStyles,
        //   borderColor: state.isFocused ? 'red' : 'green',

        // }),
      }}
    />
  )
}

export default SelectControl2
