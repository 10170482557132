export const BusinessUnitTreeQueryKey = (customerId: string) => [
  'BusinessUnitTree',
  customerId,
  'all',
]
export const organizationTreeQueryKey = (customerId: string) => [
  'organizationTree',
  customerId,
  'all',
]
export function userInviteDataQueryKey(userInviteId: string) {
  return ['userInviteData', userInviteId]
}
export const userSettingsMutationKey = () => ['usersettings', 'update']
export const userListQueryKey = () => ['users', 'list']
export const roleListQueryKey = () => ['role', 'list']
export const userInfoQueryKey = () => ['user', 'info']
export const localeQueryKey = () => ['locale', 'all']

export const userQueries = {
  BusinessUnitTreeQueryKey,
  organizationTreeQueryKey,
  userInviteDataQueryKey,
  userSettingsMutationKey,
  userListQueryKey,
  userInfoQueryKey,
  roleListQueryKey,
  localeQueryKey,
}
