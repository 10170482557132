export const operatorListDataQueryKey = (businessUnitId?: string, customerId?: string) => [
  'operators',
  'list',
  'businessUnit',
  businessUnitId,
]
export const operatorMutationKey = () => ['operator', 'add']
export const operatorUpdateMutationKey = () => ['operator', 'update']
export const operatorDeleteMutationKey = () => ['operator', 'delete']
export const checkOperatorHaveAssignmentHistoryQueryKey = (operatorId: string) => [
  'operator',
  'hasAssignmentHistory',
  'id',
  operatorId,
]
export const operatorQueries = {
  operatorListDataQueryKey,
  operatorMutationKey,
  operatorUpdateMutationKey,
  operatorDeleteMutationKey,
  checkOperatorHaveAssignmentHistoryQueryKey,
}
