import { iBusinessUnitDetails } from './Interfaces'
import { useBusinessUnitsListByCustomerId } from './BusinessUnitsListByCustomerHook'
import { useBusinessUnitsListByUser } from './BusinessUnitsListByUserHook'
import { allCustomerOption } from './CustomerListHook'

export const allBusinessUnitOption: iBusinessUnitDetails = {
  id: 'all',
  buLevelName: 'All',
  buLevelId: 'all',
  parentId: 'all',
  customerId: 'all',
  name: 'All',
}

export function useBusinessUnitsListWrapper(
  customerId: string,
  redirectPageURL: string,
  isGlobalUser: boolean,
  enabled: boolean,
) {
  return isGlobalUser && customerId !== '' && customerId !== allCustomerOption.id
    ? useBusinessUnitsListByCustomerId(customerId, redirectPageURL, enabled)
    : useBusinessUnitsListByUser(redirectPageURL)
}

// Another blunder function
// This is for the add device, operator or other such forms
// This does not fetch ALL business units in one go for ALL customers (global user case)
// Tis is on purpose and this is the difference between the earlier wrapper and this wrapper2

export function useBusinessUnitsListWrapper2(
  customerId: string,
  redirectPageURL: string,
  isGlobalUser: boolean,
  enabled: boolean,
) {
  return isGlobalUser
    ? useBusinessUnitsListByCustomerId(customerId, redirectPageURL, enabled)
    : useBusinessUnitsListByUser(redirectPageURL)
}
