import ConnectAPIClient from 'services/ConnectAPIClient'
import { getAccessToken, getAccessTokenProps } from 'services/MSALService'
import { iBillingPlan } from 'data/CustomerListHook'
import { billingPlanApiUrl } from 'services/apis/urls/business-plans'

export type iBillingPlanApiPayload = {
  id?: string
  name: string
  description: string
  numberOfDevices: number
  numberOfOperators: number
  numberOfUsers: number
  numberOfApiKeys: number
  BillingPlanType?: string
  status?: string
}

export async function updateBillingPlan(
  params: getAccessTokenProps & { payload: iBillingPlanApiPayload },
): Promise<iBillingPlan> {
  try {
    const { msalContext, payload } = params
    const token = await getAccessToken({ msalContext, redirectPageURL: '' })
    const url = billingPlanApiUrl()
    const resp = await ConnectAPIClient.put<iBillingPlan>(`${url}/${payload.id}`, token, '', {
      ...payload,
    } as unknown as iBillingPlan)
    const response = resp.data
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}
export async function addBillingPlan(
  params: getAccessTokenProps & { payload: iBillingPlanApiPayload },
): Promise<iBillingPlan> {
  try {
    const { msalContext, payload } = params
    const token = await getAccessToken({ msalContext, redirectPageURL: '' })
    const url = billingPlanApiUrl()
    const resp = await ConnectAPIClient.post<iBillingPlan>(`${url}`, token, '', {
      ...payload,
    } as unknown as iBillingPlan)
    const response = resp.data
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}
