import { IMsalContext } from '@azure/msal-react'
import { AxiosError } from 'axios'
import { parseAPIErrorv01 } from 'services/APIErrorParser'
import { crudOperatorURL } from 'services/apis/urls/operators'
import ConnectAPIClient from 'services/ConnectAPIClient'
import { getAccessToken } from 'services/MSALService'

export interface iUpdateOperatorDTO {
  Id: string
  FirstName: string
  LastName: string
  BusinessUnitId: string
  UniqueId: string
  Status: string
}

interface iUpdateOperatorProps {
  updateOperatorDTO: iUpdateOperatorDTO
  msalContext: IMsalContext
}

export const updateOperator = async (updateOperatorProps: iUpdateOperatorProps) => {
  const { updateOperatorDTO, msalContext } = updateOperatorProps

  const token = await getAccessToken({ msalContext, redirectPageURL: '' })
  try {
    const resp = await ConnectAPIClient.put(crudOperatorURL(), token, '0.2', updateOperatorDTO)
    return resp.data
  } catch (error) {
    const axiosError = error as AxiosError
    const errorDescription = parseAPIErrorv01(axiosError)
    throw new Error(errorDescription)
  }
}
