import React from 'react'
import { ExportColumnType } from 'exportReports/DataExportInterfaces'
import { FormatUTXToDateTime, FormatBusinessUnit } from 'exportReports/ExportDataUtils'

const FaultsReportColumns: ExportColumnType[] = [
  {
    key: 'timeStamp',
    label: 'Date and time',
    format: (val: any) => FormatUTXToDateTime(val as number),
    cellWidth: 100,
  },
  {
    key: 'eventType',
    label: 'Fault type',
    format: (val: string) => val,
    cellWidth: 120,
  },
  {
    key: 'faultDescription',
    label: 'Description',
    format: (val: string) => val,
    cellWidth: 150,
  },
  {
    key: 'deviceUserName',
    label: 'Assigned to',
    format: (val: string) => val,
    cellWidth: 80,
  }
]

export { FaultsReportColumns }
