import React, { useEffect } from 'react'
import { useForm, SubmitHandler, Controller, FieldErrors } from 'react-hook-form'
import { sub } from 'date-fns'
import { DateRange, IFilterValues, IselectOptionProps } from 'forms/FormModelInterface'
// eslint-disable-next-line import/no-extraneous-dependencies
import { DevTool } from '@hookform/devtools'

import { DateRangeSelectorTypes, SelectOptionTypes } from 'utils/CommonEnums'
import { ChevronRightIcon, CloseIcon } from 'assets/icons'
import { DatePickerControl, SelectControl, CreatableSelectControl } from 'components/formComponents'
import FilterFormTitle from 'components/formComponents/FilterFormTitle'

import { dateRangeOptions, selectBlankOption } from 'forms/FormUtils'
import { withErrorBoundary } from 'react-error-boundary'
import ErrorPage from 'pages/common/ErrorPage'
import { max } from 'lodash'
import { DeviceAssignmentHistoryFilterFormType, columnIDs } from './DeviceAssignmentHistory'

interface DeviceAssignmentHistoryFilterFormProps {
  // Define any props that this component will receive
  selectedValues?: DeviceAssignmentHistoryFilterFormType
  deviceUserNames?: IselectOptionProps[]
  deviceUserUniqueIds?: IselectOptionProps[]

  // Define any functions that this component will call
  onFilterSubmit: (values: IFilterValues[]) => void
  onFilterReset: () => void
  onFilterFormClose: () => void
}

const defaultFilterValues = () => {
  const dtTo = new Date()
  const dtFrom = sub(new Date(), { months: 6 })

  const defValues: DeviceAssignmentHistoryFilterFormType = {
    assignedDateSelector: dateRangeOptions.find(
      (option) => option.value === DateRangeSelectorTypes.Custom,
    ) ?? {
      label: '',
      value: '',
    },
    assignedDate: {
      startDate: dtFrom,
      endDate: dtTo,
    },
    returnedDateSelector: dateRangeOptions.find(
      (option) => option.value === DateRangeSelectorTypes.Custom,
    ) ?? {
      label: '',
      value: '',
    },
    returnedDate: {
      startDate: dtFrom,
      endDate: dtTo,
    },
    deviceUserName: selectBlankOption,
    deviceUserUniqueID: selectBlankOption,
  }

  return defValues
}
// Define the component's logic and UI here
//   const { initialValues, defaultValues, deviceUsers } = props

function DeviceAssignmentHistoryFilterForm(
  props: DeviceAssignmentHistoryFilterFormProps,
): JSX.Element {
  const {
    selectedValues,
    deviceUserNames,
    deviceUserUniqueIds,
    onFilterSubmit,
    onFilterReset,
    onFilterFormClose,
  } = props

  const defaultFormValues = defaultFilterValues()
  const { register, control, handleSubmit, setValue, reset, formState, watch } =
    useForm<DeviceAssignmentHistoryFilterFormType>({
      defaultValues: defaultFormValues,
      values: selectedValues,
    })

  const watchAssignmentDateSelector = watch('assignedDateSelector')
  const watchReturnDateSelector = watch('returnedDateSelector')
  const customAssignedStartDate = watch('assignedDate.startDate')
  const customReturnedStartDate = watch('returnedDate.startDate')

  const [showCustomAssignmentDate, setShowCustomAssignmentDate] = React.useState<boolean>(false)
  const [showCustomReturnDate, setShowCustomReturnDate] = React.useState<boolean>(false)

  useEffect(() => {
    if (watchAssignmentDateSelector?.value === DateRangeSelectorTypes.Custom) {
      setShowCustomAssignmentDate(true)
    } else {
      setShowCustomAssignmentDate(false)
    }
    if (watchReturnDateSelector?.value === DateRangeSelectorTypes.Custom) {
      setShowCustomReturnDate(true)
    } else {
      setShowCustomReturnDate(false)
    }
  }, [watchAssignmentDateSelector, watchReturnDateSelector])

  const { errors, isDirty, isValid, isSubmitting } = formState

  const onSubmit: SubmitHandler<DeviceAssignmentHistoryFilterFormType> = (data) => {
    const filterValues: IFilterValues[] = []

    filterValues.push({
      columnId: columnIDs.deviceUserAssignedOn,
      value:
        data.assignedDateSelector.label === DateRangeSelectorTypes.All ? '' : data.assignedDate,
    })

    filterValues.push({
      columnId: columnIDs.deviceUserReturnedOn,
      value:
        data.returnedDateSelector.label === DateRangeSelectorTypes.All ? '' : data.returnedDate,
    })

    filterValues.push({
      columnId: columnIDs.deviceUserName,
      value: data.deviceUserName?.value?.toString().trim() ?? '',
    })

    filterValues.push({
      columnId: columnIDs.deviceUserUniqueID,
      value: data.deviceUserUniqueID?.value?.toString().trim() ?? '',
    })

    onFilterSubmit(filterValues)
  }

  const onError = (formErrors: FieldErrors<DeviceAssignmentHistoryFilterFormType>) => {
    console.log(formErrors)
  }

  const onReset = () => {
    reset(defaultFormValues)
    onFilterReset()
  }

  return (
    <div className='filter-form-container'>
      <div className='filter-form-header'>
        <FilterFormTitle />

        <div
          title='Close Filter Form'
          role='button'
          tabIndex={0}
          onClick={() => onFilterFormClose()}
          onKeyDown={() => onFilterFormClose()}
          className='filter-form-close-button'
        >
          <CloseIcon toggleClick={() => onFilterFormClose()} className='w-5 h-5 ' />
        </div>
      </div>

      <form
        onSubmit={handleSubmit(onSubmit, onError)}
        onReset={onFilterReset}
        noValidate
        className='h-full'
      >
        {/* // Assigned Date */}
        <div className='filter-form-fields-container'>
          <div className='flex flex-col gap-0.5 items-start self-stretch'>
            <label
              htmlFor='assignedDateSelector'
              className='pl-4 pr-3 text-xs font-bold leading-5 text-c-dark-blue-1 '
            >
              Assigned Date
            </label>

            <Controller
              name='assignedDateSelector'
              control={control}
              render={({ field }) => (
                <SelectControl
                  className='w-full h-[41px]'
                  selectControlProps={{
                    options: dateRangeOptions,
                    defaultValue: field.value,
                    isMulti: false,

                    onChange: (selectedOption: IselectOptionProps) => {
                      const selectedValue = selectedOption?.value
                      field.onChange(selectedOption)
                      if (selectedValue !== DateRangeSelectorTypes.Custom) {
                        setValue('assignedDate', selectedValue as DateRange, {
                          shouldValidate: true,
                        })
                      } else {
                        setValue('assignedDate', {
                          startDate: selectedValues?.assignedDate.startDate || new Date(),
                          endDate: selectedValues?.assignedDate.endDate || new Date(),
                        })
                      }
                    },
                  }}
                />
              )}
            />
          </div>
          {showCustomAssignmentDate && (
            <>
              <div className='flex flex-row justify-between items-end'>
                <div className='w-[110px]'>
                  <div className='flex flex-col gap-0.5 items-start '>
                    <label
                      htmlFor='assignedDateFrom'
                      className='pl-[13px] text-xs font-bold leading-5 text-c-dark-blue-1'
                    >
                      From
                    </label>
                    <div className=''>
                      <Controller
                        name='assignedDate.startDate'
                        control={control}
                        render={({ field }) => (
                          <DatePickerControl
                            className=''
                            datePickerProps={{
                              selected: field.value,
                              maxDate: new Date(),
                              onChange: (date: Date | null) => field.onChange(date as Date),
                            }}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>

                <div className='mx-0.5 w-4 h-4 my-3'>
                  <ChevronRightIcon className='self-center' />
                </div>

                <div className='w-[110px]'>
                  <div className='flex flex-col gap-0.5 items-start'>
                    <label
                      htmlFor='assignedDateFilterTo'
                      className='pl-[13px] text-xs font-bold leading-5 text-c-dark-blue-1'
                    >
                      To
                    </label>
                    <div className='self-stretch'>
                      <Controller
                        name='assignedDate.endDate'
                        control={control}
                        render={({ field }) => (
                          <DatePickerControl
                            className=''
                            datePickerProps={{
                              selected: field.value,
                              minDate: customAssignedStartDate,
                              onChange: (date: Date | null) => field.onChange(date as Date),
                            }}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {errors.assignedDate && (
                <>
                  <p className='text-xs text-red-500'>{errors.assignedDate?.startDate?.message}</p>
                  <p className='text-xs text-red-500'>{errors.assignedDate?.endDate?.message}</p>
                </>
              )}
            </>
          )}

          {/* // Returned Date */}
          <div className='flex flex-col gap-0.5 items-start self-stretch'>
            <label
              htmlFor='returnedDateSelectorFilter'
              className='pl-[13px] text-xs font-bold leading-5 text-c-dark-blue-1'
            >
              Returned Date
            </label>

            <Controller
              name='returnedDateSelector'
              control={control}
              render={({ field }) => (
                <SelectControl
                  className='w-full h-[41px]'
                  selectControlProps={{
                    options: dateRangeOptions,
                    defaultValue: field.value,
                    isMulti: false,

                    onChange: (selectedOption: IselectOptionProps) => {
                      const selectedValue = selectedOption?.value
                      field.onChange(selectedOption)
                      if (selectedValue !== DateRangeSelectorTypes.Custom) {
                        setValue('returnedDate', selectedValue as DateRange, {
                          shouldValidate: true,
                        })
                      } else {
                        setValue('returnedDate', {
                          startDate: selectedValues?.returnedDate.startDate || new Date(),
                          endDate: selectedValues?.returnedDate.endDate || new Date(),
                        })
                      }
                    },
                  }}
                />
              )}
            />
          </div>

          {showCustomReturnDate && (
            <>
              <div className='flex flex-row justify-between items-end'>
                <div className='w-[110px]'>
                  <div className='flex flex-col gap-0.5 items-start '>
                    <label
                      htmlFor='returnedDateSelectorFrom'
                      className='pl-[13px] text-xs font-bold leading-5 text-c-dark-blue-1'
                    >
                      From
                    </label>
                    <div className='self-stretch'>
                      <Controller
                        name='returnedDate.startDate'
                        control={control}
                        render={({ field }) => (
                          <DatePickerControl
                            className=''
                            datePickerProps={{
                              selected: field.value,
                              maxDate: new Date(),
                              onChange: (date: Date | null) => field.onChange(date as Date),
                            }}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>

                <div className='mx-0.5 w-4 h-4 my-3'>
                  <ChevronRightIcon className='self-center' />
                </div>

                <div className='w-[110px]'>
                  <div className='flex flex-col gap-0.5 items-start'>
                    <label
                      htmlFor='returnedDateFilterTo'
                      className='pl-[13px] text-xs font-bold leading-5 text-c-dark-blue-1 '
                    >
                      To
                    </label>
                    <div className='self-stretch'>
                      <Controller
                        name='returnedDate.endDate'
                        control={control}
                        render={({ field }) => (
                          <DatePickerControl
                            className=''
                            datePickerProps={{
                              selected: field.value,
                              minDate: customAssignedStartDate,
                              onChange: (date: Date | null) => field.onChange(date as Date),
                            }}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {errors.returnedDate && (
                <>
                  <p className='text-xs text-red-500'>{errors.returnedDate?.startDate?.message}</p>
                  <p className='text-xs text-red-500'>{errors.returnedDate?.endDate?.message}</p>
                </>
              )}
            </>
          )}

          <div className='flex flex-col gap-0.5 items-start self-stretch'>
            <label
              htmlFor='assignedToUserNamesFilter'
              className='pl-4 px-3 text-xs font-bold leading-5 text-c-dark-blue-1 '
            >
              Assigned To
            </label>
            <Controller
              name='deviceUserName'
              control={control}
              render={({ field }) => (
                <CreatableSelectControl
                  className='w-full h-[41px]'
                  selectControlProps={{
                    options: deviceUserNames,
                    value: field.value,
                    defaultValue: field.value,
                    isMulti: false,
                    isSearchable: true,
                    openMenuOnClick: true,
                    placeholder: '-',
                    isClearable: true,
                    onChange: (selectedOption: IselectOptionProps) => {
                      field.onChange(selectedOption)
                    },
                  }}
                />
              )}
            />
            <p className='text-xs text-red-500'>{errors.deviceUserName?.message}</p>
          </div>

          <div className='flex flex-col gap-0.5 items-start self-stretch'>
            <label
              htmlFor='uploadDataTypeFilter'
              className='pl-4 px-3 text-xs font-bold leading-5 text-c-dark-blue-1 '
            >
              Unique ID
            </label>
            <Controller
              name='deviceUserUniqueID'
              control={control}
              render={({ field }) => (
                <CreatableSelectControl
                  className='w-full h-[41px]'
                  selectControlProps={{
                    options: deviceUserUniqueIds,
                    value: field.value,
                    defaultValue: field.value,
                    isMulti: false,
                    isSearchable: true,
                    openMenuOnClick: true,
                    placeholder: '-',
                    isClearable: true,
                    onChange: (selectedOption: IselectOptionProps) => {
                      field.onChange(selectedOption)
                    },
                  }}
                />
              )}
            />
            <p className='text-xs text-red-500'>{errors.deviceUserUniqueID?.message}</p>
          </div>
        </div>
        {/* Buttons */}
        <div className='filter-form-footer'>
          <input
            className='filter-form-reset-button'
            type='reset'
            disabled={isSubmitting}
            onClick={() => {
              onReset()
            }}
          />
          <input
            className='filter-form-apply-button'
            type='submit'
            value='Apply'
            // disabled={!isDirty || !isValid || isSubmitting}
          />
        </div>
      </form>

      <DevTool control={control} />
    </div> // opening div
  )
}

export default withErrorBoundary(DeviceAssignmentHistoryFilterForm, {
  FallbackComponent: ErrorPage,
})
