import { QueryClient, useQuery } from '@tanstack/react-query'
import { useMsal } from '@azure/msal-react'

import ConnectAPIClient from 'services/ConnectAPIClient'
import { PortableTypes } from 'utils/CommonEnums'
import { getAccessToken, getAccessTokenProps } from 'services/MSALService'
import { deviceBySerialNumber } from 'services/apis/urls/device'
import { DeviceDetailsQueryKeyBySerial } from 'services/apis/caching/device'

interface iBusinessUnit {
  id: string
  name: string
}

interface iDeviceUser {
  id: string
  deviceUserId: string
  fullName: string
  deviceUserName: string
  uniqueId: string
  assignedOn: string
}

export interface iDeviceChannelInfoDto {
  channelNumber: number
  channelName: string
}

export interface iDeviceDetails {
  id: string
  serialNumber: string
  deviceType: PortableTypes
  customerId: string
  customerAssetNumber: string
  lastCalibratedDate: Date | string | null
  calibrationDueDate: Date | string | null
  businessUnit: iBusinessUnit
  deviceUser: iDeviceUser
  channels: Array<iDeviceChannelInfoDto>
  status: string
}

interface iAPIDeviceSerialNumberParams {
  deviceSerialNumber: string
}

interface iFetchDeviceDetailsParams {
  token: string
  deviceSerialNumber: string
}

interface iGetOrFetchDeviceDetailsProps {
  queryClient: QueryClient
  token: string
  deviceSerialNumber: string
}

interface iDeviceDetailsAPIParams extends iAPIDeviceSerialNumberParams, getAccessTokenProps {}

export async function FetchDeviceDetails(
  params: iFetchDeviceDetailsParams,
): Promise<iDeviceDetails> {
  try {
    const { token, deviceSerialNumber } = params
    const url = deviceBySerialNumber(deviceSerialNumber)
    const resp = await ConnectAPIClient.get<iDeviceDetails>(url, token)
    const response = resp.data
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}

export async function getDeviceDetailsData(deviceListAPIParams: iDeviceDetailsAPIParams) {
  const { msalContext, redirectPageURL, deviceSerialNumber } = deviceListAPIParams
  const token = await getAccessToken({ msalContext, redirectPageURL })
  const deviceListData = await FetchDeviceDetails({ token, deviceSerialNumber })
  return deviceListData
}

export function useDeviceDetails(
  redirectPageURL: string,
  deviceSerialNumber: string,
  enabled?: boolean,
) {
  const msalContext = useMsal()

  return useQuery(
    DeviceDetailsQueryKeyBySerial(deviceSerialNumber),
    () =>
      getDeviceDetailsData({
        msalContext,
        redirectPageURL,
        deviceSerialNumber,
      }),
    {
      enabled,
      onError: (error) => {
        console.log(error)
      },
    },
  )
}

export async function getOrFetchDeviceDetails(props: iGetOrFetchDeviceDetailsProps) {
  const { queryClient, token, deviceSerialNumber } = props

  const deviceDetailsCached = queryClient.getQueryData<iDeviceDetails>(
    DeviceDetailsQueryKeyBySerial(deviceSerialNumber),
  )

  if (deviceDetailsCached) {
    return deviceDetailsCached
  }

  const deviceDetailsFetched = await queryClient.fetchQuery(
    DeviceDetailsQueryKeyBySerial(deviceSerialNumber),
    async () =>
      FetchDeviceDetails({
        token,
        deviceSerialNumber,
      }),
  )

  return deviceDetailsFetched
}
