// Importing necessary libraries and components
import { AccountInfo } from '@azure/msal-browser'
import { useMsal } from '@azure/msal-react'
import CrowconButton from 'components/miscComponents/CrowconButton'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { SignInAuthority, apiScopes } from 'utils/AuthConfig'
import { AxiosError, AxiosResponse } from 'axios'
import { AlertIcon } from 'assets/icons'
import { useMutation } from '@tanstack/react-query'
import { useGenericEventHandler } from 'data/GenericEventHandler'
import { RedirectProps } from './SignUpPage'
import { iUserDataIsActivate, userExists } from '../data/SignupData'

// Defining the shape of the form input
interface IFormInput {
  email: string
}

// Spinner component
function Spinner({ className }: { className: string }) {
  return (
    <div
      className={`${className} animate-spin rounded-full border-4 border-solid border-c-white border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]`}
    />
  )
}

// ButtonChildren component for CrowconButton component
function ButtonChildren({ title, loader }: { title: string; loader: string | boolean }) {
  return (
    <div className='flex items-center justify-center'>
      <p className='text-ellipsis'>{title}</p>
      {loader === title && <Spinner className='h-4 w-4 ml-2' />}
    </div>
  )
}

// Error message component for showing error message if there is any error in form
function ErrorMessage({ className, message }: { className: string; message: string }) {
  return (
    <div className={className}>
      <AlertIcon className='h-4 w-4 mr-1' fill='#ef4444' />
      <p className='text-xs text-red-500 '>{message}</p>
    </div>
  )
}

// This is a functional component that handles multiple accounts
export default function MultipleAccounts({ userInviteData }: RedirectProps) {
  const { genericEventHandler } = useGenericEventHandler()
  // Using the useMsal hook to get the instance of MSAL (Microsoft Authentication Library)
  const { instance } = useMsal()
  // Getting all accounts from the MSAL instance
  const accounts = instance.getAllAccounts()

  // State variable for managing loading state
  const [loader, setLoader] = React.useState<string | boolean>(false)

  // Function to redirect to sign in page
  function redirectToSignIn(email: string) {
    setLoader(email)
    instance.loginRedirect({
      authority: SignInAuthority,
      loginHint: email,
      redirectUri: `/`,
      redirectStartPage: `/`,
      scopes: apiScopes,
      extraQueryParameters: {
        signinname: email,
      },
    })
  }

  // Using the useForm hook from react-hook-form for form handling
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isDirty },
    getValues,
    reset,
    clearErrors,
    setError,
  } = useForm<IFormInput>()

  // Using the useMutation hook from react-query to check if the user exists
  const userExistsOrNotMutation = useMutation({
    mutationFn: (email: string) => userExists(email),

    onSuccess({ isUserActive }: iUserDataIsActivate, variables: string) {
      // Resetting the form
      reset()
      // If the user is active, redirect to sign in
      if (isUserActive) {
        setLoader(false)
        redirectToSignIn(variables)
      }
    },
    onError(error: AxiosError) {
      // // Resetting the form
      // reset()
      // If there is an error, set the loader state to false
      setLoader(false)
      const { response } = error as AxiosError
      const { status } = response as AxiosResponse<iUserDataIsActivate>
      // If the status is 404, set an error message
      if (status === 404) {
        setError('email', {
          type: 'manual',
          message: 'User not exists, try with different account',
        })
      }
    },
  })

  // Function to handle form submission
  const onSubmit = async ({ email }: { email: string }) => {
    // Setting the loader state to true
    setLoader(email)
    // Clearing any form errors
    clearErrors()
    // Checking if the user exists
    userExistsOrNotMutation.mutate(email)
  }

  const { error: userExistsOrNotError } = userExistsOrNotMutation
  useEffect(() => {
    if (userExistsOrNotError) {
      genericEventHandler({
        onlyTrack: true,
        severity: 'error',
        message: userExistsOrNotError.message || 'Error while User is on multiple account page',
        extraData: {
          component: 'MultipleAccounts',
          action: 'User is on multiple account page',
        },
      })
    }
  }, [userExistsOrNotError])

  // The component returns a form that allows the user to select an account or enter a new email
  return (
    <div className='mx-auto text-center text-c-dark-blue-1 w-screen md:w-max p-2 md:p-0'>
      <p className='text-base font-bold font-poppins text-center md:text-left tracking-tight text-c-dark-blue-1  leading-[20px] mb-3'>
        Pick an account
        <br />
        <span className='text-xs font-poppins font-medium'>
          to continue with your Crowcon Connect
        </span>
      </p>
      <CrowconButton
        additionalClassName='text-base mb-2 bg-c-dark-blue-1 w-full h-12 rounded-3xl px-2'
        buttonChildren={
          <ButtonChildren title={userInviteData.emailId.toString()} loader={loader} />
        }
        buttonAction={() => redirectToSignIn(userInviteData.emailId as string)}
      />

      <div className='flex flex-col'>
        {accounts.map((account: AccountInfo) => {
          if (account.idTokenClaims?.email) {
            return (
              <CrowconButton
                key={`${account.localAccountId}-${Math.random()}`}
                additionalClassName='text-base mb-2 bg-c-dark-blue-1 w-full h-12 rounded-3xl px-2'
                buttonChildren={
                  <ButtonChildren title={account.idTokenClaims?.email as string} loader={loader} />
                }
                buttonAction={() => redirectToSignIn(account.idTokenClaims?.email as string)}
              />
            )
          }
          return null
        })}
      </div>
      <div className='flex flex-row items-center'>
        <div className='w-full h-[0px] border border-slate-300	' />
        <p className='px-[10px]'> or</p>
        <div className='w-full h-[0px] border border-slate-300	' />
      </div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className='flex md:flex-row flex-col items-center w-full mb-2 '
      >
        <div className=' md:w-4/6 w-full m-1 md:mr-3'>
          <input
            placeholder='Enter your email'
            {...register('email', {
              onChange() {
                if (errors.email) {
                  clearErrors('email')
                }
              },
              required: { value: true, message: 'Enter email address' },
              pattern: {
                value: /^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}$/,
                message: 'Enter valid email',
              },
            })}
            className='text-normal text-center font-medium text-c-dark-blue-1 px-2 py-1 mb-2 md:mb-0 w-full rounded-3xl  h-12 bg-c-light-blue-2 cursor-pointer hover:bg-c-light-blue-4'
          />
        </div>
        {errors.email && errors.email.message && (
          <ErrorMessage
            className='flex md:hidden mb-4 items-center'
            message={errors.email.message}
          />
        )}

        {loader === getValues('email') ? (
          <div className='flex items-center justify-center text-base text-white bg-c-dark-blue-1  h-12 rounded-3xl md:w-2/6 w-full'>
            <Spinner className='h-4 w-4' />
          </div>
        ) : (
          <input
            className={`text-base text-white ${
              isDirty || isSubmitting ? 'bg-c-dark-blue-1' : 'bg-slate-300'
            }  h-12 rounded-3xl md:w-2/6 w-full`}
            type='submit'
            value='Submit'
            disabled={!isDirty || isSubmitting}
          />
        )}
      </form>

      {errors.email && errors.email.message && (
        <ErrorMessage className='md:flex hidden items-center' message={errors.email.message} />
      )}
    </div>
  )
}
