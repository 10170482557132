import React, { Component } from 'react'
import clsx from 'clsx'
import { NavFirstIcon, NavLastIcon, NavNextIcon, NavPreviousIcon } from 'assets/icons'
import { SelectControl } from 'components/formComponents'
import { TsTableParams } from 'utils/Constants'
import PaginationSelectControl from './PaginationSelectControl'

export interface tsTableFooterProps {
  pageSize: number
  setPageSize: (size: number) => void
  pageIndex: number
  setPageIndex: (index: number) => void
  nextPage: () => void
  previousPage: () => void
  pageCount: number
  canNextPage: boolean
  canPreviousPage: boolean
  totalRecords?: number
  isTableDataLoading?: boolean
}

function TsTableFooter(paginationProps: tsTableFooterProps) {
  const {
    pageSize,
    setPageSize,
    pageIndex,
    setPageIndex,
    nextPage,
    previousPage,
    pageCount,
    canNextPage,
    canPreviousPage,
    totalRecords,
    isTableDataLoading = false,
  } = paginationProps

  return (
    <div
      id='pagination-bar'
      className='h-full flex flex-row items-center align-middle justify-end mr-3 md:mr-5 '
    >
      <div
        id='pagination-count-text'
        className='hidden xs:block text-4xs font-bold leading-3 mr-2 md:mr-4 '
      >
        Results per page
      </div>

      <div id='page-size-selector' className=' w-[58px] h-8 hidden xs:flex xs:items-center mr-2 '>
        <PaginationSelectControl
          id='page-size-select'
          className='h-8 w-full '
          selectControlProps={{
            options: TsTableParams.PageSizes.map((pSize) => ({
              value: pSize,
              label: pSize.toString(),
            })),
            onChange: (e: any) => {
              setPageSize(Number(e.value))
            },
            defaultValue: {
              value: pageSize,
              label: pageSize.toString(),
            },
            value: pageSize,
            menuPlacement: 'top',
            isSearchable: false,
            isMulti: false,
            isDropDownSelectable: true,
            openMenuOnClick: true,
            placeholder: '-',
            isClearable: false,
          }}
        />
      </div>

      <div className='w-32 flex flex-row justify-between gap-x-0.5 mr-3'>
        {totalRecords && totalRecords > 0 ? (
          <div>
            <p className='text-4xs font-bold leading-3'>
              Showing &nbsp;
              <span className='text-4xs font-bold leading-3'>
                {totalRecords ? pageIndex * pageSize + 1 : '0'}
              </span>
              &nbsp; - &nbsp;
              <span className='text-4xs font-bold leading-3'>
                {totalRecords ? (pageIndex + 1) * pageSize : '0'}
              </span>
              &nbsp; of &nbsp;
              <span className='text-4xs font-bold leading-3'>{totalRecords || '0'}</span>
            </p>
          </div>
        ) : (
          <div>
            <p className='text-4xs font-bold leading-3'>Showing 0 records</p>
          </div>
        )}

        {/* <div id='records-count-text' className=' flex justify-end text-4xs font-bold leading-3 '>
          <div className=''>Showing</div>
        </div>

        <div
          id='records-count'
          className='w-full  flex gap-x-1 justify-end text-4xs font-bold leading-3'
        >
          <div className=''>{`${pageIndex * pageSize + 1} - ${(pageIndex + 1) * pageSize}`}</div>
          <div className=''>{totalRecords && `of ${totalRecords}`}</div>
        </div> */}
      </div>

      <div id='nav-buttons' className='flex flex-row gap-3'>
        <button
          title='First Page'
          type='button'
          tabIndex={0}
          onClick={() => setPageIndex(0)}
          disabled={!canPreviousPage || isTableDataLoading}
          className={clsx(
            ' w-8 h-8 p-1.5 rounded-full ',
            // "focus:border-1",
            pageIndex > 0
              ? 'bg-c-dark-blue-1  hover:bg-c-dark-blue-2 focus:border-c-blue focus:bg-c-dark-blue-1 active:bg-c-blue'
              : 'bg-c-light-blue-2 ',
          )}
        >
          <NavFirstIcon
            className={pageIndex > 0 ? 'stroke-c-white' : 'stroke-c-light-gray-1'}
            // toggleClick={() => setPageIndex(0)}
          />
        </button>

        <button
          title='Previous Page'
          type='button'
          tabIndex={0}
          onClick={() => previousPage()}
          disabled={!canPreviousPage || isTableDataLoading}
          className={clsx(
            ' w-8 h-8 p-1.5 rounded-full ',
            // 'focus:border-1',
            canPreviousPage
              ? 'bg-c-dark-blue-1  hover:bg-c-dark-blue-2 focus:border-c-blue focus:bg-c-dark-blue-1 active:bg-c-blue'
              : 'bg-c-light-blue-2 ',
          )}
        >
          <NavPreviousIcon
            className={canPreviousPage ? 'stroke-c-white' : 'stroke-c-light-gray-1'}
            // toggleClick={() => previousPage()}
          />
        </button>

        <button
          title='Next Page'
          type='button'
          tabIndex={0}
          onClick={() => nextPage()}
          disabled={!canNextPage || isTableDataLoading}
          className={clsx(
            ' w-8 h-8 p-1.5 rounded-full ',
            // 'focus:border-1',
            canNextPage
              ? 'bg-c-dark-blue-1  hover:bg-c-dark-blue-2  focus:border-c-blue focus:bg-c-dark-blue-1 active:bg-c-blue'
              : 'bg-c-light-blue-2 ',
          )}
        >
          <NavNextIcon
            className={canNextPage ? 'stroke-c-white' : 'stroke-c-light-gray-1'}
            // toggleClick={() => nextPage()}
          />
        </button>

        <button
          title='Last Page'
          type='button'
          tabIndex={0}
          onClick={() => setPageIndex(pageCount - 1)}
          disabled={!canNextPage || isTableDataLoading}
          className={clsx(
            ' w-8 h-8 p-1.5 rounded-full ',
            // "focus:border-1",
            pageIndex + 1 < pageCount
              ? 'bg-c-dark-blue-1  hover:bg-c-dark-blue-2  focus:border-c-blue focus:bg-c-dark-blue-1 active:bg-c-blue'
              : 'bg-c-light-blue-2 ',
          )}
        >
          <NavLastIcon
            className={pageIndex + 1 < pageCount ? 'stroke-c-white' : 'stroke-c-light-gray-1'}
            // toggleClick={() => setPageIndex(pageCount - 1)}
          />
        </button>
      </div>
    </div>
  )
}

export default TsTableFooter

// <select
//           className='text-4xs font-bold leading-3 h-8 w-14 px-2 rounded-2xl border-3 border-c-light-blue-4 hover:border-c-dark-blue-1 focus:border-c-blue '
//           value={pageSize}
//           onChange={(e) => {
//             setPageSize(Number(e.target.value))
//           }}
//         >
//           {[5, 10, 20, 50].map((pSize) => (
//             <option key={pSize} value={pSize}>
//               {pSize}
//             </option>
//           ))}
//         </select>
