import {
  DashboardIcon,
  DevicesIcon,
  CalibrationIcon,
  ServicesIcon,
  AlarmIcon,
  OperatorsIcon,
  SupportIcon,
} from 'assets/icons'
import { SvgProps } from 'types/SVGProps'
import { ISideNavMenuItem, ISideNavMenuSection } from './Interfaces'

const iconProps: SvgProps = {
  className: 'w-5 h-5',
}

const dashBoardMenuItem: ISideNavMenuItem = {
  Id: 10,
  Label: 'Dashboard',
  Path: 'dashboard',
  Icon: DashboardIcon(iconProps),
}

const devicesMenuItem: ISideNavMenuItem = {
  Id: 100,
  Label: 'Devices',
  Path: 'devices',
  Icon: DevicesIcon(iconProps),
}

const calibrationMenuItem: ISideNavMenuItem = {
  Id: 110,
  Label: 'Calibration',
  Path: 'calibration',
  Icon: CalibrationIcon(iconProps),
}

const serviceMenuItem: ISideNavMenuItem = {
  Id: 120,
  Label: 'Service',
  Path: 'service',
  // Path: 'devices/W1234',
  Icon: ServicesIcon(iconProps),
}

const safetyMenuItem: ISideNavMenuItem = {
  Id: 130,
  Label: 'Safety',
  Path: 'safety',
  Icon: AlarmIcon(iconProps),
}

// const usersMenuItem: ISideNavMenuItem = {
//   Id: 140,
//   Label: 'Users',
//   Path: 'users',
//   Icon: OperatorsIcon(iconProps),
//   // 'w-full col-start-1 col-end-2 sm:col-start-2 sm:col-end-3 md:col-start-3 md:col-end-4 row-start-5 row-end-6 sm:row-start-1 sm:row-end-2 md:row-start-1 md:row-end-2',
// }

const operatorsMenuItem: ISideNavMenuItem = {
  Id: 200,
  Label: 'Operators',
  Path: 'operators',
  Icon: OperatorsIcon({
    ...iconProps,
  }),
}

// const settingsMenuItem: ISideNavMenuItem = {
//   Id: 210,
//   Label: 'Settings',
//   Path: 'org/info',
//   Icon: AdminIcon(iconProps),
//   className:
//     'w-full col-start-1 col-end-2 sm:col-start-2 sm:col-end-3 md:col-start-4 md:col-end-5 row-start-7 row-end-8 sm:row-start-3 sm:row-end-4 md:row-start-1 md:row-end-2',
// }

const helpMenuItem: ISideNavMenuItem = {
  Id: 300,
  Label: 'Help',
  Path: 'help',
  Icon: SupportIcon(iconProps),
}

const DashboardSectionList: Array<ISideNavMenuItem> = [dashBoardMenuItem]

const ReportSectionList: Array<ISideNavMenuItem> = [
  devicesMenuItem,
  calibrationMenuItem,
  serviceMenuItem,
  safetyMenuItem,
  operatorsMenuItem,
]

// const DataSectionList: Array<ISideNavMenuItem> = [
//   // operatorsMenuItem,
//   // settingsMenuItem,
// ]

const SupportSectionList: Array<ISideNavMenuItem> = [helpMenuItem]

export const topNavMenuItems: Array<ISideNavMenuItem> = [
  dashBoardMenuItem,
  devicesMenuItem,
  calibrationMenuItem,
  serviceMenuItem,
  safetyMenuItem,
  operatorsMenuItem,
  // settingsMenuItem,
  // helpMenuItem,
]

export const SideNavMenuSections: Array<ISideNavMenuSection> = [
  {
    Id: 1000,
    SideNavMenuItems: DashboardSectionList,
  },
  {
    Id: 1010,
    SideNavMenuItems: ReportSectionList,
  },
  // {
  //   Id: 1020,
  //   SideNavMenuItems: DataSectionList,
  // },
  {
    Id: 1030,
    SideNavMenuItems: SupportSectionList,
  },
]
