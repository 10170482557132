/* eslint-disable no-underscore-dangle */
import React, { useContext, useEffect, useState } from 'react'
import Tree from 'react-d3-tree'
import { BusinessContext } from 'contexts/BusinessContext'
import { UserAccountContext } from 'contexts/UserAccountContext'
import { useGenericEventHandler } from 'data/GenericEventHandler'
import { IselectOptionProps } from 'forms/FormModelInterface'
import { useLocation } from 'react-router-dom'
import { IsUserGlobalScope } from 'utils/UserDataUtils'
import Show from 'components/atom/Show'
import SelectControlWithLabel from 'components/formComponents/SelectControlWithLabel'
import { useBusinessUnitTreeList } from '../data/OrganizationPageData'
import { convertToObject } from '../utils/OrganizationUtils'
import { iBusinessUnitTreeData } from '../utils/OrganizationInterface'
import BusinessUnitTreeNode from './BusinessUnitTreeNode'

function Loader() {
  return (
    <div className='animate-pulse absolute right-1/2 top-1/2   '>
      <div className='h-8 w-8 animate-spin rounded-full border-4 border-solid border-c-orange border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]' />
    </div>
  )
}
/**
 * Renders the organization BusinessUnitTree tree.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {iBusinessUnitTreeData} props.data - The organization BusinessUnitTree data.
 * @returns {JSX.Element | null} The rendered organization BusinessUnitTree tree.
 */
export default function BusinessUnitTree(): JSX.Element | null {
  // Hook to access the current location object which contains information about the current URL
  const location = useLocation()

  // Custom hook for handling generic events
  const { genericEventHandler } = useGenericEventHandler()

  // Context hook to get user information from UserAccountContext
  const { state: userInfo } = useContext(UserAccountContext)

  // Context hook to get customer data from BusinessContext
  const { selectedCustomer, customerDataFromQuery } = useContext(BusinessContext)

  const customerList = customerDataFromQuery?.data

  // Check if the user has global scope
  const isGlobalUser = IsUserGlobalScope(userInfo)

  useEffect(() => {
    document.title = 'Profile - Business'
  }, [])

  // Fetch organization BusinessUnitTree data using the useBusinessUnitTreeList hook
  const {
    data: BusinessUnitTreeData, // The fetched data
    isLoading: BusinessUnitTreeLoading, // Flag indicating if the data is loading
    isError: isBusinessUnitTreeDataError, // Flag indicating if there was an error fetching the data
    error: BusinessUnitTreeError, // The error object if there was an error
  } = useBusinessUnitTreeList(
    location.pathname, // The current URL path
    (isGlobalUser
      ? selectedCustomer && selectedCustomer?.id !== 'all'
        ? selectedCustomer?.id
        : customerList?.[1].id
      : userInfo.user.customerId) as string, // The customer ID based on user scope
    Boolean(
      isGlobalUser
        ? (selectedCustomer && selectedCustomer?.id) || (customerList && customerList?.[1]?.id)
        : userInfo.user.customerId,
    ), // Flag indicating if the user has global scope
  )
  // useEffect is a React hook that performs side effects in function components.
  // In this case, it's used to handle errors when fetching organization BusinessUnitTree and hierarchy data.
  useEffect(() => {
    // Check if there was an error fetching the organization BusinessUnitTree data
    if (isBusinessUnitTreeDataError) {
      // If there was an error, call the generic event handler with an error object
      genericEventHandler({
        error: BusinessUnitTreeError, // The error object
        severity: 'error', // The severity of the event
        onlyTrack: true, // Whether to only track the event, not display it
        message:
          BusinessUnitTreeError?.message || 'Error fetching organization BusinessUnitTree data', // The error message
        extraData: {
          // Extra data to include with the event
          component: 'Organization.tsx', // The component where the event occurred
          action: 'fetching organization BusinessUnitTree data', // The action that was being performed when the event occurred
        },
      })
    }
  }, [
    // The useEffect hook will run whenever any of these dependencies change
    isBusinessUnitTreeDataError,
    BusinessUnitTreeError,
  ])

  // Initialize a state variable 'translate' with useState hook. This state will hold an object with two properties:
  // 'x': a number indicating the x-coordinate for translation,
  // 'y': a number indicating the y-coordinate for translation.
  const [translate, setTranslate] = React.useState<{ x: number; y: number }>()

  // useEffect is a hook provided by React that is used to perform side effects in function components.
  // It takes a function and an array of dependencies.
  // In this case, the function is called when the component mounts and whenever the window is resized.
  useEffect(() => {
    // Define a resizeListener function that will be called when the window is resized.
    const resizeListener = () => {
      // Get the element with the id 'tree-wrapper'.
      const treeWrapper = document.getElementById('tree-wrapper')
      // If the element exists, set 'translate' state to an object with 'x' set to half of the element's clientWidth and 'y' set to 10.
      if (treeWrapper) {
        setTranslate({
          x: (treeWrapper?.clientWidth as number) / 2,
          y: 150,
        })
      }
    }
    // Call the resizeListener function.
    resizeListener()
    // Add the resizeListener function as an event listener for the 'resize' event on the window.
    window.addEventListener('resize', resizeListener)
    // Return a cleanup function that removes the resizeListener function as an event listener for the 'resize' event on the window.
    // This cleanup function is called when the component unmounts.
    return () => {
      window.removeEventListener('resize', resizeListener)
    }
    // The dependency array is empty, which means the effect will only run once when the component mounts, and the cleanup function will run when the component unmounts.
  }, [])

  return (
    <div className='w-full h-full relative  bg-c-light-blue-1'>
      <div className='w-full h-[calc(100vh-80px)] relative z-10' id='tree-wrapper'>
        <Show>
          <Show.When isTrue={Boolean(BusinessUnitTreeLoading && translate)}>
            <Loader />
          </Show.When>
          <Show.Otherwise>
            <Tree
              data={convertToObject(BusinessUnitTreeData as iBusinessUnitTreeData)}
              orientation='vertical'
              translate={translate}
              renderCustomNodeElement={(props) => <BusinessUnitTreeNode {...props} />}
              pathFunc='step'
              collapsible
              zoomable
              separation={{ siblings: 3, nonSiblings: 2 }}
              draggable
              scaleExtent={{ min: 0.1, max: 10 }}
              // enableLegacyTransitions
              transitionDuration={2}
              zoom={0.6}
            />
          </Show.Otherwise>
        </Show>
      </div>
    </div>
  )
}
BusinessUnitTree.defaultProps = {
  data: null,
}
