import React from 'react'
import { IFilterValues, DateRange } from 'forms/FormModelInterface'
import { FormatDateRange } from 'exportReports/ExportDataUtils'
import { CalibrationPageColumnIds } from '../view/CalibrationsPageUtils'

const CalibrationReportFilterLookup = [
  {
    columId: CalibrationPageColumnIds.serialNumber,
    label: 'Serial #',
    format: (val: string) => val,
  },
  { columId: CalibrationPageColumnIds.deviceType, label: 'Device', format: (val: string) => val },
  {
    columId: CalibrationPageColumnIds.businessUnit,
    label: 'Business unit',
    format: (val: string) => val,
  },
  {
    columId: CalibrationPageColumnIds.calibrationStatus,
    label: 'Status',
    format: (val: string) => val,
  },
  {
    columId: CalibrationPageColumnIds.lastCalSelector,
    label: 'Last calibrated',
    format: (val: DateRange) => (val ? FormatDateRange(val) : ''),
  },
  {
    columId: CalibrationPageColumnIds.calDueSelector,
    label: 'Calibration due',
    format: (val: DateRange) => (val ? FormatDateRange(val) : ''),
  },
  {
    columId: CalibrationPageColumnIds.deviceUser,
    label: 'Assigned To',
    format: (val: string) => val,
  },
]

const mapColumnLabel = (filterVal: IFilterValues) => {
  const labelToReturn = CalibrationReportFilterLookup.find(
    (l) => l.columId === filterVal.columnId,
  )?.label
  return typeof labelToReturn !== 'undefined' ? `${labelToReturn}` : ''
}

const mapColumnValue = (filterVal: any) => {
  const valueToReturn = CalibrationReportFilterLookup.find(
    (l) => l.columId === filterVal.columnId,
  )?.format(filterVal?.value || '')
  return typeof valueToReturn !== 'undefined' ? `${valueToReturn}` : ''
}

function CalibrationFilterFormat(filterValues: IFilterValues[], customer: string, mainBu: string) {
  let summary = `Customer: ${customer}, Global business unit: ${mainBu}\n`
  filterValues?.forEach((f) => {
    if (f.value && mapColumnLabel(f) && mapColumnValue(f)) {
      summary = `${`${summary} ${mapColumnLabel(f)}`}: ${f.value ? mapColumnValue(f) : ''},`
    }
  })

  return summary.replace(/,\s*$/, '')
}

export default CalibrationFilterFormat
