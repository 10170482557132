import React from 'react'
import { SvgProps } from 'types/SVGProps'

function OperatorsIcon(props: SvgProps): JSX.Element {
  const { stroke, fill, className, toggleClick } = props

  return (
    <div className=''>
      <svg
        className={className}
        width='20'
        height='20'
        viewBox='0 0 20 20'
        fill={fill ?? 'none'}
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M12.7679 16.336C12.7679 15.3467 12.504 14.416 12.0416 13.6071ZM12.7679 16.336V16.4302C10.9918 17.4605 8.95691 18.0034 6.8835 18C4.73222 18 2.71937 17.4267 1.00092 16.4302L1 16.3333C0.999293 15.0751 1.43341 13.8524 2.23391 12.858C3.0344 11.8636 4.15575 11.1542 5.42115 10.8414C6.68656 10.5287 8.02405 10.6305 9.22275 11.1307C10.4215 11.631 11.4132 12.504 12.0416 13.6071M9.99922 5C9.99922 5.79565 9.67106 6.55871 9.08692 7.12132C8.50278 7.68392 7.71052 7.99999 6.88443 7.99999C6.05833 7.99999 5.26607 7.68392 4.68193 7.12132C4.0978 6.55871 3.76963 5.79565 3.76963 5C3.76963 4.20435 4.0978 3.44129 4.68193 2.87868C5.26607 2.31607 6.05833 2 6.88443 2C7.71052 2 8.50278 2.31607 9.08692 2.87868C9.67106 3.44129 9.99922 4.20435 9.99922 5Z'
          stroke={stroke ?? '#FFFFFF'}
          strokeWidth='1.8'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M16.2678 9.26777C16.7366 8.79893 17 8.16304 17 7.5C17 6.83696 16.7366 6.20107 16.2678 5.73223C15.7989 5.26339 15.163 5 14.5 5C13.837 5 13.2011 5.26339 12.7322 5.73223C12.2634 6.20107 12 6.83696 12 7.5C12 8.16304 12.2634 8.79893 12.7322 9.26777C13.2011 9.73661 13.837 10 14.5 10C15.163 10 15.7989 9.73661 16.2678 9.26777Z'
          stroke={stroke ?? '#FFFFFF'}
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M11.4211 12.7956C12.0965 12.3277 13.0427 12 14.3684 12C19 12 19 16 19 16C19 16 19 17 14.3684 17C12.7404 17 11.6847 16.8764 11 16.7162'
          stroke={stroke ?? '#FFFFFF'}
        />
      </svg>
    </div>
  )
}

export default OperatorsIcon
