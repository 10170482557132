/* eslint-disable no-underscore-dangle */
import React, { useContext, useEffect, useState } from 'react'
import { BusinessContext } from 'contexts/BusinessContext'
import { UserAccountContext } from 'contexts/UserAccountContext'
import { useGenericEventHandler } from 'data/GenericEventHandler'
import { IselectOptionProps } from 'forms/FormModelInterface'
import { useLocation } from 'react-router-dom'
import { IsUserGlobalScope } from 'utils/UserDataUtils'
import Show from 'components/atom/Show'
import SelectControlWithLabel from 'components/formComponents/SelectControlWithLabel'
import Tree from 'react-d3-tree'
import { convertToNested } from '../utils/OrganizationUtils'
import TreeNode from './OrganizationTreeNode'
import { useOrganizationTreeList } from '../data/OrganizationPageData'

function Loader() {
  return (
    <div className='animate-pulse absolute right-1/2 top-1/2   '>
      <div className='h-8 w-8 animate-spin rounded-full border-4 border-solid border-c-orange border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]' />
    </div>
  )
}
/**
 * Renders a hierarchical organization tree.
 *
 * @component
 * @param {Object[]} data - The hierarchical organization data.
 * @returns {JSX.Element | null} The rendered Tree tree component.
 */
export default function OrganizationTree(): JSX.Element | null {
  // Hook to access the current location object which contains information about the current URL
  const location = useLocation()

  // Custom hook for handling generic events
  const { genericEventHandler } = useGenericEventHandler()

  // Context hook to get user information from UserAccountContext
  const { state: userInfo } = useContext(UserAccountContext)

  // Context hook to get customer data from BusinessContext
  const { selectedCustomer, customerDataFromQuery } = useContext(BusinessContext)
  const customerList = customerDataFromQuery?.data

  // Check if the user has global scope
  const isGlobalUser = IsUserGlobalScope(userInfo)

  useEffect(() => {
    document.title = 'Profile - Organization'
  }, [])

  // Fetch organization tree data using the useOrganizationTreeList hook
  const {
    data: organizationTreeData, // The fetched organization tree data
    isLoading: organizationTreeLoading, // Flag indicating if the data is loading
    isError: isOrganizationTreeDataError, // Flag indicating if there was an error fetching the data
    error: organizationTreeError, // The error object if there was an error
  } = useOrganizationTreeList(
    location.pathname, // The current URL path
    (isGlobalUser
      ? selectedCustomer && selectedCustomer?.id !== 'all'
        ? selectedCustomer?.id
        : customerList?.[1].id
      : userInfo.user.customerId) as string, // The customer ID based on user scope
    Boolean(
      isGlobalUser
        ? (selectedCustomer && selectedCustomer?.id) || (customerList && customerList?.[1]?.id)
        : userInfo.user.customerId, // Flag indicating if the customer ID is available
    ),
  )

  // useEffect is a React hook that performs side effects in function components.
  // In this case, it's used to handle errors when fetching organization tree data.
  useEffect(() => {
    // Check if there was an error fetching the organization Tree data
    if (isOrganizationTreeDataError) {
      // If there was an error, call the generic event handler with an error object
      genericEventHandler({
        error: organizationTreeError, // The error object
        severity: 'error', // The severity of the event
        onlyTrack: true, // Whether to only track the event, not display it
        message: organizationTreeError?.message || 'Error fetching organization Tree data', // The error message
        extraData: {
          // Extra data to include with the event
          component: 'Organization.tsx', // The component where the event occurred
          action: 'fetching organization Tree data', // The action that was being performed when the event occurred
        },
      })
    }
  }, [
    // The useEffect hook will run whenever any of these dependencies change

    isOrganizationTreeDataError,
    organizationTreeError,
  ])

  // Initialize a state variable 'translate' with useState hook. This state will hold the translation values for x and y coordinates.
  const [translate, setTranslate] = React.useState<{ x: number; y: number }>()

  // useEffect is a React hook that performs side effects in function components.
  // In this case, it's used to handle window resize events.
  useEffect(() => {
    // Define a resize listener function
    const resizeListener = () => {
      // Get the element with id 'tree-wrapper'
      const treeWrapper = document.getElementById('tree-wrapper')
      // If the element exists
      if (treeWrapper) {
        // Update the 'translate' state with new values
        // The x value is half of the clientWidth of the 'tree-wrapper' element
        // The y value is set to 10
        setTranslate({
          x: (treeWrapper?.clientWidth as number) / 2,
          y: 100,
        })
      }
    }
    // Call the resize listener function immediately to set the initial 'translate' state
    resizeListener()
    // Add the resize listener function to the window's 'resize' event
    window.addEventListener('resize', resizeListener)
    // Return a cleanup function that removes the resize listener function from the window's 'resize' event
    return () => {
      window.removeEventListener('resize', resizeListener)
    }
    // The useEffect hook will run once when the component mounts, because its dependency array is empty
  }, [])

  return (
    <div className='w-full h-full relative bg-c-light-blue-1  '>
      <div className='w-full h-[calc(100vh-80px)] relative z-10 ' id='tree-wrapper'>
        <Show>
          <Show.When isTrue={organizationTreeLoading}>
            <Loader />
          </Show.When>
          <Show.Otherwise>
            <Tree
              data={convertToNested(organizationTreeData || [])}
              orientation='vertical'
              translate={translate}
              renderCustomNodeElement={(props) => <TreeNode {...props} />}
              pathFunc='step'
              collapsible
              zoomable
              separation={{ siblings: 3, nonSiblings: 2 }}
              draggable
              scaleExtent={{ min: 0.1, max: 10 }}
              enableLegacyTransitions
              zoom={0.6}
            />
          </Show.Otherwise>
        </Show>
      </div>
    </div>
  )
}

OrganizationTree.defaultProps = {
  data: null,
}
