import { differenceInCalendarDays, sub } from 'date-fns'
import { iDevice } from 'data/DeviceListHook'
import { DeviceCalDueTypes } from 'utils/CommonEnums'
import { dayDateToDate, returnStaticDate } from 'components/modules/Calender/CalenderUtils'

export function getDeviceCalDueStatus(device: iDevice) {
  if (!device.calibrationDueDate) return DeviceCalDueTypes.NotAvailable
  const dueDate = new Date(device.calibrationDueDate)
  const today = new Date()

  //   differenceInCalendarDays(dateLeft, dateRight)
  // Arguments
  // Name	Type	Description
  // dateLeft	Date | Number
  // the later date

  // dateRight	Date | Number
  // the earlier date

  const timeDiff = differenceInCalendarDays(dueDate, today)

  if (timeDiff < 0) return DeviceCalDueTypes.Overdue
  if (timeDiff <= 7) return DeviceCalDueTypes.DueIn7Days
  if (timeDiff <= 30) return DeviceCalDueTypes.DueIn30Days
  if (timeDiff <= 60) return DeviceCalDueTypes.DueIn60Days
  if (timeDiff <= 90) return DeviceCalDueTypes.DueIn90Days
  return DeviceCalDueTypes.DueOver90Days
}

export const checkWeatherDeviceOverDue = (
  date: string,
): { displayValue: string; isOverDue: boolean } => {
  const dueDate = dayDateToDate(date).getTime()
  const currentDate = dayDateToDate(returnStaticDate(new Date())).getTime()
  const daysDifference = (dueDate - currentDate) / (1000 * 60 * 60 * 24)

  if (daysDifference < 0)
    return {
      displayValue: DeviceCalDueTypes.Overdue,
      isOverDue: true,
    }
  if (daysDifference <= 7)
    return {
      displayValue: DeviceCalDueTypes.DueIn7Days,
      isOverDue: false,
    }
  if (daysDifference <= 30)
    return {
      displayValue: DeviceCalDueTypes.DueIn30Days,
      isOverDue: false,
    }
  if (daysDifference <= 60)
    return {
      displayValue: DeviceCalDueTypes.DueIn60Days,
      isOverDue: false,
    }
  if (daysDifference <= 90)
    return {
      displayValue: DeviceCalDueTypes.DueIn90Days,
      isOverDue: false,
    }
  return {
    displayValue: DeviceCalDueTypes.DueOver90Days,
    isOverDue: false,
  }
}
