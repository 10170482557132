import React from 'react'
import withRoleChecking from 'hoc/withRoleChecking'
import { UserRoleTypes } from 'utils/CommonEnums'
import { AddEntryInTableButton } from 'components/table/tsTableComponents/buttons'
import CreateDevice from './CreateDevice'

const AddDeviceButtonComp = AddEntryInTableButton
function AddDeviceButton(): JSX.Element {
  const [openAddDevice, setOpenAddDevice] = React.useState(false)
  return (
    <div>
      <CreateDevice isOpened={openAddDevice} handleClose={() => setOpenAddDevice(false)} />
      <AddDeviceButtonComp buttonText='Add Device' buttonAction={() => setOpenAddDevice(true)} />
    </div>
  )
}

export default withRoleChecking(AddDeviceButton, [
  UserRoleTypes.User,
  UserRoleTypes.CustomerAdmin.split(' ').join(''),
  UserRoleTypes.Admin,
])
