import React from 'react'
import { SvgProps } from 'types/SVGProps'

export default function NewTabIcon({ fill, height, width, className }: SvgProps) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width ?? '14'}
      height={height ?? '14'}
      viewBox='0 0 14 14'
      fill='none'
      className={className ?? ''}
    >
      <path
        d='M10.8263 10.677H3.1536V3.0043H6.98994V1.9082H3.1536C2.54526 1.9082 2.0575 2.40145 2.0575 3.0043V10.677C2.0575 11.2799 2.54526 11.7731 3.1536 11.7731H10.8263C11.4291 11.7731 11.9224 11.2799 11.9224 10.677V6.84065H10.8263V10.677ZM8.08604 1.9082V3.0043H10.0535L4.66621 8.39163L5.43896 9.16438L10.8263 3.77705V5.74455H11.9224V1.9082H8.08604Z'
        fill={fill ?? 'black'}
      />
    </svg>
  )
}
