import React from 'react'
import DeviceImage from 'components/viewComponents/DeviceImage'
import SmallDeviceImage from 'components/viewComponents/SmallDeviceImage'
import { PortableTypes } from 'utils/CommonEnums'
import { IselectOptionProps } from 'forms/FormModelInterface'
import { PortableTypeProductNames } from 'utils/Constants'
import { iDeviceType } from '../data/AddDeviceData'

interface deviceTypeWithImageProps {
  deviceType: PortableTypes
}

export interface deviceTypeListWithImageProps {
  deviceTypes: iDeviceType[]
}

interface FormErrorProps {
  errorMessage: string
}

// w-32 h-10 pl-2.5 pr-1 py-1  rounded-2xl inline-flex

export function DeviceTypeWithImage(props: deviceTypeWithImageProps): JSX.Element {
  const { deviceType } = props
  const deviceTypeText = PortableTypeProductNames[deviceType]
  return (
    <div className='flex justify-start items-center gap-x-3  py-3'>
      <div>
        <SmallDeviceImage deviceType={deviceType} />
      </div>
      <div className=' text-c-dark-blue-1 font-bold text-xs'>{deviceTypeText}</div>
    </div>
  )
}

export function DeviceTypeListWithImage(props: deviceTypeListWithImageProps): IselectOptionProps[] {
  const { deviceTypes } = props

  return deviceTypes.map((deviceType: iDeviceType) => ({
    value: deviceType.id,
    labelText: deviceType.name,
    label: <DeviceTypeWithImage deviceType={deviceType.name as PortableTypes} />,
  }))
}

export function FormError(props: FormErrorProps): JSX.Element {
  const { errorMessage } = props
  return <div className='text-3xs px-3 -mt-2  text-red-800 font-semibold'>{errorMessage}</div>
}
