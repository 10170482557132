import React from 'react'
import { SvgProps } from 'types/SVGProps'

function NavNextIcon(props: SvgProps): JSX.Element {
  const { stroke, fill, className, toggleClick } = props

  return (
    <div className=''>
      <svg
        className={className}
        width='20'
        height='20'
        viewBox='0 0 20 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        onClick={toggleClick}
      >
        <path
          d='M9 5.99997L13 9.99997L9 14'
          //  stroke={stroke ?? '#FFFFFF'}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </div>
  )
}

export default NavNextIcon
