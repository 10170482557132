import { TargetingClientFilterType, TimeWindowClientFilterType } from './FeatureFlags'
/**
 * Checks if a user is included in the targeting client filter.
 *
 * @param clientFilter - The targeting client filter object.
 * @param user - The user identifier (e.g., email).
 * @param isGlobalFlagEnabled - A global flag indicating if the feature is enabled by default.
 * @returns boolean - True if the user is included in the filter, false otherwise.
 */
export function isTargetingClientFilter(
  clientFilter: TargetingClientFilterType,
  user: string,
  isGlobalFlagEnabled: boolean,
): boolean {
  if (clientFilter.name !== 'Microsoft.Targeting' || !clientFilter.parameters?.Audience) {
    return false
  }

  const { Audience } = clientFilter.parameters
  const groupName = user.split('@')[1]

  const isUserExcluded = Audience.Exclusion?.Users?.includes(user)
  const isGroupExcluded = Audience.Exclusion?.Groups?.some((group) => group.startsWith(groupName))
  if (isUserExcluded || isGroupExcluded) {
    return false
  }

  const isUserIncluded = Audience.Users?.includes(user)
  const isGroupIncluded = Audience.Groups?.some((group) => group.Name?.startsWith(groupName))
  if (isUserIncluded || isGroupIncluded) {
    return true
  }

  return isGlobalFlagEnabled
}

export function isTimeWindowClientFilter(clientFilter: TimeWindowClientFilterType): boolean {
  if (
    clientFilter.name === 'Microsoft.TimeWindow' &&
    clientFilter.parameters &&
    clientFilter.parameters.Start &&
    clientFilter.parameters.End &&
    typeof clientFilter.parameters.Start === 'string' &&
    typeof clientFilter.parameters.End === 'string'
  ) {
    const startTime = new Date(clientFilter.parameters.Start).getTime()
    const endTime = new Date(clientFilter.parameters.End).getTime()
    const currentTime = Date.now()

    return currentTime >= startTime && currentTime <= endTime
  }
  return false
}
