import React from 'react'
import { CloseIcon, DropDownIcon } from 'assets/icons'
import { useThemeHook } from 'data/ThemeHook'

function NotFound(): JSX.Element {
  const { theme } = useThemeHook()
  return (
    <div className='grid h-screen px-4 bg-c-light-blue-4 place-content-center'>
      <div className='text-center flex flex-col justify-center items-center'>
        <h1 className='font-black text-gray-200 text-9xl px-56 py-5'>
          <div className='border-c-red-1 border-[6px] rounded-full p-6 mb-5 '>
            <CloseIcon fill={theme.extend.colors['c-red-1']} height={60} width={60} />
          </div>
          <p className='text-c-red-1 font-bold text-5xl'>404</p>
        </h1>

        <p className='text-2xl font-bold tracking-tight text-black sm:text-4xl'>
          <p>Page not found</p>
        </p>
        <div className='px-20 flex justify-center items-center'>
          <p className='mt-4 text-black text-sm font-bold w-80'>
            The page you are looking for either doesn&rsquo;t exist or has been moved.
          </p>
        </div>
        <a
          href='/'
          className='flex flex-row items-center justify-center  w-[184px] py-[10px] mt-6 text-sm font-semibold text-white bg-c-blue rounded-full hover:bg-c-dark-blue-2 focus:outline-none focus:ring'
          // className='rounded-full md:rounded-4xl w-10 md:w-44 h-10 md:h-52 text-c-white  bg-c-dark-blue-1 outline-0 hover:bg-c-dark-blue-2  active:bg-c-dark-blue-3'
        >
          <span> Go to Home Page</span>

          <DropDownIcon className='stroke-white fill-none -rotate-90' />
        </a>
      </div>
    </div>
  )
}

export default NotFound
