import clsx from 'clsx'
import { NotVoid } from 'lodash'
import React, { ReactNode } from 'react'

interface crowconButtonProps {
  additionalClassName?: string
  buttonChildren?: JSX.Element
  disabled?: boolean
  useDefaultTextColour?: boolean
  buttonAction: () => NotVoid
}

function CrowconButton(buttonProps: crowconButtonProps) {
  const {
    additionalClassName,
    buttonChildren,
    disabled = false,
    useDefaultTextColour = true,
    buttonAction,
    ...rest
  } = buttonProps

  return (
    <button
      disabled={disabled}
      type='button'
      aria-hidden
      className={clsx(
        'bg-c-dark-blue-1 outline-0 hover:bg-c-dark-blue-2  active:bg-c-dark-blue-3',
        useDefaultTextColour && 'text-c-white',
        // focus state style is not applying now
        additionalClassName,
        disabled &&
          'cursor-not-allowed bg-c-light-blue-2 text-c-dark-blue-1 disabled:hover:bg-c-light-blue-2',
      )}
      onClick={buttonAction}
      {...rest}
    >
      {buttonChildren}
    </button>
  )
}

export default CrowconButton
