import React from 'react'
import { SvgProps } from 'types/SVGProps'

function ExpandFalseIcon(props: SvgProps): JSX.Element {
  const { stroke, fill, className, toggleClick } = props

  return (
    <div className={className}>
    <svg width="20" height="20"
    onClick={toggleClick} 
    viewBox="0 0 20 20" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg">
    <rect width="20" height="20" rx="10" fill="#DFE8F2"/>
    <path d="M13 8.5L10 11.5L7 8.5" 
    stroke="#0F1B2E" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

    </div>
  )
}


export default ExpandFalseIcon
