import React, { MouseEvent, useRef } from 'react'
import { Bar, getElementAtEvent } from 'react-chartjs-2'
import { iFleetInsightsSummary } from 'pages/dashboard/data/DashboardData'

import {
  populateFaultsDataArray,
  populateStartAndEndDatesOfMonths,
} from 'pages/dashboard/data/DashboardDataUtils'
import { withErrorBoundary } from 'react-error-boundary'
import ErrorPage from 'pages/common/ErrorPage'
import { useNavigate } from 'react-router-dom'

interface iDashboardFaultsChartProps {
  faultsHistoryData: iFleetInsightsSummary['faults']
  customerId?: string
  businessUnitId?: string
}

function DashboardFaultsChart(props: iDashboardFaultsChartProps): React.ReactElement {
  // Implement your component logic here
  const chartRef = useRef()
  const monthStartEndDates = populateStartAndEndDatesOfMonths()
  const navigate = useNavigate()
  const { faultsHistoryData, customerId, businessUnitId } = props
  const urlsearchParams = new URLSearchParams()
  if (customerId) {
    urlsearchParams.set('c', customerId)
  }
  if (businessUnitId) {
    urlsearchParams.set('mainbu', businessUnitId)
  }

  const faultsHistoryDataProcessed = populateFaultsDataArray({
    faultsDataHistory: faultsHistoryData,
  })
  const months = faultsHistoryDataProcessed.map((fault) => fault.month)
  const faultData = faultsHistoryDataProcessed.map((fault) => fault.faults)
  const options = {
    // ...chartOptions,
    responsive: true,
    layout: {
      autoPadding: false,
      padding: {
        top: 0,
        bottom: 0,
      },
    },
    interaction: {
      mode: 'index' as const,
      intersect: true,
    },
    stacked: false,
    plugins: {
      title: {
        display: false,
        text: 'Service',
        font: {
          size: 16,
        },
      },
      legend: {
        display: false,
      },
    },

    scales: {
      x: {
        stacked: false,
        ticks: {
          color: '#1B4373',
          padding: 0,
          backDropPadding: 0,
        },
        grid: {
          drawOnChartArea: false,
        },
      },

      y: {
        type: 'linear' as const,
        display: true,
        position: 'left' as const,
        stacked: false,
        ticks: {
          color: '#1B4373',
          padding: 0,
          backdropPadding: 0,
          stepSize: 5,
        },
        grid: {
          drawOnChartArea: true,
        },
        suggestedMax: Math.max(...faultData) + 5,
        suggestedMin: 0,
      },
    },
  }
  const chartData = {
    labels: months,
    datasets: [
      {
        label: 'Service',
        data: faultData,
        // backgroundColor: 'rgba(255, 99, 132, 0.5)',
        // borderColor: 'rgb(255, 99, 132)',
        backgroundColor: '#C9CEFF',
        hoverBackgroundColor: '#999EEF',
        // borderColor: 'rgb(255, 99, 132)',
        borderRadius: 12,
        borderWidth: 0,
        hoverBorderWidth: 0,
        // hoverBorderWidth: 2,
        // barThickness: 10,
        barPercentage: 0.49,
        // fill: false,
        yAxisID: 'y',
        // grouped: false,
        // stack: 'bar'
      },
    ],
  }

  const onChartClick = (event: MouseEvent<HTMLCanvasElement>) => {
    // const chartDataSet = chartRef.current && getDatasetAtEvent(chartRef.current, event)
    // if (chartDataSet && chartDataSet[0]) {
    //   const { datasetIndex: datasetIndexc } = chartDataSet[0]
    // }

    const element = chartRef.current && getElementAtEvent(chartRef.current, event)
    if (element && element[0]) {
      const { index } = element[0]
      // index will show the month alarmDate=Custom&alarmDateFrom=2023-12-01&alarmDateTo=2023-12-31

      urlsearchParams.set('fsel', 'custom')
      urlsearchParams.set('from', monthStartEndDates[index].startDate)
      urlsearchParams.set('to', monthStartEndDates[index].endDate)
      urlsearchParams.set('fdatefrom', monthStartEndDates[index].startDate)
      urlsearchParams.set('fdateto', monthStartEndDates[index].endDate)
      const url = `/web/service?${urlsearchParams.toString()}`
      // const url = `/web/service?fsel=custom&from=${monthStartEndDates[index].startDate}&to=${monthStartEndDates[index].endDate}&fdatefrom=${monthStartEndDates[index].startDate}&fdateto=${monthStartEndDates[index].endDate}`
      // window.open(url, '_blank')
      navigate(url)
      // navigate(`/web/safety?alarmType=alarm${datasetIndex + 1}&alarmDate=Custom&alarmDateFrom=${monthStartEndDates[index].startDate}&alarmDateTo=${monthStartEndDates[index].endDate}`, {
      //   replace: false,
      //   preventScrollReset: true,

      // })
    }

    // const elements = chartRef.current && getElementsAtEvent(chartRef.current, event)
    // if (!elements) return
    // const { length } = elements
  }
  return (
    // JSX code for your component's UI
    <div className='flex-grow flex flex-col px-4 py-4  bg-c-white shadow-card rounded-2xl items-center'>
      <div className='mb-2 flex flex-col gap-y-1 self-start'>
        <div className='text-base font-bold text-c-dark-blue-2'>Service</div>
      </div>
      <div className='mt-2 flex flex-grow flex-shrink-0 '>
        {faultData.length === 0 ? (
          <div className='flex justify-center items-center h-full w-full text-sm text-red-500'>
            No Service Data
          </div>
        ) : (
          <Bar ref={chartRef} onClick={onChartClick} data={chartData} options={options} />
        )}
      </div>
    </div>
  )
}
DashboardFaultsChart.defaultProps = {
  customerId: undefined,
  businessUnitId: undefined,
}

export default withErrorBoundary(DashboardFaultsChart, {
  FallbackComponent: ErrorPage,
})
