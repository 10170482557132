/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, ReactNode } from 'react'

/**
 * Show is a React component that conditionally renders its children based on their `isTrue` prop.
 *
 * @component
 * @example
 * <Show>
 *   <Show.When isTrue={condition}>
 *     <div>This is shown when the condition is true</div>
 *   </Show.When>
 *   <Show.Otherwise>
 *     <div>This is shown when the condition is false</div>
 *   </Show.Otherwise>
 * </Show>
 *
 * @param {object} props - The properties that define the Show component.
 * @param {ReactNode} props.children - The children to be rendered.
 *
 * @returns {ReactElement | null} The first child with `isTrue` prop set to true, or the first child without `isTrue` prop if no such child exists.
 */

interface ShowProps {
  children: ReactNode
}

const Show = (props: ShowProps): ReactElement | null => {
  let when: ReactElement | null = null
  let otherwise: ReactElement | null = null

  React.Children.forEach(props.children, (child: any) => {
    if (child.props?.isTrue === undefined) {
      otherwise = child
    } else if (!when && child.props.isTrue) {
      when = child
    }
  })
  return when || otherwise
}

/**
 * When is a React component that renders its children if its `isTrue` prop is true.
 *
 * @component
 *
 * @param {object} props - The properties that define the When component.
 * @param {boolean} props.isTrue - The condition based on which the children are rendered.
 * @param {ReactNode} props.children - The children to be rendered.
 *
 * @returns {ReactElement | null} The children if `isTrue` is true, or null otherwise.
 */

interface WhenProps {
  isTrue: boolean
  children: ReactNode
}

function When({ isTrue, children }: WhenProps): ReactElement | null {
  return isTrue ? (
    <>
      {/* Single child */}
      {children}
    </>
  ) : null
}

/**
 * Otherwise is a React component that renders its `render` prop or its children.
 *
 * @component
 *
 * @param {object} props - The properties that define the Otherwise component.
 * @param {ReactNode} props.render - The content to be rendered.
 * @param {ReactNode} props.children - The children to be rendered if `render` is not provided.
 *
 * @returns {ReactElement | null} The `render` prop wrapped in a div, or the children wrapped in a div if `render` is not provided.
 */

interface OtherwiseProps {
  render?: ReactNode
  children: ReactNode
}

function Otherwise({ render, children }: OtherwiseProps): ReactElement | null {
  return render ? (
    <>
      {/* Single child */}
      {render}
    </>
  ) : (
    <>
      {/* Single child */}
      {children}
    </>
  )
}
Otherwise.defaultProps = {
  render: undefined,
}

Show.When = When
Show.Otherwise = Otherwise

export default Show
