import { Transition } from '@headlessui/react'
import { CloseIcon, SimpleDot } from 'assets/icons'
import { UserAccountContext } from 'contexts/UserAccountContext'
import React, { useContext, useState } from 'react'
import { UserAccountContextActions } from 'utils/Constants'
import { useQueryClient } from '@tanstack/react-query'
import withFeatureFlag from 'hoc/withFeatureFlag'
import { FeatureFlags } from 'utils/FeatureFlags'
import DemoModeExit from '../modals/DemoModeExit'

function DemoBanner() {
  const { state, dispatch } = useContext(UserAccountContext)
  const [exitDemoMode, setExitDemoMode] = useState(false)
  const queryClient = useQueryClient()

  const exitHandler = () => {
    queryClient.clear()
    dispatch({ type: UserAccountContextActions.DemoModeStatus, payload: { isDemoMode: false } })
  }

  return (
    <>
      <Transition
        show={state.isDemoMode}
        appear
        enter='transition-all duration-150'
        enterFrom='transform translate-y-[-100%] opacity-0'
        enterTo='transform translate-y-0 opacity-100'
        leave='transition-all duration-150 delay-200'
        leaveFrom='transform translate-y-0 opacity-100'
        leaveTo='transform translate-y-[-100%] opacity-0'
      >
        <div className='font-poppins flex items-center justify-between gap-x-6 bg-gray-900 px-6 py-2.5 sm:pr-3.5 lg:pl-8'>
          <p className='text-sm leading-6 text-white'>
            <span>
              <strong className='font-semibold'>Crowcon Connect</strong>
              <SimpleDot className='mx-2 inline h-0.5 w-0.5 fill-current' />
              Take a Sneak Peek
              <span aria-hidden='true' className='px-2'>
                &rarr;
              </span>
              <strong className='font-semibold'>&rsquo; Demo Mode Activated &rsquo; &nbsp;</strong>
            </span>
          </p>
          <button
            type='button'
            className='-m-3 flex-none p-3 focus-visible:outline-offset-[-4px]'
            onClick={() => setExitDemoMode(true)}
          >
            <span className='sr-only'>Dismiss</span>
            <CloseIcon aria-hidden='true' className='h-5 w-5 text-white' />
          </button>
        </div>
      </Transition>
      <DemoModeExit
        open={exitDemoMode}
        setOpen={(e: boolean) => setExitDemoMode(e)}
        exitHandler={exitHandler}
      />
    </>
  )
}
export default withFeatureFlag(DemoBanner, FeatureFlags.DemoMode)
