import { FeatureFlagValue } from '@azure/app-configuration'
import { useAppConfig } from 'contexts/AppConfigurationContext'
import { UserAccountContext } from 'contexts/UserAccountContext'
import { useContext, useCallback } from 'react'
import { isTargetingClientFilter, isTimeWindowClientFilter } from 'utils/FeatureFlagsUtils'
import {
  FeatureFlagsEnums,
  TargetingClientFilterType,
  TimeWindowClientFilterType,
} from 'utils/FeatureFlags'

export function useFeatureFlag(featureFlag: FeatureFlagsEnums) {
  const appConfigInstance = useAppConfig()
  const { state: userInfo } = useContext(UserAccountContext)

  const isFeatureFlagEnabled = useCallback(
    (flag: FeatureFlagValue) => {
      if (flag.conditions.clientFilters?.length) {
        return flag.conditions.clientFilters.some(
          (clientFilter) =>
            isTargetingClientFilter(
              clientFilter as TargetingClientFilterType,
              userInfo.user.emailId,
              flag.enabled,
            ) || isTimeWindowClientFilter(clientFilter as TimeWindowClientFilterType),
        )
      }
      return flag.enabled
    },
    [userInfo.user.emailId],
  )

  if (appConfigInstance) {
    const flag = appConfigInstance.features?.[featureFlag]
    if (flag) {
      return isFeatureFlagEnabled(flag)
    }
  }
  return false
}
