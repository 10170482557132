import { SelectControl } from 'components/formComponents'
import { UserAccountContext } from 'contexts/UserAccountContext'
import { IselectOptionProps } from 'forms/FormModelInterface'
import withRoleChecking from 'hoc/withRoleChecking'
import React, { useContext } from 'react'
import { UserRoleTypes } from 'utils/CommonEnums'
import { DemoModeOption } from '../../utils/constants'

function DemoDropDownSelector({ onChange }: { onChange: (data: IselectOptionProps) => void }) {
  const { state } = useContext(UserAccountContext)
  return (
    <div className='font-poppins'>
      <label htmlFor='demo' className='pl-[14px] text-3xs font-bold  text-c-dark-blue-1 '>
        Demo mode
      </label>

      <SelectControl
        className='w-full h-[44px]'
        selectControlProps={{
          options: DemoModeOption,
          isMulti: false,
          defaultValue: {
            label: state.isDemoMode ? 'Yes' : 'No',
            value: state?.isDemoMode,
          },
          isSearchable: true,
          isDropDownSelectable: true,
          openMenuOnClick: true,
          placeholder: 'Select',
          isClearable: false,
          onChange,
        }}
      />
    </div>
  )
}

export default withRoleChecking(DemoDropDownSelector, [
  UserRoleTypes.SuperUser,
  UserRoleTypes.Admin,
])
