import clsx from 'clsx'
import Each from 'components/atom/Each'
import React from 'react'

export interface TabType {
  label: string
  value: string
}
interface TabsProps {
  tabs: TabType[]
  selectedTab: TabType
  onChange: (tab: TabType) => void
}

export default function Tabs({ tabs, selectedTab, onChange }: TabsProps) {
  return (
    <div className='hideScrollBar bg-c-light-blue-4 px-5 w-full overflow-x-auto overflow-y-hidden'>
      <nav className='flex ' aria-label='Tabs'>
        <Each
          mapper={tabs}
          render={(tab, index) => (
            <>
              <button
                type='button'
                onClick={() => onChange(tab)}
                key={`${tab.label}`}
                className={clsx(
                  {
                    ' text-c-blue font-bold text-md border-c-blue bg-c-light-blue-3 border-t-3  tab-bar':
                      tab.value === selectedTab?.value,
                    'text-c-dark-blue-4  font-medium bg-c-light-blue-4 hover:bg-c-light-blue-4 hover:text-c-blue':
                      tab.value !== selectedTab?.value,
                  },
                  'px-4 py-2 text-sm min-w-fit   transition-colors duration-200 ease-in-out ',
                )}
                aria-current={tab.value === selectedTab?.value ? 'page' : undefined}
              >
                {tab.label}
              </button>
              <div
                className={clsx(
                  {
                    'opacity-0 ':
                      index === tabs.findIndex((option) => option.value === selectedTab?.value) ||
                      index === tabs.length - 1 ||
                      index === tabs.findIndex((option) => option.value === selectedTab?.value) - 1,
                  },
                  'w-[3px] my-1  bg-c-light-blue-2 rounded-full ',
                )}
              />
            </>
          )}
        />
      </nav>
    </div>
  )
}
