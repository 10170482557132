import React, { createContext, useContext, useState, useEffect, useMemo, useCallback } from 'react'
import {
  AppConfigurationClient,
  FeatureFlagValue,
  isFeatureFlag,
  parseFeatureFlag,
} from '@azure/app-configuration'
import { AppConfigContextType } from 'utils/FeatureFlags'

const AppConfigContext = createContext<AppConfigContextType>({
  appConfigClient: null,
  features: undefined,
})

interface AppConfigProviderProps {
  children: JSX.Element
}

const AppConfigurationKey = process.env.REACT_APP_APP_CONFIG_KEY

export function AppConfigProvider({ children }: AppConfigProviderProps) {
  const [appConfigClient, setAppConfigClient] = useState<AppConfigurationClient | null>(null)
  const [features, setFeatures] = useState<{
    [key: string]: FeatureFlagValue
  }>()

  useEffect(() => {
    const fetchAppConfigClient = async () => {
      const client = new AppConfigurationClient(AppConfigurationKey as string)
      setAppConfigClient(client)
    }

    fetchAppConfigClient()
  }, [])

  const getConfig = useCallback(async (): Promise<boolean | null> => {
    if (!appConfigClient) return null

    try {
      const flags = appConfigClient.listConfigurationSettings()
      // eslint-disable-next-line no-restricted-syntax
      for await (const flag of flags) {
        if (isFeatureFlag(flag)) {
          const parsedFeatureFlag = parseFeatureFlag(flag)
          const featureFromConfig = {
            [parsedFeatureFlag.value.id as string]: parsedFeatureFlag.value,
          }
          setFeatures((pre) =>
            pre
              ? {
                  ...pre,
                  ...featureFromConfig,
                }
              : featureFromConfig,
          )
        }
      }

      return null
    } catch (error) {
      console.error('Error fetching configuration:', error)
      return null
    }
  }, [appConfigClient])

  useEffect(() => {
    getConfig()
  }, [getConfig])

  const contextValue = useMemo(() => ({ appConfigClient, features }), [appConfigClient, features])

  return <AppConfigContext.Provider value={contextValue}>{children}</AppConfigContext.Provider>
}

export const useAppConfig = (): AppConfigContextType => useContext(AppConfigContext)
