/**
 * SignUpRedirect is a React component that handles the redirection of a user to the signup page.
 *
 */

import { RedirectRequest } from '@azure/msal-browser'
import { useMsal } from '@azure/msal-react'
import Timer from 'components/atom/Timer'
import CrowconButton from 'components/miscComponents/CrowconButton'
import React, { useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { loginRequest, SignUpAuthority, siteBaseURL, apiScopes } from 'utils/AuthConfig'
import { RedirectProps } from './SignUpPage'

export default function SignUpRedirect({ userInviteData }: RedirectProps) {
  // useMsal is a hook that provides the Microsoft Authentication Library (MSAL) instance and the user accounts.
  const { instance } = useMsal()

  // useParams is a hook from react-router-dom that returns an object of key/value pairs of URL parameters.
  // Here it's used to get the inviteId from the URL.
  const { inviteId } = useParams<{ inviteId: string }>()

  // redirectToSignup is a function that redirects the user to the signup page.
  // It creates a signup request with the necessary parameters and calls acquireTokenRedirect from the MSAL instance.
  const redirectToSignup = useCallback(() => {
    if (inviteId) {
      const signupRequest: RedirectRequest = {
        ...loginRequest,
        authority: SignUpAuthority,
        loginHint: userInviteData?.emailId,
        redirectUri: `${siteBaseURL}/web/signup/done`,
        redirectStartPage: `${siteBaseURL}/web/signup/done`,
        scopes: apiScopes,

        extraQueryParameters: {
          invId: inviteId,
          fName: userInviteData?.firstName || '',
          lName: userInviteData?.lastName || '',
          emailId: userInviteData?.emailId || '',
        },
      }
      instance.acquireTokenRedirect(signupRequest)
    }
  }, [inviteId, userInviteData])

  // callback function for the Timer component to redirect to signup page
  const redirectCallback = useCallback(() => {
    // redirect to login
    redirectToSignup()
  }, [redirectToSignup])

  // The component renders a welcome message and instructions for the user.
  // It also displays the remaining redirect delay and a button that the user can click to get started immediately.
  return (
    <div className='mx-auto text-center text-c-dark-blue-1 w-screen md:w-max'>
      {/* Welcome message */}
      <div className='text-base font-bold font-poppins text-center md:text-left tracking-tight text-c-dark-blue-1  leading-[20px]'>
        Welcome {userInviteData?.firstName} {userInviteData?.lastName}
      </div>
      {/* Instructions */}
      <p className='mx-auto mt-4 max-w-xl text-center md:text-left text-sm leading-5 text-c-dark-blue-1 '>
        Let&lsquo;s help you get started with your Crowcon Connect account.
      </p>
      {/* More instructions */}
      <p className='mx-auto mt-2.5 max-w-xl text-center md:text-left text-sm leading-6 text-c-dark-blue-1 '>
        You will be redirected to the sign up page to setup your account using your email address{' '}
        <br className='md:hidden' />
        <span className='text-c-dark-blue-4 font-semibold'>{userInviteData?.emailId}</span>.
      </p>
      {/* Contact information */}
      <p className='mx-auto mt-1 text-center md:text-left max-w-xl text-xs leading-4 text-c-light-gray-1 '>
        If this is not your email address, please contact your administrator or{' '}
        <a
          href='mailto:techsupport@crowcon.com'
          className=' leading-6 text-c-dark-blue-4 hover:text-indigo-500'
        >
          Crowcon support
        </a>
      </p>
      {/* Redirect delay */}
      <p className='mx-auto mt-6 max-w-xl text-base leading-6 text-c-dark-blue-1 font-bold text-center'>
        Redirecting to sign up page in <Timer countdown={5} callback={redirectCallback} /> seconds
      </p>
      {/* Get started button */}
      <p className='mx-auto mt-1 max-w-xl text-xs leading-4 text-c-light-gray-1 text-center'>
        Or click the button below to get started
      </p>
      <div className='mt-6 items-center  justify-center gap-x-10'>
        <CrowconButton
          additionalClassName='text-base mb-4 bg-c-dark-blue-1 w-316 h-12 rounded-3xl'
          buttonChildren={<> Get started</>}
          buttonAction={redirectToSignup}
        />
        {/* Login link */}
        <div className='text-center text-xs font-semibold text-gray-500'>
          Already have an account?{' '}
          <a href='/' className='font-semibold leading-6 text-indigo-600 hover:text-indigo-500'>
            Login
          </a>
        </div>
      </div>
    </div>
  )
}
