import React, { useEffect, useState } from 'react'

import { iDevice } from 'data/DeviceListHook'
import GenericWidget from 'components/viewComponents/GenericWidget'
import { iWidgetData } from 'components/viewComponents/interfaces'
import { groupDeviceDataByCalDueDays } from 'pages/calibration/data/CalibrationDataUtils'
import { DeviceCalDueTypeTexts } from 'utils/Constants'
import { DeviceCalDueTypes } from 'utils/CommonEnums'
import { IFilterValues } from 'forms/FormModelInterface'
import { calDueDateRangeOptions } from 'forms/FormUtils'
import { withErrorBoundary } from 'react-error-boundary'
import ErrorPage from 'pages/common/ErrorPage'
import { CalibrationPageColumnIds } from '../CalibrationsPageUtils'

function CalibrationCountByDaysWidget({
  deviceListData,
  onFilter,
  calDueDatesFilteredParam,
}: {
  deviceListData: iDevice[]
  onFilter: (filterRequestValues: IFilterValues[]) => void
  calDueDatesFilteredParam: DeviceCalDueTypes
}): JSX.Element {
  const [widgetData, setWidgetData] = useState<iWidgetData[]>()

  const onFilterData = (filterRequestValues: IFilterValues[]) => {
    const filterRequestValueToSend: IFilterValues[] = []
    filterRequestValues.forEach((item) => {
      const calDueOption = calDueDateRangeOptions.find((x) => x.label === item.value)
      filterRequestValueToSend.push({
        columnId: item.columnId,
        value:
          item.value === '' ? '' : { label: String(item.value), value: calDueOption?.value ?? '' },
      })
    })

    if (onFilter) {
      onFilter(filterRequestValueToSend)
    }
  }

  useEffect(() => {
    if (deviceListData) {
      const deviceCalDueDaysCount = groupDeviceDataByCalDueDays(deviceListData)

      const createWidgetData = (dueType: DeviceCalDueTypes) => ({
        originalData: dueType,
        displayText: DeviceCalDueTypeTexts.find((x) => x.label === dueType)?.value || dueType,
        value: deviceCalDueDaysCount.find((x) => x.calDueParam === dueType)?.count || 0,
        gridItemClassName: 'col-span-1',
        isFiltered: calDueDatesFilteredParam === dueType,
        columnId: CalibrationPageColumnIds.calDue,
      })

      const wDataToDisplay: iWidgetData[] = [
        createWidgetData(DeviceCalDueTypes.DueIn7Days),
        createWidgetData(DeviceCalDueTypes.DueIn30Days),
        createWidgetData(DeviceCalDueTypes.DueIn60Days),
        createWidgetData(DeviceCalDueTypes.DueIn90Days),
      ]

      setWidgetData(wDataToDisplay)
    }
  }, [deviceListData, calDueDatesFilteredParam])

  return (
    <GenericWidget
      widgetProps={{
        title: 'Due',
        widgetData,
        widgetLabelCount: 4,
        columnId: CalibrationPageColumnIds.calDue,
        gridContainerClassName: 'grid-cols-1 gap-1',

        onFilter: (filterRequestValues: IFilterValues[]) => onFilterData(filterRequestValues),
      }}
    />
  )
}

export default withErrorBoundary(CalibrationCountByDaysWidget, {
  FallbackComponent: ErrorPage,
})
