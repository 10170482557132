import React from 'react'
import { SvgProps } from 'types/SVGProps'

function SearchIcon(props: SvgProps): JSX.Element {
  const { stroke, fill, className, toggleClick } = props

  return (
    <div className=''>
      <svg
        className={className}
        width='20'
        height='20'
        viewBox='0 0 20 20'
        onClick={toggleClick}
        xmlns='http://www.w3.org/2000/svg'
      >
        <path d='M14 14L17 17' strokeWidth='1.5' strokeLinecap='round' />
        <circle cx='8.5' cy='8.5' r='5.5' strokeWidth='2' />
      </svg>
    </div>
  )
}

export default SearchIcon
