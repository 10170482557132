import React, { useContext } from 'react'
import clsx from 'clsx'
import DeviceEventLogFilterForm from 'pages/deviceDetails/childPages/eventLogs/view/DeviceEventLogFilterForm'
import DeviceAssignmentHistoryFilterForm from 'pages/deviceDetails/childPages/assignmentHistory/view/DeviceAssignmentHistoryFilterForm'
import DeviceFaultsFilterForm from 'pages/deviceDetails/childPages/faults/view/DeviceFaultsFilterForm'
import DeviceSyncHistoryFilterForm from 'pages/deviceDetails/childPages/syncHistory/view/DeviceSyncHistoryFilterForm'
import BumpHistoryFilterForm from 'pages/deviceDetails/childPages/bumpHistory/view/BumpHistoryFilterForm'
import DeviceNearMissFilterForm from 'pages/deviceDetails/childPages/nearMiss/view/DeviceNearMissFilterForm'
import DeviceGasLogsFilterForm from 'pages/deviceDetails/childPages/gasLogs/view/DeviceGasLogsFilterForm'
import { DeviceDetailPageTabs } from 'utils/CommonEnums'
import { DeviceDetailContextActions, DeviceDetailsContext } from './DeviceDetailsContext'

interface DeviceDetailsFilterFormProps {
  // Add your props here
  data?: any
}

function DeviceDetailsFilterForm(props: DeviceDetailsFilterFormProps): React.ReactElement {
  // Add your component logic here
  const { data } = props
  const deviceDetailsContext = useContext(DeviceDetailsContext)
  const { state, dispatch } = deviceDetailsContext

  return (
    // Add your JSX/HTML code here
    // className={clsx('z-50', showFilterForm ? 'block -ml-80 ' : 'hidden ')}
    <div
      className={clsx(
        'filter-form hideScrollBar',
        state.filterFormShown ? 'z-50 -ml-80 opacity-100' : 'opacity-0 -z-50',
      )}
    >
      {/* Add your component content here */}
      {state.page === DeviceDetailPageTabs.EventLog ? (
        <DeviceEventLogFilterForm
          onFilterSubmit={state.filterFormData.onFilterSubmit}
          onFilterReset={state.filterFormData.onFilterReset}
          onFilterFormClose={() => dispatch({ type: DeviceDetailContextActions.HideFilterForm })}
          selectedValues={state.filterFormData.selectedValues}
          deviceUsersData={state.filterFormData.deviceUsersData}
          eventDescriptionsData={state.filterFormData.eventDescriptionsData}
        />
      ) : state.page === DeviceDetailPageTabs.AssignmentHistory ? (
        <DeviceAssignmentHistoryFilterForm
          onFilterSubmit={state.filterFormData.onFilterSubmit}
          onFilterReset={state.filterFormData.onFilterReset}
          onFilterFormClose={() => dispatch({ type: DeviceDetailContextActions.HideFilterForm })}
          selectedValues={state.filterFormData.selectedValues}
          deviceUserNames={state.filterFormData.deviceUserNames}
          deviceUserUniqueIds={state.filterFormData.deviceUserUniqueIds}
        />
      ) : state.page === DeviceDetailPageTabs.FaultsLog ? (
        <DeviceFaultsFilterForm
          onFilterSubmit={state.filterFormData.onFilterSubmit}
          onFilterReset={state.filterFormData.onFilterReset}
          onFilterFormClose={() => dispatch({ type: DeviceDetailContextActions.HideFilterForm })}
          selectedValues={state.filterFormData.selectedValues}
          deviceUserNames={state.filterFormData.deviceUsersData}
          faultTypes={state.filterFormData.faultTypesData}
          faultDescriptions={state.filterFormData.faultDescriptionsData}
        />
      ) : state.page === DeviceDetailPageTabs.SyncHistory ? (
        <DeviceSyncHistoryFilterForm
          onFilterSubmit={state.filterFormData.onFilterSubmit}
          onFilterReset={state.filterFormData.onFilterReset}
          onFilterFormClose={() => dispatch({ type: DeviceDetailContextActions.HideFilterForm })}
          selectedValues={state.filterFormData.selectedValues}
          uploadDataTypes={state.filterFormData.uploadDataTypes}
          uploadClientTypes={state.filterFormData.uploadClientTypes}
        />
      ) : state.page === DeviceDetailPageTabs.BumpHistory ? (
        <BumpHistoryFilterForm
          onFilterSubmit={state.filterFormData.onFilterSubmit}
          onFilterReset={state.filterFormData.onFilterReset}
          onFilterFormClose={() => dispatch({ type: DeviceDetailContextActions.HideFilterForm })}
          selectedValues={state.filterFormData.selectedValues}
          deviceUserNames={state.filterFormData.deviceUserNames}
          eventDescriptions={state.filterFormData.eventDescriptions}
          bumpTestResults={state.filterFormData.bumpTestResults}
        />
      ) : state.page === DeviceDetailPageTabs.NearMiss ? (
        <DeviceNearMissFilterForm
          onFilterSubmit={state.filterFormData.onFilterSubmit}
          onFilterReset={state.filterFormData.onFilterReset}
          onFilterFormClose={() => dispatch({ type: DeviceDetailContextActions.HideFilterForm })}
          selectedValues={state.filterFormData.selectedValues}
          deviceUserNames={state.filterFormData.deviceUserNames}
          gasTypes={state.filterFormData.gasTypes}
          alarmLevels={state.filterFormData.alarmLevels}
          percentValues={state.filterFormData.percentValues}
        />
      ) : state.page === DeviceDetailPageTabs.GasLog ? (
        <DeviceGasLogsFilterForm
          onFilterSubmit={state.filterFormData.onFilterSubmit}
          onFilterReset={state.filterFormData.onFilterReset}
          onFilterFormClose={() => dispatch({ type: DeviceDetailContextActions.HideFilterForm })}
          selectedValues={state.filterFormData.selectedValues}
          channelNumbers={state.filterFormData.channelNumbers}
          gasNames={state.filterFormData.gasNames}
          gasValues={state.filterFormData.gasValues}
          gasUnits={state.filterFormData.gasUnits}
        />
      ) : (
        <> </>
      )}
    </div>
  )
}

export default DeviceDetailsFilterForm
