export const services = {
  portables: 'portables/api',
  deviceLogs: 'devicelogs/api',
  users: 'users/api',
  customer: 'customers/api',
}

const azureFunctionUrl = process.env.REACT_APP_AZURE_FUNC_URL

export const azureFunctionEndpoints = {
  createDevices: `${azureFunctionUrl}/api/create-devices`,
  createEventLogs: `${azureFunctionUrl}/api/create-event-logs`,
  createGasLogs: `${azureFunctionUrl}/api/create-gas-logs`,
  createOperators: `${azureFunctionUrl}/api/create-operators`,
  deviceAssignments: `${azureFunctionUrl}/api/device-assignment`,
  setChannelConfig: `${azureFunctionUrl}/api/set-channel-config`,
  getDemoCustomer: `${azureFunctionUrl}/api/get-demo-customer`,
  getDemoBus: `${azureFunctionUrl}/api/get-demo-bu-units`,
  createDemoCustomer: `${azureFunctionUrl}/api/create-demo-customer`,
  createDemoBusinessUnits: `${azureFunctionUrl}/api/create-demo-business-units`,
}

export const api = {
  device: 'device',
  eventLogs: 'EventLogs',
  gasLogs: 'gaslogs',
  operator: 'Operator',
  customer: 'customer',
  uploadHistory: 'uploadhistory',
  subscription: 'Subscription',
  user: 'user',
  userRoles: 'userroles',
  feature: 'feature',
  role: 'role',
  userInfo: 'userinfo',
  deviceConfig: 'deviceconfig',
  helpAndSupport: 'HelpAndSupport',
  locale: 'locale',
}

export const endpoints = {
  device: {
    type: `${services.portables}/${api.device}type`,
    log: `${services.deviceLogs}/${api.device}/log/safety`,
    common: `${services.portables}/${api.device}`,
    bySerialNumber: `${services.portables}/${api.device}/serialNumber`,
    delete: `${services.portables}/${api.device}/update/delete`,
    stats: `${services.deviceLogs}/${api.device}/stats/dates/latest`,
    config: `${services.portables}/${api.deviceConfig}/deviceid`,
  },
  fleet: {
    insights: `${services.deviceLogs}/${api.eventLogs}/fleetinsights`,
    summary: `${services.portables}/${api.device}/fleetsummary`,
  },
  operator: {
    list: `${services.users}/${api.operator.toLowerCase()}`,
    common: `${services.users}/${api.operator}`,
    delete: `${services.users}/${api.operator}/update/delete`,
    assignment: `${services.portables}/Device/Assignment/deviceUser`,
  },
  businessUnit: {
    list: {
      byCustomer: `${services.customer}/${api.customer}/bu`,
      byUser: `${services.customer}/${api.customer}/bu/details`,
      orgTree: `${services.customer}/${api.customer}/bu/label`,
    },
  },
  customer: {
    list: `${services.customer}/${api.customer}`,
    demo: `${services.customer}/${api.customer}/demo`,
    me: `${services.customer}/${api.customer}/me`,
    helpAndSupport: `${services.customer}/${api.helpAndSupport}`,
  },
  locale: `${services.users}/${api.locale}`,
  userInfo: `${services.users}/${api.userInfo}`,
  billingPlan: `${services.customer}/${api.subscription}/plan`,
  feature: `${services.customer}/${api.feature}`,
  logs: {
    check: `${services.deviceLogs}/${api.eventLogs}/check`,
    eventLogs: `${services.deviceLogs}/${api.eventLogs}`,
    gasLogs: `${services.deviceLogs}/${api.gasLogs}`,
    assignment: `${services.portables}/${api.device}/assignment/device`,
    faults: `${services.deviceLogs}/${api.eventLogs}/faults`,
    sync: `${services.deviceLogs}/${api.uploadHistory}`,
    nearMiss: `${services.deviceLogs}/${api.gasLogs}/nearmiss`,
    bumpHistory: `${services.deviceLogs}/${api.eventLogs}/bump/history`,
  },
  user: {
    common: `${services.users}/${api.user}`,
    userRoles: `${services.users}/${api.userRoles}`,
    inviteStatus: `${services.users}/${api.user}/invite/status`,
    validity: `${services.users}/${api.user}/invite/validity`,
  },
  role: `${services.users}/${api.role}`,
  preference: `users/user/preference`,
  demoMode: {
    createDemoCustomer: `${services.customer}/${api.customer}/demo`,
  },
}
