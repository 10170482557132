import DeviceDetailsReportSelector from 'pages/deviceDetails/rootPage/view/DeviceDetailsReportSelector'
import { ListenerParameters } from 'pubnub'
import { usePubNub } from 'pubnub-react'
import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { PDFIcon } from 'assets/icons'
import { useDeviceDetails } from 'pages/deviceDetails/rootPage/view/DeviceDetailsRoot'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useGenericEventHandler } from 'data/GenericEventHandler'
import { useMsal } from '@azure/msal-react'
import { format, add, startOfMonth } from 'date-fns'
import { DeviceDetailsQueryKeyBySerial } from 'services/apis/caching/device'
import ErrorPage from 'pages/common/ErrorPage'
import withFeatureFlag from 'hoc/withFeatureFlag'
import { FeatureFlags } from 'utils/FeatureFlags'
import { updateCalRecord } from './data'
import CalibrationReportPdf from './components/CalibrationReportPdf'
import CalibrationStatusReport from './components/CalibrationStatusReport'
import { Stages } from './constants'

export const CALIBRATION_REPORT_CHANNEL = 'OneConnect/Calibration'
export const channels = [CALIBRATION_REPORT_CHANNEL]
export interface CalibrationDataType {
  Stage: string
  CalibrationDate: string
  OperatorName: string
}

function LiveCalibrationReport() {
  const params = useParams()
  const location = useLocation()
  const msalContext = useMsal()
  const { deviceSerialNumber } = params as { deviceSerialNumber: string }
  document.title = `Calibration Report - ${deviceSerialNumber}`
  const pubnub = usePubNub()
  const { deviceDetails } = useDeviceDetails()
  const [calibrationData, setCalibrationData] = useState<CalibrationDataType>()
  const [isError, setIsError] = useState<boolean>()
  const queryClient = useQueryClient()
  const { genericEventHandler } = useGenericEventHandler()
  const [isCompleted, setIsCompleted] = useState<boolean>(false)

  useEffect(() => {
    let interval: NodeJS.Timeout | undefined
    if (calibrationData?.Stage === Stages.Complete) {
      interval = setTimeout(() => {
        setIsCompleted(true)
      }, 3000)
    } else {
      setIsCompleted(false)
      if (interval) {
        clearTimeout(interval)
      }
    }
    return () => {
      if (interval) {
        clearTimeout(interval)
      }
    }
  }, [calibrationData?.Stage])

  useEffect(() => {
    const listenerParams: ListenerParameters = {
      message: ({ message }) => {
        setIsError(false)
        setCalibrationData(JSON.parse(message))
      },
      status: (statusEvent) => {
        const status = statusEvent as unknown as {
          error: boolean
          errorData: {
            error: boolean
            message: string
            service: string
            status: number
          }
        }
        if (status.error) {
          setIsError(true)
        }
      },
    }
    pubnub.addListener(listenerParams)
    pubnub.subscribe({ channels, withPresence: true })
    return () => {
      pubnub.unsubscribe({ channels })
      pubnub.removeListener(listenerParams)
    }
  }, [pubnub])

  const updateCalRecordMutation = useMutation(updateCalRecord, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: DeviceDetailsQueryKeyBySerial(deviceSerialNumber),
      })
    },
    onError: (error: AxiosError) => {
      console.log(error)
      genericEventHandler({
        error,
        severity: 'error',
        onlyTrack: false,
        message:
          (
            (error as unknown as AxiosError).response?.data as {
              detail: string
            }
          )?.detail ||
          error.message ||
          'Error while updating user',
        extraData: {
          component: 'LiveCalibrationReport.tsx',
          action: 'cal record updating failed',
        },
      })
    },
  })

  useEffect(() => {
    if (calibrationData?.Stage === 'Complete') {
      updateCalRecordMutation.mutate({
        redirectPageURL: location.pathname,
        msalContext,
        payload: {
          DeviceId: deviceDetails?.id as string,
          LastCalibrated: new Date().toISOString().split('T')[0],
          CalibrationDueDate: format(startOfMonth(add(new Date(), { months: 6 })), 'yyyy-MM-dd'),
          UploadHistory: {
            UploadLogType: 'CalibrationRecords',
            ClientType: 'PcApp',
            ServiceCenterName: '',
            ServiceCenterIpAddress: '',
            ServiceCenterOperatorName: '',
          },
        },
      })
    }
  }, [calibrationData?.Stage])

  return (
    <div>
      <DeviceDetailsReportSelector />
      {calibrationData ? (
        <div className='p-5 grid gap-4 grid-cols-1 lg:grid-cols-6  m-auto'>
          <div className='lg:col-span-2'>
            <CalibrationStatusReport
              isCompleted={isCompleted}
              calibrationStatus={calibrationData.Stage}
            />
          </div>
          <div className='lg:col-span-4'>
            {isCompleted ? (
              <CalibrationReportPdf calibrationData={calibrationData} />
            ) : (
              <div className='h-full w-full bg-c-white animate-pulse overflow-hidden rounded-lg shadow text-sm p-5 flex flex-col gap-5 items-center justify-center'>
                <div className='bg-c-dark-blue-1 p-6 rounded-full'>
                  <PDFIcon className='w-6 h-6 ' fill='white' />
                </div>
                <p className='text-xl font-semibold'>Waiting for Calibration to Complete</p>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className='flex items-end justify-center gap-1 mt-5 h-full'>
          {isError ? (
            <ErrorPage />
          ) : (
            <>
              <p className='text-c-dark-blue-1'>Waiting for device </p>
              <div className='flex items-end gap-1 mb-1.5'>
                <div className='w-1 h-1 bg-c-dark-blue-1 animate-pulse  rounded-full ' />
                <div className='w-1 h-1 animate-pulse  bg-c-dark-blue-1 rounded-full' />
                <div className='w-1 h-1 animate-pulse bg-c-dark-blue-1 rounded-full ' />
              </div>
            </>
          )}
        </div>
      )}
    </div>
  )
}

export default withFeatureFlag(LiveCalibrationReport, FeatureFlags.CalibrationReport)
