import React from 'react'
import { IselectOptionProps } from 'forms/FormModelInterface'
import { DropDownIcon, SearchIcon } from 'assets/icons'
import Select, { components, GroupBase } from 'react-select'

interface selectControlProps {
  id?: string
  className?: string
  selectControlProps: any
  // data?: readonly IselectOptionProps[]
  // field?: any
  // placeHolder?: string
  // isMulti?: boolean
}

const maxItemsToShow = 5

function DropDownIndicator(props: any) {
  return (
    <components.DropdownIndicator {...props}>
      <DropDownIcon className='stroke-black fill-transparent' />
    </components.DropdownIndicator>
  )
}

function PaginationSelectControl(controlProps: selectControlProps): JSX.Element {
  const { className, selectControlProps } = controlProps

  return (
    <Select
      maxMenuHeight={selectControlProps.maxMenuHeight ?? maxItemsToShow * 32}
      isSearchable={selectControlProps.isSearchable ?? true}
      components={
        selectControlProps.isDropDownSelectable === true
          ? { DropdownIndicator: DropDownIndicator }
          : { DropdownIndicator: null }
      }
      className={className}
      {...selectControlProps}
      value={selectControlProps.defaultValue}
      //  defaultValue={selectControlProps.defaultValue}
      // classNamePrefix=''
      // options={data}
      // isMulti={false}
      // placeholder={placeHolder ?? 'Select'}
      // onChange={(selectedOption) => field.onChange(selectedOption?.value as string)}

      // unstyled
      // classNames={{
      //   control: (state) => (state.isFocused ? 'self-stretch border-4 border-purple-600' : ''),
      //   option: (state) => ( state.isDisabled ? 'text-red-600 hover:bg-blue-200' : 'text-green-600 hover:bg-blue-200'),
      //   container: (state) => ('w-60 bg-red-200 rounded-xl border-2 border-c-dark-blue-2'),
      // }}

      // Following approach - using styles works to override base styles only necessary.
      // The classNames approach above does not work to override only few styles. We have to use unstlyed and then write all styles from scratch.
      // But with the styles approach we need to use plain css, we can't use tailwind classes.
      // Overall the classNames approach is better for our use case.

      styles={{
        control: (baseStyles, state) => ({
          ...baseStyles,
          borderRadius: '20px',
          //   fontFamily: 'Poppins',
          //   fontSize: '9px',
          //   fontWeight: '700',
          //   width: '100%',
          minHeight: '32px',
          height: '32px',
          // height: 'auto',
          //   border: '3px solid blue', // c-light-blue-2

          borderWidth: '3px',
          borderColor: state.isFocused ? '#2767A4' : '#CEDCEB', //
          backgroundColor: state.isFocused ? '#EAF1F6' : '#FFFFFF', // c-light-blue-2

          '&:hover': {
            borderColor: '#1B4373',
            backgroundColor: '#FFFFFF',
          },
          ':active': {
            borderColor: '#2767A4',
            backgroundColor: '#2767A4', // c-light-blue-2
            color: '#FFFFFF',
          },
        }),

        noOptionsMessage: (baseStyles, state) => ({
          ...baseStyles,
          fontFamily: 'Poppins',
          fontSize: '11px',
          fontWeight: '500',
        }),
        valueContainer: (baseStyles, state) => ({
          ...baseStyles,
          height: '32px',
          //   width: '100%',
          //   paddingLeft: '9px',
          //   borderRadius: '16px',
          //   fontFamily: 'Poppins',
          //   fontSize: '9px',
          //   fontWeight: '700',
        }),
        singleValue: (baseStyles, state) => ({
          ...baseStyles,
          width: '100%',
          height: '100%',
          paddingRight: '1px',
          marginTop: '12px',
          fontFamily: 'Poppins',
          fontSize: '9px',
          fontWeight: '700',

          //   border: '2px solid red', // c-light-blue-2
        }),
        indicatorSeparator: (baseStyles, state) => ({
          ...baseStyles,
          display: 'none',
        }),
        dropdownIndicator: (baseStyles, state) => ({
          ...baseStyles,
          marginRight: '1px',
          marginBottom: '6px',
          //   border: '2px solid green',
          width: '20px',
          height: '12px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          //   width: '16px',
          // display: (state.getValue() as IselectOptionProps[])?.length === 0 ? 'block' : 'none',
        }),
        clearIndicator: (baseStyles, state) => ({
          ...baseStyles,
          display: 'none',
        }),
        menu: (base) => ({
          ...base,
          borderRadius: '10px',
          backgroundColor: '#DDE8F2', // c-light-blue-2

          border: '3px solid #DDE8F2',
          //   scrollSnapType: 'y mandatory',
          // width: '100%',
          boxShadow: '0px 0px 40px 0px rgba(32, 71, 127, 0.60)',
        }),
        menuList: (base, state) => ({
          ...base,
        }),
        menuPortal: (base) => ({
          ...base,
        }),
        option: (baseStyles, state) => ({
          ...baseStyles,
          fontFamily: 'Poppins',
          fontSize: '9px',
          //   height: '24px',

          fontWeight: state.isDisabled ? '400' : '700',
          backgroundColor: state.isFocused ? '#FFFFFF' : '#DDE8F2', // c-light-blue-2

          color: state.isDisabled ? 'gray' : '#1B4373',
          // borderRadius: state.isFocused ? '0 0 16px 16px' : '16px',
          borderRadius: '10px',
        }),

        // control: (baseStyles, state) => ({
        //   ...baseStyles,
        //   borderColor: state.isFocused ? 'red' : 'green',

        // }),
      }}
    />
  )
}

export default PaginationSelectControl
