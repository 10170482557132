import React from 'react'
import { SvgProps } from 'types/SVGProps'

function DevicesIcon(props: SvgProps): JSX.Element {
  const { stroke, fill, className, toggleClick } = props

  return (
    <div className=''>
      <svg
        className={className}
        width='21'
        height='20'
        viewBox='0 0 21 20'
        fill={fill ?? 'none'}
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M8.625 12.2143H3V3.85714C3 3.3646 3.19754 2.89223 3.54917 2.54394C3.90081 2.19566 4.37772 2 4.875 2H16.125C16.6223 2 17.0992 2.19566 17.4508 2.54394C17.8025 2.89223 18 3.3646 18 3.85714V6M1.5 15H11.4375'
          stroke={stroke ?? '#FFFFFF'}
          strokeWidth='1.8'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M12 7.2C12 6.537 12.597 6 13.333 6H18.667C19.403 6 20 6.537 20 7.2V16.8C20 17.463 19.403 18 18.667 18H13.333C12.597 18 12 17.463 12 16.8V7.2Z'
          stroke={stroke ?? '#FFFFFF'}
          strokeWidth='1.8'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </div>
  )
}

export default DevicesIcon
