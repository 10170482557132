import { getAccessToken, getAccessTokenProps } from 'services/MSALService'
import ConnectAPIClient from 'services/ConnectAPIClient'
import { CalRecordPayload } from '../interface'

export const calibrationUpdateUrl = () => '/devicelogs/api/calrecord'

export async function updateCalRecord(
  params: getAccessTokenProps & { payload: CalRecordPayload },
): Promise<unknown> {
  try {
    const { msalContext, payload } = params
    const token = await getAccessToken({ msalContext, redirectPageURL: '' })
    const url = calibrationUpdateUrl()
    const resp = await ConnectAPIClient.post<unknown>(url, token, '', {
      ...payload,
    })
    const response = resp.data
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}
