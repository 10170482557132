import clsx from 'clsx'
import React from 'react'
import { CloseIcon, ArrowLeft, ArrowRight, CalenderIcon } from 'assets/icons'
import { CalenderHeaderProps } from './CalenderType'

export default function CalenderHeader({
  isCurrentMonth,
  goToCurrentMonth,
  goToPreviousMonth,
  goToNextMonth,
  selectedDate,
  selectedDay,
  dateFilter,
  shouldShowFilter,
  hasFilter,
  applyFilter,
  clearFilter,
  title,
}: CalenderHeaderProps) {
  const monthName = new Date(selectedDate).toLocaleDateString('en-US', { month: 'long' })
  const year = selectedDate.getFullYear()
  const isDateFilterEmpty = dateFilter.length === 0

  const buttonClass =
    'flex h-9 w-12 items-center justify-center rounded-full text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:hover:bg-gray-50'
  const filterButtonClass = clsx(
    {
      'bg-c-light-blue-4': isDateFilterEmpty,
      'bg-c-dark-blue-1': !isDateFilterEmpty,
    },
    'transition-all duration-200 delay-50 ease-in-out rounded-full text-white text-xs flex justify-center items-center px-3 py-1 h-6 w-full',
  )
  const clearButtonClass = clsx(
    {
      'translate-x-0 scale-100 relative': !isDateFilterEmpty && !hasFilter,
      '-translate-x-1/2 scale-0 absolute -z-50': isDateFilterEmpty || hasFilter,
    },
    'bg-c-dark-blue-1 p-1 rounded-full transition-all duration-200 delay-100 transform ease-in-out',
  )

  return (
    <header className='flex flex-row items-center justify-between bg-c-white rounded-t-2xl px-6 py-4 flex-none relative'>
      <div>
        <div className='flex gap-2 items-center'>
          <CalenderIcon className='w-4 h-4 fill-c-blue' />
          <button
            type='button'
            title={isCurrentMonth ? 'Current month' : 'Go to Current month'}
            onClick={goToCurrentMonth}
            className='text-sm font-semibold text-gray-900 hover:bg-gray-50 focus:relative md:block'
          >
            {monthName}
            <time dateTime={selectedDay?.date} className='ml-2'>
              {year}
            </time>
          </button>
        </div>
        {title}
      </div>

      <div className='flex flex-col items-center'>
        <div className='relative flex justify-between items-center md:items-stretch'>
          <button
            onClick={goToPreviousMonth}
            title='Previous Month'
            type='button'
            className={`${buttonClass} pr-1 md:pr-0`}
          >
            <span className='sr-only'>Previous month</span>
            <ArrowLeft className='h-5 w-5' />
          </button>

          <button
            type='button'
            title='Next Month'
            className={`${buttonClass} pl-1 md:pl-0`}
            onClick={goToNextMonth}
          >
            <span className='sr-only'>Next month</span>
            <ArrowRight className='h-5 w-5' />
          </button>
        </div>

        {shouldShowFilter && (
          <div className='w-full flex flex-row gap-2'>
            <button
              onClick={hasFilter ? clearFilter : applyFilter}
              title={hasFilter ? 'Reset Filter' : 'Apply filter'}
              disabled={isDateFilterEmpty}
              type='button'
              className={filterButtonClass}
            >
              <p>{hasFilter ? 'Reset' : 'Apply'}</p>
            </button>
            <button
              onClick={clearFilter}
              type='button'
              title='Clear Selection'
              className={clearButtonClass}
            >
              <CloseIcon className='w-4 h-4 fill-c-dark-blue-1' />
            </button>
          </div>
        )}
      </div>
    </header>
  )
}
