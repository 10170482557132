import React from 'react'
import { IFilterValues, DateRange } from 'forms/FormModelInterface'
import { FormatDateRange } from 'exportReports/ExportDataUtils'
import { ServiceFaultsColumnIds } from '../view/ServiceFaultsPageUtils'

const ServiceFaultFilterLookup = [
  {
    columId: ServiceFaultsColumnIds.deviceSerialNumber,
    label: 'Serial #',
    format: (val: string) => val,
  },
  {
    columId: ServiceFaultsColumnIds.customerAssetNumber,
    label: 'Assets #',
    format: (val: string) => val,
  },
  {
    columId: ServiceFaultsColumnIds.deviceUserName,
    label: 'Assign To',
    format: (val: string) => val,
  },
  { columId: ServiceFaultsColumnIds.deviceType, label: 'Device', format: (val: string) => val },
  {
    columId: ServiceFaultsColumnIds.businessUnit,
    label: 'Business unit',
    format: (val: string) => val,
  },
  { columId: ServiceFaultsColumnIds.faultType, label: 'Fault Type', format: (val: string) => val },
  {
    columId: ServiceFaultsColumnIds.faultDescription,
    label: 'Description',
    format: (val: string) => val,
  },
  {
    columId: ServiceFaultsColumnIds.faultDateTime,
    label: 'Date range',
    format: (val: DateRange) => FormatDateRange(val),
  },
]

const mapColumnLabel = (filterVal: IFilterValues) => {
  const labelToReturn = ServiceFaultFilterLookup.find(
    (l) => l.columId === filterVal.columnId,
  )?.label
  return labelToReturn
}

const mapColumnValue = (filterVal: any) => {
  const valueToReturn = ServiceFaultFilterLookup.find(
    (l) => l.columId === filterVal.columnId,
  )?.format(filterVal?.value || '')
  return valueToReturn
}

function ServiceFaultFilterFormat(filterValues: IFilterValues[], customer: string, mainBu: string) {
  let summary = `Customer: ${customer}, Global business unit: ${mainBu}\n`
  filterValues?.forEach((f) => {
    if (f.value)
      summary = `${`${summary} ${mapColumnLabel(f)}`}: ${f.value ? mapColumnValue(f) : ''},`
  })

  return summary.replace(/,\s*$/, '')
}

export default ServiceFaultFilterFormat
