import Show from 'components/atom/Show'
import React from 'react'
import { SvgProps } from 'types/SVGProps'

type SortIconProps = SvgProps & {
  sortDirection?: 'asc' | 'desc' | boolean
}

function SortIcon(props: SortIconProps): JSX.Element {
  const { stroke, fill, className, toggleClick, sortDirection = false } = props

  return (
    <div className=''>
      <svg
        className={className}
        width='20'
        height='20'
        viewBox='0 0 20 20'
        fill='none'
        onClick={toggleClick}
        xmlns='http://www.w3.org/2000/svg'
      >
        <Show>
          <Show.When isTrue={sortDirection === 'asc'}>
            <path
              d='M8 8L10 6L12 8'
              stroke={stroke ?? '#FFFFFF'}
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </Show.When>
          <Show.When isTrue={sortDirection === 'desc'}>
            <path
              d='M12 12L10 14L8 12'
              stroke={stroke ?? '#FFFFFF'}
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </Show.When>
          <Show.When isTrue={!sortDirection}>
            <path
              d='M8 8L10 6L12 8'
              stroke={stroke ?? '#FFFFFF'}
              strokeLinecap='round'
              strokeLinejoin='round'
            />

            <path
              d='M12 12L10 14L8 12'
              stroke={stroke ?? '#FFFFFF'}
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </Show.When>
        </Show>
      </svg>
    </div>
  )
}
SortIcon.defaultProps = {
  sortDirection: false,
}
export default SortIcon
