import { QueryClient, UseQueryResult, useQueries, useQuery } from '@tanstack/react-query'
import { LoaderFunction, createSearchParams } from 'react-router-dom'
import { IPublicClientApplication, InteractionStatus } from '@azure/msal-browser'

import { getAccessTokenFromPCA, getAccessTokenProps } from 'services/MSALService'

import {
  getOrFetchDevicesList,
  iDevice,
} from 'data/DeviceListHook'
import { getOrFetchUserInfo } from 'data/UserInfoHook'
import { IsUserGlobalScope } from 'utils/UserDataUtils'
import { allCustomerOption, getOrFetchCustomerList } from 'data/CustomerListHook'
import { getOrFetchBusinessUnitsByCustomer } from 'data/BusinessUnitsListByCustomerHook'
import { getOrFetchBusinessUnitsForUser } from 'data/BusinessUnitsListByUserHook'
import { iBusinessUnitDetails } from 'components/commonComponents/BusinessUnitDataUtils'
import { allBusinessUnitOption } from 'data/BusinessUnitsListHookWrapper'
import { iDataLoaderParams } from 'data/Interfaces'

export type iDeviceListDataWithStats = iDevice & {
  lastSwitchOnDate: Date | string
  lastUploadDate: Date | string
}

interface iCalibrationPageDataLoaderParams {
  queryClient: QueryClient
  msalInstance: IPublicClientApplication
}

export interface iAPIBusinessUnitParams {
  businessUnitId?: string
  customerId?: string
}

interface iDeviceListAPIParams extends iAPIBusinessUnitParams, getAccessTokenProps {}

interface iFetchDataParams extends iAPIBusinessUnitParams {
  token: string
}

interface iGetOrFetchDeviceListDataProps {
  queryClient: QueryClient
  token: string
  businessUnitId?: string
  customerId?: string
}

async function getOrFetchDevicePageData(props: iGetOrFetchDeviceListDataProps) {
  const { queryClient, token, businessUnitId, customerId } = props

  const devicesList = await getOrFetchDevicesList({
    queryClient,
    token,
    businessUnitId,
    customerId,
  })
  return devicesList
}

function getTopLevelBusinessUnitId(businessUnitsList: iBusinessUnitDetails[]) {
  const topLevelBusinessUnit = businessUnitsList.find(
    (businessUnit) => businessUnit.parentId === null,
  )
  if (!topLevelBusinessUnit) return ''
  return topLevelBusinessUnit?.id
}

export const CalibrationPageDataLoader =
  (props: iDataLoaderParams): LoaderFunction =>
  async ({ request, params }) => {
    const { queryClient, msalInstance, msalAccounts, msalInProgress } = props
    if (!msalInstance) throw new Error('No MSAL Context')
    if (!queryClient) throw new Error('No query client')

    try {
      if (msalInProgress !== InteractionStatus.None) {
        return {}
      }
      if (msalAccounts.length === 0) {
        return {}
      }
      const token = await getAccessTokenFromPCA({ msalInstance })

      const userInfo = await getOrFetchUserInfo({ queryClient, token })

      const isUserGlobal = IsUserGlobalScope(userInfo)

      const searchParams = createSearchParams(new URLSearchParams(request.url.split('?')[1]))

      const customerId = isUserGlobal
        ? searchParams.get('c') ?? (allCustomerOption.id as string)
        : ''
      const businessUnitId = searchParams.get('mainbu') ?? allBusinessUnitOption.id

      const customerList = !isUserGlobal ? [] : await getOrFetchCustomerList({ queryClient, token })

      const businessUnitsList =
        isUserGlobal && customerId !== '' && customerId !== allCustomerOption.id
          ? await getOrFetchBusinessUnitsByCustomer({ queryClient, token, customerId })
          : await getOrFetchBusinessUnitsForUser({ queryClient, token })

      let buId = ''
      if (
        businessUnitId === allBusinessUnitOption.id &&
        customerId !== '' &&
        customerId !== allCustomerOption.id &&
        businessUnitsList
      ) {
        buId = getTopLevelBusinessUnitId(businessUnitsList)
      } else {
        buId = businessUnitId
      }

      const devicesListWithStats = await getOrFetchDevicePageData({
        queryClient,
        token,
        businessUnitId: buId,
        customerId,
      })

      return {
        devicePageData: devicesListWithStats,
        businessUnitsList,
        customerList,
      }
    } catch (error) {
      console.error(error)
      throw error
    }
  }
