import { QueryClient, useQuery } from '@tanstack/react-query'
import { useMsal } from '@azure/msal-react'

import ConnectAPIClient from 'services/ConnectAPIClient'
import { AlarmEdgeTypes, GasUnitOfMeasurement, PortableTypes } from 'utils/CommonEnums'
import { getAccessToken, getAccessTokenProps } from 'services/MSALService'
import { AxiosError } from 'axios'
import { deviceConfigDataURL } from 'services/apis/urls/device'
import { deviceConfigDataQueryKey } from 'services/apis/caching/device'

export interface iDeviceChannelInfoDto {
  channelNumber: number
  channelName: string
}

export interface iDeviceChannelConfig {
  channelNumber: number
  sensorUid: number
  sensorDisplayName: string
  sensorUoM: GasUnitOfMeasurement
  alarm1Level: number
  alarm1EdgeType: AlarmEdgeTypes
  alarm2Level: number
  alarm2EdgeType: AlarmEdgeTypes
  gasLogThresholdValue: number
}

export interface iDeviceConfig {
  deviceId: string
  deviceSerialNumber: string
  deviceProdType: PortableTypes
  numberOfActiveChannels: number
  dataLoggingInterval: number
  isThresholdLoggingEnabled: boolean
  deviceChanelConfigDetails: Array<iDeviceChannelConfig>
  status: string
}

interface iAPIDeviceIdParams {
  deviceId: string
}

interface iFetchDeviceConfigParams {
  token: string
  deviceId: string
}

interface iGetOrFetchDeviceConfigProps {
  queryClient: QueryClient
  token: string
  deviceId: string
}

interface iDeviceConfigAPIParams extends iAPIDeviceIdParams, getAccessTokenProps {}

export async function FetchDeviceConfig(params: iFetchDeviceConfigParams): Promise<iDeviceConfig> {
  try {
    const { token, deviceId } = params
    const url = deviceConfigDataURL(deviceId)
    const resp = await ConnectAPIClient.get<iDeviceConfig>(url, token)
    const response = resp.data
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}

export async function getDeviceConfigData(deviceListAPIParams: iDeviceConfigAPIParams) {
  const { msalContext, redirectPageURL, deviceId } = deviceListAPIParams
  const token = await getAccessToken({ msalContext, redirectPageURL })
  const deviceListData = await FetchDeviceConfig({ token, deviceId })
  return deviceListData
}

export function useDeviceConfig(redirectPageURL: string, deviceId: string, enabled?: boolean) {
  const msalContext = useMsal()

  return useQuery(
    deviceConfigDataQueryKey(deviceId),
    () =>
      getDeviceConfigData({
        msalContext,
        redirectPageURL,
        deviceId,
      }),
    {
      enabled,
      onError: (error: AxiosError) => {
        console.log(error)
      },
    },
  )
}

export async function getOrFetchDeviceConfig(props: iGetOrFetchDeviceConfigProps) {
  const { queryClient, token, deviceId } = props

  const deviceConfigCached = queryClient.getQueryData<iDeviceConfig>(
    deviceConfigDataQueryKey(deviceId),
  )

  if (deviceConfigCached) {
    return deviceConfigCached
  }

  const deviceConfigFetched = await queryClient.fetchQuery(
    deviceConfigDataQueryKey(deviceId),
    async () =>
      FetchDeviceConfig({
        token,
        deviceId,
      }),
  )

  return deviceConfigFetched
}
