import { QueryClient, useQuery } from '@tanstack/react-query'
import { useMsal } from '@azure/msal-react'
import { getAccessToken, getAccessTokenProps } from 'services/MSALService'
import ConnectAPIClient from 'services/ConnectAPIClient'
import { IselectOptionProps } from 'forms/FormModelInterface'
import { supportedLanguages } from 'translations/translationUtils'
import { AxiosError } from 'axios'
import { localeURL } from 'services/apis/urls/users'
import { localeQueryKey } from 'services/apis/caching/users'

interface iFetchLocaleParams {
  token: string
}

interface iGetOrFetchLocaleProps {
  queryClient: QueryClient
  token: string
}

export interface iLocale {
  id: string
  name: string
}

export async function FetchLocaleList(params: iFetchLocaleParams): Promise<iLocale[]> {
  try {
    const { token } = params
    const url = localeURL()
    const resp = await ConnectAPIClient.get<iLocale[]>(url, token)
    const response = resp.data
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}

export async function getLocaleListData(localeAPIParams: getAccessTokenProps) {
  const { msalContext, redirectPageURL } = localeAPIParams
  const token = await getAccessToken({ msalContext, redirectPageURL })
  const localeListData = await FetchLocaleList({ token })
  return localeListData
}

export function useLocaleList(redirectPageURL: string, enabled?: boolean) {
  const msalContext = useMsal()
  return useQuery(
    localeQueryKey(),
    () =>
      getLocaleListData({
        msalContext,
        redirectPageURL,
      }),
    {
      enabled,
      select: (resultData) => {
        if (!resultData) return []
        const resultToReturn: IselectOptionProps[] = []

        resultData.forEach((element) => {
          if (supportedLanguages.find((lang) => lang.value === element.name)) {
            resultToReturn.push({
              label: `${element.name}`,
              value: element.id,
            })
          }
        })

        return resultToReturn
      },
      onError: (error: AxiosError) => {
        console.log(error)
      },
    },
  )
}

export async function getOrFetchLocaleData(props: iGetOrFetchLocaleProps) {
  const { queryClient, token } = props

  const localeDataCached = queryClient.getQueryData<iLocale[]>(localeQueryKey())
  if (localeDataCached) {
    return localeDataCached
  }

  const localeDataFetched = await queryClient.fetchQuery(localeQueryKey(), async () =>
    FetchLocaleList({
      token,
    }),
  )

  return localeDataFetched
}
