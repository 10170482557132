import React from 'react'

type FilterFormTitleProps = {
    title?: string
}

function FilterFormTitle({ title }: FilterFormTitleProps): JSX.Element {
    return <div className='text-lg leading-5 font-bold text-c-dark-blue-1'>{title}</div>
}

FilterFormTitle.defaultProps = {
    title: 'Filters'
}

export default FilterFormTitle
