import React from 'react'
import { IFilterValues, DateRange } from 'forms/FormModelInterface'
import { OperatorsPageColumnIds } from '../view/OperatorsPageUtils'

const OperatorFilterLookup = [
  { columId: OperatorsPageColumnIds.fullName, label: 'Name', format: (val: string) => val },
  {
    columId: OperatorsPageColumnIds.devicesInUse,
    label: 'Devices in use',
    format: (val: string) => val,
  },
  {
    columId: OperatorsPageColumnIds.businessUnit,
    label: 'Business unit',
    format: (val: string) => val,
  },
  {
    columId: OperatorsPageColumnIds.status,
    label: 'Status',
    format: (val: string) => val,
  },
  { columId: OperatorsPageColumnIds.operatorId, label: 'ID', format: (val: string) => val },
]

const mapColumnLabel = (filterVal: IFilterValues) => {
  const labelToReturn = OperatorFilterLookup.find((l) => l.columId === filterVal.columnId)?.label
  return labelToReturn
}

const mapColumnValue = (filterVal: any) => {
  const valueToReturn = OperatorFilterLookup.find((l) => l.columId === filterVal.columnId)?.format(
    filterVal?.value || '',
  )
  return valueToReturn
}

function OperatorFilterFormat(filterValues: IFilterValues[], customer: string, mainBu: string) {
  let summary = `Customer: ${customer}, Global business unit: ${mainBu}\n`
  filterValues?.forEach((f) => {
    if (f.value)
      summary = `${`${summary} ${mapColumnLabel(f)}`}: ${f.value ? mapColumnValue(f) : ''},`
  })

  return summary.replace(/,\s*$/, '')
}

export default OperatorFilterFormat
