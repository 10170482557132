import { Row, flexRender } from '@tanstack/react-table'
import React, { useContext } from 'react'
import clsx from 'clsx'
import Show from 'components/atom/Show'
import { ExpandFalseIcon, ExpandTrueIcon } from 'assets/icons'
import { TableContext, TableContextType } from '../../TableContext'

// Define a component that renders a table cell
export default function TableCell<T>({
  index, // The index of the cell in the row
  rowIndex, // The index of the row in the table
  getRowCanExpand, // A function to determine if a row can be expanded
}: {
  getRowCanExpand: (row: Row<T>) => boolean // The type of the getRowCanExpand function
  index: number // The type of the index
  rowIndex: number // The type of the rowIndex
}) {
  const tableWrapper = document.getElementById('table-wrapper')

  // Use the TableContext to get the table instance
  const { table } = useContext<TableContextType<T>>(TableContext)
  // Get the row at the specified index
  const row = table.getRowModel().rows[rowIndex]

  const cellArray = table.getRowModel().rows[rowIndex]?.getVisibleCells()
  // Get the cell at the specified index in the row
  const cell = cellArray[index]

  return (
    <div
      className={clsx(
        {
          'pl-5': index === 0,
          'pr-2': index === cellArray.length - 1,

          'cursor-grab': tableWrapper && tableWrapper?.offsetWidth < tableWrapper?.scrollWidth,
        },
        ' border-c-light-blue-3 w-full',
        cell.column.id === '99' && 'flex justify-end',
        rowIndex === table.getState().pagination.pageSize - 1 ||
          rowIndex === table.getRowModel().rows.length - 1 ||
          row.getIsExpanded()
          ? 'border-b-0'
          : 'border-b-2',
      )}
    >
      <div
        className={clsx(
          { 'text-ellipsis ': !cell.column.columnDef.meta?.cellClassName },
          'h-12 flex flex-row  items-center',
          cell.column.columnDef.meta?.cellClassName ?? '',
        )}
      >
        <Show>
          <Show.When
            isTrue={Boolean(getRowCanExpand(row) && cell.column.columnDef?.meta?.fixedColumn)}
          >
            <button
              type='button'
              {...{
                onClick: row.getToggleExpandedHandler(),
                className: 'cursor-pointer pr-1',
              }}
            >
              <Show>
                <Show.When isTrue={row.getIsExpanded()}>
                  <ExpandTrueIcon />
                </Show.When>
                <Show.Otherwise>
                  <ExpandFalseIcon />
                </Show.Otherwise>
              </Show>
            </button>
          </Show.When>
        </Show>

        {flexRender(cell.column.columnDef.cell, cell.getContext())}
      </div>
    </div>
  )
}
