import React from 'react'
import GenericWidget from 'components/viewComponents/GenericWidget'
import { IFilterValues } from 'forms/FormModelInterface'
import { AlarmsConstants } from 'utils/Constants'
import { AlarmTypes } from 'utils/CommonEnums'
import { iSafetyData } from '../data/iSafetyList'
import { SafetyPageColumnIds } from '../view/SafetyPageUtils'

type SafetyAlarmTypeProps = {
  data: iSafetyData[]
  onFilter: (value: IFilterValues[]) => void
  alarmTypeFilteredParam: string
}

export default function SafetyAlarmsType({
  data,
  onFilter,
  alarmTypeFilteredParam,
}: SafetyAlarmTypeProps) {
  const widgetData = data.reduce(
    (
      acc: {
        [key: string]: number
      },
      cur,
    ) => ({
      ...acc,
      [cur.alarm]: (acc?.[cur.alarm] ?? 0) + 1,
    }),
    {},
  )

  return (
    <GenericWidget
      widgetProps={{
        title: 'Alarm Types',
        widgetData: Object.entries(widgetData).map(([key, values]) => ({
          originalData: key,
          displayText: AlarmsConstants[key as AlarmTypes],
          value: values,
          isFiltered: key === alarmTypeFilteredParam,
          columnId: SafetyPageColumnIds.alarmType,
        })),
        widgetLabelCount: 4,
        columnId: SafetyPageColumnIds.alarmType,
        gridContainerClassName: 'grid-cols-1 gap-1',
        onFilter: (filterRequestValues: IFilterValues[]) => onFilter?.(filterRequestValues),
      }}
    />
  )
}
