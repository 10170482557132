import DeviceImage from 'components/viewComponents/DeviceImage'
import { iDevicesOverdueData } from 'pages/dashboard/data/DashboardData'
import React from 'react'
import { DeviceCalDueTypes, PortableTypes } from 'utils/CommonEnums'
import { PortableTypeNames, PortableTypeProductNames } from 'utils/Constants'

import { DateRange } from 'forms/FormModelInterface'
import { calDueDateRangeOptions } from 'forms/FormUtils'
import { withErrorBoundary } from 'react-error-boundary'
import ErrorPage from 'pages/common/ErrorPage'
import { ChevronRightIcon } from 'assets/icons'
import { Link } from 'react-router-dom'

type iDashboardOverdueDetailsWidget = iDevicesOverdueData &
  Partial<Pick<iDevicesOverdueData, 'totalOverdue'>>

interface iDashboardOverdueDetailsWidgetDeviceOverDueData {
  deviceOverDueData: iDashboardOverdueDetailsWidget
  customerId?: string
  businessUnitId?: string
}

function DashboardOverdueDetailsWidget(props: iDashboardOverdueDetailsWidgetDeviceOverDueData) {
  const { deviceOverDueData, customerId, businessUnitId } = props
  const { totalOverdue, ...deviceOverDueDataWithoutTotalOverdue } = deviceOverDueData

  const calDueStatus = calDueDateRangeOptions.find(
    (option) => option.label === DeviceCalDueTypes.Overdue,
  )?.label
  const calDueDateRange = calDueDateRangeOptions.find(
    (option) => option.label === DeviceCalDueTypes.Overdue,
  )?.value as DateRange
  const calDueEndDate = new Date(calDueDateRange?.endDate).toISOString().split('T')[0]

  const overDueDeviceNames = Object.keys(deviceOverDueDataWithoutTotalOverdue)
  const overDueDeviceValues = Object.values(deviceOverDueDataWithoutTotalOverdue)
  const overDuePortableTypes = [] as PortableTypes[]
  const portableTypeNames = Object.values(PortableTypeNames)
  const overDueDeviceNamesToDisplay = [] as string[]
  const overDueDeviceValueAsPercentage = [] as string[]

  overDueDeviceNames.forEach((deviceName) => {
    const deviceType = deviceName.replace('OverDue', '').toLowerCase()
    const portableType = portableTypeNames.find((portable) => portable.name === deviceType)?.type
    if (portableType) {
      overDuePortableTypes.push(portableType)
      const n = PortableTypeProductNames[portableType]
      overDueDeviceNamesToDisplay.push(n)
    }
  })

  overDueDeviceValues.forEach((value, index) => {
    const percentage = totalOverdue === 0 ? 0 : ((value / totalOverdue) * 100).toFixed()
    overDueDeviceValueAsPercentage.push(`${percentage}%`)
  })

  const urlsearchParams = new URLSearchParams()
  if (customerId) {
    urlsearchParams.set('c', customerId)
  }
  if (businessUnitId) {
    urlsearchParams.set('mainbu', businessUnitId)
  }

  urlsearchParams.set('calduesel', calDueStatus as string)
  urlsearchParams.set('caldueto', calDueEndDate)
  const reportLink = `/web/calibration?${urlsearchParams.toString()}`

  return (
    <div className='h-full flex-grow px-2 py-2 w-full overflow-hidden bg-white shadow-card rounded-2xl '>
      <div className='px-2 pt-2 pb-1 text-base font-bold text-c-dark-blue-2'>
        Overdue calibration
      </div>
      <div className='px-2 mb-2 text-sm font-medium text-c-light-gray-1'>
        {totalOverdue} {' devices'}
      </div>
      <ul className='mt-8  '>
        {/* divide-y divide-gray-100 */}
        {overDueDeviceNames.map((deviceName, index) => (
          <li key={deviceName} className='py-2 rounded-2xl px-4 '>
            <div className='flex justify-between items-center gap-x-1 '>
              <div className='flex min-w-0 gap-x-1 items-center'>
                <div className='h-12 w-12 -ml-4'>
                  <DeviceImage deviceType={overDuePortableTypes[index]} />
                </div>
                <div className='min-w-0 flex-auto'>
                  <p className='mt-0.5 text-sm font-semibold leading-6 text-c-dark-blue-2'>
                    {overDueDeviceNamesToDisplay[index]}
                  </p>
                  {/* <p className='mt-1  text-ellipsis text-xs leading-5 text-gray-500'>{''}</p> */}
                </div>
              </div>
              <div className='shrink-0 flex flex-row gap-x-3 items-center'>
                <p className='text-base font-medium leading-6 text-c-dark-blue-4'>
                  {overDueDeviceValues[index]}
                </p>
                <Link
                  to={`${reportLink}&dtype=${overDueDeviceNamesToDisplay[index]}`}
                  // target='_blank'
                  // rel='noreferrer noopener'
                >
                  <ChevronRightIcon className=' text-xl font-medium text-c-light-gray-1 ' />
                </Link>

                {/* <a
                  href='calibration?status=Overdue'
                  className='mt-1 rounded-full bg-white px-2.5 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50'
                >
                  View
                </a> */}

                {/* {person.lastSeen ? (
              <p className='mt-1 text-xs leading-5 text-gray-500'>
                Last seen <time dateTime={person.lastSeenDateTime}>{person.lastSeen}</time>
              </p>
            ) : (
              <div className='mt-1 flex items-center gap-x-1.5'>
                <div className='flex-none rounded-full bg-emerald-500/20 p-1'>
                  <div className='h-1.5 w-1.5 rounded-full bg-emerald-500' />
                </div>
                <p className='text-xs leading-5 text-gray-500'>Online</p>
              </div>
            )} */}
              </div>
            </div>
            <div className='mt-1 mb-6 overflow-hidden rounded-full bg-c-light-blue-4'>
              <div
                className='h-2 rounded-full bg-c-red-1'
                style={{ width: overDueDeviceValueAsPercentage[index] }}
              />
            </div>

            {/* <div className='mt-1 mb-4 h-0.5 overflow-hidden rounded-full bg-c-light-blue-4' /> */}
          </li>
        ))}
      </ul>
    </div>
  )
}
DashboardOverdueDetailsWidget.defaultProps = {
  customerId: '',
  businessUnitId: '',
}

export default withErrorBoundary(DashboardOverdueDetailsWidget, {
  FallbackComponent: ErrorPage,
})
