import React from 'react'

export enum LiveReadingsPropertyEnum {
  SensorType = 'Gas Type',
  SensorUnit = 'Sensor Unit',
  SensorRange = 'Sensor Range',
  Reading = 'Gas Level',
  IsAlarm = 'IsAlarm',
  AlarmType = 'Alarm Status',
}

export const LiveReadingsProperty: {
  [key in string]: LiveReadingsPropertyEnum
} = {
  SensorType: LiveReadingsPropertyEnum.SensorType,
  SensorRange: LiveReadingsPropertyEnum.SensorRange,
  SensorUnit: LiveReadingsPropertyEnum.SensorUnit,
  Reading: LiveReadingsPropertyEnum.Reading,
  IsAlarm: LiveReadingsPropertyEnum.IsAlarm,
  AlarmType: LiveReadingsPropertyEnum.AlarmType,
}

export interface LiveReadingValue {
  date: string
  value: number
}
export interface LiveReadingSensorType {
  SensorType: string
  SensorRange: number
  SensorUnit: string
  Reading: LiveReadingValue
  IsAlarm: boolean
  AlarmType: string
}

export type GraphReadingType = {
  value: string | number | boolean
  isAlarm: boolean
  alarmType: string
  date: string
}

export const stats = [
  <svg
    key='1'
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 24 24'
    strokeWidth='1.5'
    stroke='currentColor'
    className='h-6 w-6'
  >
    <path
      strokeLinecap='round'
      strokeLinejoin='round'
      d='M9.75 3.104v5.714a2.25 2.25 0 0 1-.659 1.591L5 14.5M9.75 3.104c-.251.023-.501.05-.75.082m.75-.082a24.301 24.301 0 0 1 4.5 0m0 0v5.714c0 .597.237 1.17.659 1.591L19.8 15.3M14.25 3.104c.251.023.501.05.75.082M19.8 15.3l-1.57.393A9.065 9.065 0 0 1 12 15a9.065 9.065 0 0 0-6.23-.693L5 14.5m14.8.8 1.402 1.402c1.232 1.232.65 3.318-1.067 3.611A48.309 48.309 0 0 1 12 21c-2.773 0-5.491-.235-8.135-.687-1.718-.293-2.3-2.379-1.067-3.61L5 14.5'
    />
  </svg>,

  <svg
    key='2'
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 24 24'
    strokeWidth='1.5'
    stroke='currentColor'
    className='h-6 w-6'
  >
    <path
      strokeLinecap='round'
      strokeLinejoin='round'
      d='M9 6.75V15m6-6v8.25m.503 3.498 4.875-2.437c.381-.19.622-.58.622-1.006V4.82c0-.836-.88-1.38-1.628-1.006l-3.869 1.934c-.317.159-.69.159-1.006 0L9.503 3.252a1.125 1.125 0 0 0-1.006 0L3.622 5.689C3.24 5.88 3 6.27 3 6.695V19.18c0 .836.88 1.38 1.628 1.006l3.869-1.934c.317-.159.69-.159 1.006 0l4.994 2.497c.317.158.69.158 1.006 0Z'
    />
  </svg>,
  <svg
    key='3'
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 24 24'
    strokeWidth='1.5'
    stroke='currentColor'
    className='h-6 w-6'
  >
    <path
      strokeLinecap='round'
      strokeLinejoin='round'
      d='M3.75 3v11.25A2.25 2.25 0 0 0 6 16.5h2.25M3.75 3h-1.5m1.5 0h16.5m0 0h1.5m-1.5 0v11.25A2.25 2.25 0 0 1 18 16.5h-2.25m-7.5 0h7.5m-7.5 0-1 3m8.5-3 1 3m0 0 .5 1.5m-.5-1.5h-9.5m0 0-.5 1.5m.75-9 3-3 2.148 2.148A12.061 12.061 0 0 1 16.5 7.605'
    />
  </svg>,
  <svg
    key='4'
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 24 24'
    strokeWidth='1.5'
    stroke='currentColor'
    className='h-6 w-6'
  >
    <path
      strokeLinecap='round'
      strokeLinejoin='round'
      d='M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z'
    />
  </svg>,
]
export const LIVE_READING_CHANNEL = 'OneConnect/LiveReadings'
export const channels = [LIVE_READING_CHANNEL]
export const constructDate = (dateString: string) => {
  const [date, time] = dateString.split(' ')
  const dateWithoutComa = date.replace(/,/g, '')
  const [day, month, year] = dateWithoutComa.split('/')
  return new Date(`${month}/${day}/${year} ${time}`)
}
