import React from 'react'
import { SvgProps } from 'types/SVGProps'

function ExpandTrueIcon(props: SvgProps): JSX.Element {
  const { stroke, fill, className, toggleClick } = props

  return (
    <div className={className}>
<svg  onClick={toggleClick} 
width="20" height="20" 
viewBox="0 0 20 20" 
fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="20" y="20" width="20" height="20" rx="10" transform="rotate(-180 20 20)" fill="#DCE7EF"/>
<path d="M7 11.5L10 8.5L13 11.5" stroke="#0F1B2E" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
    </div>
  )
}


export default ExpandTrueIcon
