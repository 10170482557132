import React, { useEffect, useState, memo } from 'react'
import { useForm, SubmitHandler, Controller, FieldErrors } from 'react-hook-form'
import { DateRange, IFilterValues, IselectOptionProps } from 'forms/FormModelInterface'
// eslint-disable-next-line import/no-extraneous-dependencies
import { DevTool } from '@hookform/devtools'
import { sub } from 'date-fns'

import { DateRangeSelectorTypes, SelectOptionTypes } from 'utils/CommonEnums'
import { ChevronRightIcon, CloseIcon } from 'assets/icons'
import { DatePickerControl, SelectControl, CreatableSelectControl } from 'components/formComponents'

import FilterFormTitle from 'components/formComponents/FilterFormTitle'
import { iDevice } from 'data/DeviceListHook'
import {
  calDueDateRangeOptions,
  dateRangeOptions,
  selectAllOption,
  selectBlankOption,
} from 'forms/FormUtils'
import { withErrorBoundary } from 'react-error-boundary'
import ErrorPage from 'pages/common/ErrorPage'
import { CalibrationPageColumnIds, CalibrationPageFilterFormType } from './CalibrationsPageUtils'
import { PrepareDeviceTypeOptionsForSelect } from '../data/CalibrationDataUtils'

interface DevicePageFilterFormProps {
  selectedFilterValues?: CalibrationPageFilterFormType
  optionsData: iDevice[]
  onFilterSubmit: (filterValues: IFilterValues[]) => void
  onFilterReset: () => void
  onFilterFormClose: () => void
}

const defaultFilterValues = () => {
  const dtTo = new Date()
  const dtFrom = sub(new Date(), { months: 6 })
  const defValues: CalibrationPageFilterFormType = {
    serialNumber: selectBlankOption,
    deviceType: selectAllOption,
    calibrationStatus: selectAllOption,
    deviceUser: selectAllOption,
    businessUnit: selectAllOption,

    lastCalSelector: dateRangeOptions.find(
      (option) => option.value === DateRangeSelectorTypes.All,
    ) ?? {
      label: '',
      value: '',
    },
    lastCal: {
      startDate: dtFrom,
      endDate: dtTo,
    },
    calDueSelector: dateRangeOptions.find(
      (option) => option.value === DateRangeSelectorTypes.All,
    ) ?? {
      label: '',
      value: '',
    },
    calDue: {
      startDate: dtFrom,
      endDate: dtTo,
    },
  }
  return defValues
}

function CalibrationPageFilterForm(props: DevicePageFilterFormProps): JSX.Element {
  const { selectedFilterValues, optionsData, onFilterSubmit, onFilterReset, onFilterFormClose } =
    props
  // console.log('selectedFilterValues', selectedFilterValues.serialNumber)
  // console.log('defaultFilterValues', defaultFilterValues.serialNumber)
  const defaultFormValues = defaultFilterValues()

  const {
    register,
    handleSubmit,
    watch,
    reset,
    control,
    setValue,
    getValues,
    formState: {
      errors,
      isDirty,
      isSubmitting,
      isValid,
      isValidating,
      touchedFields,
      isSubmitSuccessful,
      isSubmitted,
      dirtyFields,
    },
  } = useForm<CalibrationPageFilterFormType>({
    defaultValues: defaultFormValues,
    values: selectedFilterValues,
  })

  const watchlastCalSelector = watch('lastCalSelector')
  const watchcalDueSelector = watch('calDueSelector')
  const customCalStartDate = watch('calDue.startDate')
  const customLastStartDate = watch('lastCal.startDate')
  const [showCustomLastCalDate, setShowCustomLastCalDate] = React.useState(false)
  const [showCustomCalDueDate, setShowCustomCalDueDate] = React.useState(false)

  const [serialNumberOptions, setSerialNumberOptions] = useState<IselectOptionProps[]>([])
  const [deviceTypeOptions, setDeviceTypeOptions] = useState<IselectOptionProps[]>([])
  const [businessUnitOptions, setBusinessUnitOptions] = useState<IselectOptionProps[]>([])
  const [calibrationStatusOptions, setCalibrationStatusOptions] = useState<IselectOptionProps[]>([])
  const [deviceUsersOptions, setDeviceUsersOptions] = useState<IselectOptionProps[]>([])

  useEffect(() => {
    if (optionsData) {
      const serialNumbersInData: IselectOptionProps[] = optionsData?.map((device) => ({
        value: device.serialNumber ?? '',
        label: device.serialNumber ?? '',
      }))
      // remove duplicate serial numbers

      const uniqueSerialNumbers = new Set(serialNumbersInData.map((item) => item.value))
      const serialNumbersInDataUnique = Array.from(uniqueSerialNumbers).map((item) => ({
        value: item,
        label: item?.toString(),
      }))

      const businessUnitsInData: IselectOptionProps[] | undefined = optionsData?.map((device) => ({
        value: device.businessUnit?.id ?? '',
        label: device.businessUnit?.name ?? '',
      }))
      // remove duplicates in business units

      const businessUnitsInDataUnique: IselectOptionProps[] = businessUnitsInData.filter(
        (v, i, a) => a.findIndex((t) => t.value === v.value) === i,
      )

      businessUnitsInDataUnique.unshift(
        selectAllOption as {
          value: string
          label: string
        },
      )

      const deviceTypesInData: IselectOptionProps[] = PrepareDeviceTypeOptionsForSelect(optionsData)

      const deviceUsersInData: IselectOptionProps[] = []
      optionsData?.forEach((device) => {
        if (device.deviceUser && device.deviceUser.fullName) {
          deviceUsersInData.push({
            value: device.deviceUser?.id ?? '',
            label: device.deviceUser?.fullName ?? '',
          })
        }
      })

      const deviceUsersInDataUnique: IselectOptionProps[] = deviceUsersInData?.filter(
        (v, i, a) => a.findIndex((t) => t?.value === v?.value) === i,
      )

      //   deviceUsersInDataUnique.unshift(
      //     selectAllOption as {
      //       value: string
      //       label: string
      //     },
      //   )

      // TODO: Use Calibration date to update status properly here
      const calibrationStatusInData: IselectOptionProps[] = optionsData?.map((device) => ({
        value: device.calDueStatus,
        label: device.calDueStatus,
      }))

      const uniqueCalibrationStatus = new Set(calibrationStatusInData.map((item) => item.value))
      const calibrationStatusInDataUnique = Array.from(uniqueCalibrationStatus).map((item) => ({
        value: item,
        label: item?.toString(),
      }))

      setSerialNumberOptions(serialNumbersInDataUnique as IselectOptionProps[])
      setBusinessUnitOptions(businessUnitsInDataUnique as IselectOptionProps[])
      setDeviceTypeOptions(deviceTypesInData)
      setDeviceUsersOptions(deviceUsersInDataUnique as IselectOptionProps[])
      setCalibrationStatusOptions(calibrationStatusInDataUnique as IselectOptionProps[])
    }
  }, [optionsData])

  useEffect(() => {
    if (watchlastCalSelector?.value === DateRangeSelectorTypes.Custom) {
      setShowCustomLastCalDate(true)
    } else {
      setShowCustomLastCalDate(false)
    }

    if (watchcalDueSelector?.value === DateRangeSelectorTypes.Custom) {
      setShowCustomCalDueDate(true)
    } else {
      setShowCustomCalDueDate(false)
    }
  }, [watchlastCalSelector, watchcalDueSelector])

  const onSubmit: SubmitHandler<CalibrationPageFilterFormType> = (data) => {
    const filterValues: IFilterValues[] = []

    filterValues.push({
      columnId: CalibrationPageColumnIds.serialNumber,
      value: data.serialNumber ? data.serialNumber.value : '',
    })

    filterValues.push({
      columnId: CalibrationPageColumnIds.deviceType,
      value: data.deviceType.value === SelectOptionTypes.All ? '' : data.deviceType.value,
    })

    filterValues.push({
      columnId: CalibrationPageColumnIds.businessUnit,
      value: data.businessUnit
        ? data.businessUnit.value === SelectOptionTypes.All
          ? ''
          : (data.businessUnit.label as string)
        : '',
    })

    filterValues.push({
      columnId: CalibrationPageColumnIds.calibrationStatus,
      value:
        data.calibrationStatus.value === SelectOptionTypes.All ? '' : data.calibrationStatus.value,
    })

    filterValues.push({
      columnId: CalibrationPageColumnIds.deviceUser,
      value: data.deviceUser.label as string,
    })

    filterValues.push({
      columnId: CalibrationPageColumnIds.lastCal,
      value:
        data.lastCalSelector.label === DateRangeSelectorTypes.All
          ? ''
          : {
              label: data.lastCalSelector.label as string,
              value: data.lastCal,
            },
    })

    filterValues.push({
      columnId: CalibrationPageColumnIds.calDue,
      value:
        data.calDueSelector.label === DateRangeSelectorTypes.All
          ? ''
          : {
              label: data.calDueSelector.label as string,
              value: data.calDue,
            },
    })

    onFilterSubmit(filterValues)
  }

  const onError = (formErrors: FieldErrors<CalibrationPageFilterFormType>) => {
    console.log(formErrors)
  }

  const onReset = () => {
    reset(defaultFilterValues)
    onFilterReset()
  }

  return (
    <div className='filter-form-container'>
      <div className='filter-form-header'>
        <FilterFormTitle />

        <div
          title='Close Filter Form'
          role='button'
          tabIndex={0}
          onClick={() => onFilterFormClose()}
          onKeyDown={() => onFilterFormClose()}
          className='filter-form-close-button '
        >
          <CloseIcon toggleClick={() => onFilterFormClose()} className='w-5 h-5 ' />
        </div>
      </div>
      <form
        onSubmit={handleSubmit(onSubmit, onError)}
        onReset={onFilterReset}
        noValidate
        className=''
      >
        <div className='filter-form-fields-container'>
          <div className='flex flex-col gap-0.5 items-start self-stretch'>
            <label
              htmlFor='faultsrFilter'
              className='pl-[13px] text-xs font-bold leading-5 text-c-dark-blue-1 '
            >
              Serial Number
            </label>
            <Controller
              name='serialNumber'
              control={control}
              render={({ field }) => (
                <CreatableSelectControl
                  className='w-full h-[41px]'
                  selectControlProps={{
                    options: serialNumberOptions,
                    value: field.value,
                    defaultValue: field.value,
                    isMulti: false,
                    isSearchable: true,
                    isDropDownSelectable: false,
                    openMenuOnClick: true,
                    placeholder: '-',
                    isClearable: true,
                    // maxMenuHeight: 130,
                    onChange: (selectedOption: IselectOptionProps) => {
                      field.onChange(selectedOption)
                    },
                  }}
                />
              )}
            />
            <p className='text-xs text-red-500'>{errors.serialNumber?.message}</p>
          </div>

          <div className='flex flex-col gap-0.5 items-start self-stretch w-full'>
            <label
              htmlFor='deviceTypeFilter'
              className='pl-[13px] text-xs font-bold leading-5 text-c-dark-blue-1 '
            >
              Device Type
            </label>

            <Controller
              name='deviceType'
              control={control}
              // rules={{ required: true }}
              render={({ field }) => (
                <SelectControl
                  className='w-full h-[42px]'
                  selectControlProps={{
                    options: deviceTypeOptions,
                    defaultValue: field.value,
                    isMulti: false,
                    isSearchable: true,
                    isDropDownSelectable: true,
                    openMenuOnClick: true,
                    placeholder: 'Select device type',
                    isClearable: false,
                    onChange: (selectedOption: IselectOptionProps) => {
                      field.onChange(selectedOption)
                    },
                  }}
                />
              )}
            />
          </div>

          <div className='flex flex-col gap-0.5 items-start self-stretch'>
            <label
              htmlFor='businessUnitFilter'
              className='pl-[13px] text-xs font-bold leading-5 text-c-dark-blue-1 '
            >
              Business Unit
            </label>
            <Controller
              name='businessUnit'
              control={control}
              render={({ field }) => (
                <SelectControl
                  className='w-full h-[42px]'
                  selectControlProps={{
                    options: businessUnitOptions,
                    value: field.value,
                    defaultValue: field.value,
                    isMulti: false,
                    isSearchable: true,
                    isDropDownSelectable: true,
                    openMenuOnClick: true,
                    placeholder: 'Select business unit',
                    isClearable: false,
                    onChange: (selectedOption: IselectOptionProps) => {
                      field.onChange(selectedOption)
                    },
                  }}
                />
              )}
            />
            <p className='text-xs text-red-500'>{errors.businessUnit?.message}</p>
          </div>

          {/* <div className='flex flex-col gap-0.5 items-start self-stretch'>
                <label
                  htmlFor='businessUnitFilter'
                  className='pl-[13px] text-xs font-bold leading-5 text-c-dark-blue-1 '
                >
                  Calibration Status
                </label>
                <Controller
                  name='calibrationStatus'
                  control={control}
                  render={({ field }) => (
                    <SelectControl
                      className='w-full h-[42px]'
                      selectControlProps={{
                        options: calibrationStatusOptions,
                        value: field.value,
                        defaultValue: field.value,
                        isMulti: false,
                        isSearchable: true,
                        isDropDownSelectable: true,
                        openMenuOnClick: true,
                        placeholder: 'Select Status',
                        isClearable: false,
                        onChange: (selectedOption: IselectOptionProps) => {
                          field.onChange(selectedOption)
                        },
                      }}
                    />
                  )}
                />
                <p className='text-xs text-red-500'>{errors.calibrationStatus?.message}</p>
              </div> */}

          <div className='flex flex-col gap-0.5 items-start self-stretch'>
            <label
              htmlFor='devicesassetnumfilter'
              className='pl-[13px] text-xs font-bold leading-5 text-c-dark-blue-1 '
            >
              Assigned To
            </label>
            <Controller
              name='deviceUser'
              control={control}
              render={({ field }) => (
                <CreatableSelectControl
                  className='w-full h-[41px]'
                  selectControlProps={{
                    options: deviceUsersOptions,
                    value: field.value,
                    defaultValue: field.value,
                    isMulti: false,
                    isSearchable: true,
                    isDropDownSelectable: false,
                    openMenuOnClick: true,
                    placeholder: '-',
                    isClearable: true,
                    // maxMenuHeight: 130,
                    onChange: (selectedOption: IselectOptionProps) => {
                      field.onChange(selectedOption)
                    },
                  }}
                />
              )}
            />
            <p className='text-xs text-red-500'>{errors.deviceUser?.message}</p>
          </div>

          <div className='flex flex-col gap-0.5 items-start self-stretch'>
            <label
              htmlFor='faultDateRangeSelector'
              className='pl-[13px] text-xs font-bold leading-5 text-c-dark-blue-1 '
            >
              Last Calibrated
            </label>

            <Controller
              name='lastCalSelector'
              control={control}
              render={({ field }) => (
                <SelectControl
                  className='w-full h-[42px]'
                  selectControlProps={{
                    options: dateRangeOptions,
                    defaultValue: field.value,
                    isMulti: false,
                    isSearchable: false,
                    isDropDownSelectable: true,
                    openMenuOnClick: true,
                    placeholder: 'Last Calibrated',
                    isClearable: false,
                    // value: field.value,
                    onChange: (selectedOption: IselectOptionProps) => {
                      const selectedValue = selectedOption?.value
                      field.onChange(selectedOption)
                      if (selectedValue !== DateRangeSelectorTypes.Custom) {
                        setValue('lastCal', selectedValue as DateRange, {
                          shouldValidate: true,
                        })
                      } else {
                        setValue('lastCal', {
                          startDate: selectedFilterValues?.lastCal.startDate || new Date(),
                          endDate: selectedFilterValues?.lastCal.endDate || new Date(),
                        })
                      }
                    },
                  }}
                />
              )}
            />
          </div>

          {showCustomLastCalDate && (
            <div className='flex flex-row justify-between  items-end '>
              <div className='w-[110px]'>
                <div className='flex flex-col gap-0.5 items-start '>
                  <div>
                    <label
                      htmlFor='faultDateFrom'
                      className='pl-[13px] text-xs font-bold leading-5 text-c-dark-blue-1 '
                    >
                      From
                    </label>
                  </div>
                  <div className=''>
                    <Controller
                      name='lastCal.startDate'
                      control={control}
                      render={({ field }) => (
                        <DatePickerControl
                          className=''
                          datePickerProps={{
                            selected: field.value,
                            maxDate: new Date(),
                            onChange: (date: Date | null) => field.onChange(date as Date),
                          }}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>

              <div className='mx-0.5 w-4 h-4 my-3'>
                <ChevronRightIcon className='' />
              </div>

              <div className='w-[110px]'>
                <div className='flex flex-col gap-0.5 items-start'>
                  <label
                    htmlFor='faultDateFilterTo'
                    className='pl-[13px] text-xs font-bold leading-5 text-c-dark-blue-1 '
                  >
                    To
                  </label>
                  <div className=''>
                    <Controller
                      name='lastCal.endDate'
                      control={control}
                      render={({ field }) => (
                        <DatePickerControl
                          className=''
                          datePickerProps={{
                            selected: field.value,
                            minDate: customLastStartDate,
                            onChange: (date: Date | null) => field.onChange(date as Date),
                          }}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}

          {errors.lastCal && (
            <>
              <p className='text-xs text-red-500'>{errors.lastCal?.startDate?.message}</p>
              <p className='text-xs text-red-500'>{errors.lastCal?.endDate?.message}</p>
            </>
          )}

          {/* Cal Due */}

          <div className='flex flex-col gap-0.5 items-start self-stretch'>
            <label
              htmlFor='faultDateRangeSelector'
              className='pl-[13px] text-xs font-bold leading-5 text-c-dark-blue-1 '
            >
              Calibration Due
            </label>

            <Controller
              name='calDueSelector'
              control={control}
              render={({ field }) => (
                <SelectControl
                  className='w-full h-[42px]'
                  selectControlProps={{
                    options: calDueDateRangeOptions,
                    defaultValue: field.value,
                    isMulti: false,
                    isSearchable: false,
                    isDropDownSelectable: true,
                    openMenuOnClick: true,
                    placeholder: 'Calibration due',
                    isClearable: false,
                    // value: field.value,
                    onChange: (selectedOption: IselectOptionProps) => {
                      const selectedValue = selectedOption?.value
                      field.onChange(selectedOption)
                      if (selectedValue !== DateRangeSelectorTypes.Custom) {
                        setValue('calDue', selectedValue as DateRange, {
                          shouldValidate: true,
                        })
                      } else {
                        setValue('calDue', {
                          startDate: selectedFilterValues?.calDue.startDate || new Date(),
                          endDate: selectedFilterValues?.calDue.endDate || new Date(),
                        })
                      }
                    },
                  }}
                />
              )}
            />
          </div>

          {showCustomCalDueDate && (
            <div className='flex flex-row justify-between  items-end '>
              <div className='w-[110px]'>
                <div className='flex flex-col gap-0.5 items-start '>
                  <div>
                    <label
                      htmlFor='faultDateFrom'
                      className='pl-[13px] text-xs font-bold leading-5 text-c-dark-blue-1 '
                    >
                      From
                    </label>
                  </div>
                  <div className=''>
                    <Controller
                      name='calDue.startDate'
                      control={control}
                      render={({ field }) => (
                        <DatePickerControl
                          className=''
                          datePickerProps={{
                            selected: field.value,
                            maxDate: new Date(),
                            onChange: (date: Date | null) => field.onChange(date as Date),
                          }}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>

              <div className='mx-0.5 w-4 h-4 my-3'>
                <ChevronRightIcon className='' />
              </div>

              <div className='w-[110px]'>
                <div className='flex flex-col gap-0.5 items-start'>
                  <label
                    htmlFor='faultDateFilterTo'
                    className='pl-[13px] text-xs font-bold leading-5 text-c-dark-blue-1 '
                  >
                    To
                  </label>
                  <div className=''>
                    <Controller
                      name='calDue.endDate'
                      control={control}
                      render={({ field }) => (
                        <DatePickerControl
                          className=''
                          datePickerProps={{
                            selected: field.value,
                            minDate: customCalStartDate,
                            onChange: (date: Date | null) => field.onChange(date as Date),
                          }}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}

          {errors.calDue && (
            <>
              <p className='text-xs text-red-500'>{errors.calDue?.startDate?.message}</p>
              <p className='text-xs text-red-500'>{errors.calDue?.endDate?.message}</p>
            </>
          )}
        </div>
        {/* Buttons */}
        <div className='filter-form-footer'>
          <input
            className='filter-form-reset-button'
            type='reset'
            disabled={isSubmitting}
            onClick={() => {
              onReset()
            }}
          />
          <input
            className='filter-form-apply-button'
            type='submit'
            value='Apply'
            // disabled={!isDirty || !isValid || isSubmitting}
          />
        </div>
      </form>

      <DevTool control={control} />
    </div> // opening div
  )
}

export default withErrorBoundary(memo(CalibrationPageFilterForm), {
  FallbackComponent: ErrorPage,
})
