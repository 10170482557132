import { Header } from '@tanstack/react-table'
import React from 'react'
import { arrayMove } from 'utils/CommonUtils'

export type DraggableWrapperChildrenProps = {
  drop: (ev: React.DragEvent<HTMLElement>) => void
  allowDrop: (ev: React.DragEvent<HTMLElement>) => void
  drag: (ev: React.DragEvent<HTMLElement>) => void
}
type DraggableWrapperProps = {
  onDragEnd: React.Dispatch<React.SetStateAction<string[]>> | ((result: unknown) => void)
  headers: Header<unknown, unknown>[]
  label: string
  children: React.FC<DraggableWrapperChildrenProps>
}

export default function DraggableWrapper({
  onDragEnd,
  headers,
  label,
  children,
}: DraggableWrapperProps) {
  // This function helps in sorting the columns
  function columnSortingHelper(pre: string[], active: HTMLElement, over: HTMLElement) {
    // Get the old index and new index of the column to be moved
    const oldIndex = pre.indexOf(active.id as string)
    const newIndex = pre.indexOf(over.id as string)

    // Find the active header and the header over which the active header is being dragged
    const activeHeader = headers.find((header) => header.id === active.id)
    const overHeader = headers.find((header) => header.id === over.id)

    // Check if the active header or the over header should not be draggable
    const canIDargHeader =
      activeHeader?.column.columnDef.meta?.shouldDraggable === false ||
      overHeader?.column.columnDef.meta?.shouldDraggable === false

    // If both headers can be dragged
    if (!canIDargHeader) {
      // Update the column order in local storage
      localStorage.setItem(
        'columnOrder',
        JSON.stringify({
          ...JSON.parse(localStorage.getItem('columnOrder') as string),
          [label]: arrayMove(pre, oldIndex, newIndex),
        }),
      )

      // Return the new column order
      return arrayMove(pre, oldIndex, newIndex)
    }

    // If either header cannot be dragged, return the old column order
    return pre
  }

  // This function handles the end of a drag event
  const handleDragEnd = (event: { active: HTMLElement; over: HTMLElement }) => {
    // Destructure the active and over elements from the event
    const { active, over } = event

    // If there is an active element and an over element, and their IDs are not the same
    if (active && over && active.id !== over.id) {
      // Call the onDragEnd function with a function that takes the current column order (pre)
      // and returns the result of the columnSortingHelper function with the current column order,
      // active element, and over element
      onDragEnd((pre: string[]) => columnSortingHelper(pre, active, over))
    }
  }

  // This function prevents the default behavior of a drag event
  function allowDrop(ev: React.DragEvent<HTMLElement>) {
    // Call preventDefault on the event if it exists
    // This prevents the browser's default handling of the data (default is open as link on drop)
    ev?.preventDefault?.()
  }

  // This function checks if a given HTML element or any of its parent nodes is a <th> element
  function isThOrParentIsTh(element: HTMLElement): HTMLElement | false {
    // Base case: if the element is null, return false
    // This means we've reached the top of the DOM tree without finding a <th> element
    if (element === null) {
      return false
    }

    // If the element is a <th> element, return the element
    if (element.tagName === 'TH') {
      return element
    }

    // If the element is not a <th> element, recursively call this function with its parent node
    return isThOrParentIsTh(element.parentNode as HTMLElement)
  }

  // This function handles the drop event
  function drop(ev: React.DragEvent<HTMLElement>) {
    // Prevent the default behavior of the drop event
    ev.preventDefault()

    // Get the ID of the element that was dragged
    const element = ev.dataTransfer.getData('text')

    // Get the source element (the element that was dragged) and the target element (the element that the source element was dropped on)
    const s = document.getElementById(element)
    const { target: t }: { target: unknown } = ev

    // Check if the source element or any of its parent nodes is a <th> element
    const source = isThOrParentIsTh(s as HTMLElement) as HTMLElement
    // Check if the target element or any of its parent nodes is a <th> element
    const target = isThOrParentIsTh(t as HTMLElement) as HTMLElement

    // If the source and target elements exist and have parent nodes
    if (source?.parentNode && target.parentNode) {
      // If the source and target elements have the 'draggable' class
      if (source.classList.contains('draggable') && target.classList.contains('draggable')) {
        // Handle the end of the drag event
        handleDragEnd({ active: source, over: target })
      }
    }
  }
  // This function handles the drag event
  function drag(ev: React.DragEvent<HTMLElement>) {
    // Destructure the target element from the event
    const { target }: { target: unknown } = ev

    // Set the data transfer object's data to the ID of the target element
    // This allows the ID to be accessed in the drop event
    ev.dataTransfer.setData('text', (target as HTMLElement).id)
  }
  return children({ drop, allowDrop, drag })
}
