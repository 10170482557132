import React from 'react'
import { IFilterValues, DateRange } from 'forms/FormModelInterface'
import {FormatDateRangeFromISO } from 'exportReports/ExportDataUtils'


const GasLogReportFilterLookup = [
  {
    columId: 'channelNumber',
    label: 'Channel Number',
    format: (val: string) => val,
  },
  {
    columId: 'gasName',
    label: 'Gas Name',
    format: (val: string) => val,
  },
  {
    columId: 'timeStamp',
    label: 'Date and time',
    format: (val: DateRange) => FormatDateRangeFromISO(val),
  },
  {
    columId: 'gasUnit',
    label: 'Gas Unit',
    format: (val: any) => val ,
  }
]

const mapColumnLabel = (filterVal: IFilterValues) => {
  const labelToReturn = GasLogReportFilterLookup.find(
    (l) => l.columId === filterVal.columnId,
  )?.label
  return labelToReturn
}

const mapColumnValue = (filterVal: any) => {
  const valueToReturn = GasLogReportFilterLookup.find(
    (l) => l.columId === filterVal.columnId,
  )?.format(filterVal?.value || '')
  return valueToReturn
}

function GasLogFilterFormat(
  filterValues: IFilterValues[],
  deviceSerialNumber: string,
  deviceType: string,
) {
  let summary = `Serial number: ${deviceSerialNumber}, Device type: ${deviceType}\n`
  filterValues?.forEach((f) => {
    if (f.value)
      summary = `${`${summary} ${mapColumnLabel(f)}`}: ${f.value ? mapColumnValue(f) : ''},`
  })

  return summary.replace(/,\s*$/, '')
}

export default GasLogFilterFormat
