import { QueryClient, UseQueryResult, useQueries, useQuery } from '@tanstack/react-query'
import { LoaderFunction, createSearchParams } from 'react-router-dom'
import { IPublicClientApplication, InteractionStatus } from '@azure/msal-browser'

import { getAccessTokenFromPCA, getAccessTokenProps } from 'services/MSALService'

import {
  getDeviceListData,
  getOrFetchDevicesList,
  iBusinessUnit,
  iDevice,
} from 'data/DeviceListHook'
import { getOrFetchUserInfo } from 'data/UserInfoHook'
import { IsUserGlobalScope } from 'utils/UserDataUtils'
import { allCustomerOption, getOrFetchCustomerList } from 'data/CustomerListHook'
import { getOrFetchBusinessUnitsByCustomer } from 'data/BusinessUnitsListByCustomerHook'
import { getOrFetchBusinessUnitsForUser } from 'data/BusinessUnitsListByUserHook'
import { useMsal } from '@azure/msal-react'
import { IselectOptionProps } from 'forms/FormModelInterface'
import { iBusinessUnitDetails } from 'components/commonComponents/BusinessUnitDataUtils'
import { allBusinessUnitOption } from 'data/BusinessUnitsListHookWrapper'
import {
  getDeviceListStatsData,
  getOrFetchDevicesListStats,
  iDeviceStatsLatestDates,
} from 'data/DeviceListStatsHook'
import { iDataLoaderParams } from 'data/Interfaces'

export type iDeviceListDataWithStats = iDevice & {
  lastSwitchOnDate: Date | string
  lastUploadDate: Date | string
}

interface iDevicePageDataLoaderParams {
  queryClient: QueryClient
  msalInstance: IPublicClientApplication
}

export interface iAPIBusinessUnitParams {
  businessUnitId?: string
  customerId?: string
}

interface iDeviceListAPIParams extends iAPIBusinessUnitParams, getAccessTokenProps {}

interface iFetchDataParams extends iAPIBusinessUnitParams {
  token: string
}

interface iGetOrFetchDeviceListDataProps {
  queryClient: QueryClient
  token: string
  businessUnitId?: string
  customerId?: string
}

export const deviceListWithStatsQueryKey = (params: iAPIBusinessUnitParams) => {
  const { businessUnitId } = params
  return ['device', 'list', 'stats', businessUnitId]
}

export function updateDeviceListDataWithStats(
  deviceListData: iDevice[] | undefined,
  devicesDatesStats: iDeviceStatsLatestDates[],
): iDeviceListDataWithStats[] {
  const combinedDeviceData: iDeviceListDataWithStats[] = []
  if (deviceListData && devicesDatesStats) {
    deviceListData.forEach((device) => {
      const deviceDateStats = devicesDatesStats.find(
        (deviceDateStat) => deviceDateStat.deviceId === device.id,
      )

      if (deviceDateStats) {
        combinedDeviceData.push({
          ...device,
          lastSwitchOnDate: deviceDateStats.lastSwitchOnDate,
          lastUploadDate: deviceDateStats.lastUploadDate,
        })
      } else {
        combinedDeviceData.push({ ...device, lastSwitchOnDate: '', lastUploadDate: '' })
      }
    })
  }
  return combinedDeviceData
}

async function getOrFetchDevicePageData(props: iGetOrFetchDeviceListDataProps) {
  const { queryClient, token, businessUnitId, customerId } = props

  const devicesList = await getOrFetchDevicesList({
    queryClient,
    token,
    businessUnitId,
    customerId,
  })

  const deviceListStats = await getOrFetchDevicesListStats({ queryClient, token, businessUnitId })

  const devicesListWithStats = updateDeviceListDataWithStats(devicesList, deviceListStats)

  return devicesListWithStats
}

function getTopLevelBusinessUnitId(businessUnitsList: iBusinessUnitDetails[]) {
  const topLevelBusinessUnit = businessUnitsList.find(
    (businessUnit) => businessUnit.parentId === null,
  )
  if (!topLevelBusinessUnit) return ''
  return topLevelBusinessUnit?.id
}

export const DevicePageDataLoader =
  (props: iDataLoaderParams): LoaderFunction =>
  async ({ request, params }) => {
    const { queryClient, msalInstance, msalAccounts, msalInProgress } = props
    if (!msalInstance) throw new Error('No MSAL Context')
    if (!queryClient) throw new Error('No query client')
    console.log('FAULTS DATA LOADER')

    try {
      if (msalInProgress !== InteractionStatus.None) {
        return {}
      }
      if (msalAccounts.length === 0) {
        return {}
      }
      const token = await getAccessTokenFromPCA({ msalInstance })

      const userInfo = await getOrFetchUserInfo({ queryClient, token })

      const isUserGlobal = IsUserGlobalScope(userInfo)

      const searchParams = createSearchParams(new URLSearchParams(request.url.split('?')[1]))

      const customerId = isUserGlobal
        ? searchParams.get('c') ?? (allCustomerOption.id as string)
        : ''
      const businessUnitId = searchParams.get('mainbu') ?? allBusinessUnitOption.id

      const customerList = !isUserGlobal ? [] : await getOrFetchCustomerList({ queryClient, token })

      const businessUnitsList =
        isUserGlobal && customerId !== '' && customerId !== allCustomerOption.id
          ? await getOrFetchBusinessUnitsByCustomer({ queryClient, token, customerId })
          : await getOrFetchBusinessUnitsForUser({ queryClient, token })

      let buId = ''
      if (
        businessUnitId === allBusinessUnitOption.id &&
        customerId !== '' &&
        customerId !== allCustomerOption.id &&
        businessUnitsList
      ) {
        buId = getTopLevelBusinessUnitId(businessUnitsList)
      } else {
        buId = businessUnitId
      }

      const devicesListWithStats = await getOrFetchDevicePageData({
        queryClient,
        token,
        businessUnitId: buId,
        customerId,
      })

      return {
        devicePageData: devicesListWithStats,
        businessUnitsList,
        customerList,
      }
    } catch (error) {
      console.error(error)
      throw error
    }
  }

// export function useDevicePageData(
//   // This function does not work properly
//   customerId: string,
//   businessUnitId: string,
//   businessUnitsList: iBusinessUnitDetails[] | undefined,
//   redirectPageURL: string,
//   enabled: boolean,
// ) {
//   const msalContext = useMsal()
//   let buId = ''
//   if (
//     businessUnitId === allBusinessUnitOption.id &&
//     customerId !== allCustomerOption.id &&
//     businessUnitsList
//   ) {
//     buId = getTopLevelBusinessUnitId(businessUnitsList)
//   } else {
//     buId = businessUnitId
//   }

//   const deviceList = useQuery(
//     deviceListDataQueryKey(buId),
//     () =>
//       getDeviceListData({
//         businessUnitId: buId,
//         msalContext,
//         redirectPageURL,
//       }),
//     {
//       enabled,
//       onError: (error) => {
//         console.log(error)
//       },
//     },
//   )

//   const deviceListStats = useQuery(
//     deviceListWithStatsQueryKey({ businessUnitId: buId }),
//     () =>
//       getDeviceListStatsData({
//         businessUnitId: buId,
//         msalContext,
//         redirectPageURL,
//       }),
//     {
//       enabled,
//       onError: (error) => {
//         console.log(error)
//       },
//     },
//   )

//   const devicePageData = deviceList.data
//     ? updateDeviceListDataWithStats(deviceList.data.data ?? [], deviceListStats.data ?? [])
//     : []

//   // const r : UseQueryResult<iDeviceListDataWithStats[], unknown> = {
//   //   data: devicePageData,
//   //   isLoading: deviceList.isLoading || deviceListStats.isLoading,
//   //   isError: deviceList.isError || deviceListStats.isError,
//   //   error: deviceList.error ?? deviceListStats.error,
//   //   isFetching: deviceList.isFetching || deviceListStats.isFetching,
//   // }

//   return {
//     data: devicePageData,
//     isLoading: deviceList.isLoading || deviceListStats.isLoading,
//     isError: deviceList.isError || deviceListStats.isError,
//     error: deviceList.error ?? deviceListStats.error,
//     isFetching: deviceList.isFetching || deviceListStats.isFetching,
//   }
// }
