import React, { useCallback, useEffect, useState } from 'react'
import { usePubNub } from 'pubnub-react'
import { ListenerParameters, MessageEvent } from 'pubnub'
import ErrorPage from 'pages/common/ErrorPage'
import Chips from './Chips'
import {
  channels,
  GraphReadingType,
  LiveReadingSensorType,
  LiveReadingsPropertyEnum,
  LiveReadingValue,
  stats,
} from '../utils/constant'
import LineChart from './LineChart'

export default function Graph() {
  const pubnub = usePubNub()
  const { title } = document
  const [docTitle] = useState<string>(title)
  const [isDeviceConnected, setIsDeviceConnected] = useState<boolean>(false)
  const [liveReadings, setLiveReadings] = useState<{
    [key: string]: GraphReadingType
  }>({})
  const [isError, setIsError] = useState<boolean>()
  const [sensor, setSensor] = useState<LiveReadingSensorType>()

  const handleLiveReading = useCallback(
    ({ channel, message }: MessageEvent) => {
      setIsError(false)
      if (channel === channels[0] && message) {
        setIsDeviceConnected(true)
        const data: LiveReadingSensorType = JSON.parse(message.toString('utf8'))
        const readings: LiveReadingValue = JSON.parse(message.toString('utf8')).Reading
        setSensor(data)
        if (data.IsAlarm) {
          document.title = `Alarm: ${data.AlarmType} - ${readings?.value} ${data?.SensorUnit}`
        } else {
          document.title = docTitle
        }
        setLiveReadings((pre) => ({
          ...pre,
          ...(readings?.value === 0
            ? {}
            : {
                [readings?.date?.split(' ')[1]]: {
                  date: readings?.date,
                  value: readings?.value,
                  isAlarm: data.IsAlarm,
                  alarmType: data.AlarmType,
                },
              }),
        }))
      } else {
        setIsDeviceConnected(false)
      }
    },
    [docTitle],
  )

  useEffect(() => {
    const listenerParams: ListenerParameters = {
      message: handleLiveReading,
      status: (statusEvent) => {
        const status = statusEvent as unknown as {
          error: boolean
          errorData: {
            error: boolean
            message: string
            service: string
            status: number
          }
        }
        if (status.error) {
          setIsError(true)
        }
      },
    }
    pubnub.addListener(listenerParams)
    pubnub.subscribe({ channels, withPresence: true })
    return () => {
      pubnub.unsubscribe({ channels })
      pubnub.removeListener(listenerParams)
    }
  }, [pubnub, handleLiveReading])

  const arrayOfReadings = Object.values(liveReadings)
  const lastReading = arrayOfReadings[arrayOfReadings.length - 1]
  return (
    <div className='flex flex-col gap-4'>
      {/* <div className='flex justify-end'>
        {isDeviceConnected ? (
          <span className='animate-pulse inline-flex items-center gap-x-1.5 rounded-full bg-c-green px-2 py-0.5 text-xs font-medium text-c-light-blue-1'>
            <svg viewBox='0 0 6 6' aria-hidden='true' className='h-1.5 w-1.5 fill-c-light-blue-1'>
              <circle r={3} cx={3} cy={3} />
            </svg>
            Live
          </span>
        ) : (
          <div className='flex gap-2'>
            <span className=' inline-flex items-center gap-x-1.5 rounded-full bg-c-red-dark px-1.5 py-0.5 text-xs font-medium text-c-light-blue-1'>
              <svg viewBox='0 0 6 6' aria-hidden='true' className='h-1.5 w-1.5 fill-c-light-blue-1'>
                <circle r={3} cx={3} cy={3} />
              </svg>
              Offline
            </span>
          </div>
        )}
      </div> */}
      <div>
        <div className=' grid grid-cols-1 sm:grid-cols-3 gap-5 '>
          <Chips
            stat={sensor?.SensorType || '-'}
            name={LiveReadingsPropertyEnum.SensorType}
            icon={stats[0]}
          />
          {/* <Chips
            stat={sensor?.SensorRange || '-'}
            name={LiveReadingsPropertyEnum.SensorRange}
            icon={stats[1]}
          /> */}
          <Chips
            stat={`${lastReading?.value || '-'} ${sensor?.SensorUnit || ''}` || '-'}
            name={LiveReadingsPropertyEnum.Reading}
            icon={stats[2]}
            isAlarm={lastReading?.isAlarm}
          />

          <div
            className={lastReading?.isAlarm && isDeviceConnected ? 'motion-safe:animate-zoom ' : ''}
          >
            <Chips
              stat={lastReading?.alarmType || '-'}
              name={LiveReadingsPropertyEnum.AlarmType}
              icon={stats[3]}
              isAlarm={lastReading?.isAlarm}
            />
          </div>
        </div>
      </div>
      <div className=''>
        {arrayOfReadings.length > 1 ? (
          <div className=' h-[465px] overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6'>
            <LineChart data={arrayOfReadings} />
          </div>
        ) : (
          <div className='flex items-end justify-center gap-1 mt-5 h-full'>
            {isError ? (
              <ErrorPage />
            ) : (
              <>
                <p className='text-c-dark-blue-1'>Waiting for device </p>
                <div className='flex items-end gap-1 mb-1.5'>
                  <div className='w-1 h-1 bg-c-dark-blue-1 animate-pulse  rounded-full ' />
                  <div className='w-1 h-1 animate-pulse  bg-c-dark-blue-1 rounded-full' />
                  <div className='w-1 h-1 animate-pulse bg-c-dark-blue-1 rounded-full ' />
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  )
}
