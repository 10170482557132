import React, { useContext, useEffect } from 'react'
import { useMsal } from '@azure/msal-react'
import { EventMessage, EventType, InteractionStatus, InteractionType } from '@azure/msal-browser'
import { IntlProvider } from 'react-intl'

import { iUserAccountState, UserAccountContext } from 'contexts/UserAccountContext'

import {
  ADB2CPolicies,
  getAuthRequest,
  SignInAuthority,
  silentTokenRequest,
} from 'utils/AuthConfig'
import { useUserInfo } from 'data/UserInfoHook'
import { translations } from 'translations/translationUtils'
import { UserAccountContextActions } from 'utils/Constants'
import enGB from 'translations/en-GB.json'
import LayoutWrapper from 'LayoutWrapper'
import ErrorPageGeneric from 'pages/common/ErrorPageGeneric'
import { useGenericEventHandler } from 'data/GenericEventHandler'

// const queryClient = new QueryClient({
//   defaultOptions: {
//     queries: {
//       staleTime: 1000 * 60 * 5, // 5 minutes
//       // refetchOnWindowFocus: false,
//       // refetchOnMount: false,
//       // refetchOnReconnect: true,
//     },
//   },
// })

function Fallback() {
  return (
    <div className='animate-pulse absolute right-1/2 bottom-1/2  transform translate-x-1/2 translate-y-1/2 '>
      <div className='h-8 w-8 animate-spin rounded-full border-4 border-solid border-c-orange border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]' />
    </div>
  )
}

const urlPath = window.location.pathname

const isSignUpPath = urlPath.includes('web/signup')

function App() {
  const { genericEventHandler } = useGenericEventHandler()
  const { accounts, inProgress, instance } = useMsal()
  // const activeAccount = instance.getActiveAccount() || accounts?.length > 0 ? accounts[0] : null
  // const [activeAccount, setActiveAccount] = React.useState(
  //   instance.getActiveAccount() || accounts?.length > 0 ? accounts[0] : null,
  // )
  // const activeAccount = instance.getActiveAccount()

  const { state, dispatch } = useContext(UserAccountContext)

  /* 
    if isSignUpPath is true, AND accounts length is greater than 0,
    then the user is already signed in and trying to access the signup page
    so we need to ask them to sign out first

  */

  // if (isSignUpPath && accounts.length > 0) {
  //   /*
  //     Need to modify this to provide a better user experience
  //     Show a signout button and the user account which is currently signed in
  //   */
  //   return <div>Please sign out first</div>
  // }

  /*
  If there are multiple accounts, we need to ask the user to select an account
  */

  if (accounts.length > 1 && instance.getActiveAccount() === null) {
    /* 
      Need to modify this to provide a better user experience
      Show a list of accounts and ask the user to select one
    */
    return (
      <div>
        <h2>Please select an account:</h2>
        <ul>
          {accounts.map((account) => (
            <li key={account.homeAccountId}>
              <div
                role='button'
                tabIndex={0}
                onClick={() => instance.setActiveAccount(account)}
                onKeyDown={() => instance.setActiveAccount(account)}
              >
                {account.username ||
                  account.name ||
                  account.idTokenClaims?.name ||
                  account.idTokenClaims?.preferred_username}
              </div>
            </li>
          ))}
        </ul>
      </div>
    )
  }

  const authRequest = getAuthRequest(isSignUpPath)
  if (state.adb2cPolicy !== authRequest.authority) {
    dispatch({
      type: UserAccountContextActions.SetADB2CPolicy,
      payload: { adb2cPolicy: authRequest.authority },
    })
  }

  // useEffect(() => {
  //   if (accounts.length > 0) {
  //     instance.setActiveAccount(accounts[0])
  //   }
  // }, [accounts, instance])

  useEffect(() => {
    // callback function for the instance.addEventCallback
    function instanceCallback(event: EventMessage) {
      // console.log('event', event)

      if (event.eventType === EventType.ACCOUNT_ADDED) {
        // Update UI with new account
      } else if (event.eventType === EventType.ACCOUNT_REMOVED) {
        // Update UI with account logged out
        window.open('/', '_self')
      }
      if (
        event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS &&
        event.interactionType === InteractionType.Redirect
      ) {
        console.log('Acquired token via redirect')
      }
      if (event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) {
        console.log('Acquired token via redirect')
        // if (state.isAuthorized === false) {
        //   dispatch({
        //     type: UserAccountContextActions.UserLogin,
        //   })
        // }
        if (instance.getActiveAccount() === null && instance.getAllAccounts().length === 1) {
          instance.setActiveAccount(instance.getAllAccounts()[0])
        }
      }

      if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        console.log('Login success')
        if (instance.getActiveAccount() === null && instance.getAllAccounts().length === 1) {
          instance.setActiveAccount(instance.getAllAccounts()[0])
        }

        // dispatch({
        //   type: UserAccountContextActions.UserLogin,
        // })
      }

      if (event.eventType === EventType.SSO_SILENT_SUCCESS) {
        console.log('SSO silent success')
        // dispatch({
        //   type: UserAccountContextActions.UserLogin,
        // })
      }

      if (
        event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS &&
        event.interactionType === 'silent'
      ) {
        console.log('Acquired token silently')
        // const payload = event.payload as AuthenticationResult
        // const { account } = payload
        // instance.setActiveAccount(account)
        // const policyName =
        //   (account?.idTokenClaims?.tfp as string) || (account?.idTokenClaims?.acr as string)
        // const fullyQualifiedPolicyName = ADB2CPolicies.find(
        //   (policy) => policy.name === policyName.toUpperCase(),
        // )?.authority
        // const userInfoAPIParams: iGenericAPIParamsMsalInstance = {
        //   msalInstance: instance,
        //   apiURL: 'users/api/userinfo',
        //   redirectPageURL: '/',
        //   adb2cPolicy: fullyQualifiedPolicyName || SignInAuthority,
        // }
        // const userInfo = await getUserInfo(userInfoAPIParams)
        // const userAccountState: iUserAccountState = {
        //   ...userInfo,
        //   adb2cPolicy: fullyQualifiedPolicyName || SignInAuthority,
        // }
        // dispatch({ type: UserAccountContextActions.SetUserAccount, payload: { userAccountState } })
      }
    }

    // This will be run on component mount
    const callbackId = instance.addEventCallback(instanceCallback)

    return () => {
      // This will be run on component unmount
      if (callbackId) {
        instance.removeEventCallback(callbackId)
      }
    }
  }, [instance])

  const userLocale = state.user.locale

  useEffect(() => {
    if (
      instance.getAllAccounts().length === 0 &&
      inProgress === InteractionStatus.None &&
      !isSignUpPath
    ) {
      instance.acquireTokenRedirect(silentTokenRequest)
    }
    // if (instance.getActiveAccount() === null && accounts.length === 1) {
    //   instance.setActiveAccount(accounts[0])
    //   // setActiveAccount(accounts[0])
    // }
  }, [instance, inProgress])

  const {
    data: userInfo,
    isLoading: isUserInfoLoading,
    isFetching: isUserInfoFetching,
    isError: isUserInfoError,
    error: userInfoError,
  } = useUserInfo({
    redirectPageURL: '/',
    enabled:
      inProgress === InteractionStatus.None &&
      instance.getAllAccounts().length > 0 &&
      instance.getActiveAccount() !== null &&
      !isSignUpPath,
  })

  useEffect(() => {
    const activeAccount = instance.getActiveAccount()
    if (userInfo && activeAccount) {
      console.log('userInfo', userInfo)
      const policyName =
        (activeAccount?.idTokenClaims?.tfp as string) ||
        (activeAccount?.idTokenClaims?.acr as string)

      if (policyName) {
        const fullyQualifiedPolicyName = ADB2CPolicies.find(
          (policy) => policy.name === policyName.toUpperCase(),
        )?.authority

        const userAccountState: iUserAccountState = {
          ...userInfo,
          adb2cPolicy: fullyQualifiedPolicyName || (SignInAuthority as string),
          isAuthorized: true,
        }

        dispatch({ type: UserAccountContextActions.SetUserAccount, payload: { userAccountState } })
      }
    }
  }, [userInfo, userInfoError, instance])

  useEffect(() => {
    if (isUserInfoError) {
      genericEventHandler({
        onlyTrack: true,
        message: (userInfoError as Error).message,
        error: userInfoError,
        severity: 'error',
        extraData: {
          component: 'App',
          action: 'while fetching user info',
        },
      })
    }
  }, [isUserInfoError, userInfoError])

  return isSignUpPath || userInfo?.user?.id ? (
    <IntlProvider
      messages={translations[userLocale as keyof typeof translations] || enGB}
      locale={userLocale || 'en-GB'}
      defaultLocale='en-GB'
    >
      <LayoutWrapper />
    </IntlProvider>
  ) : !userInfo?.user?.id &&
    isUserInfoLoading &&
    isUserInfoFetching &&
    instance.getAllAccounts().length === 0 ? (
    <div className='flex items-center align-middle gap-x-5 animate-pulse absolute right-1/2 bottom-1/2  transform translate-x-1/2 translate-y-1/2 '>
      <div className='h-6 w-6 animate-spin rounded-full border-4 border-solid border-c-orange border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]' />
      <div className='text-base leading-6 text-c-dark-blue-1 font-medium text-center'>
        Authenticating
      </div>
    </div>
  ) : isUserInfoError ? (
    <ErrorPageGeneric errorMessage={(userInfoError as Error).message} />
  ) : (
    <Fallback />
  )
}

export default App

// <MsalAuthenticationTemplate
//   interactionType={InteractionType.Redirect}
//   authenticationRequest={authRequest}
//   errorComponent={ErrorComponent}
//   loadingComponent={Fallback}
// >

// if (event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS && event.interactionType === 'silent') {
//   console.log('Acquired token silently')
// }

// if (event.eventType === EventType.SSO_SILENT_SUCCESS) {
//   console.log('HANDLE_REDIRECT_END')
// }
// if (event.eventType === EventType.SSO_SILENT_FAILURE) {
//   console.log('SSO Silent Failure')
// }
// if (event.eventType === EventType.ACQUIRE_TOKEN_FAILURE) {
//   // instance.acquireTokenRedirect({
//   //   authority: SignInAuthority,
//   //   scopes: [
//   //     'openid',
//   //     'profile',
//   //     'offline_access',
//   //     'https://connectv2trial1.onmicrosoft.com/testAPI1/demo.read',
//   //     'https://connectv2trial1.onmicrosoft.com/testAPI1/Files.Read',
//   //   ],
//   //   prompt: 'login',
//   // })
// }

// if (event.eventType === EventType.ACQUIRE_TOKEN_FAILURE) {
//   const error = event.error as BrowserAuthError
//   const { errorCode } = error
//   // if (
//   //   errorCode === 'consent_required' ||
//   //   errorCode === 'interaction_required' ||
//   //   errorCode === 'monitor_window_timeout'
//   // ) {
//   //   msalInstance.acquireTokenRedirect({
//   //     ...msalInstance.getAllAccounts()[0],
//   //     scopes: [
//   //       'openid',
//   //       'profile',
//   //       'offline_access',
//   //       'https://connectv2trial1.onmicrosoft.com/testAPI1/demo.read',
//   //       'https://connectv2trial1.onmicrosoft.com/testAPI1/Files.Read',
//   //     ],
//   //     prompt: 'select_account',
//   //   })
//   // }
// }

// const AppRouter = createBrowserRouter([
//   {
//     path: '/',
//     element: <RootPage />,
//   },
//   {
//     path: 'web/signup',
//     element: <SusiLayoutContainer />,
//     children: [
//       {
//         path: 'invite/:inviteId',
//         element: <SignUpPage />,
//       },
//       {
//         path: 'done',
//         element: <SignUpWelcomePage />,
//       },
//       {
//         path: '*',
//         element: <NotFound />,
//       },
//     ],
//   },
//   {
//     path: 'web/*',
//     element: <LayoutContainer />,
//     errorElement: <ErrorPage />,
//     children: [
//       {
//         errorElement: <ErrorPage />,
//         children: [
//           {
//             element: <Dashboard />,
//             errorElement: <ErrorPage />,
//             index: true,
//             loader: DashboardDataLoader({ queryClient, msalInstance: instance }),
//           },
//           {
//             path: 'dashboard',
//             element: <Dashboard />,
//             errorElement: <ErrorPage />,
//             loader: DashboardDataLoader({ queryClient, msalInstance: instance }),
//           },
//           {
//             path: 'devices',
//             // element: <DevicesPage />,
//             // loader: DevicePageDataLoader({ queryClient, msalInstance: instance }),

//             children: [
//               {
//                 path: ':deviceSerialNumber',
//                 element: <DeviceDetailsRoot />,
//                 errorElement: <ErrorPage />,
//                 //  loader: DeviceDetailsLoader(queryClient),
//                 children: [
//                   {
//                     element: <DeviceEventLogs />,
//                     errorElement: <ErrorPage />,
//                     loader: DeviceEventLogsLoader({ queryClient, msalInstance: instance }),
//                     index: true,
//                   },
//                   {
//                     path: 'eventlog',
//                     element: <DeviceEventLogs />,
//                     errorElement: <ErrorPage />,
//                     loader: DeviceEventLogsLoader({ queryClient, msalInstance: instance }),
//                   },
//                   {
//                     path: 'gaslog',
//                     element: <DeviceGasLogs />,
//                     // loader: DeviceGasLogsLoader(queryClient),
//                     errorElement: <ErrorPage />,
//                   },
//                   {
//                     path: 'assignment-history',
//                     element: <DeviceAssignmentHistory />,
//                     loader: DeviceAssignmentHistoryLoader({
//                       queryClient,
//                       msalInstance: instance,
//                     }),
//                     errorElement: <ErrorPage />,
//                   },
//                   {
//                     path: 'faults',
//                     element: <DeviceFaults />,
//                     errorElement: <ErrorPage />,
//                   },
//                   {
//                     path: 'sync-history',
//                     element: <DeviceSyncHistory />,
//                     errorElement: <ErrorPage />,
//                   },
//                   {
//                     path: 'near-miss',
//                     element: <DeviceNearMiss />,
//                     errorElement: <ErrorPage />,
//                   },
//                   {
//                     path: 'bump-history',
//                     element: <BumpHistory />,
//                   },
//                   {
//                     path: '*', // 404 page
//                     element: <NotFound />,
//                   },
//                 ],
//               },

//               {
//                 index: true,
//                 element: <DevicesPage />,
//                 loader: DevicePageDataLoader({ queryClient, msalInstance: instance }),
//               },
//             ],
//           },
//           {
//             path: 'calibration',
//             element: <CalibrationsPage />,
//             errorElement: <ErrorPage />,
//             loader: CalibrationPageDataLoader({ queryClient, msalInstance: instance }),
//           },
//           {
//             path: 'service',
//             element: <ServiceFaultsPage />,
//             errorElement: <ErrorPage />,
//             loader: FaultsDataLoader({ queryClient, msalInstance: instance }),
//           },
//           {
//             path: 'safety',
//             element: <SafetyListPage />,
//             loader: SafetyPageDataLoader({ queryClient, msalInstance: instance }),
//             errorElement: <ErrorPage />,
//           },
//           {
//             path: 'operators',
//             element: <OperatorListPage />,
//             errorElement: <div>Oops! There was an error.</div>,
//             loader: OperatorsPageDataLoader({ queryClient, msalInstance: instance }),
//           },
//           {
//             path: 'org/info',
//             element: <div>Organisation Settings page</div>,
//             errorElement: <ErrorPage />,
//           },
//           {
//             path: 'helpandsupport',
//             element: <Help />,
//             errorElement: <ErrorPage />,
//             loader: HelpPageDataLoader({ queryClient, msalInstance: instance }),
//           },
//           {
//             path: 'profile',
//             element: <UserSettings />,
//             errorElement: <ErrorPage />,
//             loader: UserSettingsPageDataLoader({ queryClient, msalInstance: instance }),
//           },
//           {
//             path: '*',
//             element: <NotFound />,
//           },

//           {
//             path: 'login',
//             element: <div>Login page</div>,
//           },

//           {
//             path: 'signup/invite/:inviteId',
//             element: <SignUpPage />,
//           },
//         ],
//       },
//     ],
//   },
// ])
