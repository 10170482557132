import React from 'react'
import { SvgProps } from 'types/SVGProps'

function AlertIcon(props: SvgProps): JSX.Element {
  const { stroke, fill, className, toggleClick } = props

  return (
    <div>
      <svg
        className={className}
        width='46'
        height='46'
        viewBox='0 0 46 46'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path d='M22.9987 42.1673C12.4129 42.1673 3.83203 33.5864 3.83203 23.0007C3.83203 12.4149 12.4129 3.83398 22.9987 3.83398C33.5844 3.83398 42.1654 12.4149 42.1654 23.0007C42.1654 33.5864 33.5844 42.1673 22.9987 42.1673ZM22.9987 38.334C27.0653 38.334 30.9654 36.7185 33.841 33.843C36.7166 30.9674 38.332 27.0673 38.332 23.0007C38.332 18.934 36.7166 15.0339 33.841 12.1583C30.9654 9.28279 27.0653 7.66732 22.9987 7.66732C18.932 7.66732 15.032 9.28279 12.1564 12.1583C9.28084 15.0339 7.66536 18.934 7.66536 23.0007C7.66536 27.0673 9.28084 30.9674 12.1564 33.843C15.032 36.7185 18.932 38.334 22.9987 38.334ZM22.9987 13.4173C23.507 13.4173 23.9945 13.6193 24.354 13.9787C24.7134 14.3381 24.9154 14.8257 24.9154 15.334V24.9173C24.9154 25.4256 24.7134 25.9132 24.354 26.2726C23.9945 26.632 23.507 26.834 22.9987 26.834C22.4904 26.834 22.0029 26.632 21.6434 26.2726C21.284 25.9132 21.082 25.4256 21.082 24.9173V15.334C21.082 14.8257 21.284 14.3381 21.6434 13.9787C22.0029 13.6193 22.4904 13.4173 22.9987 13.4173ZM22.9987 32.584C22.4904 32.584 22.0029 32.382 21.6434 32.0226C21.284 31.6632 21.082 31.1756 21.082 30.6673C21.082 30.159 21.284 29.6715 21.6434 29.312C22.0029 28.9526 22.4904 28.7507 22.9987 28.7507C23.507 28.7507 23.9945 28.9526 24.354 29.312C24.7134 29.6715 24.9154 30.159 24.9154 30.6673C24.9154 31.1756 24.7134 31.6632 24.354 32.0226C23.9945 32.382 23.507 32.584 22.9987 32.584Z' />
      </svg>
    </div>
  )
}

export default AlertIcon
