import { Row } from '@tanstack/react-table'
import { createIntl, createIntlCache, RawIntlProvider } from 'react-intl'
import { DeviceChannelInfoDto } from 'data/DeviceListHook'
import {
  add,
  differenceInCalendarDays,
  fromUnixTime,
  isAfter,
  isBefore,
  isEqual,
  sub,
} from 'date-fns'
import { DateRange, IselectOptionProps } from 'forms/FormModelInterface'
import { faultTypesToCount } from 'pages/service-faults/data/FaultsDataUtils'
import {
  SelectOptionTypes,
  DateRangeSelectorTypes,
  DeviceUserAssignedFilterValues,
  DeviceSyncFilterValues,
  GasType,
  DeviceCalDueTypes,
  DeviceFaultTypes,
  OperatorDeviceCountTypes,
} from 'utils/CommonEnums'

export const selectAllOption: IselectOptionProps = {
  label: 'All',
  value: SelectOptionTypes.All,
}

export const selectBlankOption: IselectOptionProps = {
  label: '',
  value: '',
}

export const selectAllAssignedUsersOption: IselectOptionProps = {
  label: 'All Assigned Users',
  value: DeviceUserAssignedFilterValues.AllAssignedUsers,
}

export const selectNoAssignedUsersOption: IselectOptionProps = {
  label: 'No Assigned Users',
  value: DeviceUserAssignedFilterValues.NoAssignedUsers,
}

export const selectAllSyncedOption: IselectOptionProps = {
  label: 'All Synced',
  value: DeviceSyncFilterValues.AllSynced,
}

export const selectNotSyncedOption: IselectOptionProps = {
  label: 'Not Synced',
  value: DeviceSyncFilterValues.NotSynced,
}

export const dateRangeOptions: IselectOptionProps[] = [
  {
    label: DateRangeSelectorTypes.All,
    value: {
      startDate: '',
      endDate: '',
    },
  },
  {
    label: DateRangeSelectorTypes.Today,
    value: {
      startDate: new Date(),
      endDate: new Date(),
    },
  },
  {
    label: DateRangeSelectorTypes.Yesterday,
    value: {
      startDate: sub(new Date(), { days: 1 }),
      endDate: sub(new Date(), { days: 1 }),
    },
  },
  {
    label: DateRangeSelectorTypes.Last7Days,
    value: {
      startDate: sub(new Date(), { days: 7 }),
      endDate: new Date(),
    },
  },
  {
    label: DateRangeSelectorTypes.Last30Days,
    value: {
      startDate: sub(new Date(), { days: 30 }),
      endDate: new Date(),
    },
  },
  // {
  //   label: DateRangeSelectorTypes.Last60Days,
  //   value: {
  //     startDate: sub(new Date(), { days: 60 }),
  //     endDate: new Date(),
  //   },
  // },
  // {
  //   label: DateRangeSelectorTypes.Last90Days,
  //   value: {
  //     startDate: sub(new Date(), { days: 90 }),
  //     endDate: new Date(),
  //   },
  // },
  {
    label: DateRangeSelectorTypes.Custom,
    value: DateRangeSelectorTypes.Custom.toString(),
  },
  // {
  //   label: DateRangeSelectorTypes.Last90Days,
  //   value: {
  //     startDate: sub(new Date(), { days: 90 }),
  //     endDate: new Date(),
  //   },
  // },
  // {
  //   label: DateRangeSelectorTypes.Custom,
  //   value: DateRangeSelectorTypes.Custom.toString(),
  // },
]

export type dateRangeProps = {
  startDate: string
  endDate: string
  isCustom?: boolean
  customValue?: DeviceSyncFilterValues
}

export const calDueDateRangeOptions: IselectOptionProps[] = [
  {
    label: DateRangeSelectorTypes.All,
    value: {
      startDate: '',
      endDate: '',
    },
  },
  {
    label: DeviceCalDueTypes.DueIn7Days,
    value: {
      startDate: new Date(),
      endDate: add(new Date(), { days: 7 }),
    },
  },
  {
    label: DeviceCalDueTypes.DueIn30Days,
    value: {
      startDate: new Date(),
      endDate: add(new Date(), { days: 30 }),
    },
  },
  {
    label: DeviceCalDueTypes.DueIn60Days,
    value: {
      startDate: new Date(),
      endDate: add(new Date(), { days: 60 }),
    },
  },
  {
    label: DeviceCalDueTypes.DueIn90Days,
    value: {
      startDate: new Date(),
      endDate: add(new Date(), { days: 90 }),
    },
  },
  {
    label: DeviceCalDueTypes.DueOver90Days,
    value: {
      startDate: add(new Date(), { days: 90 }),
      endDate: add(new Date(), { days: 900 }),
    },
  },
  {
    label: DeviceCalDueTypes.NotAvailable,
    value: {
      startDate: '',
      endDate: '',
    },
  },
  {
    label: DeviceCalDueTypes.Overdue,
    value: {
      startDate: new Date('1970-01-01'),
      endDate: new Date(),
    },
  },
  {
    label: DateRangeSelectorTypes.Custom,
    value: DateRangeSelectorTypes.Custom.toString(),
  },
]

export function DateRangeColumnFilterFn<T>(row: Row<T>, columnId: string, filterValue: DateRange) {
  const cell = row.getAllCells().find((c) => c.column.id === columnId)
  const cellValue = cell?.getValue() as string

  if (filterValue.isCustom && filterValue.customValue === DeviceSyncFilterValues.NotSynced) {
    if (!cellValue) {
      return true
    }
  } else if (filterValue.isCustom && filterValue.customValue === DeviceSyncFilterValues.AllSynced) {
    if (cellValue) {
      return true
    }
  }

  if (cellValue) {
    const rowInfoDate = new Date(cellValue)
    const filterStartDate = new Date(filterValue.startDate)
    const filterEndDate = new Date(filterValue.endDate)

    if (
      rowInfoDate &&
      (isAfter(rowInfoDate, filterStartDate) || isEqual(rowInfoDate, filterStartDate)) &&
      (isBefore(rowInfoDate, filterEndDate) || isEqual(rowInfoDate, filterEndDate))
    ) {
      return true
    }
  }
  // console.log('CALDUE (FALSE): ', cellValue, new Date(cellValue))
  return false
}

export function CalDueDateFilterFn<T>(row: Row<T>, columnId: string, filterValue: DateRange) {
  const cell = row.getAllCells().find((c) => c.column.id === columnId)
  const cellValue = cell?.getValue() as string

  if (filterValue.isCustom && filterValue.customValue === DeviceCalDueTypes.Overdue) {
    if (cellValue) {
      const rowInfoDate = new Date(cellValue)
      const filterEndDate = sub(new Date(), { days: 1 })
      if (rowInfoDate && isBefore(rowInfoDate, filterEndDate)) {
        return true
      }
    }
  }

  if (cellValue) {
    const rowInfoDate = new Date(cellValue)
    const filterStartDate = new Date(filterValue.startDate)
    const filterEndDate = new Date(filterValue.endDate)

    if (
      rowInfoDate &&
      (isAfter(rowInfoDate, filterStartDate) || isEqual(rowInfoDate, filterStartDate)) &&
      (isBefore(rowInfoDate, filterEndDate) || isEqual(rowInfoDate, filterEndDate))
    ) {
      return true
    }
  }
  // console.log('CALDUE (FALSE): ', cellValue, new Date(cellValue))
  return false
}

export function DateRangeColumnFilterUnixTimeFn<T>(
  row: Row<T>,
  columnId: string,
  filterValue: DateRange,
) {
  const cell = row.getAllCells().find((c) => c.column.id === columnId)
  const cellValue = cell?.getValue() as number

  if (filterValue.isCustom && filterValue.customValue === DeviceSyncFilterValues.NotSynced) {
    if (!cellValue) {
      return true
    }
  } else if (filterValue.isCustom && filterValue.customValue === DeviceSyncFilterValues.AllSynced) {
    if (cellValue) {
      return true
    }
  }

  if (cellValue) {
    // const filterStartDate = sub (new Date(filterValue.startDate), { days: 1 })  // new Date(filterValue.startDate)
    const filterStartDate = new Date(filterValue.startDate).getTime() / 1000
    const filterEndDate = add(new Date(filterValue.endDate), { days: 1 }).getTime() / 1000

    const isBetweenDates = cellValue >= filterStartDate && cellValue <= filterEndDate
    if (isBetweenDates) {
      return true
    }

    // const rowInfoDate = fromUnixTime(cellValue)
    // const filterStartDate = new Date(filterValue.startDate)
    // const filterEndDate = new Date(filterValue.endDate)

    // const isBetweenDates = differenceInCalendarDays(rowInfoDate, filterStartDate) >= 0 && differenceInCalendarDays(rowInfoDate, filterEndDate) <= 0
    // if (isBetweenDates) {
    //   return true
    // }
  }
  // if (cellValue) {
  //   const utcDate = fromUnixTime(cellValue)
  //   const offset = cellValue + utcDate.getTimezoneOffset() * 60
  //   const rowInfoDate = fromUnixTime(offset)
  //   const filterStartDate = new Date(filterValue.startDate)
  //   const filterEndDate = new Date(filterValue.endDate)

  //   if (
  //     rowInfoDate &&
  //     (isAfter(rowInfoDate, filterStartDate) || isEqual(rowInfoDate, filterStartDate)) &&
  //     (isBefore(rowInfoDate, filterEndDate) || isEqual(rowInfoDate, filterEndDate))
  //   ) {
  //     return true
  //   }
  // }
  return false
}
export function AssignedUserFilterFn<T>(row: Row<T>, columnId: string, filterValue: string) {
  const cell = row.getAllCells().find((c) => c.column.id === columnId)
  const cellValue = cell?.getValue()
  if (filterValue === DeviceUserAssignedFilterValues.AllAssignedUsers) {
    if (cellValue && cellValue !== '') {
      // && cellValue !== 'null'
      return true
    }
  } else if (filterValue === DeviceUserAssignedFilterValues.NoAssignedUsers) {
    if (!cellValue) {
      return true
    }
  } else if (filterValue === '') {
    return true
  }

  if (cellValue) {
    if (cellValue?.toString().includes(filterValue)) {
      return true
    }
  }
  return false
}

export function DeviceChannelInfoGasTypeFilter<T>(
  row: Row<T>,
  columnId: string,
  filterValue: string,
) {
  const cell = row.getAllCells().find((c) => c.column.id === columnId)
  const cellValue: DeviceChannelInfoDto[] = cell?.getValue() as DeviceChannelInfoDto[]
  if (filterValue === '') {
    return true
  }

  if (filterValue === GasType.Other) {
    if (cellValue) {
      if (
        cellValue?.find((channel) => channel.channelName === GasType.CO) ||
        cellValue?.find((channel) => channel.channelName === GasType.CO2) ||
        cellValue?.find((channel) => channel.channelName === GasType.H2S) ||
        cellValue?.find((channel) => channel.channelName === GasType.O2) ||
        cellValue?.find((channel) => channel.channelName === GasType.FLAM)
      ) {
        return false
      }
      return true
    }
  }

  if (cellValue) {
    if (cellValue?.find((channel) => channel.channelName === filterValue)) {
      return true
    }
  }

  return false
}

export function FaultTypeFilter<T>(row: Row<T>, columnId: string, filterValue: string) {
  const cell = row.getAllCells().find((c) => c.column.id === columnId)
  const cellValue = cell?.getValue()
  if (filterValue === '') {
    return true
  }

  if (cellValue) {
    if (filterValue === DeviceFaultTypes.Other) {
      if (faultTypesToCount.includes(cellValue as DeviceFaultTypes)) {
        return false
      }
      return true
    }

    if ((cellValue as string).includes(filterValue)) {
      return true
    }
  }
  return false
}

export function UserPropertyFilter<T>(row: Row<T>, columnId: string, filterValue: string) {
  const cell = row.getAllCells().find((c) => c.column.id === columnId)
  const cellValue = cell?.getValue()
  if (filterValue === '') {
    return true
  }

  if (cellValue) {
    if (cellValue?.toString().startsWith(filterValue)) {
      return true
    }
  }
  return false
}

export function CustomerPropertyFilter<T>(row: Row<T>, columnId: string, filterValue: string) {
  console.log('CustomerPropertyFilter: ', row, columnId, filterValue)
  const cell = row.getAllCells().find((c) => c.column.id === columnId)
  const cellValue = cell?.getValue()
  if (filterValue === '') {
    return true
  }

  console.log(cellValue, filterValue)
  if (cellValue) {
    if (cellValue?.toString().startsWith(filterValue)) {
      return true
    }
  }
  return false
}

export function OperatorWithDevicesFilterFn<T>(row: Row<T>, columnId: string, filterValue: string) {
  const cell = row.getAllCells().find((c) => c.column.id === columnId)
  const cellValue = cell?.getValue()

  if (cellValue) {
    const deviceCount = Number(cellValue)

    if (filterValue === '' || filterValue === OperatorDeviceCountTypes.All) {
      return true
    }

    if (filterValue === OperatorDeviceCountTypes.NoDevice) {
      if (!deviceCount || deviceCount === 0 || deviceCount === null) {
        return true
      }
    } else if (filterValue === OperatorDeviceCountTypes.OneDevice) {
      if (deviceCount === 1) {
        return true
      }
    } else if (filterValue === OperatorDeviceCountTypes.MultipleDevices) {
      if (deviceCount && deviceCount > 1) {
        return true
      }
    } else if (filterValue === OperatorDeviceCountTypes.InUse) {
      if (deviceCount && deviceCount >= 1) {
        return true
      }
    } else if (filterValue === OperatorDeviceCountTypes.NotInUse) {
      if (deviceCount === 0) {
        return true
      }
    }
  }

  return false
}

export function ActiveInActiveFilter<T>(row: Row<T>, columnId: string, filterValue: string) {
  const cell = row.getAllCells().find((c) => c.column.id === columnId)
  const cellValue = cell?.getValue()
  console.log('cellValue: ', cellValue, filterValue)
  if (cellValue) {
    if (cellValue === filterValue) {
      return true
    }
  }
  return false
}
