import { fromUnixTime, sub } from 'date-fns'
import { iDeviceTypeCount } from 'pages/devices/data/iDevicesList'
import { PortableTypes, DeviceFaultTypes } from 'utils/CommonEnums'
import _ from 'lodash'
import { IselectOptionProps } from 'forms/FormModelInterface'
import { GetProductNameText } from 'utils/CommonUtils'
import { selectAllOption } from 'forms/FormUtils'
import { iFaultData } from './FaultsData'

export interface iFaultTypeCount {
  faultType: DeviceFaultTypes
  count: number
}
export interface iFaultsInDaysCount {
  days: string
  count: number
}

export const groupFaultsByDeviceType = (deviceData: iFaultData[]) => {
  const deviceTypeCounts = new Array<iDeviceTypeCount>(
    { deviceType: GetProductNameText(PortableTypes.T4), count: 0 },
    { deviceType: GetProductNameText(PortableTypes.Gasman), count: 0 },
    { deviceType: GetProductNameText(PortableTypes.T3), count: 0 },
    { deviceType: GetProductNameText(PortableTypes.GasPro), count: 0 },
  )

  deviceData.forEach((device) => {
    // const deviceTypeText = GetProductNameText(device.deviceType)
    if (device.deviceType === GetProductNameText(PortableTypes.T4)) {
      deviceTypeCounts[0].count += 1
    } else if (device.deviceType === GetProductNameText(PortableTypes.Gasman)) {
      deviceTypeCounts[1].count += 1
    } else if (device.deviceType === GetProductNameText(PortableTypes.T3)) {
      deviceTypeCounts[2].count += 1
    } else if (device.deviceType === GetProductNameText(PortableTypes.GasPro)) {
      deviceTypeCounts[3].count += 1
    }
  })
  // deviceData.forEach((device) => {
  //   if (device.deviceType === PortableTypes.T4) {
  //     deviceTypeCounts[0].count += 1
  //   } else if (device.deviceType === PortableTypes.Gasman) {
  //     deviceTypeCounts[1].count += 1
  //   } else if (device.deviceType === PortableTypes.T3) {
  //     deviceTypeCounts[2].count += 1
  //   } else if (device.deviceType === PortableTypes.GasPro) {
  //     deviceTypeCounts[3].count += 1
  //   }
  // })

  return deviceTypeCounts
}

export const faultTypesToCount = [
  DeviceFaultTypes.FaultPcbFaultReplacementNeeded,
  DeviceFaultTypes.FaultLowBattery,
  DeviceFaultTypes.FaultAutoZeroNotInCleanAir,
  DeviceFaultTypes.FaultCalibrationError,
  DeviceFaultTypes.FaultSensorPcbFaultInvestigationNeeded,
  // DeviceFaultTypes.SensorPcbFaultInvestigationNeeded,
  // DeviceFaultTypes.Other,
]

export const groupFaultByFaultType = (faultData: iFaultData[]) => {
  const faultTypeCounts = new Array<iFaultTypeCount>(
    { faultType: DeviceFaultTypes.FaultPcbFaultReplacementNeeded, count: 0 },
    { faultType: DeviceFaultTypes.FaultLowBattery, count: 0 },
    { faultType: DeviceFaultTypes.FaultAutoZeroNotInCleanAir, count: 0 },
    { faultType: DeviceFaultTypes.FaultCalibrationError, count: 0 },
    { faultType: DeviceFaultTypes.FaultSensorPcbFaultInvestigationNeeded, count: 0 },
  )

  const faultTypesGroupedBy = _.groupBy(faultData, 'eventType')

  faultTypesToCount.forEach((faultType) => {
    const faultSummary = faultTypeCounts.find((fault) => fault.faultType === faultType)
    const countOfFaults = faultTypesGroupedBy[faultType]?.length ?? 0

    if (faultSummary) {
      faultSummary.count = countOfFaults
    } else {
      faultTypeCounts.push({ faultType, count: countOfFaults })
    }
  })

  // Merge duplicate fault type counts
  // const sensorPCBFaultCount = faultTypeCounts.find((faultType) => faultType.faultType === DeviceFaultTypes.SensorPcbFaultInvestigationNeeded)
  // const duplicateSensorPCBFaultCount = _.sumBy(faultTypeCounts, (faultType) => {
  //     if (faultType.faultType === DeviceFaultTypes.FaultSensorPcbFaultInvestigationNeeded) {
  //         return faultType.count
  //     }
  //     return 0
  // })

  // if (sensorPCBFaultCount) {
  //     sensorPCBFaultCount.count += duplicateSensorPCBFaultCount
  // } else if (duplicateSensorPCBFaultCount > 0) {
  //     faultTypeCounts.push({ faultType: DeviceFaultTypes.SensorPcbFaultInvestigationNeeded, count: duplicateSensorPCBFaultCount })
  // }

  const totalGroupedFaults = _.sumBy(faultTypeCounts, (faultType) => faultType.count)

  faultTypeCounts.push({
    faultType: DeviceFaultTypes.Other,
    count: faultData.length - totalGroupedFaults,
  })

  return faultTypeCounts
}

export const countFaultsInLastXDays = (deviceData: iFaultData[], days: number) => {
  const today = new Date()
  const date = sub(today, { days })

  const filteredData = deviceData.filter((device) => {
    const faultDate = fromUnixTime(device.timeStamp)
    return faultDate > date
  })

  return filteredData.length
}

export const PrepareDeviceTypeOptionsForSelect = (
  deviceData: iFaultData[],
): IselectOptionProps[] => {
  // temporary remove gasman devices for test
  // const deviceDataWithoutGasman = removeGasmanDevices(deviceData);

  // Get all enums in a string array
  const portableTypes: IselectOptionProps[] = []

  Object.keys(PortableTypes).forEach((portableType) => {
    const isDisabled = !deviceData.some((device) => device?.deviceType?.toString() === portableType)
    const deviceTypeText = GetProductNameText(portableType as PortableTypes)
    portableTypes.push({
      value: deviceTypeText,
      label: deviceTypeText,
      // isDisabled,
    })
  })

  portableTypes.unshift(selectAllOption)

  return portableTypes
}
