import { SnackBarContext, SnackBarSeverityType, SnackBarType } from 'contexts/SnackBarContext'
import { useContext } from 'react'
import { appInsights } from 'utils/AppInsightConfig'

/**
 * Custom hook that returns a generic event handler function.
 * The event handler sets the snackbar message and severity, and opens the snackbar.
 * If the severity is 'error' or 'warning', it also logs the exception to AppInsights.
 *
 * @returns The generic event handler function : genericEventHandler.
 */

export const useGenericEventHandler = () => {
  const { open, setSnackBar } = useContext(SnackBarContext)

  /**
   * Generic event handler function.
   *
   * @param type - The type of the event.
   * @param severity - The severity level of the event.
   * @param message - The message to display in the snackbar.
   * @param error - Optional. The error object associated with the event.
   */
  return {
    genericEventHandler({
      type,
      severity,
      message,
      error,
      extraData,
      onlyTrack = false,
    }: {
      type?: SnackBarType
      severity?: SnackBarSeverityType
      message: string
      error?: any
      onlyTrack?: boolean
      extraData?: {
        [key: string]: unknown
      }
    }) {
      if (!onlyTrack && severity && type && message) {
        setSnackBar({ severity, message, type })
        open()
      }
      if (severity === 'error' || severity === 'warning') {
        appInsights.trackException(
          {
            exception: error,
            severityLevel: severity === 'error' ? 3 : 2,
            properties: { message },
          },
          {
            location: window.location.href,
            reactAppVersion: process.env.REACT_APP_SITE_VERSION,
            ...extraData,
          },
        )
      } else {
        appInsights.trackEvent(
          { name: message },
          {
            location: window.location.href,
            reactAppVersion: process.env.REACT_APP_SITE_VERSION,
            ...extraData,
          },
        )
      }
    },
  }
}
