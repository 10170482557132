import React from 'react'
import { useMutation } from '@tanstack/react-query'
import { FormattedDate } from 'react-intl'
import clsx from 'clsx'
import {TickIconWithCircle,DeclineIcon} from 'assets/icons'
import CrowconButton from 'components/miscComponents/CrowconButton'
import { DeviceSummary } from './DeviceSummary'
import { CreateDeviceFormType } from '../CreateDeviceFormType'

export interface ConfirmDeviceDetailsProps {
  deviceInfo: CreateDeviceFormType
  nextFormStep: () => void
  previousFormStep: () => void
  confirmCancel: () => void
}

function AddDevice(props: ConfirmDeviceDetailsProps) {
  const { nextFormStep, previousFormStep, confirmCancel, deviceInfo } = props

  const [disableAddBtn, setDisableAddBtn] = React.useState<boolean>(true)

 
  
  return (
    <div className=' py-8 h-649 w-363 px-6 '>
      <div className='font-poppins text-base font-bold'>Confirm this is the correct device</div>
      <DeviceSummary deviceInfo={deviceInfo} />
      <div className='w-full flex flex-row gap-2 pt-7'>
        <CrowconButton
          useDefaultTextColour={false}
          buttonAction={() => setDisableAddBtn(false)}
          additionalClassName='text-xs text-blue-950 bg-white font-bold  border-c-light-blue-4 border-3 m-1 p-1 rounded-3xl w-24 h-12 cursor-pointer hover:bg-c-dark-blue-2 hover:text-white  hover:border-c-dark-blue-2   active:bg-c-dark-blue-3'
          buttonChildren={
            <div className='flex pl-3  flex-row  gap-2'>
              <TickIconWithCircle className='w-5' /> <div> YES</div>
            </div>
          }
        />
        <CrowconButton
          useDefaultTextColour={false}
          buttonAction={() => previousFormStep()}
          additionalClassName='text-xs bg-white font-bold border-c-light-blue-4 border-3 m-1 p-1 rounded-3xl w-210 h-12 cursor-pointer hover:bg-c-dark-blue-2 hover:text-white  hover:border-c-dark-blue-2  active:bg-c-dark-blue-3'
          buttonChildren={
            <div className='flex flex-row  gap-2 pl-3'>
              <DeclineIcon className='w-5' /> <div>NO, CHANGE DETAILS</div>
            </div>
          }
        />
      </div>
      <div className='w-full gap-2 pt-11 px-1 flex flex-row'>
        <CrowconButton
          useDefaultTextColour={false}
          additionalClassName='text-c-dark-blue-1 text-base font-bold bg-c-light-blue-2 hover:text-white h-12  w-28 rounded-3xl'
          buttonChildren={<>Cancel</>}
          buttonAction={() => confirmCancel()}
        />

        <CrowconButton
          useDefaultTextColour={!disableAddBtn}
          additionalClassName={clsx(
            'text-base h-12  w-48 rounded-3xl px-2 text-c-dark-blue-1',
            disableAddBtn &&
              'cursor-auto bg-c-light-blue-1 hover:bg-c-light-blue-1 active:bg-c-light-blue-1',
          )}
          disabled={disableAddBtn}
          buttonChildren={<>Add Device</>}
          buttonAction={() => nextFormStep()}
        />
      </div>
    </div>
  )
}

export default AddDevice
