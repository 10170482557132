export enum PortableTypes {
  Gasman = 'Gasman',
  GasPro = 'GasPro',
  T3 = 'T3',
  T4 = 'T4',
  Scout = 'Scout',
  Clip = 'Clip',
}

export enum PortableStatusTypes {
  Active = 'Active',
  Inactive = 'Inactive',
  // Faulty = "Faulty",
  // Stolen = "Stolen",
  // Deleted = "Deleted",
}

export enum UserStatusTypes {
  Active = 'Active',
  Inactive = 'Inactive',
}

export enum UserInviteStatusTypes {
  Invited = 'Invited',
  Expired = 'Expired',
  Active = 'Active',
}

export enum DeviceEventTypes {
  Event = 'Event',
  Alarm = 'Alarm',
  Fault = 'Fault',
}

export enum UserStatusEnum {
  Invited = 25,
  Active = 30,
  InActive = 35,

  Deleted = 40,
}
// "Eventlog.FaultTypes.FaultAutoZeroNotInCleanAir": "Auto Zero Not In Clean Air",
//     "Eventlog.FaultTypes.FaultCalibrationError": "Calibration Error",
//     "Eventlog.FaultTypes.FaultSensorPcbFaultInvestigationNeeded": "Sensor PCB Fault Investigation Needed",
//     "Eventlog.FaultTypes.FaultPcbFaultReplacementNeeded": "PCB Fault Replacement Needed",
//     "Eventlog.FaultTypes.FaultLowBattery": "Low Battery",
//     "Eventlog.FaultTypes.FaultPumpError": "Pump Error",
//     "Eventlog.FaultTypes.FaultManufacturingCheck": "Manufacturing Check",
//     "Eventlog.FaultTypes.FaultEventLogError": "EventLog Error",
//     "Eventlog.FaultTypes.FaultInstrumentRestartedByWatchdog": "Instrument Restarted By Watchdog",
//     "Eventlog.FaultTypes.FaultImoduleChanged": "Imodule Changed",
//     "Eventlog.FaultTypes.FaultLifeExpired": "Life Expired",
//     "Eventlog.FaultTypes.FaultDataLogError": "Data Log Error",
//     "Eventlog.FaultTypes.FaultInvalidTimeAndDate": "Invalid Time And Date",
//     "Eventlog.FaultTypes.FaultCalibrationOverdue": "Calibration Overdue",
//     "Eventlog.FaultTypes.FaultBumpFailed": "Bump Failed",
//     "Eventlog.FaultTypes.FaultLastSwitchOffDueToAssert": "Last Switch-Off Due To Assert",
//     "Eventlog.FaultTypes.FaultTimeAndDateLost": "Time and Date Lost",
//     "Eventlog.FaultTypes.FaultGasLogError": "Gas Log Error",
//     "Eventlog.FaultTypes.FaultCalibrationOverdueLocked": "Calibration OverdueLocked",
//     "Eventlog.FaultTypes.FaultChargerInAndInstrumentOnForTooLong": "Charger In and Instrument On For Too Long",
//     "Eventlog.FaultTypes.FaultBumpOverdue": "Bump Overdue",
//     "Eventlog.FaultTypes.FaultCalibrationDue": "Calibration Due",
//     "Eventlog.FaultTypes.FaultSensorsCoveredByFlowPlate": "Sensors Covered By Flow Plate",
//     "Eventlog.FaultTypes.FaultPecAbortedByRemovingFlowPlate": "Pec Aborted By Removing Flow Plate",
//     "Eventlog.FaultTypes.FaultUnknown": "Unknown",
//     "Eventlog.FaultTypes.FaultO2SensorUnbiased": "O2 Sensor Unbiased"

export enum DeviceFaultTypes {
  SensorPcbFaultInvestigationNeeded = 'SensorPcbFaultInvestigationNeeded',
  FaultSensorPcbFaultInvestigationNeeded = 'FaultSensorPcbFaultInvestigationNeeded',

  FaultAutoZeroNotInCleanAir = 'FaultAutoZeroNotInCleanAir',
  FaultCalibrationError = 'FaultCalibrationError',

  FaultPcbFaultReplacementNeeded = 'FaultPcbFaultReplacementNeeded',
  FaultLowBattery = 'FaultLowBattery',
  FaultPumpError = 'FaultPumpError',
  FaultManufacturingCheck = 'FaultManufacturingCheck',
  FaultEventLogError = 'FaultEventLogError',
  FaultInstrumentRestartedByWatchdog = 'FaultInstrumentRestartedByWatchdog',
  FaultImoduleChanged = 'FaultImoduleChanged',
  FaultLifeExpired = 'FaultLifeExpired',
  FaultDataLogError = 'FaultDataLogError',
  FaultInvalidTimeAndDate = 'FaultInvalidTimeAndDate',
  FaultCalibrationOverdue = 'FaultCalibrationOverdue',
  FaultBumpFailed = 'FaultBumpFailed',
  FaultLastSwitchOffDueToAssert = 'FaultLastSwitchOffDueToAssert',
  FaultTimeAndDateLost = 'FaultTimeAndDateLost',
  FaultGasLogError = 'FaultGasLogError',
  FaultCalibrationOverdueLocked = 'FaultCalibrationOverdueLocked',
  FaultChargerInAndInstrumentOnForTooLong = 'FaultChargerInAndInstrumentOnForTooLong',
  FaultBumpOverdue = 'FaultBumpOverdue',
  FaultCalibrationDue = 'FaultCalibrationDue',
  FaultSensorsCoveredByFlowPlate = 'FaultSensorsCoveredByFlowPlate',
  FaultPecAbortedByRemovingFlowPlate = 'FaultPecAbortedByRemovingFlowPlate',
  FaultUnknown = 'FaultUnknown',
  FaultO2SensorUnbiased = 'FaultO2SensorUnbiased',
  Other = 'Other',
}

export enum OperatorStatusTypes {
  Active = 'Active',
  Inactive = 'Inactive',
}

export enum OperatorDeviceCountTypes {
  All = 'All',
  NoDevice = 'No Device',
  OneDevice = 'One Device',
  MultipleDevices = 'Multiple Devices',
  InUse = 'Operator with more than one devices',
  NotInUse = 'Operator with zero devices',
}

// export enum StatusValues {
//   Active = 5,
//   InActive = 10,
//   Faulty = 15,
//   Stolen = 20,
//   Deleted = 25,
// }

export enum DeviceAssignmentStatus {
  Assigned = 5,
  UnAssigned = 10,
}

export enum SelectOptionTypes {
  All = 'All',
}

export enum DeviceUserAssignedFilterValues {
  AllAssignedUsers = 'All Assigned Users',
  NoAssignedUsers = 'No Assigned Users',
}

export enum DeviceSyncFilterValues {
  AllSynced = 'All Synced',
  NotSynced = 'Not Synced',
}

export enum DateRangeSelectorTypes {
  Today = 'Today',
  Yesterday = 'Yesterday',
  Last7Days = 'Last 7 days',
  Last30Days = 'Last 30 days',
  Last60Days = 'Last 60 days',
  Last90Days = 'Last 90 days',
  Custom = 'Custom',
  All = 'All',
}

export enum GasType {
  O2 = 'O2',
  CO = 'CO',
  H2S = 'H2S',
  FLAM = 'FLAM',
  CO2 = 'CO2',
  Other = 'Other',
}

export enum AssignedType {
  Assigned = 'Assigned',
  UnAssigned = 'Not-assigned',
}

export enum SyncedType {
  Synced = 'Synced',
  NotSynced = 'Not-synced',
}

export enum ComplianceType {
  Assigned = AssignedType.Assigned,
  UnAssigned = AssignedType.UnAssigned,
  Synced = SyncedType.Synced,
  NotSynced = SyncedType.NotSynced,
}

export enum DeviceCalDueTypes {
  Overdue = 'Overdue',
  DueIn7Days = 'Due in 7d',
  DueIn30Days = 'Due in 30d',
  DueIn60Days = 'Due in 60d',
  DueIn90Days = 'Due in 90d',
  DueOver90Days = 'Due over 90d',
  NotAvailable = 'NA',
}

export enum AlarmTypes {
  Alarm1 = 'Alarm1',
  Alarm2 = 'Alarm2',
  Nearmiss = 'Near Miss',
}
export enum AlarmEdgeTypes {
  Rising = 'Rising',
  Falling = 'Falling',
}

export enum UserScopeType {
  Global = 'GlobalScope',
  Customer = 'BusinessUnitScope',
}

export enum UserRoleTypes {
  SuperUser = 'Super User',
  CustomerAdmin = 'Customer Admin',
  Admin = 'Admin',
  DeveloperAdmin = 'Developer Admin',
  User = 'User',
  CRTAdmin = 'CRT Admin',
  CRTUser = 'CRT User',
  Marketing = 'Marketing',
  Reader = 'Reader',
}

export enum UserPermissionTypes {
  PortalAccess = 'portal.access',
  DeviceRead = 'device.read',
  DeviceReadAll = 'device.read.all',
  DeviceWrite = 'device.write',
  DeviceWriteAll = 'device.write.all',
  DeviceDelete = 'device.delete',
  DeviceAssignmentWrite = 'deviceAssignment.write',
  DeviceLogsRead = 'deviceLogs.read',
  DeviceLogsWrite = 'deviceLogs.write',
  OperatorReadAll = 'operator.read.all',
  OperatorWriteAll = 'operator.write.all',
  OperatorDelete = 'operator.delete',
  BusinessUnitRead = 'bu.read',
  BusinessUnitWrite = 'bu.write',
  BusinessUnitDelete = 'bu.delete',
  UserReadAll = 'user.read.all',
  UserWriteAll = 'user.write.all',
  UserDelete = 'user.delete',
  CustomerReadAll = 'customer.read.all',
  CustomerWriteSelf = 'customer.write.self',
  CustomerWriteAll = 'customer.write.all',
  CustomerApprove = 'customer.approve',
  RoleReadAll = 'role.read.all',
  RoleWrite = 'role.write',
  RoleWriteSelf = 'role.write.self',
  RoleDelete = 'role.delete',
  PermissionsReadAll = 'permissions.read.all',
  PermissionsWrite = 'permissions.write',
  PermissionsDelete = 'permissions.delete',
  BillingPlanReadAll = 'billingPlan.read.all',
  BillingPlanWrite = 'billingPlan.write',
  BillingPlanDelete = 'billingPlan.delete',
  FeatureRead = 'feature.read',
  FeatureWrite = 'feature.write',
  DeviceTypeWrite = 'devicetypes.write',
  MasterDataReadAll = 'masterdata.read.all',
  MasterDataWriteAll = 'masterdata.write.all',
  StatusActiveReadWrite = 'status.active.readwrite',
}

export enum UploadLogType {
  EventLog = 'EventLog',
  CalRecords = 'CalibrationRecords',
  DataLog = 'DataLog',
}

export enum UploadClientType {
  PCApp = 'PcApp',
  MobileApp = 'MobileApp',
  PortableService = 'PortableService',
}

export enum BumpStatusTypes {
  BumpTestPassed = 'BumpTestPassed',
  BumpTestFailed = 'BumpTestFailed',
  BumpTestNotPerformed = 'BumpTestNotPerformed',
}

export enum GasUnitOfMeasurement {
  PercentVol = '%VOL',
  PPM = 'PPM',
  PercentLEL = '%LEL',
}

export enum DeviceDetailPageTabs {
  EventLog = 'EventLog',
  GasLog = 'GasLog',
  AssignmentHistory = 'AssignmentHistory',
  FaultsLog = 'FaultsLog',
  SyncHistory = 'SyncHistory',
  NearMiss = 'NearMiss',
  BumpHistory = 'BumpHistory',
}

export enum APIResponseTypes {
  NoContent = 'NoContent',
}

const DemoPlanConstant = 'Demo Plan'
const LivePlanConstant = 'Live Plan'

export const BillingPlanEnums: {
  [key: string]: string
} = {
  DemoPlan: DemoPlanConstant,
  LivePlan: LivePlanConstant,
}

export const CrowconGlobalId = '00000000-0000-0000-0000-000000000000'
export const CrowconGlobalName = 'Crowcon Global'
export const CrowconScopeValue = 1
export const CustomerScopeValue = 2
export const CrowconScope = 'CrowconScope'
export const CustomerScope = 'CustomerScope'
