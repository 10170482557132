import React, { useContext } from 'react'
import { FormattedDate } from 'react-intl'
import clsx from 'clsx'
import DeviceImage from 'components/viewComponents/DeviceImage'
import CrowconButton from 'components/miscComponents/CrowconButton'
import { PortableTypes } from 'utils/CommonEnums'
import { UserAccountContext } from 'contexts/UserAccountContext'
import { IsUserGlobalScope } from 'utils/UserDataUtils'
import Tooltip from 'components/atom/Tooltip'
import { CreateDeviceFormType } from '../CreateDeviceFormType'

interface DeviceSummaryProps {
  deviceInfo: CreateDeviceFormType
}

const getPortableType = (submittedType?: string) => {
  if (submittedType === PortableTypes.GasPro) return PortableTypes.GasPro
  if (submittedType === PortableTypes.Gasman) return PortableTypes.Gasman
  if (submittedType === PortableTypes.T3) return PortableTypes.T3
  if (submittedType === PortableTypes.T4) return PortableTypes.T4
  return undefined
}

export function DeviceSummary(props: DeviceSummaryProps) {
  const { deviceInfo } = props
  const userAccount = useContext(UserAccountContext)
  const { state: userInfo } = userAccount
  const isGlobalUser = IsUserGlobalScope(userInfo)

  return (
    <div className='flex flex-row pt-5 '>
      <div className='w-36 h-full -mr-5 -ml-10 pt-1'>
        <DeviceImage deviceType={getPortableType(deviceInfo?.DeviceType?.labelText)} />
      </div>
      <div className='flex gap-1 flex-col w-60 text-xs'>
        <div className='border-b-2 min-h-7 py-1.5 flex flex-row'>
          <div className='w-1/2 '>Serial number</div>
          <Tooltip
            className='w-1/2 font-medium text-c-blue relative '
            tooltipText={deviceInfo?.SerialNumber}
            showOnlyWhenTextIsTruncate
            id={`create-device-summary-${deviceInfo?.SerialNumber}`}
            toolTipClass='tooltip -top-8 -left-1/2 transform translate-x-1/2 w-fit px-2'
          >
            <p className='text-ellipsis'>{deviceInfo?.SerialNumber}</p>
          </Tooltip>
        </div>

        <div className='border-b-2 min-h-7 py-1.5 flex flex-row'>
          <div className='w-1/2'>Device type</div>
          <Tooltip
            tooltipText={deviceInfo?.DeviceType?.labelText}
            showOnlyWhenTextIsTruncate
            id={`create-device-summary-${deviceInfo?.DeviceType?.labelText}`}
            toolTipClass='tooltip -top-8 -left-1/2 transform translate-x-1/2 w-fit px-2'
            className='w-1/2 font-medium text-c-blue relative'
          >
            <p className='text-ellipsis'>{deviceInfo?.DeviceType?.labelText}</p>
          </Tooltip>
        </div>

        <div className='border-b-2 min-h-7 py-1.5 flex flex-row'>
          <div className='w-1/2'>Asset number</div>
          <Tooltip
            tooltipText={deviceInfo?.AssetNumber}
            showOnlyWhenTextIsTruncate
            id={`create-device-summary-${deviceInfo?.AssetNumber}`}
            toolTipClass='tooltip -top-8 -left-1/2 transform translate-x-1/2 w-fit px-2'
            className='w-1/2 font-medium text-c-blue relative'
          >
            <p className='text-ellipsis'>{deviceInfo?.AssetNumber}</p>
          </Tooltip>
        </div>

        {isGlobalUser && (
          <div className='border-b-2 min-h-7 py-1.5 flex flex-row'>
            <div className='w-1/2 '>Customer</div>
            <Tooltip
              tooltipText={deviceInfo?.Customer?.name}
              showOnlyWhenTextIsTruncate
              id={`create-device-summary-${deviceInfo?.Customer?.name}`}
              toolTipClass='tooltip -top-8 -left-1/2 transform translate-x-1/2 w-fit px-2'
              className='w-1/2 font-medium text-c-blue relative'
            >
              <p className='text-ellipsis'>{deviceInfo?.Customer?.name}</p>
            </Tooltip>
          </div>
        )}

        <div className='border-b-2 min-h-7 py-1.5 flex flex-row'>
          <div className='w-1/2'>Business unit</div>
          <Tooltip
            tooltipText={deviceInfo?.BusinessUnit?.name}
            shouldShow
            id={`create-device-summary-${deviceInfo?.BusinessUnit?.name}`}
            toolTipClass='tooltip z-50 -top-8 -left-1/2 transform translate-x-1/2 w-fit px-2'
            className='w-1/2 font-medium text-c-blue relative '
          >
            <p className='text-ellipsis'>{deviceInfo?.BusinessUnit?.name}</p>
          </Tooltip>
        </div>

        <div className='border-b-2 min-h-7 py-1.5 flex flex-row'>
          <div className='w-1/2'>Assigned to</div>
          <Tooltip
            tooltipText={
              deviceInfo?.DeviceUser &&
              `${deviceInfo?.DeviceUser?.firstName} ${deviceInfo?.DeviceUser?.lastName}`
            }
            showOnlyWhenTextIsTruncate
            id={`create-device-summary-${
              deviceInfo?.DeviceUser &&
              `${deviceInfo?.DeviceUser?.firstName} ${deviceInfo?.DeviceUser?.lastName}`
            }`}
            toolTipClass='tooltip -top-8 -left-1/2 transform translate-x-1/2 w-fit px-2'
            className='w-1/2 font-medium text-c-blue relative'
          >
            <p className='text-ellipsis'>
              {deviceInfo?.DeviceUser &&
                `${deviceInfo?.DeviceUser?.firstName} ${deviceInfo?.DeviceUser?.lastName}`}
            </p>
          </Tooltip>
        </div>

        <div className='border-b-2 min-h-7 py-1.5 flex flex-row'>
          <div className='w-1/2'>Service date</div>
          <div className='w-1/2 font-medium text-c-blue'>
            {deviceInfo?.LastCalibratedOn && (
              <FormattedDate
                year='numeric'
                month='short'
                day='2-digit'
                value={deviceInfo?.LastCalibratedOn}
              />
            )}
          </div>
        </div>

        <div className='border-b-2 min-h-7 py-1.5 flex flex-row'>
          <div className='w-1/2'>Calibration date</div>
          <div className='w-1/2 font-medium text-c-blue'>
            {deviceInfo?.CalibrationDue && (
              <FormattedDate
                year='numeric'
                month='short'
                day='2-digit'
                value={deviceInfo?.CalibrationDue}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
