import { DateRange, IFilterQueryParamValues, IselectOptionProps } from 'forms/FormModelInterface'

export const ServiceFaultsColumnIds = {
  deviceType: 'deviceType',
  businessUnit: 'businessUnit',
  faultType: 'eventType',
  faultDescription: 'eventDescription',
  faultDateTime: 'timeStamp',
  faultDateSelector: 'faultDateSelector',
  assignedTo: 'assignedTo',
  faultTypeUntranslated: 'eventTypeUntranslated',
  deviceUserName: 'deviceUserName',
  customerAssetNumber: 'customerAssetNumber',
  deviceSerialNumber: 'deviceSerialNumber',
}

export const ServiceFaultsFilterParamLabels = {
  faultType: 'ftyp',
  dateRangeSelector: 'fsel',
  dateRangeFrom: 'from',
  dateRangeTo: 'to',
  filterDateFrom: 'fdatefrom',
  filterDateTo: 'fdateto',
  businessUnit: 'cbu',
  deviceType: 'dtype',
  faultDescription: 'fdesc',
  deviceUserName: 'deviceUserName',
  customerAssetNumber: 'customerAssetNumber',
  deviceSerialNumber: 'deviceSerialNumber',
}

export const ServiceFaultsFilterParamValues: IFilterQueryParamValues[] = [
  { paramName: ServiceFaultsFilterParamLabels.deviceSerialNumber, paramValue: null },
  { paramName: ServiceFaultsFilterParamLabels.faultType, paramValue: null },
  { paramName: ServiceFaultsFilterParamLabels.dateRangeSelector, paramValue: null },
  { paramName: ServiceFaultsFilterParamLabels.dateRangeFrom, paramValue: null },
  { paramName: ServiceFaultsFilterParamLabels.dateRangeTo, paramValue: null },
  { paramName: ServiceFaultsFilterParamLabels.filterDateFrom, paramValue: null },
  { paramName: ServiceFaultsFilterParamLabels.filterDateTo, paramValue: null },
  { paramName: ServiceFaultsFilterParamLabels.businessUnit, paramValue: null },
  { paramName: ServiceFaultsFilterParamLabels.deviceType, paramValue: null },
  { paramName: ServiceFaultsFilterParamLabels.faultDescription, paramValue: null },
  { paramName: ServiceFaultsFilterParamLabels.deviceUserName, paramValue: null },
  { paramName: ServiceFaultsFilterParamLabels.customerAssetNumber, paramValue: null },
  { paramName: ServiceFaultsFilterParamLabels.deviceSerialNumber, paramValue: null },
]

export type ServiceFaultsFilterFormType = {
  faultType: IselectOptionProps
  businessUnit: IselectOptionProps
  deviceType: IselectOptionProps
  faultDescription: IselectOptionProps
  faultDateRangeSelector: IselectOptionProps
  deviceUserName: IselectOptionProps
  customerAssetNumber: IselectOptionProps
  deviceSerialNumber: IselectOptionProps
  faultDateRange: DateRange
}
