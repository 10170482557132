import { useMsal } from '@azure/msal-react'
import {
  BillingPlanIcon,
  BusinessUnitIcon,
  CustomerIcon,
  OrganizationIcon,
  UserIcon,
  UsersIcon,
} from 'assets/icons'
import clsx from 'clsx'
import Tooltip from 'components/atom/Tooltip'
import BusinessAndCustomerSelection from 'components/modules/BusinessAndCustomerSelection'
import { UserAccountContext } from 'contexts/UserAccountContext'
import React, { useContext } from 'react'
import { NavLink, Outlet, useLocation } from 'react-router-dom'
import { SignInAuthority } from 'utils/AuthConfig'
import { UserRoleTypes } from 'utils/CommonEnums'
import { isDemoMode } from 'utils/CommonUtils'
import { IsUserGlobalScope } from 'utils/UserDataUtils'

const urls = [
  {
    icon: (activeClass: string) => <UserIcon className={` ${activeClass}`} />,
    title: 'Account',
    url: 'account',
  },
  {
    icon: (activeClass: string) => <OrganizationIcon className={` ${activeClass}`} />,
    title: 'Organisation',
    url: 'organisation-structure',
  },
  {
    icon: (activeClass: string) => <BusinessUnitIcon className={` ${activeClass}`} />,
    title: 'Business Units',
    url: 'business-units',
  },

  // {
  //   icon: (activeClass: string) => (
  //     <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' className={` ${activeClass}`}>
  //       <path
  //         strokeLinecap='round'
  //         strokeLinejoin='round'
  //         d='M15.75 5.25a3 3 0 0 1 3 3m3 0a6 6 0 0 1-7.029 5.912c-.563-.097-1.159.026-1.563.43L10.5 17.25H8.25v2.25H6v2.25H2.25v-2.818c0-.597.237-1.17.659-1.591l6.499-6.499c.404-.404.527-1 .43-1.563A6 6 0 1 1 21.75 8.25Z'
  //       />
  //     </svg>
  //   ),
  //   title: 'Api keys',
  //   url: 'api-keys',
  // },
]
const teams = [
  {
    icon: (activeClass: string) => <UsersIcon className={` ${activeClass}`} />,
    title: 'Users',
    url: 'teams',
  },
]
const globalUrls = [
  {
    icon: (activeClass: string) => <CustomerIcon className={` ${activeClass}`} />,
    title: 'Customer',
    url: 'customers',
  },
  {
    icon: (activeClass: string) => <BillingPlanIcon className={` ${activeClass}`} />,
    title: 'Billing Plans',
    url: 'billing-plan',
  },
]
export default function UserSetting() {
  const location = useLocation()
  const activeTab = location.pathname.split('/').reverse()[0]
  const { instance } = useMsal()
  const { state: userInfo } = useContext(UserAccountContext)
  const isGlobalUser = IsUserGlobalScope(userInfo)
  const signOutUser = () => {
    instance.logoutRedirect({
      authority: SignInAuthority,
      postLogoutRedirectUri: '/',
      onRedirectNavigate(url) {
        localStorage.removeItem('demoAccount')
        localStorage.removeItem('userAccount')
        localStorage.removeItem('isDemoMode')
      },
    })
  }
  const isTeamsAccessible =
    userInfo.authority[0].roleName === UserRoleTypes.CustomerAdmin.split(' ').join('') &&
    !isDemoMode()

  const actualUrls = [
    ...urls,
    ...(isGlobalUser || isTeamsAccessible ? teams : []),
    ...(!isGlobalUser ? [] : globalUrls),
  ]
  const tabWithNoUrl = activeTab === 'profile' || activeTab === ''

  const whereShouldBusinessUnitVisible = actualUrls.filter((url) => url.url !== 'teams')
  const whereShouldCustomerVisible = actualUrls.filter(
    (url) => url.url !== 'billing-plan' && url.url !== 'account' && url.url !== 'customers',
  )
  return (
    <>
      <div className=' w-full flex flex-row  items-center bg-c-light-blue-4 sticky top-0 right-0  z-20'>
        <div className='w-full'>
          <BusinessAndCustomerSelection
            pageTitle='Settings'
            showBusinessUnitSelector={
              !whereShouldBusinessUnitVisible.find((url) => url.url === activeTab) && !tabWithNoUrl
            }
            showCustomerSelector={
              isGlobalUser &&
              Boolean(whereShouldCustomerVisible.find((url) => url.url === activeTab)) &&
              !tabWithNoUrl
            }
          />
        </div>
        <div className='py-4 mr-2 lg:p-4'>
          <button
            className='text-2xs md:text-base md:leading-5 text-c-white  font-bold  rounded-4xl w-20 h-8 md:w-32 md:h-12 cursor-pointer  bg-c-red-1 outline-0 hover:bg-c-red-2 active:bg-c-red-dark focus:border-4  border-c-red-dark'
            type='button'
            onClick={signOutUser}
          >
            Sign Out
          </button>
        </div>
      </div>

      <div className='flex flex-row  '>
        <div className='flex flex-col bg-c-light-blue-4 border-r-4  border-c-light-blue-1 '>
          {actualUrls.map((url, i) => {
            // const indexOfActiveTab = actualUrls.findIndex((path) => path.url === activeTab)

            // const indexInRange = indexOfActiveTab === -1 ? 99 : indexOfActiveTab + 1
            const isTabActive = url.url === activeTab
            return (
              <Tooltip
                tooltipText={url.title}
                toolTipClass='tooltip block lg:hidden w-[100px] text-center m-auto -left-1 -top-8'
                key={url.title}
                className='relative  '
              >
                <NavLink
                  to={url.url}
                  className={clsx(
                    {
                      // ' rounded-br-none': indexOfActiveTab - 1 === i || (tabWithNoUrl && i === 0),
                      // ' rounded-tr-none': indexInRange === i || (tabWithNoUrl && i === 1),
                      ' bg-c-light-blue-1 border-c-blue border-l-4 text-c-blue ':
                        isTabActive || (tabWithNoUrl && i === 0),
                      'bg-c-light-blue-4 hover:bg-c-blue hover:text-white':
                        !isTabActive && !(tabWithNoUrl && i === 0),
                    },
                    'w-full lg:w-[190px] py-3 pl-3 lg:pl-5 pr-3  lg:pr-10 text-xs font-bold  flex flex-row gap-5 transition-all duration-200  ease-in group/item ',
                  )}
                >
                  <span>
                    {url.icon(
                      isTabActive || (tabWithNoUrl && i === 0)
                        ? 'w-4 h-4 stroke-c-blue stroke-2 fill-transparent'
                        : 'w-4 h-4 stroke-black stroke-2 fill-transparent group-hover/item:stroke-c-white stroke-2 fill-transparent',
                    )}
                  </span>
                  <span className='hidden lg:block '>{url.title}</span>
                </NavLink>
              </Tooltip>
            )
          })}
          {/* <div
            className={clsx(
              // {
              //   'rounded-tr-none': activeTab === actualUrls[actualUrls.length - 1].url,
              // },
              ' bg-c-light-blue-4 h-full',
            )}
          /> */}
        </div>
        <div className='w-full overflow-x-auto h-[calc(100vh-80px)] '>
          <Outlet />
        </div>
      </div>
    </>
  )
}
