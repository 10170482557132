import React, { useEffect } from 'react'
import { useIsAuthenticated, useMsal } from '@azure/msal-react'
import { useQueryClient } from '@tanstack/react-query'
import { OperatorsPageDataLoader } from 'data/OperatorListHook'
import { LayoutContainer } from 'layout/container/LayoutContainer'
import { CalibrationsPage } from 'pages/calibration'
import { CalibrationPageDataLoader } from 'pages/calibration/data/CalibrationPageData'
import ErrorPage from 'pages/common/ErrorPage'
import NotFound from 'pages/common/NotFound'
import { Dashboard } from 'pages/dashboard'
import { DeviceAssignmentHistoryLoader } from 'pages/deviceDetails/childPages/assignmentHistory/data/DeviceAssignmentHistoryData'
import DeviceAssignmentHistory from 'pages/deviceDetails/childPages/assignmentHistory/view/DeviceAssignmentHistory'
import BumpHistory from 'pages/deviceDetails/childPages/bumpHistory/view/BumpHistory'
import { DeviceEventLogsLoader } from 'pages/deviceDetails/childPages/eventLogs/data/DeviceEventLogsData'
import DeviceEventLogs from 'pages/deviceDetails/childPages/eventLogs/view/DeviceEventLogs'
import DeviceFaults from 'pages/deviceDetails/childPages/faults/view/DeviceFaults'
import DeviceGasLogs from 'pages/deviceDetails/childPages/gasLogs/view/DeviceGasLogs'
import DeviceNearMiss from 'pages/deviceDetails/childPages/nearMiss/view/DeviceNearMiss'
import DeviceSyncHistory from 'pages/deviceDetails/childPages/syncHistory/view/DeviceSyncHistory'
import DeviceDetailsRoot from 'pages/deviceDetails/rootPage/view/DeviceDetailsRoot'
import { DevicePageDataLoader } from 'pages/devices-2/data/DevicePageData'
import DevicesPage from 'pages/devices-2/view/DevicesPage'
import Help from 'pages/help/Help'
import { HelpPageDataLoader } from 'pages/help/data/HelpPageData'
import { OperatorListPage } from 'pages/operators'
import RootPage from 'pages/root/Root'
import { SafetyListPage } from 'pages/safety'
import { SafetyPageDataLoader } from 'pages/safety/data/SafetyListData'
import { FaultsDataLoader } from 'pages/service-faults/data/FaultsData'
import ServiceFaultsPage from 'pages/service-faults/view/ServiceFaultsPage'
import SignUpPage from 'pages/susi/view/SignUpPage'
import SignUpWelcomePage from 'pages/susi/view/SignUpWelcomePage'
import { SusiLayoutContainer } from 'pages/susi/view/SusiLayoutContainer'
import UserSettings from 'pages/userSettings/UserSettings'
import { UserSettingsPageDataLoader } from 'pages/userSettings/data/UserSettingsPageData'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import { UsersListPage } from 'pages/users'
import BusinessUnitTree from 'pages/userSettings/Organization/Structure/BusinessUnitTree'
import UserSetting from 'pages/userSettings'
import { CustomerListPage } from 'pages/customer'
import { BillingPlans } from 'pages/billing-plans'
import OrganizationTree from 'pages/userSettings/Organization/Hierarchy/OrganizationTree'
import LiveReadings from 'pages/deviceDetails/childPages/liveReadings'
import LiveCalibrationReport from 'pages/deviceDetails/childPages/live-calibration-report'
import { useFeatureFlag } from 'hooks'
import { FeatureFlags } from 'utils/FeatureFlags'

function Fallback() {
  return (
    <div className='animate-pulse absolute right-1/2 bottom-1/2  transform translate-x-1/2 translate-y-1/2 '>
      <div className='h-8 w-8 animate-spin rounded-full border-4 border-solid border-c-orange border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]' />
    </div>
  )
}

// interface LayoutWrapperProps {
//   queryClient: QueryClient
// }

function LayoutWrapper() {
  const queryClient = useQueryClient()
  const { inProgress: msalInProgress, accounts: msalAccounts, instance: msalInstance } = useMsal()
  const isCalibrationReportFeature = useFeatureFlag(FeatureFlags.CalibrationReport)
  const isLiveReadingsFeature = useFeatureFlag(FeatureFlags.LiveReadings)

  const AppRouter = createBrowserRouter([
    {
      path: '/',
      element: <RootPage />,
    },
    {
      path: 'web/signup',
      element: <SusiLayoutContainer />,
      children: [
        {
          path: 'invite/:inviteId',
          element: <SignUpPage />,
        },
        {
          path: 'done',
          element: <SignUpWelcomePage />,
        },
        {
          path: '*',
          element: <NotFound />,
        },
      ],
    },
    {
      path: 'web/*',
      element: <LayoutContainer />,
      errorElement: <ErrorPage />,
      children: [
        {
          errorElement: <ErrorPage />,
          children: [
            {
              element: <Dashboard />,
              errorElement: <ErrorPage />,
              index: true,
              // loader: DashboardDataLoader({
              //   msalInProgress,
              //   msalAccounts,
              //   msalInstance,
              //   queryClient,
              // }),
            },
            {
              path: 'dashboard',
              element: <Dashboard />,
              errorElement: <ErrorPage />,
              // loader: DashboardDataLoader({
              //   msalInProgress,
              //   msalAccounts,
              //   msalInstance,
              //   queryClient,
              // }),
            },
            {
              path: 'devices',
              children: [
                {
                  path: ':deviceSerialNumber',
                  element: <DeviceDetailsRoot />,
                  errorElement: <ErrorPage />,
                  //  loader: DeviceDetailsLoader(queryClient),
                  children: [
                    {
                      element: <DeviceEventLogs />,
                      errorElement: <ErrorPage />,
                      loader: DeviceEventLogsLoader({
                        msalInProgress,
                        msalAccounts,
                        msalInstance,
                        queryClient,
                      }),
                      index: true,
                    },
                    {
                      path: 'eventlog',
                      element: <DeviceEventLogs />,
                      errorElement: <ErrorPage />,
                      loader: DeviceEventLogsLoader({
                        msalInProgress,
                        msalAccounts,
                        msalInstance,
                        queryClient,
                      }),
                    },
                    {
                      path: 'gaslog',
                      element: <DeviceGasLogs />,
                      // loader: DeviceGasLogsLoader(queryClient),
                      errorElement: <ErrorPage />,
                    },
                    {
                      path: 'assignment-history',
                      element: <DeviceAssignmentHistory />,
                      loader: DeviceAssignmentHistoryLoader({
                        msalInProgress,
                        msalAccounts,
                        msalInstance,
                        queryClient,
                      }),
                      errorElement: <ErrorPage />,
                    },
                    {
                      path: 'service',
                      element: <DeviceFaults />,
                      errorElement: <ErrorPage />,
                    },
                    {
                      path: 'sync-history',
                      element: <DeviceSyncHistory />,
                      errorElement: <ErrorPage />,
                    },
                    {
                      path: 'near-miss',
                      element: <DeviceNearMiss />,
                      errorElement: <ErrorPage />,
                    },
                    {
                      path: 'bump-history',
                      element: <BumpHistory />,
                    },
                    {
                      path: 'live-readings',
                      element: <LiveReadings />,
                    },
                    {
                      path: 'live-calibration-report',
                      element: <LiveCalibrationReport />,
                    },
                    {
                      path: '*', // 404 page
                      element: <NotFound />,
                    },
                  ].filter((deviceRoutes) => {
                    if (
                      !isCalibrationReportFeature &&
                      deviceRoutes.path === 'live-calibration-report'
                    ) {
                      return false
                    }
                    if (!isLiveReadingsFeature && deviceRoutes.path === 'live-readings') {
                      return false
                    }
                    return true
                  }),
                },

                {
                  index: true,
                  element: <DevicesPage />,
                  loader: DevicePageDataLoader({
                    msalInProgress,
                    msalAccounts,
                    msalInstance,
                    queryClient,
                  }),
                },
              ],
            },
            {
              path: 'calibration',
              element: <CalibrationsPage />,
              errorElement: <ErrorPage />,
              loader: CalibrationPageDataLoader({
                msalInProgress,
                msalAccounts,
                msalInstance,
                queryClient,
              }),
            },
            {
              path: 'service',
              element: <ServiceFaultsPage />,
              errorElement: <ErrorPage />,
              loader: FaultsDataLoader({ msalInProgress, msalAccounts, msalInstance, queryClient }),
            },
            {
              path: 'safety',
              element: <SafetyListPage />,
              loader: SafetyPageDataLoader({
                msalInProgress,
                msalAccounts,
                msalInstance,
                queryClient,
              }),
              errorElement: <ErrorPage />,
            },

            {
              path: 'operators',
              element: <OperatorListPage />,
              errorElement: <div>Oops! There was an error.</div>,
              loader: OperatorsPageDataLoader({
                msalInProgress,
                msalAccounts,
                msalInstance,
                queryClient,
              }),
            },
            // {
            //   path: 'org/info',
            //   element: <div> Organization Settings page</div>,
            //   errorElement: <ErrorPage />,
            // },
            {
              path: 'help',
              element: <Help />,
              errorElement: <ErrorPage />,
              loader: HelpPageDataLoader({
                msalInProgress,
                msalAccounts,
                msalInstance,
                queryClient,
              }),
            },
            {
              path: 'profile',
              element: <UserSetting />,
              errorElement: <ErrorPage />,
              children: [
                {
                  path: '',
                  element: <UserSettings />,
                  errorElement: <ErrorPage />,
                  loader: UserSettingsPageDataLoader({
                    msalInProgress,
                    msalAccounts,
                    msalInstance,
                    queryClient,
                  }),
                },
                {
                  path: 'account',
                  errorElement: <ErrorPage />,
                  element: <UserSettings />,
                  loader: UserSettingsPageDataLoader({
                    msalInProgress,
                    msalAccounts,
                    msalInstance,
                    queryClient,
                  }),
                },
                {
                  path: 'organisation-structure',
                  element: <OrganizationTree />,
                  errorElement: <ErrorPage />,
                },
                {
                  errorElement: <ErrorPage />,
                  path: 'business-units',
                  element: <BusinessUnitTree />,
                },

                {
                  path: 'teams',
                  element: <UsersListPage />,
                  errorElement: <ErrorPage />,
                },
                {
                  path: 'customers',
                  element: <CustomerListPage />,
                  errorElement: <ErrorPage />,
                },
                {
                  path: 'billing-plan',
                  element: <BillingPlans />,
                  errorElement: <ErrorPage />,
                },
                {
                  path: '*',
                  element: <NotFound />,
                },
              ],
            },
            {
              path: '*',
              element: <NotFound />,
            },

            {
              path: 'login',
              element: <div>Login page</div>,
            },

            {
              path: 'signup/invite/:inviteId',
              element: <SignUpPage />,
            },
          ],
        },
      ],
    },
  ])

  const isAuthenticated = useIsAuthenticated()
  useEffect(() => {
    if (isAuthenticated) {
      // call userinfo endpoint
      // update user context
      console.log('Authenticated')
      const activeAccount = msalInstance.getActiveAccount()
      const allAccounts = msalInstance.getAllAccounts()
      if (!activeAccount && allAccounts.length > 0) {
        msalInstance.setActiveAccount(msalAccounts[0])
      }
    }
  }, [isAuthenticated, msalInstance, msalAccounts])

  // const {
  //   data: userInfo,
  //   isLoading: userInfoLoading,
  //   isFetching: userInfoFetching,
  //   isError: userInfoError,
  //   error: userInfoErrorData,
  // } = useUserInfo({
  //   redirectPageURL: window.location.href,
  //   enabled: isAuthenticated && msalInProgress === InteractionStatus.None,
  // })
  // useEffect(() => {
  //   if (userInfo) {
  //     console.log('userInfo', userInfo)
  //     const account = msalInstance.getActiveAccount()
  //     const policyName =
  //       (account?.idTokenClaims?.tfp as string) || (account?.idTokenClaims?.acr as string)

  //     const fullyQualifiedPolicyName = ADB2CPolicies.find(
  //       (policy) => policy.name === policyName.toUpperCase(),
  //     )?.authority

  //     const userAccountState: iUserAccountState = {
  //       ...userInfo,
  //       adb2cPolicy: fullyQualifiedPolicyName || SignInAuthority,
  //     }

  //     dispatch({ type: UserAccountContextActions.SetUserAccount, payload: { userAccountState } })
  //   }
  //   // else if (userInfoError && userInfoErrorData) {
  //   //   console.log('userInfoError', userInfoErrorData)
  //   //   const axiosError = userInfoErrorData as AxiosError
  //   //   const problemDetails = axiosError.response?.data as iCrowconProblemDetails
  //   //   const errorMessage = parseAPIError(problemDetails)
  //   //   setUserError(true)
  //   //   setUserErrorMessage(errorMessage)
  //   //   console.log('errorMessage', errorMessage)
  //   // }
  // }, [userInfo, userInfoError, userInfoErrorData])

  return (
    <RouterProvider router={AppRouter} fallbackElement={<Fallback />} />

    // <div>
    //   {userInfoLoading && userInfoFetching && !userInfoError ? (
    //     <div>Authenticating...</div>
    //   ) : userInfoError ? (
    //     <div>Error: {userErrorMessage}</div>
    //   ) : (
    //     <RouterProvider router={AppRouter} fallbackElement={<Fallback />} />
    //   )}
    // </div>
  )
}
export default LayoutWrapper
