import React, { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { EditIcon } from 'assets/icons'
import { iOperatorInfo } from 'data/Interfaces'
import withRoleChecking from 'hoc/withRoleChecking'
import { UserRoleTypes } from 'utils/CommonEnums'
import EditOperator from './EditOperator'

export interface iOperatorPageTableRowActionsProps {
  rowData: iOperatorInfo
}
function OperatorRowActions(actionProps: iOperatorPageTableRowActionsProps): JSX.Element {
  const { rowData } = actionProps
  const [isOpenEditForm, setIsOpenEditForm] = useState<boolean>(false)

  // function closeModal() {
  //   setIsOpenEditForm(false)
  // }

  return (
    <div className='flex flex-row gap-3 justify-end p-1 mr-1 w-24'>
      {/* {isOpenEditForm && <TempInProgressPopup open={isOpenEditForm} />} */}

      <button
        title='Edit Operator'
        type='button'
        tabIndex={0}
        // className='rounded-full w-8 h-8 bg-c-dark-blue-1  p-1.5 hover:cursor-pointer hover:bg-c-dark-blue-2 focus:border-1  focus:border-c-blue focus:bg-c-dark-blue-1 active:bg-c-blue'
        className='rounded-full w-8 h-8 bg-c-dark-blue-1  p-1.5 hover:cursor-pointer hover:bg-c-dark-blue-2  focus:border-c-blue focus:bg-c-dark-blue-1 active:bg-c-blue'
        onClick={() => setIsOpenEditForm(true)}
      >
        <EditIcon />
      </button>

      <Transition appear show={isOpenEditForm} as={Fragment}>
        <Dialog
          as='div'
          className='relative z-50'
          onClose={() => {
            setIsOpenEditForm(false)
          }}
        >
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-black/25 z-l-2' />
          </Transition.Child>

          <div className='fixed inset-0 overflow-y-auto z-l-3'>
            <div className='flex min-h-full items-center justify-center p-4 text-center'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 scale-95'
                enterTo='opacity-100 scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 scale-100'
                leaveTo='opacity-0 scale-95'
              >
                <Dialog.Panel className=''>
                  <EditOperator
                    isOpen={isOpenEditForm}
                    setIsOpened={(val) => setIsOpenEditForm(val)}
                    rowData={rowData}
                  />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  )
}
export const RowActions = withRoleChecking<iOperatorPageTableRowActionsProps>(OperatorRowActions, [
  UserRoleTypes.User,
  UserRoleTypes.CustomerAdmin.split(' ').join(''),
  UserRoleTypes.Admin,
])
