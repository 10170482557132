import React from 'react'
import { SvgProps } from 'types/SVGProps'

function SupportIcon(props: SvgProps): JSX.Element {
  const { stroke, fill, className, toggleClick } = props

  return (
    <div className=''>
      <svg
        className={className}
        width='20'
        height='20'
        viewBox='0 0 20 20'
        fill={fill ?? 'none'}
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M9.91429 15C10.2143 15 10.468 14.8992 10.6754 14.6975C10.8823 14.4964 10.9857 14.25 10.9857 13.9583C10.9857 13.6667 10.8823 13.4203 10.6754 13.2192C10.468 13.0175 10.2143 12.9167 9.91429 12.9167C9.61429 12.9167 9.36057 13.0175 9.15314 13.2192C8.94629 13.4203 8.84286 13.6667 8.84286 13.9583C8.84286 14.25 8.94629 14.4964 9.15314 14.6975C9.36057 14.8992 9.61429 15 9.91429 15ZM9.14286 11.7917H10.7286C10.7286 11.3333 10.7823 10.9722 10.8897 10.7083C10.9966 10.4444 11.3 10.0833 11.8 9.625C12.1714 9.26389 12.4643 8.92 12.6786 8.59333C12.8929 8.26722 13 7.875 13 7.41667C13 6.63889 12.7071 6.04167 12.1214 5.625C11.5357 5.20833 10.8429 5 10.0429 5C9.22857 5 8.568 5.20833 8.06114 5.625C7.55371 6.04167 7.2 6.54167 7 7.125L8.41429 7.66667C8.48571 7.41667 8.64657 7.14583 8.89686 6.85417C9.14657 6.5625 9.52857 6.41667 10.0429 6.41667C10.5 6.41667 10.8429 6.53806 11.0714 6.78083C11.3 7.02417 11.4143 7.29167 11.4143 7.58333C11.4143 7.86111 11.3286 8.12139 11.1571 8.36417C10.9857 8.6075 10.7714 8.83333 10.5143 9.04167C9.88571 9.58333 9.5 9.99306 9.35714 10.2708C9.21429 10.5486 9.14286 11.0556 9.14286 11.7917Z'
          fill={fill ?? '#FFFFFF'}
        />
        <circle cx='10' cy='10' r='9.1' stroke={stroke ?? '#FFFFFF'} strokeWidth='1.8' />
      </svg>
    </div>
  )
}

export default SupportIcon
