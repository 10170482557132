import { azureFunctionEndpoints } from '../endpoints'

export const createDemoDevicesUrl = () => `${azureFunctionEndpoints.createDevices}`

export const createDemoOperatorsUrl = () => `${azureFunctionEndpoints.createOperators}`

export const createDemoEventsLogsUrl = () => `${azureFunctionEndpoints.createEventLogs}`

export const createDemoGasLogsUrl = () => `${azureFunctionEndpoints.createGasLogs}`

export const demoDeviceAssignmentUrl = () => `${azureFunctionEndpoints.deviceAssignments}`

export const demoDeviceConfigurationUrl = () => `${azureFunctionEndpoints.setChannelConfig}`
export const getDemoCustomerUrl = () => `${azureFunctionEndpoints.getDemoCustomer}`

export const createDemoCustomerUrl = () => `${azureFunctionEndpoints.createDemoCustomer}`

export const createDemoBusinessUnitsUrl = () => `${azureFunctionEndpoints.createDemoBusinessUnits}`

export const getDemoBusUrl = (customerId: string) =>
  `${azureFunctionEndpoints.getDemoBus}?customerId=${customerId}`
