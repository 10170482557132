import { useQuery } from '@tanstack/react-query'
import { useMsal } from '@azure/msal-react'
import { sub } from 'date-fns'
import { LoaderFunction, createSearchParams } from 'react-router-dom'
import { UploadClientType } from 'utils/CommonEnums'
import ConnectAPIClient from 'services/ConnectAPIClient'
import { getAccessToken, getAccessTokenFromPCA } from 'services/MSALService'
import { getOrFetchDeviceDetails } from 'data/DeviceDetailsHook'
import {
  iDeviceLogsAPIParams,
  iDeviceLogsDataLoaderParams,
  iGetDeviceLogsParams,
  iGetOrFetchDeviceLogDataProps,
} from 'pages/deviceDetails/commonUtils/interfaces'
import { AxiosError } from 'axios'
import { reportDataQueryKeyIdDates } from 'services/apis/caching/device'
import { deviceNearMissURL } from 'services/apis/urls/device'
import { iNearMissData } from './interface'

async function fetchDeviceNearMissLogs(
  deviceNearMissParams: iDeviceLogsAPIParams,
): Promise<iNearMissData[]> {
  try {
    const { deviceId, startDate = '', endDate = '', token } = deviceNearMissParams

    const urlSearchParams = new URLSearchParams()

    if (startDate) {
      urlSearchParams.set('FromDate', startDate)
    }
    if (endDate) {
      urlSearchParams.set('ToDate', endDate)
    }

    const url = deviceNearMissURL(deviceId, urlSearchParams.toString())

    const resp = await ConnectAPIClient.get<iNearMissData[]>(url, token)

    const response = resp.data as iNearMissData[]

    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}

async function getOrFetchNearMissData(props: iGetOrFetchDeviceLogDataProps) {
  const { queryClient, token, startDate = '', endDate = '', deviceId } = props

  const nearMissDataCached = queryClient.getQueryData<iNearMissData[]>(
    reportDataQueryKeyIdDates({ deviceId, startDate, endDate }),
  )

  if (nearMissDataCached) return nearMissDataCached

  const deviceNearMissParams = {
    deviceId,
    startDate,
    endDate,
    token,
  }

  const nearMissData = await fetchDeviceNearMissLogs(deviceNearMissParams)

  return nearMissData
}

async function getNearMissData(nearMissParams: iGetDeviceLogsParams): Promise<iNearMissData[]> {
  const { startDate, endDate, msalContext, redirectPageURL, deviceId } = nearMissParams
  const token = await getAccessToken({ msalContext, redirectPageURL })
  const nearMissData = await fetchDeviceNearMissLogs({
    startDate,
    endDate,
    token,
    deviceId,
  })
  return nearMissData
}

// async function getReportData(reportParams: iReportAPIParams): Promise<iNearMissData[]> {
//   try {
//     const { deviceId, startDate, endDate } = reportParams
//     const FromDate = new Date(startDate).toISOString().split('T')[0]
//     const ToDate = new Date(endDate).toISOString().split('T')[0]

//     const httpClient = HttpClient.getInstance()

//     const resp = await httpClient.get<iNearMissData[]>(
//       `devicelogs/api/gaslogs/nearmiss/${deviceId}?FromDate=${FromDate}&ToDate=${ToDate}`,
//     )

//     const response = resp.data

//     return response
//   } catch (error) {
//     console.error(error)
//     throw error
//   }
// }

export const DeviceNearMissDataLoader =
  (props: iDeviceLogsDataLoaderParams): LoaderFunction =>
  async ({ request, params }) => {
    try {
      const { queryClient, msalInstance } = props
      if (!params?.deviceSerialNumber) throw new Error('No device serial number')
      if (!msalInstance) throw new Error('No MSAL Context')
      if (!queryClient) throw new Error('No query client')

      const token = await getAccessTokenFromPCA({ msalInstance })

      const searchParams = createSearchParams(new URLSearchParams(request.url.split('?')[1]))

      const endDate = searchParams.get('to') ?? new Date().toISOString().split('T')[0]
      const startDate =
        searchParams.get('from') ??
        sub(new Date(endDate), { months: 6 }).toISOString().split('T')[0]

      const deviceDetails = await getOrFetchDeviceDetails({
        queryClient,
        token,
        deviceSerialNumber: params?.deviceSerialNumber,
      })

      const deviceId = deviceDetails?.id ?? ''

      const deviceNearMissData = getOrFetchNearMissData({
        queryClient,
        token,
        startDate,
        endDate,
        deviceId,
      })

      return deviceNearMissData
    } catch (error) {
      console.error(error)
      throw error
      // return []
    }
  }

export function useDeviceNearMissData(
  deviceId: string,
  startDate: string,
  endDate: string,
  redirectPageURL: string,
  enabled?: boolean,
) {
  const msalContext = useMsal()

  return useQuery(
    reportDataQueryKeyIdDates({ deviceId, startDate, endDate }),
    () =>
      getNearMissData({
        msalContext,
        redirectPageURL,
        deviceId,
        startDate,
        endDate,
      }),
    {
      enabled,
      onError: (error: AxiosError) => {
        console.error(error)
      },
    },
  )
}
