import { QueryClient, useQuery } from '@tanstack/react-query'
import { useMsal } from '@azure/msal-react'
import { UserPermissionTypes, UserScopeType } from 'utils/CommonEnums'
import ConnectAPIClient from 'services/ConnectAPIClient'
import { getAccessTokenFromPCA } from 'services/MSALService'
import { userInfoUrl } from 'services/apis/urls/users'
import { userInfoQueryKey } from 'services/apis/caching/users'
import { getAPIData, iGenericAPIParams, iGenericAPIParamsMsalInstance } from './GenericData'

interface iUserAuthority {
  businessUnitId: string
  businessUnitName: string
  roleName: string
  scope: string
  permissions: Array<UserPermissionTypes>
}

interface iAuthenticatedUserInfo {
  user: {
    id: string
    customerId: string
    firstName: string
    lastName: string
    fullName: string
    emailId: string
    timeZone: string
    locale: string
    lastLogin: {
      IpAddress: string
      Date: string
    }
    status: string
    userScope: UserScopeType
    hasApiAccess: boolean
    subscriptionExpiryDate: string
  }
  authority: Array<iUserAuthority>
}

interface iGetOrFetchUserInfoProps {
  queryClient: QueryClient
  token: string
}

interface iFetchUserInfoParams {
  token: string
}

export function useUserInfo({
  redirectPageURL,
  enabled,
}: {
  redirectPageURL: string
  enabled?: boolean
}) {
  const msalContext = useMsal()
  const apiURL = userInfoUrl()
  const queryKey = userInfoQueryKey()

  return useQuery(
    queryKey,
    () =>
      getAPIData<iGenericAPIParams, iAuthenticatedUserInfo>({
        msalContext,
        redirectPageURL,
        apiURL,
      }),

    {
      staleTime: 1000 * 60 * 60 * 24,
      refetchInterval: 1000 * 60 * 60 * 24,
      retry: false,
      refetchOnWindowFocus: false,
      // onError: (error) => {
      //   const errorDescription = parseAPIErrorv01(error as AxiosError);
      //   console.error('useUserInfo', errorDescription);
      //   throw new Error(errorDescription);
      // },
      enabled,
    },
  )
}

export async function FetchUserInfo(params: iFetchUserInfoParams): Promise<iAuthenticatedUserInfo> {
  try {
    const { token } = params
    const url = userInfoUrl()
    const resp = await ConnectAPIClient.get<iAuthenticatedUserInfo>(url, token)
    const response = resp.data
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}

export async function getOrFetchUserInfo(props: iGetOrFetchUserInfoProps) {
  const { queryClient, token } = props

  const userInfoCached = queryClient.getQueryData<iAuthenticatedUserInfo>(userInfoQueryKey())

  if (userInfoCached) return userInfoCached

  const userInfoFetched = await queryClient.fetchQuery(userInfoQueryKey(), async () =>
    FetchUserInfo({
      token,
    }),
  )

  return userInfoFetched
}

// iGenericAPIParams {
//   msalContext: IMsalContext
//   apiURL: string
//   redirectPageURL: string
//   version?: string
//   adb2cPolicy?: string
// }

export async function getUserInfo(props: iGenericAPIParamsMsalInstance) {
  const { msalInstance, redirectPageURL, apiURL, adb2cPolicy } = props
  const token = await getAccessTokenFromPCA({ msalInstance })
  const resp = await ConnectAPIClient.get<iAuthenticatedUserInfo>(apiURL, token)
  const response = resp.data as iAuthenticatedUserInfo

  return response
}
