import { IselectOptionProps } from 'forms/FormModelInterface'
import { selectAllOption } from 'forms/FormUtils'
import { iFaultData } from './interface'

function PrepareDescriptionOptionsForSelect(reportData: iFaultData[]): IselectOptionProps[] {
  if (!reportData || reportData.length === 0) return []

  const eventDescriptions = new Array<IselectOptionProps>()

  reportData.forEach((reportDataItem) => {
    eventDescriptions.push({
      label: reportDataItem.eventDescription,
      value: reportDataItem.eventDescription,
    })
  })

  // Remove duplicates
  const uniqueEventDescriptions = eventDescriptions.filter(
    (eventDescription, index, self) =>
      index === self.findIndex((e) => e.value === eventDescription.value),
  )

  return uniqueEventDescriptions
}

function PrepareUserOptionsForSelect(reportData: iFaultData[]): IselectOptionProps[] {
  if (!reportData || reportData.length === 0) return []

  const eventDeviceUsers = new Array<IselectOptionProps>()

  reportData.forEach((reportDataItem) => {
    if (reportDataItem.deviceUserName !== null) {
      eventDeviceUsers.push({
        label: reportDataItem.deviceUserName,
        value: reportDataItem.deviceUserName,
      })
    }
  })

  // Remove duplicates
  const uniqueEventDeviceUsers = eventDeviceUsers.filter(
    (eventDeviceUser, index, self) =>
      index === self.findIndex((e) => e.value === eventDeviceUser.value),
  )

  return uniqueEventDeviceUsers
}

export const PrepareFaultTypeOptionsForSelect = (
  reportData: iFaultData[],
): IselectOptionProps[] => {
  if (!reportData || reportData.length === 0) return []

  const faultTypes = new Array<IselectOptionProps>()

  reportData.forEach((reportDataItem) => {
    if (reportDataItem.eventType !== null) {
      faultTypes.push({
        label: reportDataItem.eventType,
        value: reportDataItem.eventType,
      })
    }
  })

  // Remove duplicates
  const uniqueFaultTypes = faultTypes.filter(
    (eventType, index, self) => index === self.findIndex((e) => e.value === eventType.value),
  )

  return uniqueFaultTypes
}

export const prepareFilterFormData = (reportData: iFaultData[]) => {
  if (!reportData || reportData.length === 0)
    return { faultTypes: [], faultDescriptions: [], faultDeviceUsers: [] }

  const faultDescriptions = PrepareDescriptionOptionsForSelect(reportData)
  const faultDeviceUsers = PrepareUserOptionsForSelect(reportData)
  const faultTypes = PrepareFaultTypeOptionsForSelect(reportData)

  // faultDescriptions.unshift(selectAllOption)
  // faultDeviceUsers.unshift(selectAllOption)
  faultTypes.unshift(selectAllOption)

  return { faultTypes, faultDescriptions, faultDeviceUsers }
}
