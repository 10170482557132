import { IFilterQueryParamValues, IselectOptionProps } from 'forms/FormModelInterface'

export const BillingPlanPageColumnIds = {
  name: 'name',
  numberOfDevices: 'numberOfDevices',
  numberOfOperators: 'numberOfOperators',
  numberOfUsers: 'numberOfUsers',
  numberOfApiKeys: 'numberOfApiKeys',
  billingPlanType: 'billingPlanType',
  description: 'description',
  status: 'status',
}

export const BillingPlanPageParamLabels = {
  name: 'name',
  numberOfDevices: 'numberOfDevices',
  numberOfOperators: 'numberOfOperators',
  numberOfUsers: 'numberOfUsers',
  numberOfApiKeys: 'numberOfApiKeys',
  description: 'description',
  billingPlanType: 'billingPlanType',
  status: 'status',
}

export const BillingPlanPageFilterParamValues: IFilterQueryParamValues[] = [
  {
    paramName: BillingPlanPageParamLabels.name,
    paramValue: null,
  },
  {
    paramName: BillingPlanPageParamLabels.billingPlanType,
    paramValue: null,
  },
  {
    paramName: BillingPlanPageParamLabels.numberOfApiKeys,
    paramValue: null,
  },
  {
    paramName: BillingPlanPageParamLabels.numberOfDevices,
    paramValue: null,
  },
  {
    paramName: BillingPlanPageParamLabels.status,
    paramValue: null,
  },
  {
    paramName: BillingPlanPageParamLabels.numberOfOperators,
    paramValue: null,
  },
  {
    paramName: BillingPlanPageParamLabels.numberOfUsers,
    paramValue: null,
  },
]

export type BillingPlanPageFilterFormType = {
  name: IselectOptionProps
  numberOfDevices: IselectOptionProps
  numberOfOperators: IselectOptionProps
  numberOfUsers: IselectOptionProps
  numberOfApiKeys: IselectOptionProps
  billingPlanType: IselectOptionProps
  status: IselectOptionProps
}

export const BillingPlanColumnHeader = {
  [BillingPlanPageColumnIds.name]: 'Name',
  [BillingPlanPageColumnIds.numberOfDevices]: 'Max Devices',
  [BillingPlanPageColumnIds.numberOfOperators]: 'Max Operators',
  [BillingPlanPageColumnIds.numberOfUsers]: 'Max Users',
  [BillingPlanPageColumnIds.numberOfApiKeys]: 'Max API Keys',
  [BillingPlanPageColumnIds.billingPlanType]: 'Plan Type',
  [BillingPlanPageColumnIds.description]: 'Description',
  [BillingPlanPageColumnIds.status]: 'Status',
}
