import { QueryClient, useQuery } from '@tanstack/react-query'
import { useMsal } from '@azure/msal-react'
import ConnectAPIClient from 'services/ConnectAPIClient'
import { iAPIBusinessUnitParams } from 'pages/devices-2/data/DevicePageData'
import { getAccessToken, getAccessTokenProps } from 'services/MSALService'
import { deviceListStatsQueryKey } from 'services/apis/caching/device'
import { deviceListStatsDataURL } from 'services/apis/urls/device'
import { getAPIData, iGenericAPIParams } from './GenericData'

export interface iDeviceStatsLatestDates {
  deviceId: string
  lastSwitchOnDate: Date | string
  lastUploadDate: Date | string
}

interface iFetchDeviceListStatsParams {
  token: string
  businessUnitId?: string
}

interface iGetOrFetchDeviceListStatsProps {
  queryClient: QueryClient
  token: string
  businessUnitId?: string
}

interface iDeviceListStatsAPIParams extends iAPIBusinessUnitParams, getAccessTokenProps {}

export function useDeviceListStats(redirectPageURL: string, businessUnitId?: string) {
  const msalContext = useMsal()
  const apiURL = deviceListStatsDataURL(businessUnitId)
  const queryKey = deviceListStatsQueryKey(businessUnitId)
  return useQuery(
    queryKey,
    () =>
      getAPIData<iGenericAPIParams, iDeviceStatsLatestDates>({
        msalContext,
        redirectPageURL,
        apiURL,
      }),
    {
      // refetchInterval: 1 * 10 * 1000, // 10 seconds
      select: (resultData) => resultData,
      onError: (error) => {
        console.log(error)
      },
    },
  )
}

export async function FetchDevicesListStats(
  params: iFetchDeviceListStatsParams,
): Promise<iDeviceStatsLatestDates[]> {
  try {
    const { token, businessUnitId } = params
    const url = deviceListStatsDataURL(businessUnitId)
    const resp = await ConnectAPIClient.get<iDeviceStatsLatestDates[]>(url, token)
    const response = resp.data
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}

export async function getDeviceListStatsData(deviceListStatsAPIParams: iDeviceListStatsAPIParams) {
  const { msalContext, redirectPageURL, businessUnitId } = deviceListStatsAPIParams
  const token = await getAccessToken({ msalContext, redirectPageURL })
  const deviceListStatsData = await FetchDevicesListStats({ token, businessUnitId })
  return deviceListStatsData
}

export async function getOrFetchDevicesListStats(props: iGetOrFetchDeviceListStatsProps) {
  const { queryClient, token, businessUnitId } = props

  const devicesListStatsCached = queryClient.getQueryData<iDeviceStatsLatestDates[]>(
    deviceListStatsQueryKey(businessUnitId),
  )

  if (devicesListStatsCached) {
    return devicesListStatsCached
  }

  const devicesListStatsFetched = await queryClient.fetchQuery(
    deviceListStatsQueryKey(businessUnitId),
    async () =>
      FetchDevicesListStats({
        token,
        businessUnitId,
      }),
  )

  return devicesListStatsFetched
}
