import { ExportColumnType } from 'exportReports/DataExportInterfaces'
import { UserRolesConstants } from 'utils/Constants'
import { Customer, Role, UserCustomBU } from '../data/iUsersList'
import { UsersPageColumnHeader, UsersPageColumnIds } from '../view/UsersPageUtils'

const UsersReportColumns: ExportColumnType[] = [
  {
    key: UsersPageColumnIds.email,
    label: UsersPageColumnHeader[UsersPageColumnIds.email],
    format: (val: string) => val,
    cellWidth: 110,
  },
  {
    key: UsersPageColumnIds.firstName,
    label: UsersPageColumnHeader[UsersPageColumnIds.firstName],
    format: (val: string) => val || '',
    cellWidth: 55,
  },
  {
    key: UsersPageColumnIds.lastName,
    label: UsersPageColumnHeader[UsersPageColumnIds.lastName],
    format: (val: string) => val || '',
    cellWidth: 65,
  },
  {
    key: UsersPageColumnIds.businessUnitName,
    label: UsersPageColumnHeader[UsersPageColumnIds.businessUnitName],
    format: (val: UserCustomBU) => (val?.name as string) || '',
    cellWidth: 95,
  },
  {
    key: UsersPageColumnIds.customerName,
    label: UsersPageColumnHeader[UsersPageColumnIds.customerName],
    format: (val: Customer) => val?.name || '',
    cellWidth: 95,
  },
  {
    key: UsersPageColumnIds.roleName,
    label: UsersPageColumnHeader[UsersPageColumnIds.roleName],
    format: (val: Role[]) => UserRolesConstants[val?.[0]?.roleName] || '',
    cellWidth: 75,
  },
  {
    key: UsersPageColumnIds.status,
    label: UsersPageColumnHeader[UsersPageColumnIds.status],
    format: (val: string) => val,
    cellWidth: 50,
  },
]

export { UsersReportColumns }
