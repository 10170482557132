import ConnectAPIClient from 'services/ConnectAPIClient'
import { getAccessToken, getAccessTokenProps } from 'services/MSALService'
import { useMsal } from '@azure/msal-react'
import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { roleListQueryKey, userListQueryKey } from 'services/apis/caching/users'
import { roleListUrl, updateUserRole, userListUrl, userUrl } from 'services/apis/urls/users'
import { User, UsersList, iRole, iUserPutParams, iUserV3Params } from './iUsersList'

export async function FetchUsersList(params: {
  token: string
  businessId?: string
}): Promise<UsersList[]> {
  try {
    const { token, businessId } = params
    const url = userListUrl(businessId ?? '')
    const resp = await ConnectAPIClient.get<UsersList[]>(url, token)
    const response = resp.data

    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}

export async function getUsersListData(
  usersListAPIParams: getAccessTokenProps & {
    businessId?: string
  },
) {
  const { msalContext, redirectPageURL, businessId } = usersListAPIParams
  const token = await getAccessToken({ msalContext, redirectPageURL })
  const usersListData = await FetchUsersList({ token, businessId })
  return usersListData
}

export function useUsersList(redirectPageURL: string, businessId?: string, enabled?: boolean) {
  const msalContext = useMsal()

  return useQuery(
    userListQueryKey(),
    () =>
      getUsersListData({
        msalContext,
        redirectPageURL,
        businessId,
      }),
    {
      enabled,
      onError: (error: AxiosError) => {
        console.log(error)
      },
    },
  )
}

export async function FetchRoleList(params: { token: string }): Promise<iRole[]> {
  try {
    const { token } = params
    const url = roleListUrl()
    const resp = await ConnectAPIClient.get<iRole[]>(url, token)
    const response = resp.data

    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}

export async function getRoleListData(roleListAPIParams: getAccessTokenProps) {
  const { msalContext, redirectPageURL } = roleListAPIParams
  const token = await getAccessToken({ msalContext, redirectPageURL })
  const roleListData = await FetchRoleList({ token })
  return roleListData
}

export function useRolesList(redirectPageURL: string, enabled?: boolean) {
  const msalContext = useMsal()

  return useQuery(
    roleListQueryKey(),
    () =>
      getRoleListData({
        msalContext,
        redirectPageURL,
      }),
    {
      enabled,
      onError: (error: AxiosError) => {
        console.log(error)
      },
    },
  )
}

export async function updateUser(
  params: getAccessTokenProps & { payload: iUserPutParams },
): Promise<User> {
  try {
    const { msalContext, payload } = params
    const token = await getAccessToken({ msalContext, redirectPageURL: '' })
    const url = updateUserRole()
    const resp = await ConnectAPIClient.put<User>(url, token, '0.2', {
      ...payload,
    } as unknown as User)
    const response = resp.data
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}
export async function addUser(
  params: getAccessTokenProps & { payload: iUserV3Params },
): Promise<User> {
  try {
    const { msalContext, payload } = params
    const token = await getAccessToken({ msalContext, redirectPageURL: '' })
    const url = userUrl()
    const resp = await ConnectAPIClient.post<User>(`${url}`, token, '0.3', {
      ...payload,
    } as unknown as User)
    const response = resp.data
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}
