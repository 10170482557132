import {
  GasType,
  PortableTypes,
  ComplianceType,
  DeviceUserAssignedFilterValues,
  DeviceSyncFilterValues,
} from 'utils/CommonEnums'
import { IselectOptionProps } from 'forms/FormModelInterface'
import { iDevice } from 'data/DeviceListHook'
import { DevicePageColumnIds } from 'pages/devices-2/view/DevicesPageUtils'
import { GetProductNameText } from 'utils/CommonUtils'
import { iDeviceListDataWithStats } from 'pages/devices-2/data/DevicePageData'

import { iDeviceTypeCount, iComplianceCount } from './iDevicesList'

export const groupDeviceDataByGasType = (deviceData: iDeviceListDataWithStats[]) => {
  const gasTypes = [GasType.O2, GasType.CO, GasType.H2S, GasType.FLAM, GasType.CO2]
  const gasTypeCounts = gasTypes.map((gasType) => ({
    columnId: DevicePageColumnIds.gasType,
    gasType,
    count: deviceData.filter((device) => device.channels.some((gas) => gas.channelName === gasType))
      .length,
  }))

  const otherCount = deviceData.filter(
    (device) =>
      device.channels.length === 0 ||
      !device.channels.some((gas) => gasTypes.includes(gas.channelName as GasType)),
  ).length

  gasTypeCounts.push({
    columnId: DevicePageColumnIds.gasType,
    gasType: GasType.Other,
    count: otherCount,
  })

  return gasTypeCounts
}

export const groupDeviceDataByDeviceType = (deviceData: iDeviceListDataWithStats[] | iDevice[]) => {
  const deviceTypeCounts = new Array<iDeviceTypeCount>(
    { deviceType: GetProductNameText(PortableTypes.T4), count: 0 },
    { deviceType: GetProductNameText(PortableTypes.Gasman), count: 0 },
    { deviceType: GetProductNameText(PortableTypes.T3), count: 0 },
    { deviceType: GetProductNameText(PortableTypes.GasPro), count: 0 },
  )

  deviceData.forEach((device) => {
    // const deviceTypeText = GetProductNameText(device.deviceType)
    if (device.deviceType === GetProductNameText(PortableTypes.T4)) {
      deviceTypeCounts[0].count += 1
    } else if (device.deviceType === GetProductNameText(PortableTypes.Gasman)) {
      deviceTypeCounts[1].count += 1
    } else if (device.deviceType === GetProductNameText(PortableTypes.T3)) {
      deviceTypeCounts[2].count += 1
    } else if (device.deviceType === GetProductNameText(PortableTypes.GasPro)) {
      deviceTypeCounts[3].count += 1
    }
  })

  return deviceTypeCounts
}

export const groupDeviceDataByComplianceType = (deviceData: iDeviceListDataWithStats[]) => {
  const complianceCounts = new Array<iComplianceCount>(
    {
      complianceParam: ComplianceType.Assigned,
      complianceFilter: DeviceUserAssignedFilterValues.AllAssignedUsers,
      count: 0,
      columnId: DevicePageColumnIds.deviceUser,
    },
    {
      complianceParam: ComplianceType.UnAssigned,
      complianceFilter: DeviceUserAssignedFilterValues.NoAssignedUsers,
      count: 0,
      columnId: DevicePageColumnIds.deviceUser,
    },
    {
      complianceParam: ComplianceType.Synced,
      complianceFilter: DeviceSyncFilterValues.AllSynced,
      count: 0,
      columnId: DevicePageColumnIds.lastSync,
    },
    {
      complianceParam: ComplianceType.NotSynced,
      complianceFilter: DeviceSyncFilterValues.NotSynced,
      count: 0,
      columnId: DevicePageColumnIds.lastSync,
    },
  )

  deviceData.forEach((device) => {
    if (device.deviceUser && device.deviceUser.id && device.deviceUser.fullName !== null) {
      complianceCounts[0].count += 1
    } else {
      complianceCounts[1].count += 1
    }

    if (device.lastUploadDate) {
      complianceCounts[2].count += 1
    } else {
      complianceCounts[3].count += 1
    }
  })

  return complianceCounts
}

export const AllDeviceTypesOptionsForSelect = (): IselectOptionProps[] => {
  const portableTypes: IselectOptionProps[] = []

  Object.keys(PortableTypes).forEach((portableType) => {
    portableTypes.push({
      value: portableType.toString(),
      label: portableType.toString(),
    })
  })

  return portableTypes
}
