import React from 'react'
import { SvgProps } from 'types/SVGProps'

function PDFIcon(props: SvgProps): JSX.Element {
  const { stroke, fill, className, toggleClick } = props
  return (
    <div className=''>
      <svg
        className={className}
        width='21'
        height='20'
        viewBox='0 0 21 20'
        fill={fill ?? 'none'}
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M10.5 6V0H4.5C3.96957 0 3.46086 0.210714 3.08579 0.585786C2.71071 0.960859 2.5 1.46957 2.5 2V8.668C1.909 8.949 1.5 9.552 1.5 10.25V15.75C1.5 16.448 1.909 17.05 2.5 17.332V18C2.5 18.5304 2.71071 19.0391 3.08579 19.4142C3.46086 19.7893 3.96957 20 4.5 20H16.5C17.0304 20 17.5391 19.7893 17.9142 19.4142C18.2893 19.0391 18.5 18.5304 18.5 18V17.332C19.091 17.051 19.5 16.448 19.5 15.75V10.25C19.5 9.552 19.091 8.95 18.5 8.668V8H12.5C11.9696 8 11.4609 7.78929 11.0858 7.41421C10.7107 7.03914 10.5 6.53043 10.5 6ZM3.25 10H17.75C17.8163 10 17.8799 10.0263 17.9268 10.0732C17.9737 10.1201 18 10.1837 18 10.25V15.75C18 15.8163 17.9737 15.8799 17.9268 15.9268C17.8799 15.9737 17.8163 16 17.75 16H3.25C3.1837 16 3.12011 15.9737 3.07322 15.9268C3.02634 15.8799 3 15.8163 3 15.75V10.25C3 10.1837 3.02634 10.1201 3.07322 10.0732C3.12011 10.0263 3.1837 10 3.25 10ZM12 6V0.5L18 6.5H12.5C12.3674 6.5 12.2402 6.44732 12.1464 6.35355C12.0527 6.25979 12 6.13261 12 6Z'
          fill={fill ?? '#FFFFFF'}
        />
        <path
          d='M5.91615 15V11.3636H7.35081C7.62661 11.3636 7.86158 11.4163 8.05571 11.5217C8.24984 11.6258 8.3978 11.7708 8.4996 11.9567C8.60258 12.1413 8.65408 12.3544 8.65408 12.5959C8.65408 12.8374 8.60199 13.0504 8.49783 13.2351C8.39366 13.4197 8.24274 13.5636 8.04506 13.6665C7.84856 13.7695 7.61063 13.821 7.33128 13.821H6.41686V13.2049H7.20699C7.35495 13.2049 7.47687 13.1795 7.57275 13.1286C7.66982 13.0765 7.74202 13.0049 7.78937 12.9137C7.83791 12.8214 7.86217 12.7154 7.86217 12.5959C7.86217 12.4751 7.83791 12.3698 7.78937 12.2798C7.74202 12.1887 7.66982 12.1183 7.57275 12.0685C7.47569 12.0176 7.35258 11.9922 7.20344 11.9922H6.68497V15H5.91615ZM10.4425 15H9.15345V11.3636H10.4532C10.8189 11.3636 11.1338 11.4364 11.3978 11.582C11.6617 11.7264 11.8647 11.9342 12.0068 12.2053C12.15 12.4763 12.2216 12.8007 12.2216 13.1783C12.2216 13.5571 12.15 13.8826 12.0068 14.1548C11.8647 14.4271 11.6606 14.636 11.3942 14.7816C11.1291 14.9272 10.8118 15 10.4425 15ZM9.92227 14.3413H10.4106C10.6378 14.3413 10.829 14.301 10.9841 14.2205C11.1403 14.1388 11.2575 14.0128 11.3356 13.8423C11.4149 13.6707 11.4546 13.4493 11.4546 13.1783C11.4546 12.9096 11.4149 12.69 11.3356 12.5195C11.2575 12.3491 11.1409 12.2236 10.9858 12.1431C10.8308 12.0626 10.6396 12.0224 10.4123 12.0224H9.92227V14.3413ZM12.7911 15V11.3636H15.1988V11.9975H13.56V12.864H15.039V13.4979H13.56V15H12.7911Z'
          fill={fill ?? '#FFFFFF'}
        />
      </svg>
    </div>
  )
}

export default PDFIcon
