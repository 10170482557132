import React from 'react'
import { SvgProps } from 'types/SVGProps'

function MenuIcon(props: SvgProps): JSX.Element {
  const { stroke, fill, className, toggleClick } = props

  return (
    <div className='hover:cursor-pointer '>
      <svg
        stroke={stroke ?? '#FFFFFF'}
        strokeWidth={0}
        className={className}
        width='20'
        height='20'
        viewBox='0 0 20 20'
        fill='none'
        onClick={toggleClick}
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M18 5C18 4.45 17.6 4 17.1111 4L2.88889 4C2.4 4 2 4.45 2 5C2 5.55 2.4 6 2.88889 6L17.1111 6C17.6 6 18 5.55 18 5Z'
          fill={fill ?? '#FFFFFF'}
        />
        <path
          d='M18 10C18 9.45 17.6 9 17.1111 9L2.88889 9C2.4 9 2 9.45 2 10C2 10.55 2.4 11 2.88889 11L17.1111 11C17.6 11 18 10.55 18 10Z'
          fill={fill ?? '#FFFFFF'}
        />
        <path
          d='M18 15C18 14.45 17.6 14 17.1111 14L2.88889 14C2.4 14 2 14.45 2 15C2 15.55 2.4 16 2.88889 16L17.1111 16C17.6 16 18 15.55 18 15Z'
          fill={fill ?? '#FFFFFF'}
        />
      </svg>
    </div>
  )
}

export default MenuIcon
