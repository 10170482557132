import { Transition } from '@headlessui/react'
import { CloseIcon } from 'assets/icons'
import clsx from 'clsx'
import Show from 'components/atom/Show'
import React, { useEffect, useState } from 'react'
import { CalibrationStages, Status } from '../constants'

export default function CalibrationStatusReport({
  calibrationStatus,
  isCompleted,
}: {
  isCompleted: boolean
  calibrationStatus: string
}) {
  const [state, setState] = useState(CalibrationStages)

  useEffect(() => {
    setState((prevState) => {
      let newState = [...prevState]
      const endIndex = newState.findIndex((item) => item?.startTitle === calibrationStatus)
      if (newState[endIndex - 1]?.status === Status.Working) {
        newState[endIndex - 1].status = Status.End
      }
      if (newState[endIndex]?.status === Status.None) {
        newState = newState.map((ele, i) => ({
          ...ele,
          status: i < endIndex ? Status.End : Status.None,
        }))
        newState[endIndex].status = Status.Working
      }
      return newState
    })
  }, [calibrationStatus])

  return (
    <div className='overflow-hidden rounded-lg bg-white shadow text-sm p-5'>
      <div className='flex items-center justify-center flex-col gap-4'>
        <Transition
          show={isCompleted}
          enter='transition ease-out duration-500  transform'
          enterFrom='opacity-0 scale-0'
          enterTo='opacity-100 scale-100'
        >
          <div className='bg-[#30b830] rounded-full p-2 '>
            <Transition.Child
              enter='transition ease-out duration-500 delay-100 transform'
              enterFrom='opacity-0 scale-0'
              enterTo='opacity-100 scale-100'
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                strokeWidth='1.5'
                stroke='currentColor'
                className='h-24 w-24 stroke-c-white fill-transparent'
              >
                <path strokeLinecap='square' strokeLinejoin='round' d='m4.5 12.75 6 6 9-13.5' />
              </svg>
            </Transition.Child>
          </div>
        </Transition>
        <p
          className={clsx(
            {
              block: isCompleted,
              hidden: !isCompleted,
            },
            'text-base font-bold',
          )}
        >
          Calibration Success
        </p>
      </div>
      <div
        className={clsx({
          block: !isCompleted,
          hidden: isCompleted,
        })}
      >
        {state.map((steps, i) => (
          <div
            key={steps.startTitle}
            className={clsx({
              ' before:border-double ': i !== 0 && steps.status === Status.End,
              'before:ml-4 before:block before:h-5 before:border-l-2 before:border-c-blue/50 before:border-dashed':
                i !== 0,
            })}
          >
            <div className='flex gap-4 my-2 items-center '>
              <Show>
                <Show.When isTrue={Boolean(steps.status === Status.Working)}>
                  <div className='h-8 w-8 animate-spin rounded-full border-4 border-solid border-c-dark-blue-2 border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]' />
                </Show.When>
                <Show.When isTrue={Boolean(steps.status === Status.End)}>
                  <div className='bg-c-dark-blue-2 rounded-full p-2'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 24 24'
                      strokeWidth='1.5'
                      stroke='currentColor'
                      className='h-5 w-5 stroke-c-white fill-transparent'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        d='M9 12.75 11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 0 1-1.043 3.296 3.745 3.745 0 0 1-3.296 1.043A3.745 3.745 0 0 1 12 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 0 1-3.296-1.043 3.745 3.745 0 0 1-1.043-3.296A3.745 3.745 0 0 1 3 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 0 1 1.043-3.296 3.746 3.746 0 0 1 3.296-1.043A3.746 3.746 0 0 1 12 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 0 1 3.296 1.043 3.746 3.746 0 0 1 1.043 3.296A3.745 3.745 0 0 1 21 12Z'
                      />
                    </svg>
                  </div>
                </Show.When>
                <Show.Otherwise>
                  <div className='bg-c-dark-blue-2 rounded-full p-2'>
                    <CloseIcon className='h-5 w-5 stroke-c-white fill-transparent' />
                  </div>
                </Show.Otherwise>
              </Show>

              <p className='font-bold text-md'>{steps.startTitle}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
