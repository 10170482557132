import { selectAllOption } from 'forms/FormUtils'
import { convertUrlForDemoMode } from 'utils/CommonUtils'
import { endpoints } from '../endpoints'

export const deviceBySerialNumber = (serialNumber: string) =>
  convertUrlForDemoMode(`${endpoints.device.bySerialNumber}/${serialNumber}`)

export const eventLogsUrl = (deviceId: string, params?: string) =>
  convertUrlForDemoMode(`${endpoints.logs.eventLogs}/${deviceId}?${params}`)

export function deviceGasLogURL(
  deviceId: string,
  startDate: string | undefined,
  endDate: string | undefined,
  channelNumber: number | undefined,
  gasName: string | undefined,
  // gasValue: number | undefined,
  uom: string | undefined,
  pageNumber?: number,
  pageSize?: number,
) {
  let apiURL = `${endpoints.logs.gasLogs}/${deviceId}`
  const urlParams = new URLSearchParams()
  if (startDate) urlParams.append('FromDate', startDate)
  if (endDate) urlParams.append('ToDate', endDate)
  if (channelNumber && !Number.isNaN(channelNumber) && channelNumber !== -1) {
    urlParams.append('ChannelNumber', channelNumber.toString())
  }
  if (gasName && gasName !== selectAllOption.value) {
    urlParams.append('GasType', gasName)
  }
  // if (gasValue && gasValue !== -1) urlParams.append('GasValue', gasValue.toString())
  if (uom && uom !== selectAllOption.value) urlParams.append('UOM', uom)
  if (pageNumber && !Number.isNaN(pageNumber)) urlParams.append('PageNumber', pageNumber.toString())
  if (pageSize && !Number.isNaN(pageSize)) urlParams.append('PageSize', pageSize.toString())
  if (urlParams.toString()) apiURL += `?${urlParams.toString()}`
  // ?FromDate=${startDate}&ToDate=${endDate}&ChannelNumber=${channelNumber}&GasName=${gasName}&GasValue=${gasValue}&UOM=${uom}
  return convertUrlForDemoMode(apiURL)
}

export const gasUOMList = (deviceId: string) =>
  convertUrlForDemoMode(`${endpoints.logs.gasLogs}/${deviceId}/gasuomlist`)

export const deviceAssignmentURL = (deviceId: string, params?: string) =>
  convertUrlForDemoMode(`${endpoints.logs.assignment}/${deviceId}?${params}`)

export const deviceFaultsURL = (deviceId: string, params?: string) =>
  convertUrlForDemoMode(`${endpoints.logs.faults}/${deviceId}?${params}`)

export const deviceSyncHistoryURL = (deviceId: string, params?: string) =>
  convertUrlForDemoMode(`${endpoints.logs.sync}/${deviceId}?${params}`)

export const deviceNearMissURL = (deviceId: string, params?: string) =>
  convertUrlForDemoMode(`${endpoints.logs.nearMiss}/${deviceId}?${params}`)

export const deviceBumpHistoryURL = (deviceId: string, params?: string) =>
  convertUrlForDemoMode(`${endpoints.logs.bumpHistory}/${deviceId}?${params}`)

export const deviceCheckURL = (deviceId: string) =>
  convertUrlForDemoMode(`${endpoints.logs.check}/${deviceId}`)

export const deviceListStatsDataURL = (businessUnitId?: string) => {
  let url = endpoints.device.stats
  if (
    businessUnitId !== '' &&
    businessUnitId?.toLowerCase() !== selectAllOption.value?.toString().toLowerCase()
  ) {
    url = `${endpoints.device.stats}?ExpandBusinessUnit=true&BusinessUnitId=${businessUnitId}`
  }
  return convertUrlForDemoMode(url)
}

export const crudDeviceURL = () => convertUrlForDemoMode(`${endpoints.device.common}`)

export const deviceTypesListURL = () => convertUrlForDemoMode(`${endpoints.device.type}`)

export const deviceListDataURL = (businessUnitId?: string, customerId?: string) => {
  let url = endpoints.device.common
  if (
    businessUnitId &&
    businessUnitId.toLowerCase() !== selectAllOption.value?.toString().toLowerCase()
    // || (customerId === '' || customerId === allCustomerOption.id)
  ) {
    url = `${endpoints.device.common}?ExpandBusinessUnit=true&BusinessUnitId=${businessUnitId}`
  }
  return convertUrlForDemoMode(url)
}

export const deviceConfigDataURL = (deviceId?: string) =>
  convertUrlForDemoMode(`${endpoints.device.config}/${deviceId}`)
