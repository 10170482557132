import React from 'react'
import { SvgProps } from 'types/SVGProps'

function ServicesIcon(props: SvgProps): JSX.Element {
  const { stroke, fill, className, toggleClick } = props

  return (
    <div className=''>
      <svg
      className={className}
        width='20'
        height='21'
        viewBox='0 0 20 21'
        fill={fill ?? 'none'}
        xmlns='http://www.w3.org/2000/svg'
      >
        <path d='M13 12L7 12' stroke={stroke ?? '#FFFFFF'} strokeLinecap='round' />
        <path d='M10 9L10 15' stroke={stroke ?? '#FFFFFF'} strokeLinecap='round' />
        <rect
          x='0.75'
          y='4.75'
          width='18.5'
          height='14.5'
          rx='1.25'
          stroke={stroke ?? '#FFFFFF'}
          strokeWidth='1.5'
        />
        <path
          d='M13 4V2.5C13 1.94772 12.5523 1.5 12 1.5H8C7.44772 1.5 7 1.94772 7 2.5V4'
          stroke={stroke ?? '#FFFFFF'}
          strokeWidth='1.5'
        />
      </svg>
    </div>
  )
}

export default ServicesIcon
