import { useMsal } from '@azure/msal-react'
import { useMutation } from '@tanstack/react-query'
import { iBusinessUnitDetails } from 'data/Interfaces'
import { StepsTypes, AzureFunctionResponse, BusinessUnitProps } from '../../interfaces'
import { StageStatus } from '../../utils/constants'
import { getDemoBusinessUnit } from '../apis'

export function useBusinessUnitMutation(
  state: StepsTypes[],
  setState: React.Dispatch<React.SetStateAction<StepsTypes[]>>,
) {
  const { instance: msalInstance } = useMsal()

  return useMutation<iBusinessUnitDetails[], AzureFunctionResponse<unknown>, BusinessUnitProps>(
    (props) => {
      setState((prevState) => {
        const newState = [...prevState]
        newState[1].status = StageStatus.WORKING
        return newState
      })

      return getDemoBusinessUnit({
        ...props,
        msalInstance,
      })
    },
    {
      retry: 3,
      retryDelay: 1000,
      onSuccess(_, variables) {
        const { fromCreation } = variables
        let interval: NodeJS.Timeout | undefined
        if (fromCreation) {
          setState((prevState) => {
            const newState = [...prevState]
            newState[1].status = StageStatus.END
            return newState
          })
        } else if (
          state.filter((item) => item.status === StageStatus.END).length !== state.length
        ) {
          interval = setInterval(() => {
            setState((prevState) => {
              const newState = [...prevState]
              const endIndex = newState.findIndex((item) => item?.status === StageStatus.WORKING)
              if (
                newState[endIndex]?.status &&
                newState[endIndex]?.status === StageStatus.WORKING
              ) {
                newState[endIndex].status = StageStatus.END
              } else {
                const workingIndex = newState.findIndex((item) => item?.status === StageStatus.NONE)
                if (newState[workingIndex]?.status === StageStatus.NONE) {
                  newState[workingIndex].status = StageStatus.WORKING
                }
              }
              return newState
            })
          }, 1000)
        } else if (interval) {
          clearInterval(interval)
        }

        return () => {
          if (interval) clearInterval(interval)
        }
      },
      onError(error: AzureFunctionResponse<unknown>) {
        console.log(error)
        setState((prevState) => {
          const newState = [...prevState]
          newState[1].error = error.message
          return newState
        })
      },
    },
  )
}
