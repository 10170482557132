import React from 'react'

import { Doughnut, getElementAtEvent } from 'react-chartjs-2'

import { iOperatorInfo } from 'data/Interfaces'
import { OperatorDeviceCountTypes } from 'utils/CommonEnums'
import { withErrorBoundary } from 'react-error-boundary'
import ErrorPage from 'pages/common/ErrorPage'
import { useNavigate } from 'react-router-dom'

interface iDashboardOperatorAssignmentChartProps {
  operatorData: iOperatorInfo[] | undefined
  customerId: string
  businessUnitId: string
}

const chartOptions = {
  responsive: true,
  interaction: {
    mode: 'index' as const,
    intersect: true,
  },
  stacked: false,
  plugins: {
    title: {
      display: false,
      text: 'Device Assignments',
      font: {
        size: 16,
      },
      position: 'top' as const,
      align: 'start' as const,
    },
    legend: {
      display: false,
      position: 'top' as const,
      align: 'start' as const,
      labels: {
        pointStyle: 'circle' as const,
        usePointStyle: true,
        borderRadius: 26,
      },
    },
  },
}

function DashboardOperatorAssignmentChart(
  props: iDashboardOperatorAssignmentChartProps,
): React.ReactElement {
  // Implement your component logic here
  const { operatorData, customerId, businessUnitId } = props
  const urlsearchParams = new URLSearchParams()
  if (customerId) {
    urlsearchParams.set('c', customerId)
  }
  if (businessUnitId) {
    urlsearchParams.set('mainbu', businessUnitId)
  }

  const chartRef = React.useRef()

  const operatorsUsingMultipleDevices = operatorData?.filter(
    (operator) => operator.currentDevice.length > 1,
  ).length
  const operatorsUsingSingleDevice = operatorData?.filter(
    (operator) => operator.currentDevice.length === 1,
  ).length
  const operatorsUsingNoDevice = operatorData?.filter(
    (operator) => operator.currentDevice.length === 0,
  ).length
  const navigate = useNavigate()
  const chartData = {
    labels: ['No device', 'One device', 'More than 1 device'],
    datasets: [
      {
        // labels: ['Using more than 1 device', 'Using 1 device', 'Using No device'],
        // data: [operatorsUsingMultipleDevices, operatorsUsingSingleDevice, operatorsUsingNoDevice],
        data: [operatorsUsingNoDevice, operatorsUsingSingleDevice, operatorsUsingMultipleDevices],
        backgroundColor: ['#ff5636', '#48d3ff', '#ffc12d'],
        hoverBackgroundColor: ['#b23c25', '#3293b2', '#b2871f'],
        // borderWidth: 1,
        // hoverBorderWidth: 2,
        // barThickness: 10,
        // barPercentage: 1,
        // fill: false,
        yAxisID: 'y',
        // offset: 12,
        // hoverOffset: 12,

        borderRadius: {
          outerStart: 0,
          outerEnd: 0,
          innerStart: 8,
          innerEnd: 8,
        },
      },
    ],
  }

  const onChartClick = (event: React.MouseEvent<HTMLCanvasElement>) => {
    // Implement your chart click logic here
    const element = chartRef.current && getElementAtEvent(chartRef.current, event)

    if (element && element[0]) {
      const { index } = element[0]
      // let url = `/web/operators`
      if (index === 0) {
        urlsearchParams.set('diu', OperatorDeviceCountTypes.NoDevice)
        // url += `?diu=${OperatorDeviceCountTypes.NoDevice}`
      } else if (index === 1) {
        urlsearchParams.set('diu', OperatorDeviceCountTypes.OneDevice)
        // url += `?diu=${OperatorDeviceCountTypes.OneDevice}`
      } else if (index === 2) {
        urlsearchParams.set('diu', OperatorDeviceCountTypes.MultipleDevices)
        // url += `?diu=${OperatorDeviceCountTypes.MultipleDevices}`
      }
      const url = `/web/operators?${urlsearchParams.toString()}`
      // window.open(url, '_blank')
      navigate(url)
    }
  }

  return (
    // JSX code for your component's UI
    <div className=' px-4 py-4  bg-c-white shadow-card rounded-2xl items-center justify-around h-full'>
      <div className='my-2 flex flex-col gap-y-1 self-start'>
        <div className='text-base font-bold text-c-dark-blue-2'>Operators using devices</div>
        <div className='flex gap-x-2 mt-1 items-baseline'>
          <div className='rounded-full bg-custom-4 h-2 w-2 mt-1 ' />
          <div className='text-xs text-c-dark-blue-3'>More than one device</div>
          <div className='rounded-full bg-custom-3 h-2 w-2 mt-1 ' />
          <div className='text-xs text-c-dark-blue-3'>One device</div>
          <div className='rounded-full bg-custom-5 h-2 w-2 mt-1 ' />
          <div className='text-xs text-c-dark-blue-3'>No device</div>
        </div>
      </div>
      <div className='mt-2 p-2 h-48 2xl:h-64'>
        {operatorData?.length && operatorData?.length > 0 ? (
          <Doughnut ref={chartRef} onClick={onChartClick} data={chartData} options={chartOptions} />
        ) : (
          <div className='flex justify-center items-center h-full w-full text-sm text-red-500'>
            No Operators
          </div>
        )}
      </div>
    </div>
  )
}

export default withErrorBoundary(DashboardOperatorAssignmentChart, {
  FallbackComponent: ErrorPage,
})
