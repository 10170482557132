import { convertUrlForDemoMode } from 'utils/CommonUtils'
import { endpoints } from '../endpoints'

export const customerDataURL = () => convertUrlForDemoMode(endpoints.customer.list)
export const myCustomerInfo = () => convertUrlForDemoMode(endpoints.customer.me)
export const organizationTree = (customerId?: string) =>
  convertUrlForDemoMode(`${endpoints.businessUnit.list.orgTree}/${customerId}`)
export const organizationTreeCrud = () =>
  convertUrlForDemoMode(`${endpoints.businessUnit.list.orgTree}/`)

export const helpAndSupportDataURL = (customerId?: string) =>
  convertUrlForDemoMode(`${endpoints.customer.helpAndSupport}/${customerId}`)

export const customerDemoCustomerURL = () => `${endpoints.customer.demo}`
