import React, { useState, useEffect, useRef } from 'react'
import { Outlet, useLocation, useNavigation } from 'react-router-dom'
import clsx from 'clsx'
import TopNavContainer from 'layout/sideNav/TopNavContainer'
import { ScreenWidthsInPx } from 'utils/Constants'
import { BusinessContextProvider } from 'contexts/BusinessContext'
import { DemoBanner } from 'components/modules/demo'
import SideNavContainer from '../sideNav/SideNavContainer'

export function LayoutContainer() {
  const location = useLocation()

  const navigation = useNavigation()
  const [sideNavExpanded, setSideNavExpanded] = useState(true)
  const windowSize = useRef([window.innerWidth])

  useEffect(() => {
    const handleResize = () => {
      windowSize.current = [window.innerWidth]
    }

    handleResize()
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [windowSize])

  useEffect(() => {
    const windowWidth = windowSize.current[0]
    if (windowWidth < ScreenWidthsInPx.xl) {
      setSideNavExpanded(false)
    } else {
      setSideNavExpanded(true)
    }
  }, [windowSize])

  useEffect(() => {
    console.log(`LayoutContainer - location ${location.pathname}${location.search}`)
  }, [location])

  const collapseSideNavOnSelection = () => {
    const windowWidth = windowSize.current[0]
    if (windowWidth < ScreenWidthsInPx.xl) {
      setSideNavExpanded(false)
    }
  }
  // const [fixedSideNav, setFixedSideNav] = React.useState(false)
  // useEffect(() => {
  //   const handleResize = () => {
  //     const windowHeight = window.innerHeight
  //     if (windowHeight < ScreenHeightInPx.Large) {
  //       setFixedSideNav(false)
  //     }
  //   }

  //   handleResize()
  //   window.addEventListener('resize', handleResize)

  //   return () => {
  //     window.removeEventListener('resize', handleResize)
  //   }
  // }, [])

  return (
    <div
      className={clsx(
        ' font-poppins bg-c-light-blue-1 h-full min-h-screen flex w-full justify-stretch lg:flex-row max-lg:flex-col ',
      )}
    >
      <div
        className={clsx(
          'z-50 max-lg:hidden  min-h-screen',
          // fixedSideNav ? 'fixed h-full' : 'h-auto',
        )}
      >
        <SideNavContainer
          collapseSideNavOnSelection={collapseSideNavOnSelection}
          sideNavExpanded={sideNavExpanded}
          sideNavStatusUpdater={(val) => setSideNavExpanded(val)}
        />
      </div>
      <div className={clsx(' lg:hidden h-12', 'z-[991]')}>
        <TopNavContainer sideNavStatusUpdater={(val) => setSideNavExpanded(val)} />
      </div>
      {navigation.state === 'loading' && (
        <div className='absolute right-1/2 bottom-1/2  transform translate-x-1/2 translate-y-1/2 '>
          <div className='h-8 w-8 animate-spin rounded-full border-4 border-solid border-c-orange border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]' />
        </div>
      )}
      <div
        className={clsx('duration-500 h-screen flex-grow bg-c-light-blue-1  ', {
          'opacity-25 pointer-events-none': navigation.state === 'loading',
          'opacity-1 pointer-events-auto': navigation.state === 'idle',
          'lg:ml-[210px] lg:w-[calc(100vw-220px)] ': sideNavExpanded,
          'lg:ml-[75px] lg:w-[calc(100vw-85px)] ': !sideNavExpanded,
        })}
      >
        <DemoBanner />
        <BusinessContextProvider>
          <Outlet />
        </BusinessContextProvider>
      </div>
    </div>
  )
}

// const appInsights = TelemetryAppInsights()
// if (appInsights) {
//   appInsights.loadAppInsights()
// }

// if (isAuthenticated) {
//   console.log('login isAuthenticated')
// } else if (inProgress === InteractionStatus.None) {
//   console.log('login NOT isAuthenticated')
//   instance
//     .loginRedirect()
//     .then((r) => {
//       console.log('Logged in')
//     })
//     .catch((err) => {
//       console.error(err)
//     })
// }

// if (isAuthenticated) {
//   return (
//     <div className=' mx-auto bg-c-light-blue-2 flex flex-row font-poppins'>
//       <SideNavContainer />
//       <Outlet />
//     </div>
//   )
// }
// return <div>Not authenticated</div>
