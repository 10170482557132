import { useQuery, QueryClient } from '@tanstack/react-query'
import { LoaderFunction } from 'react-router-dom'
import { IMsalContext, useMsal } from '@azure/msal-react'
import { InteractionStatus } from '@azure/msal-browser'
import { getAccessToken, getAccessTokenFromPCA } from 'services/MSALService'
import ConnectAPIClient from 'services/ConnectAPIClient'
import { iDataLoaderParams } from 'data/Interfaces'
import { AxiosError } from 'axios'
import { DeviceDetailsQueryKeyBySerial } from 'services/apis/caching/device'
import { deviceBySerialNumber } from 'services/apis/urls/device'
import { iDeviceDetails } from './interface'

async function fetchDeviceDetails({
  token,
  deviceSerial,
}: {
  token: string
  deviceSerial: string
}): Promise<iDeviceDetails> {
  try {
    const resp = await ConnectAPIClient.get<iDeviceDetails>(
      deviceBySerialNumber(deviceSerial),
      token,
    )

    const deviceDetails = resp.data as iDeviceDetails

    return deviceDetails
  } catch (error) {
    console.error(error)
    throw error
  }
}

async function getDeviceDetails({
  msalContext,
  redirectPageURL,
  deviceSerial,
}: {
  msalContext: IMsalContext
  redirectPageURL: string
  deviceSerial: string
}) {
  const token = await getAccessToken({ msalContext, redirectPageURL })
  const deviceDetails = await fetchDeviceDetails({ token, deviceSerial })
  return deviceDetails
}

async function getOrFetchDeviceDetails({
  queryClient,
  token,
  deviceSerial,
}: {
  queryClient: QueryClient
  token: string
  deviceSerial: string
}) {
  const deviceDetailsCached = queryClient.getQueryData<iDeviceDetails>(
    DeviceDetailsQueryKeyBySerial(deviceSerial),
  )
  if (deviceDetailsCached) return deviceDetailsCached
  try {
    const deviceDetails = await fetchDeviceDetails({
      token,
      deviceSerial,
    })
    return deviceDetails
  } catch (error) {
    console.error(error)
    throw error
  }
}

export function useGetDeviceDetails(
  redirectPageURL: string,
  deviceSerial: string,
  enabled?: boolean,
) {
  const msalContext = useMsal()
  const { accounts, inProgress } = msalContext
  const isQueryEnabled = enabled && accounts.length > 0 && inProgress === InteractionStatus.None
  return useQuery(
    DeviceDetailsQueryKeyBySerial(deviceSerial),
    () => getDeviceDetails({ msalContext, redirectPageURL, deviceSerial }),
    {
      enabled: isQueryEnabled,
      onError: (error: AxiosError) => {
        console.error(error)
      },
    },
  )
}

export const DeviceDetailsLoader =
  (props: iDataLoaderParams): LoaderFunction =>
  async ({ params }) => {
    const { queryClient, msalInstance, msalAccounts, msalInProgress } = props
    if (!msalInstance) throw new Error('No MSAL Context')
    if (!queryClient) throw new Error('No query client')
    if (!params?.deviceSerialNumber) throw new Error('No device serial number')

    try {
      if (msalInProgress !== InteractionStatus.None) {
        return {}
      }
      if (msalAccounts.length === 0) {
        return {}
      }
      const token = await getAccessTokenFromPCA({ msalInstance })

      const deviceDetailsData = getOrFetchDeviceDetails({
        queryClient,
        token,
        deviceSerial: params.deviceSerialNumber,
      })

      return {
        deviceDetails: deviceDetailsData,
      }
    } catch (error) {
      console.error(error)
      return []
    }
  }

// export const deviceDetailsQuery = (deviceSerial: string) => ({
//   queryKey: DeviceDetailsQueryKeyBySerial(deviceSerial),
//   queryFn: async () => {
//     try {
//       const deviceDetails = await getDeviceDetails(deviceSerial)
//       return deviceDetails
//     } catch (error) {
//       console.error(error)
//       throw error
//     }
//   },

//   // retry: 10
// })
