import {
  iBusinessUnitTreeData,
  BusinessUnitTreeNodeType,
  iOrganizationTreeData,
  OrganisationTreeNodeType,
} from './OrganizationInterface'

// This function converts an object of type iBusinessUnitTreeData to an object of type business unit NodeType.
/**
 * Converts an object of type iBusinessUnitTreeData to an object of type business unit NodeType.
 * @param data - The data to be converted.
 * @returns The converted object.
 */
export function convertToObject(data: iBusinessUnitTreeData): BusinessUnitTreeNodeType {
  // It returns a new object with the following properties:
  return {
    // 'id' is copied from 'data.id'.
    id: data?.id,
    // 'name' is copied from 'data.name'.
    name: data?.name,
    // 'buLevelId' is copied from 'data.buLevelId'.
    buLevelId: data?.buLevelId,
    // 'customerId' is copied from 'data.customerId'.
    customerId: data?.customerId,
    // 'attributes' is an object with a single property 'buLevelName' that is copied from 'data.buLevelName'.
    attributes: { buLevelName: data?.buLevelName },
    // 'children' is an array that is created by mapping over 'data.subBusinessUnits'.
    // Each element of 'data.subBusinessUnits' is transformed by calling 'convertToObject' with an object that is a copy of the element with 'customerId' set to 'data.customerId'.
    children: data?.subBusinessUnits?.map((subData) =>
      convertToObject({ ...subData, customerId: data?.customerId }),
    ),
  }
}

// This function retrieves the parent ID from an array of organization Tree data.
// It takes an array of data and a level as parameters.

/**
 * Retrieves the parent ID from an array of organization Tree data.
 * @param data - The array of organization Tree data.
 * @param level - The level to match.
 * @returns The parent ID if found, otherwise null.
 */
function getParentId(data: iOrganizationTreeData[], level: number) {
  // It finds the first item in the data array where the level matches the provided level.
  const item = data.find((node) => node.level === level)
  // If such an item is found, it returns the id of the item.
  // If no such item is found, it returns null.
  return item ? item.id : null
}

// This function converts a flat array of organization Tree data into a nested business unit.
// It takes an array of data as a parameter.
/**
 * Converts a flat array of organization Tree data into a nested business unit.
 * @param data - The array of organization Tree data.
 * @returns The converted nested business unit.
 */
export function convertToNested(data: iOrganizationTreeData[]) {
  // It initializes an empty object 'nodes'.
  const nodes: OrganisationTreeNodeType = {}

  // It iterates over the data array and for each item, it adds a new property to 'nodes' with the key as the item's id and the value as the item with an empty 'children' array.
  data.forEach((item) => {
    nodes[item.id] = { ...item, children: [] }
  })

  // It initializes an empty object 'roots'.
  const roots: OrganisationTreeNodeType = {}

  // It iterates over the data array and for each item, it checks if the item's level is 1.
  // If the item's level is 1, it adds a new property to 'roots' with the key as the item's id and the value as the corresponding value from 'nodes'.
  // If the item's level is not 1, it finds the parent id by calling 'getParentId' with the data array and the item's level minus 1.
  // If a parent id is found, it pushes the corresponding value from 'nodes' to the 'children' array of the parent in 'nodes'.
  data.forEach((item) => {
    if (item.level === 1) {
      roots[item.id] = nodes[item.id]
    } else {
      const parentId = getParentId(data, item.level - 1)
      if (parentId) {
        nodes[parentId].children.push(nodes[item.id])
      }
    }
  })

  // It returns an array of the values of 'roots'.
  return Object.values(roots)
}
