import React, { useEffect, useState } from 'react'
import { iDeviceListDataWithStats } from 'pages/devices-2/data/DevicePageData'
import GenericWidget from 'components/viewComponents/GenericWidget'
import { iWidgetData } from 'components/viewComponents/interfaces'
import { groupDeviceDataByGasType } from 'pages/devices/data/DeviceDataUtils'
import { IFilterValues } from 'forms/FormModelInterface'
import { withErrorBoundary } from 'react-error-boundary'
import ErrorPage from 'pages/common/ErrorPage'
// export interface iWidgetData {
//     originalData: string | string[] | number | boolean | DateRange | null
//     displayText: string
//     value: number
// }

// export interface iWidgetProps {
//     title: string
//     widgetData: iWidgetData[]
//     widgetLabelCount: number
//     // onFilter: (filterRequestValue: string | string[] | number | boolean | DateRange | null) => void
// }
function DeviceCountByGasTypeWidget({
  deviceListData,
  onFilter,
  gasTypeFilteredParam,
}: {
  deviceListData: iDeviceListDataWithStats[]
  onFilter: (filterRequestValues: IFilterValues[]) => void
  gasTypeFilteredParam: string
}): JSX.Element {
  const [widgetData, setWidgetData] = useState<iWidgetData[]>()

  const onFilterData = (filterRequestValues: IFilterValues[]) => {
    const filterRequestValueToSend: IFilterValues[] = []
    filterRequestValues.forEach((item) => {
      filterRequestValueToSend.push({
        columnId: item.columnId,
        value: item.value,
      })
    })

    if (onFilter) {
      onFilter(filterRequestValueToSend)
    }
  }

  useEffect(() => {
    const wdata: iWidgetData[] = []

    if (deviceListData) {
      const deviceGasTypeCounts = groupDeviceDataByGasType(deviceListData)

      deviceGasTypeCounts.forEach((item) => {
        wdata.push({
          originalData: item.gasType,
          displayText: item.gasType,
          value: item.count,
          isFiltered: gasTypeFilteredParam === item.gasType,
          columnId: item.columnId,
        })
      })
      setWidgetData(wdata)
    }
  }, [deviceListData, gasTypeFilteredParam])

  return (
    <div>
      <GenericWidget
        widgetProps={{
          title: 'Gases',
          widgetData,
          widgetLabelCount: 6,
          onFilter: (filterRequestValues: IFilterValues[]) => onFilterData(filterRequestValues),
        }}
      />
    </div>
  )
}

export default withErrorBoundary(DeviceCountByGasTypeWidget, { FallbackComponent: ErrorPage })
