import { IselectOptionProps } from 'forms/FormModelInterface'
import { selectAllOption } from 'forms/FormUtils'
import { iNearMissData } from './interface'



export const prepareNearMissAlarmLevelValueForSelect = (
  reportData: iNearMissData[],
): IselectOptionProps[] => {
  if (!reportData || reportData.length === 0) return []

  const nearMissAlarmLevels = new Array<IselectOptionProps>()

  reportData.forEach((reportDataItem) => {
    if (reportDataItem.nearMissPercentage !== null) {
      nearMissAlarmLevels.push({
        label: reportDataItem.alarmLevelValue,
        value: reportDataItem.alarmLevelValue
      })
    }
  })

  // Remove duplicates
  const uniqueNearMissAlarmLevels = nearMissAlarmLevels.filter(
    (alarmLevelValue, index, self) => index === self.findIndex((e) => e.value === alarmLevelValue.value),
  )

  return uniqueNearMissAlarmLevels
}


export const prepareNearMissGasTypesForSelect = (
  reportData: iNearMissData[],
): IselectOptionProps[] => {
  if (!reportData || reportData.length === 0) return []

  const nearMissGasTypes = new Array<IselectOptionProps>()

  reportData.forEach((reportDataItem) => {
    if (reportDataItem.nearMissPercentage !== null) {
      nearMissGasTypes.push({
        label: reportDataItem.channelName,
        value: reportDataItem.channelName
      })
    }
  })

  // Remove duplicates
  const uniqueNearMissGasTypes = nearMissGasTypes.filter(
    (channelName, index, self) => index === self.findIndex((e) => e.value === channelName.value),
  )

  return uniqueNearMissGasTypes
}

function PrepareUserOptionsForSelect(reportData: iNearMissData[]): IselectOptionProps[] {
  if (!reportData || reportData.length === 0) return []

  const eventDeviceUsers = new Array<IselectOptionProps>()

  reportData.forEach((reportDataItem) => {
    if (reportDataItem.deviceUserName !== null) {
      eventDeviceUsers.push({
        label: reportDataItem.deviceUserName,
        value: reportDataItem.deviceUserName,
      })
    }
  })

  // Remove duplicates
  const uniqueEventDeviceUsers = eventDeviceUsers.filter(
    (eventDeviceUser, index, self) =>
      index === self.findIndex((e) => e.value === eventDeviceUser.value),
  )

  return uniqueEventDeviceUsers
}

export const prepareNearMissPercentValuesForSelect = (
  reportData: iNearMissData[],
): IselectOptionProps[] => {
  if (!reportData || reportData.length === 0) return []

  const nearMissPercentages = new Array<IselectOptionProps>()

  reportData.forEach((reportDataItem) => {
    if (reportDataItem.nearMissPercentage !== null) {
      nearMissPercentages.push({
        label: reportDataItem.nearMissPercentage.toString(),
        value: reportDataItem.nearMissPercentage,
      })
    }
  })

  // Remove duplicates
  const uniqueNearMissPercentages = nearMissPercentages.filter(
    (nearMissPercentage, index, self) => index === self.findIndex((e) => e.value === nearMissPercentage.value),
  )

  return uniqueNearMissPercentages
}

export const prepareFilterFormData = (reportData: iNearMissData[]) => {
  if (!reportData || reportData.length === 0)
    return { nearMissPercentages: [], nearMissGasTypes: [], nearMissAlarmLevels: [],  deviceUsers: [] }

  const nearMissPercentages = prepareNearMissPercentValuesForSelect(reportData)
  const nearMissGasTypes = prepareNearMissGasTypesForSelect(reportData)
  const nearMissAlarmLevels = prepareNearMissAlarmLevelValueForSelect(reportData)
  const deviceUsers = PrepareUserOptionsForSelect(reportData)

  nearMissPercentages.unshift(selectAllOption)
  nearMissGasTypes.unshift(selectAllOption)
  nearMissAlarmLevels.unshift(selectAllOption)
  deviceUsers.unshift(selectAllOption)
  
  return { nearMissPercentages, nearMissGasTypes, nearMissAlarmLevels, deviceUsers }

  
}
