import React, { memo } from 'react'
import clsx from 'clsx'
import { IFilterValues } from 'forms/FormModelInterface'
import Tooltip from 'components/atom/Tooltip'
import Show from 'components/atom/Show'
import { iGenericDataType } from './interfaces'

export interface DashboardDataLabelProps {
  Label?: string | JSX.Element
  LabelOriginalData?: iGenericDataType
  Data?: iGenericDataType
  className?: string
  filteredClassName?: string
  onClick?: (filterRequestValues: IFilterValues[]) => void
  isFiltered?: boolean
  columnId?: string
}

function DashboardDataSmall(props: DashboardDataLabelProps): JSX.Element {
  const {
    Label,
    Data,
    LabelOriginalData,
    onClick,
    className,
    filteredClassName,
    isFiltered,
    columnId,
  } = props

  const onFilterData = (data: string) => {
    if (onClick) {
      if (isFiltered) {
        onClick([
          {
            columnId: columnId ?? '',
            value: '',
          },
        ])
      } else {
        onClick([
          {
            columnId: columnId ?? '',
            value: data,
          },
        ])
      }
    }
  }

  // bg-c-light-blue-1
  // isFiltered ? 'bg-c-dark-blue-2' : 'bg-c-light-blue-1'
  return (
    <div
      role='button'
      tabIndex={0}
      className={clsx(
        isFiltered && filteredClassName && filteredClassName,
        isFiltered && !filteredClassName && 'bg-c-dark-blue-2 hover:bg-c-dark-blue-3 text-c-white',

        !isFiltered && className && className,
        !isFiltered && !className && 'bg-c-light-blue-1 hover:bg-c-light-blue-2 text-c-dark-blue-2',

        'grid grid-cols-2 rounded-2xl h-26 ',
      )}
      // className={clsx(
      //   'flex-grow flex flex-row justify-between items-center rounded-2xl h-26 pt-1.5 pb-1.5 ',
      //   className && className !== ''
      //     ? className
      //     : isFiltered
      //     ? 'bg-c-dark-blue-2 hover:bg-c-dark-blue-3 text-c-white'
      //     : 'bg-c-light-blue-1 hover:bg-c-light-blue-2 text-c-dark-blue-2',

      // )}
      onClick={() => onFilterData(LabelOriginalData as string)}
      onKeyDown={
        // () => onFilterData(LabelOriginalData as string)
        (event) => {
          if (event.key === 'Enter') {
            onFilterData(LabelOriginalData as string)
          }
        }
      }
    >
      <div className='col-span-1 pl-3 text-4xs font-bold leading-3  text-ellipsis self-center'>
        {Label}
      </div>

      <div className='col-span-1 self-center  pr-3'>
        <Show>
          <Show.When isTrue={typeof Data === 'string' || typeof Data === 'number'}>
            <Tooltip
              showOnlyWhenTextIsTruncate
              id={Data as unknown as string}
              tooltipText={Data as unknown as string}
              toolTipClass='tooltip top-12  w-fit right-0 text-center w-auto m-auto'
              className=' text-xs font-medium leading-normal align-middle text-end  text-ellipsis'
            >
              {Data as unknown as string}
            </Tooltip>
          </Show.When>
          <Show.Otherwise>{Data as unknown as React.ReactNode}</Show.Otherwise>
        </Show>
      </div>
    </div>
  )
}

DashboardDataSmall.defaultProps = {
  Label: '',
  LabelOriginalData: '',
  Data: '',
  className: '',
  filteredClassName: '',
  onClick: () => {},
  isFiltered: false,
  columnId: '',
}
export default memo(DashboardDataSmall)
