import React from 'react'
import { useIntl } from 'react-intl'
import { ExportWidgetType } from 'exportReports/DataExportInterfaces'
import { iDeviceTypeCount } from 'pages/devices/data/iDevicesList'
import { iFaultData } from '../data/FaultsData'

import {
  groupFaultsByDeviceType,
  groupFaultByFaultType,
  countFaultsInLastXDays,
} from '../data/FaultsDataUtils'

const XDaysToCountForFaults = [7, 30, 60, 90]

const GeFaultyByDeviceTypeForReport = (faultsData: iFaultData[]) => {
  const faultCountByDeviceTypeForReport: ExportWidgetType = {
    WidgetTitle: '',
    ColumnHeaders: [],
    ColumnDatas: [],
  }
  const faultByDeviceType: iDeviceTypeCount[] = groupFaultsByDeviceType(faultsData)
  faultByDeviceType.forEach((val) => {
    faultCountByDeviceTypeForReport.ColumnHeaders.push(val.deviceType)
    faultCountByDeviceTypeForReport.ColumnDatas.push(val.count)
  })
  faultCountByDeviceTypeForReport.WidgetTitle = 'Type'

  return faultCountByDeviceTypeForReport
}

const GetFaultsByDaysForReport = (faultsData: iFaultData[]) => {
  const faultCountByDaysForReport: ExportWidgetType = {
    WidgetTitle: '',
    ColumnHeaders: [],
    ColumnDatas: [],
  }
  const faultCountInXDays = XDaysToCountForFaults.map((days) => {
    const count = countFaultsInLastXDays(faultsData, days)
    return {
      days: `${days.toString()} days`,
      count,
    }
  })
  faultCountInXDays.forEach((val) => {
    faultCountByDaysForReport.ColumnHeaders.push(val.days)
    faultCountByDaysForReport.ColumnDatas.push(val.count)
  })
  faultCountByDaysForReport.WidgetTitle = 'Fault in last'

  return faultCountByDaysForReport
}

const GetFaultsByFaultTypeForReport = (faultsData: iFaultData[]) => {
  const intl = useIntl()
  const faultsByTypeForReport: ExportWidgetType = {
    WidgetTitle: '',
    ColumnHeaders: [],
    ColumnDatas: [],
  }
  const devicesDueByMonths = groupFaultByFaultType(faultsData)
  devicesDueByMonths.forEach((val) => {
    faultsByTypeForReport.ColumnHeaders.push(
      intl.formatMessage({
        id: `Eventlog.FaultTypes.${val.faultType}`,
      }),
    )
    faultsByTypeForReport.ColumnDatas.push(val.count)
  })
  faultsByTypeForReport.WidgetTitle = 'Fault type'
  return faultsByTypeForReport
}

function FormatServiceFaultWidget(faultsData: iFaultData[]) {
  const ServiceFaultWidgetForReport: ExportWidgetType[] = []
  const faultByDeviceTypeForReport: ExportWidgetType = GeFaultyByDeviceTypeForReport(faultsData)
  ServiceFaultWidgetForReport.push(faultByDeviceTypeForReport)
  const faultByDaysForReport: ExportWidgetType = GetFaultsByDaysForReport(faultsData)
  ServiceFaultWidgetForReport.push(faultByDaysForReport)
  const faultByFaultTypeForReport: ExportWidgetType = GetFaultsByFaultTypeForReport(faultsData)
  ServiceFaultWidgetForReport.push(faultByFaultTypeForReport)

  return ServiceFaultWidgetForReport
}
export default FormatServiceFaultWidget
