import React from 'react'
import { SvgProps } from 'types/SVGProps'

function NavPreviousIcon(props: SvgProps): JSX.Element {
  const { stroke, fill, className, toggleClick } = props

  return (
    <div className=''>
      <svg
        className={className}
        width='20'
        height='20'
        viewBox='0 0 20 20'
        fill='none'
        onClick={toggleClick}
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M12 14.0392L8 10.0196L12 5.99997'
          //  stroke={stroke ?? '#FFFFFF'}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </div>
  )
}

export default NavPreviousIcon
