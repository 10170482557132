import React from 'react'
import { CalenderDateProps } from './CalenderType'
import { dayDateToDate } from './CalenderUtils'

function CalenderDate({
  day,
  onDateSelect,
  shouldShowFilter,
  children,
  className,
  ...rest
}: CalenderDateProps) {
  const handleClick = () => {
    if (shouldShowFilter) {
      onDateSelect(day)
    }
  }

  return (
    <button
      {...rest}
      type='button'
      title='Select date or range of dates to filter'
      onClick={handleClick}
      className={className}
    >
      <div className={`flex h-6 w-6 z-10 ${day.isToday ? 'text-c-blue font-semibold' : ''}`}>
        {dayDateToDate(`${day?.date}`).getDate()}
      </div>
      {children}
    </button>
  )
}

CalenderDate.displayName = 'CalenderDate'
export default CalenderDate
