import React, { useState, useEffect, useRef, useContext } from 'react'
import { CloseIcon, MenuIcon, SupportIcon } from 'assets/icons'

import clsx from 'clsx'
import crowconWhiteLogo from 'assets/images/CrowconConnect_WhiteLogo-03.png'
import { ScreenWidthsInPx } from 'utils/Constants'
import { UserAccountContext } from 'contexts/UserAccountContext'
import { NavLink } from 'react-router-dom'
import UserIdentification from './sideNavComponents/UserIdentification'
import FooterLinks from './sideNavComponents/FooterLinks'
import TopNavMenu from './sideNavComponents/TopNavMenu'

interface sideNavStausUpdaterProps {
  sideNavStatusUpdater: (status: boolean) => void
}

function TopNavContainer(props: sideNavStausUpdaterProps) {
  const { sideNavStatusUpdater } = props
  const { state: userInfo } = useContext(UserAccountContext)
  const windowSize = useRef([window.innerWidth])
  const [sideNavExpanded, setSideNavExpanded] = useState(false)

  useEffect(() => {
    const windowWidth = windowSize.current[0]
    if (windowWidth < ScreenWidthsInPx.xl) {
      setSideNavExpanded(false)
      sideNavStatusUpdater(false)
    } else {
      setSideNavExpanded(true)
      sideNavStatusUpdater(true)
    }
  }, [windowSize])

  const collapseSideNav = () => {
    setSideNavExpanded(false)
    sideNavStatusUpdater(false)
  }

  const expandSideNav = () => {
    setSideNavExpanded(true)
    sideNavStatusUpdater(true)
  }

  const collapseSideNavOnSelection = () => {
    const windowWidth = windowSize.current[0]
    if (windowWidth < ScreenWidthsInPx.xl) {
      collapseSideNav()
    }
  }

  const toggleSideNavExpansion = () => {
    if (sideNavExpanded === true) {
      collapseSideNav()
    } else {
      expandSideNav()
    }
  }
  // h-530 sm:h-370 md:h-64
  return (
    <div className={clsx('bg-c-dark-blue-1 px-2 flex flex-col  duration-500')}>
      {/* Logo and menu / close icon */}
      <div className='flex flex-row py-1 items-center justify-between'>
        <div className=''>
          <button
            type='button'
            tabIndex={0}
            onClick={toggleSideNavExpansion}
            className='rounded-full flex justify-center items-center h-10 w-10 p-3  bg-c-dark-blue-1 outline-0 focus:border-1 focus:border-c-blue focus:bg-c-dark-blue-2 hover:bg-c-dark-blue-2  active:bg-c-blue'
          >
            {sideNavExpanded === false ? (
              <MenuIcon className=' w-3.5 h-3.5 ' />
            ) : (
              <CloseIcon className=' w-3.5 h-3.5' />
            )}
          </button>
        </div>

        <div className={clsx('duration-500 w-24 ml-1')}>
          <img src={crowconWhiteLogo} alt='Company Logo' className={clsx('w-24 object-contain')} />
        </div>

        <div className='flex flex-row h-12 items-center'>
          <NavLink to='help'>
            <div className='p-3'>
              <SupportIcon className='w-5 h-5' />
            </div>
          </NavLink>
          <NavLink to='profile'>
            {({ isActive }) => (
              <UserIdentification
                isActive={isActive}
                userFirstName={userInfo?.user?.firstName}
                userLastName={userInfo?.user?.lastName}
                sideNavExpanded={sideNavExpanded}
              />
            )}
          </NavLink>
        </div>
      </div>

      {/* End logo and menu / close icon */}

      {sideNavExpanded && (
        <div className=''>
          <div className={clsx('mb-3 border-b-2 border-c-white/10 ')} />
          <div className='mb-6'>
            <TopNavMenu
              ExpandedStatus={sideNavExpanded}
              OnItemSelected={collapseSideNavOnSelection}
            />
          </div>
          <div className={clsx('mb-6 border-b-2 border-c-white/10 ')} />
          <div className='mb-7'>
            <FooterLinks sideNavExpanded={sideNavExpanded} />
          </div>
        </div>
      )}

      {/* <div className='flex flex-col mt-10 ml-5 mb-8 mr-5'>
        <div
          className={clsx(
            'border-b-2 border-c-white/10 mx-auto duration-500',
            sideNavExpanded ? 'w-44' : 'w-8',
          )}
        />
        <div className='mt-10 static'>
          <FooterLinks sideNavExpanded={sideNavExpanded} />
        </div>

      </div> */}
    </div>
  )
}

export default TopNavContainer
