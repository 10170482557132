import { useThemeHook } from 'data/ThemeHook'
import React from 'react'
import { Line } from 'react-chartjs-2'
import { GraphReadingType } from '../utils/constant'

export default function LineChart({ data }: { data: GraphReadingType[] }) {
  const { theme } = useThemeHook()
  const options = {
    maintainAspectRatio: false,
    resizeDelay: 1,
    responsive: true,
    layout: {
      autoPadding: false,
      padding: {
        top: -50,
        bottom: 0,
        left: 0,
        right: 0,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  }
  return (
    <Line<GraphReadingType[]>
      options={options}
      data={{
        datasets: [
          {
            data: data.map((item) => ({
              ...item,
              date: item.date.split(' ')[1],
            })),
            backgroundColor: (ctx) => {
              const alarmValue = ctx.raw as unknown as GraphReadingType
              return alarmValue?.isAlarm
                ? theme.extend.colors['c-red-1']
                : theme.extend.colors['c-green']
            },
            borderColor: theme.extend.colors['c-blue'],
            borderWidth: 1,
            fill: true,
            tension: 0.4,
            parsing: {
              yAxisKey: 'value',
              xAxisKey: 'date',
            },
          },
        ],
      }}
    />
  )
}
