import {
  BrowserAuthError,
  IPublicClientApplication,
  InteractionRequiredAuthError,
  RedirectRequest,
  SilentRequest,
} from '@azure/msal-browser'
import { IMsalContext } from '@azure/msal-react'
import { ADB2CPolicies, SignInAuthority, apiScopes, siteBaseURL } from 'utils/AuthConfig'

const redirectTokenRequest: RedirectRequest = {
  scopes: apiScopes,
  authority: SignInAuthority,
}

const silentTokenRequest: SilentRequest = {
  scopes: apiScopes,

  // authority: 'https://connectv2trial1.b2clogin.com/connectv2trial1.onmicrosoft.com/b2c_1a_Smart_HRD_SUP_ONLY',
  authority: SignInAuthority,
  // forceRefresh: false,
}

export interface getAccessTokenProps {
  msalContext: IMsalContext
  redirectPageURL: string
  adb2cPolicy?: string
}

export interface getAccessTokenPropsForPCA {
  msalInstance: IPublicClientApplication
  adb2cPolicy?: string
}

export async function getAccessTokenFromPCA(props: getAccessTokenPropsForPCA): Promise<string> {
  const { msalInstance, adb2cPolicy } = props

  const tokenRequest: SilentRequest = {
    ...silentTokenRequest,
    authority: adb2cPolicy ?? silentTokenRequest.authority,
  }
  let fullyQualifiedPolicyName = SignInAuthority
  try {
    const allAccounts = msalInstance.getAllAccounts()
    if (allAccounts.length === 0) {
      throw new InteractionRequiredAuthError('No accounts found')
    }
    const activeAccount = msalInstance.getActiveAccount()

    const policyName =
      (activeAccount?.idTokenClaims?.tfp as string) || (activeAccount?.idTokenClaims?.acr as string)

    if (policyName) {
      fullyQualifiedPolicyName = ADB2CPolicies.find(
        (policy) => policy.name === policyName.toUpperCase(),
      )?.authority as string
    }

    const firstAccount = allAccounts[0]
    if (!activeAccount) {
      if (allAccounts.length > 0) {
        const tokenRequestWithAccount = {
          ...tokenRequest,
          account: firstAccount,
        }

        const authResponse = await msalInstance.acquireTokenSilent({
          ...tokenRequestWithAccount,
          account: activeAccount ?? firstAccount,

          redirectUri: `${siteBaseURL}/blank.html`,
          authority: fullyQualifiedPolicyName,
        })
        return authResponse.accessToken
      }
    }
    const authResponse = await msalInstance.acquireTokenSilent({
      ...tokenRequest,
      account: activeAccount || firstAccount,

      redirectUri: `${siteBaseURL}/blank.html`,
      authority: fullyQualifiedPolicyName,
    })
    return authResponse.accessToken
  } catch (error) {
    console.log('error', error)
    if (error instanceof InteractionRequiredAuthError || error instanceof BrowserAuthError) {
      const interactiveTokenRequest = {
        ...redirectTokenRequest,
        account: msalInstance.getActiveAccount() ?? undefined,
        redirectStartPage: '/',
        // loginHint: msalInstance.getActiveAccount()?.
      }
      await msalInstance.acquireTokenRedirect(interactiveTokenRequest)
    }
    throw error
  }
}

export async function getAccessToken(props: getAccessTokenProps) {
  const { msalContext, adb2cPolicy } = props
  const { instance } = msalContext

  return getAccessTokenFromPCA({ msalInstance: instance, adb2cPolicy })

  // const tokenRequest = {
  //   ...silentTokenRequest,
  //   authority: adb2cPolicy ?? silentTokenRequest.authority,
  // }

  // return instance
  //   .acquireTokenSilent(tokenRequest)
  //   .then((response) => {
  //     console.log('response', response)
  //     return response.accessToken
  //   })
  //   .catch(async (error) => {
  //     if (error instanceof InteractionRequiredAuthError || error instanceof BrowserAuthError) {
  //       const interactiveTokenRequest = {
  //         ...redirectTokenRequest,
  //         account: instance.getActiveAccount() ?? undefined,
  //         redirectStartPage: redirectPageURL,
  //       }
  //       instance.acquireTokenRedirect(interactiveTokenRequest)
  //     }
  //     console.error('error', error)
  //     throw error
  //   })
}
