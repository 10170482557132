import { IFilterQueryParamValues, IselectOptionProps } from 'forms/FormModelInterface'
import { UsersList } from '../data/iUsersList'

export const UsersPageColumnIds = {
  firstName: 'firstName',
  lastName: 'lastName',
  businessUnitName: 'businessUnit',
  roleName: 'role',
  email: 'email',
  status: 'status',
  customerName: 'customer',
}

export const UsersPageParamLabels = {
  firstName: 'firstName',
  lastName: 'lastName',
  businessUnitName: 'businessUnit',
  roleName: 'role',
  email: 'email',
  status: 'status',
  customerName: 'customer',
}

export const UsersPageFilterParamValues: IFilterQueryParamValues[] = [
  {
    paramName: UsersPageParamLabels.firstName,
    paramValue: null,
  },
  {
    paramName: UsersPageParamLabels.lastName,
    paramValue: null,
  },
  {
    paramName: UsersPageParamLabels.businessUnitName,
    paramValue: null,
  },
  {
    paramName: UsersPageParamLabels.email,
    paramValue: null,
  },
  {
    paramName: UsersPageParamLabels.status,
    paramValue: null,
  },
  {
    paramName: UsersPageParamLabels.roleName,
    paramValue: null,
  },
  {
    paramName: UsersPageParamLabels.customerName,
    paramValue: null,
  },
]

export type UsersPageFilterFormType = {
  firstName: IselectOptionProps
  lastName: IselectOptionProps
  email: IselectOptionProps
  status: IselectOptionProps
  businessUnitName: IselectOptionProps
  roleName: IselectOptionProps
  customerName: IselectOptionProps
}

export const UsersPageColumnHeader = {
  [UsersPageColumnIds.firstName]: 'First Name',
  [UsersPageColumnIds.lastName]: 'Last Name',
  [UsersPageColumnIds.businessUnitName]: 'Business Unit',
  [UsersPageColumnIds.roleName]: 'Role',
  [UsersPageColumnIds.email]: 'Email',
  [UsersPageColumnIds.status]: 'Status',
  [UsersPageColumnIds.customerName]: 'Customer',
}

export const getUsersWithCustomer = (data: UsersList[]) =>
  data?.reduce(
    (prevUser: UsersList, newUser: UsersList): UsersList => ({
      users: [
        ...prevUser.users,
        ...newUser.users.map((user) => ({
          ...user,
          customer: newUser.customer,
          businessUnit: {
            name: user.role?.[0]?.businessUnitName,
            id: user?.role?.[0].businessUnitId,
          },
        })),
      ],
    }),
    { users: [] },
  )?.users

export function getFilteredUsers(
  data: UsersList[],
  selectedCustomer?: string,
  selectedBusinessUnit?: string,
) {
  if (selectedCustomer && selectedBusinessUnit) {
    if (selectedCustomer === 'all') {
      return getUsersWithCustomer(data)
    }
    if (selectedCustomer !== 'all' && selectedBusinessUnit === 'all') {
      return getUsersWithCustomer(data).filter((user) => user.customer?.id === selectedCustomer)
    }
    if (selectedCustomer !== 'all' && selectedBusinessUnit !== 'all') {
      return getUsersWithCustomer(data).filter(
        (user) =>
          user.customer?.id === selectedCustomer && user.businessUnit?.id === selectedBusinessUnit,
      )
    }
  }
  return getUsersWithCustomer(data)
}
