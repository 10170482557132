import React, { useContext, useEffect, useMemo, useState, useCallback } from 'react'
import clsx from 'clsx'
import { useLocation, useSearchParams } from 'react-router-dom'

import { dateRangeOptions, selectAllOption } from 'forms/FormUtils'
import { IFilterValues } from 'forms/FormModelInterface'
import { OperatorDeviceCountTypes } from 'utils/CommonEnums'
import { iOperatorInfo, useOperatorList } from 'data/OperatorListHook'
import ExportData from 'exportReports/ExportData'

import { BusinessContext } from 'contexts/BusinessContext'
import BusinessAndCustomerSelection from 'components/modules/BusinessAndCustomerSelection'
import { withPageTracking } from 'utils/AppInsightConfig'
import { useGenericEventHandler } from 'data/GenericEventHandler'
import OperatorsPageTable from './OperatorsPageTable'

import {
  OperatorsPageColumnIds,
  OperatorsPageParamLabels,
  OperatorsPageFilterFormType,
} from './OperatorsPageUtils'

import OperatorsPageFilterForm from './OpratorsPageFilterForm'
import OperatorFilterFormat from '../export/OperatorFilterFormat'
import { OperatorReportColumns } from '../export/OperatorReportFormat'
import ActiveInActiveOperator from '../widget/ActiveInActiveOperator'
import HighestOperator from '../widget/HighestOperator'
import DevicesInUse from '../widget/DevicesInUse'

function OperatorsPage() {
  useEffect(() => {
    document.title = 'Operators'
  }, [])

  const location = useLocation()
  const redirectPageURL = `${location.pathname}${location.search}`

  const [showFilterForm, setShowFilterForm] = useState(false)

  // global filter params
  const [searchParams, setSearchParams] = useSearchParams()

  // filter form params
  const childBUParam = searchParams.get(OperatorsPageParamLabels.businessUnit) ?? ''
  const status = searchParams.get(OperatorsPageParamLabels.status) ?? ''
  const fullNameParam = searchParams.get(OperatorsPageParamLabels.name) ?? ''
  const devicesInUseParam = searchParams.get(OperatorsPageParamLabels.devicesInUse) ?? ''
  const operatorIDParam = searchParams.get(OperatorsPageParamLabels.operatorId) ?? ''

  /**
   * Business Units List and Customer List from BusinessContext
   */
  const {
    customerDataFromQuery,
    businessUnitDataFromQuery,
    selectedBusinessUnit,
    selectedCustomer,
  } = useContext(BusinessContext)

  const businessUnitsList = businessUnitDataFromQuery?.data ?? []
  const customerList = customerDataFromQuery?.data ?? []
  const selectedCustomerParam = selectedCustomer?.id ?? ''
  const selectedMainBUParam = selectedBusinessUnit?.id ?? ''

  const [filteredOperatorsPageData, setfilteredOperatorsPageData] = useState<iOperatorInfo[]>([])

  const { genericEventHandler } = useGenericEventHandler()

  const {
    data: operatorListData,
    isLoading: isOperatorListDataLoading,
    isError: isOperatorListDataError,
    error: operatorListDataError,
  } = useOperatorList(
    redirectPageURL,
    selectedMainBUParam,
    selectedCustomerParam,
    businessUnitsList,
    businessUnitsList !== undefined,
  )

  useEffect(() => {
    if (isOperatorListDataError) {
      genericEventHandler({
        onlyTrack: true,
        severity: 'error',
        error: operatorListDataError,
        message: operatorListDataError.message || 'Error in fetching Operator List',
        extraData: {
          component: 'OperatorsPage',
          action: 'useOperatorList',
        },
      })
    }
  }, [isOperatorListDataError, operatorListDataError])

  const resetFilterFormParams = () => {
    searchParams.delete(OperatorsPageParamLabels.name)
    searchParams.delete(OperatorsPageParamLabels.devicesInUse)
    searchParams.delete(OperatorsPageParamLabels.businessUnit)
    searchParams.delete(OperatorsPageParamLabels.operatorId)
    searchParams.delete(OperatorsPageParamLabels.status)
    setSearchParams(searchParams)
  }

  const handleFilterFormSubmit = useCallback(
    (formData: IFilterValues[]) => {
      formData.forEach((filter) => {
        if (filter.columnId === OperatorsPageColumnIds.fullName) {
          if (filter?.value === '') {
            searchParams.delete(OperatorsPageParamLabels.name)
          } else {
            searchParams.set(OperatorsPageParamLabels.name, filter?.value as string)
          }
        }

        if (filter.columnId === OperatorsPageColumnIds.devicesInUse) {
          if (filter?.value === '') {
            searchParams.delete(OperatorsPageParamLabels.devicesInUse)
          } else {
            searchParams.set(OperatorsPageParamLabels.devicesInUse, filter?.value as string)
          }
        }

        if (filter.columnId === OperatorsPageColumnIds.businessUnit) {
          if (filter?.value === '') {
            searchParams.delete(OperatorsPageParamLabels.businessUnit)
          } else {
            searchParams.set(OperatorsPageParamLabels.businessUnit, filter?.value as string)
          }
        }
        if (filter.columnId === OperatorsPageColumnIds.status) {
          if (filter?.value === '') {
            searchParams.delete(OperatorsPageParamLabels.status)
          } else {
            searchParams.set(OperatorsPageParamLabels.status, filter?.value as string)
          }
        }

        if (filter.columnId === OperatorsPageColumnIds.operatorId) {
          if (filter?.value === '') {
            searchParams.delete(OperatorsPageParamLabels.operatorId)
          } else {
            searchParams.set(OperatorsPageParamLabels.operatorId, filter?.value as string)
          }
        }
      })
      setSearchParams(searchParams)
    },
    [searchParams, setSearchParams],
  )

  const handleFilterFormReset = () => {
    // console.log('Reset form')
    resetFilterFormParams()
  }

  const filterTableValues = useMemo(
    () => [
      {
        columnId: OperatorsPageColumnIds.fullName,
        value: fullNameParam ?? '',
      },
      {
        columnId: OperatorsPageColumnIds.devicesInUse,
        value: devicesInUseParam ?? '',
      },
      {
        columnId: OperatorsPageColumnIds.businessUnit,
        value: childBUParam ?? '',
      },
      {
        columnId: OperatorsPageColumnIds.status,
        value: status ?? '',
      },
      {
        columnId: OperatorsPageColumnIds.operatorId,
        value: operatorIDParam ?? '',
      },
    ],
    [fullNameParam, devicesInUseParam, status, childBUParam, operatorIDParam],
  )

  const convertISelectOptionPropsToServiceFaultsFormType = useMemo(() => {
    const fullNameFiltered =
      filterTableValues.find((filter) => filter.columnId === OperatorsPageColumnIds.fullName)
        ?.value ?? ''
    const devicesInUseFiltered = filterTableValues.find(
      (filter) => filter.columnId === OperatorsPageColumnIds.devicesInUse,
    )?.value as string
    const operatorIDFiltered =
      filterTableValues.find((filter) => filter.columnId === OperatorsPageColumnIds.operatorId)
        ?.value ?? ''
    const statusFiltered =
      filterTableValues.find((filter) => filter.columnId === OperatorsPageColumnIds.status)
        ?.value ?? ''
    const businessUnitFiltered =
      filterTableValues.find((filter) => filter.columnId === OperatorsPageColumnIds.businessUnit)
        ?.value ?? ''

    const r: OperatorsPageFilterFormType = {
      fullname: {
        value: fullNameFiltered as string,
        label: fullNameFiltered as string,
      },
      devicesInUse:
        devicesInUseFiltered === ''
          ? {
              label: OperatorDeviceCountTypes.All,
              value: OperatorDeviceCountTypes.All,
            }
          : {
              label: devicesInUseFiltered as string,
              value: devicesInUseFiltered as string,
            },

      status:
        statusFiltered === ''
          ? {
              label: OperatorDeviceCountTypes.All,
              value: OperatorDeviceCountTypes.All,
            }
          : {
              label: statusFiltered as string,
              value: statusFiltered as string,
            },

      businessUnit:
        businessUnitFiltered === ''
          ? selectAllOption
          : {
              value: businessUnitFiltered as string,
              label: businessUnitFiltered as string,
            },
      operatorId:
        operatorIDFiltered === ''
          ? selectAllOption
          : {
              value: operatorIDFiltered as string,
              label: operatorIDFiltered as string,
            },
    }

    return r
  }, [filterTableValues, dateRangeOptions])

  useEffect(() => {
    function hideFilterFormHandler(e: KeyboardEvent) {
      if (e.key === 'Escape' && showFilterForm) {
        setShowFilterForm(false)
      }
    }
    window.addEventListener('keyup', hideFilterFormHandler)

    return () => {
      window.removeEventListener('keyup', hideFilterFormHandler)
    }
  }, [showFilterForm])

  return (
    <>
      <BusinessAndCustomerSelection pageTitle='Operators' />
      <div
        id='operators-main'
        className='flex flex-col h-[calc(100vh-80px)] w-full'
        aria-hidden='true'
        onClick={() => showFilterForm && setShowFilterForm(false)}
      >
        <div
          id='operator-page-widgets'
          className={clsx(
            'bg-c-light-blue-2 px-3 md:px-5 py-3 md:py-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-4 gap-y-2 ',
          )}
        >
          <ActiveInActiveOperator
            data={operatorListData}
            onFilter={handleFilterFormSubmit}
            statusFilteredParam={
              filterTableValues.find((filter) => filter.columnId === OperatorsPageColumnIds.status)
                ?.value as string
            }
          />
          <div className='md:max-lg:col-span-2 '>
            <HighestOperator
              data={operatorListData}
              onFilter={handleFilterFormSubmit}
              operatorFilteredParam={
                filterTableValues.find(
                  (filter) => filter.columnId === OperatorsPageColumnIds.fullName,
                )?.value as string
              }
            />
          </div>
          <div className='md:max-lg:row-start-1'>
            <DevicesInUse
              data={operatorListData}
              onFilter={handleFilterFormSubmit}
              statusFilteredParam={
                filterTableValues.find(
                  (filter) => filter.columnId === OperatorsPageColumnIds.devicesInUse,
                )?.value as string
              }
            />
          </div>
        </div>
        <div id='operator-page-table' className=' h-full'>
          <OperatorsPageTable
            tableData={operatorListData ?? []}
            isLoading={isOperatorListDataLoading}
            updateFilteredData={setfilteredOperatorsPageData}
            // updateFilteredData={(filteredData: iDevice[]) =>
            //   setfilteredOperatorsPageData(filteredData)
            // }
            filterValues={filterTableValues}
            onFilterFormSubmit={handleFilterFormSubmit}
            onFilterFormReset={() => handleFilterFormReset()}
            onShowFilter={() => setShowFilterForm(true)}
            filterSummary={OperatorFilterFormat(
              filterTableValues,
              customerList && selectedCustomerParam !== ''
                ? customerList?.find((c) => c.id === selectedCustomerParam)?.name || ''
                : customerList?.[0]?.name || '',
              businessUnitsList && selectedMainBUParam !== ''
                ? businessUnitsList?.find((c) => c.id === selectedMainBUParam)?.name || ''
                : businessUnitsList?.[0]?.name || '',
            )}
          />
        </div>
      </div>
      <div
        id='operators-page-filter-form'
        className={clsx(
          'filter-form hideScrollBar',
          showFilterForm ? 'z-50 -ml-80 opacity-100' : 'opacity-0 -z-50',
        )}
      >
        <OperatorsPageFilterForm
          selectedFilterValues={convertISelectOptionPropsToServiceFaultsFormType}
          optionsData={filteredOperatorsPageData}
          onFilterSubmit={(formData: IFilterValues[]) => handleFilterFormSubmit(formData)}
          onFilterReset={() => handleFilterFormReset()}
          onFilterFormClose={() => setShowFilterForm(false)}
        />
      </div>
    </>
  )
}

export default withPageTracking(OperatorsPage)
