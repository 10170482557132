import { IMsalContext } from '@azure/msal-react'
import { AxiosError } from 'axios'
import { iBusinessUnit, iDeviceUser, DeviceChannelInfoDto } from 'data/DeviceListHook'
import { iCrowconProblemDetails, parseAPIError } from 'services/APIErrorParser'
import { crudDeviceURL } from 'services/apis/urls/device'

import ConnectAPIClient from 'services/ConnectAPIClient'
import { getAccessToken } from 'services/MSALService'
import { PortableTypes } from 'utils/CommonEnums'

export type iDevice = {
  id: string
  serialNumber: string
  deviceType: PortableTypes // PortableTypes,
  customerId: string
  customerAssetNumber: string
  lastCalibratedDate?: string
  calibrationDueDate?: string
  businessUnit: iBusinessUnit
  deviceUser: iDeviceUser
  channels: Array<DeviceChannelInfoDto>
  status: string
  Note: string
}

export interface ideviceAssignmentUpdateDTO {
  deviceUserId: string
  assignedOn?: string
  unassignedOn?: string
}

export interface iUpdateDeviceDTO {
  deviceId: string
  businessUnitId: string
  customerAssetNumber?: string
  Status?: number
  Note?: string
  deviceAssignment?: ideviceAssignmentUpdateDTO
}

interface iUpdateDeviceProps {
  updateDeviceDTO: iUpdateDeviceDTO
  msalContext: IMsalContext
}

export const updateDevice = async (updateDeviceProps: iUpdateDeviceProps) => {
  const { updateDeviceDTO, msalContext } = updateDeviceProps

  const token = await getAccessToken({ msalContext, redirectPageURL: '' })
  try {
    const resp = await ConnectAPIClient.put(crudDeviceURL(), token, '0.2', updateDeviceDTO)
    return resp.data
  } catch (error) {
    const axiosError = error as AxiosError
    const errorDescription = parseAPIError(axiosError?.response?.data as iCrowconProblemDetails)
    throw new Error(errorDescription)
  }
}
