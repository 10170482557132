import React from 'react'
import { IFilterValues, DateRange } from 'forms/FormModelInterface'
import { FormatDateRange } from 'exportReports/ExportDataUtils'
import { SafetyPageColumnIds } from '../view/SafetyPageUtils'

const SafetyReportFilterLookup = [
  {
    columId: SafetyPageColumnIds.businessUnit,
    label: 'Business Unit',
    format: (val: string) => val,
  },
  { columId: SafetyPageColumnIds.serialNumber, label: 'Serial #', format: (val: string) => val },
  {
    columId: SafetyPageColumnIds.assetNumber,
    label: 'Asset No.',
    format: (val: string) => val,
  },
  { columId: SafetyPageColumnIds.gasName, label: 'Gas', format: (val: string) => val },
  { columId: SafetyPageColumnIds.alarmType, label: 'Alarm', format: (val: string) => val },
  { columId: SafetyPageColumnIds.alarmLevel, label: '% Level', format: (val: string) => val },
  {
    columId: SafetyPageColumnIds.alarmSetPoint,
    label: 'Alarm 1 Set point',
    format: (val: string) => val,
  },
  {
    columId: SafetyPageColumnIds.alarmDate,
    label: 'Date & Time',
    format: (val: DateRange) => FormatDateRange(val),
  },
  {
    columId: SafetyPageColumnIds.deviceUser,
    label: 'Assigned To',
    format: (val: string) => val,
  },
]

const mapColumnLabel = (filterVal: IFilterValues) => {
  const labelToReturn = SafetyReportFilterLookup.find(
    (l) => l.columId === filterVal.columnId,
  )?.label
  return labelToReturn
}

const mapColumnValue = (filterVal: any) => {
  const valueToReturn = SafetyReportFilterLookup.find(
    (l) => l.columId === filterVal.columnId,
  )?.format(filterVal?.value || '')
  return valueToReturn
}

function SafetyFilterFormat(filterValues: IFilterValues[], customer: string, mainBu: string) {
  let summary = `Customer: ${customer}, Global business unit: ${mainBu}\n`
  filterValues?.forEach((f) => {
    if (f.value)
      summary = `${`${summary} ${mapColumnLabel(f)}`}: ${f.value ? mapColumnValue(f) : ''},`
  })

  return summary.replace(/,\s*$/, '')
}

export default SafetyFilterFormat
