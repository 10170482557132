import React, { useEffect, useMemo, useState, useContext, useCallback } from 'react'
import { useLocation, useParams, useSearchParams } from 'react-router-dom'
import { DateRange, IFilterValues, IselectOptionProps } from 'forms/FormModelInterface'
import { isAfter, isBefore, isEqual, fromUnixTime, sub } from 'date-fns'
import { useDeviceDetails } from 'pages/deviceDetails/rootPage/view/DeviceDetailsRoot'
import { ColumnDef, Table } from '@tanstack/react-table'
import { FormattedDate, FormattedTime } from 'react-intl'
import { ScreenWidths, returnDateWithoutTimeOffset } from 'utils/Constants'
import { DateRangeColumnFilterUnixTimeFn, dateRangeOptions } from 'forms/FormUtils'
import TsTable from 'components/table/TsTable'
import { DateRangeSelectorTypes, DeviceDetailPageTabs } from 'utils/CommonEnums'

import {
  DeviceDetailContextActions,
  DeviceDetailsContext,
} from 'pages/deviceDetails/rootPage/view/DeviceDetailsContext'
import ExportData from 'exportReports/ExportData'
import { withPageTracking } from 'utils/AppInsightConfig'
import { useGenericEventHandler } from 'data/GenericEventHandler'
import DeviceDetailsReportSelector from 'pages/deviceDetails/rootPage/view/DeviceDetailsReportSelector'
import { withErrorBoundary } from 'react-error-boundary'
import ErrorPage from 'pages/common/ErrorPage'
import { NavigateBackButton } from '../../../rootPage/view/NavigateBackButton'
import { iNearMissData } from '../data/interface'
import { useDeviceNearMissData } from '../data/DeviceNearMissData'
import { prepareFilterFormData } from '../data/ReportDataUtils'
import { NearMissReportColumns } from '../export/NearMissReportFormat'
import NearMissFilterFormat from '../export/NearMissFilterFormat'

export const columnIDs = {
  gasName: 'channelName',
  percentValue: 'nearMissPercentage',
  alarmLevel: 'alarmLevelValue',
  duration: 'nearMissDuration',
  dateTime: 'nearMissStartTime',
  assignedTo: 'deviceUserName',
}

export type DeviceNearMissFilterFormType = {
  nearMissStartDateSelector: IselectOptionProps
  nearMissStartDate: DateRange

  percentValue: IselectOptionProps
  alarmLevel: IselectOptionProps

  deviceUserName: IselectOptionProps
  gasName: IselectOptionProps

  duration: IselectOptionProps
}

const DeviceNearMissFilterParamLabels = {
  nearMissFrom: 'from',
  nearMissTo: 'to',
  filterNearMissFrom: 'fdatefrom',
  filterNearMissTo: 'fdateto',
  percentValue: 'per',
  alarmLevel: 'al',
  deviceUserName: 'user',
  gasName: 'gas',
  duration: 'dur',
}

function DeviceNearMiss() {
  // Implement the component logic here
  const deviceDetailsContext = useContext(DeviceDetailsContext)
  const { dispatch } = deviceDetailsContext

  const [is2xlAndAbove, setIs2xlAndAbove] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      setIs2xlAndAbove(window.innerWidth >= ScreenWidths.xxl)
    }

    handleResize() // Initial check

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const location = useLocation()
  const redirectPageURL = `${location.pathname}${location.search}`

  const [searchParams, setSearchParams] = useSearchParams()
  const [filterValues, setFilterValues] = React.useState<IFilterValues[]>([])
  const [showExportDialog, setShowExportDialog] = React.useState(false)
  const tableRef = React.useRef<Table<iNearMissData>>(null)
  const sortedTableData =
    tableRef.current?.getSortedRowModel().rows.map((row) => row.original) ?? []

  /**
   * Get the from and to date from the URL
   * @param {string} nearMissFromParam - The from date
   * @param {string} nearMissToParam - The to date
   * @param {string} filterNearMissFromParam - The from date for the filter
   * @param {string} filterNearMissToParam - The to date for the filter
   */
  const isFromDate =
    searchParams.get(DeviceNearMissFilterParamLabels.nearMissFrom) ||
    searchParams.get(DeviceNearMissFilterParamLabels.filterNearMissFrom)
  const isToDate =
    searchParams.get(DeviceNearMissFilterParamLabels.nearMissTo) ||
    searchParams.get(DeviceNearMissFilterParamLabels.filterNearMissTo)

  const filterNearMissToParam = returnDateWithoutTimeOffset(isToDate || new Date())
  const filterNearMissFromParam = returnDateWithoutTimeOffset(
    isFromDate || sub(new Date(filterNearMissToParam), { months: 6 }),
  )

  const gasNameParam = searchParams.get(DeviceNearMissFilterParamLabels.gasName) ?? ''
  const percentValueParam = searchParams.get(DeviceNearMissFilterParamLabels.percentValue) ?? ''
  const alarmLevelParam = searchParams.get(DeviceNearMissFilterParamLabels.alarmLevel) ?? ''
  const deviceUserNameParam = searchParams.get(DeviceNearMissFilterParamLabels.deviceUserName) ?? ''
  const durationParam = searchParams.get(DeviceNearMissFilterParamLabels.duration) ?? ''

  const params = useParams()
  const { deviceSerialNumber } = params as { deviceSerialNumber: string }
  document.title = `Near Miss ${deviceSerialNumber}`

  const { deviceDetails } = useDeviceDetails()
  const { id: deviceId } = deviceDetails ?? { deviceId: '' }

  const [filterDataNearMissPercentages, setFilterDataNearMissPercentages] = React.useState<
    IselectOptionProps[]
  >([])
  const [filterDataNearMissGasTypes, setFilterDataNearMissGasTypes] = React.useState<
    IselectOptionProps[]
  >([])
  const [filterDataNearMissAlarmLevels, setFilterDataNearMissAlarmLevels] = React.useState<
    IselectOptionProps[]
  >([])
  const [filterDataDeviceUsers, setFilterDataDeviceUsers] = React.useState<IselectOptionProps[]>([])

  const { genericEventHandler } = useGenericEventHandler()

  const {
    data: nearMissData,
    isLoading: isNearMissDataLoading,
    isError: isNearMissDataError,
    error: nearMissDataError,
  } = useDeviceNearMissData(
    deviceId as string,
    filterNearMissFromParam.toISOString().split('T')[0],
    filterNearMissToParam.toISOString().split('T')[0],
    redirectPageURL,
    true,
  )

  useEffect(() => {
    if (isNearMissDataError) {
      genericEventHandler({
        onlyTrack: true,
        severity: 'error',
        message: nearMissDataError?.message || 'Error getting device near miss data',
        error: nearMissDataError,
        extraData: {
          component: 'DeviceNearMiss',
          action: 'get device near miss data',
        },
      })
    }
  }, [isNearMissDataError, nearMissDataError])

  useEffect(() => {
    if (nearMissData && nearMissData.length > 0) {
      const { nearMissPercentages, nearMissGasTypes, nearMissAlarmLevels, deviceUsers } =
        prepareFilterFormData(nearMissData)

      setFilterDataNearMissPercentages(nearMissPercentages)
      setFilterDataNearMissGasTypes(nearMissGasTypes)
      setFilterDataNearMissAlarmLevels(nearMissAlarmLevels)
      setFilterDataDeviceUsers(deviceUsers)
    }
  }, [nearMissData])

  const fetchedReportDataColumns = useMemo<ColumnDef<iNearMissData>[]>(
    () => [
      {
        id: columnIDs.dateTime,
        header: 'Date and time',
        accessorKey: 'nearMissStartTime',
        cell: (info) => {
          const value: number = info.getValue() as number
          const date = fromUnixTime(value)
          return (
            <div className='font-poppins text-2xs leading-4 font-normal text-c-dark-blue-1 '>
              <span className='mr-2'>
                <FormattedDate value={date} />
              </span>
              <span>
                <FormattedTime value={date} hour='2-digit' minute='2-digit' second='2-digit' />
              </span>
            </div>
          )
        },
        meta: { colClassName: '' },
        filterFn: DateRangeColumnFilterUnixTimeFn<iNearMissData>,
      },
      {
        id: columnIDs.gasName,
        header: 'Gas',
        accessorKey: 'channelName',
        cell: (info) => {
          const value: string = info.getValue() as string
          return (
            <div className='font-poppins text-2xs leading-4 font-semibold text-c-dark-blue-1 '>
              {value}
            </div>
          )
        },
        meta: {},
        filterFn: 'includesString',
      },
      {
        id: columnIDs.percentValue,
        header: '% of alarm level',
        accessorKey: 'nearMissPercentage',
        cell: (info) => {
          const value: string = info.getValue() as string
          return (
            <div className='font-poppins text-2xs leading-4 font-normal text-c-dark-blue-1 '>
              {value}
            </div>
          )
        },
        meta: { colClassName: '' },
        filterFn: 'includesString',
      },
      {
        id: columnIDs.alarmLevel,
        header: 'Alarm level',
        accessorKey: 'alarmLevelValue',
        cell: (info) => {
          const value: string = info.getValue() as string
          return (
            <div className='font-poppins text-2xs leading-4 font-semibold text-c-dark-blue-1 '>
              {value}
            </div>
          )
        },
        meta: { colClassName: '' },
        filterFn: 'includesString',
      },
      {
        id: columnIDs.duration,
        header: 'Duration',
        accessorKey: 'nearMissDuration',
        cell: (info) => {
          const value: string = info.getValue() as string
          return (
            <div className='font-poppins text-2xs leading-4 font-semibold text-c-dark-blue-1 '>
              {value}
            </div>
          )
        },
        meta: { colClassName: '' },
        filterFn: 'includesString',
      },

      {
        id: columnIDs.assignedTo,
        header: 'Assigned to',
        accessorKey: 'deviceUserName',
        cell: (info) => {
          const value: string = info.getValue() as string
          return (
            <div className='font-poppins text-2xs leading-4 font-normal text-c-dark-blue-1 '>
              {value}
            </div>
          )
        },
        meta: { colClassName: '' },
        filterFn: 'includesString',
      },
    ],
    [],
  )

  const resetFilterFormParams = () => {
    searchParams.delete(DeviceNearMissFilterParamLabels.nearMissFrom)
    searchParams.delete(DeviceNearMissFilterParamLabels.nearMissTo)
    searchParams.delete(DeviceNearMissFilterParamLabels.filterNearMissFrom)
    searchParams.delete(DeviceNearMissFilterParamLabels.filterNearMissTo)
    searchParams.delete(DeviceNearMissFilterParamLabels.percentValue)
    searchParams.delete(DeviceNearMissFilterParamLabels.alarmLevel)
    searchParams.delete(DeviceNearMissFilterParamLabels.deviceUserName)
    searchParams.delete(DeviceNearMissFilterParamLabels.gasName)
    searchParams.delete(DeviceNearMissFilterParamLabels.duration)
    setSearchParams(searchParams)
  }

  const handleFilterFormSubmit = useCallback(
    (formData: IFilterValues[]) => {
      formData.forEach((filterValue) => {
        const { columnId, value } = filterValue
        if (columnId === columnIDs.dateTime) {
          if (value === '') {
            searchParams.delete(DeviceNearMissFilterParamLabels.nearMissFrom)
            searchParams.delete(DeviceNearMissFilterParamLabels.nearMissTo)
            searchParams.delete(DeviceNearMissFilterParamLabels.filterNearMissFrom)
            searchParams.delete(DeviceNearMissFilterParamLabels.filterNearMissTo)
          } else {
            const dateRangeToBeFiltered = value as DateRange
            // Calculate if DateRange is wider based on below code
            const isFromBeforeOrSame =
              isBefore(new Date(dateRangeToBeFiltered.startDate), filterNearMissFromParam) ||
              isEqual(new Date(dateRangeToBeFiltered.startDate), filterNearMissFromParam)

            const isToAfterOrSame =
              isAfter(new Date(dateRangeToBeFiltered.endDate), filterNearMissToParam) ||
              isEqual(new Date(dateRangeToBeFiltered.endDate), filterNearMissToParam)

            const isDateRangeWider = isFromBeforeOrSame && isToAfterOrSame

            if (isDateRangeWider) {
              searchParams.set(
                DeviceNearMissFilterParamLabels.nearMissFrom,
                new Date(dateRangeToBeFiltered.startDate).toISOString().split('T')[0],
              )

              searchParams.set(
                DeviceNearMissFilterParamLabels.nearMissTo,
                new Date(dateRangeToBeFiltered.endDate).toISOString().split('T')[0],
              )
            }

            searchParams.set(
              DeviceNearMissFilterParamLabels.filterNearMissFrom,
              new Date(dateRangeToBeFiltered.startDate).toISOString().split('T')[0],
            )

            searchParams.set(
              DeviceNearMissFilterParamLabels.filterNearMissTo,
              new Date(dateRangeToBeFiltered.endDate).toISOString().split('T')[0],
            )
          }
        } else if (columnId === columnIDs.percentValue) {
          if (value === '') {
            searchParams.delete(DeviceNearMissFilterParamLabels.percentValue)
          } else {
            searchParams.set(DeviceNearMissFilterParamLabels.percentValue, value as string)
          }
        } else if (columnId === columnIDs.alarmLevel) {
          if (value === '') {
            searchParams.delete(DeviceNearMissFilterParamLabels.alarmLevel)
          } else {
            searchParams.set(DeviceNearMissFilterParamLabels.alarmLevel, value as string)
          }
        } else if (columnId === columnIDs.assignedTo) {
          if (value === '') {
            searchParams.delete(DeviceNearMissFilterParamLabels.deviceUserName)
          } else {
            searchParams.set(DeviceNearMissFilterParamLabels.deviceUserName, value as string)
          }
        } else if (columnId === columnIDs.gasName) {
          if (value === '') {
            searchParams.delete(DeviceNearMissFilterParamLabels.gasName)
          } else {
            searchParams.set(DeviceNearMissFilterParamLabels.gasName, value as string)
          }
        } else if (columnId === columnIDs.duration) {
          if (value === '') {
            searchParams.delete(DeviceNearMissFilterParamLabels.duration)
          } else {
            searchParams.set(DeviceNearMissFilterParamLabels.duration, value as string)
          }
        }
      })
      setSearchParams(searchParams)
    },
    [searchParams],
  )

  const filterTableValues = useMemo(
    () => [
      {
        columnId: columnIDs.dateTime,
        value:
          isFromDate && isToDate
            ? {
                startDate: new Date(filterNearMissFromParam),
                endDate: new Date(filterNearMissToParam),
              }
            : '',
      },
      {
        columnId: columnIDs.percentValue,
        value: percentValueParam,
      },
      {
        columnId: columnIDs.alarmLevel,
        value: alarmLevelParam,
      },
      {
        columnId: columnIDs.assignedTo,
        value: deviceUserNameParam,
      },
      {
        columnId: columnIDs.gasName,
        value: gasNameParam,
      },
      {
        columnId: columnIDs.duration,
        value: durationParam,
      },
    ],
    [
      filterNearMissFromParam,
      filterNearMissToParam,
      percentValueParam,
      alarmLevelParam,
      deviceUserNameParam,
      gasNameParam,
      durationParam,
    ],
  )

  // channelName
  // nearMissDuration: string
  // nearMissPercentage: number
  // peakGasLevelValue: string
  // alarmLevelValue: string

  // deviceUserId: string
  // deviceUserName: string
  // uniqueId: string
  // deviceUserStatus: User

  const convertISelectOptionPropsToFormType = useMemo(() => {
    const gasNameFiltered = filterTableValues.find(
      (filterValue) => filterValue.columnId === columnIDs.gasName,
    )?.value as string
    const percentValueFiltered = filterTableValues.find(
      (filterValue) => filterValue.columnId === columnIDs.percentValue,
    )?.value as string
    const alarmLevelFiltered = filterTableValues.find(
      (filterValue) => filterValue.columnId === columnIDs.alarmLevel,
    )?.value as string
    const deviceUserNameFiltered = filterTableValues.find(
      (filterValue) => filterValue.columnId === columnIDs.assignedTo,
    )?.value as string
    const durationFiltered = filterTableValues.find(
      (filterValue) => filterValue.columnId === columnIDs.duration,
    )?.value as string

    const nearMissDateFiltered = (filterTableValues.find(
      (filterValue) => filterValue.columnId === columnIDs.dateTime,
    )?.value || {
      startDate: filterNearMissFromParam,
      endDate: filterNearMissToParam,
    }) as DateRange

    const r: DeviceNearMissFilterFormType = {
      nearMissStartDateSelector: dateRangeOptions.find(
        (option) => option.value === DateRangeSelectorTypes.Custom,
      ) ?? {
        label: '',
        value: '',
      },

      nearMissStartDate: {
        startDate: new Date(nearMissDateFiltered?.startDate),
        endDate: new Date(nearMissDateFiltered?.endDate),
      },

      deviceUserName: {
        label: deviceUserNameFiltered,
        value: deviceUserNameFiltered,
      },

      gasName: {
        label: gasNameFiltered,
        value: gasNameFiltered,
      },

      alarmLevel: {
        label: alarmLevelFiltered,
        value: alarmLevelFiltered,
      },

      duration: {
        label: durationFiltered,
        value: durationFiltered,
      },

      percentValue: {
        label: percentValueFiltered,
        value: percentValueFiltered,
      },
    }
    return r
  }, [
    filterNearMissFromParam,
    filterNearMissToParam,
    filterDataDeviceUsers,
    filterDataNearMissAlarmLevels,
    filterDataNearMissGasTypes,
    filterDataNearMissPercentages,
  ])

  // const [filterFormSelectedValues, setFilterFormSelectedValues] =
  //   React.useState<DeviceNearMissFilterFormType>({
  //     nearMissStartDateSelector: dateRangeOptions.find(
  //       (option) => option.value === DateRangeSelectorTypes.Custom,
  //     ) ?? {
  //       label: '',
  //       value: '',
  //     },

  //     nearMissStartDate: {
  //       startDate: new Date(nearMissFrom),
  //       endDate: new Date(nearMissTo),
  //     },

  //     deviceUserName: {
  //       label: '',
  //       value: '',
  //     },

  //     gasName: selectAllOption,

  //     alarmLevel: selectAllOption,

  //     duration: {
  //       label: '',
  //       value: '',
  //     },

  //     percentValue: selectAllOption,
  //   })

  const showFilter = () => {
    dispatch({
      type: DeviceDetailContextActions.ShowFilterForm,
      payload: {
        page: DeviceDetailPageTabs.NearMiss,
        filterFormData: {
          onFilterSubmit: (formData: IFilterValues[]) => handleFilterFormSubmit(formData),
          onFilterReset: () => resetFilterFormParams(),
          // onFilterFormClose: () => setShowFilterForm(false),
          selectedValues: convertISelectOptionPropsToFormType,
          deviceUserNames: filterDataDeviceUsers,
          gasTypes: filterDataNearMissGasTypes,
          alarmLevels: filterDataNearMissAlarmLevels,
          percentValues: filterDataNearMissPercentages,
        },
      },
    })
  }

  const hideFilterForm = () => {
    dispatch({ type: DeviceDetailContextActions.HideFilterForm })
  }

  // useEffect(() => {
  //   if (is2xlAndAbove) {
  //     showFilter()
  //   } else {
  //     hideFilterForm()
  //   }
  // }, [is2xlAndAbove])

  // Return the JSX that will be rendered
  return (
    <>
      {/* <div className='flex flex-row'> */}
      <ExportData
        handleCancel={() => setShowExportDialog(false)}
        IsOpen={showExportDialog}
        data={sortedTableData ?? []}
        reportColumns={NearMissReportColumns}
        widgetForReport={[]}
        reportTitle='Device near-miss'
        filterSummary={NearMissFilterFormat(
          filterValues,
          deviceDetails?.serialNumber || '',
          deviceDetails?.deviceType || '',
        )}
      />
      {/* <div className='flex-grow overflow-x-auto'> */}
      <TsTable
        ref={tableRef}
        data={nearMissData ?? []}
        columns={fetchedReportDataColumns}
        showGlobalActionButton={false}
        globalActionButton={<NavigateBackButton />}
        showGlobalFilter={false}
        getRowCanExpand={() => false}
        onPrint={() => {}}
        onExport={() => {}}
        updateFilteredData={() => {}}
        renderFilterSummary={() => <> </>}
        filterValues={filterTableValues}
        onShowFilter={() => showFilter()}
        dataIsLoading={isNearMissDataLoading}
        resetFilter={() => resetFilterFormParams()}
        minDisplayRows={10}
        defaultSortedColumKey={columnIDs.dateTime}
        renderTabs={() => <DeviceDetailsReportSelector />}
        setShowExportDialog={(val) => setShowExportDialog(val)}
      />
      {/* </div> */}
      {/* </div> */}
    </>
  )
}

export default withErrorBoundary(withPageTracking(DeviceNearMiss), {
  FallbackComponent: ErrorPage,
})

// const filterReportData = (formFilterValues: IFilterValues[]) => {
//   const filterValueDateRange = formFilterValues.find(
//     (filterValue) => filterValue.columnId === columnIDs.dateTime,
//   )?.value as DateRange

//   const isDateRangeNarrower =
//     (isAfter(new Date(filterValueDateRange.startDate), new Date(reportFromToDates.startDate)) ||
//       isEqual(new Date(filterValueDateRange.startDate), new Date(reportFromToDates.startDate))) &&
//     (isBefore(new Date(reportFromToDates.endDate), new Date(filterValueDateRange.endDate)) ||
//       isEqual(new Date(reportFromToDates.endDate), new Date(filterValueDateRange.endDate)))

//   if (isDateRangeNarrower) {
//     setFilterValues(formFilterValues)
//   } else {
//     const { startDate, endDate } = filterValueDateRange

//     // navigate(`/devices/${deviceSerialNumber}/eventlog?from=${startDate}&to=${endDate}`)

//     setSearchParams({
//       from: new Date(startDate).toISOString().split('T')[0],
//       to: new Date(endDate).toISOString().split('T')[0],
//     })
//   }
// }
