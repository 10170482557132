import React from 'react'
import withRoleChecking from 'hoc/withRoleChecking'
import { UserRoleTypes } from 'utils/CommonEnums'
import { AddEntryInTableButton } from 'components/table/tsTableComponents/buttons'
import CreateOperator from '../create/CreateOperator'

const AddOperatorButtonComp = AddEntryInTableButton

function AddOperatorButton(): JSX.Element {
  const [openAddOperator, setOpenAddOperator] = React.useState(false)
  return (
    <div>
      <CreateOperator isOpened={openAddOperator} handleClose={() => setOpenAddOperator(false)} />
      <AddOperatorButtonComp
        buttonText='Add Operator'
        buttonAction={() => setOpenAddOperator(true)}
      />
    </div>
  )
}

export default withRoleChecking(AddOperatorButton, [
  UserRoleTypes.User,
  UserRoleTypes.CustomerAdmin.split(' ').join(''),
  UserRoleTypes.Admin,
])
