import { useFeatureFlag } from 'hooks'
import React from 'react'
import { FeatureFlagsEnums } from 'utils/FeatureFlags'

const withFeatureFlag = <P extends object>(
  WrappedComponent: React.ComponentType<P>,
  featureFlag: FeatureFlagsEnums,
): React.FC<P> =>
  function FeatureFlagChecking(props: P) {
    const isThisFeatureEnabled = useFeatureFlag(featureFlag)
    if (!isThisFeatureEnabled) {
      return null
    }
    return <WrappedComponent {...props} />
  }

export default withFeatureFlag
