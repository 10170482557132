import React from 'react'
import clsx from 'clsx'
// import { NavLink, useNavigate } from 'react-router-dom'
// import { DeviceAssignmentStatus } from 'utils/CommonEnums'
// import { UserAddIcon, UserRemoveIcon, EditIcon,ViewIcon } from 'assets/icons'
// import { FormattedDate } from 'react-intl'
// import { iCombinedDeviceData } from 'pages/devices/data/iDevicesList'

// import EditDevice from 'pages/devices/edit/EditDevice'
import { StatusValues } from 'utils/Constants'

type stringProps = {
  data: string
  className?: string
}

export function DeviceStatus(props: stringProps): JSX.Element {
  const { data, className } = props
  const isActive: boolean = data === 'Active'

  const valueToDisplay = StatusValues.find(
    (item) => item.label.toString()?.toLowerCase() === data.toLowerCase(),
  )?.label
  return (
    <span
      className={clsx(
        className || 'px-2.5 py-1 font-semibold text-xs leading-4 ',
        isActive ? 'bg-c-green' : 'bg-c-red-light',
        'whitespace-nowrap rounded-full text-c-dark-blue-1 bg-opacity-20',
      )}
    >
      {valueToDisplay}
    </span>
  )
}
