import { selectAllOption } from 'forms/FormUtils'
import {
  iDeviceGasLogsServerSideSearchParams,
  iDevieGasLogsAPIParams,
} from 'pages/deviceDetails/childPages/gasLogs/data/DeviceGasLogData'

export const deviceListDataQueryKey = (customerId?: string, businessUnitId?: string) => [
  'devices',
  'list',
  'businessUnit',
  customerId,
  businessUnitId,
]
export const deviceListStatsQueryKey = (businessUnitId?: string) => {
  if (
    businessUnitId !== '' &&
    businessUnitId?.toLowerCase() !== selectAllOption.value?.toString().toLowerCase()
  ) {
    return ['device', 'stats', 'dates', 'latest', businessUnitId]
  }
  return ['device', 'stats', 'dates', 'latest', 'all']
}

export const DeviceDetailsQueryKeyBySerial = (deviceSerial: string) => [
  'device',
  'details',
  'serial',
  deviceSerial,
]

export const DeviceDetailsQueryKeyById = (deviceId: string) => ['device', 'details', 'id', deviceId]

/**
 * Events Log Query Key
 */

export const deviceEventLogsQueryKeyIdDates = ({
  deviceId,
  startDate,
  endDate,
}: {
  deviceId: string
  startDate?: string
  endDate?: string
}) => ['device', 'eventLogs', 'id', deviceId, 'startDate', startDate, 'endDate', endDate]

/**
 * Gas log query key
 */

export const deviceGasLogsQueryKeyIdDates = (params: iDevieGasLogsAPIParams) => {
  const { deviceId, startDate, endDate } = params
  return ['device', 'gasLogs', 'id', deviceId, 'startDate', startDate, 'endDate', endDate]
}

export const deviceGasLogsQueryKey = (params: iDeviceGasLogsServerSideSearchParams) => {
  const { deviceId, startDate, endDate, channelNumber, gasName, uom, pageNumber, pageSize } = params
  return [
    'device',
    'gasLogs',
    'id',
    deviceId,
    'startDate',
    startDate,
    'endDate',
    endDate,
    'channelNumber',
    channelNumber && channelNumber !== -1 ? channelNumber : '',
    'gasName',
    gasName,
    // 'gasValue',
    // gasValue && gasValue !== -1 ? gasValue : '',
    'uom',
    uom,
    'pageNumber',
    pageNumber,
    'pageSize',
    pageSize,
  ]
}

/**
 * Device Assignment History Query Key
 */

export const deviceAssignmentHistoryQueryKeyIdDates = ({
  deviceId,
  startDate,
  endDate,
}: {
  deviceId: string
  startDate?: string
  endDate?: string
}) => ['device', 'assignmentHistory', 'id', deviceId, 'startDate', startDate, 'endDate', endDate]

/**
 * Device Faults Query Key
 */
export const faultsDataQueryKeyIdDates = ({
  deviceId,
  startDate,
  endDate,
}: {
  deviceId: string
  startDate?: string
  endDate?: string
}) => ['faults', 'device', 'id', deviceId, 'startDate', startDate, 'endDate', endDate]

/**
 * Device Sync History Query Key
 */
export const deviceSyncHistoryQueryKeyIdDates = ({
  deviceId,
  startDate,
  endDate,
}: {
  deviceId: string
  startDate?: string
  endDate?: string
}) => ['device', 'syncHistory', 'id', deviceId, 'startDate', startDate, 'endDate', endDate]

/**
 * Device Near Miss Query Key
 */

export const reportDataQueryKeyIdDates = ({
  deviceId,
  startDate,
  endDate,
}: {
  deviceId: string
  startDate?: string
  endDate?: string
}) => ['device', 'faults', 'id', deviceId, 'startDate', startDate, 'endDate', endDate]

/**
 * Device Bump Test Query Key
 */

export const BumpHistoryQueryKeyIdDates = ({
  deviceId,
  startDate,
  endDate,
}: {
  deviceId: string
  startDate?: string
  endDate?: string
}) => ['device', 'bumpHistory', 'id', deviceId, 'startDate', startDate, 'endDate', endDate]

/**
 * Other Queries
 */

export const deviceGasUoMListQueryKey = (deviceId: string) => [
  'device',
  'gasUoMList',
  'id',
  deviceId,
]

export const checkDeviceHasAnyLogsQueryKey = (deviceId: string) => [
  'device',
  'hasAnyLogs',
  'id',
  deviceId,
]

export const deviceMutationKey = (deviceSerial: string) => ['device', 'add', deviceSerial]

export const deviceTypesListQueryKey = () => ['deviceTypes']

export const deviceConfigDataQueryKey = (deviceId?: string) => [
  'device',
  'details',
  'deviceId',
  deviceId,
]

export const devicesQueries = {
  deviceListDataQueryKey,
  deviceListStatsQueryKey,
  DeviceDetailsQueryKeyBySerial,
  DeviceDetailsQueryKeyById,
  deviceEventLogsQueryKeyIdDates,
  deviceGasLogsQueryKeyIdDates,
  deviceGasLogsQueryKey,
  deviceAssignmentHistoryQueryKeyIdDates,
  faultsDataQueryKeyIdDates,
  deviceSyncHistoryQueryKeyIdDates,
  reportDataQueryKeyIdDates,
  BumpHistoryQueryKeyIdDates,
  deviceGasUoMListQueryKey,
  checkDeviceHasAnyLogsQueryKey,
  deviceMutationKey,
  deviceTypesListQueryKey,
  deviceConfigDataQueryKey,
}
