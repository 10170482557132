import { QueryClient, useQuery } from '@tanstack/react-query'
import { LoaderFunction, createSearchParams } from 'react-router-dom'
import sub from 'date-fns/sub'
import { PortableTypes, UserStatusTypes } from 'utils/CommonEnums'
import ConnectAPIClient from 'services/ConnectAPIClient'
import { getAccessToken, getAccessTokenFromPCA, getAccessTokenProps } from 'services/MSALService'
import { InteractionStatus } from '@azure/msal-browser'
import { getOrFetchDevicesList, iBusinessUnit, iDevice } from 'data/DeviceListHook'
import { getOrFetchUserInfo } from 'data/UserInfoHook'
import { IsUserGlobalScope } from 'utils/UserDataUtils'
import { allCustomerOption, getOrFetchCustomerList } from 'data/CustomerListHook'
import { getOrFetchBusinessUnitsByCustomer } from 'data/BusinessUnitsListByCustomerHook'
import { getOrFetchBusinessUnitsForUser } from 'data/BusinessUnitsListByUserHook'
import { useMsal } from '@azure/msal-react'
import { iBusinessUnitDetails } from 'components/commonComponents/BusinessUnitDataUtils'
import { allBusinessUnitOption } from 'data/BusinessUnitsListHookWrapper'
import { GetProductNameText } from 'utils/CommonUtils'
import { iDataLoaderParams } from 'data/Interfaces'
import { allFaultsQueryKey } from 'services/apis/caching/services'
import { listFaultsApiUrl } from 'services/apis/urls/services'

export interface iFaultData {
  timeStamp: number
  deviceSerialNumber: string
  customerAssetNumber: string
  deviceId: string
  deviceType: PortableTypes | string
  eventId: number
  eventByte: number
  eventData: number
  eventLogValues: string
  eventDescription: string
  eventType: string
  deviceUserId: string
  deviceUserName: string
  uniqueId: string
  deviceUserStatus: UserStatusTypes

  businessUnit: iBusinessUnit // Not populated by API response
  businessUnitName: string // Not populated by API response (Duplicate)
  eventTypeUntranslated: string // original event type recd from API duplicated here (temporary)
}

export interface iAPIDateRangeBusinessUnitParams {
  startDate: string
  endDate: string
  businessUnitId: string
}

interface iFaultsAPIParams extends iAPIDateRangeBusinessUnitParams, getAccessTokenProps {}

interface iFetchDataParams extends iAPIDateRangeBusinessUnitParams {
  token: string
}

interface iGetOrFetchFaultsDataProps {
  queryClient: QueryClient
  token: string
  startDate: string
  endDate: string
  businessUnitId: string
}

function updateFaultsDataWithBusinessUnit(
  faultsData: iFaultData[],
  deviceListData: iDevice[],
): iFaultData[] {
  const combinedData = faultsData?.map((fault) => {
    const deviceBusinessUnit = deviceListData?.find(
      (device) => device.serialNumber === fault.deviceSerialNumber,
    )?.businessUnit ?? { id: 'unknown', name: 'unknown' }

    return { ...fault, businessUnit: deviceBusinessUnit }
  })
  return combinedData
}

export async function FetchFaultsData(params: iFetchDataParams): Promise<iFaultData[]> {
  try {
    const { startDate, endDate, token, businessUnitId } = params
    const ToDate = (endDate ? new Date(endDate) : new Date()).toISOString().split('T')[0]
    const FromDate = (startDate ? new Date(startDate) : sub(new Date(), { months: 6 }))
      .toISOString()
      .split('T')[0]
    const url = listFaultsApiUrl(FromDate, ToDate, businessUnitId)
    const resp = await ConnectAPIClient.get<iFaultData[]>(url, token)
    // `devicelogs/api/EventLogs/faults?FromDate=${FromDate}&ToDate=${ToDate}`, token
    const response = resp.data
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}

async function getOrFetchFaultsData(props: iGetOrFetchFaultsDataProps) {
  const { queryClient, token, startDate, endDate, businessUnitId } = props

  const faultsDataCached = queryClient.getQueryData<iFaultData[]>(
    allFaultsQueryKey({ startDate, endDate, businessUnitId }),
  )

  if (faultsDataCached) return faultsDataCached

  const faultsDataFetched = await queryClient.fetchQuery(
    allFaultsQueryKey({ startDate, endDate, businessUnitId }),
    async () =>
      FetchFaultsData({
        startDate,
        endDate,
        token,
        businessUnitId,
      }),
  )

  return faultsDataFetched
}

// interface iFaultsDataLoaderParams {
//   queryClient: QueryClient
//   msalInstance: IPublicClientApplication
// }

function getTopLevelBusinessUnitId(businessUnitsList: iBusinessUnitDetails[]) {
  const topLevelBusinessUnit = businessUnitsList.find(
    (businessUnit) => businessUnit.parentId === null,
  )
  if (!topLevelBusinessUnit) return ''
  return topLevelBusinessUnit?.id
}

export const FaultsDataLoader =
  (props: iDataLoaderParams): LoaderFunction =>
  async ({ request }) => {
    const { queryClient, msalInstance, msalAccounts, msalInProgress } = props
    if (!msalInstance) throw new Error('No MSAL Context')
    if (!queryClient) throw new Error('No query client')

    try {
      if (msalInProgress !== InteractionStatus.None) {
        return {}
      }
      if (msalAccounts.length === 0) {
        return {}
      }
      const token = await getAccessTokenFromPCA({ msalInstance })

      const userInfo = await getOrFetchUserInfo({ queryClient, token })

      console.log('USER INFO: ', userInfo)

      const isUserGlobal = IsUserGlobalScope(userInfo)

      const searchParams = createSearchParams(new URLSearchParams(request.url.split('?')[1]))
      const endDate = searchParams.get('to') ?? new Date().toISOString().split('T')[0]
      const startDate =
        searchParams.get('from') ??
        sub(new Date(endDate), { months: 6 }).toISOString().split('T')[0]
      const customerId = isUserGlobal
        ? searchParams.get('c') ?? (allCustomerOption.id as string)
        : ''
      const businessUnitId = searchParams.get('mainbu') ?? allBusinessUnitOption.id

      const customerList = !isUserGlobal ? [] : await getOrFetchCustomerList({ queryClient, token })

      const businessUnitsList =
        isUserGlobal && customerId !== '' && customerId !== allCustomerOption.id
          ? await getOrFetchBusinessUnitsByCustomer({ queryClient, token, customerId })
          : await getOrFetchBusinessUnitsForUser({ queryClient, token })

      const devicesList = await getOrFetchDevicesList({ queryClient, token })

      let buId = ''
      if (
        businessUnitId === allBusinessUnitOption.id &&
        customerId !== allCustomerOption.id &&
        businessUnitsList
      ) {
        buId = getTopLevelBusinessUnitId(businessUnitsList)
      } else {
        buId = businessUnitId
      }

      const faultsDataFetched = await getOrFetchFaultsData({
        queryClient,
        token,
        startDate,
        endDate,
        businessUnitId: buId,
      })

      const faultsDataWithBusinessUnit = updateFaultsDataWithBusinessUnit(
        faultsDataFetched,
        devicesList,
      )

      return {
        faultsData: faultsDataWithBusinessUnit,
        businessUnitsList,
        customerList,
      }
    } catch (error) {
      console.error(error)
      throw error
    }
  }

export async function getFaultsData(faultAPIParams: iFaultsAPIParams) {
  const { startDate, endDate, msalContext, redirectPageURL, businessUnitId } = faultAPIParams
  const token = await getAccessToken({ msalContext, redirectPageURL })
  const faultsData = await FetchFaultsData({ startDate, endDate, token, businessUnitId })
  return faultsData
}

export function useFaultsData(
  startDate: string,
  endDate: string,
  customerId: string,
  businessUnitId: string,
  businessUnitsList: iBusinessUnitDetails[] | undefined,
  redirectPageURL: string,
  enabled: boolean,
) {
  const msalContext = useMsal()
  let buId = ''
  if (
    businessUnitId === allBusinessUnitOption.id &&
    customerId !== allCustomerOption.id &&
    businessUnitsList
  ) {
    buId = getTopLevelBusinessUnitId(businessUnitsList)
  } else {
    buId = businessUnitId
  }

  return useQuery(
    allFaultsQueryKey({
      startDate,
      endDate,
      businessUnitId: buId,
    }),
    () =>
      getFaultsData({
        startDate,
        endDate,
        businessUnitId: buId,
        msalContext,
        redirectPageURL,
      }),
    {
      enabled,
      onError: (error) => {
        console.log(error)
      },
      select: (resultData) => {
        if (!resultData) return []

        const resultDataWithDeviceTypeTextModified = resultData.map((device) => {
          const deviceTypeText = GetProductNameText(device.deviceType as PortableTypes)
          if (deviceTypeText === '') {
            return device
          }
          return { ...device, deviceType: deviceTypeText }
        })
        return resultDataWithDeviceTypeTextModified
      },
    },
  )
}

// const allFaultsQuery = (params: iFetchDataParams) => ({
//   queryKey: allFaultsQueryKey({ startDate: params.startDate, endDate: params.endDate }),
//   queryFn: async () => {
//     try {
//       const faultsData = await FetchFaultsData(params)
//       return faultsData
//     } catch (error) {
//       console.error(error)
//       throw error
//     }
//   },
// })
