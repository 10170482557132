import React from 'react'
import { IFilterValues, DateRange } from 'forms/FormModelInterface'
import { FormatDateRange,FormatBumpResult} from 'exportReports/ExportDataUtils'

const BumpHistoryReportFilterLookup = [
  {
    columId: 'bumpTestDateTime',
    label: 'Date & Time',
    format: (val: any) => FormatDateRange(val as DateRange),
  },
  {
    columId: 'bumpTestResult',
    label: 'Bump result',
    format: (val: string) => FormatBumpResult(val),
  },
  {
    columId: 'eventDescription',
    label: 'Description',
    format: (val: any) => val,
  },
  {
    columId: 'assignedTo',
    label: 'Assigned to',
    format: (val: any) => val,
  }
]

const mapColumnLabel = (filterVal: IFilterValues) => {
  const labelToReturn = BumpHistoryReportFilterLookup.find(
    (l) => l.columId === filterVal.columnId,
  )?.label
  return labelToReturn
}

const mapColumnValue = (filterVal: any) => {
  const valueToReturn = BumpHistoryReportFilterLookup.find(
    (l) => l.columId === filterVal.columnId,
  )?.format(filterVal?.value || '')
  return valueToReturn
}

function BumpHistoryFilterFormat(
  filterValues: IFilterValues[],
  deviceSerialNumber: string,
  deviceType: string,
) {
  let summary = `Serial number: ${deviceSerialNumber}, Device type: ${deviceType}\n`
  filterValues?.forEach((f) => {
    if (f.value)
      summary = `${`${summary} ${mapColumnLabel(f)}`}: ${f.value ? mapColumnValue(f) : ''},`
  })

  return summary.replace(/,\s*$/, '')
}

export default BumpHistoryFilterFormat
