import { UserAccountContext } from 'contexts/UserAccountContext'
import { useContext } from 'react'
import { IsUserGlobalScope } from 'utils/UserDataUtils'

export function useUserHasRequiredRoles(requiredRoles?: string[]) {
  const { state: userInfo } = useContext(UserAccountContext) // Adjust this line if you're getting userInfo from somewhere else
  const isGlobalUser = IsUserGlobalScope(userInfo)
  const customerIsAdmin = requiredRoles?.includes(userInfo.authority[0].roleName)

  const userHasRequiredRoles = isGlobalUser || customerIsAdmin
  return userHasRequiredRoles
}
