import { DateRange, IFilterQueryParamValues, IselectOptionProps } from 'forms/FormModelInterface'
import { dateRangeProps } from 'forms/FormUtils'

export const DevicePageColumnIds = {
  serialNumber: 'serialNumber',
  deviceType: 'deviceType',
  businessUnit: 'businessUnit',
  assetNumber: 'customerAssetNumber',
  deviceStatus: 'status',
  deviceUser: 'deviceUser',

  lastSyncSelector: 'lSyncSel',
  lastSwitchOnSelector: 'lSwitchOnSel',
  lastSync: 'lastUploadDate',
  lastSwitchOn: 'lastSwitchOnDate',

  gasType: 'channels',
}

export const DevicePageParamLabels = {
  serialNumber: 'ser',
  assetNumber: 'asset',
  status: 'status',

  lastSyncSelector: 'lsync',
  lastsyncFrom: 'lsyncfrom',
  lastsyncTo: 'lsyncto',

  lastSwitchOnSelector: 'lson',
  lastSwitchedOnFrom: 'lsonfrom',
  lastSwitchedOnTo: 'lsonto',

  businessUnit: 'cbu',
  deviceType: 'dtype',
  deviceUser: 'user',

  gasType: 'gas',
}

export const DevicePageFilterParamValues: IFilterQueryParamValues[] = [
  { paramName: DevicePageParamLabels.serialNumber, paramValue: null },
  { paramName: DevicePageParamLabels.businessUnit, paramValue: null },
  { paramName: DevicePageParamLabels.deviceType, paramValue: null },
  { paramName: DevicePageParamLabels.assetNumber, paramValue: null },
  { paramName: DevicePageParamLabels.status, paramValue: null },
  { paramName: DevicePageParamLabels.lastSyncSelector, paramValue: null },
  { paramName: DevicePageParamLabels.lastsyncFrom, paramValue: null },
  { paramName: DevicePageParamLabels.lastsyncTo, paramValue: null },
  { paramName: DevicePageParamLabels.lastSwitchOnSelector, paramValue: null },
  { paramName: DevicePageParamLabels.lastSwitchedOnFrom, paramValue: null },
  { paramName: DevicePageParamLabels.lastSwitchedOnTo, paramValue: null },
  { paramName: DevicePageParamLabels.deviceUser, paramValue: null },
]

export type DevicePageFilterFormType = {
  serialNumber: IselectOptionProps
  assetNumber: IselectOptionProps

  deviceType: IselectOptionProps
  businessUnit: IselectOptionProps
  deviceStatus: IselectOptionProps

  deviceUser: IselectOptionProps

  lastSync: DateRange | dateRangeProps
  lastSwitchOn: DateRange

  lastSyncSelector: IselectOptionProps
  lastSwitchOnSelector: IselectOptionProps

  gasType: IselectOptionProps
}
