import React from 'react'
import { ExportColumnType } from 'exportReports/DataExportInterfaces'
import { FormatUTXToDateTime, FormatBusinessUnit } from 'exportReports/ExportDataUtils'

const AssignmentHistoryReportColumns: ExportColumnType[] = [
  {
    key: 'fullName',
    label: 'Name',
    format: (val: string) => val,
    cellWidth: 120,
  },
  {
    key: 'uniqueId',
    label: 'Unique ID',
    format: (val: string) => val,
    cellWidth: 100,
  },
  {
    key: 'assignedOn',
    label: 'Assigned on',
    format: (val: string) => val,
    cellWidth: 100,
  },
  {
    key: 'unAssignedOn',
    label: 'Returned on',
    format: (val: string) => val,
    cellWidth: 100,
  }
]

export { AssignmentHistoryReportColumns }
