import React, { useContext } from 'react'
import { CloseIcon, MenuIcon } from 'assets/icons'
import clsx from 'clsx'
import { NavLink } from 'react-router-dom'
import crowconWhiteLogo from 'assets/images/CrowconConnect_WhiteLogo-03.png'
import { ScreenWidthsInPx } from 'utils/Constants'
import { UserAccountContext } from 'contexts/UserAccountContext'

import SideNavMenu from './sideNavComponents/SideNavMenu'
import UserIdentification from './sideNavComponents/UserIdentification'
import FooterLinks from './sideNavComponents/FooterLinks'

interface sideNavStausUpdaterProps {
  sideNavStatusUpdater: (status: boolean) => void
  sideNavExpanded: boolean
  collapseSideNavOnSelection: () => void
}

function SideNavContainer(props: sideNavStausUpdaterProps) {
  const { sideNavStatusUpdater, sideNavExpanded, collapseSideNavOnSelection } = props
  const { state } = useContext(UserAccountContext)

  const toggleSideNavExpansion = () => {
    if (sideNavExpanded === true) {
      sideNavStatusUpdater(false)
    } else {
      sideNavStatusUpdater(true)
    }
  }

  return (
    <div
      id='sideNavContainer'
      className={clsx(
        'bg-c-dark-blue-1 flex  duration-500 h-full flex-col fixed top-0 bottom-0 left-0 overflow-y-auto overflow-hidden ',
        sideNavExpanded ? 'w-210' : 'w-74 ',
      )}
    >
      {/* Logo and menu / close icon */}
      <div className='flex flex-row mt-9 ml-3.5 items-center h-11'>
        <button
          type='button'
          tabIndex={0}
          onClick={toggleSideNavExpansion}
          className='text-2xl rounded-full h-11 w-11 mt-2 mr-1 bg-c-dark-blue-1 outline-0 border-1 border-c-dark-blue-1 focus:border-c-blue  hover:bg-c-dark-blue-2  active:bg-c-blue'
        >
          {sideNavExpanded === false ? (
            <MenuIcon className='w-6 h-6 mx-2.5 p-0.5' />
          ) : (
            <CloseIcon className='w-6 h-6 mx-2.5 p-0.5' />
          )}
        </button>
        <div className={clsx('duration-500 pt-2 w-24 ml-1')}>
          <img
            src={crowconWhiteLogo}
            alt='Company Logo'
            className={clsx('w-24 object-contain', sideNavExpanded ? 'scale-100' : 'scale-0')}
          />
        </div>
      </div>
      {/* End logo and menu / close icon */}
      <div className='mt-11 flex flex-col'>
        <SideNavMenu ExpandedStatus={sideNavExpanded} OnItemSelected={collapseSideNavOnSelection} />
      </div>

      <div className='mt-10 flex-grow flex flex-col'>
        <div className=''>
          <NavLink to='profile'>
            {({ isActive }) => (
              <UserIdentification
                isActive={isActive}
                userFirstName={state?.user?.firstName}
                userLastName={state?.user?.lastName}
                sideNavExpanded={sideNavExpanded}
              />
            )}
          </NavLink>
        </div>
      </div>

      {/* absolute bottom-8 left-5 */}
      <div className='flex flex-col mt-2 pb-2 ml-5 mr-5'>
        {/* Manually add bordres now */}
        <div
          className={clsx(
            'border-b-2 border-c-white/10 mx-auto duration-500',
            sideNavExpanded ? 'w-44' : 'w-8',
          )}
        />
        <div className='mt-10 '>
          <FooterLinks sideNavExpanded={sideNavExpanded} />
        </div>
      </div>
    </div>
  )
}

export default SideNavContainer
