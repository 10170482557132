/* eslint-disable import/no-duplicates */
import min from 'date-fns/min'
import max from 'date-fns/max'
import fromUnixTime from 'date-fns/fromUnixTime'

import isValid from 'date-fns/isValid'

import { IselectOptionProps } from 'forms/FormModelInterface'
import { selectAllOption } from 'forms/FormUtils'
import { iDeviceEventLog } from './interface'

export const getMinMaxEventDates = (eventLogsData: iDeviceEventLog[]) => {
  if (!eventLogsData || eventLogsData.length === 0)
    return { minEventLogDate: '', maxEventLogDate: '' }

  const eventLogDates = new Array<Date>()

  eventLogsData.forEach((eventLog) => {
    const eventDateFromUnixTime = fromUnixTime(eventLog.timeStamp)
    if (isValid(eventDateFromUnixTime)) {
      eventLogDates.push(eventDateFromUnixTime)
    }
  })

  const minEventLogDate = isValid(min(eventLogDates)) ? min(eventLogDates) : ''
  const maxEventLogDate = isValid(max(eventLogDates)) ? max(eventLogDates) : ''

  return { minEventLogDate, maxEventLogDate }
}

// type translationProps = {
//     id: string
//     values: any
// }

// export function useTranslation(props: translationProps) : string {
//     const {id, values} = props
//     const intl = useIntl()
//     return intl.formatMessage({id}, values as Record<string, any>)
// }

function PrepareEventDescriptionOptionsForSelect(
  eventLogsData: iDeviceEventLog[],
): IselectOptionProps[] {
  if (!eventLogsData || eventLogsData.length === 0) return []

  const eventDescriptions = new Array<IselectOptionProps>()

  eventLogsData.forEach((eventLog) => {
    eventDescriptions.push({
      label: eventLog.eventDescription,
      value: eventLog.eventDescription,
    })
  })

  // Remove duplicates
  const uniqueEventDescriptions = eventDescriptions.filter(
    (eventDescription, index, self) =>
      index === self.findIndex((e) => e.value === eventDescription.value),
  )

  return uniqueEventDescriptions
}

function PrepareDeviceUserOptionsForSelect(eventLogsData: iDeviceEventLog[]): IselectOptionProps[] {
  if (!eventLogsData || eventLogsData.length === 0) return []

  const eventDeviceUsers = new Array<IselectOptionProps>()

  eventLogsData.forEach((eventLog) => {
    if (eventLog.deviceUserName !== null) {
      eventDeviceUsers.push({
        label: eventLog.deviceUserName,
        value: eventLog.deviceUserId,
      })
    }
  })

  // Remove duplicates
  const uniqueEventDeviceUsers = eventDeviceUsers.filter(
    (eventDeviceUser, index, self) =>
      index === self.findIndex((e) => e.value === eventDeviceUser.value),
  )

  return uniqueEventDeviceUsers
}

export const prepareEventLogsFormData = (eventLogsData: iDeviceEventLog[]) => {
  if (!eventLogsData || eventLogsData.length === 0)
    return { eventDates: {}, eventDescriptions: [], eventDeviceUsers: [] }

  const eventDates = getMinMaxEventDates(eventLogsData)
  const eventDescriptions = PrepareEventDescriptionOptionsForSelect(eventLogsData)
  const eventDeviceUsers = PrepareDeviceUserOptionsForSelect(eventLogsData)

  // eventDescriptions.unshift(selectAllOption)
  // eventDeviceUsers.unshift(selectAllOption)

  return { eventDates, eventDescriptions, eventDeviceUsers }
}
