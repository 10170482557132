import { AxiosError, AxiosResponse } from 'axios'
import { APIResponseTypes } from 'utils/CommonEnums'

export interface iCrowconAPIErrorCodes {
  errorCode: string
  errorType: string
  errorDescription: string
  severity: string
}

export interface iProblemDetails {
  type?: string
  title?: string
  status?: number
  detail?: string
  instance?: string
  extensions?: unknown
}

export type iCrowconProblemDetails = iProblemDetails & {
  data: unknown
  errors: iCrowconAPIErrorCodes[]
  isSuccess: boolean
  status: string
}

export function parseAPIError(error: iCrowconProblemDetails): string {
  const { detail, errors, status } = error

  let errorDescription = detail || 'Unknown error'

  if (status) {
    errorDescription += ` Status: ${status}`
  }

  if (errors && errors.length > 0) {
    errorDescription += ', Error codes: '
    errorDescription += errors.map((e) => e.errorCode).join(' ,')
  }

  return errorDescription
}

export const parseApiErrorOrNetworkError = (error: unknown) => {
  const apiError = error as AxiosError
  if (apiError.response?.data) {
    return { message: parseAPIError(apiError.response.data as iCrowconProblemDetails) }
  }
  return apiError
}
// write a function to parse  response from an API
// if the response is 204, this function should return an enum value of 'NoContent'
// if the response is not 204, this function should return the response as is
// the function signature should be:
// function parseAPIResponse<T>(response: AxiosResponse<T>): T | iNoContent
// where iNoContent is an enum with value 'NoContent'

export function parseAPIResponse<T>(response: AxiosResponse<T>): T | APIResponseTypes {
  if (response.status === 204) {
    return APIResponseTypes.NoContent
  }
  return response.data
}

export function parseAPIErrorv01(error: AxiosError): string {
  let errorDescription

  if (error.response) {
    const { data, status } = error.response
    errorDescription = `Error: ${status} `
    if (data) {
      if (typeof data === 'string') {
        errorDescription += data
      } else if ((data as { message: string }).message) {
        errorDescription += (data as { message: string }).message
      }
      // if ((data as { errors: any[] }).errors) {
      //   errorDescription += ', Error codes: '
      //   errorDescription += (data as { errors: any[] }).errors.map((e) => e.errorCode).join(' ,')
      // }
    }

    return errorDescription
  }

  return 'Unknown error'
}
