import React from 'react'
import { FormattedMessage } from 'react-intl'

interface pageTitleProps {
  title: string
}

function PageTitle(props: pageTitleProps): JSX.Element {
  const { title } = props

  const translationKey = `pageTitles.${title.toLowerCase().replace(/\s/g, '_')}`

  return (
    <div className='flex items-center h-12 text-xl md:text-3.5xl leading-9 font-bold text-c-dark-blue-1'>
      <FormattedMessage id={translationKey} defaultMessage={title} />
      {/* {title} */}
    </div>
  )
}

export default PageTitle
