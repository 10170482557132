import React from 'react'
import { SvgProps } from 'types/SVGProps'

function DashboardIcon(props: SvgProps): JSX.Element {
  const { stroke, fill, className, toggleClick } = props
  
  return (
    <div className=''>
      <svg
        className={className}
        width='20'
        height='20'
        viewBox='0 0 20 20'
        fill={fill ?? 'none'}
        xmlns='http://www.w3.org/2000/svg'
      >
        <rect
          x='1.9'
          y='1.9'
          width='16.2'
          height='16.2'
          rx='1.1'
          stroke={stroke ?? '#FFFFFF'}
          strokeWidth='1.8'
        />
        <path d='M12 2V18' stroke={stroke ?? '#FFFFFF'} strokeLinecap='round' />
        <path d='M12 8H18' stroke={stroke ?? '#FFFFFF'} strokeLinecap='round' />
      </svg>
    </div>
  )
}

export default DashboardIcon
