import React from 'react'
import { IFilterValues, DateRange } from 'forms/FormModelInterface'
import { FormatDateRange, FormatToShortDate } from 'exportReports/ExportDataUtils'

const SyncHistoryReportFilterLookup = [
  {
    columId: 'uploadDate',
    label: 'Date',
    format: (val: any) => FormatDateRange(val as DateRange),
  },
  {
    columId: 'uploadType',
    label: 'Synced data',
    format: (val: string) => val,
  },
  {
    columId: 'uploadClientType',
    label: 'Synced via',
    format: (val: any) => val,
  },
]

const mapColumnLabel = (filterVal: IFilterValues) => {
  const labelToReturn = SyncHistoryReportFilterLookup.find(
    (l) => l.columId === filterVal.columnId,
  )?.label
  return labelToReturn
}

const mapColumnValue = (filterVal: any) => {
  const valueToReturn = SyncHistoryReportFilterLookup.find(
    (l) => l.columId === filterVal.columnId,
  )?.format(filterVal?.value || '')
  return valueToReturn
}

function SyncHistoryFilterFormat(
  filterValues: IFilterValues[],
  deviceSerialNumber: string,
  deviceType: string,
) {
  let summary = `Serial number: ${deviceSerialNumber}, Device type: ${deviceType}\n`
  filterValues?.forEach((f) => {
    if (f.value)
      summary = `${`${summary} ${mapColumnLabel(f)}`}: ${f.value ? mapColumnValue(f) : ''},`
  })

  return summary.replace(/,\s*$/, '')
}

export default SyncHistoryFilterFormat
