import { RedirectRequest, SilentRequest } from '@azure/msal-browser'

// const localSiteURL = 'http://localhost:3000'
// const stagingSiteURL = 'https://portal.bravemeadow-7ee975d1.westeurope.azurecontainerapps.io'
// const devSiteURL = 'https://ui-styling-poc.bravemeadow-7ee975d1.westeurope.azurecontainerapps.io'
// const prodTrialSiteURL =
// 'https://connectlive-ui-v2-2.redwave-2ce5c32b.northeurope.azurecontainerapps.io'
// const prodSiteURL = 'https://portal.crowcon.com'

// const stagingAPIBaseURL = 'https://portal.bravemeadow-7ee975d1.westeurope.azurecontainerapps.io'
// const prodAPIBaseURL = 'https://portal.crowcon.com'

// siteBaseURLs
// prod -> 'https://portal.crowcon.com'
// staging -> 'https://portal.bravemeadow-7ee975d1.westeurope.azurecontainerapps.io/'
// dev -> 'https://ui-styling-poc.bravemeadow-7ee975d1.westeurope.azurecontainerapps.io/'
// local -> 'https://localhost:3000'

// adb2cTenants
// prod -> 'crowconconnect.onmicrosoft.com'
// all other -> 'connectv2trial1.onmicrosoft.com'
// const prodADB2CTenant = 'crowconconnect'
// const stagingADB2CTenant = 'connectv2trial1'

// const stagingAPIScopes = [
//   'openid',
//   'profile',
//   'offline_access',
//   // 'https://connectv2trial1.onmicrosoft.com/testAPI1/demo.read',
//   'https://connectv2trial1.onmicrosoft.com/testAPI1/Files.Read',
// ]

// const prodAPIScopes = [
//   'openid',
//   'profile',
//   'offline_access',
//   'https://crowconconnect.onmicrosoft.com/68a3ef76-0faf-48b8-b8d6-c10e9b5243a3/access_app_data',
// ]

// const stagingADB2CClientID = '1e4952b6-d67c-4fe1-9f10-230b1920ae8a'
// const prodADB2CClientID = '87c68b98-3028-404d-b7b9-7fbc9f86b91b'

// export const siteBaseURL = prodTrialSiteURL
// export const adb2cTenant = prodADB2CTenant
// export const apiScopes = prodAPIScopes
// export const adb2cClientID = prodADB2CClientID
// export const apiBaseURL = prodAPIBaseURL
// export const SignInAuthority = `https://${adb2cTenant}.b2clogin.com/${adb2cTenant}.onmicrosoft.com/b2c_1A_SIGNIN_UI_1`
// export const SignUpAuthority = `https://${adb2cTenant}.b2clogin.com/${adb2cTenant}.onmicrosoft.com/b2c_1A_SIGNUP_UI_1`
// export const InstrumentationKey =
//   'InstrumentationKey=4be37327-059a-4816-9a8b-5c42f178ff26;IngestionEndpoint=https://westeurope-5.in.applicationinsights.azure.com/;LiveEndpoint=https://westeurope.livediagnostics.monitor.azure.com/'

export const siteBaseURL = process.env.REACT_APP_SITE_BASE_URL
export const adb2cTenant = process.env.REACT_APP_ADB2C_TENANT
export const apiScopes = (process.env.REACT_APP_API_SCOPE as string).split(',')
export const adb2cClientID = process.env.REACT_APP_ADB2C_CLIENT_ID
export const apiBaseURL = process.env.REACT_APP_API_BASE_URL

export interface iADB2CPolicy {
  shortname: string
  name: string
  authority: string
}

export interface iADB2CPolicies {
  signIn: iADB2CPolicy
  signUp: iADB2CPolicy
}

export const SignInAuthority = process.env.REACT_APP_SIGN_IN_AUTHORITY
export const SignUpAuthority = process.env.REACT_APP_SIGN_UP_AUTHORITY
export const InstrumentationKey = process.env.REACT_APP_INSTRUMENTATION_KEY

export const loginRequest: RedirectRequest = {
  scopes: apiScopes,
  authority: SignInAuthority,
  redirectUri: '/',
  prompt: 'login',
  onRedirectNavigate: () => {
    // This is called on / just before loginRedirect and perhaps also on acquireTokenRedirect.
    // console.log('onRedirectNavigate')
    // the URL logged here is the redirected URL which is connectv2trial1 etc
    // console.log(url)
  },
  // redirectStartPage: `${location.pathname}`,
  // state: '12345',
}

export const silentTokenRequest: SilentRequest = {
  scopes: apiScopes,

  // authority: 'https://connectv2trial1.b2clogin.com/connectv2trial1.onmicrosoft.com/b2c_1a_Smart_HRD_SUP_ONLY',
  authority: SignInAuthority,
  forceRefresh: false,
}

export const getAuthRequest = (isSignUpPath: boolean) => {
  if (isSignUpPath) {
    return {
      ...loginRequest,
      authority: SignUpAuthority,
    }
  }
  return {
    ...loginRequest,
    authority: SignInAuthority,
  }
}

export const ADB2CPolicies: iADB2CPolicy[] = [
  {
    shortname: 'signin',
    name: process.env.REACT_APP_SIGN_IN_POLICY_NAME as string,
    authority: SignInAuthority as string,
  },
  {
    shortname: 'signup',
    name: 'B2C_1A_SIGNUP_UI_1',
    authority: SignUpAuthority as string,
  },
]

// export const apiScopes = [
//   'openid',
//   'profile',
//   'offline_access',
//   'https://connectv2trial1.onmicrosoft.com/testAPI1/demo.read',
//   'https://connectv2trial1.onmicrosoft.com/testAPI1/Files.Read',
// ]
