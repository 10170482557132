import React, { useEffect } from 'react'

export default function ScrollableWrapper({
  children,
  containerId,
}: {
  children: React.ReactNode
  containerId: string
}) {
  // Use the useEffect hook to add event listeners for mouse events
  useEffect(() => {
    // Get the container wrapper and thead elements
    const containerWrapper = document.getElementById(containerId) as HTMLTableElement
    const thead = document.getElementById('table-thead') as HTMLTableElement

    // Initialize variables for tracking the mouse down state and position
    let isDown = false
    let startX: number
    let scrollLeft: number

    // Define a function to handle the mousedown event
    function handleMouseDown(e: MouseEvent) {
      // Set isDown to true and add the 'active' class to the container wrapper
      isDown = true
      containerWrapper?.classList.add('active')
      // Record the initial mouse position and scroll position
      startX = e.pageX - containerWrapper.offsetLeft
      scrollLeft = containerWrapper.scrollLeft
    }

    // Define a function to handle the mouseleave and mouseup events
    function handleMouseLeave() {
      // Set isDown to false and remove the 'active' class from the container wrapper
      isDown = false
      containerWrapper?.classList.remove('active')
    }

    // Define a function to handle the mousemove event
    function handleMouseMove(e: MouseEvent) {
      // If the mouse is not down, do nothing
      if (!isDown) return
      // Prevent the default action of the event
      e.preventDefault()
      // Calculate the new mouse position and scroll position
      const x = e.pageX - containerWrapper.offsetLeft
      const walk = (x - startX) * 3
      // Update the scroll position of the container wrapper
      containerWrapper.scrollLeft = scrollLeft - walk
    }

    // Define a function to handle the mousedown and mousemove events on the thead
    function handleTheadMouseDown() {
      // Set isDown to false and remove the 'active' class from the container wrapper
      isDown = false
      containerWrapper?.classList.remove('active')
    }

    // Add the event listeners to the container wrapper and thead
    containerWrapper?.addEventListener?.('mousedown', handleMouseDown)
    containerWrapper?.addEventListener?.('mousemove', handleMouseMove)
    containerWrapper?.addEventListener?.('mouseup', handleMouseLeave)
    containerWrapper?.addEventListener?.('mouseleave', handleMouseLeave)
    thead?.addEventListener?.('mousedown', handleTheadMouseDown)
    thead?.addEventListener?.('mousemove', handleTheadMouseDown)

    // Return a cleanup function to remove the event listeners when the component unmounts
    return () => {
      containerWrapper?.removeEventListener?.('mousedown', handleMouseDown)
      containerWrapper?.removeEventListener?.('mousemove', handleMouseMove)
      containerWrapper?.removeEventListener?.('mouseleave', handleMouseLeave)
      containerWrapper?.removeEventListener?.('mouseup', handleMouseLeave)
      thead?.removeEventListener?.('mousedown', handleTheadMouseDown)
      thead?.removeEventListener?.('mousemove', handleTheadMouseDown)
    }
  }, [containerId]) // The empty dependency array means this effect runs once on mount and cleanup runs on unmount
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>
}
