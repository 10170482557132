import React, { MouseEvent, useRef } from 'react'
import { Bar, getElementAtEvent } from 'react-chartjs-2'
import clsx from 'clsx'
import { iFleetInsightsSummary } from 'pages/dashboard/data/DashboardData'

import {
  populateAlarmsDataArray,
  populateStartAndEndDatesOfMonths,
} from 'pages/dashboard/data/DashboardDataUtils'
import { withErrorBoundary } from 'react-error-boundary'
import ErrorPage from 'pages/common/ErrorPage'
import { useNavigate } from 'react-router-dom'

interface iDashboardAlarmsChartProps {
  alarmsHistoryData: iFleetInsightsSummary['alarms']
  customerId: string
  businessUnitId: string
}

function DashboardAlarmsChart(props: iDashboardAlarmsChartProps): React.ReactElement {
  // Implement your component logic here
  const chartRef = useRef()
  const [chartSegmentHovered, setChartSegmentHovered] = React.useState(false)
  const monthStartEndDates = populateStartAndEndDatesOfMonths()

  // const ontouchstart = (event: MouseEvent<HTMLCanvasElement>) => {
  //   // Can't get this to work
  //   const element = chartRef.current && getElementAtEvent(chartRef.current, event)
  //   if (element && element[0]) {
  //     setChartSegmentHovered(true)
  //   } else {
  //     setChartSegmentHovered(false)
  //   }
  // }

  const { alarmsHistoryData, customerId, businessUnitId } = props
  const urlsearchParams = new URLSearchParams()
  if (customerId) {
    urlsearchParams.set('c', customerId)
  }
  if (businessUnitId) {
    urlsearchParams.set('mainbu', businessUnitId)
  }

  const alarmHistoryDataProcessed = populateAlarmsDataArray({
    alarmsDataHistory: alarmsHistoryData,
  })
  const navigate = useNavigate()
  const months = alarmHistoryDataProcessed.map((alarm) => alarm.month)
  const alarm1Data = alarmHistoryDataProcessed.map((alarm) => alarm.alarm1)
  const alarm2Data = alarmHistoryDataProcessed.map((alarm) => alarm.alarm2)
  const options = {
    // events: ['click', 'touchstart'],
    maintainAspectRatio: false,
    resizeDelay: 1,
    responsive: true,
    layout: {
      autoPadding: false,
      padding: {
        top: 0,
        bottom: 0,
      },
    },
    interaction: {
      // events: ['click', 'touchstart'],   // Can't get this to work
      // onHover: ontouchstart,             // Can't get this to work
      mode: 'index' as const,
      intersect: true,
    },
    stacked: false,
    plugins: {
      title: {
        display: false,
        text: 'Alarms',
        font: {
          size: 16,
        },
      },
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        stacked: false,
        ticks: {
          color: '#1B4373',
          padding: 0,
          backDropPadding: 0,
        },
        title: {
          display: false,
          padding: 0,
        },
        grid: {
          drawOnChartArea: false,
        },
      },

      y: {
        // type: 'linear' as const,
        display: true,
        position: 'left' as const,
        stacked: false,
        ticks: {
          color: '#1B4373',
          padding: 0,
          backdropPadding: 0,
          stepSize: 5,
        },
        grid: {
          drawOnChartArea: true,
        },
        suggestedMax: Math.max(...alarm1Data, ...alarm2Data) + 5,
        suggestedMin: 0,
        beginAtZero: true,
        min: 0,
      },
      //   y1: {
      //     type: 'linear' as const,
      //     display: true,
      //     position: 'right' as const,
      //     stacked: false,
      //     ticks: {
      //       color: '#1B4373',
      //     },
      //     grid: {
      //       drawOnChartArea: false,
      //     },

      //     suggestedMax: Math.max(...alarm2Data) + 5,
      //   },
    },
    // events: ['click', 'touchstart'],
    // onHover: ontouchstart,
  }
  const chartData = {
    labels: months,
    datasets: [
      {
        label: 'Alarm 1',
        data: alarm1Data,
        backgroundColor: '#D91544',
        hoverBackgroundColor: '#A90514',
        // borderColor: 'rgb(255, 99, 132)',
        borderRadius: 12,
        borderWidth: 0,
        // hoverBorderWidth: 1,
        // barThickness: 10,
        barPercentage: 0.94,
        // fill: false,
        yAxisID: 'y',
        // minBarLength: 20,
        // grouped: false,
        // stack: 'bar'
      },
      {
        label: 'Alarm 2',
        data: alarm2Data,
        backgroundColor: '#C9CEFF',
        hoverBackgroundColor: '#999EEF',
        // borderColor: '#999EEF',
        borderRadius: 12,
        borderWidth: 0,
        // hoverBorderWidth: 1,
        // barThickness: 10,
        barPercentage: 0.94,
        // fill: false,
        yAxisID: 'y',
        // minBarLength: 20,
        // grouped: false,
        // stack: 'bar'
      },
    ],
  }

  const onChartClick = (event: MouseEvent<HTMLCanvasElement>) => {
    // const chartDataSet = chartRef.current && getDatasetAtEvent(chartRef.current, event)
    // if (chartDataSet && chartDataSet[0]) {
    //   const { datasetIndex: datasetIndexc } = chartDataSet[0]
    // }

    const element = chartRef.current && getElementAtEvent(chartRef.current, event)
    if (element && element[0]) {
      const { datasetIndex, index } = element[0]
      // datasetIndex shows alarm1, alarm2 altype=alarm1 alarmType=alarm2
      // index will show the month alarmDate=Custom&alarmDateFrom=2023-12-01&alarmDateTo=2023-12-31
      urlsearchParams.set('altype', `alarm${datasetIndex + 1}`)
      urlsearchParams.set('alarmDate', 'Custom')
      urlsearchParams.set('alarmDateFrom', monthStartEndDates[index].startDate)
      urlsearchParams.set('alarmDateTo', monthStartEndDates[index].endDate)
      const url = `/web/safety?${urlsearchParams.toString()}`

      // const url = `/web/safety?altype=alarm${datasetIndex + 1}&alarmDate=Custom&alarmDateFrom=${
      //   monthStartEndDates[index].startDate
      // }&alarmDateTo=${monthStartEndDates[index].endDate}`

      // window.open(url, '_blank')
      navigate(url)
      // navigate(`/web/safety?alarmType=alarm${datasetIndex + 1}&alarmDate=Custom&alarmDateFrom=${monthStartEndDates[index].startDate}&alarmDateTo=${monthStartEndDates[index].endDate}`, {
      //   replace: false,
      //   preventScrollReset: true,

      // })
    }

    // const elements = chartRef.current && getElementsAtEvent(chartRef.current, event)
    // if (!elements) return
    // const { length } = elements
  }

  return (
    // JSX code for your component's UI
    <div className='flex-grow flex flex-col px-4 py-4  bg-c-white shadow-card rounded-2xl items-center'>
      <div className='mb-2 flex flex-col gap-y-1 self-start'>
        <div className='text-base font-bold text-c-dark-blue-2'>Alarms</div>
        <div className='flex gap-x-2'>
          <div className='rounded-full bg-c-red-1 h-2 w-2 mt-1 ' />
          <div className='text-xs text-c-dark-blue-3'>Alarm 1</div>
          <div className='rounded-full bg-c-blue-1 h-2 w-2 mt-1 ' />
          <div className='text-xs text-c-dark-blue-3'>Alarm 2</div>
        </div>
      </div>
      <div
        className={clsx(
          'mt-2 flex flex-grow flex-shrink-0  ',
          chartSegmentHovered && 'cursor-pointer',
        )}
      >
        {alarm1Data?.length === 0 && alarm2Data?.length === 0 ? (
          <div className='flex justify-center items-center h-full w-full text-sm text-red-500'>
            No Alarms
          </div>
        ) : (
          <Bar
            ref={chartRef}
            onClick={onChartClick}
            // onTouchMove={ontouchstart}
            data={chartData}
            options={options}
          />
        )}
      </div>
    </div>

    // <Bar data={chartData} options={options} />
  )
}

export default withErrorBoundary(DashboardAlarmsChart, {
  FallbackComponent: ErrorPage,
})
