/*
TODO:
Refactoring required:
1. Move all widget data preparation to its own components / hoks
2. Reduce dependencies passed to filter form component
*/

import React, { useContext, useEffect, useMemo, useState, useCallback } from 'react'
import clsx from 'clsx'
import { useLocation, useSearchParams } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { isAfter, isBefore, isEqual, sub } from 'date-fns'
import { selectAllOption } from 'forms/FormUtils'
import { DateRange, IFilterValues, IselectOptionProps } from 'forms/FormModelInterface'
import { DateRangeSelectorTypes, DeviceFaultTypes } from 'utils/CommonEnums'
import { useDeviceList } from 'data/DeviceListHook'
import { iWidgetData } from 'components/viewComponents/interfaces'
import ExportData from 'exportReports/ExportData'
import { BusinessContext } from 'contexts/BusinessContext'
import BusinessAndCustomerSelection from 'components/modules/BusinessAndCustomerSelection'
import { withPageTracking } from 'utils/AppInsightConfig'
import { useGenericEventHandler } from 'data/GenericEventHandler'
import { returnDateWithoutTimeOffset } from 'utils/Constants'
import { withErrorBoundary } from 'react-error-boundary'
import ErrorPage from 'pages/common/ErrorPage'
import { dayDateToDate, returnStaticDate } from 'components/modules/Calender/CalenderUtils'
import { iFaultData, useFaultsData } from '../data/FaultsData'
import ServiceFaultstable from './ServiceFaultsTable'
import {
  PrepareDeviceTypeOptionsForSelect,
  countFaultsInLastXDays,
  groupFaultByFaultType,
  groupFaultsByDeviceType,
  iFaultsInDaysCount,
} from '../data/FaultsDataUtils'
import {
  ServiceFaultsColumnIds,
  ServiceFaultsFilterFormType,
  ServiceFaultsFilterParamLabels,
} from './ServiceFaultsPageUtils'
import ServiceFaultsFilterForm from './ServiceFaultsFilterForm'
import { ServiceFaultReportColumns } from '../export/ServiceFaultReportFormat'
import FormatServiceFaultWidget from '../export/ServiceFaultWidgetFormat'
import ServiceFaultFilterFormat from '../export/ServiceFaultFilterFormat'
import FaultsByNumberOfDaysWidget from './widgets/FaultsByNumberOfDaysWidget'
import FaultsByFaultTypeWidget from './widgets/FaultsByFaultTypeWidget'
import FaultsByDeviceTypeWidget from './widgets/FaultsByDeviceTypeWidget'

const XDaysToCountForFaults = [7, 30, 60, 90]
function ServiceFaultsPage() {
  useEffect(() => {
    document.title = 'Service'
  }, [])

  const intl = useIntl()
  const location = useLocation()
  const redirectPageURL = `${location.pathname}${location.search}`

  const [showFilterForm, setShowFilterForm] = useState(false)

  // global filter params
  const [searchParams, setSearchParams] = useSearchParams()
  /**
   * Business Units List and Customer List from BusinessContext
   */
  const {
    customerDataFromQuery,
    businessUnitDataFromQuery,
    selectedBusinessUnit,
    selectedCustomer,
  } = useContext(BusinessContext)

  const businessUnitsList = businessUnitDataFromQuery?.data ?? []
  const customerList = customerDataFromQuery?.data ?? []
  const selectedCustomerParam = selectedCustomer?.id ?? ''
  const selectedMainBUParam = selectedBusinessUnit?.id ?? ''
  const isBusinessUnitsListLoading = businessUnitDataFromQuery?.isLoading
  const isBusinessUnitsListError = businessUnitDataFromQuery?.isError

  // filter form params
  const childBUParam = searchParams.get(ServiceFaultsFilterParamLabels.businessUnit) ?? ''
  const deviceSerialParam =
    searchParams.get(ServiceFaultsFilterParamLabels.deviceSerialNumber) ?? ''
  const deviceUserNameParam = searchParams.get(ServiceFaultsFilterParamLabels.deviceUserName) ?? ''
  const deviceCustomerAssetsParam =
    searchParams.get(ServiceFaultsFilterParamLabels.customerAssetNumber) ?? ''

  const deviceTypeParam = searchParams.get(ServiceFaultsFilterParamLabels.deviceType) ?? ''
  const faultTypeParam = searchParams.get(ServiceFaultsFilterParamLabels.faultType) ?? ''
  const faultDescriptionParam =
    searchParams.get(ServiceFaultsFilterParamLabels.faultDescription) ?? ''

  const isFromFilterDate = searchParams.get(ServiceFaultsFilterParamLabels.filterDateFrom)
  const isFromRangeDate = searchParams.get(ServiceFaultsFilterParamLabels.dateRangeFrom)
  const isToFilterDate = searchParams.get(ServiceFaultsFilterParamLabels.filterDateTo)
  const isToRangeDate = searchParams.get(ServiceFaultsFilterParamLabels.dateRangeTo)

  const filterFaultDateTimeTo = returnDateWithoutTimeOffset(isToFilterDate || new Date())
  const filterFaultDateTimeFrom = returnDateWithoutTimeOffset(
    isFromFilterDate || sub(filterFaultDateTimeTo, { months: 6 }),
  )
  const rangeFaultDateTimeTo = returnDateWithoutTimeOffset(isToRangeDate || new Date())
  const rangeFaultDateTimeFrom = returnDateWithoutTimeOffset(
    isFromRangeDate || sub(rangeFaultDateTimeTo, { months: 6 }),
  )

  const faultDateSelectorParam =
    searchParams.get(ServiceFaultsFilterParamLabels.dateRangeSelector) ?? ''

  const [filteredFaultsData, setFilteredFaultsData] = useState<iFaultData[]>([])
  const [unfilteredFaultsData, setUnfilteredFaultsData] = useState<iFaultData[]>([])

  const { genericEventHandler } = useGenericEventHandler()

  const checkIfDateHasWiderRange = useCallback(
    (dateFrom: Date, dateTo: Date) => {
      const isFromBeforeOrSame =
        isBefore(new Date(dateFrom), filterFaultDateTimeFrom) ||
        isEqual(new Date(dateTo), filterFaultDateTimeFrom)

      const isToAfterOrSame =
        isAfter(new Date(dateFrom), filterFaultDateTimeTo) ||
        isEqual(new Date(dateTo), filterFaultDateTimeTo)

      return isFromBeforeOrSame && isToAfterOrSame
    },
    [filterFaultDateTimeTo, filterFaultDateTimeFrom],
  )

  const {
    data: deviceListData,
    isLoading: isDeviceListLoading,
    isError: isDeviceListError,
    error: deviceListError,
  } = useDeviceList(
    redirectPageURL,
    selectedMainBUParam as string,
    selectedCustomerParam,
    businessUnitsList,
    businessUnitsList !== undefined,
  )

  const {
    data: faultsData,
    isLoading: isFaultsDataLoading,
    // isFetching,
    isError: isFaultsDataError,
    error: faultsDataError,
  } = useFaultsData(
    rangeFaultDateTimeFrom.toISOString().split('T')[0],
    rangeFaultDateTimeTo.toISOString().split('T')[0],
    selectedCustomerParam as string,
    selectedMainBUParam as string,
    businessUnitsList,
    redirectPageURL,
    !isBusinessUnitsListLoading && !isBusinessUnitsListError,
  )
  useEffect(() => {
    if (isDeviceListError) {
      genericEventHandler({
        onlyTrack: true,
        severity: 'error',
        message: deviceListError?.message || 'Error in fetching device list',
        error: deviceListError,
        extraData: {
          component: 'ServiceFaultsPage',
          action: 'useDeviceList',
        },
      })
    }
    if (isFaultsDataError) {
      genericEventHandler({
        onlyTrack: true,
        severity: 'error',
        message: deviceListError?.message || 'Error in fetching faults data',
        error: deviceListError,
        extraData: {
          component: 'ServiceFaultsPage',
          action: 'useFaultsData',
        },
      })
    }
  }, [isDeviceListError, deviceListError, isFaultsDataError, faultsDataError])

  useEffect(() => {
    const translatedData: iFaultData[] = []
    // Update business unit and translation for fault description

    if (faultsData && deviceListData) {
      faultsData?.forEach((dataRow) => {
        const { eventDescription } = dataRow
        const translationKey = eventDescription as string
        const translationValues: Record<string, string> = JSON.parse(dataRow.eventLogValues)
        let translatedDescription

        if (!dataRow.eventLogValues) {
          translatedDescription = intl.formatMessage({ id: translationKey })
        } else {
          translatedDescription = intl.formatMessage(
            {
              id: translationKey,
            },
            translationValues,
          )
        }

        const deviceBusinessUnit = deviceListData.find(
          (device) => device.serialNumber === dataRow.deviceSerialNumber,
        )?.businessUnit

        translatedData.push({
          ...dataRow,
          eventTypeUntranslated: dataRow.eventType,
          eventType: intl.formatMessage({
            id: `Eventlog.FaultTypes.${dataRow.eventType}`,
          }),
          eventDescription: translatedDescription,
          businessUnit: deviceBusinessUnit ?? { id: 'unknown', name: 'unknown' },
          businessUnitName: deviceBusinessUnit?.name ?? 'unknown',
        })
      })
    }
    setUnfilteredFaultsData(translatedData)
  }, [faultsData, deviceListData])

  const resetFilterFormParams = () => {
    searchParams.delete(ServiceFaultsFilterParamLabels.deviceSerialNumber)
    searchParams.delete(ServiceFaultsFilterParamLabels.customerAssetNumber)
    searchParams.delete(ServiceFaultsFilterParamLabels.deviceUserName)
    searchParams.delete(ServiceFaultsFilterParamLabels.deviceType)
    searchParams.delete(ServiceFaultsFilterParamLabels.faultType)
    searchParams.delete(ServiceFaultsFilterParamLabels.faultDescription)
    searchParams.delete(ServiceFaultsFilterParamLabels.businessUnit)
    searchParams.delete(ServiceFaultsFilterParamLabels.dateRangeFrom)
    searchParams.delete(ServiceFaultsFilterParamLabels.dateRangeTo)
    searchParams.delete(ServiceFaultsFilterParamLabels.filterDateFrom)
    searchParams.delete(ServiceFaultsFilterParamLabels.filterDateTo)
    searchParams.delete(ServiceFaultsFilterParamLabels.dateRangeSelector)
    setSearchParams(searchParams)
  }

  const [serialNumberOptions, setSerialNumberOptions] = useState<IselectOptionProps[]>([])
  const [customerAssetNumberOptions, setCustomerAssetNumberOptions] = useState<
    IselectOptionProps[]
  >([])
  const [deviceUserNameOptions, setDeviceUserNameOptions] = useState<IselectOptionProps[]>([])
  const [deviceTypeOptions, setDeviceTypeOptions] = useState<IselectOptionProps[]>([])
  const [faultTypeOptions, setFaultTypeOptions] = useState<IselectOptionProps[]>([])
  const [faultDescriptionOptions, setFaultDescriptionOptions] = useState<IselectOptionProps[]>([])
  const [businessUnitOptions, setBusinessUnitOptions] = useState<IselectOptionProps[]>([])
  const [showExportDialog, setShowExportDialog] = React.useState(false)

  const handleFilterFormSubmit = useCallback(
    (formData: IFilterValues[]) => {
      formData.forEach((filter) => {
        if (filter.columnId === ServiceFaultsColumnIds.deviceSerialNumber) {
          if (filter?.value === '') {
            searchParams.delete(ServiceFaultsFilterParamLabels.deviceSerialNumber)
          } else {
            searchParams.set(
              ServiceFaultsFilterParamLabels.deviceSerialNumber,
              filter?.value as string,
            )
          }
        }

        if (filter.columnId === ServiceFaultsColumnIds.deviceType) {
          if (filter?.value === '') {
            searchParams.delete(ServiceFaultsFilterParamLabels.deviceType)
          } else {
            searchParams.set(ServiceFaultsFilterParamLabels.deviceType, filter?.value as string)
          }
        }
        if (filter.columnId === ServiceFaultsColumnIds.deviceUserName) {
          if (filter?.value === '') {
            searchParams.delete(ServiceFaultsFilterParamLabels.deviceUserName)
          } else {
            searchParams.set(ServiceFaultsFilterParamLabels.deviceUserName, filter?.value as string)
          }
        }
        if (filter.columnId === ServiceFaultsColumnIds.customerAssetNumber) {
          if (filter?.value === '') {
            searchParams.delete(ServiceFaultsFilterParamLabels.customerAssetNumber)
          } else {
            searchParams.set(
              ServiceFaultsFilterParamLabels.customerAssetNumber,
              filter?.value as string,
            )
          }
        }

        if (filter.columnId === ServiceFaultsColumnIds.faultType) {
          if (filter?.value === '') {
            searchParams.delete(ServiceFaultsFilterParamLabels.faultType)
          } else {
            searchParams.set(ServiceFaultsFilterParamLabels.faultType, filter?.value as string)
          }
        }

        if (filter.columnId === ServiceFaultsColumnIds.faultTypeUntranslated) {
          if (filter?.value === '') {
            searchParams.delete(ServiceFaultsFilterParamLabels.faultType)
          } else if (filter?.value === DeviceFaultTypes.Other) {
            searchParams.set(ServiceFaultsFilterParamLabels.faultType, filter?.value as string)
          }
        }

        if (filter.columnId === ServiceFaultsColumnIds.faultDescription) {
          if (filter?.value === '') {
            searchParams.delete(ServiceFaultsFilterParamLabels.faultDescription)
          } else {
            searchParams.set(
              ServiceFaultsFilterParamLabels.faultDescription,
              filter?.value as string,
            )
          }
        }

        if (filter.columnId === ServiceFaultsColumnIds.businessUnit) {
          if (filter?.value === '') {
            searchParams.delete(ServiceFaultsFilterParamLabels.businessUnit)
          } else {
            searchParams.set(ServiceFaultsFilterParamLabels.businessUnit, filter?.value as string)
          }
        }

        if (filter.columnId === ServiceFaultsColumnIds.faultDateTime) {
          if (filter?.value === '') {
            searchParams.delete(ServiceFaultsFilterParamLabels.dateRangeFrom)
            searchParams.delete(ServiceFaultsFilterParamLabels.dateRangeTo)
            searchParams.delete(ServiceFaultsFilterParamLabels.filterDateFrom)
            searchParams.delete(ServiceFaultsFilterParamLabels.filterDateTo)
            searchParams.delete(ServiceFaultsFilterParamLabels.dateRangeSelector)
          } else {
            const dateRangeToBeFiltered = (filter?.value as IselectOptionProps).value as DateRange

            const dateRangeSelectorTobeFiltered = (filter?.value as IselectOptionProps)
              .label as string

            searchParams.set(
              ServiceFaultsFilterParamLabels.dateRangeSelector,
              dateRangeSelectorTobeFiltered,
            )

            // Calculate if DateRange is wider based on below code
            const isDateRangeWider = checkIfDateHasWiderRange(
              dateRangeToBeFiltered.startDate as Date,
              dateRangeToBeFiltered.endDate as Date,
            )
            if (isDateRangeWider) {
              searchParams.set(
                ServiceFaultsFilterParamLabels.dateRangeFrom,
                new Date(dateRangeToBeFiltered.startDate).toISOString().split('T')[0],
              )

              searchParams.set(
                ServiceFaultsFilterParamLabels.dateRangeTo,
                new Date(dateRangeToBeFiltered.endDate).toISOString().split('T')[0],
              )
            }

            searchParams.set(
              ServiceFaultsFilterParamLabels.filterDateFrom,
              new Date(dateRangeToBeFiltered.startDate).toISOString().split('T')[0],
            )

            searchParams.set(
              ServiceFaultsFilterParamLabels.filterDateTo,
              new Date(dateRangeToBeFiltered.endDate).toISOString().split('T')[0],
            )
          }
        }
      })
      setSearchParams(searchParams)
    },
    [searchParams, checkIfDateHasWiderRange],
  )

  const handleFilterFormReset = () => {
    // console.log('Reset form')
    resetFilterFormParams()
  }

  const filterTableValues = useMemo(
    () => [
      {
        columnId: ServiceFaultsColumnIds.deviceSerialNumber,
        value: deviceSerialParam ?? '',
      },
      {
        columnId: ServiceFaultsColumnIds.customerAssetNumber,
        value: deviceCustomerAssetsParam ?? '',
      },
      {
        columnId: ServiceFaultsColumnIds.deviceUserName,
        value: deviceUserNameParam ?? '',
      },
      {
        columnId: ServiceFaultsColumnIds.deviceType,
        value: deviceTypeParam ?? '',
      },
      {
        columnId:
          faultTypeParam === DeviceFaultTypes.Other
            ? ServiceFaultsColumnIds.faultTypeUntranslated
            : ServiceFaultsColumnIds.faultType,
        value: faultTypeParam ?? '',
      },
      {
        columnId: ServiceFaultsColumnIds.faultDescription,
        value: faultDescriptionParam ?? '',
      },
      {
        columnId: ServiceFaultsColumnIds.businessUnit,
        value: childBUParam ?? '',
      },
      {
        columnId: ServiceFaultsColumnIds.faultDateTime,
        value:
          isFromFilterDate && isToFilterDate
            ? {
                startDate: dayDateToDate(returnStaticDate(filterFaultDateTimeFrom)),
                endDate: dayDateToDate(returnStaticDate(filterFaultDateTimeTo)),
              }
            : '',
      },
      {
        columnId: ServiceFaultsColumnIds.faultDateSelector,
        value: faultDateSelectorParam ?? '',
      },
    ],
    [
      deviceSerialParam,
      deviceUserNameParam,
      deviceCustomerAssetsParam,
      deviceTypeParam,
      faultTypeParam,
      faultDescriptionParam,
      childBUParam,
      isFromFilterDate,
      isToFilterDate,
      filterFaultDateTimeFrom,
      filterFaultDateTimeTo,
      faultDateSelectorParam,
    ],
  )

  const convertISelectOptionPropsToServiceFaultsFormType = useMemo(() => {
    if (faultTypeParam === DeviceFaultTypes.Other) {
      filterTableValues.push({
        columnId: ServiceFaultsColumnIds.faultTypeUntranslated,
        value: faultTypeParam,
      })
      filterTableValues.push({
        columnId: ServiceFaultsColumnIds.faultType,
        value: '',
      })
    } else if (faultTypeParam !== DeviceFaultTypes.Other && faultTypeParam !== '') {
      filterTableValues.push({
        columnId: ServiceFaultsColumnIds.faultType,
        value: faultTypeParam,
      })
      filterTableValues.push({
        columnId: ServiceFaultsColumnIds.faultTypeUntranslated,
        value: '',
      })
    } else if (faultTypeParam === '') {
      filterTableValues.push({
        columnId: ServiceFaultsColumnIds.faultType,
        value: '',
      })
      filterTableValues.push({
        columnId: ServiceFaultsColumnIds.faultTypeUntranslated,
        value: '',
      })
    }

    // if (faultTypeParam === '') {
    //   filterTableValues.push({
    //     columnId: ServiceFaultsColumnIds.faultTypeUntranslated,
    //     value: '',
    //   })
    // }
    const serialNumberFiltered =
      filterTableValues.find(
        (filter) => filter.columnId === ServiceFaultsColumnIds.deviceSerialNumber,
      )?.value ?? ''
    const deviceTypeFiltered =
      filterTableValues.find((filter) => filter.columnId === ServiceFaultsColumnIds.deviceType)
        ?.value ?? ''
    const businessUnitFiltered =
      filterTableValues.find((filter) => filter.columnId === ServiceFaultsColumnIds.businessUnit)
        ?.value ?? ''
    const faultTypeFiltered =
      filterTableValues.find((filter) => filter.columnId === ServiceFaultsColumnIds.faultType)
        ?.value ?? ''
    const deviceUserNameFiltered =
      filterTableValues.find((filter) => filter.columnId === ServiceFaultsColumnIds.deviceUserName)
        ?.value ?? '-'
    const customerAssetNumberFiltered =
      filterTableValues.find(
        (filter) => filter.columnId === ServiceFaultsColumnIds.customerAssetNumber,
      )?.value ?? ''
    const faultDescriptionFiltered =
      filterTableValues.find(
        (filter) => filter.columnId === ServiceFaultsColumnIds.faultDescription,
      )?.value ?? ''

    const dateRangeFiltered = (filterTableValues.find(
      (filter) => filter.columnId === ServiceFaultsColumnIds.faultDateTime,
    )?.value || {
      startDate: filterFaultDateTimeFrom,
      endDate: filterFaultDateTimeTo,
    }) as DateRange

    const r: ServiceFaultsFilterFormType = {
      deviceSerialNumber: {
        value: serialNumberFiltered as string,
        label: serialNumberFiltered as string,
      },
      deviceType:
        deviceTypeFiltered === ''
          ? selectAllOption
          : {
              value: deviceTypeFiltered as string,
              label: deviceTypeFiltered as string,
            },

      businessUnit:
        businessUnitFiltered === ''
          ? selectAllOption
          : {
              value: businessUnitFiltered as string,
              label: businessUnitFiltered as string,
            },

      faultType:
        faultTypeFiltered === ''
          ? selectAllOption
          : {
              value: faultTypeFiltered as string,
              label: faultTypeFiltered as string,
            },
      deviceUserName: {
        value: deviceUserNameFiltered as string,
        label: deviceUserNameFiltered as string,
      },

      customerAssetNumber: {
        value: customerAssetNumberFiltered as string,
        label: customerAssetNumberFiltered as string,
      },

      faultDescription: {
        value: faultDescriptionFiltered as string,
        label: faultDescriptionFiltered as string,
      },

      faultDateRange: {
        startDate: new Date(dateRangeFiltered.startDate), // new Date(faultDateTimeFromParam as string),
        endDate: new Date(dateRangeFiltered.endDate), // new Date(faultDateTimeToParam as string),
      },
      faultDateRangeSelector: {
        value: faultDateSelectorParam as string,
        label: faultDateSelectorParam as string,
      },
    }

    return r
  }, [
    filterTableValues,
    faultTypeParam,
    filterFaultDateTimeFrom,
    filterFaultDateTimeTo,
    faultDateSelectorParam,
  ])

  const [faultsByDeviceType, setFaultsByDeviceType] = useState<iWidgetData[] | never>()
  const [faultsByFaultType, setFaultsByFaultType] = useState<iWidgetData[] | never>()
  const [faultsInXDays, setFaultsInXDays] = useState<iWidgetData[] | never>()

  useEffect(() => {
    if (filteredFaultsData) {
      const deviceTypeCounts = groupFaultsByDeviceType(filteredFaultsData)
      const deviceTypeWidgetData: iWidgetData[] = deviceTypeCounts.map((deviceTypeCount) => ({
        originalData: deviceTypeCount.deviceType,
        displayText: deviceTypeCount.deviceType,
        value: deviceTypeCount.count,
      }))
      setFaultsByDeviceType(deviceTypeWidgetData)

      // setFaultsByDeviceType(deviceTypeCounts)

      // For fault types, use non-translated data only
      // Not the best way...
      const faultTypeCounts = groupFaultByFaultType(faultsData ?? [])
      const faultTypeWidgetData: iWidgetData[] = faultTypeCounts.map((faultTypeCount) => ({
        originalData: faultTypeCount.faultType,
        displayText: intl.formatMessage({
          id: `Eventlog.FaultTypes.${faultTypeCount.faultType}`,
        }),
        value: faultTypeCount.count,
      }))
      setFaultsByFaultType(faultTypeWidgetData)

      const faultCountInXDays: iFaultsInDaysCount[] = XDaysToCountForFaults.map((days) => {
        const count = countFaultsInLastXDays(filteredFaultsData, days)
        return {
          days: `${days.toString()} days`,
          count,
        }
      })
      const faultsInXDaysWidgetData: iWidgetData[] = faultCountInXDays.map((faultCount) => ({
        originalData: faultCount.days,
        displayText: faultCount.days,
        value: faultCount.count,
      }))
      setFaultsInXDays(faultsInXDaysWidgetData)

      // serialNumberOptions: IselectOptionProps[]
      // deviceTypeOptions: IselectOptionProps[]
      // faultTypeOptions: IselectOptionProps[]
      // faultDescriptionOptions: IselectOptionProps[]

      const serialNumbersInData: IselectOptionProps[] = filteredFaultsData?.map((fault) => ({
        value: fault.deviceSerialNumber,
        label: fault.deviceSerialNumber,
      }))

      const uniqueSerialNumbers = new Set(serialNumbersInData.map((item) => item.value))
      const serialNumbersInDataUnique = Array.from(uniqueSerialNumbers).map((item) => ({
        value: item,
        label: item?.toString(),
      }))

      setSerialNumberOptions(serialNumbersInDataUnique as IselectOptionProps[])

      const deviceUserNameInData: IselectOptionProps[] = filteredFaultsData?.map((fault) => ({
        value: fault.deviceUserName,
        label: fault.deviceUserName,
      }))
      const deviceUserNames = new Set(deviceUserNameInData.map((devices) => devices.value))
      const deviceUserNameList = Array.from(deviceUserNames).map((item) => ({
        value: item,
        label: item?.toString(),
      }))

      setDeviceUserNameOptions(deviceUserNameList as IselectOptionProps[])

      const customerAssetNumberInData: IselectOptionProps[] = filteredFaultsData?.map((fault) => ({
        value: fault.customerAssetNumber,
        label: fault.customerAssetNumber,
      }))
      const customerAssetNumbers = new Set(
        customerAssetNumberInData.map((devices) => devices.value),
      )
      const customerAssetNumberList = Array.from(customerAssetNumbers).map((item) => ({
        value: item,
        label: item?.toString(),
      }))

      setCustomerAssetNumberOptions(customerAssetNumberList as IselectOptionProps[])

      const businessUnitsInData: IselectOptionProps[] | undefined = filteredFaultsData?.map(
        (fault) => ({
          value: fault?.businessUnit?.id ?? '',
          label: fault?.businessUnit?.name ?? '',
        }),
      )
      // remove duplicates in business units

      const businessUnitsInDataUnique: IselectOptionProps[] = businessUnitsInData.filter(
        (v, i, a) => a.findIndex((t) => t.value === v.value) === i,
      )

      businessUnitsInDataUnique.unshift(
        selectAllOption as {
          value: string
          label: string
        },
      )
      setBusinessUnitOptions(businessUnitsInDataUnique as IselectOptionProps[])

      const deviceTypesInData: IselectOptionProps[] =
        PrepareDeviceTypeOptionsForSelect(filteredFaultsData)
      setDeviceTypeOptions(deviceTypesInData)

      const faultTypesInData: IselectOptionProps[] = filteredFaultsData?.map((fault) => ({
        value: fault.eventType,
        label: fault.eventType,
      }))

      // remove duplicate fault types
      const faultTypesInDataUnique: IselectOptionProps[] = faultTypesInData.filter(
        (v, i, a) => a.findIndex((t) => t.value === v.value) === i,
      )

      // const uniqueFaultTypes = new Set(faultTypesInData.map((item) => item.value))
      // const faultTypesInDataUnique = Array.from(uniqueFaultTypes).map((item) => ({
      //   value: item,
      //   label: item?.toString(),
      // }))

      faultTypesInDataUnique.unshift(
        selectAllOption as {
          value: string
          label: string
        },
      )
      setFaultTypeOptions(faultTypesInDataUnique as IselectOptionProps[])

      const faultDescriptionsInData: IselectOptionProps[] = filteredFaultsData?.map((fault) => ({
        value: fault.eventDescription,
        label: fault.eventDescription,
      }))

      // remove duplicate fault descriptions
      const faultDescriptionsInDataUnique: IselectOptionProps[] = faultDescriptionsInData.filter(
        (v, i, a) => a.findIndex((t) => t.value === v.value) === i,
      )
      // const uniqueFaultDescriptions = new Set(faultDescriptionsInData.map((item) => item.value))
      // const faultDescriptionsInDataUnique = Array.from(uniqueFaultDescriptions).map((item) => ({
      //   value: item,
      //   label: item,
      // }))

      faultDescriptionsInDataUnique.unshift(
        selectAllOption as IselectOptionProps & { label: string },
      )
      setFaultDescriptionOptions(faultDescriptionsInDataUnique as IselectOptionProps[])
    }
  }, [filteredFaultsData])

  useEffect(() => {
    function hideFilterFormHandler(e: KeyboardEvent) {
      if (e.key === 'Escape' && showFilterForm) {
        setShowFilterForm(false)
      }
    }
    window.addEventListener('keyup', hideFilterFormHandler)

    return () => {
      window.removeEventListener('keyup', hideFilterFormHandler)
    }
  }, [showFilterForm])
  return (
    <>
      <BusinessAndCustomerSelection pageTitle='Service' />
      <div
        id='faults-page-main'
        className='flex flex-col h-[calc(100vh-80px)]'
        aria-hidden='true'
        onClick={() => showFilterForm && setShowFilterForm(false)}
      >
        <div
          id='faults-page-widgets'
          className='bg-c-light-blue-2 px-3  md:px-5 py-3 md:py-4 gap-x-4 gap-y-2 grid grid-cols-1 xs:grid-cols-3'
        >
          <div className='h-full'>
            <FaultsByDeviceTypeWidget
              faultsData={unfilteredFaultsData}
              onFilter={(filterRequestValues: IFilterValues[]) =>
                handleFilterFormSubmit(filterRequestValues)
              }
              deviceTypeFilteredParam={
                filterTableValues.find(
                  (filter) => filter.columnId === ServiceFaultsColumnIds.deviceType,
                )?.value as string
              }
            />
          </div>
          <div className='h-full'>
            <FaultsByNumberOfDaysWidget
              faultsData={unfilteredFaultsData}
              onFilter={(filterRequestValues: IFilterValues[]) =>
                handleFilterFormSubmit(filterRequestValues)
              }
              faultsDateRangeFilteredParam={
                filterTableValues.find(
                  (filter) => filter.columnId === ServiceFaultsColumnIds.faultDateTime,
                )?.value as DateRange
              }
              faultsDateRangeSelctorFilteredParam={
                filterTableValues.find(
                  (filter) => filter.columnId === ServiceFaultsColumnIds.faultDateSelector,
                )?.value as DateRangeSelectorTypes
              }
            />
          </div>
          <div className='h-full'>
            <FaultsByFaultTypeWidget
              faultsData={faultsData ?? []}
              onFilter={(filterRequestValues: IFilterValues[]) =>
                handleFilterFormSubmit(filterRequestValues)
              }
              faultTypeFilteredParam={
                filterTableValues.find(
                  (filter) => filter.columnId === ServiceFaultsColumnIds.faultType,
                )?.value as string
              }
              faultTypeUntransaltedFilteredParam={
                filterTableValues.find(
                  (filter) => filter.columnId === ServiceFaultsColumnIds.faultTypeUntranslated,
                )?.value as string
              }
            />
          </div>
        </div>

        <ServiceFaultstable
          tableData={unfilteredFaultsData}
          isLoading={isFaultsDataLoading || isDeviceListLoading}
          updateFilteredData={(data: iFaultData[]) => setFilteredFaultsData(data)}
          filterValues={filterTableValues}
          serialNumberOptions={serialNumberOptions}
          deviceTypeOptions={deviceTypeOptions}
          faultTypeOptions={faultTypeOptions}
          faultDescriptionOptions={faultDescriptionOptions}
          onFilterFormSubmit={(filterVals: IFilterValues[]) => handleFilterFormSubmit(filterVals)}
          onFilterFormReset={() => handleFilterFormReset()}
          onShowFilter={() => setShowFilterForm(true)}
          filterSummary={ServiceFaultFilterFormat(
            filterTableValues,
            customerList && selectedCustomerParam !== ''
              ? customerList?.find((c) => c.id === selectedCustomerParam)?.name || ''
              : customerList?.[0]?.name || '',
            businessUnitsList && selectedMainBUParam !== ''
              ? businessUnitsList?.find((c) => c.id === selectedMainBUParam)?.name || ''
              : businessUnitsList?.[0]?.name || '',
          )}
        />
      </div>

      <div
        id='faults-page-filter-form'
        className={clsx(
          'filter-form hideScrollBar',
          showFilterForm ? 'z-50 -ml-80 opacity-100' : 'opacity-0 -z-50',
        )}
      >
        <ServiceFaultsFilterForm
          selectedFilterValues={convertISelectOptionPropsToServiceFaultsFormType}
          serialNumberOptions={serialNumberOptions}
          customerAssetNumberOptions={customerAssetNumberOptions}
          deviceUserNameOptions={deviceUserNameOptions}
          deviceTypeOptions={deviceTypeOptions}
          businessUnitOptions={businessUnitOptions}
          faultTypeOptions={faultTypeOptions}
          faultDescriptionOptions={faultDescriptionOptions}
          onFilterSubmit={(formData: IFilterValues[]) => handleFilterFormSubmit(formData)}
          onFilterReset={() => handleFilterFormReset()}
          onFilterFormClose={() => setShowFilterForm(false)}
        />
      </div>
    </>
  )
}

export default withErrorBoundary(withPageTracking(ServiceFaultsPage), {
  FallbackComponent: ErrorPage,
})

// -ml-80 -mt-[272px]

// useEffect(() => {
//   if (businessUnitsList && businessUnitsList.length > 0) {
//     if (selectedCustomerParam !== selectAllOption.value && isGlobalUser) {
//       setMainBUParam(businessUnitsList[1].value as string)
//       // searchParams.set('mainbu', businessUnitsList[1].value as string)
//       // // Disable ALL option for main BU
//       // const AllOptionIndex = businessUnitsList.findIndex(
//       //   (bu) => bu.value === selectAllOption.value,
//       // )
//       // if (AllOptionIndex) {
//       //   businessUnitsList[AllOptionIndex] = {
//       //     ...businessUnitsList[AllOptionIndex],
//       //     isDisabled: true,
//       //   }
//       // }
//       // businessUnitsList.shift()
//       // businessUnitsList.unshift({...selectAllOption, isDisabled: true})
//     } else {
//       setMainBUParam(selectAllOption.value as string)
//       // searchParams.set('mainbu', selectAllOption.value as string)
//     }
//     // setSearchParams(searchParams)
//   }
// }, [businessUnitsList, selectedCustomerParam])
