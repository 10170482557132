import { useQuery } from '@tanstack/react-query'
import { useMsal } from '@azure/msal-react'
import { sub } from 'date-fns'
import { LoaderFunction, createSearchParams } from 'react-router-dom'
import ConnectAPIClient from 'services/ConnectAPIClient'
import { getAccessToken, getAccessTokenFromPCA } from 'services/MSALService'
import { getOrFetchDeviceDetails } from 'data/DeviceDetailsHook'
import {
  iDeviceLogsAPIParams,
  iDeviceLogsDataLoaderParams,
  iGetDeviceLogsParams,
  iGetOrFetchDeviceLogDataProps,
} from 'pages/deviceDetails/commonUtils/interfaces'
import { AxiosError } from 'axios'
import { BumpHistoryQueryKeyIdDates } from 'services/apis/caching/device'
import { deviceBumpHistoryURL } from 'services/apis/urls/device'
import { iBumpHistory } from './interface'

async function fetchDeviceBumpHistory(
  deviceEventLogParams: iDeviceLogsAPIParams,
): Promise<iBumpHistory[]> {
  try {
    const { deviceId, startDate = '', endDate = '', token } = deviceEventLogParams
    const urlSearchParams = new URLSearchParams()
    if (startDate) {
      urlSearchParams.set('FromDate', startDate)
    }
    if (endDate) {
      urlSearchParams.set('ToDate', endDate)
    }
    const url = deviceBumpHistoryURL(deviceId, urlSearchParams.toString())
    const resp = await ConnectAPIClient.get<iBumpHistory[]>(url, token)
    const response = resp.data as iBumpHistory[]
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}

async function getOrFetchBumpHistoryData(props: iGetOrFetchDeviceLogDataProps) {
  const { queryClient, token, startDate = '', endDate = '', deviceId } = props
  const bumpHistoryDataCached = queryClient.getQueryData<iBumpHistory[]>(
    BumpHistoryQueryKeyIdDates({ deviceId, startDate, endDate }),
  )
  if (bumpHistoryDataCached) return bumpHistoryDataCached
  const bumpHistoryParams = {
    deviceId,
    startDate,
    endDate,
    token,
  }
  const BumpHistory = await fetchDeviceBumpHistory(bumpHistoryParams)
  return BumpHistory
}

async function getBumpHistory(BumpHistoryParams: iGetDeviceLogsParams): Promise<iBumpHistory[]> {
  const { startDate, endDate, msalContext, redirectPageURL, deviceId } = BumpHistoryParams
  const token = await getAccessToken({ msalContext, redirectPageURL })
  const bumpHistoryData = await fetchDeviceBumpHistory({
    startDate,
    endDate,
    token,
    deviceId,
  })
  return bumpHistoryData
}

export const BumpHistoryLoader =
  (props: iDeviceLogsDataLoaderParams): LoaderFunction =>
  async ({ request, params }) => {
    try {
      const { queryClient, msalInstance } = props
      if (!params?.deviceSerialNumber) throw new Error('No device serial number')
      if (!msalInstance) throw new Error('No MSAL Context')
      if (!queryClient) throw new Error('No query client')

      const token = await getAccessTokenFromPCA({ msalInstance })

      const searchParams = createSearchParams(new URLSearchParams(request.url.split('?')[1]))

      const endDate = searchParams.get('to') ?? new Date().toISOString().split('T')[0]
      const startDate =
        searchParams.get('from') ??
        sub(new Date(endDate), { months: 6 }).toISOString().split('T')[0]

      const deviceDetails = await getOrFetchDeviceDetails({
        queryClient,
        token,
        deviceSerialNumber: params?.deviceSerialNumber,
      })

      const deviceId = deviceDetails?.id ?? ''

      const bumpHistoryData = getOrFetchBumpHistoryData({
        queryClient,
        token,
        startDate,
        endDate,
        deviceId,
      })

      return bumpHistoryData
    } catch (error) {
      console.error(error)
      throw error
      // return []
    }
  }

export function useBumpHistoryData(
  deviceId: string,
  startDate: string,
  endDate: string,
  redirectPageURL: string,
  enabled?: boolean,
) {
  const msalContext = useMsal()
  const bumpHistoryDataFetched = useQuery(
    BumpHistoryQueryKeyIdDates({ deviceId, startDate, endDate }),
    async () =>
      getBumpHistory({
        msalContext,
        redirectPageURL,
        deviceId,
        startDate,
        endDate,
      }),
    {
      enabled,
      onError: (error: AxiosError) => {
        console.error(error)
      },
    },
  )

  return bumpHistoryDataFetched
}
