import { IFilterQueryParamValues, IselectOptionProps } from 'forms/FormModelInterface'

export const CustomersPageColumnIds = {
  name: 'name',
  billingPlanName: 'billingPlanName',
  billingPlanType: 'billingPlanType',
  features: 'features',
  subscriptionActivationDate: 'subscriptionActivationDate',
  subscriptionExpirationDate: 'subscriptionExpirationDate',
  status: 'status',
}

export const CustomersPageParamLabels = {
  name: 'name',
  billingPlanName: 'billingPlanName',
  billingPlanType: 'billingPlanType',
  features: 'features',
  subscriptionActivationDate: 'subscriptionActivationDate',
  subscriptionExpirationDate: 'subscriptionExpirationDate',
  status: 'status',
}

export const CustomersPageFilterParamValues: IFilterQueryParamValues[] = [
  {
    paramName: CustomersPageParamLabels.name,
    paramValue: null,
  },
  {
    paramName: CustomersPageParamLabels.features,
    paramValue: null,
  },
  {
    paramName: CustomersPageParamLabels.billingPlanName,
    paramValue: null,
  },
  {
    paramName: CustomersPageParamLabels.billingPlanType,
    paramValue: null,
  },
  {
    paramName: CustomersPageParamLabels.subscriptionActivationDate,
    paramValue: null,
  },
  {
    paramName: CustomersPageParamLabels.subscriptionExpirationDate,
    paramValue: null,
  },
  {
    paramName: CustomersPageParamLabels.status,
    paramValue: null,
  },
]

export type CustomersPageFilterFormType = {
  name: IselectOptionProps
  features: IselectOptionProps
  billingPlanName: IselectOptionProps
  billingPlanType: IselectOptionProps
  subscriptionActivationDate: IselectOptionProps
  subscriptionExpirationDate: IselectOptionProps
  status: IselectOptionProps
}

export const CustomersPageColumnHeader = {
  [CustomersPageColumnIds.name]: 'Name',
  [CustomersPageColumnIds.billingPlanName]: 'Plan Name',
  [CustomersPageColumnIds.billingPlanType]: 'Plan Type',
  [CustomersPageColumnIds.features]: 'Features',
  [CustomersPageColumnIds.subscriptionActivationDate]: 'Activation Date',
  [CustomersPageColumnIds.subscriptionExpirationDate]: 'Expiration Date',
  [CustomersPageColumnIds.status]: 'Status',
}

export enum CustomerStatusEnums {
  Active = 'Active',
  Inactive = 'InActive',
  CreationFailed = 'CreationFailed',
  Invited = 'Invited',
}
export const CustomerStatusConstants: {
  [key in CustomerStatusEnums]: string
} = {
  [CustomerStatusEnums.Active]: 'Active',
  [CustomerStatusEnums.Inactive]: 'Inactive',
  [CustomerStatusEnums.CreationFailed]: 'Creation Failed',
  [CustomerStatusEnums.Invited]: 'Invited',
}
