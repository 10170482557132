import { CalenderDateType, CalenderDayType } from './CalenderType'

/**
 * `classNames` function is a utility function to concatenate class names.
 *
 * @function
 *
 * @param {...string[]} classes - An array of class names. It can contain any number of class names.
 *
 * @returns {string} - Returns a string of class names separated by a space. It filters out any falsy values before joining.
 */
export function classNames(...classes: string[]): string {
  return classes.filter(Boolean).join(' ')
}

/**
 * Returns a static date string in the format 'YYYY-MM-DD'.
 *
 * @param date - The input date object.
 * @param shouldDecDay - A boolean flag indicating whether to decrement the day by 1. Defaults to `false`.
 * @returns A string representing the formatted date.
 *
 * @remarks
 * If `shouldDecDay` is `false`, the function will decrement the day by 1.
 * If the resulting day is less than or equal to 0, the function will adjust the month and year accordingly.
 * The month and day are zero-padded to ensure two digits.
 */
export const returnStaticDate = (date: Date, shouldDecDay = false): string => {
  let year = date.getFullYear()
  let month = date.getMonth()
  let day = shouldDecDay ? date.getDate() : date.getDate() - 1

  if (day <= 0) {
    month -= 1
    if (month < 0) {
      month = 11
      year -= 1
    }
    day = new Date(year, month + 1, 0).getDate()
  }

  const monthStr = String(month + 1).padStart(2, '0')
  const dayStr = String(day).padStart(2, '0')

  return `${year}-${monthStr}-${dayStr}`
}
/**
 * `returnDaysInMonth` function returns an array of days in a month including the trailing days of the previous and next month.
 *
 * @function
 *
 * @param {number} month - The month for which the days are to be returned. The month is 0-indexed (i.e., January is 0, February is 1, etc.).
 *
 * @param {number} year - The year for which the days are to be returned.
 *
 * @returns {CalenderDayType[]} - Returns an array of `CalenderDayType` objects representing each day in the month including the trailing days of the previous and next month.
 *
 * Each `CalenderDayType` object includes the date, and flags indicating if the day is in the current month and if the day is today.
 */
export function returnDaysInMonth(month: number, year: number): CalenderDayType[] {
  // Get the last date of the previous month
  const lastDateOfPreviousMonth = new Date(year, month, 0).getDate()

  // Create a date object for the current date
  const currentDate = new Date(year, month, 1)
  const currentYear = currentDate.getFullYear()
  const currentMonth = currentDate.getMonth()

  // Get the first and last day of the current month
  const firstDayOfMonth = new Date(currentYear, currentMonth, 1)
  const lastDayOfMonth = new Date(currentYear, currentMonth + 1, 0)

  // Get the number of days in the current month
  const daysInMonth = lastDayOfMonth.getDate()

  // Get the day of the week for the first day of the month (0 for Sunday, 1 for Monday, etc.)
  const firstDayOfWeek = firstDayOfMonth.getDay()

  // Calculate the previous and next month and year
  const previousMonth = currentMonth === 0 ? 11 : currentMonth - 1
  const previousYear = currentMonth === 0 ? currentYear - 1 : currentYear
  const nextMonth = currentMonth === 11 ? 0 : currentMonth + 1
  const nextYear = currentMonth === 11 ? currentYear + 1 : currentYear

  // Initialize an array to store the days
  const days: CalenderDayType[] = []

  // Add the trailing days of the previous month
  for (let i = firstDayOfWeek === 0 ? 7 : firstDayOfWeek; i > 1; i -= 1) {
    const date = new Date(previousYear, previousMonth, lastDateOfPreviousMonth - i + 2)
    days.push({ date: returnStaticDate(date) })
  }

  // Add the days of the current month
  for (let i = 1; i <= daysInMonth; i += 1) {
    const date = new Date(currentYear, currentMonth, i)
    days.push({
      date: returnStaticDate(date),
      isCurrentMonth: true,
      isToday: returnStaticDate(date) === returnStaticDate(new Date()),
    })
  }

  // Add the leading days of the next month
  const remainingDays = 42 - days.length
  for (let i = 1; i <= remainingDays; i += 1) {
    const date = new Date(nextYear, nextMonth, i)
    days.push({ date: returnStaticDate(date) })
  }

  // Return the array of days
  return days
}

/**
 * `dayDateToDate` function converts a date string in the format 'day/month/year' to a JavaScript Date object.
 *
 * @function
 *
 * @param {string} date - A string representing a date in the format 'day/month/year'.
 *
 * @returns {Date} - Returns a JavaScript Date object representing the same date.
 *
 * The function splits the input string into day, month, and year, and uses these values to create a new Date object.
 * Note that the month is 0-indexed in JavaScript Date objects, so 1 is subtracted from the month.
 */
export const dayDateToDate = (date: string, shouldDelay?: boolean): Date => {
  // Split the date string into day, month, and year
  const [year, month, day] = date.split('-')
  // Create a new Date object using the formatted date string
  const newDate = new Date(
    Number(year),
    Number(month) - 1,
    shouldDelay ? Number(day) : Number(day) + 1,
  )

  return newDate
}

/**
 * `selectedDateIsInRange` function checks if a given date is within a specified date range.
 *
 * @function
 *
 * @param {CalenderDateType} startDate - A string representing the start date of the range in the format 'day/month/year'.
 *
 * @param {CalenderDateType} endDate - A string representing the end date of the range in the format 'day/month/year'.
 *
 * @param {CalenderDateType} currentDate - A string representing the date to check in the format 'day/month/year'.
 *
 * @returns {boolean} - Returns a boolean indicating if the `currentDate` is within the range specified by `startDate` and `endDate`.
 *
 * The function converts the input strings to JavaScript Date objects using the `dayDateToDate` function, and then checks if the `currentDate` is within the range.
 */
export const selectedDateIsInRange = (
  startDate: CalenderDateType,
  endDate: CalenderDateType,
  currentDate: CalenderDateType,
) => {
  // Convert the start, end, and current dates from string to Date objects
  const startDateInDate = dayDateToDate(startDate as string)
  const endDateInDate = dayDateToDate(endDate as string)
  const currentDateInDate = dayDateToDate(currentDate as string)
  // Check if the current date is within the range of start and end dates
  // Return true if it is, false otherwise
  return currentDateInDate >= startDateInDate && currentDateInDate <= endDateInDate
}

/**
 * `debounce` function creates and returns a debounced version of the passed function.
 *
 * @function
 *
 * @param {Function} func - The function to debounce. It should accept a single argument of type `CalenderDayType`.
 *
 * @param {number} wait - The number of milliseconds to delay before executing the function.
 *
 * @returns {Function} - Returns a debounced version of the passed function.
 *
 * The returned function, when invoked, clears any pending timeouts and sets up a new timeout that will call the original function after the specified `wait` time.
 * If the returned function is called again before the `wait` time has elapsed, the previous timeout is cleared and a new one is set up.
 */
export function debounce<T>(func: (data: T) => void, wait: number): (args: T) => void {
  // Declare a variable to hold the timeout
  let timeout: NodeJS.Timeout

  // Return a function that will be executed when the debounced function is called
  return function executedFunction(args: T) {
    // Define a function to be called after the wait time
    const later = () => {
      // Clear the timeout
      clearTimeout(timeout)

      // Call the original function with the arguments
      func(args as T)
    }

    // Clear any existing timeout
    clearTimeout(timeout)

    // Set a new timeout with the wait time
    timeout = setTimeout(later, wait)
  }
}

/**
 * `generateDateRange` function generates an array of dates within a specified date range.
 *
 * @function
 *
 * @param {CalenderDateType} startDate - A string representing the start date of the range in the format 'day/month/year'.
 *
 * @param {CalenderDateType} endDate - A string representing the end date of the range in the format 'day/month/year'.
 *
 * @returns {Date[]} - Returns an array of JavaScript Date objects representing each date within the range.
 *
 * The function converts the input strings to JavaScript Date objects using the `dayDateToDate` function, and then generates an array of dates within the range.
 */
export function generateDateRange(
  startDate: CalenderDateType,
  endDate: CalenderDateType,
): string[] {
  // Convert the start and end dates from string to Date objects
  const startDateInDate = dayDateToDate(startDate as string)
  const endDateInDate = dayDateToDate(endDate as string)

  // Initialize an array to store the dates
  const dates: string[] = []

  // Iterate over each date within the range
  let currentDate = startDateInDate
  while (currentDate <= endDateInDate) {
    // Add the current date to the array
    dates.push(returnStaticDate(currentDate))

    // Move to the next day
    currentDate = new Date(currentDate.getTime() + 24 * 60 * 60 * 1000)
  }

  // Return the array of dates
  return dates
}

/**
 * `selectDateInRange` function selects a date within a specified date range.
 *
 * @function
 *
 * @param {CalenderDateType} selectedDateToFilter - A string representing the selected date in the format 'day/month/year'.
 *
 * @param {Date[]} listOfDate - An array of JavaScript Date objects representing the current date range.
 *
 * @returns {Date[]} - Returns an array of JavaScript Date objects representing the updated date range.
 *
 * The function converts the selected date to a JavaScript Date object using the `dayDateToDate` function, and then adds it to the current date range.
 */
export const deselectDateFromRange = (
  selectedDateToFilter: CalenderDateType,
  listOfDate: string[],
): string[] => {
  const middleIndex = Math.floor(listOfDate.length / 2)

  const selectedDate = dayDateToDate(selectedDateToFilter as string)
  const middleDate = dayDateToDate(listOfDate[middleIndex] as string)

  const selectedDateInMillis = selectedDate.getTime()
  const middleDateInMillis = middleDate.getTime()

  return listOfDate.filter((dateItem) => {
    const dateItemInDate = dayDateToDate(dateItem as string)
    const dateItemInMillis = dateItemInDate.getTime()

    return selectedDateInMillis < middleDateInMillis
      ? dateItemInMillis >= selectedDateInMillis
      : dateItemInMillis <= selectedDateInMillis
  })
}

export const manageCalenderRangeDate = (prev: CalenderDateType[], day?: CalenderDayType) => {
  const newDateSet = new Set(prev)
  if (day) {
    newDateSet.add(day.date)
  }
  const arrayOfDate = Array.from(newDateSet).sort(
    (a, b) => dayDateToDate(a as string).getTime() - dayDateToDate(b as string).getTime(),
  )

  if (prev.length > 0 && day) {
    const generatedDateRangeArray = generateDateRange(
      arrayOfDate[0],
      arrayOfDate[arrayOfDate.length - 1],
    )
    return deselectDateFromRange(day.date, generatedDateRangeArray)
  }
  return arrayOfDate
}
