import { iAPIDateRangeBusinessUnitParams } from 'pages/service-faults/data/FaultsData'

export const allFaultsQueryKey = (params: iAPIDateRangeBusinessUnitParams) => {
  const { startDate, endDate, businessUnitId } = params
  return ['faults', 'businessUnitId', businessUnitId, 'startDate', startDate, 'endDate', endDate]
}

export const servicesQueries = {
  allFaultsQueryKey,
}
