import { allBusinessUnitOption } from 'data/BusinessUnitsListHookWrapper'
import { convertUrlForDemoMode } from 'utils/CommonUtils'
import { endpoints } from '../endpoints'

export const safetyListDataURL = (
  startDate: string,
  endDate: string,

  businessUnitId?: string,
  // customerId?: string,
  // serverSideSearchParams?: iSafetySearchParams,
) => {
  // const searchParams = new URLSearchParams(serverSideSearchParams as any)
  // const paginationParams = new URLSearchParams(serverSideSearchParams?.paginationParams as any)
  // const serverSearchParams = new URLSearchParams(
  //   serverSideSearchParams?.serverSideSearchParams as any,
  // )

  // if (serverSearchParams.get('serialNumber') === '') {
  //   serverSearchParams.delete('serialNumber')
  // }
  // if (serverSearchParams.get('AssetNumber') === '') {
  //   serverSearchParams.delete('AssetNumber')
  // }
  // if (serverSearchParams.get('assignedTo') === '') {
  //   serverSearchParams.delete('assignedTo')
  // }
  // if (serverSearchParams.get('toDate') === '') {
  //   serverSearchParams.delete('toDate')
  // }
  // if (serverSearchParams.get('fromDate') === '') {
  //   serverSearchParams.delete('fromDate')
  // }
  // if (serverSearchParams.get('gas') === '') {
  //   serverSearchParams.delete('gas')
  // }
  // if (serverSearchParams.get('Alarm') === '') {
  //   serverSearchParams.delete('Alarm')
  // }
  // if (serverSearchParams.get('Level') === '-1') {
  //   serverSearchParams.delete('Level')
  // }
  // if (serverSearchParams.get('Alarm1SetPoint') === '-1') {
  //   serverSearchParams.delete('Alarm1SetPoint')
  // }

  let url = `${endpoints.device.log}?FromDate=${startDate}&ToDate=${endDate}`
  if (businessUnitId !== null && businessUnitId !== (allBusinessUnitOption.id as string)) {
    url += `&BusinessUnitId=${businessUnitId}`
  }

  return convertUrlForDemoMode(url)

  //   if (
  //   businessUnitId &&
  //   businessUnitId.toLowerCase() !== selectAllOption.value?.toString().toLowerCase()
  // ) {
  //   // if (paginationParams.toString() === '' && serverSearchParams.toString() === '') {
  //   //   return `devicelogs/api/device/log/safety?ExpandBusinessUnit=true&BusinessUnitId=${businessUnitId}`
  //   // }
  //   return `devicelogs/api/device/log/safety?ExpandBusinessUnit=true&BusinessUnitId=${businessUnitId}&${paginationParams.toString()}&${serverSearchParams.toString()}`
  //   // return `devicelogs/api/device/log/safety?ExpandBusinessUnit=true&BusinessUnitId=${businessUnitId}`
  // }
  // // if (paginationParams.toString() === '' && serverSearchParams.toString() === '') {
  // //   return `devicelogs/api/device/log/safety`
  // // }
  // return `devicelogs/api/device/log/safety?&${paginationParams.toString()}&${serverSearchParams.toString()}`
}
