import React, { useContext, useEffect, useMemo, useState, useCallback } from 'react'
import clsx from 'clsx'
import { useLocation, useSearchParams } from 'react-router-dom'
import { sub } from 'date-fns'
import { dateRangeOptions, selectAllOption } from 'forms/FormUtils'
import { DateRange, IFilterValues, IselectOptionProps } from 'forms/FormModelInterface'
import { DateRangeSelectorTypes } from 'utils/CommonEnums'
import { useDeviceList } from 'data/DeviceListHook'
import { AlarmTypeValues, returnDateWithoutTimeOffset } from 'utils/Constants'
import ExportData from 'exportReports/ExportData'
import BusinessAndCustomerSelection from 'components/modules/BusinessAndCustomerSelection'
import { BusinessContext } from 'contexts/BusinessContext'
import { withPageTracking } from 'utils/AppInsightConfig'
import { useGenericEventHandler } from 'data/GenericEventHandler'
import { withErrorBoundary } from 'react-error-boundary'
import ErrorPage from 'pages/common/ErrorPage'
import { dayDateToDate, returnStaticDate } from 'components/modules/Calender/CalenderUtils'
import { SafetyReportColumns } from '../export/SafetyReportFormat'
import SafetyFilterFormat from '../export/SafetyFilterFormat'
import SafetyPageTable from './SafetyPageTable'
import {
  SafetyPageColumnIds,
  SafetyPageParamLabels,
  SafetyPageFilterFormType,
} from './SafetyPageUtils'
import { useSafetyListData } from '../data/SafetyListData'
import { iSafetyData } from '../data/iSafetyList'
import SafetyPageFilterForm from './SafetyPageFilterForm'
import SafetyAlarmsType from '../widgets/SafetyAlarmsType'
import SafetyAlarmsGasType from '../widgets/SafetyAlarmsGasType'
import AlarmTypeCalender from '../widgets/AlarmTypeCalender'

function SafetyPage() {
  useEffect(() => {
    document.title = 'Safety'
  }, [])

  const location = useLocation()
  const redirectPageURL = `${location.pathname}${location.search}`

  const [showFilterForm, setShowFilterForm] = useState(false)

  // global filter params
  const [searchParams, setSearchParams] = useSearchParams()
  // pagination params

  const pageIndexParam = searchParams.get(SafetyPageParamLabels.pageNumber) ?? '0'
  const pageSizeParam = searchParams.get(SafetyPageParamLabels.pageSize) ?? '10'

  // filter form params
  const childBUParam = searchParams.get(SafetyPageParamLabels.businessUnit) ?? ''
  const deviceSerialParam = searchParams.get(SafetyPageParamLabels.serialNumber) ?? ''
  const assetNumberParam = searchParams.get(SafetyPageParamLabels.assetNumber) ?? ''
  const gasNameParam = searchParams.get(SafetyPageParamLabels.gasName) ?? ''
  const alarmTypeParam = searchParams.get(SafetyPageParamLabels.alarmType) ?? ''
  const alarmLevelParam = searchParams.get(SafetyPageParamLabels.alarmLevel) ?? ''
  const alarmSetPointParam = searchParams.get(SafetyPageParamLabels.alarmSetPoint) ?? ''
  const deviceUserParam = searchParams.get(SafetyPageParamLabels.deviceUser) ?? ''

  const alarmDateSelectorParam =
    searchParams.get(SafetyPageParamLabels.alarmDateSelector) ?? DateRangeSelectorTypes.All

  const isFromDate = searchParams.get(SafetyPageParamLabels.alarmDateFrom) ?? ''
  const isToDate = searchParams.get(SafetyPageParamLabels.alarmDateTo) ?? ''

  const filterAlarmsDateTimeTo = returnDateWithoutTimeOffset(isToDate || new Date())
  const filterAlarmsDateTimeFrom = returnDateWithoutTimeOffset(
    isFromDate || sub(filterAlarmsDateTimeTo, { months: 6 }),
  )
  const [unFilteredSafetyData, setUnFilteredSafetyData] = useState<iSafetyData[]>([])
  const [filteredSafetyData, setfilteredSafetyData] = useState<iSafetyData[]>([])

  const [totalRecords, setTotalRecords] = useState<number>(0)

  const filterFaultDateTimeTo = returnDateWithoutTimeOffset(isFromDate || new Date())
  const filterFaultDateTimeFrom = returnDateWithoutTimeOffset(
    isToDate || sub(filterFaultDateTimeTo, { months: 6 }),
  )

  /**
   * Business Units List and Customer List from BusinessContext
   */
  const {
    customerDataFromQuery,
    businessUnitDataFromQuery,
    selectedBusinessUnit,
    selectedCustomer,
  } = useContext(BusinessContext)

  const businessUnitsList = businessUnitDataFromQuery?.data ?? []
  const customerList = customerDataFromQuery?.data ?? []
  const selectedCustomerParam = selectedCustomer?.id ?? ''
  const selectedMainBUParam = selectedBusinessUnit?.id ?? ''

  //  startDate: string,
  // endDate: string,
  // redirectPageURL: string,
  // businessUnitId: string,
  // customerId?: string,
  // businessUnitsList?: iBusinessUnitDetails[] | undefined,
  // enabled?: boolean,

  const { genericEventHandler } = useGenericEventHandler()
  const {
    data: safetyDataAPIResponse,
    isLoading: isSafetyListDataLoading,
    isError: isSafetyListDataError,
    error: safetyListDataError,
  } = useSafetyListData(
    filterAlarmsDateTimeFrom.toISOString().split('T')[0],
    filterAlarmsDateTimeTo.toISOString().split('T')[0],
    redirectPageURL,
    selectedMainBUParam as string,
    selectedCustomerParam,
    businessUnitsList,
    businessUnitsList !== undefined,
  )

  const {
    data: deviceListData,
    isLoading: isDeviceListLoading,
    isError: isDeviceListError,
    error: deviceListError,
  } = useDeviceList(
    redirectPageURL,
    selectedMainBUParam as string,
    selectedCustomerParam,
    businessUnitsList,
    businessUnitsList !== undefined,
  )
  useEffect(() => {
    if (isSafetyListDataError) {
      genericEventHandler({
        onlyTrack: true,
        severity: 'error',
        error: safetyListDataError,
        message: safetyListDataError.message || 'Error in Safety List Data',
        extraData: {
          component: 'SafetyPage',
          action: 'useSafetyListData',
        },
      })
    }
    if (isDeviceListError) {
      genericEventHandler({
        onlyTrack: true,
        severity: 'error',
        error: deviceListError,
        message: deviceListError.message || 'Error in Device List Data',
        extraData: {
          component: 'SafetyPage',
          action: 'useDeviceList',
        },
      })
    }
  }, [isSafetyListDataError, safetyListDataError, isDeviceListError, deviceListError])

  useEffect(() => {
    const combinedData: iSafetyData[] = []
    // Update business unit and translation for fault description

    if (safetyDataAPIResponse && deviceListData) {
      const safetyListData = safetyDataAPIResponse.Data
      safetyListData?.forEach((dataRow) => {
        const deviceBusinessUnit = deviceListData.find(
          (device) => device.id === dataRow.DeviceId,
        )?.businessUnit

        combinedData.push({
          ...dataRow,
          businessUnitName: deviceBusinessUnit?.name ?? 'unknown',
        })
      })
      const recordCount = safetyDataAPIResponse.TotalRecords
      setTotalRecords(recordCount)
    }

    setUnFilteredSafetyData(combinedData)
  }, [safetyDataAPIResponse, deviceListData])

  const resetFilterFormParams = () => {
    if (searchParams.toString().length > 0) {
      searchParams.delete(SafetyPageParamLabels.serialNumber)
      searchParams.delete(SafetyPageParamLabels.deviceUser)
      searchParams.delete(SafetyPageParamLabels.businessUnit)
      searchParams.delete(SafetyPageParamLabels.alarmDateFrom)
      searchParams.delete(SafetyPageParamLabels.alarmDateTo)
      searchParams.delete(SafetyPageParamLabels.alarmDateSelector)
      searchParams.delete(SafetyPageParamLabels.alarmLevel)
      searchParams.delete(SafetyPageParamLabels.alarmSetPoint)
      searchParams.delete(SafetyPageParamLabels.alarmType)
      searchParams.delete(SafetyPageParamLabels.gasName)
      searchParams.delete(SafetyPageParamLabels.assetNumber)

      setSearchParams(searchParams)
    }
  }

  const [serialNumberOptions, setSerialNumberOptions] = useState<IselectOptionProps[]>([])
  const [assetNumberOptions, setAssetNumberOptions] = useState<IselectOptionProps[]>([])
  const [gasNameOptions, setGasNameOptions] = useState<IselectOptionProps[]>([])
  const [alarmTypeOptions, setAlarmTypeOptions] = useState<IselectOptionProps[]>([])
  const [alarmLevelOptions, setAlarmLevelOptions] = useState<IselectOptionProps[]>([])
  const [alarmSetPointOptions, setAlarmSetPointOptions] = useState<IselectOptionProps[]>([])
  const [businessUnitOptions, setBusinessUnitOptions] = useState<IselectOptionProps[]>([])
  const [deviceUserOptions, setDeviceUserOptions] = useState<IselectOptionProps[]>([])

  const handleFilterFormSubmit = useCallback(
    (formData: IFilterValues[]) => {
      // console.log('Filter form submit handler in main page', formData)

      formData.forEach((filter) => {
        if (filter.columnId === SafetyPageColumnIds.serialNumber) {
          if (filter?.value === '') {
            searchParams.delete(SafetyPageParamLabels.serialNumber)
          } else {
            searchParams.set(SafetyPageParamLabels.serialNumber, filter?.value as string)
          }
        }

        if (filter.columnId === SafetyPageColumnIds.assetNumber) {
          if (filter?.value === '') {
            searchParams.delete(SafetyPageParamLabels.assetNumber)
          } else {
            searchParams.set(SafetyPageParamLabels.assetNumber, filter?.value as string)
          }
        }

        if (filter.columnId === SafetyPageColumnIds.businessUnit) {
          if (filter?.value === '') {
            searchParams.delete(SafetyPageParamLabels.businessUnit)
          } else {
            searchParams.set(SafetyPageParamLabels.businessUnit, filter?.value as string)
          }
        }

        if (filter.columnId === SafetyPageColumnIds.deviceUser) {
          if (filter?.value === '') {
            searchParams.delete(SafetyPageColumnIds.deviceUser)
          } else {
            searchParams.set(SafetyPageParamLabels.deviceUser, filter?.value as string)
          }
        }

        if (filter.columnId === SafetyPageColumnIds.alarmType) {
          if (filter?.value === '') {
            searchParams.delete(SafetyPageParamLabels.alarmType)
          } else {
            searchParams.set(SafetyPageParamLabels.alarmType, filter?.value as string)
          }
        }

        if (filter.columnId === SafetyPageColumnIds.alarmLevel) {
          if (filter?.value === '') {
            searchParams.delete(SafetyPageParamLabels.alarmLevel)
          } else {
            searchParams.set(SafetyPageParamLabels.alarmLevel, filter?.value as string)
          }
        }

        if (filter.columnId === SafetyPageColumnIds.alarmSetPoint) {
          if (filter?.value === '') {
            searchParams.delete(SafetyPageParamLabels.alarmSetPoint)
          } else {
            searchParams.set(SafetyPageParamLabels.alarmSetPoint, filter?.value as string)
          }
        }

        if (filter.columnId === SafetyPageColumnIds.gasName) {
          if (filter?.value === '') {
            searchParams.delete(SafetyPageParamLabels.gasName)
          } else {
            searchParams.set(SafetyPageParamLabels.gasName, filter?.value as string)
          }
        }

        if (filter.columnId === SafetyPageColumnIds.alarmDate) {
          if (filter?.value === '') {
            searchParams.delete(SafetyPageParamLabels.alarmDateFrom)
            searchParams.delete(SafetyPageParamLabels.alarmDateTo)
            searchParams.delete(SafetyPageParamLabels.alarmDateSelector)
          } else {
            const lastSyncdateRangeToBeFiltered = (filter?.value as IselectOptionProps)
              .value as DateRange
            const alarmDateSelectorTobeFiltered = (filter?.value as IselectOptionProps)
              .label as string
            searchParams.set(SafetyPageParamLabels.alarmDateSelector, alarmDateSelectorTobeFiltered)
            searchParams.set(
              SafetyPageParamLabels.alarmDateFrom,
              new Date(lastSyncdateRangeToBeFiltered.startDate).toISOString().split('T')[0],
            )
            searchParams.set(
              SafetyPageParamLabels.alarmDateTo,
              new Date(lastSyncdateRangeToBeFiltered.endDate).toISOString().split('T')[0],
            )
          }
        }
      })

      setSearchParams(searchParams)
    },
    [searchParams, setSearchParams],
  )

  const filterTableValues = useMemo(
    () => [
      {
        columnId: SafetyPageColumnIds.serialNumber,
        value: deviceSerialParam ?? '',
      },
      {
        columnId: SafetyPageColumnIds.assetNumber,
        value: assetNumberParam ?? '',
      },
      {
        columnId: SafetyPageColumnIds.businessUnit,
        value: childBUParam ?? '',
      },
      {
        columnId: SafetyPageColumnIds.deviceUser,
        value: deviceUserParam ?? '',
      },
      {
        columnId: SafetyPageColumnIds.alarmType,
        value: alarmTypeParam ?? '',
      },
      {
        columnId: SafetyPageColumnIds.alarmLevel,
        value: alarmLevelParam ?? '',
      },
      {
        columnId: SafetyPageColumnIds.alarmSetPoint,
        value: alarmSetPointParam ?? '',
      },
      {
        columnId: SafetyPageColumnIds.gasName,
        value: gasNameParam ?? '',
      },
      {
        columnId: SafetyPageColumnIds.alarmDate,
        value:
          alarmDateSelectorParam === DateRangeSelectorTypes.All
            ? ''
            : {
                startDate: dayDateToDate(returnStaticDate(filterAlarmsDateTimeFrom)),
                endDate: dayDateToDate(returnStaticDate(filterAlarmsDateTimeTo)),
              },
      },
    ],
    [
      deviceSerialParam,
      assetNumberParam,
      childBUParam,
      alarmDateSelectorParam,
      deviceUserParam,
      alarmTypeParam,
      alarmLevelParam,
      alarmSetPointParam,
      gasNameParam,
      filterAlarmsDateTimeFrom,
      filterAlarmsDateTimeTo,
    ],
  )

  const convertISelectOptionPropsToServiceFaultsFormType = useMemo(() => {
    const serialNumberFiltered =
      filterTableValues.find((filter) => filter.columnId === SafetyPageColumnIds.serialNumber)
        ?.value ?? ''

    const assetNumberFiltered = filterTableValues.find(
      (filter) => filter.columnId === SafetyPageColumnIds.assetNumber,
    )?.value as string

    const businessUnitFiltered =
      filterTableValues.find((filter) => filter.columnId === SafetyPageColumnIds.businessUnit)
        ?.value ?? ''

    const deviceUserFiltered = filterTableValues.find(
      (filter) => filter.columnId === SafetyPageColumnIds.deviceUser,
    )?.value as string

    const alarmDateRangeFiltered = filterTableValues.find(
      (filter) => filter.columnId === SafetyPageColumnIds.alarmDate,
    )?.value as DateRange

    const gasNameFiltered = filterTableValues.find(
      (filter) => filter.columnId === SafetyPageColumnIds.gasName,
    )?.value as string

    const alarmTypeFiltered = filterTableValues.find(
      (filter) => filter.columnId === SafetyPageColumnIds.alarmType,
    )?.value as string

    const alarmLevelFiltered = filterTableValues.find(
      (filter) => filter.columnId === SafetyPageColumnIds.alarmLevel,
    )?.value as string

    const alarmSetPointFiltered = filterTableValues.find(
      (filter) => filter.columnId === SafetyPageColumnIds.alarmSetPoint,
    )?.value as string

    const r: SafetyPageFilterFormType = {
      serialNumber: {
        value: serialNumberFiltered as string,
        label: serialNumberFiltered as string,
      },
      businessUnit:
        businessUnitFiltered === ''
          ? selectAllOption
          : {
              value: businessUnitFiltered as string,
              label: businessUnitFiltered as string,
            },
      deviceUser: {
        value: deviceUserFiltered as string,
        label: deviceUserFiltered as string,
      },

      alarmDate: alarmDateRangeFiltered
        ? {
            startDate: new Date(alarmDateRangeFiltered.startDate), // new Date(faultDateTimeFromParam as string),
            endDate: new Date(alarmDateRangeFiltered.endDate), // new Date(faultDateTimeToParam as string),
          }
        : {
            startDate: new Date(),
            endDate: new Date(),
          },

      alarmDateSelector:
        alarmDateSelectorParam !== ''
          ? dateRangeOptions.find((option) => option.label === alarmDateSelectorParam) ?? {
              label: '',
              value: '',
            }
          : dateRangeOptions.find((option) => option.label === DateRangeSelectorTypes.Custom) ?? {
              label: '',
              value: '',
            },

      gasName: {
        value: gasNameFiltered as string,
        label: gasNameFiltered as string,
      },
      alarmType: {
        value: alarmTypeFiltered as string,
        label: alarmTypeFiltered as string,
      },

      // alarmLevel: alarmLevelFiltered ?? '',

      alarmLevel: {
        value: alarmLevelFiltered as string,
        label: alarmLevelFiltered as string,
      },
      // alarmSetPoint: alarmSetPointFiltered ?? '',
      alarmSetPoint: {
        value: alarmSetPointFiltered as string,
        label: alarmSetPointFiltered as string,
      },
      // assetNumber: assetNumberFiltered ?? '',
      assetNumber: {
        value: assetNumberFiltered as string,
        label: assetNumberFiltered as string,
      },

      // gasName: gasNameFiltered ?? '',
      // alarmType: alarmTypeFiltered ?? '',
      // alarmLevel: alarmLevelFiltered ?? '',
      // alarmSetPoint: alarmSetPointFiltered ?? '',
      // assetNumber: assetNumberFiltered ?? '',
    }

    return r
  }, [filterTableValues, dateRangeOptions])

  useEffect(() => {
    if (filteredSafetyData && filteredSafetyData.length > 0) {
      const serialNumbersInData: IselectOptionProps[] = filteredSafetyData?.map((safetyData) => ({
        value: safetyData.SerialNumber,
        label: safetyData.SerialNumber,
      }))
      const uniqueSerialNumbers = new Set(serialNumbersInData.map((item) => item.value))
      const serialNumbersInDataUnique = Array.from(uniqueSerialNumbers).map((item) => ({
        value: item?.toString(),
        label: item?.toString(),
      }))
      setSerialNumberOptions(serialNumbersInDataUnique as IselectOptionProps[])

      const assetNumbersInData: IselectOptionProps[] = filteredSafetyData?.map((safetyData) => ({
        value: safetyData.AssetNumber,
        label: safetyData.AssetNumber,
      }))
      const uniqueAssetNumbers = new Set(assetNumbersInData.map((item) => item.value))

      const assetNumbersInDataUnique = Array.from(uniqueAssetNumbers)
        .map((item) => ({
          value: item?.toString(),
          label: item?.toString(),
        }))
        .filter((item) => item.value !== '' && item.value !== null)
      setAssetNumberOptions(assetNumbersInDataUnique as IselectOptionProps[])

      const gasNamesInData: IselectOptionProps[] = filteredSafetyData?.map((safetyData) => ({
        value: safetyData.Gas,
        label: safetyData.Gas,
      }))
      const uniqueGasNames = new Set(gasNamesInData.map((item) => item.value))
      const gasNamesInDataUnique = Array.from(uniqueGasNames).map((item) => ({
        value: item?.toString(),
        label: item?.toString(),
      }))
      setGasNameOptions(gasNamesInDataUnique as IselectOptionProps[])

      // const alarmTypesInData: IselectOptionProps[] = filteredSafetyData?.map((safetyData) => ({
      //   value: safetyData.alarm,
      //   label: AlarmTypeValues.find((alarmType) => alarmType.label === safetyData.alarm)?.value ?? '',
      // }))
      // const uniqueAlarmTypes = new Set(alarmTypesInData.map((item) => item.label));
      // const alarmTypesInDataUnique = Array.from(uniqueAlarmTypes).map((item) => ({
      //   value: item?.toString(),
      //   label: item?.toString(),
      // }));

      // setAlarmTypeOptions(alarmTypesInDataUnique as IselectOptionProps[]);

      setAlarmTypeOptions(AlarmTypeValues as IselectOptionProps[])

      const alarmLevelsInData: IselectOptionProps[] = filteredSafetyData?.map((safetyData) => ({
        value: safetyData.Level?.toString(),
        label: safetyData.Level?.toString(),
      }))
      const uniqueAlarmLevels = new Set(alarmLevelsInData.map((item) => item.value))
      const alarmLevelsInDataUnique = Array.from(uniqueAlarmLevels).map((item) => ({
        value: item?.toString(),
        label: item?.toString(),
      }))
      alarmLevelsInDataUnique.sort((a, b) =>
        a?.value && b?.value ? (a.value > b.value ? 1 : -1) : 0,
      )
      setAlarmLevelOptions(alarmLevelsInDataUnique as IselectOptionProps[])

      const alarmSetPointsInData: IselectOptionProps[] = filteredSafetyData?.map((safetyData) => ({
        value: safetyData.Alarm1SetPoint?.toString(),
        label: safetyData.Alarm1SetPoint?.toString(),
      }))
      const uniqueAlarmSetPoints = new Set(alarmSetPointsInData.map((item) => item.value))
      const alarmSetPointsInDataUnique = Array.from(uniqueAlarmSetPoints).map((item) => ({
        value: item?.toString(),
        label: item?.toString(),
      }))
      setAlarmSetPointOptions(alarmSetPointsInDataUnique as IselectOptionProps[])

      const businessUnitsInData: IselectOptionProps[] = filteredSafetyData?.map((safetyData) => ({
        value: safetyData.businessUnitName,
        label: safetyData.businessUnitName,
      }))
      const uniqueBusinessUnits = new Set(businessUnitsInData.map((item) => item.value))
      const businessUnitsInDataUnique: IselectOptionProps[] = Array.from(uniqueBusinessUnits).map(
        (item) => ({
          value: item as string,
          label: item as string,
        }),
      )
      businessUnitsInDataUnique.unshift(selectAllOption)
      setBusinessUnitOptions(businessUnitsInDataUnique as IselectOptionProps[])

      const deviceUsersInData: IselectOptionProps[] = filteredSafetyData?.map((safetyData) => ({
        value: safetyData.AssignedTo,
        label: safetyData.AssignedTo,
      }))
      const uniqueDeviceUsers = new Set(deviceUsersInData.map((item) => item.value))
      const deviceUsersInDataUnique = Array.from(uniqueDeviceUsers).map((item) => ({
        value: item,
        label: item,
      }))
      setDeviceUserOptions(deviceUsersInDataUnique as IselectOptionProps[])
    }
  }, [filteredSafetyData])
  useEffect(() => {
    function hideFilterFormHandler(e: KeyboardEvent) {
      if (e.key === 'Escape' && showFilterForm) {
        setShowFilterForm(false)
      }
    }
    window.addEventListener('keyup', hideFilterFormHandler)

    return () => {
      window.removeEventListener('keyup', hideFilterFormHandler)
    }
  }, [showFilterForm])

  return (
    <>
      <BusinessAndCustomerSelection pageTitle='Safety' />
      <div
        id='safety-main'
        className='flex flex-col h-[calc(100vh-80px)] w-full'
        aria-hidden='true'
        onClick={() => showFilterForm && setShowFilterForm(false)}
      >
        <div
          id='safety-page-widgets'
          className={clsx(
            'bg-c-light-blue-2 px-3 md:px-5 py-3 md:py-4 grid grid-cols-1 lg:grid-cols-2 gap-x-4 gap-y-2 ',
          )}
        >
          <div className='grid  grid-cols-1 md:max-lg:grid-cols-2 gap-2'>
            <div className='row-start-1'>
              <SafetyAlarmsType
                data={unFilteredSafetyData}
                onFilter={(filterValues: IFilterValues[]) => {
                  handleFilterFormSubmit(filterValues)
                }}
                alarmTypeFilteredParam={
                  filterTableValues.find(
                    (filter) => filter.columnId === SafetyPageColumnIds.alarmType,
                  )?.value as string
                }
              />
            </div>
            <div className='row-start-2 md:max-lg:row-start-1'>
              <SafetyAlarmsGasType
                data={unFilteredSafetyData}
                onFilter={(filterValues: IFilterValues[]) => {
                  handleFilterFormSubmit(filterValues)
                }}
                gasTypeFilteredParam={
                  filterTableValues.find(
                    (filter) => filter.columnId === SafetyPageColumnIds.gasName,
                  )?.value as string
                }
              />
            </div>
          </div>
          {/* <div className=''>
          <AlarmType
            data={unFilteredSafetyData}
            onFilter={(filterValues: IFilterValues[]) => {
              handleFilterFormSubmit(filterValues)
            }}
            alarmTypeFilteredParam='Alarm1'
            title='Alarm 1 Gas Type'
            gasTypeFilteredParam={
              filterTableValues.find((filter) => filter.columnId === SafetyPageColumnIds.gasName)
                ?.value as string
            }
          />
        </div>
        <div className=''>
          <AlarmType
            title='Alarm 2 Gas Type'
            data={unFilteredSafetyData}
            onFilter={(filterValues: IFilterValues[]) => {
              handleFilterFormSubmit(filterValues)
            }}
            alarmTypeFilteredParam='Alarm2'
            gasTypeFilteredParam={
              filterTableValues.find((filter) => filter.columnId === SafetyPageColumnIds.gasName)
                ?.value as string
            }
          />
        </div> */}
          <div className=''>
            {/* <AlarmType
            title='Near Miss Gas Type'
            data={unFilteredSafetyData}
            onFilter={(filterValues: IFilterValues[]) => {
              handleFilterFormSubmit(filterValues)
            }}
            alarmTypeFilteredParam='Near Miss'
            gasTypeFilteredParam={
              filterTableValues.find((filter) => filter.columnId === SafetyPageColumnIds.gasName)
                ?.value as string
            }
          /> */}

            <AlarmTypeCalender
              data={unFilteredSafetyData}
              onFilter={(filterValues: IFilterValues[]) => {
                handleFilterFormSubmit(filterValues)
              }}
              onReset={() => resetFilterFormParams()}
            />
          </div>
        </div>

        <SafetyPageTable
          filterSummary={SafetyFilterFormat(
            filterTableValues,
            customerList && selectedCustomerParam !== ''
              ? customerList?.find((c) => c.id === selectedCustomerParam)?.name || ''
              : customerList?.[0]?.name || '',
            businessUnitsList && selectedMainBUParam !== ''
              ? businessUnitsList?.find((c) => c.id === selectedMainBUParam)?.name || ''
              : businessUnitsList?.[0]?.name || '',
          )}
          tableData={unFilteredSafetyData ?? []}
          isLoading={isSafetyListDataLoading || isDeviceListLoading}
          updateFilteredData={(filteredData: iSafetyData[]) => setfilteredSafetyData(filteredData)}
          // updateFilteredData={(filteredData: iDevice[]) =>
          //   setfilteredCalibrationPageData(filteredData)
          // }
          filterValues={filterTableValues}
          onFilterFormSubmit={(filterVals: IFilterValues[]) => handleFilterFormSubmit(filterVals)}
          onFilterFormReset={() => resetFilterFormParams()}
          onShowFilter={() => setShowFilterForm(true)}
          pageSize={Number(pageSizeParam ?? 10)}
          pageIndex={pageIndexParam ? Number(pageIndexParam) : 0}
          setPageSize={(size: number) => {
            searchParams.set(SafetyPageParamLabels.pageSize, size.toString())
            setSearchParams(searchParams)
          }}
          setPageIndex={(index: number) => {
            searchParams.set(SafetyPageParamLabels.pageNumber, index.toString())
            setSearchParams(searchParams)
          }}
          totalRecords={totalRecords}
        />
      </div>
      <div
        id='safety-page-filter-form'
        className={clsx(
          ' filter-form hideScrollBar',
          showFilterForm ? 'z-50 -ml-80 opacity-100' : 'opacity-0 -z-50',
        )}
      >
        <SafetyPageFilterForm
          selectedFilterValues={convertISelectOptionPropsToServiceFaultsFormType}
          serialNumberOptions={serialNumberOptions}
          assetNumberOptions={assetNumberOptions}
          gasNameOptions={gasNameOptions}
          alarmTypeOptions={alarmTypeOptions}
          alarmLevelOptions={alarmLevelOptions}
          alarmSetPointOptions={alarmSetPointOptions}
          businessUnitOptions={businessUnitOptions}
          deviceUserOptions={deviceUserOptions}
          onFilterSubmit={(formData: IFilterValues[]) => handleFilterFormSubmit(formData)}
          onFilterReset={() => resetFilterFormParams()}
          onFilterFormClose={() => setShowFilterForm(false)}
        />
      </div>
    </>
  )
}

export default withErrorBoundary(withPageTracking(SafetyPage), {
  FallbackComponent: ErrorPage,
})
