import React from 'react'
import { ExportColumnType } from 'exportReports/DataExportInterfaces'
import { FormatBusinessUnit, FormatToShortDate,FormatUTXToDateTime } from 'exportReports/ExportDataUtils'

const NearMissReportColumns: ExportColumnType[] = [
  {
    key: 'channelName',
    label: 'Gas',
    format: (val: any) => val,
    cellWidth: 40,
  },
  {
    key: 'nearMissPercentage',
    label: '% alarm level',
    format: (val: string) => val,
    cellWidth: 80,
  },
  {
    key: 'alarmLevelValue',
    label: 'Alarm level',
    format: (val: string) => val,
    cellWidth: 80,
  },
  {
    key: 'nearMissDuration',
    label: 'Duration',
    format: (val: string) =>(val),
    cellWidth: 80,
  },
  {
    key: 'nearMissStartTime',
    label: 'Date and time',
    format: (val: any) =>FormatUTXToDateTime(val),
    cellWidth: 100,
  },
  {
    key: 'deviceUserName',
    label: 'Assigned to',
    format: (val: string) => val,
    cellWidth: 100,
  },
]

export { NearMissReportColumns }
