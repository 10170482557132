import React, {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react'
import Each from 'components/atom/Each'
import CalenderDate from './CalenderDate'
import CalenderHeader from './CalenderHeader'
import CalenderWeek from './CalenderWeek'
import { CalenderDateType, CalenderDayType, CalenderRefType, CalenderType } from './CalenderType'
import {
  classNames,
  dayDateToDate,
  debounce,
  manageCalenderRangeDate,
  returnDaysInMonth,
  selectedDateIsInRange,
} from './CalenderUtils'

const Calender = forwardRef<CalenderRefType, CalenderType>(
  (
    {
      events,
      render,
      dayContainerClassName = '',
      title = <div />,
      onChange = () => {},
      onDateRangeChange = () => {},
      date,
      shouldShowFilter = false,
      onFilter = () => {},
      onReset = () => {},
      selectedDateRange = undefined,
      defaultFilterApplied = false,
    },
    ref,
  ) => {
    const initialDays = useMemo(
      () =>
        returnDaysInMonth(
          date?.getMonth() ?? new Date().getMonth(),
          date?.getFullYear() ?? new Date().getFullYear(),
        ),
      [date],
    )

    const [days, setDays] = useState(initialDays)
    const [hasFilter, setHasFilter] = useState(defaultFilterApplied)
    const [isMouseDown, setIsMouseDown] = useState(false)
    const [dateFilter, setDateFilter] = useState<CalenderDateType[]>([])

    const selectedMonth = useMemo(
      () => days.find((day: CalenderDayType) => day.isCurrentMonth),
      [days],
    )
    const selectedDate = useMemo(
      () => dayDateToDate(selectedMonth?.date as string),
      [selectedMonth],
    )

    useMemo(() => {
      setDays(
        returnDaysInMonth(
          date?.getMonth() ?? new Date().getMonth(),
          date?.getFullYear() ?? new Date().getFullYear(),
        ),
      )
    }, [date])

    useMemo(() => {
      setHasFilter(defaultFilterApplied)
    }, [defaultFilterApplied])

    useMemo(() => {
      if (selectedDateRange) setDateFilter(selectedDateRange)
    }, [selectedDateRange])

    const setEventDate = useCallback(
      (month: number, year: number) => {
        onChange(new Date(year, month, 1))
        setDays(returnDaysInMonth(month, year))
      },
      [onChange],
    )

    const applyFilter = useCallback(() => {
      setHasFilter(true)
      onFilter(dateFilter.map((d) => dayDateToDate(d as string)))
    }, [dateFilter, onFilter])

    const clearFilter = useCallback(() => {
      setDateFilter([])
      setHasFilter(false)
      onReset()
    }, [onReset])

    const goToCurrentMonth = useCallback(() => {
      setEventDate(new Date().getMonth(), new Date().getFullYear())
    }, [setEventDate])

    const goToPreviousMonth = useCallback(() => {
      setEventDate(selectedDate.getMonth() - 1, selectedDate.getFullYear())
    }, [selectedDate, setEventDate])

    const goToNextMonth = useCallback(() => {
      setEventDate(selectedDate.getMonth() + 1, selectedDate.getFullYear())
    }, [selectedDate, setEventDate])

    const isCurrentMonth = useMemo(
      () =>
        selectedDate.getMonth() === new Date().getMonth() &&
        selectedDate.getFullYear() === new Date().getFullYear(),
      [selectedDate],
    )

    const onDateSelect = useCallback((day: CalenderDayType) => {
      setHasFilter(false)
      setDateFilter((prev) => manageCalenderRangeDate(prev, day))
    }, [])

    useMemo(() => {
      if (dateFilter.length > 0) {
        onDateRangeChange?.(dateFilter)
      }
    }, [onDateRangeChange, dateFilter])

    useImperativeHandle(
      ref,
      () => ({
        clearFilter,
        days,
        hasFilter,
        dateFilter,
      }),
      [clearFilter, days, hasFilter, dateFilter],
    )

    useEffect(() => {
      const handleMouseUp = () => setIsMouseDown(false)
      window.addEventListener('mouseup', handleMouseUp)
      return () => window.removeEventListener('mouseup', handleMouseUp)
    }, [])

    const debouncedOnDateSelect = useMemo(() => debounce(onDateSelect, 10), [onDateSelect])
    return (
      <div className='flex h-full flex-col relative'>
        <CalenderHeader
          isCurrentMonth={isCurrentMonth}
          goToCurrentMonth={goToCurrentMonth}
          goToPreviousMonth={goToPreviousMonth}
          goToNextMonth={goToNextMonth}
          selectedDate={selectedDate}
          selectedDay={selectedMonth as CalenderDayType}
          dateFilter={dateFilter}
          shouldShowFilter={shouldShowFilter}
          hasFilter={hasFilter}
          applyFilter={applyFilter}
          clearFilter={clearFilter}
          title={title}
        />

        <div className='flex flex-auto flex-col rounded-b-2xl'>
          <CalenderWeek />
          <div className='flex bg-gray-200 text-xs leading-6 text-gray-700 flex-auto rounded-b-2xl'>
            <div className='w-full h-full grid grid-cols-7 grid-rows-6 relative'>
              <Each
                mapper={days}
                render={(day: CalenderDayType, i: number) => {
                  const event = events?.[day.date]
                  const isDateOrDateRangeSelected =
                    dateFilter.includes(day.date) ||
                    (dateFilter.length > 0 &&
                      selectedDateIsInRange(
                        dateFilter[0],
                        dateFilter[dateFilter.length - 1],
                        day.date,
                      ))

                  return (
                    <div
                      tabIndex={0}
                      role='button'
                      key={day.date}
                      onMouseDown={() => shouldShowFilter && setIsMouseDown(true)}
                      onMouseOver={() =>
                        shouldShowFilter && isMouseDown && debouncedOnDateSelect(day)
                      }
                      className='p-[0.5px]'
                      onFocus={() => shouldShowFilter && isMouseDown && debouncedOnDateSelect(day)}
                      onMouseUp={() => shouldShowFilter && setIsMouseDown(false)}
                    >
                      <CalenderDate
                        day={day}
                        onDateSelect={onDateSelect}
                        shouldShowFilter={shouldShowFilter}
                        className={classNames(
                          isDateOrDateRangeSelected
                            ? 'bg-c-light-blue-4'
                            : day.isCurrentMonth
                            ? 'bg-white hover:bg-gray-100'
                            : 'bg-gray-100 text-gray-500 hover:bg-gray-100',
                          i === 35 ? 'rounded-bl-2xl' : '',
                          i === 41 ? 'rounded-br-2xl' : '',
                          isMouseDown ? 'cursor-grabbing' : 'cursor-pointer',
                          'relative px-3 h-full py-2 w-full group/day select-none',
                          dayContainerClassName,
                        )}
                      >
                        {render({ day, events, event })}
                      </CalenderDate>
                    </div>
                  )
                }}
              />
            </div>
          </div>
        </div>
      </div>
    )
  },
)

Calender.displayName = 'Calender'

export default Calender
