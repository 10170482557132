import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import Calender from 'components/modules/Calender/Calender'
import clsx from 'clsx'
import {
  CalenderChildrenType,
  CalenderDateType,
  CalenderRefType,
} from 'components/modules/Calender/CalenderType'
import { Transition } from '@headlessui/react'
import Tooltip from 'components/atom/Tooltip'
import { IFilterValues } from 'forms/FormModelInterface'
import { AlarmTypes, DateRangeSelectorTypes } from 'utils/CommonEnums'
import { useSearchParams } from 'react-router-dom'
import { AlarmsConstants } from 'utils/Constants'
import { returnStaticDate } from 'components/modules/Calender/CalenderUtils'
import { SafetyPageColumnIds } from '../view/SafetyPageUtils'
import { iSafetyData } from '../data/iSafetyList'

type AlarmTypeCalenderProps = {
  data: iSafetyData[]
  onFilter?: (filter: IFilterValues[]) => void
  onReset?: () => void
}
const alarms: {
  [key: string]: string
} = {
  Alarm1: 'c-red-1',
  Alarm2: 'c-blue-1',
  'Near Miss': 'c-blue',
}

const AlarmTypeWidget = memo(({ event }: CalenderChildrenType) => {
  const alarmsEvent = (event as iSafetyData[])?.reduce?.(
    (acc: { [key: string]: iSafetyData[] }, curr: iSafetyData) => {
      if (curr.alarm) {
        return {
          ...acc,
          [`${curr.alarm}`]: [...(acc[`${curr.alarm}`] ?? []), curr],
        }
      }
      return acc
    },
    {},
  )
  const [show, setShow] = useState(false)

  let timer: NodeJS.Timeout

  useEffect(() => {
    timer = setTimeout(() => {
      setShow(true)
    }, 100)

    return () => timer && clearTimeout(timer)
  }, [])

  if (event) {
    return (
      <Transition
        show={show}
        enter='transition-opacity ease-in duration-150 delay-50'
        enterFrom='opacity-0'
        enterTo='opacity-100'
        leave='transition-opacity ease-in duration-200 delay-50'
        leaveFrom='opacity-100'
        leaveTo='opacity-0'
        className={clsx(
          {
            'grid-cols-2 grid-rows-2': Object.values(alarmsEvent).length > 2,
            'grid-cols-1 grid-rows-2': Object.values(alarmsEvent).length > 1,
          },
          'gap-0 absolute top-1/2 -translate-y-1/2 right-2 z-10 grid  ',
        )}
      >
        {Object.entries(alarmsEvent).map(([key, value]) => (
          <Tooltip
            tooltipText={key}
            toolTipClass='tooltip text-4xs w-max px-2 m-auto -top-7 left-1/2 -translate-x-1/2  text-center'
            className={clsx(' rounded-full  select-none relative w-full p-0.5  ')}
            key={key}
          >
            <div
              className={`text-${alarms[key]} text-4xs font-bold leading-3 text-ellipsis self-end`}
            >
              {value.length > 99 ? `99+` : `${value.length}`.padStart(2, '0')}
            </div>
          </Tooltip>
        ))}
      </Transition>
    )
  }
  return null
})

AlarmTypeWidget.displayName = 'AlarmTypeWidget'

function AlarmTypeCalender({ data, onFilter, onReset }: AlarmTypeCalenderProps) {
  const ref = useRef<CalenderRefType>(null)
  const [searchParams] = useSearchParams()
  const widgetData = useMemo(() => {
    const acc: { [key: string]: iSafetyData[] } = {}
    data.forEach((cur) => {
      if (cur.TimeStamp) {
        const date = new Date(cur.TimeStamp * 1000)
        const key = returnStaticDate(date)
        if (!acc[key]) {
          acc[key] = []
        }
        acc[key].push(cur)
      }
    })
    return acc
  }, [data])

  const onFilterCalender = useCallback(
    (selectedDateArray: CalenderDateType[]) => {
      const filterValues: IFilterValues[] = [
        {
          columnId: SafetyPageColumnIds.alarmDate,
          value:
            data.length > 0
              ? {
                  label: DateRangeSelectorTypes.Custom,
                  value:
                    data.length > 0
                      ? {
                          startDate: returnStaticDate(selectedDateArray[0] as Date, true),
                          endDate: returnStaticDate(
                            selectedDateArray[selectedDateArray.length - 1] as Date,
                            true,
                          ),
                        }
                      : '',
                }
              : '',
        },
      ]
      onFilter?.(filterValues)
    },
    [onFilter],
  )

  useEffect(() => {
    if (searchParams.toString().length === 0) {
      ref.current?.clearFilter?.()
    }
  }, [searchParams])
  const dataList = Array.from(new Set(data.reduce((acc: string[], cur) => [...acc, cur.alarm], [])))

  return (
    <Calender
      ref={ref}
      shouldShowFilter
      onFilter={onFilterCalender}
      onReset={onReset}
      title={
        <div className='flex flex-row gap-2 mt-3'>
          {dataList.map((key) => (
            <p
              key={key}
              className={clsx(
                'text-2xs font-bold leading-3  text-ellipsis self-center text-c-white px-3 py-1 select-none rounded-full',
                `bg-${alarms[key]}`,
              )}
            >
              {AlarmsConstants[key as AlarmTypes]}
            </p>
          ))}
        </div>
      }
      events={widgetData}
      render={(props) => <AlarmTypeWidget {...props} />}
    />
  )
}
AlarmTypeCalender.displayName = 'AlarmTypeCalender'
AlarmTypeCalender.defaultProps = {
  onFilter: () => {},
  onReset: () => {},
}
export default memo(AlarmTypeCalender)
