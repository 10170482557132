import React, { Fragment } from 'react'
import { useForm, Controller, SubmitHandler } from 'react-hook-form'
import Modal from 'react-modal'
import { Dialog, Transition } from '@headlessui/react'
import { modalFormStyles } from 'components/formComponents/ModalStyles'
import { selectBlankOption } from 'forms/FormUtils'
import AddDeviceForm from './createDeviceSteps/CreateDeviceForm'
import AddDevice from './createDeviceSteps/ConfirmAddDevice'
import DeviceAdded from './createDeviceSteps/DeviceAdded'
import { CreateDeviceFormType } from './CreateDeviceFormType'
// import { deviceInitialValue } from './FormInitialValue'

interface CreateDeviceProps {
  isOpened: boolean
  handleClose: () => void
}

function CreateDevice(props: CreateDeviceProps): JSX.Element {
  const { handleClose, isOpened } = props
  const [formStep, setFormStep] = React.useState<number>(0)
  const [deviceData, setDeviceData] = React.useState<CreateDeviceFormType | undefined>()
  const nextFormStep = () => setFormStep((currentStep) => currentStep + 1)
  const prevFormStep = () => setFormStep((currentStep) => currentStep - 1)
  const handleCancel = () => {
    // setDeviceData()
    handleClose()
  }

  return (
    // <Transition
    //   show={isOpened}
    //   enter='transition duration-100 ease-out'
    //   enterFrom='transform scale-95 opacity-0'
    //   enterTo='transform scale-100 opacity-100'
    //   leave='transition duration-75 ease-out'
    //   leaveFrom='transform scale-100 opacity-100'
    //   leaveTo='transform scale-95 opacity-0'
    //   as={Fragment}
    // > onClose={handleCancel} 
      <Dialog open={isOpened} onClose={()=>{}} className='relative z-50 rounded-2xl'>
        <div className='fixed inset-0 bg-black/30' aria-hidden='true' />
        <div className='fixed inset-0 w-screen overflow-y-auto'>
          <div className='flex min-h-full items-center justify-center p-4'>
            <Dialog.Panel className='mx-auto w-96 rounded-2xl bg-white'>
              <div className='w-full '>
                {formStep === 0 && (
                  <AddDeviceForm
                  onClose={handleCancel}
                    // formValues={deviceData}
                    // nextFormStep={() => nextFormStep()}
                    // confirmCancel={() => handleCancel()}
                    // setDeviceData={(device: CreateDeviceFormType) => setDeviceData(device)}
                  />
                )}
                {formStep === 1 && deviceData && (
                  <AddDevice
                    deviceInfo={deviceData}
                    nextFormStep={() => nextFormStep()}
                    previousFormStep={() => prevFormStep()}
                    confirmCancel={() => {
                      setFormStep(0)
                      handleCancel()
                    }}
                  />
                )}
                {formStep > 1 && deviceData && (
                  <DeviceAdded
                    deviceInfo={deviceData}
                    confirmCompleted={() => {
                      setFormStep(0)
                      handleCancel()
                    }}
                  />
                )}
              </div>
            </Dialog.Panel>
          </div>
        </div>
      </Dialog>
    // </Transition>
  )
}

export default CreateDevice
