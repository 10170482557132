import React from 'react'
import { SvgProps } from 'types/SVGProps'

export default function DragIcon(props: SvgProps): JSX.Element {
  const { className, height, width } = props
  return (
    <div>
      <svg
        width={width}
        height={height}
        viewBox='0 0 9 14'
        xmlns='http://www.w3.org/2000/svg'
        className={className}
      >
        <circle cx='2' cy='2' r='2' />
        <circle cx='2' cy='7' r='2' />
        <circle cx='2' cy='12' r='2' />
        <circle cx='7' cy='2' r='2' />
        <circle cx='7' cy='7' r='2' />
        <circle cx='7' cy='12' r='2' />
      </svg>
    </div>
  )
}
