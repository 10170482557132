import { IselectOptionProps } from 'forms/FormModelInterface'
import React, { useContext, useState } from 'react'
import { UserAccountContext } from 'contexts/UserAccountContext'
import { UserAccountContextActions } from 'utils/Constants'
import { useQueryClient } from '@tanstack/react-query'
import clsx from 'clsx'
import { UserRoleTypes } from 'utils/CommonEnums'
import { FeatureFlags } from 'utils/FeatureFlags'
import { useFeatureFlag, useUserHasRequiredRoles } from 'hooks'
import DemoModeExit from './components/modals/DemoModeExit'
import DemoModeInitiate from './components/modals/DemoModeInitiate'
import DemoDropDownSelector from './components/dropdown'
import { DataForUserType } from './interfaces'

function DemoModeProvider({ children }: { children: React.ReactNode }) {
  const { dispatch, state } = useContext(UserAccountContext)
  const [exitDemoMode, setExitDemoMode] = useState(false)
  const [enterDemoMode, setEnterDemoMode] = useState(false)
  const queryClient = useQueryClient()
  const isDemoModeFeatureEnabled = useFeatureFlag(FeatureFlags.DemoMode)
  const isHasAccess = useUserHasRequiredRoles([UserRoleTypes.SuperUser, UserRoleTypes.Admin])
  const isDemoModeAvailable = isDemoModeFeatureEnabled && isHasAccess
  const demoModeHandler = (status: boolean, dataForUser?: DataForUserType) => {
    queryClient.clear()
    dispatch({
      type: UserAccountContextActions.DemoModeStatus,
      payload: { isDemoMode: status, ...(dataForUser || {}) },
    })
  }

  const demoModeSwitcher = (selectedOption: IselectOptionProps) => {
    const isDemoMode = selectedOption.value as unknown as boolean
    if (state.isDemoMode !== isDemoMode) {
      if (!isDemoMode) {
        setExitDemoMode(true)
      } else {
        setEnterDemoMode(true)
      }
    }
  }

  return (
    <div
      className={clsx(
        {
          'md:grid-cols-3': isDemoModeAvailable,
          'md:grid-cols-2': !isDemoModeAvailable,
        },
        'font-poppins col-span-full grid grid-cols-1  gap-4',
      )}
    >
      {children}
      {isDemoModeAvailable && (
        <>
          <DemoDropDownSelector onChange={demoModeSwitcher} />
          <DemoModeExit
            open={exitDemoMode}
            setOpen={(e: boolean) => setExitDemoMode(e)}
            exitHandler={() => demoModeHandler(false)}
          />
          <DemoModeInitiate isOpen={enterDemoMode} close={demoModeHandler} />
        </>
      )}
    </div>
  )
}
export default DemoModeProvider
