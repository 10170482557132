import React from 'react'
import CrowconButton from 'components/miscComponents/CrowconButton'
import { CreateDeviceFormType } from '../CreateDeviceFormType'
import { DeviceSummary } from './DeviceSummary'

export interface DeviceSubmitionCompletedProps {
  deviceInfo: CreateDeviceFormType
  confirmCompleted: () => void
}
function DeviceAdded(props: DeviceSubmitionCompletedProps) {
  const { confirmCompleted, deviceInfo } = props
  return (
    <div className='pt-8'>
      <div className='pl-5 font-poppins text-base font-bold'>Device added successfully</div>
      <div className='px-5 pb-7'>
        <DeviceSummary deviceInfo={deviceInfo} />
      </div>
      <div className='w-full px-5 pt-10 pb-11'>
        <CrowconButton
          additionalClassName='text-base bg-c-dark-blue-1 w-316 h-12 rounded-3xl'
          buttonChildren={<>Done</>}
          buttonAction={() => confirmCompleted()}
        />
      </div>
    </div>
  )
}

export default DeviceAdded
