import { DateRange, IFilterQueryParamValues, IselectOptionProps } from 'forms/FormModelInterface'

export const SafetyPageColumnIds = {
  businessUnit: 'businessUnitName',
  serialNumber: 'SerialNumber',
  assetNumber: 'AssetNumber',

  deviceUser: 'AssignedTo',

  gasName: 'Gas',

  alarmDateSelector: 'alarmDateSelector',
  alarmDate: 'TimeStamp',
  alarmType: 'alarm',
  alarmLevel: 'Level',
  alarmSetPoint: 'Alarm1SetPoint',
}

export const SafetyPageParamLabels = {
  pageNumber: 'pagenum',
  pageSize: 'pagesize',

  serialNumber: 'ser',
  assetNumber: 'asset',

  alarmDateSelector: 'alarmDate',
  alarmDateFrom: 'alarmDateFrom',
  alarmDateTo: 'alarmDateTo',

  businessUnit: 'cbu',
  deviceUser: 'user',

  gasName: 'gas',
  alarmType: 'altype',
  alarmLevel: 'alevel',
  alarmSetPoint: 'asp',
}

export const SafetyPageFilterParamValues: IFilterQueryParamValues[] = [
  { paramName: SafetyPageParamLabels.pageNumber, paramValue: 1 },
  { paramName: SafetyPageParamLabels.pageSize, paramValue: 10 },
  { paramName: SafetyPageParamLabels.serialNumber, paramValue: null },
  { paramName: SafetyPageParamLabels.businessUnit, paramValue: null },
  { paramName: SafetyPageParamLabels.assetNumber, paramValue: null },
  { paramName: SafetyPageParamLabels.alarmDateSelector, paramValue: null },
  { paramName: SafetyPageParamLabels.alarmDateFrom, paramValue: null },
  { paramName: SafetyPageParamLabels.alarmDateTo, paramValue: null },
  { paramName: SafetyPageParamLabels.gasName, paramValue: null },
  { paramName: SafetyPageParamLabels.alarmType, paramValue: null },
  { paramName: SafetyPageParamLabels.alarmLevel, paramValue: null },
  { paramName: SafetyPageParamLabels.alarmSetPoint, paramValue: null },
  { paramName: SafetyPageParamLabels.deviceUser, paramValue: null },
]

// assetNumber: 'asset',

// alarmDateSelector: 'alarmDate',
// alarmDateFrom: 'alarmDateFrom',
// alarmDateTo: 'alarmDateTo',

// businessUnit: 'cbu',
// deviceUser: 'user',

// gasName: 'gas',
// alarmType: 'altype',
// alarmLevel: 'alevel',
// alarmSetPoint: 'asp',

export type SafetyPageFilterFormType = {
  serialNumber: IselectOptionProps
  assetNumber: IselectOptionProps
  businessUnit: IselectOptionProps

  alarmDateSelector: IselectOptionProps
  alarmDate: DateRange

  deviceUser: IselectOptionProps

  gasName: IselectOptionProps
  alarmType: IselectOptionProps
  alarmLevel: IselectOptionProps
  alarmSetPoint: IselectOptionProps
}
