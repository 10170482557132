import { selectAllOption } from 'forms/FormUtils'
import { convertUrlForDemoMode } from 'utils/CommonUtils'
import { endpoints } from '../endpoints'

export const operatorListDataURL = (businessUnitId?: string, customerId?: string) => {
  let url = endpoints.operator.list
  if (
    businessUnitId &&
    businessUnitId.toLowerCase() !== selectAllOption.value?.toString().toLowerCase()
    // || (customerId === '' || customerId === allCustomerOption.id)
  ) {
    url = `${endpoints.operator.list}?ExpandBusinessUnit=true&BusinessUnitId=${businessUnitId}`
  }
  return convertUrlForDemoMode(url)
}

export const crudOperatorURL = () => convertUrlForDemoMode(endpoints.operator.common)
export const deviceAssignmentToUser = (operatorId: string) =>
  convertUrlForDemoMode(`${endpoints.operator.assignment}/${operatorId}`)
