/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import { CSVLink } from 'react-csv'
import { useIntl } from 'react-intl'
import CrowconButton from 'components/miscComponents/CrowconButton'
import { CSVIcon } from 'assets/icons'
import { FormatData } from './ExportDataUtils'
import { ExportColumnType } from './DataExportInterfaces'

interface ExportCSVProps<T> {
  data: T[]
  reportColumns: ExportColumnType[]
  fileName: string
}

function ExportCSV<T>(props: ExportCSVProps<T>) {
  const { data, reportColumns, fileName } = props
  const intl = useIntl()
  const localDate = Intl.DateTimeFormat(intl.locale).format(new Date())
  const formattedData = FormatData(data || [], reportColumns)
  return (
    <CSVLink data={formattedData} headers={reportColumns} filename={`${fileName}_${localDate}`}>
      <CrowconButton
        additionalClassName='text-base text-c-dark-blue-1  font-bold  bg-c-dark-blue-1 hover:text-white h-52  w-full rounded-4xl'
        buttonChildren={
          <span className='flex flex-row pl-16 gap-3'>
            Download CSV <CSVIcon />
          </span>
        }
        buttonAction={() => null}
      />
    </CSVLink>
  )
}
export default ExportCSV
