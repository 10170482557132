/* eslint-disable no-unused-expressions */

import { useIntl } from 'react-intl'
import { fromUnixTime } from 'date-fns'
import { iBusinessUnit, iDeviceUser } from 'data/DeviceListHook'
import { DateRange } from 'forms/FormModelInterface'
import { BumpTestStatusValues } from 'utils/Constants'
import { ExportColumnType } from './DataExportInterfaces'

export const FormatData = (data: any[], reportColumns: ExportColumnType[]) => {
  const resultsToReturn: any[] = []
  data.forEach((row) => {
    const resultRow: any = {}
    reportColumns.forEach((col) => {
      resultRow[col.key] = col.format(row[col.key])
    })
    resultsToReturn.push(resultRow)
  })
  return resultsToReturn
}

export const FormatToShortDate = (val?: Date) => {
  const intl = useIntl()
  return val ? Intl.DateTimeFormat(intl.locale).format(new Date(val)) : ''
}

export const FormatUTXToDateTime = (val: number) => {
  const intl = useIntl()
  const date = fromUnixTime(val).toLocaleDateString(intl.locale)
  const time = fromUnixTime(val).toLocaleTimeString(intl.locale)
  return `${date} ${time}`
}

export const FormatDateRange = (val: DateRange) => {
  const intl = useIntl()
  const startDate = Intl.DateTimeFormat(intl.locale).format(val.startDate as Date)
  const endDate = Intl.DateTimeFormat(intl.locale).format(val.endDate as Date)
  return `${startDate} to ${endDate}`
}

export const FormatDateRangeFromISO = (val: DateRange) => {
  const intl = useIntl()
  const startDate = Intl.DateTimeFormat(intl.locale).format(new Date(val.startDate))
  const endDate = Intl.DateTimeFormat(intl.locale).format(new Date(val.endDate))
  return `${startDate} to ${endDate}`
}

export const FormatBusinessUnit = (val: iBusinessUnit) => val?.name?.trim() || ''

export const FormatDeviceUser = (val?: iDeviceUser) => val?.fullName?.trim() || ''

export const FormatBumpResult = (val: string) => {
  const bumpResultValue = BumpTestStatusValues.find((status) => status.value === val)
  return bumpResultValue?.label.toString() || ''
}

// const SliceText = (val:string,length:number) => val?.slice(0,length) ||''

// export {
//   FormatData,
//   FormatToShortDate,
//   FormatBusinessUnit,
//   FormatDeviceUser,
//   FormatUTXToDateTime,
// }
