import { useQuery } from '@tanstack/react-query'
import ConnectAPIClient from 'services/ConnectAPIClient'
import { isAfter } from 'date-fns'
import { getAccessToken, getAccessTokenProps } from 'services/MSALService'
import { UserInviteStatusTypes } from 'utils/CommonEnums'
import { AxiosError } from 'axios'
import { parseApiErrorOrNetworkError } from 'services/APIErrorParser'
import { userInviteDataQueryKey } from 'services/apis/caching/users'
import { emailValidityURL, userInviteStatusURL } from 'services/apis/urls/users'

export interface iUserInviteData {
  id: string
  domain: string
  emailId: string
  firstName: string
  lastName: string
  invitationExpiryDate: string
  locale: string
  status: UserInviteStatusTypes
  isInvitationValid: boolean
}

export interface iUserDataToActivate {
  InvitationId: string
  UserOID: string
  FirstName: string
  LastName: string
  Status: string
  EmailId: string
}
export interface iUserDataIsActivate {
  isUserActive: boolean
  locale: string
  status: string
  isTnCAcceptanceCheckRequired: boolean
}

export interface iActivateUserProps {
  userData: iUserDataToActivate
  accessTokenProps: getAccessTokenProps
}

async function fetchUserInviteData(userInviteId: string): Promise<iUserInviteData> {
  try {
    const url = userInviteStatusURL(userInviteId)
    console.log(url)
    const resp = await ConnectAPIClient.getNoAuth<iUserInviteData>(url)
    const response = resp.data
    return response
  } catch (error) {
    console.error(error)
    throw parseApiErrorOrNetworkError(error)
  }
}

async function getUserInviteData(userInviteId: string) {
  const userInviteData = await fetchUserInviteData(userInviteId)
  return userInviteData
}

export function useUserInviteData(userInviteId: string, enabled?: boolean) {
  return useQuery<iUserInviteData, AxiosError>(
    userInviteDataQueryKey(userInviteId),
    () => getUserInviteData(userInviteId),
    {
      enabled,
      keepPreviousData: false,
      select: (data) => {
        let isInvitationValid = false
        if (data) {
          const currentDate = new Date()
          const expiryDate = new Date(data.invitationExpiryDate)
          const isInvitationDateExpired = isAfter(currentDate, expiryDate)
          const isUserInviteStatusInvited = data.status === UserInviteStatusTypes.Invited.valueOf()
          // isInvitationValid = !isInvitationDateExpired && isUserInviteStatusInvited
          isInvitationValid = true
        }
        return {
          ...data,
          isInvitationValid,
        }
      },
      onError: (error) => {
        console.log(error)
      },
    },
  )
}

export async function activateUser(activateUserProps: iActivateUserProps) {
  const { userData, accessTokenProps } = activateUserProps
  const url = userInviteStatusURL()
  try {
    const token = await getAccessToken(accessTokenProps)
    const response = await ConnectAPIClient.put<iUserDataToActivate>(url, token, '', userData)
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}

export async function userExists(emailId: string) {
  const url = emailValidityURL(emailId)
  try {
    const response = await ConnectAPIClient.get<iUserDataIsActivate>(url, '')
    return response.data
  } catch (error) {
    console.error(error)
    throw parseApiErrorOrNetworkError(error)
  }
}
