import { AddIcon } from 'assets/icons'
import React from 'react'
import CrowconButton from 'components/miscComponents/CrowconButton'

interface addProductButtonProps {
  buttonText: string
  buttonAction: () => void
}
function AddEntryInTableButton(buttonProps: addProductButtonProps) {
  const { buttonText, buttonAction } = buttonProps

  return (
    <CrowconButton
      additionalClassName='flex flex-row justify-around items-center rounded-full md:rounded-4xl w-10 md:w-auto h-10 md:h-52 '
      buttonAction={buttonAction}
      buttonChildren={
        <>
          <span className='hidden md:inline ml-5 font-bold text-base leading-5 '>{buttonText}</span>
          <span className='md:mr-4 md:ml-3'>
            <AddIcon />
          </span>
        </>
      }
    />
    // max-md:hidden
    // <button
    //   type='button'
    //   className=' flex flex-row gap-5 justify-around items-center h-52 text-c-white rounded-3xl  bg-red-100 hover:bg-c-dark-blue-2 focus:bg-c-dark-blue-1 active:bg-c-dark-blue-3'
    //   onClick={buttonAction}
    // >
    //   <span className='ml-5 font-bold text-base leading-5'>{buttonText}</span>
    //   <span className='mr-5'>
    //       <AddIcon />
    //   </span>
    // </button>
  )
}

export default AddEntryInTableButton
