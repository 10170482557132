import React from 'react'
import { SvgProps } from 'types/SVGProps'

function ChevronRightIcon(props: SvgProps): JSX.Element {
  const { stroke, fill, className, toggleClick } = props

  return (
    <div className={className}>
      <svg
        width='16'
        height='16'
        viewBox='0 0 16 16'
        fill={fill ?? 'none'}
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M6 4L10.1298 8.12982L6.00965 12.25'
          stroke={stroke ?? '#0E2444'}
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </div>
  )
}

export default ChevronRightIcon
