import React from 'react'
import { IFilterValues } from 'forms/FormModelInterface'
import GenericWidget from 'components/viewComponents/GenericWidget'
import { iSafetyData } from '../data/iSafetyList'
import { SafetyPageColumnIds } from '../view/SafetyPageUtils'

type SafetyAlarmsGasTypeProps = {
  data: iSafetyData[]
  onFilter: (value: IFilterValues[]) => void
  gasTypeFilteredParam: string
}

export default function SafetyAlarmsGasType({
  data,
  onFilter,
  gasTypeFilteredParam,
}: SafetyAlarmsGasTypeProps) {
  const widgetData = data.reduce(
    (
      acc: {
        [key: string]: number
      },
      cur,
    ) => {
      if (cur.Gas) {
        return {
          ...acc,
          [cur.Gas]: (acc?.[cur.Gas] ?? 0) + 1,
        }
      }
      return acc
    },
    {},
  )

  return (
    <GenericWidget
      widgetProps={{
        title: 'Gas Types',
        widgetData: Object.entries(widgetData).map(([key, values]) => ({
          originalData: key,
          displayText: key,
          value: values,
          isFiltered: key === gasTypeFilteredParam,
          columnId: SafetyPageColumnIds.gasName,
        })),
        widgetLabelCount: 4,
        columnId: SafetyPageColumnIds.gasName,
        gridContainerClassName: 'grid-cols-1 md:grid-cols-2 gap-1',
        onFilter: (filterRequestValues: IFilterValues[]) => onFilter?.(filterRequestValues),
      }}
    />
  )
}
