/* eslint-disable react/require-default-props */

import React from 'react'

/**
 * Props for the Timer component.
 */
interface TimerProps {
  /**
   * The countdown duration in seconds.
   */
  countdown: number
  /**
   * The callback function to be called when the countdown reaches 0.
   */
  callback: () => void
  /**
   * Determines whether the timer should start automatically.
   * @default true
   */
  shouldStartAutomatically?: boolean
}

/**
 * A countdown timer component.
 * @param {TimerProps} props - The Timer component props.
 * @returns {JSX.Element} The Timer component.
 */
function Timer({ countdown, callback, shouldStartAutomatically = true }: TimerProps) {
  const [redirectDelay, setRedirectDelay] = React.useState<number>(countdown)

  React.useEffect(() => {
    let timer: NodeJS.Timeout | undefined
    if (shouldStartAutomatically && redirectDelay > 0) {
      timer = setInterval(() => setRedirectDelay((prev) => prev - 1), 1000)
    }
    return () => {
      if (timer) {
        clearInterval(timer)
      }
    }
  }, [shouldStartAutomatically, redirectDelay])

  React.useEffect(() => {
    if (redirectDelay === 0) {
      callback()
    }
  }, [redirectDelay, callback])

  return <span>{redirectDelay}</span>
}

export default Timer
