import React, { useEffect, useState } from 'react'

import { iDeviceTypeCount } from 'pages/devices/data/iDevicesList'
import { iDeviceListDataWithStats } from 'pages/devices-2/data/DevicePageData'
import GenericWidget from 'components/viewComponents/GenericWidget'
import { iWidgetData } from 'components/viewComponents/interfaces'
import { groupDeviceDataByDeviceType } from 'pages/devices/data/DeviceDataUtils'
import { IFilterValues } from 'forms/FormModelInterface'
import { FallbackProps, withErrorBoundary } from 'react-error-boundary'
import { AlertIcon } from 'assets/icons'
import ErrorPage from 'pages/common/ErrorPage'
import { DevicePageColumnIds } from '../DevicesPageUtils'

// export interface iWidgetData {
//     originalData: string | string[] | number | boolean | DateRange | null
//     displayText: string
//     value: number
// }

// export interface iWidgetProps {
//     title: string
//     widgetData: iWidgetData[]
//     widgetLabelCount: number
//     // onFilter: (filterRequestValue: string | string[] | number | boolean | DateRange | null) => void
// }
function DeviceCountByTypeWidget({
  deviceListData,
  onFilter,
  deviceTypeFilteredParam,
}: {
  deviceListData: iDeviceListDataWithStats[]
  onFilter: (filterRequestValues: IFilterValues[]) => void
  deviceTypeFilteredParam: string
}): JSX.Element {
  const [widgetData, setWidgetData] = useState<iWidgetData[]>()
  const onFilterData = (filterRequestValues: IFilterValues[]) => {
    // const filterRequestValue: IFilterValues[] = []
    // filterRequestValue.push({
    //   columnId: DevicePageColumnIds.deviceType,
    //   value: labelValue,
    // })
    if (onFilter) {
      onFilter(filterRequestValues)
    }
    // if (labelValue !== '') {
    //   setIsFiltered(true)
    // } else {
    //   setIsFiltered(false)
    // }
  }

  // useEffect(() => {
  //   if (filterValue.current !== deviceTypeFilteredParam) {
  //     filterValue.current = deviceTypeFilteredParam
  //     setIsFilterUpdated(true)
  //   }
  // }
  // , [deviceTypeFilteredParam])

  useEffect(() => {
    const wdata: iWidgetData[] = []

    if (deviceListData) {
      const deviceTypeCount: iDeviceTypeCount[] = groupDeviceDataByDeviceType(deviceListData)
      deviceTypeCount.forEach((item) => {
        wdata.push({
          originalData: item.deviceType, // GetProductNameText(item.deviceType as PortableTypes) ,
          displayText: item.deviceType, // GetProductNameText(item.deviceType as PortableTypes) ,
          value: item.count,
          isFiltered: item.deviceType === deviceTypeFilteredParam,
          columnId: DevicePageColumnIds.deviceType,
        })
      })
      setWidgetData(wdata)
    }
  }, [deviceListData, deviceTypeFilteredParam])

  return (
    <GenericWidget
      widgetProps={{
        title: 'Type',
        columnId: DevicePageColumnIds.deviceType,
        widgetData,
        widgetLabelCount: 4,
        onFilter: (filterRequestValues: IFilterValues[]) => onFilterData(filterRequestValues),
      }}
    />
  )
}

export default withErrorBoundary(DeviceCountByTypeWidget, {
  FallbackComponent: ErrorPage,
})
