import { AppConfigurationClient, FeatureFlagValue } from '@azure/app-configuration'

export const AppConfigFeatureFlags = '.appconfig.featureflag'

export enum FeatureFlagsEnums {
  DemoMode = 'DemoMode',
  CalibrationReport = 'CalibrationReport',
  LiveReadings = 'LiveReadings',
}

export const FeatureFlags = {
  DemoMode: FeatureFlagsEnums.DemoMode,
  CalibrationReport: FeatureFlagsEnums.CalibrationReport,
  LiveReadings: FeatureFlagsEnums.LiveReadings,
}

export type TargetingClientFilterType = {
  name: string
  parameters?: {
    Audience?: {
      Users?: string[]
      Groups?: { Name?: string; RolloutPercentage?: number }[]
      Exclusion?: { Users?: string[]; Groups?: string[] }
    }
  }
}

export type TimeWindowClientFilterType = {
  name: string
  parameters: { Start: string; End: string }
}

export interface AppConfigContextType {
  appConfigClient: AppConfigurationClient | null
  features:
    | {
        [key: string]: FeatureFlagValue
      }
    | undefined
}
