import React from 'react'
import clsx from 'clsx'

import { iFormComponentProps } from './formComponentInterfaces'

// self-stretch h-10

function TextInputControl(props: iFormComponentProps): JSX.Element {
  const { className, elementProps, id } = props

  return (
    <div className='w-full'>
      <input
        type='text'
        id={id}
        disabled={elementProps.readOnly}
        className={clsx(
          className,
          'font-poppins rounded-3xl border-3 pl-4 py-3 pr-3 text-c-dark-blue-2 text-xs font-medium leading-5 border-c-light-blue-2 placeholder-c-dark-blue-2',
          elementProps.readOnly
            ? 'bg-c-light-blue-1'
            : 'bg-c-white focus:bg-c-light-blue-2 active:bg-c-blue  hover:border-c-dark-blue-2 focus:border-c-blue active:border-c-blue active:text-c-white',
        )}
        {...elementProps}
        // readOnly={elementProps.readonly}
      />
    </div>
  )
}

export default TextInputControl
