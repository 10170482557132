import { ExportColumnType } from 'exportReports/DataExportInterfaces'

import { FormatUTXToDateTime } from 'exportReports/ExportDataUtils'
import { iBillingPlan } from 'data/CustomerListHook'
import { BillingPlanEnums } from 'utils/CommonEnums'
import {
  CustomerStatusConstants,
  CustomerStatusEnums,
  CustomersPageColumnHeader,
  CustomersPageColumnIds,
} from '../view/CustomerPageUtils'
import { Feature } from '../data/iCustomerList'

const CustomersReportColumns: ExportColumnType[] = [
  {
    key: CustomersPageColumnIds.name,
    label: CustomersPageColumnHeader[CustomersPageColumnIds.name],
    format: (val: string) => val,
    cellWidth: 100,
  },
  {
    key: CustomersPageColumnIds.subscriptionActivationDate,
    label: CustomersPageColumnHeader[CustomersPageColumnIds.subscriptionActivationDate],
    format: (val: string) => (val ? FormatUTXToDateTime(new Date(val).getTime()) : ''),
    cellWidth: 95,
  },
  {
    key: CustomersPageColumnIds.subscriptionExpirationDate,
    label: CustomersPageColumnHeader[CustomersPageColumnIds.subscriptionExpirationDate],
    format: (val: string) => (val ? FormatUTXToDateTime(new Date(val).getTime()) : ''),
    cellWidth: 95,
  },
  {
    key: 'billingPlans',
    label: CustomersPageColumnHeader[CustomersPageColumnIds.billingPlanName],
    format: (val: iBillingPlan) => val?.name || '',
    cellWidth: 50,
  },
  {
    key: 'billingPlans',
    label: CustomersPageColumnHeader[CustomersPageColumnIds.billingPlanType],
    format: (val: iBillingPlan) => BillingPlanEnums[val?.billingPlanType as string] || '',
    cellWidth: 65,
  },
  {
    key: CustomersPageColumnIds.features,
    label: CustomersPageColumnHeader[CustomersPageColumnIds.features],
    format: (val: Feature[]) => val?.[0]?.name || '',
    cellWidth: 75,
  },
  {
    key: CustomersPageColumnIds.status,
    label: CustomersPageColumnHeader[CustomersPageColumnIds.status],
    format: (val: string) => CustomerStatusConstants[val as CustomerStatusEnums],
    cellWidth: 65,
  },
]

export { CustomersReportColumns }
