import React from 'react'
import { ExportColumnType } from 'exportReports/DataExportInterfaces'
import { FormatUTXToDateTime, FormatBusinessUnit } from 'exportReports/ExportDataUtils'

const EventLogsReportColumns: ExportColumnType[] = [
  {
    key: 'timeStamp',
    label: 'Date and time',
    format: (val: any) => FormatUTXToDateTime(val as number),
    cellWidth: 100,
  },
  {
    key: 'eventDescription',
    label: 'Description',
    format: (val: string) => val,
    cellWidth: 340,
  },
  {
    key: 'deviceUserName',
    label: 'Assigned to',
    format: (val: string) => val,
    cellWidth: 80,
  }
]

export { EventLogsReportColumns }
