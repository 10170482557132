import { useMsal } from '@azure/msal-react'
import { useMutation } from '@tanstack/react-query'
import { iDeviceConfig } from 'data/DeviceConfigHook'
import { StepsTypes, AzureFunctionResponse, LogsProps } from '../../interfaces'
import { StageStatus } from '../../utils/constants'
import { getDemoLogs } from '../apis'

export function useLogsMutation(
  state: StepsTypes[],
  setState: React.Dispatch<React.SetStateAction<StepsTypes[]>>,
) {
  const { instance: msalInstance } = useMsal()

  return useMutation<unknown, AzureFunctionResponse<unknown>, LogsProps[]>(
    async (props) => {
      setState((prevState) => {
        const newState = [...prevState]
        newState[5].status = StageStatus.WORKING
        return newState
      })
      const logs = await Promise.all(
        props.map((p) =>
          getDemoLogs({
            ...p,
            msalInstance,
          }),
        ),
      )
      return logs
    },
    {
      retry: 3,
      retryDelay: 1000,
      onSuccess: () => {
        setState((prevState) => {
          const newState = [...prevState]
          newState[5].endTitle = 'Log data has been created.'
          newState[5].status = StageStatus.END
          return newState
        })
      },
      onError: (error: AzureFunctionResponse<unknown>) => {
        console.log(error)
        setState((prevState) => {
          const newState = [...prevState]
          newState[5].error = error.message
          return newState
        })
      },
    },
  )
}
