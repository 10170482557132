export const fleetHealthDataQueryKey = (businessUnitId?: string, customerId?: string) => [
  'fleetHealthSummary',
  'businessUnit',
  businessUnitId,
]

export const fleetInsightsDataQueryKey = (businessUnitId?: string, customerId?: string) => [
  'fleetInsightsData',
  'businessUnit',
  businessUnitId,
]

export const dashboardQueries = {
  fleetHealthDataQueryKey,
  fleetInsightsDataQueryKey,
}
