import React from 'react'
import { SvgProps } from 'types/SVGProps'

function NavLastIcon(props: SvgProps): JSX.Element {
  const { stroke, fill, className, toggleClick } = props

  return (
    <div className=''>
      <svg
        className={className}
        width='20'
        height='20'
        viewBox='0 0 20 20'
        fill='none'
        onClick={toggleClick}
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M8.99988 6.00001L12.9999 10.0196L8.99988 14.0393'
          //  stroke={stroke ?? '#FFFFFF'}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M15.9999 6.00001L15.9999 14.0393'
          //  stroke={stroke ?? '#FFFFFF'}
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M12.4999 10.0196L4.99988 10.0196'
          //  stroke={stroke ?? '#FFFFFF'}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </div>
  )
}

export default NavLastIcon
