import React from 'react'
import { DropDownIcon } from 'assets/icons'
import Select, {
  components,
  SingleValueProps,
  DropdownIndicatorProps,
  OptionProps,
} from 'react-select'

import clsx from 'clsx'
import Tooltip from 'components/atom/Tooltip'

interface selectControlProps {
  id?: string
  className?: string
  selectControlProps: any
  labelName?: string
  isLoading?: boolean
}

// <div className='flex flex-col justify-start text-c-white border-2 border-red-400'>
//         <div className='font-poppins  text-4xs font-bold '>
//           {name}
//         </div>
//         {children}
//       </div>

// <div className='font-poppins bg-c-blue text-4xs text-c-white font-bold leading-5'>
// Data View
// </div>
// {props.children}

// <div className="w-36 h-12 px-4 bg-cyan-700 rounded-3xl border-2 border-cyan-700 justify-start items-center gap-2 inline-flex">
//   <div className="grow shrink basis-0 self-stretch justify-start items-center gap-2.5 flex">
//     <div className="grow shrink basis-0 flex-col justify-center items-start inline-flex">
//       <div className="text-white text-xs font-bold font-['Poppins'] leading-3">Business Unit</div>
//       <div className="text-white text-sm font-bold font-['Poppins'] leading-none">Input Label</div>
//     </div>
//   </div>
//   <div className="justify-end items-center gap-2.5 flex">
//     <div className="w-5 h-5 relative" />
//   </div>
// </div>

function SingleValue({ children, ...props }: SingleValueProps) {
  const { name, isDisabled } = props.selectProps
  // if (props.selectProps.labelName)
  return (
    <components.SingleValue {...props}>
      <div
        className={clsx(
          {
            'text-c-white': !isDisabled,
            'text-c-dark-blue-1': isDisabled,
          },
          'h-[52px] flex flex-col justify-stretch  gap-0.5 ',
        )}
      >
        <div
          className={clsx(
            {
              'text-c-white': !isDisabled,
              'text-c-dark-blue-1': isDisabled,
            },
            'font-poppins  text-4xs font-bold  leading-3 ',
          )}
        >
          {name}
        </div>
        <div
          className={clsx(
            {
              'text-c-white': !isDisabled,
              'text-c-dark-blue-1': isDisabled,
            },
            'font-poppins text-sm font-bold  leading-none ',
          )}
        >
          {children}
        </div>
      </div>
    </components.SingleValue>
  )
}

// {...props}>{children}

function DropDownIndicator(props: DropdownIndicatorProps) {
  const { selectProps } = props

  return (
    <components.DropdownIndicator {...props}>
      <DropDownIcon
        className={clsx(
          {
            'stroke-white': !selectProps.isDisabled,
            'stroke-transparent': selectProps.isDisabled,
          },
          ' fill-transparent',
        )}
      />
    </components.DropdownIndicator>
  )
}

function Option({ children, ...props }: OptionProps) {
  return (
    <components.Option {...props}>
      <div className='relative px-1 cursor-pointer my-1'>
        <Tooltip
          tooltipText={children}
          showOnlyWhenTextIsTruncate
          id={`selection-${children}`}
          toolTipClass='tooltip text-pretty  w-fit left-0 -translate-1/2 z-50 -top-10 px-2 py-1'
          className={` text-ellipsis text-c-blue font-bold text-xs px-3 py-2 z-50 hover:bg-c-white rounded-full ${
            JSON.stringify(props?.selectProps?.value) === JSON.stringify(props?.data)
              ? 'bg-c-white'
              : 'bg-transparent'
          }`}
        >
          {children}
        </Tooltip>
      </div>
    </components.Option>
  )
}

function SelectControlWithLabel(controlProps: selectControlProps): JSX.Element {
  const { className, selectControlProps, isLoading } = controlProps

  return (
    <Select
      isLoading={isLoading}
      isSearchable={selectControlProps.isSearchable ?? true}
      // filterOption={(option, inputValue) => {
      //   if ((option.data as IselectOptionProps).isHidden === true) {
      //     return false
      //   }
      //   return ((option.data as IselectOptionProps).label as string)
      //     ?.toLowerCase()
      //     .includes(inputValue?.toLowerCase())
      // }}
      className={className}
      {...selectControlProps}
      value={selectControlProps.value}
      name={selectControlProps.labelName}
      components={{
        // ValueContainer: valueContainer,
        DropdownIndicator: DropDownIndicator,
        SingleValue,
        Option,
      }}
      styles={{
        control: (baseStyles, state) => ({
          ...baseStyles,
          // width: '100%',
          // backgroundColor: state.isDisabled ? '#EAF1F6' : '#FFFFFF', // c-light-blue-1
          borderRadius: '26px',
          // fontFamily: 'Poppins',
          // fontSize: '12px',
          // fontWeight: '500',

          width: '100%',
          height: '52px',

          borderWidth: state?.isDisabled ? '2px' : '3px',

          paddingTop: '6px',

          // borderColor: '#CEDCEB',

          borderColor: state.isFocused ? '#0F1B2E' : '#2767A4', //
          backgroundColor: state.isFocused ? '#2767A4' : state?.isDisabled ? '#DDE8F2' : '#2767A4', //

          // '&:focus': {
          //   outline: 'none',
          //   borderColor: 'red', // '#2767A4', // '',
          //   backgroundColor: 'chocolate', // '#EAF1F6', // c-light-blue-2
          // },
          '&:hover': {
            borderColor: '#1B4373',
            backgroundColor: '#1B4373',
          },
          ':active': {
            borderColor: '#0F1B2E',
            backgroundColor: '#0F1B2E', // c-light-blue-2
            // color: '#FFFFFF',
          },

          // border-c-light-blue-2 #DDE8F2
          // hover:border-c-dark-blue-2 #1B4373
          // focus:border-c-blue #2767A4
          // active:border-c-blue #2767A4

          // focus:bg-c-light-blue-2
        }),

        noOptionsMessage: (baseStyles, state) => ({
          ...baseStyles,
          fontFamily: 'Poppins',
          fontSize: '11px',
          fontWeight: '500',
        }),
        valueContainer: (baseStyles, state) => ({
          ...baseStyles,

          // backgroundColor: state.isDisabled ? '#EAF1F6' : '#FFFFFF', // c-light-blue-1
          // backgroundColor: '#FFFFFF',
          // ':active': {
          //   // color: '#FFFFFF',
          //   backgroundColor: '#2767A4', // c-light-blue-2
          // },
          // ':disabled': {
          //   backgroundColor: '#EAF1F6', // c-light-blue-2
          // },

          // paddingLeft: '9px',

          // borderRadius: '26px',
          // fontFamily: 'Poppins',
          // fontSize: '12px',
          // fontWeight: '500',
        }),
        singleValue: (baseStyles, state) => ({
          ...baseStyles,

          // fontFamily: 'Poppins',
          // fontSize: '12px',
          // fontWeight: '500',

          paddingLeft: '9px',

          // paddingTop: '10px',
          // paddingBottom: '10px',

          // border: '2px solid red'
          // color: '#1B4373',
          // ':active': {
          //   color: '#FFFFFF',
          //   backgroundColor: '#2767A4', // c-light-blue-2
          //   // backgroundColor: 'red', // c-light-blue-2
          // },
          // ':disabled': {
          //   backgroundColor: '#EAF1F6', // c-light-blue-2
          // },
        }),
        indicatorSeparator: (baseStyles, state) => ({
          ...baseStyles,
          display: 'none',
        }),
        dropdownIndicator: (baseStyles, state) => ({
          ...baseStyles,
          paddingRight: '15px',
          marginTop: '-24px',
        }),
        clearIndicator: (baseStyles, state) => ({
          ...baseStyles,
          display: 'none',
        }),
        menu: (base) => ({
          ...base,
          borderRadius: '20px',
          backgroundColor: '#DDE8F2', // c-light-blue-2

          paddingBottom: '16px',
          paddingTop: '16px',

          paddingRight: '6px',

          marginTop: '0px',
          marginBottom: '0px',

          border: '3px solid #DDE8F2',
          scrollSnapType: 'y mandatory',
          // width: '100%',
          boxShadow: '0px 0px 40px 0px rgba(32, 71, 127, 0.60)',
        }),
        menuList: (base, state) => ({
          ...base,
          paddingBottom: '0px',
          paddingTop: '0px',
          marginTop: '0px',
          marginBottom: '0px',
          scrollSnapAlign: 'start',
          // borderRadius: '16px',
          // marginRight: '12px',
          // width: '100%'
        }),
        menuPortal: (base) => ({
          ...base,
          // width: '50px',
          // borderRadius: '16px',
          // paddingBottom: '0px',
          // paddingTop: '0px',
        }),
        option: (baseStyles, state) => ({}),
        placeholder: (baseStyles, state) => ({
          ...baseStyles,
          color: '#1B4373',
        }),

        // control: (baseStyles, state) => ({
        //   ...baseStyles,
        //   borderColor: state.isFocused ? 'red' : 'green',

        // }),
      }}
    />
  )
}

export default SelectControlWithLabel
