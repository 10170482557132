import React from 'react'
import { SvgProps } from 'types/SVGProps'

function CalibrationIcon(props: SvgProps): JSX.Element {
  const { stroke, fill, className, toggleClick } = props

  return (
    <div className=''>
      <svg
        className={className}
        width='20'
        height='20'
        viewBox='0 0 20 20'
        fill={fill ?? 'none'}
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M13 2.5H15C16.1046 2.5 17 3.39543 17 4.5V17C17 18.1046 16.1046 19 15 19H5C3.89543 19 3 18.1046 3 17V4.5C3 3.39543 3.89543 2.5 5 2.5H7'
          stroke={stroke ?? '#FFFFFF'}
          strokeWidth='1.8'
        />
        <rect x='6.9' y='0.9' width='6.2' height='3.2' rx='1.1' stroke={stroke ?? '#FFFFFF'} strokeWidth='1.8' />
        <path d='M7 8V15' stroke={stroke ?? '#FFFFFF'} strokeLinecap='round' />
        <path d='M10 10L10 15' stroke={stroke ?? '#FFFFFF'} strokeLinecap='round' />
        <path d='M13 12L13 15' stroke={stroke ?? '#FFFFFF'} strokeLinecap='round' />
      </svg>
    </div>
  )
}

export default CalibrationIcon
