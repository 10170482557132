import React from 'react'
import { SvgProps } from 'types/SVGProps'

function TickIcon(props: SvgProps): JSX.Element {
    const { stroke, fill, className, toggleClick } = props

  return (
    <div>
    <svg onClick={toggleClick} className={className} width="21" height="19" viewBox="0 0 21 19" fill={fill??"none"} xmlns="http://www.w3.org/2000/svg">
    <path d="M1.32031 12.4537L7.21365 17L19.0003 1.8457" stroke={stroke?? 'white'} strokeWidth="2.65487" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
    </div>
  )
}

export default TickIcon