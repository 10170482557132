import SelectControlWithLabel from 'components/formComponents/SelectControlWithLabel'
import { BusinessContext } from 'contexts/BusinessContext'
import { UserAccountContext } from 'contexts/UserAccountContext'
import { iCustomerList } from 'data/CustomerListHook'
import { iBusinessUnitDetails } from 'data/Interfaces'
import PageTitle from 'pages/common/PageTitle'
import React, { useContext } from 'react'
import { IsUserGlobalScope } from 'utils/UserDataUtils'

type BusinessAndCustomerSelectionProps = {
  pageTitle: string
  showCustomerSelector?: boolean
  showBusinessUnitSelector?: boolean
}

function BusinessAndCustomerSelection({
  pageTitle,
  showBusinessUnitSelector = true,
  showCustomerSelector = true,
}: BusinessAndCustomerSelectionProps) {
  const userAccountInfo = useContext(UserAccountContext)
  const { state: userInfo } = userAccountInfo
  const isGlobalUser = IsUserGlobalScope(userInfo)
  const {
    customerDataFromQuery,
    selectedCustomer,
    setSelectedCustomer,
    businessUnitDataFromQuery,
    selectedBusinessUnit,
    setSelectedBusinessUnit,
  } = useContext(BusinessContext)
  const isCustomerListLoading = customerDataFromQuery?.isLoading
  const customerList = customerDataFromQuery?.data

  const isBusinessUnitsListLoading = businessUnitDataFromQuery?.isLoading
  const businessUnitsList = businessUnitDataFromQuery?.data

  return (
    <div
      id='dashboard-title-cust-bu-selector'
      className='pl-3 md:pl-5 pr-3 md:pr-5 py-4 flex-grow flex flex-wrap sm:flex-nowrap flex-row items-center bg-c-light-blue-4 sticky top-0 right-0 z-20'
    >
      <div id='dashboardtitle' className=' xl:w-full mr-12'>
        <PageTitle title={pageTitle} />
      </div>
      <div
        id='cust-bu-selector'
        className='grid grid-cols-1 md:grid-cols-2 gap-2  md:gap-x-4 w-full md:ml-12'
      >
        {showCustomerSelector && isGlobalUser && (
          <div
            id='cust-selector'
            className={`w-full h-12 ${showBusinessUnitSelector ? '' : 'col-start-2'}`}
          >
            <SelectControlWithLabel
              isLoading={isCustomerListLoading}
              className='w-full md:pl-1 h-12'
              selectControlProps={{
                labelName: 'Customer',
                options: customerList?.sort((a, b) => (a.name === 'All' ? -1 : 1)),
                getOptionLabel: (option: iCustomerList) => option.name,
                getOptionvalue: (option: iCustomerList) => option.id,
                value:
                  customerList && selectedCustomer
                    ? customerList?.find((c: iCustomerList) => c.id === selectedCustomer?.id)
                    : customerList?.[0],
                // defaultValue: selectedCustomerParam ?? customerList?.[0],
                isMulti: false,
                isSearchable: false,
                onChange: (selectedOption: iCustomerList) => {
                  setSelectedBusinessUnit?.(businessUnitsList?.[0])
                  setSelectedCustomer?.(selectedOption)
                },
              }}
            />
          </div>
        )}
        {showBusinessUnitSelector && (
          <div id='bu-selector' className='w-full h-12 md:col-start-2 '>
            <SelectControlWithLabel
              isLoading={isBusinessUnitsListLoading}
              className='w-full md:pl-1 h-12'
              selectControlProps={{
                labelName: 'Business unit',
                options: businessUnitsList,
                getOptionLabel: (option: iBusinessUnitDetails) => option.name,
                getOptionvalue: (option: iBusinessUnitDetails) => option.id,
                value:
                  businessUnitsList && selectedBusinessUnit
                    ? businessUnitsList?.find(
                        (c: iBusinessUnitDetails) => c.id === selectedBusinessUnit?.id,
                      )
                    : businessUnitsList?.[0],
                isMulti: false,
                isSearchable: false,
                onChange: (selectedOption: iBusinessUnitDetails) => {
                  setSelectedBusinessUnit?.(selectedOption)
                },
              }}
            />
          </div>
        )}
      </div>
    </div>
  )
}
BusinessAndCustomerSelection.defaultProps = {
  showCustomerSelector: true,
  showBusinessUnitSelector: true,
}
export default BusinessAndCustomerSelection
