import React, { createContext, useMemo, useReducer } from 'react'
import { SignInAuthority } from 'utils/AuthConfig'
import { UserRoleTypes, UserScopeType } from 'utils/CommonEnums'
import { UserAccountContextActions } from 'utils/Constants'
import { getUserAccount, isDemoMode } from 'utils/CommonUtils'
import { iAuthenticatedUserInfo } from './interfaces'

// Define the initial state of the context
export interface iUserAccountState extends iAuthenticatedUserInfo {
  adb2cPolicy: string
  isAuthorized: boolean
}

const initialUserAccountState: iUserAccountState = {
  user: {
    id: '',
    customerId: '',
    firstName: '',
    lastName: '',
    fullName: '',
    emailId: '',
    timeZone: '',
    locale: '',
    lastLogin: {
      IpAddress: '',
      Date: '',
    },
    status: '',
    userScope: UserScopeType.Customer,
    hasApiAccess: false,
    subscriptionExpiryDate: '',
  },
  authority: [
    {
      businessUnitId: '',
      businessUnitName: '',
      roleName: UserRoleTypes.Reader,
      scope: '',
      permissions: [],
    },
  ],
  adb2cPolicy: SignInAuthority as string,
  isAuthorized: false,
  isDemoMode: isDemoMode(),
}

interface iUserAccountContext {
  state: iUserAccountState
  dispatch: React.Dispatch<any>
}

// Define the reducer function
function userAccountReducer(state: iUserAccountState, action: { type: string; payload: any }) {
  // const msal = useMsal()
  switch (action.type) {
    // Add cases for different actions
    case UserAccountContextActions.SetUserAccount:
      if (state.user.id !== action.payload?.userAccountState?.user.id) {
        return {
          ...state,
          ...action.payload.userAccountState,
          isAuthorized: true,
          ...getUserAccount(),
        }
      }
      return state
    case UserAccountContextActions.SetADB2CPolicy:
      return {
        ...state,
        adb2cPolicy: action.payload.adb2cPolicy,
      }

    case UserAccountContextActions.UpdateBasicProfile:
      return {
        ...state,
        user: {
          ...state.user,
          locale: action.payload.locale,
          firstName: action.payload.firstName,
          lastName: action.payload.lastName,
        },
      }
    case UserAccountContextActions.UserLogin:
      return {
        ...state,
        isAuthorized: true,
      }

    case UserAccountContextActions.DemoModeStatus: {
      localStorage.setItem('isDemoMode', action.payload.isDemoMode as string)

      if (action.payload.isDemoMode) {
        localStorage.setItem('userAccount', JSON.stringify(state))
        const demoAccount = {
          ...state,
          isDemoMode: action.payload.isDemoMode,
          user: {
            ...state.user,
            customerId: action.payload.customerId,
            userScope: action.payload.userScope,
          },
          authority: [
            {
              ...state.authority[0],
              businessUnitId: action.payload.businessUnitId,
              businessUnitName: action.payload.businessUnitName,
              roleName: action.payload.roleName,
              scope: action.payload.scope,
            },
          ],
        }
        localStorage.setItem('demoAccount', JSON.stringify(demoAccount))
        return demoAccount
      }
      return getUserAccount()
    }

    // Remove the commented out code block
    default:
      return state
  }
}

// Create the context
export const UserAccountContext = createContext({} as iUserAccountContext)

// Create a provider component
export function UserAccountProvider({ children }: { children: React.ReactNode }) {
  // const [userAccount, setUserAccount] = useState<iUserAccountState>(initialUserAccountState)

  // const value = useMemo(() => ({ userAccount, setUserAccount }), [userAccount, setUserAccount])

  // Use the reducer function
  const [state, dispatch] = useReducer(userAccountReducer, initialUserAccountState)

  // Define any additional functions or state variables here

  const contextValue = useMemo(() => ({ state, dispatch }), [state, dispatch])

  return <UserAccountContext.Provider value={contextValue}>{children}</UserAccountContext.Provider>
}
