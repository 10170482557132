import React, { useContext, useEffect, useMemo, useState, useCallback } from 'react'
import clsx from 'clsx'
import { useLocation, useSearchParams } from 'react-router-dom'
import { dateRangeOptions, selectAllOption, selectBlankOption } from 'forms/FormUtils'
import { DateRange, IFilterValues, IselectOptionProps } from 'forms/FormModelInterface'
import { DateRangeSelectorTypes, DeviceCalDueTypes } from 'utils/CommonEnums'
import { iDevice, useDeviceList } from 'data/DeviceListHook'
import BusinessAndCustomerSelection from 'components/modules/BusinessAndCustomerSelection'
import { BusinessContext } from 'contexts/BusinessContext'
import { withPageTracking } from 'utils/AppInsightConfig'
import { useGenericEventHandler } from 'data/GenericEventHandler'
import { withErrorBoundary } from 'react-error-boundary'
import ErrorPage from 'pages/common/ErrorPage'
import { sub } from 'date-fns'
import CalibrationPageTable from './CalibrationPageTable'
import {
  CalibrationPageColumnIds,
  CalibrationPageParamLabels,
  CalibrationPageFilterFormType,
} from './CalibrationsPageUtils'
import CalibrationCountByDaysWidget from './widgets/CalibrationCountByDaysWidget'
import CalibrationCountByMonthWidget from './widgets/CalibrationCountByMonthWidget'
import CalibrationCountByTypeWidget from './widgets/CalibrationCountByTypeWidget'
import CalibrationPageFilterForm from './CalibrationPageFilterForm'
import CalibrationFilterFormat from '../export/CalibrationFilterFormat'
import CalibrationCalenderWidget from './widgets/CalibrationCalander/CalibrationCalenderWidget'

function CalibrationsPage() {
  useEffect(() => {
    document.title = 'Calibrations'
  }, [])

  const location = useLocation()
  const redirectPageURL = `${location.pathname}${location.search}`

  const [showFilterForm, setShowFilterForm] = useState(false)

  // global filter params
  const [searchParams, setSearchParams] = useSearchParams()

  // filter form params
  const childBUParam = searchParams.get(CalibrationPageParamLabels.businessUnit) ?? ''
  const deviceSerialParam = searchParams.get(CalibrationPageParamLabels.serialNumber) ?? ''
  const calibrationStatusParam = searchParams.get(CalibrationPageParamLabels.calStatus) ?? ''
  const deviceTypeParam = searchParams.get(CalibrationPageParamLabels.deviceType) ?? ''
  const deviceUserParam = searchParams.get(CalibrationPageParamLabels.deviceUser) ?? ''

  const lastCalSelectorParam = searchParams.get(CalibrationPageParamLabels.lastCalSelector) ?? ''
  const lastCalDateToParam = searchParams.get(CalibrationPageParamLabels.lastCalTo) ?? ''
  const lastCalDateFromParam = searchParams.get(CalibrationPageParamLabels.lastCalFrom) ?? ''

  const calDueSelectorParam = searchParams.get(CalibrationPageParamLabels.calDueSelector) ?? ''
  const calDueDateToParam = searchParams.get(CalibrationPageParamLabels.calDueTo) ?? ''
  const calDueDateFromParam = searchParams.get(CalibrationPageParamLabels.calDueFrom) ?? ''

  /**
   * Business Units List and Customer List from BusinessContext
   */
  const {
    customerDataFromQuery,
    businessUnitDataFromQuery,
    selectedBusinessUnit,
    selectedCustomer,
  } = useContext(BusinessContext)

  const businessUnitsList = businessUnitDataFromQuery?.data ?? []
  const customerList = customerDataFromQuery?.data ?? []
  const selectedCustomerParam = selectedCustomer?.id ?? ''
  const selectedMainBUParam = selectedBusinessUnit?.id ?? ''

  const [filteredCalibrationPageData, setfilteredCalibrationPageData] = useState<iDevice[]>([])
  const [unfilteredCalibrationPageData, setUnfilteredCalibrationPageData] = useState<iDevice[]>([])

  const { genericEventHandler } = useGenericEventHandler()
  const {
    data: deviceListData,
    isLoading: isDeviceListDataLoading,
    isError: isDeviceListDataError,
    error: deviceListError,
  } = useDeviceList(
    redirectPageURL,
    selectedMainBUParam,
    selectedCustomerParam,
    businessUnitsList,
    businessUnitsList !== undefined,
  )

  useEffect(() => {
    if (isDeviceListDataError) {
      genericEventHandler({
        onlyTrack: true,
        severity: 'error',
        message: deviceListError?.message || 'Error in fetching device list data',
        error: deviceListError,
        extraData: {
          component: 'CalibrationPage',
          action: 'get in fetching device list data',
        },
      })
    }
  }, [isDeviceListDataError, deviceListError])

  useEffect(() => {
    if (!isDeviceListDataLoading && !isDeviceListDataError) {
      setUnfilteredCalibrationPageData(deviceListData ?? [])
    }
  }, [deviceListData, isDeviceListDataLoading, isDeviceListDataError])

  const resetFilterFormParams = () => {
    if (searchParams.toString().length > 0) {
      searchParams.delete(CalibrationPageParamLabels.serialNumber)
      searchParams.delete(CalibrationPageParamLabels.deviceType)
      searchParams.delete(CalibrationPageParamLabels.deviceUser)
      searchParams.delete(CalibrationPageParamLabels.businessUnit)
      searchParams.delete(CalibrationPageParamLabels.calStatus)
      searchParams.delete(CalibrationPageParamLabels.lastCalFrom)
      searchParams.delete(CalibrationPageParamLabels.lastCalTo)
      searchParams.delete(CalibrationPageParamLabels.calDueFrom)
      searchParams.delete(CalibrationPageParamLabels.calDueTo)
      searchParams.delete(CalibrationPageParamLabels.lastCalSelector)
      searchParams.delete(CalibrationPageParamLabels.calDueSelector)
      setSearchParams(searchParams)
    }
  }

  const handleFilterFormSubmit = useCallback(
    (formData: IFilterValues[]) => {
      formData.forEach((filter) => {
        if (filter.columnId === CalibrationPageColumnIds.serialNumber) {
          if (filter?.value === '') {
            searchParams.delete(CalibrationPageParamLabels.serialNumber)
          } else {
            searchParams.set(CalibrationPageParamLabels.serialNumber, filter?.value as string)
          }
        }

        if (filter.columnId === CalibrationPageColumnIds.calibrationStatus) {
          if (filter?.value === '') {
            searchParams.delete(CalibrationPageParamLabels.calStatus)
          } else {
            searchParams.set(CalibrationPageParamLabels.calStatus, filter?.value as string)
          }
        }

        if (filter.columnId === CalibrationPageColumnIds.deviceType) {
          if (filter?.value === '') {
            searchParams.delete(CalibrationPageParamLabels.deviceType)
          } else {
            searchParams.set(CalibrationPageParamLabels.deviceType, filter?.value as string)
          }
        }

        if (filter.columnId === CalibrationPageColumnIds.businessUnit) {
          if (filter?.value === '') {
            searchParams.delete(CalibrationPageParamLabels.businessUnit)
          } else {
            searchParams.set(CalibrationPageParamLabels.businessUnit, filter?.value as string)
          }
        }

        if (filter.columnId === CalibrationPageColumnIds.deviceUser) {
          if (filter?.value === '') {
            searchParams.delete(CalibrationPageParamLabels.deviceUser)
          } else {
            searchParams.set(CalibrationPageParamLabels.deviceUser, filter?.value as string)
          }
        }

        // lastCalSelector: 'lsync',
        // lastCalFrom: 'lsyncfrom',
        // lastCalTo: 'lsyncto',

        // calDueSelector: 'lson',
        // calDueFrom: 'lsonfrom',
        // calDueTo: 'lsonto',
        if (filter.columnId === CalibrationPageColumnIds.lastCal) {
          if (filter?.value === '') {
            searchParams.delete(CalibrationPageParamLabels.lastCalFrom)
            searchParams.delete(CalibrationPageParamLabels.lastCalTo)
            searchParams.delete(CalibrationPageParamLabels.lastCalSelector)
          } else {
            const lastSyncdateRangeToBeFiltered = (filter?.value as IselectOptionProps)
              .value as DateRange
            const lastCalSelectorTobeFiltered = (filter?.value as IselectOptionProps)
              .label as string
            searchParams.set(
              CalibrationPageParamLabels.lastCalSelector,
              lastCalSelectorTobeFiltered,
            )
            searchParams.set(
              CalibrationPageParamLabels.lastCalFrom,
              (lastSyncdateRangeToBeFiltered.startDate as Date).toISOString().split('T')[0],
            )
            searchParams.set(
              CalibrationPageParamLabels.lastCalTo,
              (lastSyncdateRangeToBeFiltered.endDate as Date).toISOString().split('T')[0],
            )
          }
        }

        if (filter.columnId === CalibrationPageColumnIds.calDue) {
          if (filter?.value === '') {
            searchParams.delete(CalibrationPageParamLabels.calDueFrom)
            searchParams.delete(CalibrationPageParamLabels.calDueTo)
            searchParams.delete(CalibrationPageParamLabels.calDueSelector)
          } else {
            const calDueDateToBeFiltered = (filter?.value as IselectOptionProps)?.value as DateRange
            if (calDueDateToBeFiltered && calDueDateToBeFiltered.isCustom) {
              const customCalDueDateFilterValue = calDueDateToBeFiltered.customValue
              if (customCalDueDateFilterValue) {
                searchParams.set(
                  CalibrationPageParamLabels.calDueSelector,
                  customCalDueDateFilterValue,
                )
              }
              if (customCalDueDateFilterValue === DeviceCalDueTypes.Overdue) {
                searchParams.delete(CalibrationPageParamLabels.calDueFrom)
              }
            } else {
              const lastSwOnSelectorTobeFiltered = (filter?.value as IselectOptionProps)
                .label as string
              searchParams.set(
                CalibrationPageParamLabels.calDueSelector,
                lastSwOnSelectorTobeFiltered,
              )
              searchParams.set(
                CalibrationPageParamLabels.calDueFrom,
                new Date(calDueDateToBeFiltered.startDate).toISOString().split('T')[0],
              )
              searchParams.set(
                CalibrationPageParamLabels.calDueTo,
                new Date(calDueDateToBeFiltered.endDate).toISOString().split('T')[0],
              )
            }
          }
        }
      })

      setSearchParams(searchParams)
    },
    [searchParams, setSearchParams],
  )

  const handleFilterFormReset = () => {
    // console.log('Reset form')
    resetFilterFormParams()
  }

  const filterTableValues = useMemo(
    () => [
      {
        columnId: CalibrationPageColumnIds.serialNumber,
        value: deviceSerialParam ?? '',
      },
      {
        columnId: CalibrationPageColumnIds.deviceType,
        value: deviceTypeParam ?? '',
      },
      {
        columnId: CalibrationPageColumnIds.calibrationStatus,
        value: calibrationStatusParam ?? '',
      },
      {
        columnId: CalibrationPageColumnIds.businessUnit,
        value: childBUParam ?? '',
      },
      {
        columnId: CalibrationPageColumnIds.deviceUser,
        value: deviceUserParam ?? '',
      },
      {
        columnId: CalibrationPageColumnIds.lastCal,
        value:
          lastCalDateFromParam === '' && lastCalDateToParam === ''
            ? ''
            : {
                startDate: new Date(lastCalDateFromParam),
                endDate: new Date(lastCalDateToParam),
              },
      },

      {
        columnId: CalibrationPageColumnIds.calDue,
        value:
          calDueSelectorParam === DeviceCalDueTypes.Overdue
            ? {
                startDate: new Date('0001-01-01'),
                endDate: sub(new Date(), { days: 1 }),
                isCustom: true,
                customValue: DeviceCalDueTypes.Overdue,
              }
            : calDueDateFromParam === '' && calDueDateToParam === ''
            ? ''
            : {
                startDate: new Date(`${calDueDateFromParam}T00:00:00`), // Start date for the calibration, set to the start of the day
                endDate: new Date(`${calDueDateToParam}T23:59:59`), // End date for the calibration, set to the end of the day
              },
      },

      // {
      //   columnId: CalibrationPageColumnIds.calDue,
      //   value:
      //     calDueDateFromParam === '' && calDueDateToParam === ''
      //       ? ''
      //       : {
      //           startDate: new Date(calDueDateFromParam),
      //           endDate: new Date(calDueDateToParam),
      //         },
      // },
      {
        columnId: CalibrationPageColumnIds.lastCalSelector,
        value: lastCalSelectorParam ?? '',
      },
      {
        columnId: CalibrationPageColumnIds.calDueSelector,
        value: calDueSelectorParam ?? '',
      },
    ],
    [
      deviceSerialParam,
      deviceTypeParam,
      childBUParam,
      calibrationStatusParam,
      lastCalSelectorParam,
      lastCalDateFromParam,
      lastCalDateToParam,
      calDueSelectorParam,
      calDueDateFromParam,
      calDueDateToParam,
      deviceUserParam,
    ],
  )

  const convertISelectOptionPropsToServiceFaultsFormType = useMemo(() => {
    const serialNumberFiltered =
      filterTableValues.find((filter) => filter.columnId === CalibrationPageColumnIds.serialNumber)
        ?.value ?? ''
    const calibrationStatusFiltered = filterTableValues.find(
      (filter) => filter.columnId === CalibrationPageColumnIds.calibrationStatus,
    )?.value as string
    const deviceTypeFiltered =
      filterTableValues.find((filter) => filter.columnId === CalibrationPageColumnIds.deviceType)
        ?.value ?? ''
    const businessUnitFiltered =
      filterTableValues.find((filter) => filter.columnId === CalibrationPageColumnIds.businessUnit)
        ?.value ?? ''
    const deviceUserFiltered = filterTableValues.find(
      (filter) => filter.columnId === CalibrationPageColumnIds.deviceUser,
    )?.value as string
    const lastCalDateRangeFiltered = filterTableValues.find(
      (filter) => filter.columnId === CalibrationPageColumnIds.lastCal,
    )?.value as DateRange

    const calDueDateRangeFiltered = filterTableValues.find(
      (filter) => filter.columnId === CalibrationPageColumnIds.calDue,
    )?.value as DateRange

    const calDueSelectorFiltered =
      (filterTableValues.find(
        (filter) => filter.columnId === CalibrationPageColumnIds.calDueSelector,
      )?.value as DeviceCalDueTypes) ?? ''

    const r: CalibrationPageFilterFormType = {
      serialNumber: {
        value: serialNumberFiltered as string,
        label: serialNumberFiltered as string,
      },
      deviceType:
        deviceTypeFiltered === ''
          ? selectAllOption
          : {
              value: deviceTypeFiltered as string,
              label: deviceTypeFiltered as string,
            },

      businessUnit:
        businessUnitFiltered === ''
          ? selectAllOption
          : {
              value: businessUnitFiltered as string,
              label: businessUnitFiltered as string,
            },

      calibrationStatus:
        calibrationStatusFiltered === ''
          ? selectAllOption
          : {
              value: calibrationStatusFiltered as string,
              label: calibrationStatusFiltered as string,
            },

      deviceUser:
        deviceUserFiltered === ''
          ? selectBlankOption
          : {
              value: deviceUserFiltered as string,
              label: deviceUserFiltered as string,
            },

      lastCal: lastCalDateRangeFiltered
        ? {
            startDate: new Date(lastCalDateRangeFiltered.startDate), // new Date(faultDateTimeFromParam as string),
            endDate: new Date(lastCalDateRangeFiltered.endDate), // new Date(faultDateTimeToParam as string),
          }
        : {
            startDate: new Date(),
            endDate: new Date(),
          },

      calDue: calDueDateRangeFiltered
        ? {
            startDate: new Date(calDueDateRangeFiltered.startDate), // new Date(faultDateTimeFromParam as string),
            endDate: new Date(calDueDateRangeFiltered.endDate), // new Date(faultDateTimeToParam as string),
          }
        : {
            startDate: new Date(),
            endDate: new Date(),
          },

      lastCalSelector:
        lastCalSelectorParam !== ''
          ? dateRangeOptions.find((option) => option.label === lastCalSelectorParam) ?? {
              label: '',
              value: '',
            }
          : dateRangeOptions.find((option) => option.label === DateRangeSelectorTypes.All) ?? {
              label: '',
              value: '',
            },

      calDueSelector: {
        label: calDueSelectorFiltered,
        value: calDueSelectorFiltered,
      },
    }

    return r
  }, [filterTableValues, dateRangeOptions])

  useEffect(() => {
    function hideFilterFormHandler(e: KeyboardEvent) {
      if (e.key === 'Escape' && showFilterForm) {
        setShowFilterForm(false)
      }
    }
    window.addEventListener('keyup', hideFilterFormHandler)

    return () => {
      window.removeEventListener('keyup', hideFilterFormHandler)
    }
  }, [showFilterForm])

  return (
    <>
      <BusinessAndCustomerSelection pageTitle='Calibration' />
      <div
        id='calibration-page-main'
        className='flex flex-col h-[calc(100vh-80px)] w-full'
        aria-hidden='true'
        onClick={() => showFilterForm && setShowFilterForm(false)}
      >
        {/* <div
          id='calibrationpage-widgets-collapsible'
          className='flex flex-col bg-c-light-blue-2 px-3 md:px-5 py-3 md:py-4 '
        >
          <div className='mb-2 self-end'>
            {widgetsVisible ? (
              <ExpandFalseIcon toggleClick={() => setWidgetsVisible(false)} />
            ) : (
              <ExpandTrueIcon toggleClick={() => setWidgetsVisible(true)} />
            )}
          </div> */}
        <div
          id='calibration-page-widgets'
          className={clsx(
            'bg-c-light-blue-2 px-3 md:px-5 py-3 md:py-4 grid grid-cols-1 lg:grid-cols-2 gap-x-4 gap-y-2 ',
          )}
        >
          <div className='rounded-lg lg:row-start-1 row-start-2'>
            <CalibrationCalenderWidget
              data={unfilteredCalibrationPageData}
              onFilter={(filterValues: IFilterValues[]) => {
                handleFilterFormSubmit(filterValues)
              }}
              onReset={() => handleFilterFormReset()}
            />
          </div>
          <div className='gap-2 grid grid-cols-1 md:grid-cols-3 lg:grid-cols-2'>
            <div className=' '>
              <CalibrationCountByDaysWidget
                deviceListData={unfilteredCalibrationPageData ?? []}
                onFilter={(filterRequestValues: IFilterValues[]) => {
                  handleFilterFormSubmit(filterRequestValues)
                }}
                calDueDatesFilteredParam={
                  filterTableValues.find(
                    (filter) => filter.columnId === CalibrationPageColumnIds.calDueSelector,
                  )?.value as DeviceCalDueTypes
                }
              />
            </div>
            <div className=''>
              <CalibrationCountByTypeWidget
                deviceListData={unfilteredCalibrationPageData ?? []}
                onFilter={(filterRequestValues: IFilterValues[]) =>
                  handleFilterFormSubmit(filterRequestValues)
                }
                deviceTypeFilteredParam={
                  filterTableValues.find(
                    (filter) => filter.columnId === CalibrationPageColumnIds.deviceType,
                  )?.value as string
                }
              />
            </div>
            <div className='lg:col-span-2 '>
              <CalibrationCountByMonthWidget
                deviceListData={unfilteredCalibrationPageData ?? []}
                onFilter={(filterRequestValues: IFilterValues[]) =>
                  handleFilterFormSubmit(filterRequestValues)
                }
                calDueDatesFilteredParam={
                  filterTableValues.find(
                    (filter) => filter.columnId === CalibrationPageColumnIds.calDue,
                  )?.value as DateRange
                }
                calDueDateSelectorFilteredParam={
                  filterTableValues.find(
                    (filter) => filter.columnId === CalibrationPageColumnIds.calDueSelector,
                  )?.value as string
                }
              />
            </div>
          </div>

          {/* </div> */}
        </div>

        <CalibrationPageTable
          tableData={deviceListData ?? []}
          isLoading={isDeviceListDataLoading}
          updateFilteredData={(filteredData: iDevice[]) => {
            setfilteredCalibrationPageData(filteredData)
          }}
          // updateFilteredData={(filteredData: iDevice[]) =>
          //   setfilteredCalibrationPageData(filteredData)
          // }
          filterValues={filterTableValues}
          onFilterFormSubmit={(filterVals: IFilterValues[]) => handleFilterFormSubmit(filterVals)}
          onFilterFormReset={() => handleFilterFormReset()}
          onShowFilter={() => setShowFilterForm(true)}
          filterSummary={CalibrationFilterFormat(
            filterTableValues,
            customerList && selectedCustomerParam !== ''
              ? customerList?.find((c) => c.id === selectedCustomerParam)?.name || ''
              : customerList?.[0]?.name || '',
            businessUnitsList && selectedMainBUParam !== ''
              ? businessUnitsList?.find((c) => c.id === selectedMainBUParam)?.name || ''
              : businessUnitsList?.[0]?.name || '',
          )}
        />
      </div>
      <div
        id='calibration-filter-form'
        className={clsx(
          'filter-form hideScrollBar',
          showFilterForm ? 'z-50 -ml-80 opacity-100' : 'opacity-0 -z-50',
        )}
      >
        <CalibrationPageFilterForm
          selectedFilterValues={convertISelectOptionPropsToServiceFaultsFormType}
          optionsData={filteredCalibrationPageData}
          onFilterSubmit={(formData: IFilterValues[]) => handleFilterFormSubmit(formData)}
          onFilterReset={() => handleFilterFormReset()}
          onFilterFormClose={() => setShowFilterForm(false)}
        />
      </div>
    </>
  )
}

export default withErrorBoundary(withPageTracking(CalibrationsPage), {
  FallbackComponent: ErrorPage,
})
