import { useMsal } from '@azure/msal-react'
import { useMutation } from '@tanstack/react-query'
import { iDeviceConfig } from 'data/DeviceConfigHook'
import {
  StepsTypes,
  DemoDevicesCreationResponse,
  AzureFunctionResponse,
  DeviceProps,
} from '../../interfaces'
import { StageStatus } from '../../utils/constants'
import { getDemoDevices, setChannelConfig } from '../apis'

export function useDeviceMutation(
  state: StepsTypes[],
  setState: React.Dispatch<React.SetStateAction<StepsTypes[]>>,
) {
  const { instance: msalInstance } = useMsal()

  return useMutation<DemoDevicesCreationResponse[], AzureFunctionResponse<unknown>, DeviceProps[]>(
    async (props) => {
      setState((prevState) => {
        const newState = [...prevState]
        newState[3].status = StageStatus.WORKING
        return newState
      })
      const devices = await Promise.all(
        props.map((p) =>
          getDemoDevices({
            ...p,
            msalInstance,
          }),
        ),
      )
      return devices
    },
    {
      retry: 3,
      retryDelay: 1000,
      onSuccess: () => {
        setState((prevState) => {
          const newState = [...prevState]
          newState[3].endTitle = 'Device data has been successfully created.'
          newState[3].status = StageStatus.END
          return newState
        })
      },
      onError: (error: AzureFunctionResponse<unknown>) => {
        console.log(error)
        setState((prevState) => {
          const newState = [...prevState]
          newState[3].error = error.message
          return newState
        })
      },
    },
  )
}

export function useConfigurationMutation(
  state: StepsTypes[],
  setState: React.Dispatch<React.SetStateAction<StepsTypes[]>>,
) {
  const { instance: msalInstance } = useMsal()

  return useMutation<
    iDeviceConfig[],
    AzureFunctionResponse<unknown>,
    DemoDevicesCreationResponse[]
  >(
    async (props) => {
      setState((prevState) => {
        const newState = [...prevState]
        newState[4].status = StageStatus.WORKING
        return newState
      })
      const configs = await Promise.all(
        props.map((device) =>
          setChannelConfig({
            device: { id: device.id, deviceType: device.deviceType },
            msalInstance,
          }),
        ),
      )
      return configs
    },
    {
      retry: 3,
      retryDelay: 1000,
      onSuccess: () => {
        setState((prevState) => {
          const newState = [...prevState]
          newState[4].endTitle = 'Device setup successfully.'
          newState[4].status = StageStatus.END
          return newState
        })
      },
      onError: (error: AzureFunctionResponse<unknown>) => {
        console.log(error)
        setState((prevState) => {
          const newState = [...prevState]
          newState[4].error = error.message
          return newState
        })
      },
    },
  )
}
