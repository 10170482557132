import enGB from './en-GB.json'
import enUS from './en-US.json'

export const supportedLanguages = [
  {
    label: 'English (UK)',
    value: 'en-GB',
  },
  // {
  //   label: 'English (US)',
  //   value: 'en-US',
  // },
]
export const translations = {
  'en-GB': enGB,
  'en-US': enUS,
}
