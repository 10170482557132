import { Row } from '@tanstack/react-table'
import clsx from 'clsx'
import Each from 'components/atom/Each'
import React, { useContext } from 'react'
import { TableContext } from '../../TableContext'
import TableCell from './TableCell'
// Define the type for the TableRow component's props
type TableRowProps<T> = {
  getRowCanExpand: (row: Row<T>) => boolean // A function to determine if a row can be expanded
  index: number // The index of the row in the table
}

// Define the TableRow component
export default function TableRow<T>({ index, getRowCanExpand }: TableRowProps<T>) {
  // Use the TableContext to get the table instance and column order
  const { table } = useContext(TableContext)
  const rowArray = table.getRowModel().rows
  // Get the row at the specified index
  const row = rowArray[index]
  const tableWrapper = document.getElementById('table-wrapper')

  // Render the row
  return (
    <tr
      className={clsx({
        'cursor-grab': tableWrapper && tableWrapper?.offsetWidth < tableWrapper?.scrollWidth,
      })}
    >
      {/* Map over the visible cells in the row */}
      <Each
        mapper={row.getVisibleCells()}
        render={(cell, i, cellArray) => (
          // Render a table cell for each visible cell
          <td
            style={
              cell?.column?.columnDef?.meta?.resizable === false
                ? {}
                : {
                    width: cell.column.getSize(),
                    maxWidth: cell.column.getSize(),
                  }
            }
            key={cell.id}
            className={clsx(
              cell.column.columnDef?.meta?.fixedColumn &&
                'sticky left-0 text-ellipsis  l:relative bg-inherit',
            )}
          >
            <div className='relative'>
              {/* Wrap the cell in a SortableContext to make it sortable */}
              {/* Wrap the cell in a DraggableWrapper to make it draggable */}
              {/* Render the TableCell component for the cell */}
              <TableCell index={i} rowIndex={index} getRowCanExpand={getRowCanExpand} />

              {/* Render a div that shows when the cell is being resized */}
              <div
                {...{
                  className: clsx({
                    'right-0': cellArray.length - 1 !== i,
                    'right-1.5': cellArray.length - 1 === i,
                    'absolute top-1/2 -translate-y-1/2 bg-c-white  z-50  h-8  flex justify-center w-[20px]':
                      cell.column.getIsResizing(),
                  }),
                }}
              >
                <div className='w-[2px] flex  bg-c-light-blue-2 rounded-full h-full' />
              </div>
            </div>
          </td>
        )}
      />
    </tr>
  )
}
