import { iAuthenticatedUserInfo } from 'contexts/interfaces'
import { UserPermissionTypes, UserScopeType } from './CommonEnums'
import { iUserPermissions } from './Constants'

export const IsUserGlobalScope = (userInfo: iAuthenticatedUserInfo | null): boolean => {
  if (userInfo && userInfo.user.userScope === UserScopeType.Global) {
    return true
  }
  return false
}

export const IsUserCustomerScope = (userInfo: iAuthenticatedUserInfo): boolean => {
  if (userInfo.user.userScope === UserScopeType.Customer) {
    return true
  }
  return false
}

export const DoesUserHavePermission = (
  userInfo: iAuthenticatedUserInfo,
  permission: UserPermissionTypes,
): boolean => {
  if (userInfo.authority && userInfo.authority.length > 0) {
    const userPermissions = userInfo.authority[0].permissions
    if (userPermissions && userPermissions.length > 0) {
      return userPermissions.includes(permission)
      // const userHasPermission = userPermissions.find((userPermission) => userPermission === permission)
      // if (userHasPermission) {
      //   return true
      // }
    }
  }
  return false
}
