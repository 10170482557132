import React from 'react'
import { SvgProps } from 'types/SVGProps'

export default function ExportIcon(props: SvgProps) {
  const { stroke, fill, className, height, width, toggleClick } = props
  return (
    <div>
      <svg
        className={className}
        width={width ?? '20'}
        height={height ?? '20'}
        fill={fill ?? 'none'}
        onClick={toggleClick}
        viewBox='0 0 18 18'
      >
        <path
          d='M13.5 7.7998L9 12.2998L4.5 7.7998'
          // stroke={stroke ?? '#FFFFFF'}
          strokeWidth='1.8'
          strokeLinecap='round'
          strokeLinejoin='round'
          className='rotate-180 translate-x-[100%] translate-y-[75%]'
        />
        <path
          d='M9 1.7998V11.6998'
          // stroke={stroke ?? '#FFFFFF'}
          strokeWidth='1.8'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          xmlns='http://www.w3.org/2000/svg'
          d='M2 11V14.6C2 15.0971 2.40294 16.0768 2.9 16.0768H15.5C15.9971 16.0768 16.4 15.0971 16.4 14.6V11'
          // stroke={stroke ?? '#FFFFFF'}
          strokeWidth='1.35'
          strokeLinecap='round'
        />
      </svg>
    </div>
  )
}
